import React from 'react';
import NoResults from 'views/common/list/NoResults';
import GenericUserPortfolios from 'views/userMgmt/shared/GenericUserPortfolios';
import portfoliosIllustration from 'views/assets/img/illustrations/portfolios.svg';

export default function UserPortfolios() {
    return (
        <GenericUserPortfolios noResultsRender={renderNoResults} />
    );

    function renderNoResults() {
        return (
            <NoResults
                illustration={portfoliosIllustration}
                title="user_mgmt.users.detail.portfolios.list.no_results.title"
                text="user_mgmt.users.detail.portfolios.list.no_results.text"
            />
        );
    }
}
