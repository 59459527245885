import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { ConsoleBff } from '@console/bff';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { getRoboMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { api } from 'api';
import {
    enrichReportResponseWithFetchDate,
    isEnrichedReportOutdated,
    TEnrichedReportResponse,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';

// eslint-disable-next-line max-len
export const roboClientCashTransferReportEntity = getEntitiesManager().registerEntity<TEnrichedReportResponse<ConsoleBff.TFetchRoboClientCashTransferReportApiReply>>({
    asyncEntityKey: AsyncEntityKeys.roboClientCashTransferReport,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_ROBO_CLIENT_CASH_TRANSFER_DATA],
    includeUpdaters: false,
});

export function selectRoboClientCashTransferReport() {
    return roboClientCashTransferReportEntity.select();
}

export function triggerFetchRoboClientCashTransferReport({
    forceRefresh = false,
}: IForceStateRefreshFilter = {}) {
    const {
        period,
    } = getRoboMgmtReportingPageVars();

    // eslint-disable-next-line max-len
    return roboClientCashTransferReportEntity.async.fetch<ConsoleBff.TFetchRoboClientCashTransferReportApiInputClient, TEnrichedReportResponse<ConsoleBff.TFetchRoboClientCashTransferReportApiReply>, ConsoleBff.TFetchRoboClientCashTransferReportApiReply>({
        api: api.bff.mgmtReporting.fetchRoboClientCashTransferReport,
        apiInputSelector: () => ({
            period,
        }),
        mapApiResponse: ({ response }) => enrichReportResponseWithFetchDate(response),
        refreshMode: () => forceRefresh || isEnrichedReportOutdated(roboClientCashTransferReportEntity.select().data),
        resetDataOnTriggerMode: 'always',
    });
}
