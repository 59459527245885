import React from 'react';
import flatMap from 'lodash/flatMap';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { getSecondLastItemOfArray } from '@console/common/snipsonian/core/cjs/array/filtering/getSecondLastItemOfArray';
import { parseInputDate } from '@console/common/utils/date/dateUtils';
import { ConsoleBff } from '@console/bff';
import { AggregateSelfAumBy } from 'models/ui/mgmtReporting.ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { selectSelfAumReportAsCommonReport } from 'state/entities/mgmtReporting/selfAumReport';
import { selectSelfClientGrowthReport } from 'state/entities/mgmtReporting/selfClientGrowthReport';
import { selectSelfClientCashTransferReport } from 'state/entities/mgmtReporting/selfClientCashTransferReport';
import { isThereReportData } from 'state/entities/mgmtReporting/reportInfo';
import {
    determineTrendDirection,
    determineValueLineItemVariant,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';
import SelfMgmtReportingFilterBlock from './SelfMgmtReportingFilterBlock';
import MgmtReportingAumBox from '../blocks/MgmtReportingAumBox';
import MgmtReportingClientsBox, { toClientPeriodComparisonRowValues } from '../blocks/MgmtReportingClientsBox';
import { MgmtReportingNoData } from '../blocks/MgmtReportingNoData';

const LABEL_PREFIX = 'mgmt_reporting';
const LABEL_PREFIX_SECTIONS = `${LABEL_PREFIX}.clients.sections`;

export default function SelfInvestorMgmtReporting() {
    if (!isThereReportData()) {
        return <MgmtReportingNoData mode="no_data_at_all" />;
    }

    return (
        <>
            <SelfMgmtReportingFilterBlock />

            <MgmtReportingAumBox<AggregateSelfAumBy>
                chartName="self-aum"
                description={`${LABEL_PREFIX}.aum.self.box_description`}
                aggregation={{
                    getCurrentAggregateBy: () => AggregateSelfAumBy.ASSET_TYPE,
                    labelPrefix: `${LABEL_PREFIX}.aum.self.aggregate_by`,
                }}
                reportData={{
                    notifications: [StateChangeNotification.MGMT_REPORTING_SELF_AUM_DATA],
                    asyncEntitySelector: selectSelfAumReportAsCommonReport,
                }}
            />

            {/* eslint-disable-next-line max-len */}
            <MgmtReportingClientsBox<ConsoleBff.TFetchSelfClientGrowthReportApiReply, ConsoleBff.TFetchSelfClientCashTransferReportApiReply>
                description={`${LABEL_PREFIX}.clients.self.box_description`}
                reportData={{
                    growth: {
                        notifications: [StateChangeNotification.MGMT_REPORTING_SELF_CLIENT_GROWTH_DATA],
                        asyncEntitySelector: selectSelfClientGrowthReport,
                        mainMetricDataSelector: ({ data }) => {
                            const clientTotal = getLastItemOfArray(data.report.time_series).total;

                            return {
                                clientTotal,
                                trendDirection: determineTrendDirection(
                                    getSecondLastItemOfArray(data.report.time_series)?.total || 0,
                                    clientTotal,
                                ),
                            };
                        },
                        periodComparisonTableMapper: ({ data }) => ({
                            rows: [{
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.inflow`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'inflow',
                                }),
                                isGrowthNumber: true,
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.outflow`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'outflow',
                                    convertToNegative: true,
                                }),
                                isGrowthNumber: true,
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.net_inflow`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'inflow',
                                    subtractFieldName: 'outflow',
                                }),
                                isGrowthNumber: true,
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.retention_rate`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'retention_rate',
                                }),
                                isGrowthNumber: true,
                                isPercentage: true,
                            }],
                        }),
                        barChartMapper: ({ data: reportData }) => ({
                            categories: [{
                                key: 'inflow',
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.inflow`,
                            }, {
                                key: 'outflow',
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.outflow`,
                                useNegativeColor: true,
                                catKeyToStackOn: 'inflow',
                            }],
                            data: reportData.report.time_series.map((item) => ({
                                x: parseInputDate(item.datetime).toDate(),
                                bars: [{
                                    catKey: 'inflow',
                                    y: item.inflow,
                                }, {
                                    catKey: 'outflow',
                                    y: -1 * item.outflow,
                                }],
                            })),
                            valueLineAggregator: ({ barGroup, barGroupIndex, nrOfBarGroups }) => flatMap(barGroup.bars)
                                .reduce(
                                    (accumulator, bar) => {
                                        // eslint-disable-next-line no-param-reassign
                                        accumulator.y += bar.y;

                                        return accumulator;
                                    },
                                    {
                                        y: 0,
                                        variant: determineValueLineItemVariant({
                                            periodType: reportData.input.periodType,
                                            barGroupIndex,
                                            nrOfBarGroups,
                                        }),
                                    },
                                ),
                        }),
                    },
                    cashTransfer: {
                        notifications: [StateChangeNotification.MGMT_REPORTING_SELF_CLIENT_CASH_TRANSFER_DATA],
                        asyncEntitySelector: selectSelfClientCashTransferReport,
                        periodComparisonTableMapper: ({ data }) => ({
                            /* eslint-disable max-len */
                            rows: [{
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_initial_deposits`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'making_initial_deposits',
                                }),
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_buys`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'making_buys',
                                }),
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_sells`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'making_sells',
                                }),
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.average_number_of_executed_orders`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'average_number_of_executed_orders',
                                }),
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.average_executed_order_size`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'average_executed_order_size',
                                }),
                            }],
                            /* eslint-enable max-len */
                        }),
                        barChartMapper: ({ data: reportData }) => ({
                            categories: [{
                                key: 'making_initial_deposits',
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_initial_deposits`,
                            }, {
                                key: 'making_buys',
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_buys`,
                            }, {
                                key: 'making_sells',
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_sells`,
                                useNegativeColor: true,
                            }],
                            data: reportData.report.time_series.map((item) => ({
                                x: parseInputDate(item.datetime).toDate(),
                                bars: [{
                                    catKey: 'making_initial_deposits',
                                    y: item.making_initial_deposits,
                                }, {
                                    catKey: 'making_buys',
                                    y: item.making_buys,
                                }, {
                                    catKey: 'making_sells',
                                    y: item.making_sells,
                                }],
                            })),
                        }),
                    },
                }}
            />
        </>
    );
}
