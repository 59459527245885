import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { TFetchPolicyOptimizationApiInput } from '@console/core-api/models/portfolioMgmt/policySimulation.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import {
    TPolicyOptimizationApiResponse,
} from '@console/bff/models/policies/policyOptimization.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { api } from 'api';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { warningFlashDispatcher } from '../../flashDispatcher';

export const policySimulationEntity = getEntitiesManager().registerEntity<TPolicyOptimizationApiResponse>({
    asyncEntityKey: AsyncEntityKeys.policyOptimization,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.POLICY_OPTIMIZATION],
    includeUpdaters: true,
});

export function triggerFetchPolicySimulationDetails(apiInput: TFetchPolicyOptimizationApiInput) {
    if (!validateEntityIdBeforeFetch({
        entityId: apiInput.policyId,
        entityType: CoreApiEntityType.policies,
    })) {
        return null;
    }

    return policySimulationEntity.async.fetch({
        api: api.bff.policies.fetchPolicyOptimization,
        apiInputSelector: () => (apiInput),
        onSuccess: ({ apiResponse }) => {
            if (apiResponse.status === EnhancedOptimizationStatus.FAILURE) {
                warningFlashDispatcher('error.optimization.optimization_failed')();
            } else if (apiResponse.status === EnhancedOptimizationStatus.INFEASIBLE) {
                warningFlashDispatcher('error.optimization.opitimization_infeasable')();
            } else if (apiResponse.status === EnhancedOptimizationStatus.NOT_OPTIMIZED) {
                warningFlashDispatcher('error.optimization.optimization_not_optimized')();
            } else if (apiResponse.status === EnhancedOptimizationStatus.PENDING) {
                warningFlashDispatcher('error.optimization.optimization_not_ready')();
            }
        },
        onError: () => warningFlashDispatcher('error.operation_failed.fetch'),
    });
}
