import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { formatDate, DATE_FORMAT } from '@console/common/utils/date/formatDate';
import {
    getSelectedStoryManagerDatabaseDetail,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { getStoryManagerDatabaseHasChanges } from 'state/ui/storyManager.selector';
import { canUserPublishStoryManager } from 'state/auth/apiEntityAuthorization.selectors';
import DetailPageFooter from 'views/common/detail/DetailPageFooter';
import TextButton from 'views/common/buttons/TextButton';
import PublishModal from './PublishModal';
import ResetModal from './ResetModal';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.template.footer';

const useStyles = makeStyles((theme) => ({
    Footer: {
        '& .__box': {
            justifyContent: 'flex-start',

            gap: theme.spacing(2),

            '& .label': {
                fontWeight: 700,
                marginRight: theme.spacing(0.5),
            },

            '& .action': {
                height: 32,
            },
        },
    },
}));

export default function StoryManagerFooter() {
    const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);
    const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false);
    const classes = useStyles();
    const storyManagerDatabase = getSelectedStoryManagerDatabaseDetail();
    const databaseHasChanges = getStoryManagerDatabaseHasChanges();
    const canUserPublish = canUserPublishStoryManager();

    const conditionallyDisableActionButtons = {
        disabled: !canUserPublish || !databaseHasChanges,
        tooltip: !canUserPublish && 'common.action.insufficient_permissions',
    };

    return (
        <>
            <DetailPageFooter className={classes.Footer}>
                <div>
                    <span className="label">
                        <Translate msg={`${TRANSLATION_PREFIX}.modify_date`} />
                    </span>
                    <span>
                        {formatDate({
                            date: storyManagerDatabase.config_modification_datetime,
                            format: DATE_FORMAT.WITH_TIME,
                        })}
                    </span>
                </div>
                <div>
                    <span className="label">
                        <Translate msg={`${TRANSLATION_PREFIX}.publish_date`} />
                    </span>
                    <span>
                        {formatDate({
                            date: storyManagerDatabase.config_publish_datetime,
                            format: DATE_FORMAT.WITH_TIME,
                        })}
                    </span>
                </div>
                {databaseHasChanges && (
                    <TextButton
                        id="storymanager-footer-reset"
                        label={{
                            msg: `${TRANSLATION_PREFIX}.reset`,
                        }}
                        className="action"
                        variant="bare"
                        onClick={() => setIsResetConfirmOpen(true)}
                        size="M"
                        {...conditionallyDisableActionButtons}
                    />
                )}
                {databaseHasChanges && (
                    <TextButton
                        id="storymanager-footer-publish"
                        label={{
                            msg: `${TRANSLATION_PREFIX}.publish`,
                        }}
                        className="action"
                        variant="outlined"
                        onClick={() => setIsPublishConfirmOpen(true)}
                        size="M"
                        noMargin
                        {...conditionallyDisableActionButtons}
                    />
                )}
            </DetailPageFooter>
            {isResetConfirmOpen && (
                <ResetModal
                    open
                    onClose={() => setIsResetConfirmOpen(false)}
                />
            )}
            {isPublishConfirmOpen && (
                <PublishModal
                    open
                    onClose={() => setIsPublishConfirmOpen(false)}
                />
            )}
        </>
    );
}
