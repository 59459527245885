import React from 'react';
import { triggerResetClientsFetch } from 'state/entities/userMgmt/clients';
import GenericUserProperties from 'views/userMgmt/shared/GenericUserProperties';

export default function ClientProperties() {
    return (
        <GenericUserProperties
            overrideOnPreSuccess={triggerResetClientsFetch}
        />
    );
}
