import React from 'react';
import { getBankAccountTypeSelectItems } from 'utils/entities/entityDetailUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { TPortfolioBrokerageAccountFormValues } from './types';

export function PortfolioBrokerageAccountFormContent({
    fields,
}: IExtendedInputFormContext<TPortfolioBrokerageAccountFormValues>) {
    return (
        <>
            <ExtendedInputText<string>
                formField={fields.brokerageUserId}
                wrapper={{
                    label: 'fields.brokerage_user_id.label',
                }}
            />
            <ExtendedInputText<string>
                formField={fields.brokeragePortfolioId}
                wrapper={{
                    label: 'fields.brokerage_portfolio_id.label',
                }}
            />
            <ExtendedInputSelect<string>
                formField={fields.bankAccountType}
                wrapper={{
                    label: 'fields.bank_account_type.label',
                    shouldPrefixTooltip: false,
                    tooltip: {
                        msg: 'common.fields.bank_account_number_type.tooltip',
                        raw: true,
                    },
                }}
                items={getBankAccountTypeSelectItems()}
            />
            <ExtendedInputText<string>
                formField={fields.bankAccountNumber}
                wrapper={{
                    label: 'fields.bank_account_number.label',
                }}
            />
            <ExtendedInputText<string>
                formField={fields.bankId}
                wrapper={{
                    label: 'fields.bank_id.label',
                }}
            />
            <ExtendedInputText<string>
                formField={fields.paymentReference}
                wrapper={{
                    label: 'fields.payment_reference.label',
                }}
            />
        </>
    );
}
