import React from 'react';
import { OptimizationOwnerChoice } from '@console/core-api/models/portfolioMgmt/portfolioOptimization.models';
import {
    getOptimizationOwnerChoiceLabel,
} from 'utils/entities/portfolioMgmt/portfolioOptimizationUtils';
import Tag, { ITagProps, TTagVariant } from 'views/common/widget/Tag';

interface IOptimizationOwnerChoiceTagProps extends Pick<ITagProps, 'addMargin'> {
    ownerChoice: OptimizationOwnerChoice;
}

export default function OptimizationOwnerChoiceTag({
    ownerChoice,
    addMargin = false,
}: IOptimizationOwnerChoiceTagProps) {
    return (
        <Tag
            label={getOptimizationOwnerChoiceLabel(ownerChoice)}
            variant={determineTagVariant()}
            addMargin={addMargin}
        />
    );

    function determineTagVariant(): TTagVariant {
        switch (ownerChoice) {
            case OptimizationOwnerChoice.ACCEPT:
                return 'green';
            case OptimizationOwnerChoice.IGNORE:
                return 'orange';
            case OptimizationOwnerChoice.REJECT:
                return 'red';
            case OptimizationOwnerChoice.REOPTIMIZE:
                return 'blue';
            default:
                return 'regular';
        }
    }
}
