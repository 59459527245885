import React, { useRef, useEffect } from 'react';
import d3, { AxisDomain, CurveFactory, CurveFactoryLineOnly } from 'utils/libs/d3';
import { ILineDataItem, TXScale, TYScale } from './types';

interface IPublicProps<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number> {
    xScale: TXScale<XDomain>;
    yScale: TYScale<YDomain>;
    lineData: ILineDataItem<XDomain, YDomain>[];
    strokeColor: string;
    strokeWidth?: number; // default 2
    strokeRounded?: boolean; // default false
    /* default curveLinear, see https://github.com/d3/d3-shape/blob/master/README.md#curves */
    curveFactory?: CurveFactory | CurveFactoryLineOnly;
}

export default function PathLine<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number>({
    xScale,
    yScale,
    lineData,
    strokeColor,
    strokeWidth = 2,
    strokeRounded = false,
    curveFactory,
}: IPublicProps<XDomain, YDomain>) {
    const ref = useRef();

    useEffect(
        () => {
            const line = d3.line<ILineDataItem<XDomain, YDomain>>()
                .x((d) => xScale(d.x))
                .y((d) => yScale(d.y));

            if (curveFactory) {
                line.curve(curveFactory);
            }

            const path = d3.select<SVGPathElement, unknown>(ref.current)
                .datum(lineData)
                .attr('fill', 'none')
                .attr('stroke', strokeColor)
                .attr('stroke-width', strokeWidth)
                .attr('d', line);

            if (strokeRounded) {
                path
                    // .attr('stroke-linecap', 'round')
                    .attr('stroke-linejoin', 'round');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [xScale, yScale, lineData],
    );

    return (
        <path ref={ref} />
    );
}
