import React from 'react';
import {
    publicInstrumentGroupDetailsEntity,
    triggerPatchPublicInstrumentGroupDetails,
} from 'state/entities/thematicSearch/publicInstrumentGroupDetails';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export function PublicInstrumentGroupAccessibleByTab() {
    const publicInstrumentGroupDetailsData = publicInstrumentGroupDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={publicInstrumentGroupDetailsData}
            nameForDisplay={getDefaultTranslationFromApiLabel(publicInstrumentGroupDetailsData.name)}
            asyncEntityPatchTrigger={(values) => triggerPatchPublicInstrumentGroupDetails((currentInstrumentGroup) => {
                /* eslint-disable no-param-reassign */
                currentInstrumentGroup.modifiable_by = values.modifiable_by;
                currentInstrumentGroup.readable_by = values.readable_by;
                /* eslint-enable no-param-reassign */
            })}
        />
    );
}
