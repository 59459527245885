import { IState } from 'models/state.models';
import { getMultiTranslationsLabelSchema } from 'state/appConfig/selectors';
import { string, integer, object, ObjectSchema } from '@console/common/utils/schema';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let goalDetailsSchema: ObjectSchema<any> = null;

export const getGoalDetailsSchema = (stateForTestPurposes?: IState) => {
    if (!goalDetailsSchema) {
        goalDetailsSchema = object({
            /* p.s. the label objects are required, but it's not that each locale inside are required */
            name: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            description: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            type: string().required(),
            minHorizonYears: integer().minVal(0).required(),
            externalId: string(),
            imgUrl: string().url().required(),
        }).required();
    }

    return goalDetailsSchema;
};
