import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import isUndefined from '@snipsonian/core/cjs/is/isUndefined';
import isString from '@snipsonian/core/cjs/is/isString';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { II18nLabel, IUntranslatableLabel, TI18nLabelOrString, TLabel } from 'models/general.models';

const TRANSLATION_KEY_REGEX = /^[a-z_0-9]+(\.[a-z_0-9]+)+$/;

export function isI18nLabel(label: TI18nLabelOrString): label is II18nLabel {
    return !isString(label);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isI18nLabelOrString(label: any): label is TI18nLabelOrString {
    if (isString(label)) {
        return true;
    }
    if (isObjectPure(label)) {
        return isSetString((label as unknown as II18nLabel).msg);
    }
    return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUntranslatableLabelTypeGuard(label: any): label is IUntranslatableLabel {
    if (isObjectPure(label)) {
        return (label as unknown as IUntranslatableLabel).shouldTranslate === false
            && !isUndefined((label as unknown as IUntranslatableLabel).text);
    }
    return false;
}

export function toI18nLabel(label: TI18nLabelOrString): II18nLabel {
    return isI18nLabel(label)
        ? label
        : {
            msg: label,
        };
}

export function getI18nLabelMsg(label: TLabel): string {
    if (isUntranslatableLabelTypeGuard(label)) {
        return label.text;
    }

    return isI18nLabel(label)
        ? label.msg
        : label;
}

export function isTranslationKey(input: string): boolean {
    if (!input) {
        return false;
    }
    return input.search(TRANSLATION_KEY_REGEX) > -1;
}
