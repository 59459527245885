import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { APP_COLORS } from 'config/styling/colors';
import React from 'react';
import { makeStyles, mixins } from 'views/styling';
import { BORDER_RADIUS } from '../buttons/buttonUtils';

export interface IStepperProps {
    backgroundImagePath: string;
    backgroundImageSize: number;
    nrOfSteps: number;
    activeStep: number;
}

function useStyles(backgroundImagePath: string, backgroundImageSize: number) {
    return makeStyles((theme) => ({
        Stepper: {
            backgroundImage: `linear-gradient(#A8B8EC, #8E9FD4), url(${backgroundImagePath})`,
            backgroundRepeat: 'no-repeat',
            backgroundBlendMode: 'multiply',
            backgroundPositionX: 'right',
            backgroundPositionY: 'bottom',
            backgroundSize: `cover, ${backgroundImageSize}px`,
            ...mixins.widthMax(),
            height: 160,
            borderTopRightRadius: BORDER_RADIUS,
            borderTopLeftRadius: BORDER_RADIUS,

            '& .stepDisplay': {
                ...mixins.flexColCenterCenter(),
                transform: 'translate(0px, 60px)',
                ...mixins.typoBold({ color: APP_COLORS.SYSTEM.WHITE }),
            },
            '& .stepCircles': {
                ...mixins.flexRow(),
                paddingBottom: theme.spacing(1),
            },
            '& .fullCircle': {
                height: 15,
                width: 15,
                borderRadius: '50%',
                borderColor: '#FFFFFF',
                backgroundColor: '#FFFFFF',
                margin: 5,
            },
            '& .emptyCircle': {
                height: 15,
                width: 15,
                borderRadius: '50%',
                borderColor: '#FFFFFF',
                borderStyle: 'solid',
                borderWidth: 1,
                margin: 5,
            },
        },
    }));
}

export default function Stepper({
    backgroundImagePath,
    backgroundImageSize,
    activeStep,
    nrOfSteps,
}: IStepperProps) {
    const getStyles = useStyles(backgroundImagePath, backgroundImageSize);
    const classes = getStyles();
    const currentStepCircles = getStepCircles();

    return (
        <div className={classes.Stepper}>
            <div className="colorBackground">
                <div className="stepDisplay">
                    <div className="stepCircles">
                        {currentStepCircles}
                    </div>
                    <Translate
                        msg="common.stepper.label"
                        placeholders={{
                            activeStep,
                            nrOfSteps,
                        }}
                    />
                </div>
            </div>
        </div>
    );

    function getStepCircles() {
        const circles = [];

        for (let i = 1; i <= nrOfSteps; i++) {
            circles.push([
                <span key={`step_nr_${activeStep}`} className={activeStep >= i ? 'fullCircle' : 'emptyCircle'} />,
            ]);
        }

        return circles;
    }
}
