import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { notifyRouteObservers, setBrowserHistory } from 'views/routes';
import getRouteMatchByPath from 'utils/routing/getRouteMatchByPath';
import mapQueryStringToObject from 'utils/routing/mapQueryStringToObject';

let isInitialRoute = true;

interface IHistoryLocation {
    pathname: string;
    search: string;
}

export default function RouteListener() {
    const history = useHistory();
    const location = useLocation();

    setBrowserHistory(history);
    const { listen } = history;

    if (isInitialRoute) {
        isInitialRoute = false;

        const { route, match } = getRouteMatchByPath(location.pathname);

        notifyRouteObservers({
            routeKey: route.routeKey,
            path: route.path,
            url: location.pathname,
            params: match.params,
            query: mapQueryStringToObject(location.search),
        });
    }

    useEffect(() => {
        const unlisten = listen((historyLocation: unknown) => {
            /* Casting to own IHistoryLocation interface as the types
               do not seem to match with the actual history code */
            const { pathname, search } = (historyLocation as unknown as IHistoryLocation);

            const { route, match } = getRouteMatchByPath(pathname);

            notifyRouteObservers({
                routeKey: route.routeKey,
                path: route.path,
                url: pathname,
                params: match.params,
                query: mapQueryStringToObject(search),
            });
        });

        return unlisten;
    }, [listen]);

    return <div />;
}
