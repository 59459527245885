import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { SortOrder } from '@console/common/models/sort.models';
import { FIRST_PAGE_NR } from '../../config/coreApi.config';
import { IApiOrderByField } from '../../models/api.models';

export function conditionallyAskCount({
    pageNr,
    count,
}: {
    pageNr: number;
    count?: boolean;
}): { count?: boolean } {
    if (isSet(count)) {
        /* the count is explicitly set as input (true or false) so we use that */
        return { count };
    }

    if (pageNr === FIRST_PAGE_NR) {
        /* if not specified as input, by default we ask the back-end for a count
           only when requesting items for the first page */
        return {
            count: true,
        };
    }

    return {};
}

export function convertOrderByObjectToString(orderBy: IApiOrderByField): string {
    if (!orderBy) {
        return null;
    }

    const {
        field,
        order = SortOrder.Ascending,
    } = orderBy;

    return `${field},${order}`;
}

export function convertOrderByStringToObject(orderBy: string): IApiOrderByField {
    if (!isSetString(orderBy)) {
        return null;
    }

    const [field, order] = orderBy.split(',');

    return {
        field,
        order: order as SortOrder,
    };
}
