/**
 * These "api warning" methods are there to avoid circular dependencies, the api methods can not directly
 * use the flashDispatcher (who via-via has a dependency on the api).
 */

type TMessageFlasher = (translationKey: string) => () => void;

let apiWarningFlasher: TMessageFlasher = null;

export function setApiWarningFlasher(messageFlasher: TMessageFlasher) {
    apiWarningFlasher = messageFlasher;
}

export function flashApiWarning(translationKey: string) {
    apiWarningFlasher(translationKey)();
}
