import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import {
    IEnhancedPortfolioPerformance,
    IPortfolioPerformanceStatus,
} from '@console/bff/models/portfolios/enhancedPortfolioPerformance.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { CHART } from 'config/styling/chart';
import { portfolioPerformanceEntity } from 'state/entities/portfolioMgmt/portfolioPerformance';
import { observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import LabelWithValue from 'views/common/layout/LabelWithValue';
import { EntityWrapperNoExtraObserve } from 'views/common/widget/EntityWrapper';
import ContentTitle from 'views/common/layout/ContentTitle';

const DEFAULT_PORTFOLIO_STATUS: IPortfolioPerformanceStatus = {
    currentValue: 0,
    initialInvestment: 0,
    withdrawals: 0,
    additionalInvestment: 0,
};

interface IPublicProps {
    baseCurrency: string;
}

const useStyles = makeStyles((theme) => ({
    PortfolioPerformanceStatus: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'flex-start' }),
        marginTop: theme.spacing(2),

        '& .__left': {
            ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'center', wrap: 'wrap' }),
        },
        '& .__left > div': {
            marginRight: theme.spacing(4),
            marginTop: theme.spacing(1),
        },
        '& .__right': {
            ...mixins.flexRow({ alignMain: 'flex-end', alignCross: 'center', wrap: 'wrap' }),
        },
        '& .__right > div': {
            marginLeft: theme.spacing(4),
            marginTop: theme.spacing(1),

            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-end',
            },
        },
    },
}));

function PortfolioPerformanceStatus({ baseCurrency }: IPublicProps) {
    const classes = useStyles();

    return (
        <>
            <ContentTitle label="portfolio_mgmt.portfolios.detail.status.title" />

            <EntityWrapperNoExtraObserve
                asyncEntitySelector={portfolioPerformanceEntity.select}
                renderData={renderPortfolioPerformanceStatus}
            />
        </>
    );

    function renderPortfolioPerformanceStatus({ data }: { data: IEnhancedPortfolioPerformance }) {
        const isCurrentValueSet = isSet(data?.past?.status?.currentValue);
        const {
            currentValue, initialInvestment,
            withdrawals, additionalInvestment,
        } = data?.past?.status || DEFAULT_PORTFOLIO_STATUS;

        return (
            <div className={classes.PortfolioPerformanceStatus}>
                <div className="__left">
                    <LabelWithValue
                        label="portfolio_mgmt.portfolios.detail.status.current_value"
                        value={isCurrentValueSet ? formatPortfolioAmount(currentValue) : '-'}
                        emphasizeValue={CHART.PAST.VALUE_COLOR}
                    />
                </div>

                <div className="__right">
                    <LabelWithValue
                        label="portfolio_mgmt.portfolios.detail.status.initial_investment"
                        value={formatPortfolioAmount(initialInvestment)}
                    />
                    <LabelWithValue
                        label="portfolio_mgmt.portfolios.detail.status.additional_investment"
                        value={formatPortfolioAmount(additionalInvestment)}
                    />
                    <LabelWithValue
                        label="portfolio_mgmt.portfolios.detail.status.withdrawals"
                        value={formatPortfolioAmount(withdrawals)}
                    />
                </div>
            </div>
        );
    }

    function formatPortfolioAmount(amount: number) {
        return formatAmount(amount, { currency: baseCurrency });
    }
}

export default observe<IPublicProps>(
    [StateChangeNotification.PORTFOLIO_PERFORMANCE_PAST_DATA],
    PortfolioPerformanceStatus,
);
