import isSet from '@snipsonian/core/cjs/is/isSet';
import { IPathParams, IRouteLocation } from 'models/routing.models';
import { ROUTE_KEY } from 'views/routeKeys';

export interface IVerifyRouteLocationExpected {
    routeKey?: ROUTE_KEY;
    isPrevRouteKeySameAsCurrent?: boolean;
    prevRouteKey?: ROUTE_KEY;
    params?: IPathParams;
    prevParams?: IPathParams;
    paramsSameAsPrevRoute?: string[];
}

export const verifyRouteLocation = ({
    currentRouteLocation,
    prevRouteLocation,
    expected,
}: {
    currentRouteLocation?: IRouteLocation;
    prevRouteLocation?: IRouteLocation;
    expected: IVerifyRouteLocationExpected;
}): boolean => {
    if (isSet(expected.routeKey)) {
        if (currentRouteLocation.routeKey !== expected.routeKey) {
            return false;
        }
    }

    if (isSet(expected.isPrevRouteKeySameAsCurrent)) {
        const isSame = (currentRouteLocation.routeKey === (prevRouteLocation && prevRouteLocation.routeKey));

        if (isSame !== expected.isPrevRouteKeySameAsCurrent) {
            return false;
        }
    }

    if (isSet(expected.prevRouteKey)) {
        if ((prevRouteLocation && prevRouteLocation.routeKey) !== expected.prevRouteKey) {
            return false;
        }
    }

    if (isSet(expected.params)) {
        if (!currentRouteLocation.params) {
            return false;
        }

        const isAtLeastOneParamNotAsExpected = Object.keys(expected.params)
            .some((paramKey) => expected.params[paramKey] !== currentRouteLocation.params[paramKey]);
        if (isAtLeastOneParamNotAsExpected) {
            return false;
        }
    }

    if (isSet(expected.prevParams)) {
        if (!prevRouteLocation || !prevRouteLocation.params) {
            return false;
        }

        const isAtLeastOneParamNotAsExpected = Object.keys(expected.prevParams)
            .some((paramKey) => expected.prevParams[paramKey] !== prevRouteLocation.params[paramKey]);
        if (isAtLeastOneParamNotAsExpected) {
            return false;
        }
    }

    if (isSet(expected.paramsSameAsPrevRoute)) {
        if (!currentRouteLocation.params || !(prevRouteLocation && prevRouteLocation.params)) {
            return false;
        }

        const isAtLeastOneParamNotSameAsPreviousRoute = expected.paramsSameAsPrevRoute
            .some((paramKey) =>
                (currentRouteLocation.params[paramKey] || 'paramNotOnCurrent')
                !== (prevRouteLocation.params[paramKey] || 'paramNotOnPrev'));
        if (isAtLeastOneParamNotSameAsPreviousRoute) {
            return false;
        }
    }

    return true;
};
