import React from 'react';
import isString from '@snipsonian/core/cjs/is/isString';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IRouteBreadcrumb, TBreadcrumbTranslationPlaceholdersSelector } from 'models/route.models';
import { IAppShellConfig } from 'models/state/ui.models';
import { getCurrentRouteAppShellConfigEnhanced, getCurrentRouteBreadcrumbsMemoized } from 'state/ui/selectors';
import { IObserveProps, observe } from 'views/observe';
import { ROUTE_KEY } from 'views/routeKeys';
import Breadcrumbs, { IBreadcrumb } from 'views/common/widget/Breadcrumbs';
import { getTranslator, getTranslatorIfNeeded } from 'state/i18n/selectors';

function RouteBreadcrumbs({ state }: IObserveProps) {
    const { routesParentFirst, pathParams } = getCurrentRouteBreadcrumbsMemoized(state);
    const appShellConfig = getCurrentRouteAppShellConfigEnhanced(state);
    const translator = getTranslator(state);
    const translatorIfNeeded = getTranslatorIfNeeded(state);
    const executeTranslationPlaceholdersSelector = (
        selector: TBreadcrumbTranslationPlaceholdersSelector,
    ) => selector({ pathParams, state, translator, translatorIfNeeded });

    if (!appShellConfig
        || !(appShellConfig as IAppShellConfig).showBreadcrumbs
        || !routesParentFirst
        || routesParentFirst.length === 0
    ) {
        return null;
    }

    const homeRouteBreadcrumb: IBreadcrumb = {
        route: {
            routeKey: ROUTE_KEY.R_HOME,
        },
        label: 'main.home.breadcrumb',
    };

    const routeBreadcrumbs: IBreadcrumb[] = [homeRouteBreadcrumb].concat(routesParentFirst.map((route, index) => {
        const isCurrentRoute = index === routesParentFirst.length - 1;
        const { routeKey, breadcrumb } = route;
        const label = breadcrumb
            ? getBreadcrumbLabel({ breadcrumb, isCurrentRoute, state })
            : routeKey;
        const labelPlaceholders = (breadcrumb && breadcrumb.translationPlaceholdersSelector)
            ? executeTranslationPlaceholdersSelector(
                breadcrumb.translationPlaceholdersSelector,
            )
            : {};

        return {
            route: !isCurrentRoute && {
                routeKey,
                params: pathParams,
            },
            label: {
                msg: label,
                placeholders: labelPlaceholders,
            },
            shouldHighlightBreadcrumb: isCurrentRoute,
        };
    }));

    return (
        <Breadcrumbs
            breadcrumbs={routeBreadcrumbs}
        />
    );
}

export function initRouteBreadcrumbs({
    notifications,
}: { notifications: StateChangeNotification[] }) {
    return observe(
        [StateChangeNotification.UI_ROUTE, StateChangeNotification.UI_APP_SHELL_CONFIG].concat(notifications),
        RouteBreadcrumbs,
    );
}

function getBreadcrumbLabel({
    breadcrumb,
    isCurrentRoute,
    state,
}: {
    breadcrumb: IRouteBreadcrumb;
    isCurrentRoute: boolean;
    state: IState;
}): string {
    const translationKey = (isCurrentRoute && breadcrumb.translationKeyIfCurrentRoute)
        ? breadcrumb.translationKeyIfCurrentRoute
        : breadcrumb.translationKey;

    if (isString(translationKey)) {
        return translationKey;
    }

    return translationKey({ state });
}
