import addItemAndGetResultingArray, {
    IAddOrRemoveItemsFromArrayCommonOptions,
} from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import removeItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/removeItemAndGetResultingArray';

export function addOrRemoveItemFromArray<Item = unknown>(
    condition: boolean,
    arr: Item[],
    item: Item,
    {
        resultInNewArray = false,
    }: IAddOrRemoveItemsFromArrayCommonOptions = {},
): Item[] {
    if (condition) {
        return addItemAndGetResultingArray<Item>(arr, item, { resultInNewArray });
    }
    return removeItemAndGetResultingArray<Item>(arr, item, { resultInNewArray });
}
