import React, { useState, useEffect } from 'react';
import { observe, IObserveProps } from 'views/observe';
import ContentTitle from 'views/common/layout/ContentTitle';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { getStoryManagerEditorMode } from 'state/ui/storyManager.selector';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { redirectToStoryManagerFindAndReplaceAndResetView } from 'state/ui/storyManager.actions';
import {
    hasAsyncOperationFailed,
    hasAsyncOperationSucceeded,
    isUpdateBusy,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import {
    storyManagerDatabaseDetailEntity, triggerPatchStoryManagerConfig,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import TextButton from 'views/common/buttons/TextButton';
import IconButton from 'views/common/buttons/IconButton';
import { FindAndReplaceIcon } from 'views/common/icons';
import { JSONPatchModels } from '@console/bff/models/storyteller/storymanager.models';
import {
    canUserModifyStoryManagerScenarios,
    canUserModifyStoryManagerTextOutputs,
} from 'state/auth/apiEntityAuthorization.selectors';
import { triggerFlashSuccess } from 'state/ui/flashMessages.actions';
import { getStore } from 'state';
import { calculateOutputResults, calculateScenarioResults, IOutputKeyResults } from './resultCalculations';
import Results from './Results';
import SearchInput from './SearchInput';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.find_and_replace.side_content';

const useStyles = makeStyles((theme) => ({
    UpdateError: {
        padding: theme.spacing(3),

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),

        textAlign: 'center',
    },
}));

function SideContent({ state }: IObserveProps) {
    const classes = useStyles();

    const [findValue, setFindValue] = useState('');
    const [replaceValue, setReplaceValue] = useState('');
    const [results, setResults] = useState<IOutputKeyResults>({});

    const editorMode = getStoryManagerEditorMode(state);
    const isUpdating = isUpdateBusy(storyManagerDatabaseDetailEntity.select());
    const hasUpdateError = hasAsyncOperationFailed(
        storyManagerDatabaseDetailEntity.select(),
        AsyncOperation.update,
    );
    const hasUpdateSucceeded = hasAsyncOperationSucceeded(
        storyManagerDatabaseDetailEntity.select(),
        AsyncOperation.update,
    );

    const canUserModifyScenarios = canUserModifyStoryManagerScenarios(state);
    const canUserModifyTextOutput = canUserModifyStoryManagerTextOutputs(state);
    const hasAccessToReplace = canReplaceForSelectedEditorMode();

    useEffect(() => {
        setResults({});
    }, [editorMode]);

    useEffect(() => {
        refreshResults(findValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUpdateSucceeded]);

    if (hasUpdateError) {
        return (
            <div className={classes.UpdateError}>
                <Translate msg="apps.story_teller.output_keys.error.out_of_sync_error" />
                <TextButton
                    id="find-and-replace-refresh"
                    label="apps.story_teller.output_keys.error.out_of_sync_error_confirm"
                    onClick={() => window.location.reload()}
                    size="S"
                />
            </div>
        );
    }

    return (
        <div>
            <ContentTitle
                variant="section-small"
                label={{ msg: `${TRANSLATION_PREFIX}.title` }}
            />
            <SearchInput
                onSearch={onSearch}
                readonly={!hasAccessToReplace}
                replaceInputActions={(
                    <IconButton
                        id="find-and-replace-all"
                        icon={<FindAndReplaceIcon />}
                        size="S"
                        onClick={replaceAllResults}
                        disabled={isReplaceAllDisabled()}
                        tooltip={!hasAccessToReplace ? 'common.action.insufficient_permissions' :
                            `${TRANSLATION_PREFIX}.fields.replace.replace_all.tooltip`}
                        askConfirmation={{
                            titleLabel: `${TRANSLATION_PREFIX}.fields.replace.replace_all.warning.title`,
                            confirmLabel: `${TRANSLATION_PREFIX}.fields.replace.replace_all.warning.confirm`,
                            messageLabel: {
                                msg: `${TRANSLATION_PREFIX}.fields.replace.replace_all.warning.description`,
                                placeholders: { replaceValue, findValue },
                                raw: true,
                            },
                        }}
                    />
                )}
                setReplaceValue={setReplaceValue}
            />
            <Divider />
            <Results
                replaceValue={replaceValue}
                results={results}
                readonly={!hasAccessToReplace}
            />
        </div>
    );

    function onSearch(search: string) {
        if (!search) {
            setResults({});
            redirectToStoryManagerFindAndReplaceAndResetView();
        } else {
            refreshResults(search);
            setFindValue(search);
        }
    }

    function refreshResults(value: string) {
        const newResults = editorMode === 'text' ?
            calculateOutputResults({ findValue: value }) :
            calculateScenarioResults({ findValue: value });
        setResults(newResults);
    }

    function replaceAllResults() {
        const jsonPatch = Object.keys(results).reduce(
            (acc, outputKeyId) => {
                const data = results[outputKeyId];
                data.results.forEach((result) => {
                    result.jsonPatchOperationsGetter(replaceValue).forEach((op) => acc.push(op));
                });
                return acc;
            },
            [] as JSONPatchModels.Operation[],
        );
        triggerPatchStoryManagerConfig({
            jsonPatch,
            onSuccess: () => {
                getStore().dispatch( // eslint-disable-next-line max-len
                    triggerFlashSuccess({ translationKey: 'apps.story_teller.output_keys.find_and_replace.flash_messages.replace_success' }),
                );
            },
        });
    }

    function canReplaceForSelectedEditorMode() {
        if (editorMode === 'scenario') {
            return canUserModifyScenarios;
        }
        if (editorMode === 'text') {
            return canUserModifyTextOutput;
        }
        return false;
    }

    function isReplaceAllDisabled() {
        return !replaceValue
            || Object.keys(results).length === 0
            || isUpdating
            || !hasAccessToReplace;
    }
}

export default observe(
    [
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
    ],
    SideContent,
);
