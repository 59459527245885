import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { SIZES } from 'config/styling/sizes';
import { getCurrentRouteAppShellConfigEnhanced, getCurrentRouteConfig, shouldShowAppShell } from 'state/ui/selectors';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import { initRouteBreadcrumbs } from 'views/appShell/routing/RouteBreadcrumbs';
import { IAppShellConfig } from 'models/state/ui.models';

const useStyles = makeStyles((theme) => ({
    AppBodyTop: {
        ...mixins.flexCol({ wrap: 'nowrap' }),
        ...mixins.widthMax(),
    },
    appBodyOffset: theme.mixins.toolbar,
    appBodyHeader: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
        padding: theme.spacing(1, 3),
        minHeight: SIZES.BODY.HEADER_HEIGHT,

        '& > div:only-child': {
            marginLeft: 'auto',
        },
    },
}));

function AppBodyTop({
    state,
}: IObserveProps) {
    const classes = useStyles();

    if (!shouldShowAppShell(state)) {
        return null;
    }

    const { showBreadcrumbs, padWithHeader } = getCurrentRouteAppShellConfigEnhanced(state) as IAppShellConfig;

    const { breadcrumb } = getCurrentRouteConfig(state);
    const extraBreadcrumbsNotifications: StateChangeNotification[] = breadcrumb
        ? breadcrumb.extraNotifications
        : [];

    const RouteBreadcrumbs = initRouteBreadcrumbs({
        notifications: extraBreadcrumbsNotifications,
    });

    return (
        <div className={classes.AppBodyTop}>
            <div className={classes.appBodyOffset} />
            {(padWithHeader || showBreadcrumbs) && (
                <div className={classes.appBodyHeader}>
                    <RouteBreadcrumbs />
                </div>
            )}
        </div>
    );
}

export default observe(
    [StateChangeNotification.UI_APP_SHELL_CONFIG, StateChangeNotification.UI_ROUTE_KEY],
    AppBodyTop,
);
