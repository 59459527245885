import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import {
    OPERATION_PERMISSION_KEY,
    ALL_OPERATION_PERMISSION_KEYS,
} from '@console/core-api/config/operationPermissionKeys';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getUserPermissions } from 'state/auth/selectors';
import { updateUserPermission } from 'state/auth/actions';
import { observe, IObserveProps } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import IconButton from 'views/common/buttons/IconButton';
import Modal from 'views/common/layout/Modal';
import InputCheckboxField, { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';

const SHOW_DEV_PANEL = process.env.NODE_ENV === 'development';

interface IPublicProps {
    className?: string;
}

const useStyles = makeStyles((/* theme */) => ({
    mockUserPermissions: {
        ...mixins.flexColTopLeft(),

        '& .checkboxWrapper': {
            ...mixins.flexRowCenterLeft(),
        },
    },
}));

function DeveloperPanel({ className, state, dispatch }: IPublicProps & IObserveProps) {
    const [isDevPanelOpen, setIsDevPanelOpen] = useState<boolean>(false);
    const classes = useStyles();

    if (!SHOW_DEV_PANEL) {
        return null;
    }

    const userPermissions = getUserPermissions(state);

    return (
        <div className={className}>
            <IconButton
                id="open-dev-panel"
                icon={<DeveloperModeIcon />}
                onClick={openDevPanel}
                variant="bare"
                color="primary"
                size="S"
                // svgSize={24}
                isCircle
            />
            <Modal
                id="dev-panel-modal"
                title={{
                    text: 'Developer panel (only localhost)',
                    shouldTranslate: false,
                }}
                open={isDevPanelOpen}
                onClose={closeDevPanel}
            >
                <Typography variant="h5">Mock user permissions:</Typography>

                <div className={classes.mockUserPermissions}>
                    {ALL_OPERATION_PERMISSION_KEYS.map((permissionKey) => {
                        const key = `dev-panel-permission-key_${permissionKey}`;

                        return (
                            <div
                                key={key}
                                className="checkboxWrapper"
                            >
                                <InputCheckboxField<OPERATION_PERMISSION_KEY>
                                    id={key}
                                    className="permissionCheckbox"
                                    name={permissionKey}
                                    checked={userPermissions[permissionKey]}
                                    onChange={mockUserPermission}
                                    description={{
                                        text: permissionKey,
                                        shouldTranslate: false,
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </div>
    );

    function openDevPanel() {
        setIsDevPanelOpen(true);
    }

    function closeDevPanel() {
        setIsDevPanelOpen(false);
    }

    function mockUserPermission({ name, checked }: IOnChangeCheckboxProps<OPERATION_PERMISSION_KEY>) {
        dispatch(updateUserPermission({
            permission: name,
            enabled: checked,
        }));
    }
}

export default observe(
    [StateChangeNotification.AUTH_USER],
    DeveloperPanel,
);
