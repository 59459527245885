import React from 'react';
import clsx from 'clsx';
import Text from 'views/common/widget/Text';
import { makeStyles, mixins } from 'views/styling';
import { APP_COLORS } from 'config/styling/colors';

const LABEL_PREFIX = 'mgmt_reporting.generic';

type TMgmtReportingNoDataProps = {
    mode?: 'no_data_for_period' | 'no_data_at_all';
};

const useStyles = makeStyles((theme) => ({
    MgmtReportingNoData: {
        ...mixins.widthMax(),
        color: APP_COLORS.FEEDBACK.WARNING,
        padding: theme.spacing(1, 0, 2),
    },
}));

export function MgmtReportingNoData({
    mode = 'no_data_for_period',
}: TMgmtReportingNoDataProps) {
    const classes = useStyles();

    const label_suffix = mode === 'no_data_for_period'
        ? 'no_report_data_available_for_period'
        : 'no_report_data_available';

    return (
        <div className={clsx(classes.MgmtReportingNoData, '__noData')}>
            <Text label={`${LABEL_PREFIX}.${label_suffix}`} />
        </div>
    );
}
