import React, { useEffect } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { TAppMenuStatus } from 'config/menu.config';
import { SIZES } from 'config/styling/sizes';
import { MENU_COLORS } from 'config/styling/colors';
import { DURATION_IN_SECS } from 'config/styling/animation';
import {
    getAllowedAppMenuItemsMemoized,
    getAppMenuStatus,
    getSelectedAppMenuItem,
    getSelectedBreadcrumbAppMenuItemIdsMemoized,
    getActiveBreadcrumbAppMenuItemIdsMemoized,
    shouldShowAppShell, getActiveAppMenuItemMemoized,
} from 'state/ui/selectors';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import { toIdSelector } from 'utils/dom/selectorUtils';
import MenuHeader from './MenuHeader';
import { TopMenu } from './TopMenu';
import ContextMenu from './ContextMenu';
import AppFooter from '../AppFooter';

const APP_MENU_ID = 'app-menu';
let prevAppMenuStatus: TAppMenuStatus;

/**
 * Menu behaviour:
 * - same look&feel for desktop and tablets (potential mobile phones differences we will do later)
 * - the 'collapse preference of the user' is remembered
 *   > e.g. if the menu is collapsed, but the user clicks on an item where he/she has to choose between sub-items,
 *     then we expand the menu automatically, and collapse it again automatically after he/she has chosen the
 *     navigation item
 * - the active navigation item is in 'blue'
 * - the active content page remains until another navigation item is selected
 *   > this can mean that, temporarily, the user does not see an active/blue item in the menu
 *   > if the user does not select an item, and clicks outside the menu, then the menu should revert back so that
 *     the active menu item is shown again
 * - when first selecting the top item, and then the lower context item, we keep showing the top part so that users
 *   can easily get a feel for the whole site content (good for 'user exploration')
 */

const useStyles = makeStyles((/* theme */) => ({
    menuTop: {
        ...mixins.typo({ size: 16 }),
        flexShrink: 0,
        width: SIZES.MENU.TOP_WIDTH,
    },
    menuShiftContext: {
        width: SIZES.MENU.CONTEXT_WIDTH,
    },
    menuShiftFull: {
        width: SIZES.MENU.TOP_WIDTH + SIZES.MENU.CONTEXT_WIDTH,
    },
    menuShiftCollapsed: {
        width: SIZES.MENU.COLLAPSED_WIDTH,
    },
    drawerPaper: {
        color: MENU_COLORS.TITLE.TEXT,
        backgroundColor: MENU_COLORS.TOP.BACKGROUND,
    },
    row: {
        ...mixins.flexRow(),
        ...mixins.heightMax(),
        marginBottom: '-76px', /* otherwise the context menu is not until the bottom */
    },
}));

function AppMenu({ state }: IObserveProps) {
    const classes = useStyles();
    const appMenuStatus = getAppMenuStatus(state);
    const selectedMenuItem = getSelectedAppMenuItem(state);
    const selectedBreadcrumbMenuItemIds = getSelectedBreadcrumbAppMenuItemIdsMemoized(state);
    const activeMenuItem = getActiveAppMenuItemMemoized(state);
    const activeBreadcrumbMenuItemIds = getActiveBreadcrumbAppMenuItemIdsMemoized(state);
    const allowedMenuItems = getAllowedAppMenuItemsMemoized(state);

    useEffect(
        () => {
            if (!prevAppMenuStatus) {
                prevAppMenuStatus = appMenuStatus;
            }

            animateMenuShift();

            prevAppMenuStatus = appMenuStatus;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appMenuStatus],
    );

    if (!shouldShowAppShell(state)) {
        return null;
    }

    const mainMenuItem = selectedMenuItem || activeMenuItem;

    const isCollapsed = ['collapsed'].includes(appMenuStatus);
    const showTopMenu = ['top', 'full'].includes(appMenuStatus)
        || !mainMenuItem || (isCollapsed && mainMenuItem.level === 'top');
    const showContextMenu = ['context', 'full'].includes(appMenuStatus)
        || (isCollapsed && mainMenuItem && mainMenuItem.level === 'context');

    return (
        <Drawer
            id={APP_MENU_ID}
            className={clsx(classes.menuTop, {
                [classes.menuShiftContext]: appMenuStatus === 'context',
                [classes.menuShiftFull]: appMenuStatus === 'full',
                [classes.menuShiftCollapsed]: appMenuStatus === 'collapsed',
            })}
            variant="persistent"
            anchor="left"
            open
            classes={{
                paper: clsx(classes.drawerPaper, classes.menuTop, {
                    [classes.menuShiftContext]: appMenuStatus === 'context',
                    [classes.menuShiftFull]: appMenuStatus === 'full',
                    [classes.menuShiftCollapsed]: appMenuStatus === 'collapsed',
                }),
            }}
        >
            <MenuHeader
                isTopMenuShown={showTopMenu}
                mainMenuItem={mainMenuItem}
            />

            <Divider />

            <div className={classes.row}>
                <TopMenu
                    allowedMenuItems={allowedMenuItems}
                    showTopMenu={showTopMenu}
                    isCollapsed={isCollapsed}
                    activeBreadcrumbMenuItemIds={activeBreadcrumbMenuItemIds}
                    selectedBreadcrumbMenuItemIds={selectedBreadcrumbMenuItemIds}
                />

                <ContextMenu
                    isCollapsed={isCollapsed}
                    showContextMenu={showContextMenu}
                    mainMenuItem={mainMenuItem}
                    allowedMenuItems={allowedMenuItems}
                    activeBreadcrumbMenuItemIds={activeBreadcrumbMenuItemIds}
                    selectedBreadcrumbMenuItemIds={selectedBreadcrumbMenuItemIds}
                />
            </div>

            <AppFooter />
        </Drawer>
    );

    function animateMenuShift() {
        if (document.getElementById(APP_MENU_ID)) {
            const fromWidth = determineMenuShiftWidth(prevAppMenuStatus);
            const toWidth = determineMenuShiftWidth(appMenuStatus);

            gsap.fromTo(toIdSelector(APP_MENU_ID), { width: fromWidth }, {
                width: toWidth,
                duration: DURATION_IN_SECS.MENU_SHIFT,
            });
            gsap.fromTo('.MuiDrawer-paper', { width: fromWidth }, {
                width: toWidth,
                duration: DURATION_IN_SECS.MENU_SHIFT,
            });
        }
    }
}

export default observe(
    [
        StateChangeNotification.AUTH_USER,
        StateChangeNotification.UI_ROUTE_KEY,
        StateChangeNotification.UI_APP_MENU,
        StateChangeNotification.UI_APP_SHELL_CONFIG,
        StateChangeNotification.APP_CONFIG_TENANT,
    ],
    AppMenu,
);

function determineMenuShiftWidth(appMenuStatus: TAppMenuStatus) {
    if (appMenuStatus === 'context') {
        return SIZES.MENU.CONTEXT_WIDTH;
    }
    if (appMenuStatus === 'full') {
        return SIZES.MENU.TOP_WIDTH + SIZES.MENU.CONTEXT_WIDTH;
    }
    if (appMenuStatus === 'collapsed') {
        return SIZES.MENU.COLLAPSED_WIDTH;
    }
    return SIZES.MENU.TOP_WIDTH;
}
