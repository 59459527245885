import React from 'react';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { DEFAULT_POLICY_TAB_KEY } from 'config/tabs.config';
import { IDataItem } from 'models/list.models';
import GenericPoliciesList, { IDefaultPolicyCols } from './GenericPoliciesList';

export default function PoliciesList() {
    return (
        <GenericPoliciesList
            box={{
                title: 'portfolio_mgmt.policies.title',
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_POLICY_ADD,
                },
                tooltip: 'portfolio_mgmt.policies.list.actions.create_policy',
            }}
            onPolicyRowClicked={openPolicyDetail}
        />
    );

    function openPolicyDetail({
        id,
    }: IDataItem<IDefaultPolicyCols>) {
        redirectTo({
            routeKey: ROUTE_KEY.R_POLICY_DETAIL,
            params: {
                policyId: id,
                policyTab: DEFAULT_POLICY_TAB_KEY,
            },
        });
    }
}
