import getLocalDvlpConfig from '@snipsonian/dvlp/cjs/storedConfig/getLocalDvlpConfig';
import { BASE_BROWSER_STORAGE_KEY } from 'config/browserStorage.config';

const DEFAULT_DEV_CONFIG = {
    ENABLE_API_LOGGING: false,
    ENABLE_STATE_LOGGING: false,
    ENABLE_STATE_STORAGE: true,
};

const devConfig = getLocalDvlpConfig<typeof DEFAULT_DEV_CONFIG>({
    storageKey: `${BASE_BROWSER_STORAGE_KEY}_DVLP`,
    defaultDevConfig: DEFAULT_DEV_CONFIG,
});

export const isApiLoggingEnabled = devConfig.ENABLE_API_LOGGING as boolean;
export const isStateLoggingEnabled = devConfig.ENABLE_STATE_LOGGING as boolean;
export const isStateStorageEnabled = devConfig.ENABLE_STATE_STORAGE as boolean;
