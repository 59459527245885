import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getRoboMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { triggerFetchMgmtReportsRobo } from 'state/entities/mgmtReporting/triggerFetchMgmtReportsGeneric';
import { updateRoboMgmtReportingPageVars } from 'state/ui/uiPages.actions';
import { IObserveProps, observe } from 'views/observe';
import MgmtReportingFilterBlock from '../blocks/MgmtReportingFilterBlock';

function RoboMgmtReportingFilterBlock({ dispatch, state }: IObserveProps) {
    const { period } = getRoboMgmtReportingPageVars(state);

    return (
        <MgmtReportingFilterBlock
            currentPeriod={period}
            updatePeriodData={(currentPeriod) => {
                dispatch(
                    updateRoboMgmtReportingPageVars({
                        periodType: currentPeriod.type,
                    }),
                );

                triggerFetchMgmtReportsRobo({ forceRefresh: true });
            }}
        />
    );
}

export default observe(
    [StateChangeNotification.MGMT_REPORTING_ROBO_UI_VARS],
    RoboMgmtReportingFilterBlock,
);
