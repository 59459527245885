import flatten from 'flat';
import produce from 'immer';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { ITranslationsPerLocale } from '@snipsonian/react/cjs/components/i18n/translations/types';
import createTranslationsManager from '@snipsonian/react/cjs/components/i18n/translations/createTranslationsManager';
import generateTranslatorForEachSupportedLocale
    from '@snipsonian/react/cjs/components/i18n/translator/generateTranslatorForEachSupportedLocale';
import { reportErrorMessage } from '@typsy/sentry-react/dist/reporting/reportErrorReact';
import { Locale, CONSOLE_LOCALES, TRANSLATION_KEY_DELIMITER } from '@console/common/config/i18n.config';
import { ENVIRONMENT } from 'utils/env/environment';
import { isTranslationKey } from 'utils/i18n/i18nUtils';

import appShellEnUS from './appShell/translations/en-US.yml';
import appsEnUs from './apps/translations/en-US.yml';
import authEnUS from './auth/translations/en-US.yml';
import documentationEnUS from './documentation/translations/en-US.yml';
import mainEnUS from './main/translations/en-US.yml';
import mgmtReportingEnUS from './mgmtReporting/translations/en-US.yml';
import portfolioMgmtEnUS from './portfolioMgmt/translations/en-US.yml';
import userMgmtEnUS from './userMgmt/translations/en-US.yml';
import suitabilityProfilerEnUS from './suitabilityProfiler/translations/en-US.yml';
import thematicSearchEnUS from './thematicSearch/translations/en-US.yml';

/**
 * TODO once there are lots of locales + lots of translations:
 * - mechanism so that the translations are not all bundled
 * - via dynamic import?
 */

const shippedTranslations: ITranslationsPerLocale = {
    [Locale.en_US]: {
        ...flatten(appShellEnUS),
        ...flatten(appsEnUs),
        ...flatten(authEnUS),
        ...flatten(documentationEnUS),
        ...flatten(mainEnUS),
        ...flatten(mgmtReportingEnUS),
        ...flatten(portfolioMgmtEnUS),
        ...flatten(userMgmtEnUS),
        ...flatten(suitabilityProfilerEnUS),
        ...flatten(thematicSearchEnUS),
    },
};

const ENVS_TO_SHOW_THE_LAST_MSG_KEY_PART_WHEN_TRANSLATION_NOT_FOUND: TConsoleEnv[] =
    ['test', 'acceptance', 'production'];

export const translationsManager = createTranslationsManager({
    locales: CONSOLE_LOCALES,
    initialTranslations: shippedTranslations,
    onTranslationNotFound: ({ locale, msgKey }) => {
        const translationIssue = isTranslationKey(msgKey)
            ? `Missing translation for locale "${locale}": [${msgKey}]`
            : `Unneeded translation for locale "${locale}": [${msgKey}]`;

        console.log(`===== ${translationIssue} =====`);

        if (shouldReportMissingTranslation({ locale, msgKey })) {
            reportErrorMessage(translationIssue, {
                extra: {
                    locale,
                    msgKey,
                },
                groupingFingerprint: [
                    translationIssue,
                ],
            });
        }
    },
    returnWhenTranslationNotFound: ({ msgKey }) => {
        if (!isTranslationKey(msgKey)) {
            /* this was a regular text that should not have been tried for translation --> just return as is */
            return msgKey;
        }

        if (ENVS_TO_SHOW_THE_LAST_MSG_KEY_PART_WHEN_TRANSLATION_NOT_FOUND.indexOf(ENVIRONMENT) > -1) {
            return msgKey.split(TRANSLATION_KEY_DELIMITER).pop();
        }

        /* on the lower envs we show the entire message key so that they really stand out */
        return msgKey;
    },
});

generateTranslatorForEachSupportedLocale({
    translationsManager,
});

export function overrideTranslationsIfAny(overriddenTranslations: ITranslationsPerLocale): boolean {
    let didOverride = false;

    if (overriddenTranslations) {
        Object.keys(overriddenTranslations)
            .filter((locale) => CONSOLE_LOCALES.indexOf(locale) > -1)
            .forEach((locale) => {
                const overriddenTranslationsOfLocale = overriddenTranslations[locale];

                if (overriddenTranslationsOfLocale) {
                    translationsManager.setTranslationsOfLocale({
                        locale,
                        translations: produce(
                            translationsManager.getTranslationsOfLocale({ locale }),
                            (draftTranslationsOfLocale) => {
                                Object.keys(overriddenTranslationsOfLocale)
                                    .forEach((translationKey) => {
                                        // eslint-disable-next-line no-param-reassign,max-len
                                        draftTranslationsOfLocale[translationKey] = overriddenTranslationsOfLocale[translationKey];
                                    });
                            },
                        ),
                    });

                    didOverride = true;
                }
            });
    }

    return didOverride;
}

function shouldReportMissingTranslation({
    msgKey,
}: {
    locale: string;
    msgKey: string;
}): boolean {
    if (!isSetString(msgKey)) {
        return false;
    }

    return true;
}
