import isSet from '@snipsonian/core/cjs/is/isSet';
import deepEquals from 'ramda/src/equals';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IMenuItemBase,
} from 'models/state/ui.models';
import { IRouteLocation } from 'models/routing.models';
import { createAction } from 'state';
import {
    getCurrentRouteAppShellConfig,
    getCurrentRouteLocation,
    getPrevRouteLocation,
} from 'state/ui/selectors';
import { getRoute } from 'views/routes';

export const setCurrentRouteLocation = ({
    routeLocation,
}: {
    routeLocation: IRouteLocation;
}) => createAction<{ routeLocation: IRouteLocation }>({
    type: 'SET_CURRENT_ROUTE_LOCATION',
    payload: {
        routeLocation,
    },
    process({ setStateImmutable, getState }) {
        const { routeKey } = routeLocation;
        const routeConfig = getRoute({ routeKey });

        /**
         * On page-refresh, the current route location will be null (as it is not stored in browser storage)
         * so that we get the previous route location (that is being stored in session storage)
         */
        const prevRouteLocation = getCurrentRouteLocation(getState()) || getPrevRouteLocation(getState());

        const newAppShellConfig = routeConfig.appShell;
        const prevAppShellConfig = getCurrentRouteAppShellConfig(getState());

        const isRouteKeyChanged = routeLocation.routeKey !== (prevRouteLocation && prevRouteLocation.routeKey);
        const isAppShellConfigChanged = !deepEquals(newAppShellConfig, prevAppShellConfig);

        setStateImmutable({
            toState: (draftState) => {
                draftState.ui.currentRoute.location = routeLocation;
                draftState.ui.currentRoute.prevLocation = prevRouteLocation;

                if (isAppShellConfigChanged) {
                    draftState.ui.currentRoute.appShellConfig = newAppShellConfig;
                }
            },
            notificationsToTrigger: [StateChangeNotification.UI_ROUTE]
                .concat(isRouteKeyChanged ? [StateChangeNotification.UI_ROUTE_KEY] : [])
                .concat(isAppShellConfigChanged ? [StateChangeNotification.UI_APP_SHELL_CONFIG] : []),
        });
    },
});

export interface ISetSelectedMenuItemPayload {
    selectedItem: IMenuItemBase;
    isSelecting: boolean;
}

export const setSelectedMenuItem = ({
    selectedItem,
    isSelecting,
}: ISetSelectedMenuItemPayload) => createAction<unknown>({
    type: 'SET_SELECTED_MENU_ITEM',
    payload: {
        selectedItem,
        isSelecting,
    },
    process({ setStateImmutable }) {
        setStateImmutable({
            toState: (draftState) => {
                draftState.ui.appMenu.selectedItemId = isSelecting
                    ? selectedItem.id
                    : null;
                draftState.ui.appMenu.isSelecting = isSelecting;
            },
            notificationsToTrigger: [StateChangeNotification.UI_APP_MENU],
        });
    },
});

export const toggleIsUserSelectingMenu = (isSelecting?: boolean) => createAction<unknown>({
    type: 'TOGGLE_IS_USER_SELECTING_MENU',
    payload: {
        isSelecting,
    },
    process({ setStateImmutable }) {
        setStateImmutable({
            toState: (draftState) => {
                draftState.ui.appMenu.isSelecting = isSet(isSelecting)
                    ? isSelecting
                    : !draftState.ui.appMenu.isSelecting;
                draftState.ui.appMenu.selectedItemId = null;
            },
            notificationsToTrigger: [StateChangeNotification.UI_APP_MENU],
        });
    },
});

/**
 * If 'prefersCollapsed' is specified as input, that value will be taken for the 'prefersCollapsed' flag,
 * otherwise the 'prefersCollapsed' value will be automatically toggled from the previous value.
 */
export const toggleUserPrefersCollapsedMenu = (prefersCollapsed?: boolean) => createAction<unknown>({
    type: 'TOGGLE_USER_PREFERS_COLLAPSED_MENU',
    payload: {
        prefersCollapsed,
    },
    process({ setStateImmutable }) {
        setStateImmutable({
            toState: (draftState) => {
                draftState.ui.appMenu.prefersCollapsed = isSet(prefersCollapsed)
                    ? prefersCollapsed
                    : !draftState.ui.appMenu.prefersCollapsed;
            },
            notificationsToTrigger: [StateChangeNotification.UI_APP_MENU],
        });
    },
});
