import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { IDefaultUserLocale } from '@console/core-api/models/api.models';
import {
    IAdvancedPublicInstrumentGroupsFilters,
    IFetchPublicInstrumentGroupsApiInput,
    TPublicInstrumentGroupsData,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { isRouteLocation } from 'state/ui/selectors';
import { getDefaultUserLocaleConfig } from 'state/appConfig/selectors';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';

export const publicInstrumentGroupsEntity = getEntitiesManager().registerEntity<TPublicInstrumentGroupsData>({
    asyncEntityKey: AsyncEntityKeys.publicInstrumentGroups,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUPS_DATA],
    includeUpdaters: true,
});

export const triggerResetPublicInstrumentGroupsFetch = () =>
    publicInstrumentGroupsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchPublicInstrumentGroups({
    forceRefresh = false,
    ...apiInput
}: IFetchPublicInstrumentGroupsApiInput & IForceStateRefreshFilter = {}) {
    // eslint-disable-next-line max-len
    return publicInstrumentGroupsEntity.async.fetch<IFetchPublicInstrumentGroupsApiInput & IDefaultUserLocale>({
        api: api.publicInstrumentGroups.fetchPublicInstrumentGroups,
        // eslint-disable-next-line max-len
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchPublicInstrumentGroupsApiInput & IDefaultUserLocale, IAdvancedPublicInstrumentGroupsFilters>({
            pageKey: UiPageKey.publicInstrumentGroupsList,
            state,
            apiInput: {
                ...apiInput,
                defaultUserLocale: getDefaultUserLocaleConfig(state),
            },
            mapSimpleFilterValue: (simpleInput) => ({
                name: simpleInput,
            }),
            mapAdvancedFilterValues: (filters) => ({
                name: filters.name,
                externalId: filters.externalId,
                category: filters.category,
            }),
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: publicInstrumentGroupsEntity.select().data,
        }),
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_DETAIL,
        }),
        resetDataOnTriggerMode: 'never',
    });
}
