import React from 'react';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { IColValues, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { triggerFetchUsersForSearch } from 'state/entities/userMgmt/users';
import GenericUsersList, {
    DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX,
    getDefaultUsersCols,
} from 'views/userMgmt/shared/GenericUsersList';
import InputRadioField from 'views/common/inputs/base/InputRadioField';
import { TUser } from '@console/core-api/models/userMgmt/user.models';

interface IPublicProps {
    selectedUserId: TApiEntityId;
    initialSelectedUserId?: TApiEntityId;
    onUserSelected: (onUserSelectedProps: IOnUserSelectedProps) => void;
}

export interface IOnUserSelectedProps {
    user: TUser;
}

interface ISearchedUsersCols extends IColValues {
    selected: boolean;
    name: string;
    email: string;
    externalId: string;
}

interface ISearchedUserExtraData {
    userData: TUser;
    selectable: boolean;
    changeSelected: (changeSelectedUserProps: IOnUserSelectedProps) => void;
}

const DEFAULT_USER_COLS = getDefaultUsersCols({
    desiredCols: [
        { colName: 'name', percentWidth: 33 },
        { colName: 'email', percentWidth: 33 },
        { colName: 'externalId', percentWidth: 26 },
    ],
});

const COLS: TDataColumns<ISearchedUsersCols, ISearchedUserExtraData> = {
    selected: {
        label: {
            msg: `${DEFAULT_USERS_LIST_COL_TRANSLATION_PREFIX}.selected`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputRadioField
                    name="select-single-user"
                    value={item.id}
                    selected={cellValue as boolean}
                    onSelected={() => item.extra.changeSelected({
                        user: item.extra.userData,
                    })}
                    disabled={!item.extra.selectable}
                />
            ),
        },
        percentWidth: 8,
    },
    name: DEFAULT_USER_COLS.name,
    email: DEFAULT_USER_COLS.email,
    externalId: DEFAULT_USER_COLS.externalId,
};

export default function GenericUserSelect({
    selectedUserId,
    initialSelectedUserId,
    onUserSelected,
}: IPublicProps) {
    return (
        <GenericUsersList<ISearchedUsersCols, ISearchedUserExtraData>
            overrideEntity={{
                asyncListEntityFetchTrigger: triggerFetchUsersForSearch,
            }}
            overrideUiVars={{
                uiVarsNotification: StateChangeNotification.USER_SEARCH_UI_VARS,
                uiPageKey: UiPageKey.userSearchList,
            }}
            overrideCols={{
                cols: COLS,
                toUserListItem: ({ user, defaultUserCols }) => ({
                    id: user.id,
                    colValues: {
                        selected: user.id === selectedUserId,
                        name: defaultUserCols.name,
                        email: defaultUserCols.email,
                        externalId: defaultUserCols.externalId,
                    },
                    extra: {
                        userData: user,
                        selectable: canUserBeSelected(user.id),
                        changeSelected: ({ user: selectedUser }) => changeSelectedUser(selectedUser),
                    },
                }),
            }}
            onUserRowClicked={(userItem) => changeSelectedUser(userItem.extra.userData)}
            isUserRowClickable={({ id }) => canUserBeSelected(id)}
        />
    );

    function changeSelectedUser(user: TUser) {
        onUserSelected({ user });
    }

    function canUserBeSelected(userId: TApiEntityId) {
        return userId !== initialSelectedUserId;
    }
}
