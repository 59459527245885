import * as policies from './policies/policies.console.bff';
import * as portfolios from './portfolios/portfolios.console.bff';
import * as tenantSettings from './settings/tenantSettings.console.bff';
import * as storyTeller from './storyteller/storyteller.console.bff';
import * as mgmtReporting from './mgmtReporting/mgmtReporting.console.bff';

export const consoleBffClient = {
    policies,
    portfolios,
    tenantSettings,
    storyTeller,
    mgmtReporting,
};
