import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { SortOrder } from '@console/common/models/sort.models';
import { ALL_CURRENCIES } from '@console/common/config/currencies.config';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import {
    PolicyManagerTag,
    PolicyHierarchyLevel,
} from '@console/core-api/models/portfolioMgmt/policy.models';
import {
    PolicyAlgorithmType,
    TEnhancedPolicy,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import {
    TEnhancedPoliciesData,
    TFetchEnhancedPoliciesClientApiInput,
} from '@console/bff/models/policies/enhancedPolicies.models';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { policiesEntity, triggerFetchPolicies } from 'state/entities/portfolioMgmt/policies';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { getPoliciesAlgorithmsConfig } from 'state/appConfig/selectors';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import {
    convertPolicyManagerTagsForDisplay,
    getPolicyAlgorithmLabel,
    getPolicyManagerTagLabel,
} from 'utils/entities/portfolioMgmt/policyUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { UiPageKey } from 'models/state/ui.models';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, {
    IListPageForApiEntityProps, ISortConfigFunctions,
} from 'views/common/list/ListPageForApiEntity';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { IRenderAdvancedWithSchemaFiltersProps } from 'views/common/list/DataSearch';
import InputGroup from 'views/common/inputs/base/InputGroup';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import { PolicyHierarchyLevelIndicator } from 'views/common/icons/indicators';
import { IInputSearchableSelectItem } from 'views/common/inputs/base/InputSearchableSelectField';
import { IAdvancedPoliciesFilters } from 'models/ui/policyList.ui.models';
import { advancedPolicyFiltersClientSideSchema } from 'views/portfolioMgmt/Policies/advancedPolicyFiltersSchema';
import { IFormValues } from 'views/common/inputs/extended/types';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';

export const DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX = 'portfolio_mgmt.policies.list.columns';

const currencySelectItems: IInputSearchableSelectItem[] = ALL_CURRENCIES.map((currency) => ({
    value: currency,
    label: currency,
}));

interface IPublicProps<ColValues extends IColValues, ExtraPolicyItemData extends TObjectWithProps = unknown>
    // eslint-disable-next-line max-len
    extends Pick<IListPageForApiEntityProps<TFetchEnhancedPoliciesClientApiInput, IAdvancedPoliciesFilters, TEnhancedPoliciesData>, 'className' | 'create' | 'actions' | 'box'> {
    overrideEntity?: Partial<IOverrideEntity>;
    overrideUiVars?: IOverrideUiVars;
    overrideCols?: IOverrideCols<ColValues, ExtraPolicyItemData>;
    onPolicyRowClicked?: (policy: IDataItem<ColValues, ExtraPolicyItemData>) => void;
    isPolicyRowClickable?: (policy: IDataItem<ColValues, ExtraPolicyItemData>) => boolean;
    preFilledPolicyAlgorithmTypeFilter?: PolicyAlgorithmType;
}

// eslint-disable-next-line max-len
interface IOverrideEntity extends Pick<IListPageForApiEntityProps<TFetchEnhancedPoliciesClientApiInput, IAdvancedPoliciesFilters, TEnhancedPoliciesData>, 'asyncListEntity' | 'asyncListEntityFetchTrigger' | 'setStateOnPageNrChange'> {
    dataNotification: StateChangeNotification; // default POLICIES_DATA
}

// eslint-disable-next-line max-len
interface IOverrideUiVars extends Pick<IListPageForApiEntityProps<TFetchEnhancedPoliciesClientApiInput, IAdvancedPoliciesFilters, TEnhancedPoliciesData>, 'uiPageKey'> {
    uiVarsNotification: StateChangeNotification; // default POLICIES_UI_VARS
}

interface IOverrideCols<ColValues extends IColValues, ExtraPolicyItemData extends TObjectWithProps> {
    cols: TDataColumns<ColValues, ExtraPolicyItemData>;
    toPolicyListItem: TToPolicyListItem<ColValues, ExtraPolicyItemData>;
}

type TToPolicyListItem<ColValues extends IColValues, ExtraPolicyItemData> =
    (props: IToPolicyListItemProps) => IDataItem<ColValues, ExtraPolicyItemData>;

interface IToPolicyListItemProps {
    policy: TEnhancedPolicy;
    defaultPolicyCols: IDefaultPolicyCols;
}

function toDefaultPolicyListItem({ policy, defaultPolicyCols }: IToPolicyListItemProps): IDataItem<IDefaultPolicyCols> {
    return {
        id: policy.id,
        colValues: defaultPolicyCols,
    };
}

export interface IDefaultPolicyCols extends IColValues {
    name: string;
    baseCurrency: string;
    tags: string;
    updated: string;
    hierarchyLevel: PolicyHierarchyLevel;
    algorithmType: PolicyAlgorithmType;
}

interface IDesiredCol {
    colName: keyof IDefaultPolicyCols;
    percentWidth: number;
}

const DEFAULT_DESIRED_POLICY_COLS: IDesiredCol[] = [
    { colName: 'hierarchyLevel', percentWidth: 5 },
    { colName: 'name', percentWidth: 30 },
    { colName: 'tags', percentWidth: 15 },
    { colName: 'algorithmType', percentWidth: 15 },
    { colName: 'baseCurrency', percentWidth: 15 },
    { colName: 'updated', percentWidth: 20 },
];

export function getDefaultPoliciesCols({
    desiredCols = DEFAULT_DESIRED_POLICY_COLS,
}: {
    desiredCols?: IDesiredCol[];
} = {}): TDataColumns<Partial<IDefaultPolicyCols>> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.name`,
                            },
                            data: {
                                className: UtilityClass.table.cellBold,
                            },
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Ascending,
                                serverSide: {
                                    field: 'name',
                                },
                            },
                        };
                        break;
                    }
                    case 'baseCurrency': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.base_currency`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'tags': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.tags`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'updated': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.updated`,
                            },
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Descending,
                                serverSide: {
                                    field: 'version_datetime',
                                },
                            },
                        };
                        break;
                    }
                    case 'hierarchyLevel': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.hierarchy_level`,
                            },
                            percentWidth,
                            data: {
                                render: ({ item }) => (
                                    <PolicyHierarchyLevelIndicator
                                        policyHierarchyLevel={item.colValues.hierarchyLevel}
                                    />
                                ),
                            },
                        };
                        break;
                    }
                    case 'algorithmType': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_POLICY_LIST_COL_TRANSLATION_PREFIX}.algorithm_type`,
                            },
                            percentWidth,
                            data: {
                                render: ({ item }) => (
                                    <Translate
                                        // eslint-disable-next-line max-len
                                        msg={`portfolio_mgmt.policies.data.algorithm.${item.colValues.algorithmType.toLowerCase()}`}
                                    />
                                ),
                            },
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected col name '${colName}'`);
                }
            }

            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultPolicyCols>>,
    );
}

interface IAdvancedWithSchemaPoliciesFilters extends IAdvancedPoliciesFilters, IFormValues {}

export function getInitialAdvancedPolicyFilters({
    preFilledPolicyAlgorithmTypeFilter,
}: {
    preFilledPolicyAlgorithmTypeFilter?: PolicyAlgorithmType;
} = {}): IAdvancedWithSchemaPoliciesFilters {
    return {
        externalId: '',
        policyName: '',
        riskProfileId: undefined,
        algorithmType: preFilledPolicyAlgorithmTypeFilter || undefined,
        tags: [],
        currencies: [],
        ids: [],
    };
}

export default function GenericPoliciesList<
    ColValues extends IColValues = IDefaultPolicyCols,
    ExtraPolicyItemData extends TObjectWithProps = unknown,
>({
    overrideEntity = {},
    overrideUiVars,
    overrideCols,
    onPolicyRowClicked,
    isPolicyRowClickable,
    preFilledPolicyAlgorithmTypeFilter,
    ...otherListPageProps
}: IPublicProps<ColValues, ExtraPolicyItemData>) {
    const asyncListEntityFetchTrigger = overrideEntity?.asyncListEntityFetchTrigger || triggerFetchPolicies;
    const entityConfig: IOverrideEntity = {
        dataNotification: StateChangeNotification.POLICIES_DATA,
        asyncListEntity: policiesEntity,
        setStateOnPageNrChange: (pageNr) => ({
            toState: (draftState) => {
                // eslint-disable-next-line no-param-reassign
                draftState.entities.policies.data.pageNr = pageNr;
            },
            notificationsToTrigger: [StateChangeNotification.POLICIES_DATA],
        }),
        ...overrideEntity,
        asyncListEntityFetchTrigger: ({ algorithmType, ...otherApiInput }) => asyncListEntityFetchTrigger({
            ...otherApiInput,
            algorithmType: preFilledPolicyAlgorithmTypeFilter || algorithmType,
        }),
    };
    const uiVarsConfig: IOverrideUiVars = overrideUiVars || {
        uiVarsNotification: StateChangeNotification.POLICIES_UI_VARS,
        uiPageKey: UiPageKey.policiesList,
    };

    const colsConfig: IOverrideCols<ColValues, ExtraPolicyItemData> = overrideCols || {
        cols: getDefaultPoliciesCols() as TDataColumns<ColValues>,
        toPolicyListItem: toDefaultPolicyListItem as unknown as TToPolicyListItem<ColValues, ExtraPolicyItemData>,
    };
    const [asyncAllRiskProfiles] = useAsyncFetchOnMount({
        fetcher: apiCacheManager.fetchAllRiskProfiles,
    });

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <ListPageForApiEntity
            notifications={[entityConfig.dataNotification, uiVarsConfig.uiVarsNotification]}

            asyncListEntity={entityConfig.asyncListEntity}
            asyncListEntityFetchTrigger={entityConfig.asyncListEntityFetchTrigger}
            setStateOnPageNrChange={entityConfig.setStateOnPageNrChange}

            uiPageKey={uiVarsConfig.uiPageKey}
            notificationToTriggerOnUiVarChanges={uiVarsConfig.uiVarsNotification}

            search={{
                simple: {
                    tipTranslationKey: 'portfolio_mgmt.policies.list.filter.simple.tip',
                    mapSimpleSearchInputToFetchFilter,
                },
                advancedWithSchema: {
                    initialValues: getInitialAdvancedPolicyFilters({ preFilledPolicyAlgorithmTypeFilter }),
                    preferTheseInitialValuesOverCurrentValues: preFilledPolicyAlgorithmTypeFilter
                        ? ['algorithmType']
                        : null,
                    renderFilters: renderAdvancedPoliciesFilters,
                    schema: advancedPolicyFiltersClientSideSchema,
                },
            }}

            list={{
                renderData: renderPoliciesList,
            }}

            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}

            {...otherListPageProps}
        />
    );

    function renderAdvancedPoliciesFilters({
        fields,
    }: IRenderAdvancedWithSchemaFiltersProps<IAdvancedWithSchemaPoliciesFilters>) {
        const algoItems: IInputSelectItem<string>[] = getPoliciesAlgorithmsConfig().map((value) => ({
            value,
            label: getPolicyAlgorithmLabel(value),
        }));
        const tagItems: IInputSelectItem<PolicyManagerTag>[] = Object.values(PolicyManagerTag).map((value) => ({
            value,
            label: getPolicyManagerTagLabel(value),
        }));

        return (
            <>
                <InputGroup>
                    <ExtendedInputText
                        formField={fields.externalId}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.external_id.label',
                        }}
                        placeholder="portfolio_mgmt.policies.list.filter.advanced.external_id.tip"
                    />
                    <ExtendedInputSearchableSelect<string[]>
                        formField={fields.ids}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.ids.label',
                        }}
                        placeholder="portfolio_mgmt.policies.list.filter.advanced.ids.tip"
                        shouldAllowCustomInput
                        sortItemsByLabel={false}
                    />
                    <ExtendedInputText
                        formField={fields.policyName}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.policy_name.label',
                        }}
                        placeholder="portfolio_mgmt.policies.list.filter.advanced.policy_name.tip"
                    />
                </InputGroup>
                <InputGroup>
                    <ExtendedInputSearchableSelect<string[]>
                        formField={fields.currencies}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.currencies.label',
                        }}
                        placeholder="portfolio_mgmt.policies.list.filter.advanced.currencies.tip"
                        items={currencySelectItems}
                    />
                    <ExtendedInputSelect
                        formField={fields.algorithmType}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.algorithm_type.label',
                        }}
                        addNoDataSelectItem="portfolio_mgmt.policies.list.filter.advanced.algorithm_type.no_data"
                        items={algoItems}
                        itemLabelsAreTranslationKeys
                        disabled={isSet(preFilledPolicyAlgorithmTypeFilter)}
                    />
                    <ExtendedInputSelect
                        formField={fields.riskProfileId}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.risk_profile_id.label',
                        }}
                        addNoDataSelectItem="portfolio_mgmt.policies.list.filter.advanced.risk_profile_id.no_data"
                        items={[]}
                        async={{
                            itemsData: asyncAllRiskProfiles,
                            dataToSelectItemsMapper: mapRiskProfilesToInputSelectItems,
                        }}
                    />
                    <ExtendedInputSearchableSelect<PolicyManagerTag[]>
                        formField={fields.tags}
                        wrapper={{
                            label: 'portfolio_mgmt.policies.list.filter.advanced.tags.label',
                        }}
                        placeholder="portfolio_mgmt.policies.list.filter.advanced.tags.tip"
                        items={tagItems}
                        itemLabelsAreTranslationKeys
                    />
                </InputGroup>
            </>
        );

        function mapRiskProfilesToInputSelectItems(): IInputSelectItem<string>[] {
            return asyncAllRiskProfiles.data.results
                .sort((riskProfileA, riskProfileB) =>
                    anyComparerAscending(riskProfileA.score_range[0], riskProfileB.score_range[0]))
                .map((riskProfile) => ({
                    value: riskProfile.id,
                    label: getDefaultTranslationFromApiLabel(riskProfile.name),
                }));
        }
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            policyName: simpleInput,
        };
    }

    function renderPoliciesList({ data, translator }: IRenderDataProps<TEnhancedPolicy[]>) {
        if (!data) {
            return null;
        }

        const policyItems: IDataItem<ColValues, ExtraPolicyItemData>[] = data.map((policy) =>
            colsConfig.toPolicyListItem({
                policy,
                defaultPolicyCols: {
                    name: policy.name,
                    baseCurrency: policy.config.algorithm_settings.execution_settings.base_currency.toUpperCase(),
                    tags: convertPolicyManagerTagsForDisplay({
                        tags: policy.tags,
                        translator,
                    }),
                    updated: formatDateRelativeToNow({ date: policy.version_datetime }),
                    hierarchyLevel: isSetString(policy.parent_policy_id)
                        ? PolicyHierarchyLevel.Child
                        : PolicyHierarchyLevel.Base,
                    algorithmType: policy.config.algorithm,
                },
            }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={policyItems}
                onItemRowClicked={onPolicyRowClicked}
                isItemClickable={isPolicyRowClickable}
                toDynamicCellClass={styleItemIfNotClickable}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );
    }

    function styleItemIfNotClickable({ dataItem }: IToDynamicCellClassProps<ColValues, ExtraPolicyItemData>) {
        if (isPolicyRowClickable && !isPolicyRowClickable(dataItem)) {
            return [UtilityClass.table.cellDisabled];
        }

        return null;
    }
}
