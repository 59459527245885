import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IHorizonEntityData } from '@console/core-api/models/portfolioMgmt/horizon.models';
import React from 'react';
import { getMultiTranslationsLabelInitialValue } from 'state/appConfig/selectors';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import { horizonDetailsEntity, triggerCreateHorizon } from 'state/entities/portfolioMgmt/horizonDetails';
import AddPage from 'views/common/add/AddPage';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import HorizonPropertiesForm,
{
    IHorizonFormValues,
    mapHorizonFormValuesToApiEntity,
} from '../HorizonDetail/HorizonPropertiesForm';

export default function HorizonAdd() {
    const initialValues: IHorizonFormValues = {
        name: getMultiTranslationsLabelInitialValue(),
        description: getMultiTranslationsLabelInitialValue(),
        years: undefined,
        warning: false,
        warningMessage: getMultiTranslationsLabelInitialValue(),
        disabled: false,
        disabledMessage: getMultiTranslationsLabelInitialValue(),
        externalId: '',
    };

    return (
        <AddPage
            titleLabel="portfolio_mgmt.horizons.add.title"
            entity={{
                notifications: [StateChangeNotification.HORIZONS_DATA],
                asyncEntitySelector: horizonDetailsEntity.select,
            }}
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <HorizonPropertiesForm
                mode="add"
                initialValues={initialValues}
                submit={{
                    onSubmit: onSubmitAdd,
                }}
            />
        </AddPage>
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IHorizonFormValues>) {
        return triggerCreateHorizon(
            enrichApiEntityDataForCreate<IHorizonEntityData>(mapHorizonFormValuesToApiEntity(values)),
        );
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_HORIZONS_LIST,
        });
    }
}
