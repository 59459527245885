import { string, StringSchema, array, object, ObjectSchema } from '@typsy/schema-validation/dist';
import { IDatePeriod } from '../../../models/period.models';
import { ALL_CURRENCIES, SupportedCurrency } from '../../../config/currencies.config';

export const REGEX_COMMA_SEPARATED_STRING = /^[A-Za-z_]+(,[A-Za-z_]+)*$/;
export const REGEX_COMMA_SEPARATED_UPPERCASE_STRING = /^[A-Z_]+(,[A-Z_]+)*$/;
export const REGEX_COMMA_SEPARATED_ENTITY_IDS = /^[A-Z0-9]+(,[A-Z0-9]+)*$/;
export const REGEX_COMMA_SEPARATED_CURRENCIES = /^[A-Z]{3}(,[A-Z]{3})*$/;

export const REGEX_DATE_STRING =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/; /* "equals" check */
export const REGEX_DATETIME_STRING =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}/; /* "starts with" check */

export const currencySchema = string().oneOf(ALL_CURRENCIES) as StringSchema<SupportedCurrency>;

export const currencyArraySchema = array().of(currencySchema);

export const dateStringSchema = string()
    .matches(
        REGEX_DATE_STRING,
        'should be a date string of the format YYYY-MM-DD',
    );

export const datetimeStringSchema = string()
    .matches(
        REGEX_DATETIME_STRING,
        'should be a datetime string of the format YYYY-MM-DDTHH:mm:ss.sss',
    );

export const partialDatePeriodSchema: ObjectSchema<Partial<IDatePeriod<string>>> = object({
    startDate: dateStringSchema,
    endDate: dateStringSchema,
});

export const datePeriodSchema: ObjectSchema<IDatePeriod<string>> = object({
    startDate: dateStringSchema.required(),
    endDate: dateStringSchema.required(),
});

export const partialDatetimePeriodSchema: ObjectSchema<Partial<IDatePeriod<string>>> = object({
    startDate: datetimeStringSchema,
    endDate: datetimeStringSchema,
});

export const datetimePeriodSchema: ObjectSchema<IDatePeriod<string>> = object({
    startDate: datetimeStringSchema.required(),
    endDate: datetimeStringSchema.required(),
});
