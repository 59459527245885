import React from 'react';
import clsx from 'clsx';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { APP_COLORS } from 'config/styling/colors';
import useAsyncFetch from 'utils/react/hooks/useAsyncFetch';
import formatValueForDisplay from 'utils/varia/formatValueForDisplay';
import { makeStyles } from 'views/styling';
import Spinner from 'views/common/loading/Spinner';
import RouteLink from 'views/common/routing/RouteLink';
import Tooltip from 'views/common/widget/Tooltip';
import {
    IApiEntityAsyncValueField,
    goalNameAsyncValueField,
    horizonNameAsyncValueField,
    policyNameAsyncValueField,
    riskProfileNameAsyncValueField,
    userNameAsyncValueField,
    userOrGroupNameAsyncValueField,
} from 'views/common/genericApiEntity/versions/apiEntityAsyncValueFieldConfigs';
import {
    formatCounterAccount,
    formatExpectedAssetClasses,
} from 'views/common/genericApiEntity/versions/apiEntityValueFormatters';

const FIELD_NAME_TO_ASYNC_ENTITY_MAP: { [fieldName: string]: IApiEntityAsyncValueField } = {
    /* generic */
    readable_by: userOrGroupNameAsyncValueField,
    modifiable_by: userOrGroupNameAsyncValueField,
    /* portfolio */
    owned_by_user_id: userNameAsyncValueField,
    goal_id: goalNameAsyncValueField,
    horizon_id: horizonNameAsyncValueField,
    risk_profile_id: riskProfileNameAsyncValueField,
    policy_id: policyNameAsyncValueField,
    /* userGroup */
    user_ids: userNameAsyncValueField,
};

const FIELD_NAME_TO_VALUE_FORMATTER: { [fieldName: string]: TValueFormatter } = {
    expected_asset_classes: formatExpectedAssetClasses as TValueFormatter,
    counter_account: formatCounterAccount as TValueFormatter,
};

interface IPublicProps {
    fieldName: string;
    value: string | number | boolean;
    className?: string;
    formatter?: TValueFormatter;
    noRouteLink?: boolean;
}

export type TValueFormatter<Val = unknown> = (value: Val) => string;

const useStyles = makeStyles(() => ({
    AsyncValueFieldLoader: {
        display: 'inline-flex',
        minWidth: 100,
        justifyContent: 'center',
    },

    AsyncValueTooltip: {
        display: 'inline-flex',
        alignItems: 'center',

        '& .__routeLink': {
            color: APP_COLORS.PRIMARY['500'],
        },
    },
}));

export default function ApiEntityAsyncValueField({
    value,
    fieldName,
    className,
    formatter,
    noRouteLink,
}: IPublicProps) {
    const [asyncItem] = useAsyncFetch({
        shouldFetch,
        fetcher: () => FIELD_NAME_TO_ASYNC_ENTITY_MAP[fieldName].asyncValueMapper(getValueAsString()),
        deps: [],
    });

    const classes = useStyles();

    const valueFormatter = formatter || FIELD_NAME_TO_VALUE_FORMATTER[fieldName] || formatValueForDisplay;

    if (asyncItem.status === AsyncStatus.Success) {
        const { to, params } = FIELD_NAME_TO_ASYNC_ENTITY_MAP[fieldName].linkTo(value.toString());

        if (noRouteLink) {
            return (
                <div className={clsx(className, '__value')}>
                    {valueFormatter ? valueFormatter(asyncItem.data) : asyncItem.data}
                </div>
            );
        }

        return (
            <Tooltip
                className={classes.AsyncValueTooltip}
                label={{ msg: 'common.action.navigate_to' }}
                placement="top-end"
            >
                <RouteLink to={to} params={params} className={clsx(className, '__routeLink')}>
                    {valueFormatter ? valueFormatter(asyncItem.data) : asyncItem.data}
                </RouteLink>
            </Tooltip>
        );
    }

    if (asyncItem.status === AsyncStatus.Busy) {
        return (
            <Spinner className={classes.AsyncValueFieldLoader} size="XS" />
        );
    }

    return (
        <div className={clsx(className, '__value')}>
            {valueFormatter ? valueFormatter(value) : value}
        </div>
    );

    function shouldFetch() {
        return Object.keys(FIELD_NAME_TO_ASYNC_ENTITY_MAP).includes(fieldName);
    }

    function getValueAsString(): string {
        return value
            ? value.toString()
            : value as string;
    }
}
