import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { ISinglePolicyApiInput } from '@console/core-api/models/portfolioMgmt/policy.models';
import { IPolicyChain } from '@console/bff/models/policies/policyChain.models';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';

/**
 * This entity is for storing the parent-policy chain of a policy.
 * It will return the chain for the input policyId, where the first part of the chain is that requested policy.
 *
 * So for best performance:
 * - after fetching the policy detail (when opening a policy e.g. from the policies list) you already
 *   get back in the response if that policy has a parent or not (but you don't know the parent policy name yet)
 * - if it has a parent policy id, then fetch the policy chain with that parent policy id as input
 *   (otherwise, if you give the 'child' policy id as input, then the api would first again fetch that child api)
 */

export const policyParentChainEntity = getEntitiesManager().registerEntity<IPolicyChain>({
    asyncEntityKey: AsyncEntityKeys.policyParentChain,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.POLICY_PARENT_CHAIN_DATA],
    includeUpdaters: true,
});

export const triggerResetParentPolicyChain = () => policyParentChainEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchParentPolicyChain({ policyId }: ISinglePolicyApiInput) {
    return policyParentChainEntity.async.fetch<ISinglePolicyApiInput>({
        api: api.bff.policies.fetchPolicyChain,
        apiInputSelector: () => ({
            policyId,
        }),
        refreshMode: () => policyParentChainEntity.select().data.id !== policyId,
        resetDataOnTriggerMode: 'always',
    });
}
