import React from 'react';
import { TStrokeLinecap } from 'models/chart.models';
import { AxisDomain } from 'utils/libs/d3';
import { TYScale } from 'views/common/charts/types';

export interface IYAxisSpecialTickLineProps<YDomain extends AxisDomain = number> {
    yScale: TYScale<YDomain>;
    yValue: YDomain;
    lineLength: number;
    strokeColor: string;
    strokeWidth?: number; /* default 1 */
    strokeDasharray?: string; /* default undefined = non-interrupted line */
    strokeLinecap?: TStrokeLinecap; /* default 'butt' */
}

export function YAxisSpecialTickLine({
    yScale,
    yValue,
    lineLength,
    strokeColor,
    strokeWidth = 1,
    strokeDasharray,
    strokeLinecap = 'butt',
}: IYAxisSpecialTickLineProps) {
    const y = yScale(yValue);

    return (
        <line
            x1={0}
            y1={y}
            x2={lineLength}
            y2={y}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
            strokeLinecap={strokeLinecap}
        />
    );
}
