import React, { ReactElement } from 'react';
import { useSnackbar } from 'notistack';
import MuiIconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { INavigateToRoute } from 'models/routing.models';
import { TFlashMessageKey } from 'models/state/ui.models';
import { getFlashMessages } from 'state/ui/flashMessages.selectors';
import { removeFlashMessage } from 'state/ui/flashMessages.actions';
import { getTranslator } from 'state/i18n/selectors';
import { observe, IObserveProps } from 'views/observe';
import RouteLink from 'views/common/routing/RouteLink';
import { getRoute } from 'views/routes';

/**
 * Implemented based on redux example:
 * https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example
 */

let displayedKeys: TFlashMessageKey[] = [];

function FlashMessageManager({ state, dispatch }: IObserveProps): ReactElement {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const flashMessages = getFlashMessages(state);
    const translator = getTranslator(state);

    React.useEffect(() => {
        flashMessages.forEach((flashMessage) => {
            const {
                key, translationKey, translationPlaceholders,
                options, dismissed, navigateToRoute,
            } = flashMessage;

            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayedKeys.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(translator({ msg: translationKey, placeholders: translationPlaceholders }), {
                key,
                // Default values
                action: getFlashMessageActions({
                    onClose: () => closeSnackbar(key),
                    navigateToRoute,
                }),
                // Custom options
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    if (options.onExited) {
                        options.onExited(event, myKey);
                    }
                    // remove this snackbar from store
                    dispatch(removeFlashMessage({ key: myKey }));
                    removeFromDisplayedKeys(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            addToDisplayedKeys(key);
        });
    }, [flashMessages, closeSnackbar, enqueueSnackbar, dispatch, translator]);

    return null;
}

function getFlashMessageActions({
    onClose,
    navigateToRoute,
}: {
    onClose: () => void;
    navigateToRoute: INavigateToRoute;
}) {
    return (
        <>
            {navigateToRoute && navigateToRoute.routeKey && (
                renderNavigateToRoute()
            )}
            <MuiIconButton
                className="flash-close-icon"
                size="small"
                aria-label="close flash"
                color="inherit"
                onClick={onClose}
            >
                <Close fontSize="small" />
            </MuiIconButton>
        </>
    );

    function renderNavigateToRoute() {
        const { exact } = getRoute({ routeKey: navigateToRoute.routeKey });

        return (
            <RouteLink to={navigateToRoute.routeKey} exact={exact} inheritColor>
                <MuiIconButton
                    className="flash-navigate-icon"
                    size="medium"
                    aria-label="navigate flash"
                    color="inherit"
                    onClick={onClose}
                >
                    <Visibility fontSize="small" />
                </MuiIconButton>
            </RouteLink>
        );
    }
}

function addToDisplayedKeys(keyToAdd: TFlashMessageKey) {
    displayedKeys = [...displayedKeys, keyToAdd];
}

function removeFromDisplayedKeys(keyToRemove: TFlashMessageKey) {
    displayedKeys = [...displayedKeys.filter((key) => keyToRemove !== key)];
}

export default observe(
    [StateChangeNotification.UI_FLASH_MESSAGES, StateChangeNotification.I18N_TRANSLATIONS],
    FlashMessageManager,
);
