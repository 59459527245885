import { IRoute } from 'models/route.models';
import { ROUTE_KEY } from '../routeKeys';
import Dashboard from './Dashboard';
import StyleGuide from './StyleGuide';

export const MAIN_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_HOME,
    path: '/',
    exact: true,
    breadcrumb: {
        translationKey: 'main.dashboard.breadcrumb',
    },
    component: Dashboard,
    appShell: {
        showBreadcrumbs: false,
    },
}, {
    routeKey: ROUTE_KEY.R_STYLE_GUIDE,
    path: '/style-guide',
    exact: true,
    breadcrumb: {
        translationKey: 'main.style_guide.breadcrumb',
    },
    component: StyleGuide,
}];
