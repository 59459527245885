import { IUntranslatableLabel } from 'models/general.models';

export type TFontSize = 30 | 24 | 21 | 18 | 17 | 16 | 15 | 14 | 12 | 10;

export const NO_DATA_CHARACTER = '-';

export const NO_DATA_CHARACTER_DO_NOT_TRANSLATE: IUntranslatableLabel = {
    text: NO_DATA_CHARACTER,
    shouldTranslate: false,
};
