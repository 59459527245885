import { ONE_MINUTE_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import {
    IEnhancedPortfolioOptimization,
    TFetchEnhancedPortfolioOptimizationApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolioOptimization.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { isRouteLocation } from 'state/ui/selectors';
import { getStore } from 'state';
import { getEntitiesManager } from '../entitiesManager';

export const portfolioOptimizationEntity = getEntitiesManager().registerEntity<IEnhancedPortfolioOptimization>({
    asyncEntityKey: AsyncEntityKeys.portfolioOptimization,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_OPTIMIZATION],
    includeUpdaters: true,
});

export function resetPortfolioOptimization() {
    portfolioOptimizationEntity.updaters.fetch.reset();
}

export function triggerFetchPortfolioOptimizationLatest({
    forceRefresh = false,
    ...apiInput
}: Omit<TFetchEnhancedPortfolioOptimizationApiInput, 'optimizationId'> & IForceStateRefreshFilter) {
    if (forceRefresh) {
        apiCacheManager.clearPortfolioOptimization({ portfolioId: apiInput.portfolioId });
    }

    return portfolioOptimizationEntity.async.fetch({
        api: apiCacheManager.fetchPortfolioOptimizationLatest,
        apiInputSelector: () => apiInput,
        refreshMode: () => forceRefresh
            || portfolioOptimizationEntity.select().data.portfolioId !== apiInput.portfolioId,
        onSuccess: () => {
            const optimizationStatus = portfolioOptimizationEntity.select().data.status;

            if (optimizationStatus === EnhancedOptimizationStatus.PENDING) {
                /* this is the case where the underlying optimization actually returned a 202 */
                setTimeout(
                    () => {
                        if (isRouteLocation(getStore().getState(), {
                            params: {
                                portfolioId: apiInput.portfolioId,
                            },
                        })) {
                            triggerFetchPortfolioOptimizationLatest(apiInput);
                        }
                    },
                    ONE_MINUTE_IN_MILLIS,
                );
            }
        },
        resetDataOnTriggerMode: 'always',
    });
}
