import React from 'react';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import ContentBox from 'views/common/layout/ContentBox';
import { ReportIcon } from 'views/common/icons';
import { getStore } from 'state';
import { openCreatePortfolioReportAppModal } from 'state/ui/appModals.actions';
import { canUserCreatePortfolioReport } from 'state/auth/apiEntityAuthorization.selectors';
import { triggerFetchPortfolioReports } from 'state/entities/portfolioMgmt/portfolioReports';
import GenericReportsList, {
    IDefaultPortfolioReportCols,
    getDefaultReportCols,
} from 'views/apps/StoryTeller/GenericReportsList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPortfolioReportCols extends Omit<IDefaultPortfolioReportCols, 'type'> {}

const COLS = getDefaultReportCols({
    desiredCols: [
        { colName: 'name', percentWidth: 30 },
        { colName: 'client', percentWidth: 15 },
        { colName: 'period', percentWidth: 15 },
        { colName: 'language', percentWidth: 5 },
        { colName: 'created', percentWidth: 15 },
        { colName: 'status', percentWidth: 10 },
        { colName: 'actions', percentWidth: 5 },
    ],
});

export default function PortfolioReports() {
    const portfolioDetailsData = portfolioDetailsEntity.select().data;

    const embeddedUserData = extractEmbeddedEntity<IUserEntityData>({
        // eslint-disable-next-line no-underscore-dangle
        data: portfolioDetailsEntity.select().data._embedded,
        id: portfolioDetailsData.owned_by_user_id,
    });
    const canUserCreateReports = canUserCreatePortfolioReport();

    return (
        <ContentBox noHorizontalPadding>
            <GenericReportsList<IPortfolioReportCols>
                overrideCols={{
                    cols: COLS,
                    toReportListItem: ({ report, defaultReportCols }) => ({
                        id: report.id,
                        colValues: defaultReportCols,
                    }),
                }}
                asyncListEntityFetchTriggerOverride={(apiInput) => triggerFetchPortfolioReports({
                    ...apiInput,
                    portfolioId: portfolioDetailsData.id,
                })}
                shouldDisplayFilters={false}
                listActions={[{
                    id: 'create_portfolio_report_button',
                    icon: <ReportIcon />,
                    label: 'portfolio_mgmt.portfolio_reports.create.default_button_label',
                    disabled: !canUserCreateReports,
                    tooltip: !canUserCreateReports && 'common.action.insufficient_permissions',
                    tooltipPlacement: 'left',
                    onClick: () => getStore().dispatch(openCreatePortfolioReportAppModal({
                        extraData: {
                            entityType: CoreApiEntityType.portfolios,
                            reportsInputData: [{
                                entityCreationDatetime: portfolioDetailsData.creation_datetime,
                                entityId: portfolioDetailsData.id,
                                entityName: portfolioDetailsData.name,
                                userId: portfolioDetailsData.owned_by_user_id,
                                preferredLanguage: embeddedUserData.language,
                                portfolioManagerType: portfolioDetailsData.config.manager,
                                portfolioMoneyType: portfolioDetailsData.money_type,
                            }],
                        },
                    })),
                }]}
            />
        </ContentBox>
    );
}
