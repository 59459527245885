import React from 'react';
import clsx from 'clsx';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { TApiEntity, IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';
import { IRiskProfileEntityData } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import {
    riskProfileDetailsEntity, triggerPatchRiskProfileDetails,
} from 'state/entities/portfolioMgmt/riskProfileDetails';
import { canUserModifyRiskProfile } from 'state/auth/apiEntityAuthorization.selectors';
import { ensureMultiTranslationsLabelHasAllLocales } from 'state/appConfig/selectors';
import { makeStyles, mixins } from 'views/styling';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import {
    IRiskProfileAssetClassesFormValues,
    RiskProfileAssetClassesForm,
    RiskProfileDetailsForm,
    TRiskProfileDetailsFormValues,
} from './RiskProfilePropertiesForm';
import { getRiskProfileAssetClassesSchema, getRiskProfilePropertiesDetailsSchema } from './riskProfileDetailsSchema';

const LABEL_PREFIX = 'portfolio_mgmt.risk_profiles.detail';

const useStyles = makeStyles((/* theme */) => ({
    RiskProfileDetailsTab: {
        ...mixins.flexColTopCenter(),
    },
}));

export default function RiskProfilePropertiesTab() {
    const classes = useStyles();
    const riskProfileData = riskProfileDetailsEntity.select().data;
    const isReadOnly = !canUserModifyRiskProfile(riskProfileData);

    return (
        <div className={clsx(classes.RiskProfileDetailsTab, readOnlyEntityClass(isReadOnly))}>
            <PropertiesCategoryBox<TRiskProfileDetailsFormValues>
                id="riskProfile-details-fields"
                category="details"
                isReadOnly={isReadOnly}
                labelPrefix={LABEL_PREFIX}
                maxWidth={1000}
                fields={getDetailsFields(riskProfileData)}
                editGlobalForm={getEditDetailsForm(riskProfileData)}
            />

            <PropertiesCategoryBox<IRiskProfileAssetClassesFormValues>
                id="riskProfile-assetClasses-fields"
                category="asset_classes"
                isReadOnly={isReadOnly}
                labelPrefix={LABEL_PREFIX}
                maxWidth={1000}
                titleTooltip={{
                    msg: 'portfolio_mgmt.risk_profiles.detail.expected_asset_classes.info_tooltip.content',
                    raw: true,
                }}
                shouldPrefixLabels={false}
                fields={getAssetClassesFields(riskProfileData)}
                editGlobalForm={getEditAssetClassesForm(riskProfileData)}
            />
        </div>
    );
}

function getDetailsFields(riskProfile: TApiEntity<IRiskProfileEntityData>): IDetailField[] {
    return [{
        label: 'name.label',
        value: riskProfile.name,
    }, {
        label: 'description.label',
        value: riskProfile.description,
        alignValueLeft: true,
    }, {
        label: 'external_id.label',
        value: riskProfile.external_id,
    }, {
        label: 'image_url.label',
        value: riskProfile.image_url,
    }, {
        label: 'score_range.label',
        value: `${riskProfile.score_range[0]} - ${riskProfile.score_range[1]}`,
    }, {
        label: 'expected_risk_fraction.label',
        value: riskProfile.expected_risk_fraction.toString(),
    }, {
        label: 'expected_return_fraction.label',
        value: riskProfile.expected_return_fraction.toString(),
    }];
}

function getAssetClassesFields(riskProfile: TApiEntity<IRiskProfileEntityData>): IDetailField[] {
    return riskProfile.expected_asset_classes.map((assetClass) => ({
        label: assetClass.name,
        value: assetClass.allocation,
        alignValueLeft: true,
    }));
}

function getEditDetailsForm(riskProfile: TApiEntity<IRiskProfileEntityData>): IEditForm<TRiskProfileDetailsFormValues> {
    return {
        initialValues: {
            name: ensureMultiTranslationsLabelHasAllLocales(riskProfile.name),
            description: ensureMultiTranslationsLabelHasAllLocales(riskProfile.description),
            scoreRangeMax: riskProfile.score_range[1],
            scoreRangeMin: riskProfile.score_range[0],
            imageUrl: riskProfile.image_url,
            expectedReturnFraction: riskProfile.expected_return_fraction,
            expectedRiskFraction: riskProfile.expected_risk_fraction,
            externalId: riskProfile.external_id,
        },
        schema: getRiskProfilePropertiesDetailsSchema(),
        renderFormFields: RiskProfileDetailsForm,
        submit: {
            onSubmit: ({ values }) => triggerPatchRiskProfileDetails((currentRiskProfile) => {
                /* eslint-disable no-param-reassign */
                currentRiskProfile.name = values.name as IApiMultiTranslationsLabel;
                currentRiskProfile.description = values.description as IApiMultiTranslationsLabel;
                currentRiskProfile.image_url = isSetString(values.imageUrl) ? values.imageUrl as string : null;
                currentRiskProfile.score_range[0] = values.scoreRangeMin as number;
                currentRiskProfile.score_range[1] = values.scoreRangeMax as number;
                currentRiskProfile.expected_return_fraction = values.expectedReturnFraction as number;
                currentRiskProfile.expected_risk_fraction = values.expectedRiskFraction as number;
                currentRiskProfile.external_id = values.externalId as string;
                /* eslint-enable no-param-reassign */
            }),
        },
        modalMaxWidth: 'md',
        modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
        labelPrefix: LABEL_PREFIX,
    };
}

function getEditAssetClassesForm(
    riskProfile: TApiEntity<IRiskProfileEntityData>,
): IEditForm<IRiskProfileAssetClassesFormValues> {
    return {
        initialValues: {
            expectedAssetClasses: riskProfile.expected_asset_classes.map((assetClass) => ({
                allocation: ensureMultiTranslationsLabelHasAllLocales(assetClass.allocation),
                name: ensureMultiTranslationsLabelHasAllLocales(assetClass.name),
            })),
        },
        schema: getRiskProfileAssetClassesSchema(),
        renderFormFields: RiskProfileAssetClassesForm,
        submit: {
            onSubmit: ({ values }) => triggerPatchRiskProfileDetails((currentRiskProfile) => {
                /* eslint-disable no-param-reassign */
                currentRiskProfile.expected_asset_classes = values.expectedAssetClasses.map((assetClass) => ({
                    name: assetClass.name,
                    allocation: assetClass.allocation,
                }));
                /* eslint-enable no-param-reassign */
            }),
        },
        modalMaxWidth: 'md',
        modalTitle: `${LABEL_PREFIX}.properties.modal_titles.asset_classes`,
        labelPrefix: LABEL_PREFIX,
    };
}
