import React from 'react';
import { TLabel } from 'models/general.models';
import { IExtendedInputBaseProps } from 'views/common/inputs/extended/ExtendedInputText';
import { ExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import InputToggleField, { IInputToggleFieldProps } from 'views/common/inputs/base/InputToggleField';
import InputToggleFieldWithDescription from 'views/common/inputs/base/InputToggleFieldWithDescription';
import ExtendedInputWrapper, {
    IExtendedInputWrapperProps,
    prependLabelWithPrefix,
} from 'views/common/inputs/extended/ExtendedInputWrapper';
import { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import { makeStyles } from 'views/styling';

interface IExtendedInputToggleProps<Name extends string = string>
    extends Omit<IExtendedInputBaseProps, 'wrapper'>,
    Omit<IInputToggleFieldProps<Name>, 'id' | 'name' | 'checked' | 'onChange' | 'description'> {
    description?: TLabel; // to show a description text (to the right of the toggle)
    shouldPrefixDescription?: boolean; // default true
    wrapper?: Omit<IExtendedInputWrapperProps, 'disabled' | 'children' | 'readOnly' | 'hasError'>;
    inForm?: boolean; // default false
}

const useStyles = makeStyles((theme) => ({
    InFormToggleWrapper: {
        '& .inFormToggle': {
            marginTop: theme.spacing(4),
        },
    },
}));

export default function ExtendedInputToggle<Name extends string = string>({
    readOnly = false,
    inForm = false,
    formField,
    wrapper,
    description,
    shouldPrefixDescription = true,
    ...toggleFieldProps
}: IExtendedInputToggleProps<Name>) {
    const classes = useStyles();
    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, readOnlyForm, setFieldValue }) => {
                const adjustedDescription = prependLabelWithPrefix({
                    label: description,
                    labelPrefix,
                    shouldPrefixLabel: shouldPrefixDescription,
                });

                const isFieldReadOnly = readOnly || readOnlyForm;

                const commonToggleProps: IInputToggleFieldProps<Name> = {
                    id: formField.fieldId,
                    name: formField.fieldName as Name,
                    checked: formField.value as boolean,
                    onChange,
                    disabled: toggleFieldProps.disabled || isFieldReadOnly,
                };

                function onChange({ checked }: IOnChangeCheckboxProps<Name>) {
                    setFieldValue({ fieldName: formField.fieldName, value: checked });
                }

                const toggleField = (
                    <>
                        {adjustedDescription && (
                            <InputToggleFieldWithDescription<Name>
                                description={adjustedDescription}
                                {...commonToggleProps}
                            />
                        )}
                        {!adjustedDescription && (
                            <InputToggleField<Name>
                                {...commonToggleProps}
                            />
                        )}
                    </>
                );

                if (inForm) {
                    return (
                        <div className={classes.InFormToggleWrapper}>
                            <ExtendedInputWrapper
                                disabled={toggleFieldProps.disabled}
                                hasError={!!formField.error && formField.emphasizeError}
                                readOnly
                                shouldPrefixLabel={false}
                                {...wrapper}
                            >
                                <div className="inFormToggle">
                                    {toggleField}
                                </div>
                            </ExtendedInputWrapper>
                        </div>
                    );
                }
                if (wrapper) {
                    return (
                        <ExtendedInputWrapper
                            disabled={toggleFieldProps.disabled}
                            readOnly={isFieldReadOnly}
                            hasError={!!formField.error && formField.emphasizeError}
                            {...wrapper}
                        >
                            {toggleField}
                        </ExtendedInputWrapper>
                    );
                }

                return toggleField;
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
