import React from 'react';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { anyComparerDescending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import getArrayCopy from '@snipsonian/core/cjs/array/getArrayCopy';
import {
    getMgmtReportingChartColor,
    MGMT_REPORTING_LINE_CHART_CONFIG,
} from 'config/mgmtReporting/mgmtReportingCharts.config';
import GenericLineChart, { IGenericLineChartProps } from 'views/common/charts/GenericLineChart';

export interface IMgmtReportingLineChartProps extends Omit<IGenericLineChartProps, 'id' | 'options'> {
    chartName: string;
    currency: SupportedCurrency;
}

export default function MgmtReportingStackedLineChart({
    chartName,
    currency,
    data,
    ...otherProps
}: IMgmtReportingLineChartProps) {
    if (!isArrayWithValues(data) || !isArrayWithValues(data[0].dataPoints) || data[0].dataPoints.length < 2) {
        console.log('Not enough dataPoints to display the MgmtReportingStackedLineChart.');
        return null;
    }

    const sortedColoredData = getArrayCopy(data)
        .sort((lineA, lineB) => anyComparerDescending(
            getLastItemOfArray(lineA.dataPoints).y,
            getLastItemOfArray(lineB.dataPoints).y,
        ))
        .map((item, index) => ({
            ...item,
            areaColor: getMgmtReportingChartColor(index),
            lineColor: MGMT_REPORTING_LINE_CHART_CONFIG.lineColor,
        }));

    return (
        <GenericLineChart
            id={`mgmt_reporting_line_chart_${chartName}`}
            options={{
                dimensions: {
                    maxWidth: MGMT_REPORTING_LINE_CHART_CONFIG.svg.maxWidth,
                    maxHeight: MGMT_REPORTING_LINE_CHART_CONFIG.svg.maxHeight,
                },
                axis: {
                    x: {
                        height: MGMT_REPORTING_LINE_CHART_CONFIG.axis.x.height,
                        marginRight: MGMT_REPORTING_LINE_CHART_CONFIG.axis.x.marginRight,
                    },
                    y: {
                        width: MGMT_REPORTING_LINE_CHART_CONFIG.axis.y.width,
                        marginTop: MGMT_REPORTING_LINE_CHART_CONFIG.axis.y.marginTop,
                        text: {
                            label: {
                                text: currency,
                                shouldTranslate: false,
                            },
                            paddingLeft: MGMT_REPORTING_LINE_CHART_CONFIG.axis.y.label.paddingLeft,
                        },
                    },
                },
                labels: {
                    width: MGMT_REPORTING_LINE_CHART_CONFIG.labels.width,
                    paddingLeft: MGMT_REPORTING_LINE_CHART_CONFIG.labels.paddingLeft,
                },
                shouldStackLines: true,
                shouldIncludeAreas: true,
            }}
            data={sortedColoredData}
            {...otherProps}
        />
    );
}
