import { IState } from 'models/state.models';
import { getMultiTranslationsLabelSchema } from 'state/appConfig/selectors';
import { string, boolean, integer, object, ObjectSchema } from '@console/common/utils/schema';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let horizonDetailsSchema: ObjectSchema<any> = null;

export const getHorizonDetailsSchema = (stateForTestPurposes?: IState) => {
    if (!horizonDetailsSchema) {
        horizonDetailsSchema = object({
            name: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            description: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            disabled: boolean().required(),
            disabledMessage: getMultiTranslationsLabelSchema(stateForTestPurposes, false).when('disabled', {
                is: true,
                then: () => getMultiTranslationsLabelSchema(stateForTestPurposes, true).required(),
            }),
            warning: boolean().required(),
            warningMessage: getMultiTranslationsLabelSchema(stateForTestPurposes, false).when('warning', {
                is: true,
                then: () => getMultiTranslationsLabelSchema(stateForTestPurposes, true).required(),
            }),
            years: integer().required().minVal(0),
            externalId: string(),
        }).required();
    }

    return horizonDetailsSchema;
};
