import React from 'react';

export function stopPropagation(functionToExecute: (e: React.MouseEvent) => void) {
    return (event: React.MouseEvent) => {
        if (event?.stopPropagation) {
            event.stopPropagation();
        }
        return functionToExecute(event);
    };
}
