import { saveAs } from 'file-saver';
import { IDownloadableDocument } from '@console/core-api/models/api.models';

export function presentFileForDownload(documentToDownload: IDownloadableDocument) {
    if (!documentToDownload) {
        return;
    }

    const { data, filename } = documentToDownload;

    if (!data || !filename) {
        return;
    }

    saveAs(data, filename);
}
