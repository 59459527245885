import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { RecursivePartial } from '@console/common/models/genericTypes.models';
import { RecurringDepositFrequency } from '@console/core-api/models/performance.models';
import { ENABLE_USE_EXPECTED_RETURNS_OPTION } from '@console/core-api/config/coreApi.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    PortfolioPerformancePastPeriod, PortfolioPerformanceFuturePeriod,
    IPortfolioPerformancePageVars,
} from 'models/ui/portfolioPerformance.ui.models';
import { updatePortfolioPerformancePageVars } from 'state/ui/uiPages.actions';
import { getPortfolioPerformancePageVars } from 'state/ui/uiPages.selectors';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import IconButtonSubmit from 'views/common/buttons/IconButtonSubmit';
import InputForm from 'views/common/inputs/base/InputForm';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputSelectField, { IOnChangeSelectProps } from 'views/common/inputs/base/InputSelectField';
import InputNumberField, { IOnChangeNumberInputProps } from 'views/common/inputs/base/InputNumberField';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import {
    getPastFilterPeriodsByPortfolioStartDate,
    validateRecurringDepositFilterInput,
    FUTURE_FILTER_PERIODS,
    RECURRING_DEPOSIT_FREQUENCIES,
    MIN_SIMULATION_RECURRING_DEPOSIT_AMOUNT,
} from './portfolioPerformanceFilterUtils';

interface IPublicProps {
    baseCurrency: string;
    onChangeFilter: () => void;
}

const useStyles = makeStyles((theme) => ({
    PortfolioPerformanceFilter: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
        padding: theme.spacing(2, 0),

        '& .periodFilter': {
            marginRight: theme.spacing(2),
        },
        '& .useExpectedReturns': {
            ...mixins.typoBold(),

            '& .toggle': {
                marginLeft: theme.spacing(1),
            },
        },

        '& .filtersThatNeedSubmit': {
            ...mixins.flexRow({ alignMain: 'flex-end', alignCross: 'center', wrap: 'wrap' }),

            '& .__label': {
                ...mixins.typoBold({ size: 16 }),
                marginRight: theme.spacing(1),
                textAlign: 'right',
            },
            '& .recurringDepositAmount': {
                marginRight: theme.spacing(1),
            },
            '& .recurringDepositFrequency': {
                marginRight: theme.spacing(1),
            },
        },
    },
}));

function PortfolioPerformanceFilter({
    state, dispatch,
    baseCurrency, onChangeFilter,
}: IObserveProps & IPublicProps) {
    const classes = useStyles();

    const { mode, past, future } = getPortfolioPerformancePageVars(state);

    if (mode === 'past') {
        const pastPeriods = getPastFilterPeriodsByPortfolioStartDate(past.portfolioStartDate);

        if (pastPeriods.length < 1) {
            return null;
        }

        return (
            <div className={classes.PortfolioPerformanceFilter}>
                <InputWrapper noPadding maxWidth={false} className="periodFilter">
                    <InputSelectField<PortfolioPerformancePastPeriod>
                        items={pastPeriods}
                        value={past.period}
                        itemLabelsAreTranslationKeys
                        onChange={onChangePastPeriod}
                    />
                </InputWrapper>
            </div>
        );
    }

    const filterInputValidation = validateRecurringDepositFilterInput({
        ...future,
        allowEmptyAmount: true,
    });

    /* mode === 'future' */
    return (
        <div className={classes.PortfolioPerformanceFilter}>
            <InputWrapper noPadding maxWidth={false} className="periodFilter">
                <InputSelectField<PortfolioPerformanceFuturePeriod>
                    items={FUTURE_FILTER_PERIODS}
                    value={future.period}
                    itemLabelsAreTranslationKeys
                    onChange={onChangeFuturePeriod}
                />
            </InputWrapper>

            {ENABLE_USE_EXPECTED_RETURNS_OPTION && (
                <InputWrapper noPadding maxWidth={false} className="useExpectedReturns">
                    <Translate msg="portfolio_mgmt.portfolios.detail.performance.future.use_expected_returns" />
                    <InputToggleField
                        checked={future.useExpectedReturns}
                        name="use-expected-returns-toggle"
                        onChange={({ checked }) => onChangeUseExpectedReturns(checked)}
                        className="toggle"
                    />
                </InputWrapper>
            )}

            <InputForm
                name="portfolio-future-performance-filter-form"
                className="filtersThatNeedSubmit"
                onSubmit={submitAdditionalFutureFilters}
            >
                <div className="__label">
                    <Translate msg="portfolio_mgmt.portfolios.detail.performance.future.recurring_deposit.label" />
                </div>

                <InputWrapper className="recurringDepositAmount" noPadding maxWidth={false}>
                    <InputNumberField
                        placeholder={{
                            msg: 'portfolio_mgmt.portfolios.detail.performance.future.recurring_deposit.placeholder',
                            placeholders: {
                                currency: baseCurrency,
                                minAmount: MIN_SIMULATION_RECURRING_DEPOSIT_AMOUNT,
                            },
                        }}
                        value={future.recurringDepositAmount}
                        onChange={onChangeRecurringDepositAmount}
                        error={filterInputValidation.errors.recurringDepositAmount}
                        positionHelperTextAsAbsolute
                        addSpaceIfPlaceholderAndNoValue={false}
                    />
                </InputWrapper>

                <InputWrapper className="recurringDepositFrequency" noPadding maxWidth={false}>
                    <InputSelectField<RecurringDepositFrequency>
                        items={RECURRING_DEPOSIT_FREQUENCIES}
                        value={future.recurringDepositFrequency}
                        itemLabelsAreTranslationKeys
                        onChange={onChangeRecurringDepositFrequency}
                    />
                </InputWrapper>

                <IconButtonSubmit
                    id="trigger-refresh-future-performance"
                    tooltip="portfolio_mgmt.portfolios.detail.performance.future.recurring_deposit.submit"
                    disabled={!filterInputValidation.isValid}
                />
            </InputForm>
        </div>
    );

    function onChangePastPeriod({ value }: IOnChangeSelectProps<PortfolioPerformancePastPeriod>) {
        onChangeFilterGeneric({
            past: {
                period: value,
            },
        });
    }

    function onChangeFuturePeriod({ value }: IOnChangeSelectProps<PortfolioPerformanceFuturePeriod>) {
        onChangeFilterGeneric({
            future: {
                period: value,
            },
        });
    }

    function onChangeUseExpectedReturns(value: boolean) {
        onChangeFilterGeneric({
            future: {
                useExpectedReturns: value,
            },
        });
    }

    function onChangeRecurringDepositAmount({ value }: IOnChangeNumberInputProps) {
        onChangeFilterGeneric({
            future: {
                recurringDepositAmount: value,
            },
        }, false);
    }

    function onChangeRecurringDepositFrequency({ value }: IOnChangeSelectProps<RecurringDepositFrequency>) {
        onChangeFilterGeneric({
            future: {
                recurringDepositFrequency: value,
            },
        }, false);
    }

    function onChangeFilterGeneric(
        changedFilterValues: RecursivePartial<IPortfolioPerformancePageVars>,
        triggerGraphRefresh = true,
    ) {
        dispatch(updatePortfolioPerformancePageVars(
            changedFilterValues,
            [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_FILTER],
        ));

        if (triggerGraphRefresh) {
            onChangeFilter();
        }
    }

    function submitAdditionalFutureFilters() {
        if (filterInputValidation.isValid) {
            onChangeFilter();
        }
    }
}

export default observe<IPublicProps>(
    [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_FILTER],
    PortfolioPerformanceFilter,
);
