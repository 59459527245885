import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
    BorderStyleProperty, BorderColorProperty,
} from 'csstype';
import { APP_COLORS } from 'config/styling/colors';

interface IBorderProps {
    width?: number;
    style?: BorderStyleProperty;
    color: BorderColorProperty;
}

export const border = (borderProps: IBorderProps): CSSProperties => ({
    border: toCssBorder(borderProps),
});

export const borderBottom = (borderProps: IBorderProps): CSSProperties => ({
    borderBottom: toCssBorder(borderProps),
});

export const borderTop = (borderProps: IBorderProps): CSSProperties => ({
    borderTop: toCssBorder(borderProps),
});

export const borderRight = (borderProps: IBorderProps): CSSProperties => ({
    borderRight: toCssBorder(borderProps),
});

export const borderLeft = (borderProps: IBorderProps): CSSProperties => ({
    borderLeft: toCssBorder(borderProps),
});

export const borderBottomGrey = () => ({
    borderBottom: toCssBorder({
        color: APP_COLORS.GREY['100'],
    }),
});

export const borderTopGrey = () => ({
    borderTop: toCssBorder({
        color: APP_COLORS.GREY['100'],
    }),
});

export function toCssBorder({
    width = 1,
    style = 'solid',
    color,
}: IBorderProps) {
    return `${width}px ${style} ${color}`;
}
