import isString from '@snipsonian/core/cjs/is/isString';
import { toFloat } from './parseFloat';

interface IRoundFloatOptions {
    nrOfDecimals?: number;
}

export function roundFloat(
    input: string | number,
    { nrOfDecimals = 0 }: IRoundFloatOptions = {},
): number {
    const float = isString(input) ? toFloat(input) : input;

    if (typeof float === 'undefined') {
        return float;
    }

    const toThePowerOf = 10 ** nrOfDecimals;

    return Math.round(float * toThePowerOf) / toThePowerOf;
}

export function roundFloat2(input: string | number) {
    return roundFloat(input, { nrOfDecimals: 2 });
}

export function roundFloat4(input: string | number) {
    return roundFloat(input, { nrOfDecimals: 4 });
}
