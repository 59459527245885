import React from 'react';
import NoResults from 'views/common/list/NoResults';
import { ROUTE_KEY } from 'views/routeKeys';
import portfoliosIllustration from 'views/assets/img/illustrations/portfolios.svg';
import GenericUserPortfolios from 'views/userMgmt/shared/GenericUserPortfolios';

export default function ClientPortfolios() {
    return (
        <GenericUserPortfolios
            overridePortfolioDetailRouteKey={ROUTE_KEY.R_CLIENT_DETAIL_PORTFOLIO_DETAIL}
            noResultsRender={renderNoResults}
        />
    );

    function renderNoResults() {
        return (
            <NoResults
                illustration={portfoliosIllustration}
                title="client_mgmt.detail.portfolios.list.no_results.title"
                text="client_mgmt.detail.portfolios.list.no_results.text"
            />
        );
    }
}
