import { getCurrentDate } from '@snipsonian/core/cjs/date/currentDate';
import { formatDateAsBackendDatetime } from './formatDate';
import { getSpecificDate, ISpecificDateInput } from './getSpecificDate';

export function getCurrentBackendDatetime(): string {
    return formatDateAsBackendDatetime(getCurrentDate());
}

export function getSpecificBackendDatetime(props: ISpecificDateInput): string {
    return formatDateAsBackendDatetime(getSpecificDate(props));
}
