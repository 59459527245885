import React from 'react';
import { APP_COLORS } from 'config/styling/colors';
import { makeStyles } from 'views/styling';
import { toCssBorder } from 'views/assets/cssInJs/mixins/box';

const BAR_RADIUS = 8;
const BAR_HEIGHT = 16;
const SURROUNDING_BORDER_WIDTH = 4;

interface IPublicProps extends Omit<IStyleProps, 'indicatorWidth'> {
    percentage: number; // should be between 0 - 100
}

interface IStyleProps {
    barColor?: string;
    barBgColor?: string;
    surroundingBgColor?: string;
    totalWidth?: number; // the desired width without padding
    indicatorWidth?: number;
}

const useStyles = makeStyles((/* theme */) => ({
    ProgressBar: {
        width: ({ totalWidth }: IStyleProps) => totalWidth + (2 * SURROUNDING_BORDER_WIDTH),
        height: BAR_HEIGHT + (2 * SURROUNDING_BORDER_WIDTH),
        /* this border is to overlap the straight edges of the indicator so that we get rounded corners
         * + it also provides a margin while doing so */
        borderRadius: BAR_RADIUS + SURROUNDING_BORDER_WIDTH,
        border: ({ surroundingBgColor }: IStyleProps) =>
            toCssBorder({ width: SURROUNDING_BORDER_WIDTH, color: surroundingBgColor }),
    },
}));

export default function ProgressBar({
    percentage,
    barColor = APP_COLORS.PRIMARY['500'],
    barBgColor = APP_COLORS.GREY['100'],
    surroundingBgColor = APP_COLORS.SYSTEM.WHITE,
    totalWidth = 256,
}: IPublicProps) {
    const indicatorWidth = Math.round((totalWidth / 100) * percentage);
    const classes = useStyles({
        barColor,
        barBgColor,
        surroundingBgColor,
        totalWidth,
        indicatorWidth,
    });

    return (
        <svg className={classes.ProgressBar}>
            <g>
                <rect
                    x={0}
                    y={0}
                    width={indicatorWidth}
                    height={BAR_HEIGHT}
                    fill={barColor}
                    strokeWidth={0}
                />
                <rect
                    x={indicatorWidth}
                    y={0}
                    width={totalWidth - indicatorWidth}
                    height={BAR_HEIGHT}
                    fill={barBgColor}
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}
