import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TI18nLabelOrString } from 'models/general.models';
import { BOX_BORDER_RADIUS } from 'config/styling/border';
import { APP_COLORS } from 'config/styling/colors';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import TextButton from '../buttons/TextButton';
import ContentBox from './ContentBox';

export enum MessagePriorityVariant {
    Info = 'INFO',
    Success = 'SUCCESS',
    Warning = 'WARNING',
    Error = 'ERROR',
}

interface IStyleProps {
    variant?: MessagePriorityVariant; // default info
}

interface IPublicProps extends IStyleProps {
    title: TI18nLabelOrString;
    renderHeaderContent?: () => React.ReactNode;
    action?: {
        label: TI18nLabelOrString;
        onClick: React.MouseEventHandler;
    };
    details?: TI18nLabelOrString;
    renderBodyContent?: () => React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    Banner: {
        ...mixins.widthMax(),

        '& .__row': {
            ...mixins.flexRow(),

            '& .__content': {
                ...mixins.widthMax(),
                padding: theme.spacing(3),

                '& .__header': {
                    ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
                    fontWeight: 'bold',
                    color: ({ variant }: IStyleProps) => APP_COLORS.FEEDBACK[variant],

                    '& .__title': {
                        ...mixins.widthMax(),
                    },
                },

                '& .__details': {
                    paddingTop: theme.spacing(1),
                },

                '& .__action': {
                    minWidth: 200,
                    ...mixins.flexRowCenterRight(),
                },

                '& .__bodyContent': {
                    paddingTop: theme.spacing(3),
                },
            },

            '& .__sideBelt': {
                ...mixins.heightMax(),
                backgroundColor: ({ variant }: IStyleProps) => APP_COLORS.FEEDBACK[variant],
                width: 10,
                borderBottomLeftRadius: BOX_BORDER_RADIUS,
                borderTopLeftRadius: BOX_BORDER_RADIUS,
            },
        },
    },
}));

export default function MessageBanner({
    variant = MessagePriorityVariant.Info,
    title,
    renderHeaderContent,
    action,
    details,
    renderBodyContent,
}: IPublicProps) {
    const classes = useStyles({ variant });

    return (
        <div className={classes.Banner}>
            <ContentBox
                noHorizontalPadding
                noVertialPadding
            >
                <div className="__row">
                    <div>
                        <div className="__sideBelt"> &nbsp; </div>
                    </div>
                    <div className="__content">
                        <div className="__header">
                            <div className="__title">
                                <Translate {...toI18nLabel(title)} />
                            </div>
                            {renderHeaderContent && renderHeaderContent()}
                            {action && (
                                <div className="__action">
                                    <TextButton
                                        id="open_banner_modal"
                                        label={action.label}
                                        variant="filled"
                                        size="XS"
                                        color="grey_inverted"
                                        onClick={action.onClick}
                                        fontSize="M"
                                    />
                                </div>
                            )}
                        </div>
                        {details && (
                            <div className="__details">
                                <Translate {...toI18nLabel(details)} />
                            </div>
                        )}
                        {renderBodyContent && (
                            <div className="__bodyContent">
                                {renderBodyContent()}
                            </div>
                        )}
                    </div>
                </div>
            </ContentBox>
        </div>
    );
}
