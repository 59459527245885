import React, { useState } from 'react';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import {
    IAsyncEntityTriggerResolveValue,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { Locale } from '@console/common/config/i18n.config';
import { IColValues, IRenderItemCellProps, TDataColumns } from 'models/list.models';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import {
    TEnhancedPortfoliosData,
    TEnhancedPortfolioInList,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import {
    ReportEntityType,
    ISingleReportInputData,
    TPortfolioReportEntityType,
} from 'models/ui/portfolioReport.ui.models';
import { IPortfolioReportAddEntityData } from '@console/core-api/models/portfolioMgmt/portfolioReport.models';
import { IAsyncItem, TApiEntityId } from '@console/core-api/models/api.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import {
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { triggerCreatePortfolioReports } from 'state/entities/portfolioMgmt/portfolioReports';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import {
    getMultiTranslationsLabelInitialValue,
    getStoryTellerConfig,
} from 'state/appConfig/selectors';
import {
    triggerFetchPortfolioGroupReportPortfolios,
} from 'state/entities/portfolioMgmt/portfolioGroupReportPortfolios';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { getInitialPeriodData } from 'utils/date/periodUtils';
import { getEndOfTodayAsDate } from '@console/common/utils/date/getSpecificDate';
import { getLatestDate } from '@console/common/utils/date/dateUtils';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { getLocaleTranslationLabel } from 'utils/i18n/localeUtils';
import { makeStyles, mixins } from 'views/styling';
import ApiEntityAsyncValueField from 'views/common/genericApiEntity/versions/ApiEntityAsyncValueField';
import TextButton from 'views/common/buttons/TextButton';
import Modal from 'views/common/layout/Modal';
import DataTable from 'views/common/list/DataTable';
import InputSelectField, { IInputSelectItem, TInputSelectValue } from 'views/common/inputs/base/InputSelectField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import { PortfolioTypeIndicator, ReportTypeIndicator } from 'views/common/icons/indicators';
import InputPeriodField from 'views/common/inputs/base/InputPeriodField';
import { IPeriodFilter } from '@console/common/models/period.models';

const LABEL_PREFIX = 'portfolio_mgmt.portfolio_reports.create';
const COL_TRANSLATION_PREFIX = 'portfolio_mgmt.portfolio_reports.create.modal.columns';

interface IPublicProps {
    open: boolean;
    onClose(): void;
    entityType: TPortfolioReportEntityType; // the entity that the report is being generated for
    reportsInputData: ISingleReportInputData[];
}

const useStyles = makeStyles((theme) => ({
    ReportGenerationScreen: {
        '& .periodInput': {
            ...mixins.flexRowCenterLeft(),
            ...mixins.widthHeightPixels({ w: 400 }),
            paddingBottom: theme.spacing(2),

            '& .periodInputDescription': {
                ...mixins.widthHeightPixels({ w: 200 }),
            },
        },
        '& .dateRangeWrapper': {
            display: 'flex',
            '& > :first-child': {
                marginRight: theme.spacing(2),
            },
        },
        '& .actions': {
            marginTop: theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& > :first-child': {
                marginRight: theme.spacing(3),
            },
        },
    },
}));

interface IColumnsInput {
    entityTranslationSuffix: string;
    entityType: CoreApiEntityType;
    reportsInputData: ISingleReportInputData[];
    asyncPortfolioGroupReportPortfolios: IAsyncItem<IAsyncEntityTriggerResolveValue<TEnhancedPortfoliosData>>;
    portfoliosToUserSelectItemsMapper: (portfolioId: TApiEntityId) => IInputSelectItem<TInputSelectValue>[];
    clientsData: IClientsData;
    changeClient: (changeClientProps: IChangeClientProps) => void;
}

interface IChangeClientProps {
    clientId: string;
    portfolioGroupId: string;
}

interface ICreateReportExtraData {
    managerType?: PortfolioManagerType;
    moneyType?: PortfolioMoneyType;
}

interface ICreateReportCols extends IColValues {
    type?: null;
    entity: string;
    client: string;
    language: string;
}

function getColumns({
    entityTranslationSuffix,
    entityType,
    asyncPortfolioGroupReportPortfolios,
    portfoliosToUserSelectItemsMapper,
    clientsData,
    changeClient,
}: IColumnsInput): TDataColumns<ICreateReportCols, ICreateReportExtraData> {
    const isEntityTypePortfolios = entityType === CoreApiEntityType.portfolios;
    return {
        type: {
            label: {
                msg: `${COL_TRANSLATION_PREFIX}.type`,
            },
            data: {
                render: ({ item }) => {
                    if (isEntityTypePortfolios) {
                        return (
                            <PortfolioTypeIndicator
                                managerType={item.extra.managerType}
                                moneyType={item.extra.moneyType}
                            />
                        );
                    }
                    return (
                        <ReportTypeIndicator
                            reportType={ReportEntityType.PortfolioGroup}
                        />
                    );
                },
            },
            percentWidth: 10,
        },
        entity: {
            label: {
                msg: `${COL_TRANSLATION_PREFIX}.${entityTranslationSuffix}`,
            },
            percentWidth: 35,
        },
        client: {
            label: {
                msg: `${COL_TRANSLATION_PREFIX}.client`,
            },
            data: {
                render: ({ item }) => {
                    if (entityType === CoreApiEntityType.portfolioGroups) {
                        return (
                            <InputWrapper>
                                <InputSelectField
                                    items={[]}
                                    async={{
                                        itemsData: asyncPortfolioGroupReportPortfolios,
                                        dataToSelectItemsMapper: () => portfoliosToUserSelectItemsMapper(item.id),
                                    }}
                                    value={clientsData[item.id]}
                                    onChange={({ value }) => changeClient({
                                        clientId: value as string,
                                        portfolioGroupId: item.id,
                                    })}
                                />
                            </InputWrapper>
                        );
                    }

                    return (
                        <ApiEntityAsyncValueField
                            value={item.colValues.client || ''}
                            fieldName="owned_by_user_id"
                            formatter={(value) => value.toString()}
                            noRouteLink
                        />
                    );
                },
            },
            percentWidth: 35,
        },
        language: {
            label: {
                msg: `${COL_TRANSLATION_PREFIX}.language`,
            },
            percentWidth: 20,
        },
    };
}

interface IClientsData {
    [entityId: string]: TApiEntityId;
}

interface IClientsState {
    areClientsChosen: boolean;
}

interface ILanguagesData {
    [entityId: string]: Locale;
}

export default function CreatePortfolioReportModal({
    entityType,
    reportsInputData,
    open,
    onClose,
}: IPublicProps) {
    const storytellerConfig = getStoryTellerConfig();
    const entityTranslationSuffix = entityType === CoreApiEntityType.portfolios ? 'portfolios' : 'portfolio_groups';
    const areThereMultipleReports = reportsInputData.length > 1;
    const entityCountTranslationSuffix = areThereMultipleReports ? 'multiple' : 'single';
    const classes = useStyles();
    const { minDate, maxDate } = getMinMaxDateRange();
    const [periodData, setPeriodData] = useState<IPeriodFilter>(getInitialPeriodData({ minDate, maxDate }));
    const [languagesData, setLanguagesData] = useState<ILanguagesData>({});
    const [clientsData, setClientsData] = useState<IClientsData>(getInitialClientsData());
    const { areClientsChosen } = getClientsState();

    // We fetch all portfolios of all portfolio groups to determine the clients and their preferred languages
    const [asyncPortfolioGroupReportPortfolios] = useAsyncFetchOnMount({
        shouldFetch: () => entityType === CoreApiEntityType.portfolioGroups,
        fetcher: () => {
            const allPortfolioIds = reportsInputData.reduce(
                (accumulator, reportData) => accumulator.concat(reportData.portfolioIds), [],
            );
            return triggerFetchPortfolioGroupReportPortfolios({
                ids: allPortfolioIds,
            });
        },
    });
    const columns = getColumns({
        entityType,
        entityTranslationSuffix,
        reportsInputData,
        asyncPortfolioGroupReportPortfolios,
        portfoliosToUserSelectItemsMapper,
        clientsData,
        changeClient,
    });

    columns.language.data = { render: renderLanguage };

    return (
        <Modal
            id="create_portfolio_reports_modal"
            open={open}
            title={`${LABEL_PREFIX}.modal.title.${entityTranslationSuffix}.${entityCountTranslationSuffix}`}
            onClose={onClose}
            maxWidth="md"
            emphasizeTitle
        >
            <div className={classes.ReportGenerationScreen}>
                <div className="periodInput">
                    <p className="periodInputDescription">
                        <Translate msg={`${LABEL_PREFIX}.modal.period_select.label`} />
                    </p>
                    <InputWrapper>
                        <InputPeriodField<Date>
                            onChange={setPeriodData}
                            currentPeriod={{
                                type: periodData.type,
                                startDate: periodData.startDate,
                                endDate: periodData.endDate,
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </InputWrapper>
                </div>
                <DataTable<ICreateReportCols, ICreateReportExtraData>
                    cols={columns}
                    items={reportsInputData.map((item) => ({
                        id: item.entityId,
                        colValues: {
                            type: null,
                            entity: item.entityName,
                            client: item.userId,
                            language: item.preferredLanguage,
                        },
                        extra: {
                            managerType: item?.portfolioManagerType,
                            moneyType: item?.portfolioMoneyType,
                        },
                    }))}
                />
                <div className="actions">
                    <TextButton
                        id="portfolio-report-creator-submit"
                        label={{ msg: 'common.action.cancel' }}
                        variant="bare"
                        color="text"
                        onClick={onClose}
                    />
                    <TextButton
                        id="portfolio-report-creator-submit"
                        label={{ msg: `${LABEL_PREFIX}.modal.action.generate.${entityCountTranslationSuffix}` }}
                        onClick={onCreate}
                        size="M"
                        fontSize="L"
                        disabled={
                            !periodData.startDate ||
                            !periodData.endDate ||
                            (entityType === CoreApiEntityType.portfolioGroups && !areClientsChosen)
                        }
                    />
                </div>
            </div>
        </Modal>
    );

    function getMinMaxDateRange(): { minDate: Date, maxDate: Date } {
        const creationDates = reportsInputData.map((entityInput) => new Date(entityInput.entityCreationDatetime));

        return {
            minDate: getLatestDate(creationDates),
            maxDate: getEndOfTodayAsDate(),
        };
    }

    function changeClient({ clientId, portfolioGroupId }: IChangeClientProps) {
        setClientsData({
            ...clientsData,
            [portfolioGroupId]: clientId,
        });
        setLanguagesData({
            ...languagesData,
            [portfolioGroupId]: extractEmbeddedEntity<IUserEntityData>({
                id: clientId,
                data: asyncPortfolioGroupReportPortfolios.data.asyncResult,
            }).language,
        });
    }

    function renderLanguage({ item }: IRenderItemCellProps<ICreateReportCols, unknown, unknown>) {
        return (
            <InputWrapper>
                <InputSelectField
                    items={storytellerConfig.locales.map((locale) => ({
                        value: locale,
                        label: getLocaleTranslationLabel(locale),
                    }))}
                    onChange={(changeProps) => setLanguagesData({ ...languagesData, [item.id]: changeProps.value })}
                    value={getLanguageValue(
                        reportsInputData.find(((inputData) => inputData.entityId === item.id)),
                    )}
                    itemLabelsAreTranslationKeys
                />
            </InputWrapper>
        );
    }

    function getInitialClientsData(): IClientsData {
        return reportsInputData.reduce(
            (accumulator, reportData) => {
                accumulator[reportData.entityId] = null;
                return accumulator;
            },
            {} as IClientsData,
        );
    }

    function getClientsState(): IClientsState {
        const areAllClientsChosen = Object.values(clientsData).every((clientId) => isSetString(clientId));
        return {
            areClientsChosen: areAllClientsChosen,
        };
    }

    function onCreate() {
        const payload = reportsInputData.map((inputData) => {
            const startDate = formatDate({
                date: periodData.startDate,
                format: DATE_FORMAT.BACK_END,
            });
            const endDate = formatDate({
                date: periodData.endDate,
                format: DATE_FORMAT.BACK_END,
            });

            return enrichApiEntityDataForCreate<IPortfolioReportAddEntityData>({
                portfolio_id: inputData.entityId,
                name: initMultiTranslationsLabelWithDefaultNameForEachLocale(),
                start_date: startDate,
                end_date: endDate,
                language: getLanguageValue(inputData),
                user_id: getChosenClientId(inputData),
            });

            function initMultiTranslationsLabelWithDefaultNameForEachLocale() {
                return getMultiTranslationsLabelInitialValue(
                    formatDefaultName(),
                );
            }

            function formatDefaultName() {
                return `${inputData.entityName} ${startDate} - ${endDate}`;
            }
        });

        return triggerCreatePortfolioReports(payload).then(onClose);
    }

    function getChosenClientId(inputData: ISingleReportInputData) {
        if (entityType === CoreApiEntityType.portfolioGroups) {
            return clientsData[inputData.entityId];
        }

        return inputData.userId;
    }

    function portfoliosToUserSelectItemsMapper(portfolioGroupId: TApiEntityId): IInputSelectItem<TInputSelectValue>[] {
        const currentPortfolioGroup = reportsInputData.find((reportData) => reportData.entityId === portfolioGroupId);
        return asyncPortfolioGroupReportPortfolios.data.asyncResult.results
            .filter((portfolio: TEnhancedPortfolioInList) => currentPortfolioGroup.portfolioIds.includes(portfolio.id))
            .map((portfolio: TEnhancedPortfolioInList) => ({
                label: getUserFullName(extractEmbeddedEntity<IUserEntityData>({
                    id: portfolio.owned_by_user_id,
                    data: asyncPortfolioGroupReportPortfolios.data.asyncResult,
                })),
                value: portfolio.owned_by_user_id,
            }));
    }

    function getLanguageValue(inputItem: ISingleReportInputData): Locale {
        const preferredLocale = storytellerConfig.locales.find((locale) => locale === inputItem.preferredLanguage);
        return languagesData[inputItem.entityId] || preferredLocale || storytellerConfig.defaultLocale;
    }
}
