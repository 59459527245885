import {
    CoreApiPolicyAlgorithmType,
    IPolicyAlgorithmSettings,
    IPolicyConfig,
    TPolicyComposition,
} from '@console/core-api/models/portfolioMgmt/policy.models';
import {
    IEnhancedPolicyAlgorithmSettings,
    IEnhancedPolicyConfig,
    PolicyAlgorithmType,
    PolicyMeasureOfRisk,
    TEnhancedPolicyComposition,
} from '../../../models/policies/enhancedPolicyDetails.models';
import {
    isPolicyOfCompositionBasedType,
    isPolicyOfTypeModelPortfolio,
} from '../../../utils/policies/policyTypeUtils';

export function mapEnhancedToCoreApiPolicyConfig(enhancedConfig: IEnhancedPolicyConfig): IPolicyConfig {
    const {
        algorithm,
        measureOfRisk,
        algorithm_settings,
        ...otherConfig
    } = enhancedConfig;

    const coreApiPolicyConfig = {
        algorithm_settings: mapEnhancedToCoreApiPolicyAlgorithmSettings({
            enhancedAlgorithmSettings: algorithm_settings,
            algorithmType: algorithm,
        }),
        ...otherConfig,
    };

    if (measureOfRisk === PolicyMeasureOfRisk.IVAR && algorithm === PolicyAlgorithmType.RISK_BASED) {
        return {
            algorithm: CoreApiPolicyAlgorithmType.IVAR,
            ...coreApiPolicyConfig,
        };
    }

    return {
        algorithm: algorithm as unknown as CoreApiPolicyAlgorithmType,
        ...coreApiPolicyConfig,
    };
}

export function mapEnhancedToCoreApiPolicyAlgorithmSettings({
    enhancedAlgorithmSettings,
    algorithmType,
}: {
    enhancedAlgorithmSettings: IEnhancedPolicyAlgorithmSettings;
    algorithmType: PolicyAlgorithmType;
}): IPolicyAlgorithmSettings {
    const {
        model_portfolio_settings, meta_portfolio_settings,
        ...otherAlgorithmSettings
    } = enhancedAlgorithmSettings;

    if (!isPolicyOfCompositionBasedType(algorithmType)) {
        return otherAlgorithmSettings;
    }

    const compositionSettings = isPolicyOfTypeModelPortfolio(algorithmType)
        ? {
            model_portfolio_settings: {
                ...model_portfolio_settings,
                // eslint-disable-next-line max-len
                model_portfolio: mapEnhancedToCoreApiPolicyComposition(model_portfolio_settings?.model_portfolio),
            },
        }
        : {
            meta_portfolio_settings: {
                ...meta_portfolio_settings,
                // eslint-disable-next-line max-len
                meta_portfolio: mapEnhancedToCoreApiPolicyComposition(meta_portfolio_settings?.meta_portfolio),
            },
        };

    return {
        ...otherAlgorithmSettings,
        ...compositionSettings,
    };
}

function mapEnhancedToCoreApiPolicyComposition(
    composition: TEnhancedPolicyComposition,
): TPolicyComposition {
    return Object.fromEntries(Object.entries(composition).map(([id, { weight }]) => [id, weight]));
}
