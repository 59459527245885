import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import { get } from '../coreApiRequestWrapper';
import {
    IPortfolioOptimization,
    IPortfolioOptimizationEntityData,
} from '../../models/portfolioMgmt/portfolioOptimization.models';
import { IFetchOptimizationApiParams } from '../../models/portfolioMgmt/optimization.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { TApiEntity } from '../../models/api.models';

export function fetchOptimizationDetails({
    optimizationId,
    underlyingApiRequestConfig,
}: IFetchOptimizationApiParams & IUnderlyingRequestApiInput) {
    return get<IPortfolioOptimization, TApiEntity<IPortfolioOptimizationEntityData>>({
        url: CoreApiPath.OPTIMIZATION_DETAILS,
        pathParams: {
            optimizationId,
        },
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            optimization: data,
        }),
        ...underlyingApiRequestConfig,
    });
}
