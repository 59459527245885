import { isClientSideRestApiErrorTypeGuard } from '@typsy/rest-api/dist/client/error/isClientSideRestApiErrorTypeGuard';
import { BaseApiErrorCode, IBaseApiErrorClientSide } from '../../server/error/apiBaseError.models';

/**
 * Checks if the error is the client-side error reply of one of the underlying
 * api's (core-api / console-bff / cms-api)
 */
export function isClientSideApiErrorTypeGuard<ErrorCode extends string = BaseApiErrorCode>(
    error: unknown,
): error is IBaseApiErrorClientSide<ErrorCode> {
    return isClientSideRestApiErrorTypeGuard(error);
}
