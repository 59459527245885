import { CHART, MODAL_CHART_DIMENTIONS } from 'config/styling/chart';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPolicySimulationFuturePerformanceMarkedDate } from 'models/ui/policySimulation.ui.models';
import React from 'react';
import { getPolicySimulationPerformancePageVars } from 'state/ui/uiPages.selectors';
import {
    policyFuturePerformanceEntity,
} from 'state/entities/portfolioMgmt/policyPerformanceFuture';
import { formatDate } from '@console/common/utils/date/formatDate';
import GenericPerformanceGraph, { IGenericPerformanceGraphProps } from 'views/common/charts/GenericPerformanceGraph';
import { IMarkedDateData, IMarkedDateValueItem } from 'views/common/charts/MarkedDateBox';
import { observe } from 'views/observe';
import { IChartDimensions } from 'models/chart.models';
import { getPolicySimulationChartConfig } from './policySimulationChartConfig';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    // eslint-disable-next-line max-len
    extends Pick<IGenericPerformanceGraphProps<IPolicySimulationFuturePerformanceMarkedDate>, 'onChangedMarkedDate'> {
}

function PolicySimulationExpectedPerformanceChart({
    onChangedMarkedDate,
}: IPublicProps) {
    const { data: futurePerformanceData } = policyFuturePerformanceEntity.select();

    return (
        <GenericPerformanceGraph
            chartDimensions={MODAL_CHART_DIMENTIONS}
            getChartConfig={(dimensions: IChartDimensions) => getPolicySimulationChartConfig({
                chartDimensions: dimensions,
                futurePerformanceData,
            })}
            markedDateBoxNotifications={[StateChangeNotification.UI_PAGE_POLICY_PERFORMANCE_FUTURE_MARKED_DATE]}
            markedDateDataSelector={markedDateDataSelector}
            onChangedMarkedDate={onChangedMarkedDate}
        />
    );

    function markedDateDataSelector(state: IState): IMarkedDateData {
        const { markedDate } = getPolicySimulationPerformancePageVars(state);

        if (!markedDate) {
            return null;
        }

        const valueItems: IMarkedDateValueItem[] = [{
            label: 'portfolio_mgmt.policies.detail.configuration.simulation.performance.marked_date.optimistic',
            labelColor: CHART.FUTURE.OPTIMISTIC_COLOR,
            amount: markedDate.values.optimistic,
        }, {
            label: 'portfolio_mgmt.policies.detail.configuration.simulation.performance.marked_date.realistic',
            labelColor: CHART.FUTURE.REALISTIC_COLOR,
            amount: markedDate.values.realistic,
        }, {
            label: 'portfolio_mgmt.policies.detail.configuration.simulation.performance.marked_date.pessimistic',
            labelColor: CHART.FUTURE.PESSIMISTIC_COLOR,
            amount: markedDate.values.pessimistic,
        }];

        return {
            formattedDate: formatDate({ date: markedDate.date }),
            valueItems,
        };
    }
}

export default observe<IPublicProps>(
    [StateChangeNotification.POLICY_PERFORMANCE_FUTURE_DATA],
    PolicySimulationExpectedPerformanceChart,
);
