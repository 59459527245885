import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    IAdvancedPortfoliosFilters,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import {
    TEnhancedPortfoliosData,
    TFetchEnhancedPortfoliosClientApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { IState } from 'models/state.models';
import { api } from 'api';
import { isRouteLocation } from 'state/ui/selectors';
import { errorFlashDispatcher } from 'state/flashDispatcher';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { presentFileForDownload } from 'utils/file/presentFileForDownload';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';

export const portfoliosEntity = getEntitiesManager().registerEntity<TEnhancedPortfoliosData>({
    asyncEntityKey: AsyncEntityKeys.portfolios,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIOS_DATA],
    includeUpdaters: true,
});

export const triggerResetPortfoliosFetch = () => portfoliosEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchPortfolios({
    forceRefresh = false,
    ...apiInput
}: TFetchEnhancedPortfoliosClientApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchPortfoliosGeneric({
        apiInput,
        pageKey: UiPageKey.portfoliosList,
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
        }),
    });
}

export function triggerFetchPortfoliosForSelect({
    forceRefresh = false,
    ...apiInput
}: TFetchEnhancedPortfoliosClientApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchPortfoliosGeneric({
        apiInput,
        pageKey: UiPageKey.portfolioSelectList,
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput),
    });
}

function triggerFetchPortfoliosGeneric({
    apiInput,
    pageKey,
    refreshMode,
}: {
    apiInput?: TFetchEnhancedPortfoliosClientApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return portfoliosEntity.async.fetch<TFetchEnhancedPortfoliosClientApiInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolios,
        apiInputSelector: ({ state }) =>
            enrichApiInputWithListPageVars<TFetchEnhancedPortfoliosClientApiInput, IAdvancedPortfoliosFilters>({
                pageKey,
                state,
                apiInput,
                mapSimpleFilterValue: (simpleFilterValues) => ({
                    externalId: simpleFilterValues,
                }),
                mapAdvancedFilterValues: (advancedFilterValues) => ({
                    externalId: advancedFilterValues.externalId,
                    email: advancedFilterValues.email,
                    virtualIban: advancedFilterValues.virtualIban,
                    userLastName: advancedFilterValues.userLastName,
                    portfolioName: advancedFilterValues.portfolioName,
                    ids: advancedFilterValues.ids,
                }),
            }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: portfoliosEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}

export function triggerExportPortfolios() {
    return api.portfolios.exportPortfolios()
        .then(presentFileForDownload)
        .catch(errorFlashDispatcher('error.download_failed'));
}
