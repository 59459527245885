import React, { useState } from 'react';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getStore } from 'state';
import { getTranslator } from 'state/i18n/selectors';
import { policyDetailsEntity } from 'state/entities/portfolioMgmt/policyDetails';
import {
    determineDivergingPolicySettingsIfAny,
    IDivergingPolicySetting,
} from 'utils/entities/portfolioMgmt/policyDivergingSettingsUtils';
import { formatPercentage } from 'utils/number/percentageUtils';
import { observe } from 'views/observe';
import Banner, { MessagePriorityVariant } from 'views/common/layout/MessageBanner';
import TextButton from 'views/common/buttons/TextButton';
import { makeStyles } from 'views/styling';
import { canUserCreateBasePolicy } from 'state/auth/apiEntityAuthorization.selectors';
import { getDefaultTenantCurrency } from 'state/appConfig/selectors';

const LABEL_PREFIX = 'portfolio_mgmt.policies.detail';
const HEADER_LABEL_PREFIX = `${LABEL_PREFIX}.overriden_settings_header`;
const FIELDS_LABEL_PREFIX = `${LABEL_PREFIX}.fields`;
const SECTIONS_LABEL_PREFIX = `${LABEL_PREFIX}.configuration.settings.sections`;
const GENERIC_COMPARISON_LABEL_PREFIX = 'common.generic_api_entity.versions.list.comparison';
const POLICY_SETTINGS_LEVEL_CONNECTOR = ' > ';
const UNIVERSE_SECTION_KEY = 'universe';
const FIELD_KEYS_WITH_CURRENCY_VALUE = [
    'cost_per_transaction_amount', 'transaction_amount', 'min_portfolio_value', 'cash_amount', 'position_amount',
];
const NUMBER_OF_ROWS_IF_SETTINGS_LIST_IS_COLLAPSED = 3;

const useStyles = makeStyles((theme) => ({
    SettingsListModeButton: {
        padding: theme.spacing(2, 0, 0, 2),
    },
}));

function OverridenPolicySettingsHeader() {
    const classes = useStyles();
    const translator = getTranslator(getStore().getState());
    const policyDetailsData = policyDetailsEntity.select().data;
    const currency = policyDetailsData?.coerced_config?.algorithm_settings?.execution_settings?.base_currency
        || getDefaultTenantCurrency();
    const { areThereDivergingSettings, settings } = determineDivergingPolicySettingsIfAny(policyDetailsData);
    const [isSettingsListExpanded, setIsSettingsListExpanded] = useState<boolean>(false);
    const shouldDisplayDetailsText = canUserCreateBasePolicy();

    // No settings diverging from parent -> no need for the banner
    if (!areThereDivergingSettings) {
        return null;
    }

    return (
        <Banner
            title={`${HEADER_LABEL_PREFIX}.info`}
            details={shouldDisplayDetailsText && `${HEADER_LABEL_PREFIX}.details`}
            variant={MessagePriorityVariant.Warning}
            renderBodyContent={() => displayDivergingSettings(joinAllUniverseDivergingSettings(settings))}
        />
    );

    function displayDivergingSettings(divergingSettings: IDivergingPolicySetting[]) {
        const isCollapseButtonNecessary = divergingSettings.length > NUMBER_OF_ROWS_IF_SETTINGS_LIST_IS_COLLAPSED;
        const settingsForDisplay = getDivergingSettingsForDisplay();

        return (
            <>
                {settingsForDisplay.map((setting) => {
                    const key = `${setting.section}${setting.field?.name}${setting.field?.numberRangeElementType}`;
                    const message = `<b>${formatSettingPath(setting)}</b>: ${formatSettingValues(setting)}`;

                    return (
                        <div className="__row" key={key}>
                            {/* Safe to display this html as it is a combo of self-made-content */}
                            {/* and content from a secure call. */}
                            {/* eslint-disable-next-line react/no-danger */}
                            <li /><span dangerouslySetInnerHTML={{ __html: message }} />
                        </div>
                    );
                })}
                {isCollapseButtonNecessary && (
                    <TextButton
                        id="toggle_overriden_setting_list_mode"
                        label={isSettingsListExpanded ? 'common.action.show_less' : 'common.action.show_more'}
                        variant="bare"
                        onClick={() => setIsSettingsListExpanded(!isSettingsListExpanded)}
                        fontSize="M"
                        className={classes.SettingsListModeButton}
                    />
                )}
            </>
        );

        function getDivergingSettingsForDisplay() {
            return isCollapseButtonNecessary
                ? isSettingsListExpanded
                    ? divergingSettings
                    : divergingSettings.slice(0, NUMBER_OF_ROWS_IF_SETTINGS_LIST_IS_COLLAPSED)
                : divergingSettings;
        }
    }

    function joinAllUniverseDivergingSettings(divergingSettings: IDivergingPolicySetting[]): IDivergingPolicySetting[] {
        if (!divergingSettings.find((setting) => setting.section === UNIVERSE_SECTION_KEY)) {
            return divergingSettings;
        }

        const universeInstruments: string[] = [];
        return divergingSettings.reduce(
            (accumulator, setting) => {
                if (setting.section === UNIVERSE_SECTION_KEY) {
                    universeInstruments.push(setting.divergingValue);
                    return accumulator;
                }

                return accumulator.concat(setting);
            },
            [] as IDivergingPolicySetting[],
        ).concat({
            section: UNIVERSE_SECTION_KEY,
            divergingValue: universeInstruments.join(', '),
            parentValue: null,
            field: null,
        });
    }

    function formatSettingPath(setting: IDivergingPolicySetting): string {
        let settingPath: string = translator(`${SECTIONS_LABEL_PREFIX}.${setting.section}.title`);

        if (setting?.subSection) {
            const subSectionName = translator(`${FIELDS_LABEL_PREFIX}.${setting.subSection}.label`);

            appendNewSettingPathElement(subSectionName);
        }

        if (setting.field?.name && setting.field.name !== 'id' && setting.section !== UNIVERSE_SECTION_KEY) {
            const minMaxPathSuffix = setting.field.numberRangeElementType
                ? ` (${translator(`common.fields.${setting.field.numberRangeElementType}`)})`
                : '';
            const fieldName = translator(`${FIELDS_LABEL_PREFIX}.${getFieldNameTranslationLabel(
                setting?.subSection,
                setting.field.name,
            )}`);

            appendNewSettingPathElement(`${fieldName}${minMaxPathSuffix}`);
        }

        return settingPath;

        function appendNewSettingPathElement(element: string) {
            settingPath = settingPath.concat(POLICY_SETTINGS_LEVEL_CONNECTOR).concat(element);
        }
    }

    function getFieldNameTranslationLabel(subSection: string, fieldName: string): string {
        if (isSetString(subSection)) {
            if (subSection.includes('region')) {
                return `regions.${fieldName}`;
            }

            if (subSection.includes('bond_types') || subSection.includes('sectors')) {
                return `${subSection}.${fieldName}`;
            }

            return `${subSection}.${fieldName}.label`;
        }

        return `${fieldName}.label`;
    }

    function formatSettingValues(setting: IDivergingPolicySetting): string {
        if (setting.section === UNIVERSE_SECTION_KEY) {
            return `${setting.divergingValue} ${translator(`${GENERIC_COMPARISON_LABEL_PREFIX}.removed`)}`;
        }
        const isFieldOfCurrencyValue = FIELD_KEYS_WITH_CURRENCY_VALUE.includes(setting.field.name);

        return getSettingValuesForDisplay(setting.divergingValue, setting.parentValue, isFieldOfCurrencyValue);
    }

    function getSettingValuesForDisplay(childValue: string, parentValue: string, shouldDisplayCurrency: boolean) {
        const valueConnector = translator(`${GENERIC_COMPARISON_LABEL_PREFIX}.to`);
        const formattedChildValue = formattedSettingValue(childValue);
        const formattedParentValue = formattedSettingValue(parentValue);

        return `'${formattedChildValue}' ${valueConnector} '${formattedParentValue}'`;

        function formattedSettingValue(value: unknown) {
            if (isNumber(value)) {
                return shouldDisplayCurrency
                    ? formatAmount(value, { currency })
                    : formatPercentage(value * 100);
            }

            if (isObjectPure(value)) {
                // return JSON.stringify(value);
                return translator(`${HEADER_LABEL_PREFIX}.settings_object`);
            }

            return value;
        }
    }
}

export default observe(
    [StateChangeNotification.POLICY_DETAILS_DATA],
    OverridenPolicySettingsHeader,
);
