import React, { useState } from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { yearOffset } from '@console/common/utils/date/getSpecificDate';
import { TKeyMap } from '@console/common/utils/object/objectUtils';
import { SampleFrequencyPerformancePast } from '@console/core-api/models/performance.models';
import { UniverseType } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { IColValues, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import { triggerFetchInstrumentPerformancePast } from 'state/entities/portfolioMgmt/instrumentPerformancePast';
import { getUniverseInstrumentsPageVars } from 'state/ui/uiPages.selectors';
import { updateUniverseInstrumentsPageVars } from 'state/ui/uiPages.actions';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { determineExcludedBuyInstrumentsMap } from 'utils/entities/portfolioMgmt/policyUniverseUtils';
import TextButton from 'views/common/buttons/TextButton';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import { makeStyles, mixins } from 'views/styling';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import GenericInstrumentsList, {
    getDefaultInstrumentCols,
} from 'views/portfolioMgmt/instruments/GenericInstrumentsList';
import AmountDisplay from 'views/common/widget/AmountDisplay';
import Modal from 'views/common/layout/Modal';
import IconButton from 'views/common/buttons/IconButton';
import { DuplicateIcon, PerformanceIcon } from 'views/common/icons';
import { IObserveProps, observe } from 'views/observe';
import { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import { IListAction } from 'views/common/list/ListActionsHeader';
import { IFetchInstrumentsBasedOnFiltersProps, IPolicySettingsFormValues, IPolicyUniverseState } from './types';
import InstrumentPerformancePast, {
    getPolicyBaseCurrency,
} from '../PolicyConfiguration/PolicySimulationExpectedPerformance/InstrumentPerformancePast';

interface IChangeInvestmentUniverseProps {
    context: IExtendedInputFormContext<IPolicySettingsFormValues>;
    closeModal: () => void;
    triggerFetchInstrumentsBasedOnFilters: (props: IFetchInstrumentsBasedOnFiltersProps) => Promise<unknown>;
    policySettingsRules: IPolicySettingsRules;
}

const defaultInstrumentCols = getDefaultInstrumentCols({
    desiredCols: [
        { colName: 'name', percentWidth: 40 },
        { colName: 'type', percentWidth: 10 },
    ],
});

interface IPolicyUniverseCols extends IColValues {
    selected: boolean;
    excludedFromBuying: boolean;
    name: string;
    type: string;
    price: number;
    performance: null;
}

interface IPolicyUniverseExtraData {
    onChangeHoldInstrument: (props: TOnChangeHoldInstrumentProps) => void;
    onChangeBuyInstrument: (props: TOnChangeHoldInstrumentProps) => void;
    openPastPerformanceModal: (isin: string) => void;
    isExcludedFromBuyingInParent: boolean;
}

type TOnChangeHoldInstrumentProps = {
    instrumentId: string;
    checked: boolean;
};

const COLS: TDataColumns<IPolicyUniverseCols, IPolicyUniverseExtraData> = {
    selected: {
        label: {
            // eslint-disable-next-line max-len
            msg: 'portfolio_mgmt.policies.detail.configuration.settings.sections.universe.include_instrument_in_hold_universe',
        },
        data: {
            render: ({ item }) => (
                <InputToggleField
                    name={item.id}
                    checked={item.colValues.selected}
                    onChange={({ checked }) => item.extra.onChangeHoldInstrument({
                        instrumentId: item.id,
                        checked,
                    })}
                />
            ),
        },
        percentWidth: 5,
        align: 'left',
    },
    excludedFromBuying: {
        label: {
            // eslint-disable-next-line max-len
            msg: 'portfolio_mgmt.policies.detail.configuration.settings.sections.universe.exclude_instrument_from_buying',
        },
        data: {
            render: ({ item }) => {
                if (item.colValues.selected) {
                    return (
                        <InputToggleField
                            name={`${item.id}_exclude-from-buying`}
                            checked={item.colValues.excludedFromBuying}
                            onChange={({ checked }) => item.extra.onChangeBuyInstrument({
                                instrumentId: item.id,
                                checked,
                            })}
                            disabled={item.extra.isExcludedFromBuyingInParent}
                        />
                    );
                }

                return '-';
            },
        },
        percentWidth: 10,
        align: 'left',
    },
    name: {
        ...defaultInstrumentCols.name,
    },
    type: {
        ...defaultInstrumentCols.type,
    },
    price: {
        label: {
            msg: 'portfolio_mgmt.instruments.list.columns.price',
        },
        data: {
            render: ({ item }) => (
                <AmountDisplay
                    value={item.colValues.price}
                />
            ),
        },
        percentWidth: 10,
    },
    performance: {
        label: EMPTY_COL_TITLE,
        data: {
            render: ({ item }) => (
                <IconButton
                    id="universe-instrument-performance"
                    aria-label="instrument performance"
                    variant="bare"
                    size="XS"
                    svgSize={24}
                    color="text"
                    onClick={() => item.extra.openPastPerformanceModal(item.id)}
                    icon={<PerformanceIcon />}
                />
            ),
        },
        percentWidth: 5,
    },
};

const useStyles = makeStyles((theme) => ({
    ChangeInvestmentUniverse: {

        '& .ChangeUniverseActions': {
            ...mixins.flexRowCenterRight(),
            padding: theme.spacing(3),
        },
    },
    OnlyFetchSelectedFilter: {
        ...mixins.flexRow({ alignMain: 'right', alignCross: 'center' }),
        padding: theme.spacing(1, 4, 2, 0),

        '& .showOnlySelectedInstrumentsToggle': {
            margin: theme.spacing(0, 2, 0, 0),
        },
    },
}));

function ChangeInvestmentUniverse({
    context,
    closeModal,
    state,
    dispatch,
    triggerFetchInstrumentsBasedOnFilters,
    policySettingsRules,
}: IChangeInvestmentUniverseProps & IObserveProps) {
    const classes = useStyles();
    const { fields, setFieldValue, labelPrefix } = context;
    const initialHoldInstruments = (fields.investmentUniverse.value as string[]) || [];
    const initialExcludedBuyInstrumentsMap = (fields.excludedBuyInstrumentsMap.value || {}) as TKeyMap;
    const [selectedHoldInstruments, setSelectedHoldInstruments] = useState<string[]>(initialHoldInstruments);
    const [excludedBuyMap, setExcludedBuyMap] = useState<TKeyMap>(initialExcludedBuyInstrumentsMap);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const holdInstrumentsMap = selectedHoldInstruments.reduce(
        (accumulator, isin) => {
            accumulator[isin] = true;

            return accumulator;
        },
        {} as IPolicyUniverseState,
    );
    const { showOnlySelectedInstruments } = getUniverseInstrumentsPageVars(state);
    const excludedInParentMap = determineExcludedBuyInstrumentsMap({
        investment_universe: policySettingsRules.universe.instruments.parentValue,
        buy_universe: policySettingsRules.universe.buyInstruments.parentValue,
    });

    useAsyncFetchOnMount({
        fetcher: () => triggerFetchInstrumentsBasedOnFilters({
            selectedInstruments: selectedHoldInstruments,
            forceRefresh: false,
        }),
    });

    return (
        <div className={classes.ChangeInvestmentUniverse}>
            <div className={classes.OnlyFetchSelectedFilter}>
                <InputToggleField
                    className="showOnlySelectedInstrumentsToggle"
                    name="show_only_selected_instruments_filter"
                    checked={showOnlySelectedInstruments}
                    onChange={updateShowOnlySelectedInstrumentsFilter}
                />
                <Translate msg="portfolio_mgmt.instruments.list.filter.show_only_selected_instruments" />
            </div>
            <GenericInstrumentsList
                universe_type={UniverseType.Robo}
                currency={getPolicyBaseCurrency()}
                overrideEntity={{
                    asyncListEntityFetchTrigger: (apiInput) => triggerFetchInstrumentsBasedOnFilters({
                        apiInput,
                        selectedInstruments: selectedHoldInstruments,
                    }),
                }}
                overrideUiVars={{
                    uiPageKey: UiPageKey.universeInstrumentsList,
                    uiVarsNotification: StateChangeNotification.INSTRUMENTS_UNIVERSE_UI_VARS,
                }}
                overrideCols={{
                    cols: COLS,
                    toInstrumentListItem: ({ instrument, defaultInstrumentCols: defaultCols }) => ({
                        id: instrument.id,
                        colValues: {
                            selected: holdInstrumentsMap[instrument.id],
                            excludedFromBuying: excludedBuyMap[instrument.id],
                            name: defaultCols.name,
                            type: defaultCols.type,
                            price: defaultCols.price,
                            performance: null,
                        },
                        extra: {
                            onChangeHoldInstrument,
                            onChangeBuyInstrument,
                            openPastPerformanceModal: openPerformanceChart,
                            isExcludedFromBuyingInParent: isInstrumentExcludedFromBuyingInParent(instrument.id),
                        },
                    }),
                }}
                listActions={getListActions()}
            />
            <div className="ChangeUniverseActions">
                <TextButton
                    variant="bare"
                    id="update_investment_universe_button"
                    label={`${labelPrefix}.configuration.settings.sections.universe.cancel`}
                    onClick={closeModal}
                />
                <TextButton
                    id="cancel_update_investment_universe_button"
                    label={`${labelPrefix}.configuration.settings.sections.universe.confirm_changes`}
                    onClick={confirmUniverseChanges}
                    disabled={initialHoldInstruments === selectedHoldInstruments
                        && initialExcludedBuyInstrumentsMap === excludedBuyMap}
                />
            </div>
            <Modal
                id="universe_instrument_past_performance"
                title={{
                    msg: `${labelPrefix}.configuration.settings.sections.universe.instrument_performance_modal_title`,
                }}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                maxWidth="md"
            >
                <InstrumentPerformancePast />
            </Modal>
        </div>
    );

    function isInstrumentExcludedFromBuyingInParent(instrumentId: string): boolean {
        return excludedInParentMap[instrumentId];
    }

    function getListActions(): IListAction[] {
        return [{
            id: 'make_buy-universe_same_as_hold-universe',
            label: `${labelPrefix}.configuration.settings.sections.universe.make_buy_universe_same_as_hold_universe`,
            onClick: makeBuyUniverseSameAsHoldUniverse,
            icon: <DuplicateIcon />,
        }];
    }

    function updateShowOnlySelectedInstrumentsFilter({ checked }: IOnChangeCheckboxProps) {
        dispatch(updateUniverseInstrumentsPageVars({
            showOnlySelectedInstruments: checked,
        }));

        triggerFetchInstrumentsBasedOnFilters({
            selectedInstruments: selectedHoldInstruments,
            forceRefresh: false,
        });
    }

    function openPerformanceChart(isin: string) {
        setIsModalOpen(true);

        triggerFetchInstrumentPerformancePast({
            isin,
            currency: getPolicyBaseCurrency(),
            universe_type: UniverseType.Robo,
            startDate: formatDate({
                date: yearOffset({ yearsToAdd: -10 }),
                format: DATE_FORMAT.BACK_END,
            }),
            sampleFrequency: SampleFrequencyPerformancePast.BM,
            instrumentAmount: 1,
        });
    }

    function onChangeHoldInstrument({
        instrumentId,
        checked,
    }: TOnChangeHoldInstrumentProps) {
        if (checked) {
            holdInstrumentsMap[instrumentId] = true;
        } else {
            delete holdInstrumentsMap[instrumentId];
        }
        setSelectedHoldInstruments(
            Object.keys(holdInstrumentsMap),
        );
    }

    function onChangeBuyInstrument({
        instrumentId,
        checked,
    }: TOnChangeHoldInstrumentProps) {
        const newExcludedBuyMap: TKeyMap = {
            ...excludedBuyMap,
        };
        if (checked) {
            newExcludedBuyMap[instrumentId] = true;
        } else {
            delete newExcludedBuyMap[instrumentId];
        }
        setExcludedBuyMap(newExcludedBuyMap);
    }

    function makeBuyUniverseSameAsHoldUniverse() {
        setExcludedBuyMap(excludedInParentMap);
    }

    function confirmUniverseChanges() {
        setFieldValue({
            fieldName: fields.investmentUniverse.fieldName,
            value: selectedHoldInstruments,
        }, {
            fieldName: fields.excludedBuyInstrumentsMap.fieldName,
            value: excludedBuyMap,
        });
        closeModal();
    }
}

export default observe<IChangeInvestmentUniverseProps>(
    [StateChangeNotification.UI_PAGE_UNIVERSE_INSTRUMENTS_FILTER],
    ChangeInvestmentUniverse,
);
