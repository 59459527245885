import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import getConfiguredStore from 'state/setup/getConfiguredStore';
import 'state/onRouteConfig';
import 'views/routeConfigs';
import { StoreProvider } from 'views/observe';
import AppThemeProvider from './providers/AppThemeProvider';
import I18nProvider from './providers/I18nProvider';
import AppTemplate from './AppTemplate';

function App() {
    return (
        <div className="App">
            <StoreProvider value={getConfiguredStore()}>
                <AppThemeProvider>
                    <Router>
                        <I18nProvider>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                                <AppTemplate />
                            </MuiPickersUtilsProvider>
                        </I18nProvider>
                    </Router>
                </AppThemeProvider>
            </StoreProvider>
        </div>
    );
}

export default App;
