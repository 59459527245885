/**
 * The order of the OPERATION_PERMISSION_KEY's is important !!!!
 * Because this order will be kept while displaying all the possible
 * operation permissions in the user group detail screen.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OPERATION_PERMISSION_KEY {
    GOALS_CREATE = 'GOAL_CREATE',
    GOALS_READ = 'GOAL_READ',
    GOALS_MODIFY = 'GOAL_MODIFY',

    HORIZONS_CREATE = 'HORIZON_CREATE',
    HORIZONS_READ = 'HORIZON_READ',
    HORIZONS_MODIFY = 'HORIZON_MODIFY',

    RISKPROFILES_CREATE = 'RISKPROFILE_CREATE',
    RISKPROFILES_READ = 'RISKPROFILE_READ',
    RISKPROFILES_MODIFY = 'RISKPROFILE_MODIFY',

    POLICIES_CREATE = 'POLICY_CREATE',
    POLICIES_READ = 'POLICY_READ',
    POLICIES_MODIFY = 'POLICY_MODIFY',

    BASE_POLICIES_CREATE = 'BASE_POLICY_CREATE',
    BASE_POLICIES_MODIFY = 'BASE_POLICY_MODIFY',

    PORTFOLIOS_CREATE = 'PORTFOLIO_CREATE',
    PORTFOLIOS_READ = 'PORTFOLIO_READ',
    PORTFOLIOS_MODIFY = 'PORTFOLIO_MODIFY',
    PORTFOLIOS_MODIFY_OWNER = 'PORTFOLIO_MODIFY_OWNER',

    PORTFOLIO_GROUPS_CREATE = 'PORTFOLIOGROUP_CREATE',
    PORTFOLIO_GROUPS_READ = 'PORTFOLIOGROUP_READ',
    PORTFOLIO_GROUPS_MODIFY = 'PORTFOLIOGROUP_MODIFY',

    PORTFOLIOREPORT_CREATE = 'PORTFOLIOREPORT_CREATE',
    PORTFOLIOREPORT_READ = 'PORTFOLIOREPORT_READ',
    PORTFOLIOREPORT_MODIFY = 'PORTFOLIOREPORT_MODIFY',

    PORTFOLIOVALUATIONS_CREATE = 'PORTFOLIOVALUATION_CREATE',
    PORTFOLIOVALUATIONS_READ = 'PORTFOLIOVALUATION_READ',
    PORTFOLIOVALUATIONS_MODIFY = 'PORTFOLIOVALUATION_MODIFY',

    OPTIMIZATIONS_CREATE = 'OPTIMIZATION_CREATE',
    OPTIMIZATIONS_READ = 'OPTIMIZATION_READ',
    OPTIMIZATIONS_MODIFY = 'OPTIMIZATION_MODIFY',

    TRANSACTIONS_CREATE = 'TRANSACTION_CREATE',
    TRANSACTIONS_READ = 'TRANSACTION_READ',
    TRANSACTIONS_MODIFY = 'TRANSACTION_MODIFY',

    USERGROUPS_CREATE = 'USERGROUP_CREATE',
    USERGROUPS_READ = 'USERGROUP_READ',
    USERGROUPS_MODIFY = 'USERGROUP_MODIFY',

    USERS_CREATE = 'USER_CREATE',
    USERS_READ = 'USER_READ',
    USERS_MODIFY = 'USER_MODIFY',

    AGREEMENTS_CREATE = 'AGREEMENT_CREATE',
    AGREEMENTS_READ = 'AGREEMENT_READ',
    AGREEMENTS_MODIFY = 'AGREEMENT_MODIFY',

    PUBLIC_INSTRUMENTS_CREATE = 'INSTRUMENT_CREATE',
    PUBLIC_INSTRUMENTS_READ = 'INSTRUMENT_READ',
    PUBLIC_INSTRUMENTS_MODIFY = 'INSTRUMENT_MODIFY',

    PUBLIC_INSTRUMENTGROUPS_CREATE = 'INSTRUMENTGROUP_CREATE',
    PUBLIC_INSTRUMENTGROUPS_READ = 'INSTRUMENTGROUP_READ',
    PUBLIC_INSTRUMENTGROUPS_MODIFY = 'INSTRUMENTGROUP_MODIFY',

    SUITABILITYPROFILER_PROFILERS_CREATE = 'SUITABILITYPROFILERPROFILER_CREATE',
    SUITABILITYPROFILER_PROFILERS_READ = 'SUITABILITYPROFILERPROFILER_READ',
    SUITABILITYPROFILER_PROFILERS_MODIFY = 'SUITABILITYPROFILERPROFILER_MODIFY',

    SUITABILITYPROFILER_QUESTIONNAIRES_CREATE = 'SUITABILITYPROFILERQUESTIONNAIRE_CREATE',
    SUITABILITYPROFILER_QUESTIONNAIRES_READ = 'SUITABILITYPROFILERQUESTIONNAIRE_READ',
    SUITABILITYPROFILER_QUESTIONNAIRES_MODIFY = 'SUITABILITYPROFILERQUESTIONNAIRE_MODIFY',

    SUITABILITYPROFILER_QUESTIONS_CREATE = 'SUITABILITYPROFILERQUESTION_CREATE',
    SUITABILITYPROFILER_QUESTIONS_READ = 'SUITABILITYPROFILERQUESTION_READ',
    SUITABILITYPROFILER_QUESTIONS_MODIFY = 'SUITABILITYPROFILERQUESTION_MODIFY',

    SUITABILITYPROFILER_PROFILES_CREATE = 'SUITABILITYPROFILERPROFILE_CREATE',
    SUITABILITYPROFILER_PROFILES_READ = 'SUITABILITYPROFILERPROFILE_READ',
    SUITABILITYPROFILER_PROFILES_MODIFY = 'SUITABILITYPROFILERPROFILE_MODIFY',

    RISKPROFILEQUESTIONRESPONSE_CREATE = 'RISKPROFILEQUESTIONRESPONSE_CREATE',
    RISKPROFILEQUESTIONRESPONSE_READ = 'RISKPROFILEQUESTIONRESPONSE_READ',
    RISKPROFILEQUESTIONRESPONSE_MODIFY = 'RISKPROFILEQUESTIONRESPONSE_MODIFY',

    STORYMANAGER_READ = 'STORYMANAGER_READ',
    STORYMANAGER_SCENARIO_MODIFY = 'STORYMANAGER_SCENARIO_MODIFY',
    STORYMANAGER_TEXTOUTPUT_MODIFY = 'STORYMANAGER_TEXTOUTPUT_MODIFY',
    STORYMANAGER_PUBLISH = 'STORYMANAGER_PUBLISH',

    /* for the document-api */
    DOCUMENTS_CREATE = 'DOCUMENT_CREATE',
    DOCUMENTS_READ = 'DOCUMENT_READ',
    DOCUMENTS_MODIFY = 'DOCUMENT_MODIFY',

    SYSTEM_FIELD_MODIFY = 'SYSTEM_FIELD_MODIFY',
    ACL_MODIFY = 'ACL_MODIFY',
}

export const ALL_OPERATION_PERMISSION_KEYS = Object.values(OPERATION_PERMISSION_KEY);
