import React, { ReactNode, MutableRefObject } from 'react';
import clsx from 'clsx';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
import isSet from '@snipsonian/core/cjs/is/isSet';
import appendQueryParams from '@snipsonian/core/cjs/url/appendQueryParams';
import { FOCUS_OUTLINE, FOCUS_OUTLINE_OFFSET } from 'config/styling/elevation';
import { IPathParams, IQueryParams } from 'models/routing.models';
import { makeStyles } from 'views/styling';
import { ROUTE_KEY } from 'views/routeKeys';
import { getRoute } from 'views/routes';
import replacePathPlaceholders from 'utils/routing/replacePathPlaceholders';

export interface IPublicProps extends Pick<NavLinkProps, 'exact'> {
    id?: string;
    to: ROUTE_KEY;
    params?: IPathParams;
    query?: IQueryParams;
    // flashMessageLink?: boolean;
    children: ReactNode;
    className?: string;
    innerRef?: ((instance: HTMLAnchorElement) => void) | MutableRefObject<HTMLAnchorElement>;
    onClick?: () => void;
    inheritColor?: boolean; // default false
}

const ACTIVE_CLASS_NAME = 'active';

const useStyles = makeStyles((theme) => ({
    routeLink: {
        '&:not(.MuiButton-root)': { // Don't add link styling if it's a Mui Button
            textDecoration: 'none',
            // color: theme.palette.text.secondary,
            '&.active': {
                color: theme.palette.primary.main,
            },
        },

        '&.inheritColor': {
            color: 'inherit',
        },

        '&:focus': {
            outline: 0,
        },
        '&:focus:not(:hover)': {
            outline: FOCUS_OUTLINE,
            outlineOffset: FOCUS_OUTLINE_OFFSET,
        },
    },
}));

function RouteLink(props: IPublicProps) {
    const { to: routeKey, id, params, query, exact, children, className, onClick, inheritColor } = props;
    const classes = useStyles();

    const route = getRoute({ routeKey });

    const shouldMatchExact = isSet(exact)
        ? exact
        : false;

    const urlTo = appendQueryParams({
        url: replacePathPlaceholders({
            path: route.path,
            placeholders: params,
        }),
        params: query,
    });

    return (
        <RouterNavLink
            id={id}
            to={urlTo}
            exact={shouldMatchExact}
            activeClassName={ACTIVE_CLASS_NAME}
            className={clsx(classes.routeLink, className, inheritColor && 'inheritColor')}
            ref={props.innerRef}
            onClick={onClick}
        >
            {children}
        </RouterNavLink>
    );
}

export default RouteLink;
