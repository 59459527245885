import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from 'views/styling';
import { hexToRgba } from 'utils/styling/colorUtils';

const useStyles = makeStyles((theme) => ({
    ContentDivider: {
        backgroundColor: hexToRgba('#253540', 0.08),
        margin: theme.spacing(2, -3),
    },
}));

export default function ContentDivider() {
    const classes = useStyles();

    return (
        <Divider className={classes.ContentDivider} />
    );
}
