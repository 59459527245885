import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getSelectedStoryManagerDatabaseOutputKey } from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { isStoryManagerInScenarioMode } from 'state/ui/storyManager.selector';
import { canUserModifyStoryManagerScenarios } from 'state/auth/apiEntityAuthorization.selectors';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import { observe, IObserveProps } from 'views/observe';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import { IFormValues } from 'views/common/inputs/extended/types';
import { mixins } from 'views/styling';
import ScenariosList from './ScenariosList';
import OutputKeyDetailsForm from './OutputKeyDetailsForm';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.detail';

const useStyles = makeStyles((theme) => ({
    OutputKeyDetail: {
        paddingBottom: theme.spacing(5),
        '& > :first-child': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        '& .input-label': {
            ...mixins.typo({ size: 16, weight: 700 }),
        },
    },
}));

export interface IOutputKeyDetailsFormValues extends IFormValues {
    description: string;
}

function OutputKeyDetail({ state }: IObserveProps) {
    const classes = useStyles();
    const { outputKeyId } = useRouteParams();
    const selectedOutputKey = getSelectedStoryManagerDatabaseOutputKey(outputKeyId);

    if (!selectedOutputKey) {
        return null;
    }

    const isScenarioEditorMode = isStoryManagerInScenarioMode(state);
    const canUserModifyScenarios = canUserModifyStoryManagerScenarios(state);

    return (
        <div className={classes.OutputKeyDetail}>
            <OutputKeyDetailsForm
                outputKey={selectedOutputKey}
                isScenarioEditorMode={isScenarioEditorMode}
                canUserModifyScenarios={canUserModifyScenarios}
            />
            <InputWrapper label={`${TRANSLATION_PREFIX}.scenario_list.label`}>
                <ScenariosList
                    outputKeyId={outputKeyId}
                    outputKey={selectedOutputKey}
                    isScenarioEditorMode={isScenarioEditorMode}
                    canUserModifyScenarios={canUserModifyScenarios}
                />
            </InputWrapper>
        </div>
    );
}

export default observe(
    [
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
        StateChangeNotification.STORY_MANAGER_DATABASES,
    ],
    OutputKeyDetail,
);
