import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { makeStyles, mixins } from 'views/styling';
import { IEntityWrapperProps, initEntityWrapper, IRenderDataProps } from 'views/common/widget/EntityWrapper';
import Tabs, { ITabItem, ITabsProps, IWrapperComponentProps } from './Tabs';

export interface ITabsWithEntityWrapperProps extends Omit<ITabsProps, 'WrapperComponent' | 'items'> {
    entity: {
        notifications: StateChangeNotification[];
    } & Pick<IEntityWrapperProps, 'asyncEntitySelector' | 'markAsPositionRelative' | 'loaderPosition'>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: IConditionalTabItem<any>[];
}

export interface IConditionalTabItem<EntityData> extends ITabItem {
    showBasedOnEntity?: (props: { entityData: EntityData }) => boolean; // default true
}

const useStyles = makeStyles((/* theme */) => ({
    ConditionalTabsWrapper: {
        ...mixins.widthMax(),
    },
}));

export default function TabsWithEntityWrapper({
    entity,
    items,
    ...other
}: ITabsWithEntityWrapperProps) {
    const classes = useStyles();
    const areThereConditionalItemsByEntity = items.some((item) => isSet(item.showBasedOnEntity));

    const EntityWrapper = initEntityWrapper({
        /**
         * We don't want this wrapper listening to notifications if we have conditional tab items based on entity.
         * The ConditionalTabsEntityWrapper should listen instead, as it filters the tabs based on entity data.
         * If both wrappers were listening at the same time they would cause a React State Warning: "Can't perform
         * a React state update on a unmounted component" as they are within each other and would both re-render.
         */
        notifications: !areThereConditionalItemsByEntity ? entity.notifications : [],
    });

    if (areThereConditionalItemsByEntity) {
        return (
            <ConditionalTabsWrapper />
        );
    }

    return (
        <Tabs
            WrapperComponent={EntityTabWrapper}
            items={items}
            {...other}
        />
    );

    function ConditionalTabsWrapper() {
        const ConditionalTabsEntityWrapper = initEntityWrapper({
            notifications: entity.notifications,
        });

        return (
            <ConditionalTabsEntityWrapper
                className={classes.ConditionalTabsWrapper}
                asyncEntitySelector={entity.asyncEntitySelector}
                shouldRenderIfNoDataSet
                renderData={renderConditionalTabs}
                markAsPositionRelative={entity.markAsPositionRelative}
                loaderPosition={entity.loaderPosition}
            />
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function renderConditionalTabs({ entityData, isAnyAsyncOperationBusy } : IRenderDataProps<any>) {
            const filteredItems = items.filter((item) => {
                if (isSet(item.showBasedOnEntity)) {
                    if (isAnyAsyncOperationBusy || !entityData) {
                        /* while the entity is being fetched, we assume we don't show the conditional tabs */
                        return false;
                    }

                    return item.showBasedOnEntity({
                        entityData,
                    });
                }

                return true;
            });

            return (
                <Tabs
                    WrapperComponent={EntityTabWrapper}
                    items={filteredItems}
                    {...other}
                />
            );
        }
    }

    function EntityTabWrapper({ renderWrapped }: IWrapperComponentProps) {
        return (
            <EntityWrapper
                asyncEntitySelector={entity.asyncEntitySelector}
                shouldRenderIfNoDataSet={false}
                renderData={renderTabContent}
                markAsPositionRelative={entity.markAsPositionRelative}
                loaderPosition={entity.loaderPosition}
            />
        );

        function renderTabContent() {
            return (
                <>
                    {renderWrapped()}
                </>
            );
        }
    }
}
