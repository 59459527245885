import React from 'react';
import Popover from '@material-ui/core/Popover';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS, BUTTON_COLORS, HEADER_COLORS, hexToRgba, OPACITY } from 'config/styling/colors';
import { BOX_SHADOW, Z_INDEX } from 'config/styling/elevation';
import { getAuthenticatedUser, getUserDisplayName, getUserInitials, isAuthenticatedUser } from 'state/auth/selectors';
import { logout } from 'state/auth/actions';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import TextButton from 'views/common/buttons/TextButton';
import Card from 'views/common/layout/Card';
import { toButtonBorder } from 'views/common/buttons/buttonUtils';

const useStyles = makeStyles((theme) => ({
    AuthenticatedUser: {
        ...mixins.flexRowCenterRight(),
        marginLeft: theme.spacing(2),

        '& button': {
            ...mixins.typo({ color: APP_COLORS.SYSTEM.BACKGROUND, weight: 'bold' }),

            margin: theme.spacing(-1, -1),
            padding: theme.spacing(1, 1),
            width: 36, // 20 + 2 x padding of 8
            height: 36,
            // border: 0,
            border: toButtonBorder(HEADER_COLORS.USER.BACKGROUND),
            borderRadius: '50%',

            backgroundColor: HEADER_COLORS.USER.BACKGROUND,
        },
        '& button:hover': {
            border: toButtonBorder(BUTTON_COLORS.PRIMARY.BACKGROUND_HOVER),
            backgroundColor: BUTTON_COLORS.PRIMARY.BACKGROUND_HOVER,
        },
        '& button:focus:not(:hover)': {
            border: toButtonBorder(BUTTON_COLORS.PRIMARY.BACKGROUND_HOVER),
            backgroundColor: HEADER_COLORS.USER.BACKGROUND,
        },
    },
    userPopoverWrapper: {
        zIndex: Z_INDEX.POPOVER,

        '& .MuiPopover-paper': {
            minWidth: 220,
            // border: `1px solid ${APP_COLORS.SYSTEM.BACKGROUND}`,
            borderRadius: 12,
            boxShadow: BOX_SHADOW.DEFAULT,
            marginTop: theme.spacing(1),
        },

        '& .userPopover': {},
        '& .userDetails': {
            ...mixins.flexColTopLeft(),
            margin: theme.spacing(1, 0),
        },
        '& .userDisplayName': {
            ...mixins.typoBold(),
        },
        '& .userEmail': {
            ...mixins.typoSmallCaps({ color: hexToRgba(APP_COLORS.TEXT['500'], OPACITY.MEDIUM) }),
        },
        '& button': {
            ...mixins.typo({ size: 16 }),
            margin: 0,
        },
    },
}));

function AuthenticatedUser({ state, dispatch }: IObserveProps) {
    const classes = useStyles();
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
    const isPopperOpen = Boolean(popoverAnchorEl);

    if (!isAuthenticatedUser(state)) {
        return null;
    }

    const initials = getUserInitials(state);
    const displayName = getUserDisplayName(state);
    const { email } = getAuthenticatedUser(state);

    return (
        <div className={classes.AuthenticatedUser}>
            <TextButton
                id="open-user-pop"
                onClick={openUserPopover}
                label={{
                    text: initials,
                    shouldTranslate: false,
                }}
            />

            <Popover
                open={isPopperOpen}
                anchorEl={popoverAnchorEl}
                onClose={closeUserPopover}
                className={classes.userPopoverWrapper}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                marginThreshold={8}
            >
                <Card className="userPopover">
                    <div className="userDetails">
                        <span className="userDisplayName">{displayName}</span>
                        <span className="userEmail">{email}</span>
                    </div>
                    <TextButton
                        id="logout-action"
                        color="secondary"
                        variant="bare"
                        size="S"
                        onClick={doLogout}
                        label="auth.logout.do_logout"
                    />
                </Card>
            </Popover>
        </div>
    );

    function doLogout() {
        dispatch(logout());
    }

    function openUserPopover(event: React.MouseEvent<HTMLButtonElement>) {
        setPopoverAnchorEl(event.currentTarget);
    }

    function closeUserPopover() {
        setPopoverAnchorEl(null);
    }
}

export default observe(
    [StateChangeNotification.AUTH_USER],
    AuthenticatedUser,
);
