import { IRoute } from 'models/route.models';
import { ROUTE_KEY } from './routeKeys';
import { registerRoutes } from './routes';
import { AUTH_ROUTES } from './auth/routeConfigs';
import { MAIN_ROUTES } from './main/routeConfigs';
import { DOCUMENTATION_ROUTES } from './documentation/routeConfigs';
import { PORTFOLIO_MGMT_ROUTES } from './portfolioMgmt/routeConfigs';
import { USER_MGMT_ROUTES } from './userMgmt/routeConfigs';
import { APP_ROUTES } from './apps/routeConfigs';
import { THEMATIC_SEARCH_ROUTES } from './thematicSearch/routeConfigs';
import { MGMT_REPORTING_ROUTES } from './mgmtReporting/routeConfigs';
import NotFound from './common/error/NotFound';

const GENERAL_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_NOT_FOUND,
    path: '*',
    component: NotFound,
    appShell: {
        showBreadcrumbs: false,
    },
    breadcrumb: null,
}];

const ALL_ROUTES: IRoute<ROUTE_KEY>[] = [
    ...AUTH_ROUTES,
    ...MAIN_ROUTES,
    ...DOCUMENTATION_ROUTES,
    ...PORTFOLIO_MGMT_ROUTES,
    ...USER_MGMT_ROUTES,
    ...APP_ROUTES,
    ...THEMATIC_SEARCH_ROUTES,
    ...MGMT_REPORTING_ROUTES,
    /* General routes has to come at the end because the not-found-route has to come last */
    ...GENERAL_ROUTES,
];

registerRoutes(ALL_ROUTES);
