import React from 'react';
import { TLabel } from 'models/general.models';
import { CHART_STYLING } from 'config/styling/chart';
import Text from 'views/common/widget/Text';

export interface IYAxisLabelProps {
    label: TLabel;
    chartHeight: number;
    paddingLeft?: number;
}

export function YAxisLabel({
    label,
    chartHeight,
    paddingLeft = 16,
}: IYAxisLabelProps) {
    return (
        <g transform={`translate(${paddingLeft}, ${chartHeight / 2})`}>
            <text
                textAnchor="middle"
                transform="rotate(-90)"
                fontSize="10px"
                fontWeight="500"
                fill={CHART_STYLING.colors.neutral['900']}
            >
                <Text label={label} />
            </text>
        </g>
    );
}
