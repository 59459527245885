import React from 'react';
import clsx from 'clsx';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { MENU_COLORS } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { Z_INDEX } from 'config/styling/elevation';
import { getAppMenuStatus, getDoesUserPreferCollapsedMenu } from 'state/ui/selectors';
import { toggleUserPrefersCollapsedMenu } from 'state/ui/actions';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import OutgoingLink from 'views/common/routing/OutgoingLink';
import { NavCollapseIcon, NavUncollapseIcon } from 'views/common/icons';
import IconButton from 'views/common/buttons/IconButton';
import { APP_VERSION } from 'utils/env/appVersion';

const useStyles = makeStyles((theme) => ({
    AppFooter: {
        ...mixins.flexCol(),
        ...mixins.typo({ color: MENU_COLORS.FOOTER.TEXT, size: 14 }),

        position: 'sticky',
        bottom: 0,
        left: 0,
        zIndex: Z_INDEX.FOOTER,
        padding: theme.spacing(1),

        '& .poweredByLink': {
            ...mixins.typo({ color: MENU_COLORS.FOOTER.TEXT, weight: 'bold' }),
        },

        '&.collapsed': {
            width: SIZES.MENU.COLLAPSED_WIDTH,
            alignItems: 'center',
        },

        '& .row': {
            ...mixins.flexRow({ alignMain: 'space-between' }),
            width: `${SIZES.MENU.TOP_WIDTH - 16}px`,

            '&.row-center': {
                justifyContent: 'center',
            },
        },
        '&.collapsed .row': {
            width: 'unset',
        },
    },
    collapseIcon: {
        ...mixins.typo({ color: MENU_COLORS.CONTEXT.TEXT, size: 16 }),
        margin: theme.spacing(1, 0, 1, 0),
        padding: 0,

        '& svg': {
            stroke: MENU_COLORS.TOP.TEXT,
            width: 'unset',
            height: 26,
        },
        '& svg:hover': {
            stroke: MENU_COLORS.TOP.TEXT_HOVER,
        },
    },
}));

function AppFooter({ state, dispatch }: IObserveProps) {
    const classes = useStyles();
    const isAppMenuCollapsed = getAppMenuStatus(state) === 'collapsed';
    const doesUserPreferCollapsedMenu = getDoesUserPreferCollapsedMenu(state);
    const collapseTranslationKey = doesUserPreferCollapsedMenu
        ? 'app_shell.menu._meta.unpin_collapsed'
        : 'app_shell.menu._meta.pin_collapsed';

    return (
        <div className={clsx(classes.AppFooter, isAppMenuCollapsed && 'collapsed')}>
            <div className={clsx('row', 'row-center')}>
                <IconButton
                    id="toggle-collapse-navigation-menu"
                    aria-label="toggle collapsed"
                    className={classes.collapseIcon}
                    variant="bare"
                    size="XS"
                    onClick={togglePrefersCollapsed}
                    tooltip={collapseTranslationKey}
                    tooltipPlacement="right-start"
                    icon={doesUserPreferCollapsedMenu ? <NavUncollapseIcon /> : <NavCollapseIcon />}
                />
            </div>

            <div className="row">
                <AppVersion />
                { !isAppMenuCollapsed && <PoweredBy /> }
            </div>
        </div>
    );

    function togglePrefersCollapsed() {
        dispatch(toggleUserPrefersCollapsedMenu());
    }
}

export default observe(
    [StateChangeNotification.UI_APP_MENU],
    AppFooter,
);

function PoweredBy() {
    return (
        <div className="poweredBy">
            <Translate msg="app_shell.footer.app_by" />
            &nbsp;
            <OutgoingLink
                className="poweredByLink"
                href="https://www.investsuite.com/"
            >
                InvestSuite
            </OutgoingLink>
        </div>
    );
}

function AppVersion() {
    return (
        <span className="appVersion">v{APP_VERSION}</span>
    );
}
