import React from 'react';
import { TApiEntity } from '@console/core-api/models/api.models';
import { IPortfolioGroupEntityData } from '@console/core-api/models/portfolioMgmt/portfolioGroups.models';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { canUserModifyPortfolioGroup } from 'state/auth/apiEntityAuthorization.selectors';
import {
    portfolioGroupDetailsEntity,
    triggerPatchPortfolioGroupDetails,
} from 'state/entities/portfolioMgmt/portfolioGroupDetails';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import { addPortfolioGroupStepOneSchema } from './portfolioGroupDetailsSchema';
import PortfolioGroupPropertiesFormFields from '../shared/PortfolioGroupPropertiesFormFields';
import { TPortfolioGroupPropertiesFormValues } from '../shared/types';

export default function PortfolioGroupDetails() {
    const portfolioGroupData = portfolioGroupDetailsEntity.select().data;
    const isPortfolioReadOnly = !canUserModifyPortfolioGroup(portfolioGroupData);
    const LABEL_PREFIX_DETAIL = 'portfolio_mgmt.portfolio_groups.detail';

    function getDetailsFields(
        portfolioGroup: TApiEntity<IPortfolioGroupEntityData>,
    ): IDetailField[] {
        return [{
            label: 'name.label',
            value: portfolioGroup.name,
        }, {
            label: 'base_currency.label',
            value: portfolioGroup.currency,
        }];
    }

    return (
        <div>
            <PropertiesCategoryBox<TPortfolioGroupPropertiesFormValues>
                id="portfolio-group-details-fields"
                category="portfolio_group_details"
                fields={getDetailsFields(portfolioGroupData)}
                labelPrefix={LABEL_PREFIX_DETAIL}
                editGlobalForm={getEditDetailsForm(portfolioGroupData)}
                isReadOnly={isPortfolioReadOnly}
            />
        </div>
    );

    function getEditDetailsForm(
        portfolioGroup: TApiEntity<IPortfolioGroupEntityData>,
    ): IEditForm<TPortfolioGroupPropertiesFormValues> {
        return {
            initialValues: {
                name: portfolioGroup.name,
                currency: portfolioGroup.currency,
            },
            schema: addPortfolioGroupStepOneSchema,
            renderFormFields: PortfolioGroupPropertiesFormFields,
            submit: {
                onSubmit: (({ values }) => triggerPatchPortfolioGroupDetails((currentPortfolioGroup) => {
                    /* eslint-disable no-param-reassign */
                    currentPortfolioGroup.name = values.name;
                    currentPortfolioGroup.currency = values.currency;
                    /* eslint-enable no-param-reassign */
                })),
            },
            modalMaxWidth: 'sm',
            modalTitle: `${LABEL_PREFIX_DETAIL}.properties.modal_titles.portfolio_group_details`,
            labelPrefix: LABEL_PREFIX_DETAIL,
        };
    }
}
