import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { ITranslatorPlaceholders } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import { PolicyAlgorithmType } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { isPolicyOfTypeModelPortfolio } from '@console/bff/utils/policies/policyTypeUtils';
import { getPolicySettingsRules } from 'state/entities/portfolioMgmt/policyDetails';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ContentTitle from 'views/common/layout/ContentTitle';
import Text from 'views/common/widget/Text';
import { TAddPolicyFormValues } from '../PolicyDetail/policyFormContents/types';
import { ParentPolicyFormContent } from '../PolicyDetail/policyFormContents/policyDetailSectionFormContent';
import {
    PolicyBaseCurrencyFormContent,
} from '../PolicyDetail/policyFormContents/policyExecutionConstraintsSectionFormContent';
import {
    renderPolicyPortfolioMinOptimalityImprovementFormContent,
    renderPolicyPortfolioMaxConstraintsViolationFormContent,
    renderPolicyPortfolioMinPortfolioValueFormContent,
} from '../PolicyDetail/policyFormContents/policyPortfolioUpdateConstraintsSectionFormContent';
import {
    renderPolicyCashAmountFormContent,
} from '../PolicyDetail/policyFormContents/policyPortfolioConstraintsSectionFormContent';
import {
    renderPolicyBenchmarkSelectionFormField,
} from '../PolicyDetail/policyFormContents/policyBenchmarkSectionFormContent';
import { PolicyAlgorithmTypeFormContent } from './policyAlgorithmTypeFormContent';
import PolicyComposition from '../PolicyComposition/PolicyComposition';

export function renderPolicyStepOneAddFormContents(context: IExtendedInputFormContext<TAddPolicyFormValues>) {
    const policySettingsRules = getPolicySettingsRules({
        parentPolicy: context.fields.selectedParentPolicy.value as TPolicy,
    });

    const selectedAlgorithm = context.fields.algorithm.value as PolicyAlgorithmType;
    const isAlgorithmTypeSet = isSet(selectedAlgorithm);

    return (
        <>
            <PolicyAlgorithmTypeFormContent
                {...context}
            />

            {isAlgorithmTypeSet && (
                <ParentPolicyFormContent
                    {...context}
                    policySettingsRules={policySettingsRules}
                />
            )}
        </>
    );
}

export function renderPolicyStepTwoAddFormContents({
    fields,
}: IExtendedInputFormContext<TAddPolicyFormValues>) {
    const policySettingsRules = getPolicySettingsRules({
        parentPolicy: fields.selectedParentPolicy.value as TPolicy,
    });

    return (
        <>
            <PolicyBaseCurrencyFormContent
                fields={fields}
                smallerSize={false}
                policySettingsRules={policySettingsRules}
            />

            {renderPolicyBenchmarkSelectionFormField({
                formField: fields.benchmarkId,
                policySettingsRules,
                usedDuringCreate: true,
            })}
        </>
    );
}

export function renderPolicyStepThreeAddFormContents({
    fields,
    setFieldValue,
}: IExtendedInputFormContext<TAddPolicyFormValues>) {
    const algorithm = fields.algorithm.value as PolicyAlgorithmType;
    const compositionFormField = isPolicyOfTypeModelPortfolio(algorithm)
        ? fields.modelPortfolioComposition
        : fields.metaPortfolioComposition;

    return (
        <div>
            {renderPolicyCompositionTitleAndInfo(algorithm)}
            <PolicyComposition
                compositionFormField={compositionFormField}
                setCompositionFormFieldValue={setFieldValue}
                policyAlgorithmType={algorithm}
                currency={fields.baseCurrency.value as SupportedCurrency}
            />
        </div>
    );
}

function renderPolicyCompositionTitleAndInfo(algorithmType: PolicyAlgorithmType) {
    const algorithmPlaceholders: ITranslatorPlaceholders = {
        algorithmType: algorithmType.split('_')[0].toLowerCase(),
    };

    return (
        <>
            <ContentTitle
                label={{
                    msg: 'portfolio_mgmt.policies.add.composition.title',
                    placeholders: algorithmPlaceholders,
                }}
                variant="list-section-open"
            />
            <div className="compositionInfoSpacing">
                <Text
                    label={{
                        msg: 'portfolio_mgmt.policies.add.composition.info',
                        placeholders: algorithmPlaceholders,
                    }}
                />
            </div>
        </>
    );
}

export function renderPolicyStepFourAddFormContents(context: IExtendedInputFormContext<TAddPolicyFormValues>) {
    const policySettingsRules = getPolicySettingsRules({
        parentPolicy: context.fields.selectedParentPolicy.value as TPolicy,
    });

    return (
        <>
            {renderPolicyPortfolioMinOptimalityImprovementFormContent(context, {
                smallerSize: false,
                policySettingsRules,
            })}
            {renderPolicyPortfolioMaxConstraintsViolationFormContent(context, {
                smallerSize: false,
                policySettingsRules,
            })}
            {renderPolicyCashAmountFormContent(context, {
                smallerSize: false,
                policySettingsRules,
                shouldShowLabelInBold: true,
            })}
            {renderPolicyPortfolioMinPortfolioValueFormContent(context, {
                smallerSize: false,
                policySettingsRules,
            })}
        </>
    );
}

export function renderPolicyStepFiveAddFormContents({
    fields,
}: IExtendedInputFormContext<TAddPolicyFormValues>) {
    return (
        <>
            <ExtendedInputText
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.add_label',
                }}
            />
        </>
    );
}
