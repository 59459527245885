import { TObjectWithProps } from '../../models/genericTypes.models';
import { diffObjects } from './diffObjects';

/**
 * Compares 2 objects and returns an object with only the changed properties
 * (of the 2nd object compared to the 1st object).
 * If there is a change in a nested property, the whole top level prop is returned.
 */
export function getOnlyChangedProperties(
    baseObject: TObjectWithProps,
    modifiedObject: TObjectWithProps,
): TObjectWithProps {
    const diffResult = diffObjects(baseObject, modifiedObject, { deepDiff: false });

    const { areDiffsDetected, diffs } = diffResult;

    if (!areDiffsDetected) {
        return {};
    }

    return diffs.reduce(
        (accumulator, { propChain, slave }) => {
            accumulator[propChain[0]] = slave;

            return accumulator;
        },
        {} as TObjectWithProps,
    );
}
