import React, { useState } from 'react';
import clsx from 'clsx';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';

export interface ITooltipProps {
    label: TLabel;
    placement?: TTooltipPlacement; // default "top"
    children: React.ReactNode;
    className?: string;
    enableClickListener?: boolean; // default false, if true it disables other listeners
    disableFocusListener?: boolean; // default false
    disableHoverListener?: boolean; // default false
    disableTouchListener?: boolean; // default false
    touchDelayValue?: number; // value which delays click/touch event on touchable devices
    showArrow?: boolean; // default false
    enterDelay?: number;
}

export type TTooltipParams = Omit<ITooltipProps, 'children'>;

export type TTooltipPlacement =
    'bottom-end' | 'bottom-start' | 'bottom' |
    'left-end' | 'left-start' | 'left' |
    'right-end' | 'right-start' | 'right' |
    'top-end' | 'top-start' | 'top';

const useStyles = makeStyles((/* theme */) => ({
    Tooltip: {},
    TooltipLabel: {
        ...mixins.typo({ size: 14 }),
    },
}));

export default function Tooltip({
    label,
    placement = 'top',
    children,
    className,
    enableClickListener = false,
    disableFocusListener = false,
    disableHoverListener = false,
    disableTouchListener = false,
    touchDelayValue = 350,
    showArrow = false,
    enterDelay = 0,
}: ITooltipProps) {
    const classes = useStyles();

    const title = <Text label={label} />;

    const [isOpen, setIsOpen] = useState(false);

    const handleTooltipOpen = () => {
        setIsOpen(true);
    };

    const handleTooltipClose = () => {
        setIsOpen(false);
    };

    if (enableClickListener) {
        return (
            <MuiClickAwayListener onClickAway={handleTooltipClose}>
                {renderTooltip({
                    open: isOpen,
                    onClick: handleTooltipOpen,
                })}
            </MuiClickAwayListener>
        );
    }

    return renderTooltip();

    /**
     * The extra wrapper div inside the Tooltip is needed by the mui tooltip to be able to give a ref.
     * See https://material-ui.com/guides/composition/#caveat-with-refs
     */
    function renderTooltip(extraProps: TAnyObject = {}) {
        return (
            <MuiTooltip
                title={title}
                placement={placement}
                className={clsx(classes.Tooltip, className)}
                classes={{
                    tooltip: classes.TooltipLabel,
                }}
                disableFocusListener={disableFocusListener}
                disableHoverListener={disableHoverListener}
                disableTouchListener={disableTouchListener}
                enterTouchDelay={touchDelayValue}
                arrow={showArrow}
                enterDelay={enterDelay}
                enterNextDelay={enterDelay}
                {...extraProps}
            >
                <div className="TooltipContentWrapper">
                    {children}
                </div>
            </MuiTooltip>
        );
    }
}
