import { IState } from 'models/state.models';
import { ISingleTooltipFilter } from 'models/state/ui.models';
import { getUserId } from 'state/auth/selectors';

export const isTooltipDismissedByUser = (
    state: IState,
    { tooltipKey }: ISingleTooltipFilter,
): boolean => {
    const notAgainTooltipsByUser = state.ui.tooltips.notAgain[getUserId(state)];

    if (notAgainTooltipsByUser && notAgainTooltipsByUser[tooltipKey]) {
        return true;
    }

    const closedTooltipsByUser = state.ui.tooltips.closed[getUserId(state)];

    if (closedTooltipsByUser && closedTooltipsByUser[tooltipKey]) {
        return true;
    }

    return false;
};

export const getTooltipKeyAutomaticallyShown = (state: IState) => state.ui.tooltips.tooltipKeyAutomaticallyShown;

export function shouldShowTooltipAutomatically(
    state: IState,
    { tooltipKey }: ISingleTooltipFilter,
): boolean {
    return tooltipKey === getTooltipKeyAutomaticallyShown(state);
}
