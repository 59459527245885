import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { observe, IObserveProps } from 'views/observe';
import ButtonGroup from 'views/common/buttons/ButtonGroup';
import { ScenarioEditorIcon, TextEditorIcon, FindAndReplaceIcon } from 'views/common/icons';
import { makeStyles } from '@material-ui/core';
import {
    storyManagerDatabaseDetailEntity,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { isUpdateBusy } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getButtonBackgroundColor, getButtonBorder, getButtonIconColor } from 'views/common/buttons/buttonUtils';
import { getStoryManagerEditorMode } from 'state/ui/storyManager.selector';
import { setStoryManagerPageVars } from 'state/ui/uiPages.actions';
import Spinner from 'views/common/loading/Spinner';
import IconButton from 'views/common/buttons/IconButton';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { getCurrentRouteLocation } from 'state/ui/selectors';
import { redirectToStoryManagerFindAndReplaceAndResetView } from 'state/ui/storyManager.actions';

const useStyles = makeStyles((theme) => ({
    StoryManagerActionsHeader: {
        margin: theme.spacing(1, 0),

        '& .inactive': {
            background: getButtonBackgroundColor({ color: 'primary', state: 'disabled' }),
            border: getButtonBorder({ color: 'primary', state: 'disabled' }),

            '& svg': {
                fill: getButtonIconColor({ variant: 'filled', color: 'primary', state: 'disabled' }),
            },
        },
    },
    CustomAction: {
        flex: 1,
        display: 'flex',
        marginLeft: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',

        '& > .spinner': {
            paddingTop: theme.spacing(1),
        },
    },
    CustomActionButtons: {
        display: 'flex',
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
}));

const SCENARIO_EDITOR_BUTTON_KEY = 'scenario';
const OUTPUT_EDITOR_BUTTON_KEY = 'text';

function StoryManagerActionsHeader({ dispatch, state }: IObserveProps) {
    const classes = useStyles();

    const editorMode = getStoryManagerEditorMode(state);
    const databaseEntity = storyManagerDatabaseDetailEntity.select();
    const databaseId = databaseEntity.data?.id;
    const isUpdating = isUpdateBusy(databaseEntity);
    const isOnFindAndReplaceRoute =
        getCurrentRouteLocation(state)?.routeKey === ROUTE_KEY.R_STORY_MANAGER_DATABASE_FIND_AND_REPLACE;

    return (
        <div className={classes.CustomAction}>
            {isUpdating ? <Spinner className="spinner" size="S" /> : <div />}
            <div className={classes.CustomActionButtons}>
                <ButtonGroup
                    id="storymanager-editor-switch"
                    className={classes.StoryManagerActionsHeader}
                    buttonType="icon"
                    inactiveColor="grey_inverted"
                    activeKey={editorMode === 'scenario' ? SCENARIO_EDITOR_BUTTON_KEY : OUTPUT_EDITOR_BUTTON_KEY}
                    buttons={[{
                        key: SCENARIO_EDITOR_BUTTON_KEY,
                        icon: <ScenarioEditorIcon />,
                        onClick: setScenarioEditorMode,
                        tooltip: 'apps.story_teller.output_keys.template.actions.scenario_editor',
                    }, {
                        key: OUTPUT_EDITOR_BUTTON_KEY,
                        icon: <TextEditorIcon />,
                        onClick: setTextEditorMode,
                        tooltip: 'apps.story_teller.output_keys.template.actions.output_editor',
                    }]}
                />
                <IconButton
                    id="storymanager-to-search"
                    icon={<FindAndReplaceIcon />}
                    size="S"
                    color={isOnFindAndReplaceRoute ? 'primary' : 'grey_inverted'}
                    onClick={toggleToFindAndReplace}
                    tooltip="apps.story_teller.output_keys.template.actions.find_and_replace"
                />
            </div>
        </div>

    );

    function setScenarioEditorMode() {
        if (editorMode !== 'scenario') {
            dispatch(setStoryManagerPageVars(
                { editorMode: 'scenario' },
                [StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE],
            ));
        }
    }

    function setTextEditorMode() {
        if (editorMode !== 'text') {
            dispatch(setStoryManagerPageVars(
                { editorMode: 'text' },
                [StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE],
            ));
        }
    }

    function toggleToFindAndReplace() {
        if (isOnFindAndReplaceRoute) {
            redirectTo({
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
                params: { databaseId },
            });
        } else {
            redirectToStoryManagerFindAndReplaceAndResetView();
        }
    }
}

export default observe(
    [
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
    ],
    StoryManagerActionsHeader,
);
