import React, { ReactNode } from 'react';
import { makeStyles, mixins } from 'views/styling';
import TextButton from 'views/common/buttons/TextButton';
import { TI18nLabelOrString } from 'models/general.models';
import { TTooltipPlacement } from '../widget/Tooltip';

const useStyles = makeStyles((theme) => ({
    ListActions: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
        ...mixins.flexRowCenterRight(),
        '& .__action': {
            paddingLeft: theme.spacing(5),
        },
    },
}));

export interface IListAction {
    id: string;
    label: TI18nLabelOrString;
    onClick: () => void;
    icon: ReactNode;
    disabled?: boolean;
    tooltip?: TI18nLabelOrString;
    tooltipPlacement?: TTooltipPlacement;
    showLoaderWhenAsync?: boolean; // default true
}

interface IPublicProps {
    actions: IListAction[];
}

export default function ListActionsHeader({ actions }: IPublicProps) {
    const classes = useStyles();

    return (
        <div className={classes.ListActions}>
            {actions.map((action) => (
                <TextButton
                    key={action.id}
                    id={action.id}
                    className="__action"
                    variant="bare"
                    disabled={action.disabled}
                    tooltip={action.tooltip}
                    tooltipPlacement={action.tooltipPlacement}
                    label={action.label}
                    onClick={action.onClick}
                    iconPlacement="end"
                    icon={action.icon}
                    showLoaderWhenAsync={action.showLoaderWhenAsync}
                />
            ))}
        </div>
    );
}
