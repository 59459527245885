import React from 'react';
import { IValueLineItem, TXScale, TYScale } from 'views/common/charts/types';
import { AxisDomain } from 'utils/libs/d3';
import { CHART_STYLING } from 'config/styling/chart';
import { TStrokeLinecap } from 'models/chart.models';

export interface IValueLineProps<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number> {
    xScale: TXScale<XDomain>;
    yScale: TYScale<YDomain>;
    data: IValueLineItem<XDomain, YDomain>[];
    variants?: {
        default?: IValueLineVariantProps;
        incomplete?: IValueLineVariantProps;
    };
}

interface IValueLineVariantProps {
    strokeColor?: string;
    strokeWidth?: number; /* default 2 */
    strokeDasharray?: string;
    strokeLinecap?: TStrokeLinecap; /* default 'round' */
}

export function ValueLine<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number>({
    xScale,
    yScale,
    data,
    variants,
}: IValueLineProps<XDomain, YDomain>) {
    const variantOptions = {
        default: {
            strokeColor: variants?.default?.strokeColor || CHART_STYLING.colors.dataVisualisation.multiple['1'],
            strokeWidth: variants?.default?.strokeWidth || 2,
            strokeDasharray: variants?.default?.strokeDasharray || undefined,
            strokeLinecap: variants?.default?.strokeLinecap || 'round',
        },
        incomplete: {
            strokeColor: variants?.default?.strokeColor || CHART_STYLING.colors.dataVisualisation.multiple['1'],
            strokeWidth: variants?.default?.strokeWidth || 2,
            strokeDasharray: variants?.default?.strokeDasharray || '0.25 6',
            strokeLinecap: variants?.default?.strokeLinecap || 'round',
        },
    };

    return (
        <g>
            {renderLines()}
            {renderPoints()}
        </g>
    );

    function renderLines() {
        return (
            <g>
                {data.map((currentItem, index) => {
                    if (index === 0) {
                        /* no line going to the first data point */
                        return null;
                    }

                    const key = `value-line_${index}`;
                    const prevItem = data[index - 1];

                    const optionsToUse = variantOptions[currentItem.variant];

                    return (
                        <line
                            key={key}
                            x1={xScale(prevItem.x)}
                            y1={yScale(prevItem.y)}
                            x2={xScale(currentItem.x)}
                            y2={yScale(currentItem.y)}
                            stroke={optionsToUse.strokeColor}
                            strokeWidth={optionsToUse.strokeWidth}
                            strokeDasharray={optionsToUse.strokeDasharray}
                            strokeLinecap={optionsToUse.strokeLinecap}
                        />
                    );
                })}
            </g>
        );
    }

    function renderPoints() {
        return (
            <g>
                {data.map((currentItem, index) => {
                    const key = `value-circle_${index}`;

                    return (
                        <circle
                            key={key}
                            cx={xScale(currentItem.x)}
                            cy={yScale(currentItem.y)}
                            strokeWidth={2}
                            stroke={CHART_STYLING.colors.neutral['50']}
                            fill={CHART_STYLING.colors.dataVisualisation.multiple['1']}
                            r={6}
                        />
                    );
                })}
            </g>
        );
    }
}
