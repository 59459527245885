import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import { IPeriodFilter } from '@console/common/models/period.models';
import {
    IApiEntityListResponseWithPageNr,
    IBaseFetchEntityListApiInput,
    TApiEntityDetail,
    TApiEntityId,
} from '../api.models';
import { ISinglePortfolioApiInput } from './portfolio.models';

export interface IPortfolioTransactionsApiInput
    extends Pick<ISinglePortfolioApiInput, 'portfolioId'>,
    Omit<IBaseFetchEntityListApiInput, 'orderBy'>,
    Pick<Partial<IAdvancedPortfolioTransactionsFilters>, 'movementTypes' | 'movementStatuses'>,
    Pick<Partial<IPeriodFilter>, 'startDate' | 'endDate'>,
    IUnderlyingRequestApiInput {
    transactionTypes?: PortfolioTransactionType[];
    transactionStatuses?: PortfolioTransactionStatus[];
}

export interface IAdvancedPortfolioTransactionsFilters {
    movementTypes: string[];
    movementStatuses: string[];
    period: IPeriodFilter;
}

export type TPortfolioTransactionsData = IApiEntityListResponseWithPageNr<IPortfolioTransactionEntityData>;
export type TPortfolioTransaction = TApiEntityDetail<IPortfolioTransactionEntityData>;

export interface IPortfolioTransactionEntityData {
    external_id: string;
    primary_movement: IPortfolioTransactionMovement;
    movements: IPortfolioTransactionMovement[];
    optimization_id: null; // TODO
    portfolio_id: TApiEntityId;
    status: PortfolioTransactionStatus;
    type: PortfolioTransactionType;
}

export interface IPortfolioTransactionMovement {
    datetime: string; // e.g. "2020-09-23T00:00:00+00:00"
    external_id: string;
    instrument_id: string; // e.g. "$USD"
    quantity: number; // e.g. 5000
    reference_instrument_id?: string;
    reference_quantity: null; // TODO
    status: PortfolioTransactionMovementStatus;
    sub_type: string; // e.g. "Cash Amount"
    type: PortfolioTransactionMovementType;
}

/**
 * As transaction movements have to be sorted...
 * - first on their 'datetime'
 * - and then on the id of the transaction they belong to (to further sort
 *   movements - of different transactions - who have the identical datetime value)
 *   This works because a transaction id is a ULID, and thus will be sorted on their creation time.
 * ...we add the transaction id to this movement interface.
 */
export interface ISortablePortfolioTransactionMovement extends IPortfolioTransactionMovement {
    transactionId: TApiEntityId;
}

export enum PortfolioTransactionStatus {
    PLANNED = 'PLANNED',
    PENDING = 'PENDING',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    COMPLETED = 'COMPLETED',
}

export enum PortfolioTransactionType {
    ORDER = 'ORDER',
    CORPORATE_ACTION = 'CORPORATE_ACTION',
    CASH_TRANSFER = 'CASH_TRANSFER',
    SECURITY_TRANSFER = 'SECURITY_TRANSFER',
    ADMINISTRATIVE = 'ADMINISTRATIVE',
}

export enum PortfolioTransactionMovementStatus {
    PLANNED = 'PLANNED',
    PENDING = 'PENDING',
    PLACED = 'PLACED',
    EXECUTED = 'EXECUTED',
    EXPIRED = 'EXPIRED',
    SETTLED = 'SETTLED',
    NOT_EXECUTED = 'NOT_EXECUTED',
    CANCELLED = 'CANCELLED',
}

export enum PortfolioTransactionMovementType {
    CASH_DEPOSIT = 'CASH_DEPOSIT',
    CASH_DIVIDEND = 'CASH_DIVIDEND',
    CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
    COUPON = 'COUPON',
    BUY = 'BUY',
    SELL = 'SELL',
    CORPORATE_ACTION_IN = 'CORPORATE_ACTION_IN',
    CORPORATE_ACTION_OUT = 'CORPORATE_ACTION_OUT',
    REVERSE_STOCK_SPLIT = 'REVERSE_STOCK_SPLIT',
    STOCK_DIVIDEND = 'STOCK_DIVIDEND',
    STOCK_SPLIT = 'STOCK_SPLIT',
    TRANSFER_IN = 'TRANSFER_IN',
    TRANSFER_OUT = 'TRANSFER_OUT',
    CUSTODY_FEE = 'CUSTODY_FEE',
    INSTRUMENT_ENTRY_EXIT_FEE = 'INSTRUMENT_ENTRY_EXIT_FEE',
    MANAGEMENT_FEE = 'MANAGEMENT_FEE',
    OTHER_FEE = 'OTHER_FEE',
    OTHER_TAX = 'OTHER_TAX',
    SERVICE_ENTRY_EXIT_FEE = 'SERVICE_ENTRY_EXIT_FEE',
    TRANSACTION_FEE = 'TRANSACTION_FEE',
    WITHHOLDING_TAX = 'WITHHOLDING_TAX',
    INTEREST_RECEIVED = 'INTEREST_RECEIVED',
    INTEREST_PAID = 'INTEREST_PAID',
    OTHER_UNDEFINED = 'OTHER_UNDEFINED',
}
