import { OPERATION_PERMISSION_KEY } from '@console/core-api/config/operationPermissionKeys';
import { ITenantSettings } from '@console/bff/models/settings/tenantSettings.models';

interface IHiddenOperationPermissionKeysMap {
    [permissionKey: string]: true;
}

export function getHiddenOperationPermissionKeysMap(
    tenantSettings: ITenantSettings,
): IHiddenOperationPermissionKeysMap {
    const hiddenKeysMap: IHiddenOperationPermissionKeysMap = {
        [OPERATION_PERMISSION_KEY.SYSTEM_FIELD_MODIFY]: true,
    };

    if (!tenantSettings?.thematicSearch?.isEnabled) {
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_CREATE] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_READ] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_MODIFY] = true;

        hiddenKeysMap[OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_CREATE] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_READ] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_MODIFY] = true;
    }

    if (!tenantSettings?.storyteller?.manageReports?.isEnabled) {
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_CREATE] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_READ] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_MODIFY] = true;

        hiddenKeysMap[OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_CREATE] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_READ] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_MODIFY] = true;
    }

    if (!tenantSettings?.storyteller?.storyManager?.isEnabled) {
        hiddenKeysMap[OPERATION_PERMISSION_KEY.STORYMANAGER_READ] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.STORYMANAGER_SCENARIO_MODIFY] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.STORYMANAGER_TEXTOUTPUT_MODIFY] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.STORYMANAGER_PUBLISH] = true;
    }

    if (!tenantSettings?.documents?.isEnabled) {
        hiddenKeysMap[OPERATION_PERMISSION_KEY.DOCUMENTS_CREATE] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.DOCUMENTS_READ] = true;
        hiddenKeysMap[OPERATION_PERMISSION_KEY.DOCUMENTS_MODIFY] = true;
    }

    // TODO also hide OPTIMIZATION keys when tenant does not have robo?

    return hiddenKeysMap;
}

interface IOperationPermissionGroup {
    label: string;
    permissions: OPERATION_PERMISSION_KEY[];
}

/* eslint-disable max-len */

export const OPERATION_PERMISSION_GROUPS: IOperationPermissionGroup[] = [{
    label: 'portfolio_mgmt.goals.title',
    permissions: [OPERATION_PERMISSION_KEY.GOALS_CREATE, OPERATION_PERMISSION_KEY.GOALS_MODIFY, OPERATION_PERMISSION_KEY.GOALS_READ],
}, {
    label: 'portfolio_mgmt.horizons.title',
    permissions: [OPERATION_PERMISSION_KEY.HORIZONS_CREATE, OPERATION_PERMISSION_KEY.HORIZONS_MODIFY, OPERATION_PERMISSION_KEY.HORIZONS_READ],
}, {
    label: 'portfolio_mgmt.risk_profiles.title',
    permissions: [OPERATION_PERMISSION_KEY.RISKPROFILES_CREATE, OPERATION_PERMISSION_KEY.RISKPROFILES_MODIFY, OPERATION_PERMISSION_KEY.RISKPROFILES_READ],
}, {
    label: 'portfolio_mgmt.policies.title',
    permissions: [OPERATION_PERMISSION_KEY.POLICIES_CREATE, OPERATION_PERMISSION_KEY.POLICIES_MODIFY, OPERATION_PERMISSION_KEY.POLICIES_READ],
}, {
    label: 'auth.operation_permission_groups.base_policies',
    permissions: [OPERATION_PERMISSION_KEY.BASE_POLICIES_CREATE, OPERATION_PERMISSION_KEY.BASE_POLICIES_MODIFY],
}, {
    label: 'portfolio_mgmt.portfolios.title',
    permissions: [OPERATION_PERMISSION_KEY.PORTFOLIOS_CREATE, OPERATION_PERMISSION_KEY.PORTFOLIOS_MODIFY, OPERATION_PERMISSION_KEY.PORTFOLIOS_MODIFY_OWNER, OPERATION_PERMISSION_KEY.PORTFOLIOS_READ],
}, {
    label: 'portfolio_mgmt.portfolio_groups.title',
    permissions: [OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_CREATE, OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_MODIFY, OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_READ],
}, {
    label: 'portfolio_mgmt.portfolio_reports.title',
    permissions: [OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_CREATE, OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_MODIFY, OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_READ],
}, {
    label: 'auth.operation_permission_groups.portfolio_valuations',
    permissions: [OPERATION_PERMISSION_KEY.PORTFOLIOVALUATIONS_CREATE, OPERATION_PERMISSION_KEY.PORTFOLIOVALUATIONS_MODIFY, OPERATION_PERMISSION_KEY.PORTFOLIOVALUATIONS_READ],
}, {
    label: 'auth.operation_permission_groups.optimizations',
    permissions: [OPERATION_PERMISSION_KEY.OPTIMIZATIONS_CREATE, OPERATION_PERMISSION_KEY.OPTIMIZATIONS_MODIFY, OPERATION_PERMISSION_KEY.OPTIMIZATIONS_READ],
}, {
    label: 'auth.operation_permission_groups.transactions',
    permissions: [OPERATION_PERMISSION_KEY.TRANSACTIONS_CREATE, OPERATION_PERMISSION_KEY.TRANSACTIONS_MODIFY, OPERATION_PERMISSION_KEY.TRANSACTIONS_READ],
}, {
    label: 'user_mgmt.users.title',
    permissions: [OPERATION_PERMISSION_KEY.USERS_CREATE, OPERATION_PERMISSION_KEY.USERS_MODIFY, OPERATION_PERMISSION_KEY.USERS_READ],
}, {
    label: 'auth.operation_permission_groups.agreements',
    permissions: [OPERATION_PERMISSION_KEY.AGREEMENTS_CREATE, OPERATION_PERMISSION_KEY.AGREEMENTS_MODIFY, OPERATION_PERMISSION_KEY.AGREEMENTS_READ],
}, {
    label: 'user_mgmt.user_groups.title',
    permissions: [OPERATION_PERMISSION_KEY.USERGROUPS_CREATE, OPERATION_PERMISSION_KEY.USERGROUPS_MODIFY, OPERATION_PERMISSION_KEY.USERGROUPS_READ],
}, {
    label: 'thematic_search.public_instruments.title',
    permissions: [OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_CREATE, OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_MODIFY, OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_READ],
}, {
    label: 'thematic_search.public_instrument_groups.title',
    permissions: [OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_CREATE, OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_MODIFY, OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_READ],
}, {
    label: 'suitability_profiler.profilers.operation_permission_group',
    permissions: [OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_PROFILERS_CREATE, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_PROFILERS_MODIFY, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_PROFILERS_READ],
}, {
    label: 'suitability_profiler.questionnaires.operation_permission_group',
    permissions: [OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_QUESTIONNAIRES_CREATE, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_QUESTIONNAIRES_MODIFY, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_QUESTIONNAIRES_READ],
}, {
    label: 'suitability_profiler.questions.operation_permission_group',
    permissions: [OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_QUESTIONS_CREATE, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_QUESTIONS_MODIFY, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_QUESTIONS_READ],
}, {
    label: 'suitability_profiler.user_profiles.operation_permission_group',
    permissions: [OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_PROFILES_CREATE, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_PROFILES_MODIFY, OPERATION_PERMISSION_KEY.SUITABILITYPROFILER_PROFILES_READ],
}, {
    label: 'auth.operation_permission_groups.story_manager',
    permissions: [OPERATION_PERMISSION_KEY.STORYMANAGER_READ, OPERATION_PERMISSION_KEY.STORYMANAGER_SCENARIO_MODIFY, OPERATION_PERMISSION_KEY.STORYMANAGER_TEXTOUTPUT_MODIFY, OPERATION_PERMISSION_KEY.STORYMANAGER_PUBLISH],
}, {
    label: 'auth.operation_permission_groups.documents',
    permissions: [OPERATION_PERMISSION_KEY.DOCUMENTS_CREATE, OPERATION_PERMISSION_KEY.DOCUMENTS_MODIFY, OPERATION_PERMISSION_KEY.DOCUMENTS_READ],
}, {
    label: 'auth.operation_permission_groups.other',
    permissions: [
        OPERATION_PERMISSION_KEY.RISKPROFILEQUESTIONRESPONSE_CREATE, OPERATION_PERMISSION_KEY.RISKPROFILEQUESTIONRESPONSE_MODIFY, OPERATION_PERMISSION_KEY.RISKPROFILEQUESTIONRESPONSE_READ,
        OPERATION_PERMISSION_KEY.ACL_MODIFY,
        OPERATION_PERMISSION_KEY.SYSTEM_FIELD_MODIFY,
    ],
}];

/* eslint-enable max-len */

export const OPERATION_PERMISSION_KEY_TO_GROUP_LABEL_MAP: { [key in OPERATION_PERMISSION_KEY]: string } =
    OPERATION_PERMISSION_GROUPS.reduce(
        (accumulator, permissionGroup) => {
            permissionGroup.permissions.forEach((permissionKey) => {
                accumulator[permissionKey] = permissionGroup.label;
            });
            return accumulator;
        },
        {} as { [key in OPERATION_PERMISSION_KEY]: string },
    );
