import React, { useState, ReactNode, useCallback } from 'react';
import _ from 'lodash';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputTextField from 'views/common/inputs/base/InputTextField';
import { makeStyles } from '@material-ui/core';
import DataSearch from 'views/common/list/DataSearch';
import { mixins } from 'views/styling';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.find_and_replace.side_content';

const useStyles = makeStyles((theme) => ({
    SearchInput: {
        '& .InputTextField': {
            '& .helperOrErrorText': {
                display: 'none',
            },
        },
        '& .DataSearch': {
            padding: theme.spacing(0),
            '& .helperOrErrorText': {
                display: 'none',
            },
        },
    },
    InputWithAction: {
        ...mixins.flexRow({ alignCross: 'flex-end' }),
        gap: theme.spacing(1),

        '& .input-actions': {
            marginBottom: theme.spacing(1.5),
        },
    },
}));

interface IPublicProps {
    onSearch: (search: string) => void;
    setReplaceValue: (value: string) => void;
    replaceInputActions: ReactNode;
    readonly: boolean;
}

export default function SearchInput({
    onSearch,
    setReplaceValue,
    replaceInputActions,
    readonly,
}: IPublicProps) {
    const classes = useStyles();
    const [searchInputValue, setSearchInputValue] = useState('');
    const [replaceInputValue, setReplaceInputValue] = useState('');

    /**
     * Debounce the actual setting of replaceValue to prevent too many unneeded re-renders while typing
     * (Because the result list needs to replaceValue it re-renders every time it changes)
     * */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setReplaceValueDebounced = useCallback(
        _.debounce(setReplaceValue, 500),
        [setReplaceValue],
    );

    return (
        <div className={classes.SearchInput}>
            <InputWrapper label={`${TRANSLATION_PREFIX}.fields.search.label`}>
                <DataSearch
                    listPageVars={{
                        search: {
                            simple: {
                                filterValue: searchInputValue || '',
                                extraFilterToggles: {},
                            },
                        },
                    }}
                    onSetSearchMode={() => null}
                    simple={{
                        onSearch,
                        onSetSearchValue: setSearchInputValue,
                        tipTranslationKey: `${TRANSLATION_PREFIX}.fields.search.label`,
                        minInputChars: 2,
                    }}
                />
            </InputWrapper>
            <div className={classes.InputWithAction}>
                <InputWrapper label={`${TRANSLATION_PREFIX}.fields.replace.label`}>
                    <InputTextField
                        onChange={(e) => {
                            setReplaceInputValue(e.value);
                            setReplaceValueDebounced(e.value);
                        }}
                        value={replaceInputValue}
                        placeholder={{ msg: `${TRANSLATION_PREFIX}.fields.replace.placeholder` }}
                        addSpaceIfPlaceholderAndNoValue={false}
                        disabled={readonly}
                    />
                </InputWrapper>
                <div className="input-actions">
                    {replaceInputActions}
                </div>
            </div>
        </div>
    );
}
