import doesArrayIncludeOneOf from '@snipsonian/core/cjs/array/verification/doesArrayIncludeOneOf';
import { PortfolioManagerType } from '../../../models/portfolioMgmt/portfolio.models';
import { UniverseType } from '../../../models/portfolioMgmt/instruments.models';

export const ROBO_PF_MANAGER_TYPES = [
    PortfolioManagerType.ROBO_ADVISOR_DISCRETIONARY,
    PortfolioManagerType.ROBO_ADVISOR_ADVISORY,
];

export function isRoboPortfolioManagerType(portfolioManagerType: PortfolioManagerType): boolean {
    return ROBO_PF_MANAGER_TYPES.includes(portfolioManagerType);
}

export function isSelfPortfolioManagerType(portfolioManagerType: PortfolioManagerType): boolean {
    return portfolioManagerType === PortfolioManagerType.USER_MANAGED;
}

export function containsRoboPortfolioManagerType(portfolioManagerTypes: PortfolioManagerType[]): boolean {
    return doesArrayIncludeOneOf(portfolioManagerTypes, ...ROBO_PF_MANAGER_TYPES);
}

export function containsSelfPortfolioManagerType(portfolioManagerTypes: PortfolioManagerType[]): boolean {
    return doesArrayIncludeOneOf(portfolioManagerTypes, PortfolioManagerType.USER_MANAGED);
}

export function mapPortfolioManagerTypeToUniverseType(portfolioManagerType: PortfolioManagerType): UniverseType {
    if (isSelfPortfolioManagerType(portfolioManagerType)) {
        return UniverseType.Self;
    }
    return UniverseType.Robo;
}
