import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPublicInstrumentEntityData } from '@console/core-api/models/thematicSearch/publicInstrument.models';
import React from 'react';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import {
    publicInstrumentDetailsEntity,
    triggerCreatePublicInstrument,
} from 'state/entities/thematicSearch/publicInstrumentDetails';
import AddPage from 'views/common/add/AddPage';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import PublicInstrumentPropertiesForm, {
    IPublicInstrumentFormValues,
    mapPublicInstrumentValuesToApiEntity,
} from '../PublicInstrumentDetail/PublicInstrumentPropertiesForm';

export function PublicInstrumentAdd() {
    const initialValues: IPublicInstrumentFormValues = {
        name: '',
        externalId: '',
        displayName: '',
        type: undefined,
        tags: [],
        imgUri: '',
    };

    return (
        <AddPage
            titleLabel="thematic_search.public_instruments.add.title"
            entity={{
                notifications: [StateChangeNotification.PUBLIC_INSTRUMENTS_DATA],
                asyncEntitySelector: publicInstrumentDetailsEntity.select,
            }}
        >
            <PublicInstrumentPropertiesForm
                initialValues={initialValues}
                mode="add"
                submit={{
                    onSubmit: onSubmitAdd,
                }}
            />
        </AddPage>
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IPublicInstrumentFormValues>) {
        return triggerCreatePublicInstrument(
            enrichApiEntityDataForCreate<IPublicInstrumentEntityData>(
                mapPublicInstrumentValuesToApiEntity(values),
            ),
        );
    }
}
