import React, { ReactElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { DURATION_IN_SECS } from 'config/styling/animation';
import { getBodyShiftWidthByAppMenuStatus } from 'config/menu.config';
import { shouldShowAppShell, getAppMenuStatus } from 'state/ui/selectors';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import TenantBanner from './TenantBanner';
import DeveloperPanel from './DeveloperPanel';
import AuthenticatedUser from './AuthenticatedUser';

interface IStyleProps {
    shiftWidth: number;
}

const useStyles = makeStyles((theme) => ({
    appBarTopMenu: {
        ...mixins.borderBottom({
            color: '#DDE1E6',
        }),
        boxShadow: 'none',

        /* to shift the menu to the right (depending on the app-menu state) */
        width: ({ shiftWidth }: IStyleProps) => `calc(100% - ${shiftWidth}px)`,
        marginLeft: ({ shiftWidth }: IStyleProps) => shiftWidth,

        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: DURATION_IN_SECS.MENU_SHIFT * 1000,
        }),
    },
    toolBar: {
        ...mixins.flexRow({ alignMain: 'space-between' }),
        padding: theme.spacing(0, 2),

        '& .actions': {
            ...mixins.flex({ wrap: 'nowrap', alignMain: 'flex-end', alignCross: 'center' }),
        },
    },
}));

function AppHeader({ state }: IObserveProps) {
    const appMenuStatus = getAppMenuStatus(state);
    const classes = useStyles({
        shiftWidth: getBodyShiftWidthByAppMenuStatus(appMenuStatus),
    });

    if (!shouldShowAppShell(state)) {
        return null;
    }

    return (
        <>
            <HideOnScrollDown>
                <AppBar
                    className={classes.appBarTopMenu}
                >
                    <Toolbar className={classes.toolBar}>
                        <TenantBanner />

                        <div className="actions">
                            <DeveloperPanel />

                            <AuthenticatedUser />
                        </div>
                    </Toolbar>
                </AppBar>
            </HideOnScrollDown>
        </>
    );
}

export default observe(
    [StateChangeNotification.UI_APP_MENU, StateChangeNotification.UI_APP_SHELL_CONFIG],
    AppHeader,
);

function HideOnScrollDown({ children }: { children: ReactElement }) {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}
