import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getAppModals } from 'state/ui/appModals.selectors';
import { closeCreatePortfolioReportAppModal, closeEntityStillUsedAppModal } from 'state/ui/appModals.actions';
import { observe, IObserveProps } from 'views/observe';
import CreatePortfolioReportModal from 'views/apps/StoryTeller/CreatePortfolioReportModal';
import EntityStillUsedModal from 'views/common/genericApiEntity/EntityStillUsedModal';

function AppModals({
    state,
    dispatch,
}: IObserveProps) {
    const { entityStillUsed, createPortfolioReport } = getAppModals(state);

    return (
        <div>
            {createPortfolioReport.open && (
                <CreatePortfolioReportModal
                    open
                    onClose={() => dispatch(closeCreatePortfolioReportAppModal())}
                    {...createPortfolioReport.extraData}
                />
            )}
            {entityStillUsed.open && (
                <EntityStillUsedModal
                    open
                    onClose={() => dispatch(closeEntityStillUsedAppModal())}
                    usedByIds={entityStillUsed.extraData.usedByIds}
                    entityId={entityStillUsed.extraData.entityId}
                />
            )}
        </div>
    );
}

export default observe(
    [StateChangeNotification.UI_APP_MODALS],
    AppModals,
);
