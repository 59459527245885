import isArray from '@snipsonian/core/cjs/is/isArray';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { IOperationPermissions } from './extractUserOperationPermissions';
import { OPERATION_PERMISSION_KEY } from '../../../config/operationPermissionKeys';

/**
 * requiredPermissions can be:
 * 1) an array of permission keys --> then all those permissions are needed (AND relation)
 * 2) an object containing multiple of such permission-key-arrays --> then at least 1 of the specified arrays (which
 *    still have a AND relation within them) has to match the user permissions (OR relation)
 */
export type TRequiredPermissions = TRequireAllThesePermissions | IRequireOneOfThesePermissions;

export interface IRequireOneOfThesePermissions {
    [label: string]: TRequireAllThesePermissions;
}

export type TRequireAllThesePermissions = OPERATION_PERMISSION_KEY[];

export function doUserPermissionsCoverRequiredPermissions({
    userPermissions,
    requiredPermissions,
}: {
    userPermissions: IOperationPermissions;
    requiredPermissions: TRequiredPermissions;
}): boolean {
    if (!requiredPermissions) {
        return true;
    }

    if (isArray<OPERATION_PERMISSION_KEY>(requiredPermissions)) {
        return doUserPermissionsCoverAllThesePermissions({
            userPermissions,
            requireAllThesePermissions: requiredPermissions,
        });
    }

    return Object.values(requiredPermissions)
        .some((requireAllThesePermissions) => doUserPermissionsCoverAllThesePermissions({
            userPermissions,
            requireAllThesePermissions,
        }));
}

function doUserPermissionsCoverAllThesePermissions({
    userPermissions,
    requireAllThesePermissions,
}: {
    userPermissions: IOperationPermissions;
    requireAllThesePermissions: TRequireAllThesePermissions;
}): boolean {
    if (!isArrayWithValues(requireAllThesePermissions)) {
        return true;
    }

    if (!userPermissions) {
        return false;
    }

    return requireAllThesePermissions
        .every((requiredPermissionKey) => !!userPermissions[requiredPermissionKey]);
}
