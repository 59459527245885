import isString from '@snipsonian/core/cjs/is/isString';
import isArray from '@snipsonian/core/cjs/is/isArray';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { ITraceableApiErrorBase } from '@snipsonian/core/cjs/typings/apiErrors';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import getRequestWrapper, {
    IRequestWrapper, IRequestWrapperConfig,
} from '@snipsonian/axios/cjs/request/getRequestWrapper';
import { IBodyRequestConfig, IGetRequestConfig } from '@snipsonian/axios/cjs/request/types';
import { BaseErrorCategory } from '@typsy/errors/dist/models/errorCategory';
import {
    ApiErrorOrigin,
    IBaseApiErrorClientSide,
} from '@console/api-base/server/error/apiBaseError.models';
import {
    combineApiErrorEnhancers,
    enhanceApiEntityDeleteError,
    enhanceApiEntityNotFoundError,
    enhanceApiEntityUniqueExternalIdError,
    enhanceApiResourcesNotReadyError,
    IPossibleApiErrorData,
    ICoreApiErrorDetailItem,
} from '@console/api-base/client/error/clientSideApiErrorUtils';
import { ICustomApiConfig, IOnApiWarningProps } from '@console/api-base/models/api.models';
import { DEFAULT_TIMEOUT_IN_MILLIS } from '../config/coreApi.config';

interface ICoreApiRequestWrapperConfig
    // eslint-disable-next-line max-len
    extends Pick<IRequestWrapperConfig<ICustomApiConfig, IBaseApiErrorClientSide, ITraceableApiErrorBase<IOrigCoreApiErrorBody>>,
    'apiLogger' | 'defaultBaseUrl' | 'onError' | 'requestCustomTransformer'> {
    onApiWarning: TOnApiWarning;
}

/** The error data model how it is originally (before mapping) returned by the core api */
interface IOrigCoreApiErrorBody {
    status: number;
    title: string;
    detail: ICoreApiErrorDetailItem[] | string;
    message?: string;
    body?: TAnyObject;
}

type TOnApiWarning = (props: IOnApiWarningProps) => void;

let requestWrapper: IRequestWrapper<ICustomApiConfig, IBaseApiErrorClientSide> = null;
let onApiWarning: TOnApiWarning = null;

export function initCoreApiRequestWrapper({
    apiLogger,
    defaultBaseUrl,
    onError,
    requestCustomTransformer,
    onApiWarning: newOnApiWarning,
}: ICoreApiRequestWrapperConfig) {
    onApiWarning = newOnApiWarning;

    // eslint-disable-next-line max-len
    requestWrapper = getRequestWrapper<ICustomApiConfig, IBaseApiErrorClientSide, ITraceableApiErrorBase<IOrigCoreApiErrorBody>>({
        apiLogger,
        defaultBaseUrl,
        defaultTimeoutInMillis: DEFAULT_TIMEOUT_IN_MILLIS,
        mapError: mapOrigCoreApiErrorToBaseApiError,
        onError,
        requestCustomTransformer,
        trackNrOfRunningApiCalls: process.env.NODE_ENV === 'test',
    });
}

export function notifyApiWarning(warningCode: string) {
    onApiWarning({ warningCode });
}

export function get<Result, ResponseData = Result>(
    config: IGetRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.get({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            config.enhanceError,
            enhanceApiEntityNotFoundError,
            enhanceApiResourcesNotReadyError,
        ),
    });
}

export function post<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.post({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            enhanceApiResourcesNotReadyError,
            enhanceApiEntityUniqueExternalIdError,
            config.enhanceError,
        ),
    });
}

export function put<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.put(config);
}

export function patch<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.patch({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            enhanceApiResourcesNotReadyError,
            enhanceApiEntityUniqueExternalIdError,
            config.enhanceError,
        ),
    });
}

export function remove<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.remove({
        ...config,
        enhanceError: combineApiErrorEnhancers(
            enhanceApiResourcesNotReadyError,
            enhanceApiEntityDeleteError,
            config.enhanceError,
        ),
    });
}

function mapOrigCoreApiErrorToBaseApiError(
    origApiError: ITraceableApiErrorBase<IOrigCoreApiErrorBody>,
): IBaseApiErrorClientSide {
    const { response, ...other } = origApiError;

    const { status, title, detail, message, body, ...remainingResponse } = response || {};

    const data: IPossibleApiErrorData = {
        ...remainingResponse,
    };
    if (isSetString(title)) {
        data.title = title;
    }
    if (isArray<ICoreApiErrorDetailItem>(detail)) {
        data.detailItems = detail;
    }
    if (isString(detail)) {
        data.detailText = detail;
    }

    return {
        ...other,
        response: {
            /**
             * This error 'code' is currently not provided by the back-end, but can be set in the front-end api layer
             * when the api returned an error that can be displayed to the end user.
             */
            code: null,
            message: message || title,
            data: data as TAnyObject,
            origin: ApiErrorOrigin.CoreApi,
            category: BaseErrorCategory.Unknown,
        },
    };
}
