import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import ContentTitle from 'views/common/layout/ContentTitle';
import { APP_COLORS } from 'config/styling/colors';

export interface IMgmtReportingBoxSectionProps {
    className?: string;
    title: TLabel;
    children: React.ReactNode;
    showBottomBorder?: boolean; /* default false */
}

const useStyles = makeStyles((theme) => ({
    MgmtReportingBoxSection: {
        ...mixins.flexColTopLeft(),
        ...mixins.widthMax(),

        '&.__bottomBorder': {
            paddingBottom: theme.spacing(2),
            ...mixins.borderBottom({
                color: APP_COLORS.GREY['200'],
            }),
        },

        '& .__title': {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),
        },
    },
}));

export function MgmtReportingBoxSection({
    className,
    title,
    children,
    showBottomBorder = false,
}: IMgmtReportingBoxSectionProps) {
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.MgmtReportingBoxSection,
                className,
                showBottomBorder && '__bottomBorder',
            )}
        >
            <ContentTitle
                className="__title"
                variant="list-section"
                label={title}
            />

            {children}
        </div>
    );
}
