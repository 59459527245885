import { Styles } from '@material-ui/core/styles/withStyles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { mixins } from 'views/assets/cssInJs/mixins';

export const layoutClasses = {
    rowCenter: 'rowCenter',
    rowCenterLeft: 'rowCenterLeft',
    colCenter: 'colCenter',
    colTopLeft: 'colTopLeft',
};

export function getLayoutStyles(/* theme: DefaultTheme */): Styles<DefaultTheme, TAnyObject> {
    return {
        rowCenter: {
            ...mixins.flexCenter(),
        },
        rowCenterLeft: {
            ...mixins.flexRowCenterLeft(),
        },
        colCenter: {
            ...mixins.flexCenter({ direction: 'col' }),
        },
        colTopLeft: {
            ...mixins.flex({ direction: 'col', alignCross: 'flex-start' }), // has by default alignMain 'flex-start'
        },
    };
}
