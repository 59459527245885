import isString from '@snipsonian/core/cjs/is/isString';
import { IPathParams, TPathParam } from 'models/routing.models';

export default function replacePathPlaceholders({
    path,
    placeholders = {},
}: {
    path: string;
    placeholders?: IPathParams;
}): string {
    const placeholderNames = Object.getOwnPropertyNames(placeholders);

    return placeholderNames.reduce(
        (prevPathResult, placeholderName) => {
            const placeholderValue = placeholders[placeholderName];

            const regex = new RegExp(`:${placeholderName}`, 'g');

            return prevPathResult.replace(regex, encodePathParam(placeholderValue));
        },
        path,
    );
}

function encodePathParam(pathParam: TPathParam): string {
    return isString(pathParam) ? encodeURIComponent(pathParam) : pathParam.toString();
}
