import { Styles } from '@material-ui/core/styles/withStyles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { colors } from '@material-ui/core';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { APP_COLORS, hexToRgba, OPACITY } from 'config/styling/colors';
import { mixins } from 'views/assets/cssInJs/mixins';

export const TABLE_CELL_BORDER = `1px solid ${hexToRgba('#253540', OPACITY.EXTREME)}`;
export const TABLE_CELL_BORDER_OVERRULE = `${TABLE_CELL_BORDER} !important`;

export const tableClasses = {
    cellBold: 'tableCellBold',
    cellDisabled: 'tableCellDisabled',
    cellBorder: 'tableCellBorder',
    cellNoBorder: 'tableCellNoBorder',
    cellRightBorder: 'tableCellRightBorder',
    cellLeftBorder: 'tableCellLeftBorder',
    cellTopAndBottomBorder: 'tableCellTopAndBottomBorder',
    cellNoDisplay: 'tableCellNoDisplay',
    cellNewItem: 'tableCellNewItem',
    cellEditedItem: 'tableCellEdited',
    cellNoTextWrap: 'tableCellNoTextWrap',
};

export function getTableStyles(/* theme: DefaultTheme */): Styles<DefaultTheme, TAnyObject> {
    return {
        tableCellBold: {
            ...mixins.typoBold(),
        },
        tableCellDisabled: {
            color: `${APP_COLORS.TEXT['200']} !important`,
        },
        tableCellBorder: {
            borderTop: TABLE_CELL_BORDER_OVERRULE,
            borderRight: TABLE_CELL_BORDER,
            borderBottom: TABLE_CELL_BORDER,
            borderLeft: TABLE_CELL_BORDER,
        },
        tableCellNoBorder: {
            borderTop: '0px !important',
        },
        tableCellRightBorder: {
            borderRight: TABLE_CELL_BORDER,
        },
        tableCellLeftBorder: {
            borderLeft: TABLE_CELL_BORDER,
        },
        tableCellTopAndBottomBorder: {
            borderTop: TABLE_CELL_BORDER_OVERRULE,
            borderBottom: TABLE_CELL_BORDER,
        },
        tableCellNoDisplay: {
            display: 'none',
        },
        tableCellNewItem: {
            borderLeftWidth: 10,
            borderLeftColor: colors.green['500'],
            borderLeftStyle: 'solid',
        },
        tableCellEdited: {
            borderLeftWidth: 10,
            borderLeftColor: APP_COLORS.SECONDARY['400'],
            borderLeftStyle: 'solid',
        },
        tableCellNoTextWrap: {
            whiteSpace: 'nowrap',
        },
    };
}
