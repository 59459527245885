import React from 'react';
import clsx from 'clsx';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { Locale } from '@console/common/config/i18n.config';
import { getDefaultUserLocaleConfig } from 'state/appConfig/selectors';
import { formatLocaleForDisplay } from 'utils/entities/multiTranslationsLabelUtils';
import { makeStyles, mixins } from 'views/styling';

interface IPublicProps {
    locale: Locale;
    shouldMarkDefaultLocale?: boolean; // default true
}

const useStyles = makeStyles((theme) => ({
    MultiTranslationsLabelLocaleTitle: {
        ...mixins.flexRow(),

        '& .__defaultLocale': {
            paddingLeft: theme.spacing(1),
        },
    },
}));

export default function MultiTranslationsLabelLocaleTitle({
    locale,
    shouldMarkDefaultLocale = true,
}: IPublicProps) {
    const classes = useStyles();

    const isDefaultLocale = shouldMarkDefaultLocale && (locale === getDefaultUserLocaleConfig());

    return (
        <div
            className={clsx(classes.MultiTranslationsLabelLocaleTitle, 'MultiTranslationsLabelLocaleTitle')}
        >
            {formatLocaleForDisplay(locale)}
            {isDefaultLocale && (
                <div className="__defaultLocale">
                    (<Translate msg="common.multi_translations_label.default_locale" />)
                </div>
            )}
        </div>
    );
}
