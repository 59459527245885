import { IAsyncItem } from '@console/core-api/models/api.models';
import useAsyncFetch, { IUseAsyncFetchProps } from './useAsyncFetch';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps<Data> extends Omit<IUseAsyncFetchProps<Data>, 'deps'>{}

export default function useAsyncFetchOnMount<Data>({
    shouldFetch,
    fetcher,
}: IPublicProps<Data>): [IAsyncItem<Data>] {
    return useAsyncFetch({
        shouldFetch,
        fetcher,
        deps: [],
    });
}
