import React from 'react';
import clsx from 'clsx';
import { APP_COLORS } from 'config/styling/colors';
import { makeStyles } from 'views/styling';

export interface IPublicProps extends IStyleProps {
    color?: TSpinnerColor; // default 'primary'
    className?: string;
}

interface IStyleProps {
    size?: TSpinnerSize; // default 'L'
}

type TSpinnerColor = 'primary' | 'secondary';
type TSpinnerSize = 'XL' | 'L' | 'M' | 'S' | 'XS';

const useStyles = makeStyles((/* theme */) => ({
    Spinner: {
        '& .rotatingImg': {
            animation: '$spinner-rotate infinite 2s linear',
            width: ({ size }: IStyleProps) => toSpinnerSizeInPx(size),
            height: ({ size }: IStyleProps) => toSpinnerSizeInPx(size),
        },
        '& .fill--primary': {
            fill: APP_COLORS.PRIMARY['500'],
        },
        '& .fill--secondary': {
            fill: APP_COLORS.SECONDARY['500'],
        },
    },
    '@keyframes spinner-rotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

export default function Spinner({
    color = 'primary',
    size = 'L',
    className,
}: IPublicProps) {
    const classes = useStyles({ size });

    return (
        <div className={clsx(classes.Spinner, className)}>
            <SpinnerImage color={color} />
        </div>
    );
}

/* See assets/img/spinner.svg for the original spinner svg */
function SpinnerImage({ color }: { color: TSpinnerColor }) {
    return (
        <svg
            className={clsx('rotatingImg', `fill--${color}`)}
            width="32px"
            height="32px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>spinner</title>
            <g id="spinner" stroke="none" strokeWidth="1" fillRule="evenodd">
                {/* eslint-disable-next-line max-len */}
                <path d="M15.9989981,3.06944073e-08 L15.999,6 L15.7200952,6.0038418 C10.3266708,6.15206828 6,10.5707601 6,16 C6,21.5228475 10.4771525,26 16,26 C21.5228475,26 26,21.5228475 26,16 L26,15.999 L32,15.9989981 C32,15.9993321 32,15.999666 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.16377796 7.16290256,0.000541456963 15.9989981,3.06944073e-08 Z" id="spinner-path" />
            </g>
        </svg>
    );
}

function toSpinnerSizeInPx(size: TSpinnerSize): number {
    switch (size) {
        case 'XL':
            return 32;
        case 'L':
            return 28;
        case 'S':
            return 20;
        case 'XS':
            return 16;
        default:
            return 24;
    }
}
