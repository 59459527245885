import { configureSentryReact } from '@typsy/sentry-react/dist/init/configureSentryReact';
import { SENTRY_DSN } from 'config/error.config';
import { APP_VERSION } from 'utils/env/appVersion';
import { ENVIRONMENT } from 'utils/env/environment';

export function initErrorReporter() {
    configureSentryReact({
        environment: ENVIRONMENT || 'localhost',
        release: getBuildInfo().release || `${APP_VERSION}_localhost`,
        config: {
            dsn: SENTRY_DSN,
            /* ignore errors where throw or promise rejection is done with a non-error object */
            ignoreErrors: [
                'Non-Error exception captured',
                'Non-Error promise rejection captured',
            ],
        },
    });
}

function getBuildInfo(): IConsoleConfigBuildInfo {
    return window.CONSOLE_CONFIG.buildInfo;
}
