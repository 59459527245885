import { IChartSizeWithoutAxis } from 'models/chart.models';
import { ILineChartOptions } from 'models/lineChart.models';

export function determineLineChartSizeWithoutAxis({
    dimensions,
    axis,
    labels,
}: ILineChartOptions): IChartSizeWithoutAxis {
    return {
        chartWidth: dimensions.maxWidth - axis.y.width - axis.x.marginRight - labels.width,
        chartHeight: dimensions.maxHeight - axis.y.marginTop - axis.x.height,
    };
}
