import React from 'react';
import { observe } from 'views/observe';
import ContentTitle from 'views/common/layout/ContentTitle';
import { makeStyles } from '@material-ui/core';
import {
    getSelectedStoryManagerDatabaseOutputKeyScenario,
    getSelectedStoryManagerDatabaseDetail,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { mixins } from 'views/styling';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import ScenarioDetails from '../../ScenarioDetails';
import StoryManagerFooter from '../../StoryManagerTemplate/StoryManagerFooter';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.find_and_replace.main_content';

const useStyles = makeStyles(() => ({
    MainContent: {
        '& .noResult': {
            minHeight: 100,
            ...mixins.flexColCenterCenter(),
            ...mixins.typo({ color: '#A8B1BA', size: 14 }),
        },
    },
}));

function MainContent() {
    const classes = useStyles();
    const { outputKeyId, scenarioIndex } = useRouteParams();
    const storyManagerDatabase = getSelectedStoryManagerDatabaseDetail();
    const selectedScenario = getSelectedStoryManagerDatabaseOutputKeyScenario(
        outputKeyId,
        scenarioIndex,
    );

    return (
        <div className={classes.MainContent}>
            <ContentTitle
                variant="section-small"
                label={{ msg: `${TRANSLATION_PREFIX}.title` }}
            />
            {!selectedScenario && (
                <div className="noResult">
                    <Translate msg={`${TRANSLATION_PREFIX}.scenario_detail.no_result`} />
                </div>
            )}
            <ScenarioDetails readonly />
            {storyManagerDatabase && <StoryManagerFooter />}
        </div>
    );
}

export default observe(
    [
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
    ],
    MainContent,
);
