function* infinite(initialValue = 0) {
    let index = initialValue;

    while (true) {
        // eslint-disable-next-line no-plusplus
        yield index++;
    }
}

interface ICounter {
    next: () => number;
}

export default function getCounter({
    initialValue = 0,
}: {
    initialValue?: number;
} = {}): ICounter {
    const counter = infinite(initialValue);

    return {
        next: () => counter.next().value as number,
    };
}
