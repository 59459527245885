// eslint-disable-next-line import/no-extraneous-dependencies
import * as validateAgainstSchemaOrig from '@typsy/schema-validation/dist/validate/validateAgainstSchema';
import { SchemaErrorType } from '@console/common/utils/schema';
import { camelCaseToSnakeCase } from '@console/common/utils/string/caseConverters';
import { TI18nLabelOrString } from 'models/general.models';

const VALIDATION_ERROR_LABEL_PREFIX = 'error.validation';

export function mapSchemaValidationErrorToI18Label(
    schemaError: validateAgainstSchemaOrig.ISchemaValidationError<SchemaErrorType>,
): TI18nLabelOrString {
    const labelSuffix = camelCaseToSnakeCase(schemaError.type);

    return {
        msg: `${VALIDATION_ERROR_LABEL_PREFIX}.${labelSuffix}`,
        placeholders: {
            field: schemaError.fieldName,
            value: schemaError.value,
            ...schemaError.params,
        },
    };
}
