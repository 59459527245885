export const SIZES = {
    HEADER: {
        HEIGHT: 48,
    },
    MENU: {
        COLLAPSED_WIDTH: 56, // icon 24 + padding 2 x 16
        TOP_WIDTH: 260,
        CONTEXT_WIDTH: 260,
    },
    BODY: {
        MAX_WIDTH: 1440,
        HEADER_HEIGHT: 40,
    },
    INPUT: {
        HEIGHT: {
            L: 48,
            M: 40,
            MD: 36,
            S: 32,
        },
    },
    DETAIL_FORM: {
        MAX_WIDTH: 500,
    },
};
