export enum SupportedCurrency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
    CHF = 'CHF',
    JPY = 'JPY',
    SEK = 'SEK',
    UAH = 'UAH',
    ALL = 'ALL',
    DZD = 'DZD',
    ARS = 'ARS',
    AUD = 'AUD',
    BSD = 'BSD',
    BHD = 'BHD',
    BDT = 'BDT',
    BBD = 'BBD',
    BZD = 'BZD',
    BOB = 'BOB',
    BAM = 'BAM',
    BWP = 'BWP',
    BRL = 'BRL',
    BND = 'BND',
    BGN = 'BGN',
    BIF = 'BIF',
    KHR = 'KHR',
    CAD = 'CAD',
    CVE = 'CVE',
    KYD = 'KYD',
    XAF = 'XAF',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    XOF = 'XOF',
    HRK = 'HRK',
    CUP = 'CUP',
    CZK = 'CZK',
    DKK = 'DKK',
    DJF = 'DJF',
    DOP = 'DOP',
    EGP = 'EGP',
    SVC = 'SVC',
    ETB = 'ETB',
    FKP = 'FKP',
    GIP = 'GIP',
    SHP = 'SHP',
    FJD = 'FJD',
    XPF = 'XPF',
    GMD = 'GMD',
    GTQ = 'GTQ',
    GNF = 'GNF',
    HTG = 'HTG',
    HNL = 'HNL',
    HKD = 'HKD',
    HUF = 'HUF',
    ISK = 'ISK',
    INR = 'INR',
    IDR = 'IDR',
    IRR = 'IRR',
    IQD = 'IQD',
    ILS = 'ILS',
    JMD = 'JMD',
    JOD = 'JOD',
    KZT = 'KZT',
    KES = 'KES',
    KWD = 'KWD',
    LAK = 'LAK',
    LBP = 'LBP',
    LSL = 'LSL',
    LYD = 'LYD',
    MOP = 'MOP',
    MKD = 'MKD',
    MGA = 'MGA',
    MWK = 'MWK',
    MYR = 'MYR',
    MRU = 'MRU',
    MUR = 'MUR',
    MXN = 'MXN',
    MDL = 'MDL',
    MAD = 'MAD',
    MMK = 'MMK',
    NAD = 'NAD',
    NPR = 'NPR',
    ANG = 'ANG',
    BYN = 'BYN',
    PGK = 'PGK',
    RON = 'RON',
    STN = 'STN',
    TRY = 'TRY',
    ZMK = 'ZMK',
    NZD = 'NZD',
    NIO = 'NIO',
    NGN = 'NGN',
    KWP = 'KWP',
    NOK = 'NOK',
    OMR = 'OMR',
    PKR = 'PKR',
    PAB = 'PAB',
    PYG = 'PYG',
    PEN = 'PEN',
    PHP = 'PHP',
    PLN = 'PLN',
    QAR = 'QAR',
    RUB = 'RUB',
    RWF = 'RWF',
    SAR = 'SAR',
    RSD = 'RSD',
    SCR = 'SCR',
    SGD = 'SGD',
    SIT = 'SIT',
    SOS = 'SOS',
    ZAR = 'ZAR',
    KRW = 'KRW',
    LKR = 'LKR',
    SDG = 'SDG',
    SZL = 'SZL',
    SYP = 'SYP',
    TWD = 'TWD',
    TZS = 'TZS',
    THB = 'THB',
    TTD = 'TTD',
    TND = 'TND',
    AED = 'AED',
    UGX = 'UGX',
    UYU = 'UYU',
    UZS = 'UZS',
    VES = 'VES',
    VND = 'VND',
    YER = 'YER',
}

export const ALL_CURRENCIES = Object.values(SupportedCurrency);
