import { IDatePeriod, IPeriodBoundaries, PeriodType } from '../../models/period.models';
import {
    getDatesOfLastFullWeek,
    getDatesOfLastFullMonth,
    getDatesOfLastFullQuarter,
    getDatesOfLastFullYear,
    getLaterDate,
    getStartDateOfWeek,
    getStartDateOfMonth,
    getStartDateOfQuarter,
    getStartDateOfYear,
    TTimezone, TIMEZONE_LOCAL, toDateWithinTimezone, TDateEnhanced, parseInputDate,
} from './dateUtils';
import { dayOffset } from './getSpecificDate';

interface IGetDatesForPeriodTypeProps extends IPeriodBoundaries {
    periodType: PeriodType;
    timezone?: TTimezone; /* by default LOCAL timezone */
}

export function getDatesForPeriodType(input: IGetDatesForPeriodTypeProps): IDatePeriod {
    const { startDate, endDate } = getDatesEnhancedForPeriodType(input);

    return {
        startDate: startDate ? startDate.toDate() : null,
        endDate: endDate ? endDate.toDate() : null,
    };
}

export function getDatesEnhancedForPeriodType({
    minDate,
    maxDate,
    periodType,
    timezone = TIMEZONE_LOCAL,
}: IGetDatesForPeriodTypeProps): IDatePeriod<TDateEnhanced> {
    switch (periodType) {
        /* Periods where user has to select the start and end */
        case PeriodType.SPECIFIC_PERIOD:
        case PeriodType.CUSTOM:
            return {
                startDate: null,
                endDate: null,
            };

        /* Standard periods */
        case PeriodType.ALL_TIME:
            return {
                startDate: ensureEnhancedDate(minDate),
                endDate: ensureEnhancedDate(maxDate),
            };
        case PeriodType.LAST_24H:
            return {
                startDate: getLaterDate(
                    dayOffset({
                        date: getTodayWithinTimezone(),
                        daysToAdd: -1,
                    }),
                    ensureEnhancedDate(minDate),
                ) as TDateEnhanced,
                endDate: getTodayWithinTimezone(),
            };
        case PeriodType.LAST_7_DAYS:
            return {
                startDate: getLaterDate(
                    dayOffset({
                        date: getTodayWithinTimezone(),
                        daysToAdd: -7,
                    }),
                    ensureEnhancedDate(minDate),
                ) as TDateEnhanced,
                endDate: getTodayWithinTimezone(),
            };
        case PeriodType.LAST_30_DAYS:
            return {
                startDate: getLaterDate(
                    dayOffset({
                        date: getTodayWithinTimezone(),
                        daysToAdd: -30,
                    }),
                    ensureEnhancedDate(minDate),
                ) as TDateEnhanced,
                endDate: getTodayWithinTimezone(),
            };

        /* Management reporting periods */
        case PeriodType.LAST_DAY:
            return {
                startDate: getYesterday().startOf('day'),
                endDate: getYesterday().endOf('day'),
            };
        case PeriodType.WEEK_TO_DATE:
            return {
                startDate: getStartDateOfWeek(getTodayWithinTimezone()),
                endDate: getTodayWithinTimezone(),
            };
        case PeriodType.LAST_WEEK:
            return getDatesOfLastFullWeek(getTodayWithinTimezone());
        case PeriodType.MONTH_TO_DATE:
            return {
                startDate: getStartDateOfMonth(getTodayWithinTimezone()),
                endDate: getTodayWithinTimezone(),
            };
        case PeriodType.LAST_MONTH:
            return getDatesOfLastFullMonth(getTodayWithinTimezone());
        case PeriodType.QUARTER_TO_DATE:
            return {
                startDate: getStartDateOfQuarter(getTodayWithinTimezone()),
                endDate: getTodayWithinTimezone(),
            };
        case PeriodType.LAST_QUARTER:
            return getDatesOfLastFullQuarter(getTodayWithinTimezone());
        case PeriodType.YEAR_TO_DATE:
            return {
                startDate: getStartDateOfYear(getTodayWithinTimezone()),
                endDate: getTodayWithinTimezone(),
            };
        case PeriodType.LAST_YEAR:
            return getDatesOfLastFullYear(getTodayWithinTimezone());
        case PeriodType.SINCE_START:
            return {
                startDate: minDate
                    ? toDateWithinTimezone({
                        date: minDate,
                        timezone,
                    }).startOf('day')
                    : null,
                endDate: getTodayWithinTimezone(),
            };
        default:
            throw new Error(`Unexpected period type ${periodType}`);
    }

    function getTodayWithinTimezone() {
        return toDateWithinTimezone({
            timezone,
        });
    }

    function getYesterday() {
        return dayOffset({
            date: getTodayWithinTimezone(),
            daysToAdd: -1,
        });
    }
}

function ensureEnhancedDate(date: Date): TDateEnhanced {
    if (date) {
        return parseInputDate(date);
    }

    return null;
}
