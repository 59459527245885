import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { IPathParams } from 'models/routing.models';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { APP_COLORS } from 'config/styling/colors';
import { getCurrentRouteParams } from 'state/ui/selectors';
import { makeStyles, mixins } from 'views/styling';
import IconButton from 'views/common/buttons/IconButton';
import { CopyIcon } from 'views/common/icons';
import { IObserveProps, observe } from 'views/observe';
import { copyToClipboard } from 'utils/dom/copyToClipboard';

export interface IEntityIdProps {
    typeLabel?: string; // to override the default label
    idSelector: TEntityIdSelector;
}

export type TEntityIdSelector = (props: { urlParams: IPathParams }) => TApiEntityId;

const useStyles = makeStyles((theme) => ({
    EntityId: {
        ...mixins.flexRowCenterRight(),
        ...mixins.typoSmallCaps(),

        '& .__label': {
            marginRight: theme.spacing(1),
        },

        '& .__id': {
            ...mixins.typo({ color: APP_COLORS.TEXT['200'] }),
        },

        '& .__copyIcon': {
            marginLeft: theme.spacing(1),
        },
        '& .__copyIcon svg': {
            height: 16,
            fill: APP_COLORS.GREY['400'],
        },
    },
}));

function EntityId({
    typeLabel = 'app_shell.header.id_copy',
    idSelector,
    state,
}: IEntityIdProps & IObserveProps) {
    const classes = useStyles();

    const entityId = idSelector({ urlParams: getCurrentRouteParams(state) });
    const isCopyToClipboardSupported = navigator && navigator.clipboard;

    return (
        <div className={classes.EntityId}>
            <div className="__label">
                <Translate msg={typeLabel} />:
            </div>

            <div className="__id">
                {entityId}
            </div>

            {isCopyToClipboardSupported && (
                <IconButton
                    id="copy-entity-id"
                    className="__copyIcon"
                    icon={<CopyIcon />}
                    onClick={() => copyToClipboard(entityId)}
                    variant="bare"
                    size="S"
                    color="grey_inverted"
                    tooltip="common.action.copy_to_clipboard"
                    tooltipPlacement="top-end"
                />
            )}
        </div>
    );
}

export default observe<IEntityIdProps>(
    [],
    EntityId,
);
