import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { SIZES } from 'config/styling/sizes';
import { makeStyles } from 'views/styling';
import {
    ITextButtonProps,
    ITextButtonStyleProps,
} from './types';
import {
    BORDER_RADIUS,
    getButtonFontSizeInPx,
    getButtonTextColor,
    enrichAskConfirmationLabelIfNotProvided,
} from './buttonUtils';
import BaseButton from './BaseButton';
import { setTextButton } from './buttonsNoCircularDependencies';
import Tooltip from '../widget/Tooltip';
import Text from '../widget/Text';

export type { ITextButtonProps } from './types';

const SIZE = {
    XL: SIZES.INPUT.HEIGHT.L,
    L: SIZES.INPUT.HEIGHT.L,
    M: SIZES.INPUT.HEIGHT.M,
    MD: SIZES.INPUT.HEIGHT.MD,
    S: SIZES.INPUT.HEIGHT.S,
    XS: SIZES.INPUT.HEIGHT.S,
};

const useStyles = makeStyles((theme) => ({
    TextButton: {
        minWidth: 'unset',
        height: ({ variant, size }: ITextButtonStyleProps) => {
            if (variant === 'bare') {
                return 'unset';
            }
            return SIZE[size];
        },
        margin: ({ noMargin }: ITextButtonStyleProps) => {
            if (noMargin) {
                return 0;
            }
            return theme.spacing(0, 1);
        },
        padding: ({ variant }: ITextButtonStyleProps) => {
            if (variant === 'bare') {
                return 0;
            }
            return theme.spacing(1, 2);
        },

        fontSize: ({ fontSize }: ITextButtonStyleProps) =>
            theme.typography.pxToRem(getButtonFontSizeInPx({ size: fontSize })),
        fontWeight: ({ fontWeight }: ITextButtonStyleProps) => fontWeight,
        textTransform: 'unset',
        color: ({ color, variant, disabled }: ITextButtonStyleProps) =>
            getButtonTextColor({ variant, color, state: disabled ? 'disabled' : undefined }),

        borderRadius: BORDER_RADIUS,

        '&.MuiButton-root.Mui-disabled': {
            color: ({ color, variant }: ITextButtonStyleProps) =>
                getButtonTextColor({ variant, color, state: 'disabled' }),
        },

        '&:hover': {
            color: ({ color, variant }: ITextButtonStyleProps) =>
                getButtonTextColor({ variant, color, state: 'hover' }),
        },

        '&:focus:not(:hover)': {
            color: ({ color, variant }: ITextButtonStyleProps) =>
                getButtonTextColor({ variant, color, state: 'focus' }),
        },

        '& .button--icon': {
            width: ({ svgSize }: ITextButtonStyleProps) => svgSize,
            height: ({ svgSize }: ITextButtonStyleProps) => svgSize,
        },
        '& .button--icon__start': {
            marginRight: theme.spacing(1),
        },
        '& .button--icon__end': {
            marginLeft: theme.spacing(1),
        },

        '& .button--label': {
            lineHeight: '20px',
        },
    },
}));

setTextButton(TextButton);

export default function TextButton(props: ITextButtonProps) {
    const {
        label,
        className,
        icon,
        iconPlacement = 'start',
        isSubmit,
        variant = 'filled',
        color = 'primary',
        size = 'L',
        fontSize: fontSizeInput,
        disabled = false,
        noMargin: noMarginInput,
        svgSize = 32,
        fontWeight = 'bold',
        askConfirmation = false,
        tooltip,
        tooltipPlacement = 'top',
        ...baseProps
    } = props;

    const fontSize = fontSizeInput || size;
    const noMargin = isSet(noMarginInput)
        ? noMarginInput
        : (variant === 'bare');

    const classes = useStyles({ variant, color, size, fontSize, disabled, noMargin, svgSize, fontWeight });

    const button = (
        <BaseButton
            {...baseProps}
            color={color}
            variant={variant}
            disabled={disabled}
            classNames={[classes.TextButton, className]}
            type={isSubmit ? 'submit' : undefined}
            addBoxShadowOnHover={variant !== 'bare'}
            svgSize={svgSize}
            askConfirmation={enrichAskConfirmationLabelIfNotProvided(askConfirmation, label)}
        >
            {icon && (iconPlacement === 'start') && (
                <div className={clsx('button--icon', 'button--icon__start')}>
                    {icon}
                </div>
            )}

            <div className="button--label">
                <Text label={label} />
            </div>

            {icon && (iconPlacement === 'end') && (
                <div className={clsx('button--icon', 'button--icon__end')}>
                    {icon}
                </div>
            )}
        </BaseButton>
    );

    if (!tooltip) {
        return button;
    }

    return (
        <Tooltip
            label={tooltip}
            placement={tooltipPlacement}
        >
            {button}
        </Tooltip>
    );
}
