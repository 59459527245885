import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { UiPageKey } from 'models/state/ui.models';
import { ROUTE_KEY } from 'views/routeKeys';

export const CORE_API_ENTITY_TYPE_TO_LIST_UI_PAGE_MAP: { [key in CoreApiEntityType]: UiPageKey; } = {
    [CoreApiEntityType.goals]: UiPageKey.goalsList,
    [CoreApiEntityType.horizons]: UiPageKey.horizonsList,
    [CoreApiEntityType.riskProfiles]: UiPageKey.riskProfilesList,
    [CoreApiEntityType.policies]: UiPageKey.policiesList,
    [CoreApiEntityType.portfolios]: UiPageKey.portfoliosList,
    [CoreApiEntityType.portfolioGroups]: UiPageKey.portfolioGroupsList,
    [CoreApiEntityType.optimizations]: UiPageKey.portfolioOptimizationsList,
    [CoreApiEntityType.userGroups]: UiPageKey.userGroupsList,
    [CoreApiEntityType.users]: UiPageKey.usersList,
    [CoreApiEntityType.publicInstruments]: UiPageKey.publicInstrumentsList,
    [CoreApiEntityType.publicInstrumentGroups]: UiPageKey.publicInstrumentGroupsList,
};

export const CORE_API_ENTITY_TYPE_TO_LIST_ROUTE_MAP: { [key in CoreApiEntityType]: ROUTE_KEY; } = {
    [CoreApiEntityType.goals]: ROUTE_KEY.R_GOALS_LIST,
    [CoreApiEntityType.horizons]: ROUTE_KEY.R_HORIZONS_LIST,
    [CoreApiEntityType.riskProfiles]: ROUTE_KEY.R_RISK_PROFILES_LIST,
    [CoreApiEntityType.policies]: ROUTE_KEY.R_POLICIES_LIST,
    [CoreApiEntityType.portfolios]: ROUTE_KEY.R_PORTFOLIOS_LIST,
    [CoreApiEntityType.portfolioGroups]: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST,
    /* if needed, this should be the optimizations tab of ROUTE_KEY.R_PORTFOLIO_DETAIL */
    [CoreApiEntityType.optimizations]: null,
    [CoreApiEntityType.userGroups]: ROUTE_KEY.R_USER_GROUPS_LIST,
    [CoreApiEntityType.users]: ROUTE_KEY.R_USERS_LIST,
    [CoreApiEntityType.publicInstruments]: ROUTE_KEY.R_PUBLIC_INSTRUMENTS_LIST,
    [CoreApiEntityType.publicInstrumentGroups]: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUPS_LIST,
};

export const CORE_API_ENTITY_TYPE_TO_DETAIL_ROUTE_MAP: { [key in CoreApiEntityType]: ROUTE_KEY; } = {
    [CoreApiEntityType.goals]: ROUTE_KEY.R_GOAL_DETAIL,
    [CoreApiEntityType.horizons]: ROUTE_KEY.R_HORIZON_DETAIL,
    [CoreApiEntityType.riskProfiles]: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
    [CoreApiEntityType.policies]: ROUTE_KEY.R_POLICY_DETAIL,
    [CoreApiEntityType.portfolios]: ROUTE_KEY.R_PORTFOLIO_DETAIL,
    [CoreApiEntityType.portfolioGroups]: ROUTE_KEY.R_PORTFOLIO_GROUP_DETAIL,
    [CoreApiEntityType.optimizations]: ROUTE_KEY.R_PORTFOLIO_OPTIMIZATION_DETAIL,
    [CoreApiEntityType.userGroups]: ROUTE_KEY.R_USER_GROUP_DETAIL,
    [CoreApiEntityType.users]: ROUTE_KEY.R_USER_DETAIL,
    [CoreApiEntityType.publicInstruments]: ROUTE_KEY.R_PUBLIC_INSTRUMENT_DETAIL,
    [CoreApiEntityType.publicInstrumentGroups]: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_DETAIL,
};

export const CORE_API_ENTITY_TYPE_TO_PLURAL_ENTITY_NAME_TRANSLATION_MAP: { [key in CoreApiEntityType]: string; } = {
    [CoreApiEntityType.goals]: 'portfolio_mgmt.goals.title',
    [CoreApiEntityType.horizons]: 'portfolio_mgmt.horizons.title',
    [CoreApiEntityType.riskProfiles]: 'portfolio_mgmt.risk_profiles.title',
    [CoreApiEntityType.policies]: 'portfolio_mgmt.policies.title',
    [CoreApiEntityType.portfolios]: 'portfolio_mgmt.portfolios.title',
    [CoreApiEntityType.portfolioGroups]: 'portfolio_mgmt.portfolio_groups.title',
    [CoreApiEntityType.optimizations]: null,
    [CoreApiEntityType.userGroups]: 'user_mgmt.user_groups.title',
    [CoreApiEntityType.users]: 'user_mgmt.users.title',
    [CoreApiEntityType.publicInstruments]: 'thematic_search.public_instruments.title',
    [CoreApiEntityType.publicInstrumentGroups]: 'thematic_search.public_instrument_groups.title',
};

export const CORE_API_ENTITY_TYPE_TO_SINGULAR_ENTITY_NAME_TRANSLATION_MAP: { [key in CoreApiEntityType]: string; } = {
    [CoreApiEntityType.goals]: 'portfolio_mgmt.goals.singular_name',
    [CoreApiEntityType.horizons]: 'portfolio_mgmt.horizons.singular_name',
    [CoreApiEntityType.riskProfiles]: 'portfolio_mgmt.risk_profiles.singular_name',
    [CoreApiEntityType.policies]: 'portfolio_mgmt.policies.singular_name',
    [CoreApiEntityType.portfolios]: 'portfolio_mgmt.portfolios.singular_name',
    [CoreApiEntityType.portfolioGroups]: 'portfolio_mgmt.portfolio_groups.singular_name',
    [CoreApiEntityType.optimizations]: null,
    [CoreApiEntityType.userGroups]: 'user_mgmt.user_groups.singular_name',
    [CoreApiEntityType.users]: 'user_mgmt.users.singular_name',
    [CoreApiEntityType.publicInstruments]: 'thematic_search.public_instruments.singular_name',
    [CoreApiEntityType.publicInstrumentGroups]: 'thematic_search.public_instrument_groups.singular_name',
};

export const CORE_API_ENTITY_TYPE_TO_FIELD_TRANSLATION_PREFIX_MAP: { [key in CoreApiEntityType]: string; } = {
    [CoreApiEntityType.goals]: 'portfolio_mgmt.goals.detail.fields',
    [CoreApiEntityType.horizons]: 'portfolio_mgmt.horizons.detail.fields',
    [CoreApiEntityType.riskProfiles]: 'portfolio_mgmt.risk_profiles.detail.fields',
    [CoreApiEntityType.policies]: 'portfolio_mgmt.policies.detail.fields',
    [CoreApiEntityType.portfolios]: 'portfolio_mgmt.portfolios.detail.fields',
    [CoreApiEntityType.portfolioGroups]: 'portfolio_mgmt.portfolio_groups.detail.fields',
    [CoreApiEntityType.optimizations]: null,
    [CoreApiEntityType.userGroups]: 'user_mgmt.user_groups.detail.fields',
    [CoreApiEntityType.users]: 'user_mgmt.users.detail.fields',
    [CoreApiEntityType.publicInstruments]: 'thematic_search.public_instruments.detail.fields',
    [CoreApiEntityType.publicInstrumentGroups]: 'thematic_search.public_instrument_groups.detail.fields',
};

export const CORE_API_ENTITY_TYPE_TO_ENTITY_NAME_PREFIX: { [key in CoreApiEntityType]: string; } = {
    [CoreApiEntityType.goals]: 'goal',
    [CoreApiEntityType.horizons]: 'horizon',
    [CoreApiEntityType.riskProfiles]: 'risk-profile',
    [CoreApiEntityType.policies]: 'policy',
    [CoreApiEntityType.portfolios]: 'portfolio',
    [CoreApiEntityType.portfolioGroups]: 'portfolio-groups',
    [CoreApiEntityType.optimizations]: 'optimization',
    [CoreApiEntityType.userGroups]: 'user-group',
    [CoreApiEntityType.users]: 'user',
    [CoreApiEntityType.publicInstruments]: 'public-instrument',
    [CoreApiEntityType.publicInstrumentGroups]: 'public-instrument-group',
};
