import { getRegisteredStore } from '@snipsonian/observable-state/cjs/store/storeManager';
import { IActionableObservableStateStore } from '@snipsonian/observable-state/cjs/actionableStore/types';
import { createObservableStateAction } from '@snipsonian/observable-state/cjs/actionableStore/actionCreators';
import { IState, IExtraProcessInput, IAction } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';

/** Without circular dependencies */
export function getStore() {
    // eslint-disable-next-line max-len
    return getRegisteredStore<IState, StateChangeNotification>() as IActionableObservableStateStore<IState, StateChangeNotification>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function createAction<Payload extends {}>(
    action: IAction<Payload>,
) {
    return createObservableStateAction<string, Payload, IState, IExtraProcessInput, StateChangeNotification>(action);
}
