import React, { useState } from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { yearOffset } from '@console/common/utils/date/getSpecificDate';
import { UniverseType } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { SampleFrequencyPerformancePast } from '@console/core-api/models/performance.models';
import { TEnhancedPolicyComposition } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { triggerFetchInstrumentPerformancePast } from 'state/entities/portfolioMgmt/instrumentPerformancePast';
import GenericInstrumentsList, {
    getDefaultInstrumentCols,
} from 'views/portfolioMgmt/instruments/GenericInstrumentsList';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import InputRadioField from 'views/common/inputs/base/InputRadioField';
import AmountDisplay from 'views/common/widget/AmountDisplay';
import IconButton from 'views/common/buttons/IconButton';
import { PerformanceIcon } from 'views/common/icons';
import Modal from 'views/common/layout/Modal';
import InstrumentPerformancePast
    from '../PolicyDetail/PolicyConfiguration/PolicySimulationExpectedPerformance/InstrumentPerformancePast';
import { IAddCompositionElementProps, ISelectedCompositionElementState } from './types';

interface IAddModelPortfolioCompositionProps {
    currency: SupportedCurrency;
    currentComposition: TEnhancedPolicyComposition;
    selectedElementState: ISelectedCompositionElementState;
    setSelectedElementState: (elementState: ISelectedCompositionElementState) => void;
}

interface IPolicyAddCompositionInstrumentCols extends IColValues {
    selected: boolean;
    name: string;
    type: string;
    price: number;
    performance: null;
}

interface IPolicyAddCompositionInstrumentExtraData {
    currency: SupportedCurrency;
    isSelectable: boolean;
    setSelectedCompositionInstrument: (props: IAddCompositionElementProps) => void;
    openPastPerformanceModal: (isin: string) => void;
}

const defaultInstrumentCols = getDefaultInstrumentCols({
    desiredCols: [
        { colName: 'name', percentWidth: 60 },
        { colName: 'type', percentWidth: 15 },
    ],
});

const COLS: TDataColumns<IPolicyAddCompositionInstrumentCols, IPolicyAddCompositionInstrumentExtraData> = {
    selected: {
        label: EMPTY_COL_TITLE,
        data: {
            render: ({ item }) => (
                <InputRadioField
                    name={item.id}
                    value={item.id}
                    selected={item.colValues.selected}
                    disabled={!item.extra.isSelectable}
                    onSelected={() => item.extra.setSelectedCompositionInstrument({
                        compositionElementId: item.id,
                        compositionElementName: item.colValues.name,
                    })}
                />
            ),
        },
        percentWidth: 5,
        align: 'right',
    },
    name: {
        ...defaultInstrumentCols.name,
    },
    type: {
        ...defaultInstrumentCols.type,
    },
    price: {
        label: {
            msg: 'portfolio_mgmt.instruments.list.columns.price',
        },
        data: {
            render: ({ item }) => (
                <AmountDisplay
                    value={item.colValues.price}
                    currency={item.extra.currency}
                />
            ),
        },
        percentWidth: 15,
    },
    performance: {
        label: EMPTY_COL_TITLE,
        data: {
            render: ({ item }) => (
                <IconButton
                    id="composition-instrument-performance"
                    variant="bare"
                    size="XS"
                    svgSize={24}
                    color="text"
                    onClick={() => item.extra.openPastPerformanceModal(item.id)}
                    icon={<PerformanceIcon />}
                />
            ),
        },
        percentWidth: 5,
    },
};

export default function AddModelPortfolioCompositionElement({
    currency,
    currentComposition,
    selectedElementState,
    setSelectedElementState,
}: IAddModelPortfolioCompositionProps) {
    const [isInstrumentPerformanceModalOpen, setIsInstrumentPerformanceModalOpen] = useState<boolean>(false);

    return (
        <>
            <Modal
                id="model_composition_instrument_past_performance"
                title="portfolio_mgmt.instruments.performance_modal.title"
                open={isInstrumentPerformanceModalOpen}
                onClose={() => setIsInstrumentPerformanceModalOpen(false)}
                maxWidth="md"
            >
                <InstrumentPerformancePast />
            </Modal>
            <GenericInstrumentsList
                universe_type={UniverseType.Robo}
                currency={currency}
                styleCell={styleInstrumentTable}
                onInstrumentRowClicked={onInstrumentRowClicked}
                isInstrumentRowClickable={isInstrumentRowClickable}
                overrideCols={{
                    cols: COLS,
                    toInstrumentListItem: ({ instrument, defaultInstrumentCols: defaultCols }) => ({
                        id: instrument.id,
                        colValues: {
                            selected: isSetString(selectedElementState[instrument.id]),
                            name: defaultCols.name,
                            type: defaultCols.type,
                            price: defaultCols.price,
                            performance: null,
                        },
                        extra: {
                            isSelectable: !isSet(currentComposition[instrument.id]),
                            currency,
                            setSelectedCompositionInstrument,
                            openPastPerformanceModal: openPerformanceChart,
                        },
                    }),
                }}
            />
        </>
    );

    function isInstrumentRowClickable({
        extra,
    }: IDataItem<IPolicyAddCompositionInstrumentCols, IPolicyAddCompositionInstrumentExtraData>) {
        return extra.isSelectable;
    }

    function onInstrumentRowClicked({
        id,
        colValues,
        extra,
    }: IDataItem<IPolicyAddCompositionInstrumentCols, IPolicyAddCompositionInstrumentExtraData>) {
        if (extra.isSelectable) {
            setSelectedCompositionInstrument({
                compositionElementId: id,
                compositionElementName: colValues.name,
            });
        }
    }

    function setSelectedCompositionInstrument({
        compositionElementId,
        compositionElementName,
    }: IAddCompositionElementProps) {
        setSelectedElementState({
            [compositionElementId]: compositionElementName,
        });
    }

    function styleInstrumentTable({
        dataItem,
    }: IToDynamicCellClassProps<IPolicyAddCompositionInstrumentCols, IPolicyAddCompositionInstrumentExtraData>) {
        return !dataItem.extra.isSelectable && [UtilityClass.table.cellDisabled];
    }

    function openPerformanceChart(isin: string) {
        setIsInstrumentPerformanceModalOpen(true);

        triggerFetchInstrumentPerformancePast({
            isin,
            currency,
            universe_type: UniverseType.Robo,
            startDate: formatDate({
                date: yearOffset({ yearsToAdd: -10 }),
                format: DATE_FORMAT.BACK_END,
            }),
            sampleFrequency: SampleFrequencyPerformancePast.BM,
            instrumentAmount: 1,
        });
    }
}
