// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ROUTE_KEY {
    R_LOGIN = 'R_LOGIN',
    R_HOME = 'R_HOME',

    R_POLICIES_LIST = 'R_POLICIES_LIST',
    R_POLICY_ADD = 'R_POLICY_ADD',
    R_POLICY_DETAIL = 'R_POLICY_DETAIL',
    R_POLICY_VERSIONS = 'R_POLICY_VERSIONS',

    R_PORTFOLIOS_LIST = 'R_PORTFOLIOS_LIST',
    R_PORTFOLIO_ADD = 'R_PORTFOLIO_ADD',
    R_PORTFOLIO_DETAIL = 'R_PORTFOLIO_DETAIL',
    R_PORTFOLIO_VERSIONS = 'R_PORTFOLIO_VERSIONS',
    // R_PORTFOLIO_OPTIMIZATIONS = 'R_PORTFOLIO_OPTIMIZATIONS',
    R_PORTFOLIO_OPTIMIZATION_DETAIL = 'R_PORTFOLIO_OPTIMIZATION_DETAIL',

    R_PORTFOLIO_GROUPS_LIST = 'R_PORTFOLIO_GROUPS_LIST',
    R_PORTFOLIO_GROUP_DETAIL = 'R_PORTFOLIO_GROUP_DETAIL',
    R_PORTFOLIO_GROUP_ADD = 'R_PORTFOLIO_GROUP_ADD',
    R_PORTFOLIO_GROUP_VERSIONS = 'R_PORTFOLIO_GROUP_VERSIONS',

    R_GOALS_LIST = 'R_GOALS_LIST',
    R_GOAL_ADD = 'R_GOAL_ADD',
    R_GOAL_DETAIL = 'R_GOAL_DETAIL',
    R_GOAL_VERSIONS = 'R_GOAL_VERSIONS',

    R_HORIZONS_LIST = 'R_HORIZONS_LIST',
    R_HORIZON_ADD = 'R_HORIZON_ADD',
    R_HORIZON_DETAIL = 'R_HORIZON_DETAIL',
    R_HORIZON_VERSIONS = 'R_HORIZON_VERSIONS',

    R_RISK_PROFILES_LIST = 'R_RISK_PROFILES_LIST',
    R_RISK_PROFILE_ADD = 'R_RISK_PROFILE_ADD',
    R_RISK_PROFILE_DETAIL = 'R_RISK_PROFILE_DETAIL',
    R_RISK_PROFILE_VERSIONS = 'R_RISK_PROFILE_VERSIONS',

    R_ROBOADVISOR_DASHBOARD = 'R_ROBOADVISOR_DASHBOARD',
    R_ROBOADVISOR_ANALYTICS_DASHBOARD = 'R_ROBOADVISOR_ANALYTICS_DASHBOARD',

    R_STORYTELLER_DASHBOARD = 'R_STORYTELLER_DASHBOARD',
    R_STORYTELLER_REPORTS = 'R_STORYTELLER_REPORTS',
    R_STORY_MANAGER_VERSIONS = 'R_STORY_MANAGER_VERSIONS',
    R_STORY_MANAGER_DATABASE_OUTPUT_KEYS = 'R_STORY_MANAGER_DATABASE_OUTPUT_KEYS',
    R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL = 'R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL',
    // eslint-disable-next-line max-len
    R_STORY_MANAGER_DATABASE_OUTPUT_KEY_SCENARIO_DETAIL = 'R_STORY_MANAGER_DATABASE_OUTPUT_KEY_SCENARIO_DETAIL',
    R_STORY_MANAGER_DATABASE_FIND_AND_REPLACE = 'R_STORY_MANAGER_DATABASE_FIND_AND_REPLACE',

    R_USERS_LIST = 'R_USERS_LIST',
    R_USER_ADD = 'R_USER_ADD',
    R_USER_DETAIL = 'R_USER_DETAIL',
    R_USER_DETAIL_PORTFOLIOS = 'R_USER_DETAIL_PORTFOLIOS',
    R_USER_DETAIL_PORTFOLIO_DETAIL = 'R_USER_DETAIL_PORTFOLIO_DETAIL',
    R_USER_DETAIL_PORTFOLIO_VERSIONS = 'R_USER_DETAIL_PORTFOLIO_VERSIONS',
    R_USER_DETAIL_PORTFOLIO_REPORTS = 'R_USER_DETAIL_PORTFOLIO_REPORTS',
    R_USER_VERSIONS = 'R_USER_VERSIONS',

    R_USER_GROUPS_LIST = 'R_USER_GROUPS_LIST',
    R_USER_GROUP_ADD = 'R_USER_GROUP_ADD',
    R_USER_GROUP_DETAIL = 'R_USER_GROUP_DETAIL',
    R_USER_GROUP_VERSIONS = 'R_USER_GROUP_VERSIONS',

    R_CLIENTS_LIST = 'R_CLIENTS_LIST',
    R_CLIENT_ADD = 'R_CLIENT_ADD',
    R_CLIENT_DETAIL = 'R_CLIENT_DETAIL',
    R_CLIENT_DETAIL_PORTFOLIOS = 'R_CLIENT_DETAIL_PORTFOLIOS',
    R_CLIENT_DETAIL_PORTFOLIO_DETAIL = 'R_CLIENT_DETAIL_PORTFOLIO_DETAIL',
    R_CLIENT_DETAIL_PORTFOLIO_VERSIONS = 'R_CLIENT_DETAIL_PORTFOLIO_VERSIONS',
    R_CLIENT_DETAIL_PORTFOLIO_REPORTS = 'R_CLIENT_DETAIL_PORTFOLIO_REPORTS',
    R_CLIENT_VERSIONS = 'R_CLIENT_VERSIONS',

    R_MGMT_REPORTING = 'R_MGMT_REPORTING',

    R_RELEASES = 'R_RELEASES',

    R_PUBLIC_INSTRUMENTS_LIST = 'R_PUBLIC_INSTRUMENTS_LIST',
    R_PUBLIC_INSTRUMENT_ADD = 'R_PUBLIC_INSTRUMENT_ADD',
    R_PUBLIC_INSTRUMENT_DETAIL = 'R_PUBLIC_INSTRUMENT_DETAIL',
    R_PUBLIC_INSTRUMENT_VERSIONS = 'R_PUBLIC_INSTRUMENT_VERSIONS',

    R_PUBLIC_INSTRUMENT_GROUPS_LIST = 'R_PUBLIC_INSTRUMENT_GROUPS_LIST',
    R_PUBLIC_INSTRUMENT_GROUP_ADD = 'R_PUBLIC_INSTRUMENT_GROUP_ADD',
    R_PUBLIC_INSTRUMENT_GROUP_DETAIL = 'R_PUBLIC_INSTRUMENT_GROUP_DETAIL',
    R_PUBLIC_INSTRUMENT_GROUP_VERSIONS = 'R_PUBLIC_INSTRUMENT_GROUP_VERSIONS',

    R_STYLE_GUIDE = 'R_STYLE_GUIDE',
    R_NOT_FOUND = 'R_NOT_FOUND',
}
