import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { anyComparerDescending, stringComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import getArrayCopy from '@snipsonian/core/cjs/array/getArrayCopy';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IEnhancedPortfolioAllocation,
    IEnhancedPortfolioAllocationCategory,
    IEnhancedPortfolioAllocationSubCategory,
} from '@console/bff/models/portfolios/portfolioAllocation.models';
import { IInstrumentIdToNameMap } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { APP_COLORS } from 'config/styling/colors';
import {
    portfolioAllocationEntity,
} from 'state/entities/portfolioMgmt/portfolioAllocation';
import { getTranslator } from 'state/i18n/selectors';
import { formatPercentage } from 'utils/number/percentageUtils';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import { EntityWrapperNoExtraObserve } from 'views/common/widget/EntityWrapper';
import ContentBox from 'views/common/layout/ContentBox';
import ContentTitle from 'views/common/layout/ContentTitle';
import ProgressBar from 'views/common/widget/ProgressBar';
import IconButtonCollapse from 'views/common/buttons/IconButtonCollapse';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import FunctionalityNotAvailableForPortfolioType
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/FunctionalityNotAvailableForPortfolioType';
import { isUserManagedPortfolio } from 'utils/entities/portfolioMgmt/portfolioUtils';
import { formatInstrumentOrListingIdForDisplay } from 'utils/entities/portfolioMgmt/instrumentUtils';

type TAllocationCategory = 'asset_classes' | 'regions.bonds' | 'regions.stocks' | 'sectors';

const useStyles = makeStyles((theme) => ({
    PortfolioAllocation: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'flex-start', wrap: 'wrap' }),

        '& .__column': {
            ...mixins.flexColTopLeft(),
            flexGrow: 1,
            padding: theme.spacing(0, 1),
        },
        '& .__allocationCategoryBox': {
            ...mixins.widthMax(),
            marginBottom: theme.spacing(2),
        },
        '& .__allocationCategoryBox .ContentTitle': {
            marginTop: theme.spacing(1),
        },
        '& .__allocationCategoryBox .ContentBox': {
            ...mixins.flexColTopCenter(),
            ...mixins.widthMax(),
        },
        '& .__allocationCategoryBoxContent': {
            maxWidth: 460,
        },
        '& .__allocationSubCategories': {
            padding: theme.spacing(1, 0),
        },
        '& .__allocationSubRow': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            height: 48,
            padding: theme.spacing(0, 1),
        },
        '& .__allocationSubCategory': {
            ...mixins.typo({ size: 15, weight: 'bold' }),
        },
        '& .__allocationSubCategory:not(:first-child)': {
            ...mixins.borderTopGrey(),
        },
        '& .__allocationSubInstrument': {
            ...mixins.typo({ size: 15, weight: 'normal', color: APP_COLORS.TEXT['400'] }),
        },
        '& .__allocationSubCategory-name': {
            width: 140,
        },
        '& .__allocationSubCategory-percentage': {
            width: 70,
            textAlign: 'end',
        },
        '& .__allocationSubCategory-collapse': {
            width: 40,
            paddingLeft: theme.spacing(1),
        },
        '& .__allocationSubCategory-instrumentName': {
            ...mixins.widthMax(),
        },
    },
}));

function PortfolioAllocation({ state }: IObserveProps) {
    const classes = useStyles();
    const translator = getTranslator(state);

    const doNotShowAllocation = isUserManagedPortfolio(portfolioDetailsEntity.select().data);

    if (doNotShowAllocation) {
        return (
            <ContentBox noWrapperPadding>
                <FunctionalityNotAvailableForPortfolioType />
            </ContentBox>
        );
    }

    return (
        <EntityWrapperNoExtraObserve
            asyncEntitySelector={portfolioAllocationEntity.select}
            renderData={renderPortfolioAllocation}
        />
    );

    function renderPortfolioAllocation({
        data: enhancedPortfolioAllocation,
    }: { data: IEnhancedPortfolioAllocation }) {
        const percentageAllCategories = [
            enhancedPortfolioAllocation.assetClasses,
            enhancedPortfolioAllocation.regionsBonds,
            enhancedPortfolioAllocation.regionsStocks,
            enhancedPortfolioAllocation.sectors,
        ]
            .reduce(
                (sumAccumulator, portfolioCategory) => {
                    // eslint-disable-next-line no-param-reassign
                    sumAccumulator += portfolioCategory.totalPercentage;
                    return sumAccumulator;
                },
                0,
            );

        if (percentageAllCategories === 0) {
            return (
                <div>
                    <Translate msg="portfolio_mgmt.portfolios.detail.allocation.no_allocation" />
                </div>
            );
        }

        return (
            <div className={classes.PortfolioAllocation}>
                <div className="__column">
                    <AllocationCategoryBox
                        category="asset_classes"
                        categoryData={enhancedPortfolioAllocation.assetClasses}
                        instrumentMap={enhancedPortfolioAllocation.instrumentMap}
                        translator={translator}
                    />

                    <AllocationCategoryBox
                        category="regions.bonds"
                        categoryData={enhancedPortfolioAllocation.regionsBonds}
                        instrumentMap={enhancedPortfolioAllocation.instrumentMap}
                        translator={translator}
                    />

                    <AllocationCategoryBox
                        category="regions.stocks"
                        categoryData={enhancedPortfolioAllocation.regionsStocks}
                        instrumentMap={enhancedPortfolioAllocation.instrumentMap}
                        translator={translator}
                    />
                </div>

                <div className="__column">
                    <AllocationCategoryBox
                        category="sectors"
                        categoryData={enhancedPortfolioAllocation.sectors}
                        instrumentMap={enhancedPortfolioAllocation.instrumentMap}
                        translator={translator}
                    />
                </div>
            </div>
        );
    }
}

export default observe(
    [StateChangeNotification.PORTFOLIO_ALLOCATION],
    PortfolioAllocation,
);

function AllocationCategoryBox({
    category,
    categoryData,
    instrumentMap,
    translator,
}: {
    category: TAllocationCategory;
    categoryData: IEnhancedPortfolioAllocationCategory;
    instrumentMap: IInstrumentIdToNameMap;
    translator: TTranslator;
}) {
    const translatedSubCategories = getArrayCopy(categoryData.subCategories)
        .map(({ subCategoryKey, ...subCategoryData }) => ({
            subCategoryKey,
            ...subCategoryData,
            translatedName: translator(`portfolio_mgmt.portfolios.detail.allocation.${category}.${subCategoryKey}`),
        }))
        .sort((subCatA, subCatB) =>
            stringComparerAscending(subCatA.translatedName, subCatB.translatedName));

    return (
        <ContentBox
            noWrapperPadding
            className="__allocationCategoryBox"
        >
            <div className="__allocationCategoryBoxContent">
                <ContentTitle
                    label={`portfolio_mgmt.portfolios.detail.allocation.${category}.title`}
                    variant="section"
                />

                <div className="__allocationSubCategories">
                    {translatedSubCategories.map(({ subCategoryKey, ...subCategoryData }) => (
                        <AllocationSubCategory
                            key={`allocation_${category}_${subCategoryKey}`}
                            category={category}
                            subCategoryKey={subCategoryKey}
                            subCategoryData={subCategoryData}
                            instrumentMap={instrumentMap}
                        />
                    ))}
                </div>
            </div>
        </ContentBox>
    );
}

function AllocationSubCategory({
    category,
    subCategoryKey,
    subCategoryData,
    instrumentMap,
}: {
    category: TAllocationCategory;
    subCategoryKey: string;
    subCategoryData: IEnhancedPortfolioAllocationSubCategory & { translatedName: string };
    instrumentMap: IInstrumentIdToNameMap;
}) {
    const [isCollapsed, setIsCollapsed] = React.useState(true);

    const instruments = getArrayCopy(subCategoryData.instruments)
        .sort((instruA, instruB) =>
            anyComparerDescending(instruA.percentage, instruB.percentage));
    const hasInstruments = instruments.length > 0;

    return (
        <>
            <div className="__allocationSubRow __allocationSubCategory">
                <div className="__allocationSubCategory-name">
                    {subCategoryData.translatedName}
                </div>

                <ProgressBar percentage={subCategoryData.percentage} totalWidth={184} />

                <div className="__allocationSubCategory-percentage">
                    {formatPercentage(subCategoryData.percentage)}
                </div>

                <div className="__allocationSubCategory-collapse">
                    {hasInstruments && (
                        <IconButtonCollapse
                            id={`allocation-collapse_${category}_${subCategoryKey}`}
                            isCollapsed={isCollapsed}
                            onCollapseChange={setIsCollapsed}
                        />
                    )}
                    {!hasInstruments && ' '}
                </div>
            </div>
            {!isCollapsed && instruments
                .filter((instrument) => instrument.percentage.toFixed(2) !== '0.00')
                .map((instrument) => (
                    <div
                        key={`allocation-instrument_${category}_${subCategoryKey}_${instrument.isin}`}
                        className="__allocationSubRow __allocationSubInstrument"
                    >
                        <div className="__allocationSubCategory-instrumentName">
                            {instrumentMap[instrument.isin] || formatInstrumentId(instrument.isin)}
                        </div>
                        <div className="__allocationSubCategory-percentage">
                            {formatPercentage(instrument.percentage)}
                        </div>
                        <div className="__allocationSubCategory-collapse">&nbsp;</div>
                    </div>
                ))}
        </>
    );
}

function formatInstrumentId(isin: string) {
    return formatInstrumentOrListingIdForDisplay(
        isin.replace('$', ''),
    );
}
