import { createAction } from 'state';
import { IOpenAppModalPayload } from 'models/state/ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ICreatePortfolioReportModalData } from 'models/ui/portfolioReport.ui.models';
import { IEntityStillUsedModalData } from 'models/ui/entityStillUsed.ui.models';

export const openCreatePortfolioReportAppModal = (payload: IOpenAppModalPayload<ICreatePortfolioReportModalData>) =>
    createAction<IOpenAppModalPayload<ICreatePortfolioReportModalData>>({
        type: 'OPEN_CREATE_PORTFOLIO_REPORT_APP_MODAL',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    draftState.ui.appModals.createPortfolioReport = {
                        open: true,
                        extraData: payload.extraData,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_APP_MODALS],
            });
        },
    });

export const closeCreatePortfolioReportAppModal = () =>
    createAction<unknown>({
        type: 'CLOSE_CREATE_PORTFOLIO_REPORT_APP_MODAL',
        payload: null,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    draftState.ui.appModals.createPortfolioReport = {
                        open: false,
                        extraData: null,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_APP_MODALS],
            });
        },
    });

export const openEntityStillUsedAppModal = (payload: IOpenAppModalPayload<IEntityStillUsedModalData>) =>
    createAction<IOpenAppModalPayload<IEntityStillUsedModalData>>({
        type: 'OPEN_ENTITY_STILL_USED_APP_MODAL',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    draftState.ui.appModals.entityStillUsed = {
                        open: true,
                        extraData: payload.extraData,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_APP_MODALS],
            });
        },
    });

export const closeEntityStillUsedAppModal = () =>
    createAction<unknown>({
        type: 'CLOSE_ENTITY_STILL_USED_APP_MODAL',
        payload: null,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    draftState.ui.appModals.entityStillUsed = {
                        open: false,
                        extraData: null,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_APP_MODALS],
            });
        },
    });
