import { BankAccountType } from '@console/common/models/banking.models';
import { stringComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import {
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { PolicyManagerTag, TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import {
    IEnhancedPortfolioCash,
    IEnhancedPortfolioInstruments,
} from '@console/bff/models/portfolios/portfolioHoldings.models';
import { IFormValues } from 'views/common/inputs/extended/ExtendedInputForm';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TUser } from '@console/core-api/models/userMgmt/user.models';

export interface IPortfolioHoldingsFormValues extends IFormValues {
    currency: SupportedCurrency;
    cash: IEnhancedPortfolioCash;
    instruments: IEnhancedPortfolioInstruments;
    instrumentsTotalValue: number;
    instrumentsPercentageWithinHoldings: number;
}

export interface IPortfolioAddFormValues extends IFormValues {
    name: string;
    externalId: string;
    managerType: PortfolioManagerType;
    moneyType: PortfolioMoneyType;
    policy: TPolicy;
    goalId: string;
    horizonId: string;
    owner: TUser;
    startAmount: number;
}

export interface IPortfolioFormValues extends Omit<IPortfolioAddFormValues, 'startAmount'> {
    tags: PolicyManagerTag[];
    bankAccountNumber: string;
    bankAccountType: BankAccountType; // e.g. 'IBAN'
    bankId: string;
    brokeragePortfolioId: string;
    brokerageUserId: string;
    paymentReference: string;
    active: boolean;
    recurringAmount: number;
    divestAmount: number;
    riskProfileScore: number;
    riskProfileId: string;
    proposedRiskProfileId: string;
}

export type TPortfolioDetailsFormValues = Pick<IPortfolioFormValues,
'name' | 'externalId' | 'active' | 'managerType' | 'moneyType'>;

export type TPortfolioRoboRelatedFormValues = Pick<IPortfolioFormValues,
'goalId' | 'tags' | 'horizonId' | 'policy' | 'managerType' | 'startAmount' | 'divestAmount' | 'recurringAmount'>;

export type TPortfolioBrokerageAccountFormValues = Pick<IPortfolioFormValues,
'bankAccountNumber' | 'bankAccountType' | 'bankId' | 'brokeragePortfolioId' | 'brokerageUserId' | 'paymentReference'>;

export type TPortfolioRiskProfileFormValues = Pick<IPortfolioFormValues,
'riskProfileId' | 'managerType' | 'proposedRiskProfileId' | 'riskProfileScore'>;

export type TPortfolioOwnerFormValues = Pick<IPortfolioFormValues, 'owner'>;

export type TPortfolioPolicyFormValues = Pick<IPortfolioFormValues, 'policy' | 'managerType'>;

export function selectItemComparerOnLabelAscending(
    selectItemA: IInputSelectItem<string>,
    selectItemB: IInputSelectItem<string>,
) {
    return stringComparerAscending(selectItemA.label as string, selectItemB.label as string);
}

export type TPortfolioAmountFormValues = Pick<IPortfolioFormValues, 'divestAmount' | 'startAmount' | 'recurringAmount'>;
