import { IApiEntityListResponse, IBaseSingleEntityApiInput } from '../../models/api.models';
import {
    IFetchRiskProfilesApiInput, ISingleRiskProfileApiInput,
    IRiskProfileEntityData, TRiskProfilesData,
    TRiskProfile, TRiskProfilePatch, TRiskProfileCreate,
} from '../../models/portfolioMgmt/riskProfiles.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { fetchAllPagesOfApiEntity } from '../../utils/fetch/fetchAllPagesOfApiEntity';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchAllRiskProfiles() {
    return fetchAllPagesOfApiEntity<IFetchRiskProfilesApiInput, IRiskProfileEntityData>({
        apiInput: {
            count: false,
        },
        api: fetchRiskProfiles,
    });
}

export function fetchRiskProfiles({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    orderBy,
    count,
}: IFetchRiskProfilesApiInput = {}) {
    return get<TRiskProfilesData, IApiEntityListResponse<IRiskProfileEntityData>>({
        url: CoreApiPath.RISK_PROFILES,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchRiskProfileDetails({ riskProfileId }: ISingleRiskProfileApiInput) {
    return get<TRiskProfile>({
        url: CoreApiPath.RISK_PROFILE_DETAILS,
        pathParams: {
            riskProfileId,
        },
    });
}

export function patchRiskProfile({
    id,
    ...patchFields
}: TRiskProfilePatch) {
    return patch<TRiskProfile>({
        url: CoreApiPath.RISK_PROFILE_DETAILS,
        pathParams: {
            riskProfileId: id,
        },
        body: patchFields,
    });
}

export function createRiskProfile(riskProfileToCreate: TRiskProfileCreate) {
    return post<TRiskProfile>({
        url: CoreApiPath.RISK_PROFILES,
        body: riskProfileToCreate,
    });
}

export function deleteRiskProfile({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.RISK_PROFILE_DETAILS,
        pathParams: {
            riskProfileId: id,
        },
    });
}
