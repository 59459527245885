import React from 'react';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import isArray from '@snipsonian/core/cjs/is/isArray';
import { makeStyles, mixins } from 'views/styling';
import { APP_COLORS } from 'config/styling/colors';

interface IPublicProps {
    children: React.ReactNode;
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    Card: {
        ...mixins.flexColTopLeft(),
        ...mixins.typo({ size: 16 }),
        backgroundColor: APP_COLORS.SYSTEM.WHITE, // TODO is this color correct?
        borderRadius: 12,
        padding: '0 4px',

        '& .CardItem': {
            ...mixins.flexColCenterLeft(),
            padding: theme.spacing(1, 2),
            minHeight: 48,
        },
        '& .MuiListItem-divider': {
            ...mixins.borderBottom({
                color: APP_COLORS.SYSTEM.BACKGROUND,
            }),
        },
    },
}));

export default function Card({ children, className }: IPublicProps) {
    const classes = useStyles();

    const childrenArray = isArray<React.ReactNode[]>(children)
        ? children
        : [children];

    return (
        <div className={clsx(classes.Card, className)}>
            {childrenArray.map((cardItem: unknown, index) => (
                /* eslint-disable react/no-array-index-key */
                <ListItem
                    key={`cardItem_${index}`}
                    className="CardItem"
                    divider={index !== childrenArray.length - 1}
                >
                    {cardItem}
                </ListItem>
                /* eslint-enable react/no-array-index-key */
            ))}
        </div>
    );
}
