import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import {
    ISingleUserGroupApiInput,
    TUserGroup,
    TUserGroupCreate,
    TUserGroupPatch,
} from '@console/core-api/models/userMgmt/userGroup.models';
import { IBaseSingleEntityApiInput } from '@console/core-api/models/api.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { DEFAULT_USERGROUP_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import {
    flashErrorApiEntityCreate, flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate, flashSuccessApiEntityPatch,
} from 'state/entities/entitiesFlashDispatcher';
import { triggerResetUserGroupsFetch } from 'state/entities/userMgmt/userGroups';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const userGroupDetailsEntity = getEntitiesManager().registerEntity<TUserGroup>({
    asyncEntityKey: AsyncEntityKeys.userGroupDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.USERGROUP_DETAILS_DATA],
    includeUpdaters: true,
});

export const getUserGroupTitle: TTitleLabelSelector = () => {
    const userGroupDetails = userGroupDetailsEntity.select();

    if (hasFetchSucceeded(userGroupDetails)) {
        return {
            text: userGroupDetails.data.name,
            shouldTranslate: false,
        };
    }

    return 'user_mgmt.user_groups.detail.title';
};

export function triggerFetchUserGroupDetails(apiInput: ISingleUserGroupApiInput) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.userGroupId, entityType: CoreApiEntityType.userGroups })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TUserGroup, ISingleUserGroupApiInput>({
        detailsEntity: userGroupDetailsEntity,
        entityType: CoreApiEntityType.userGroups,
        api: api.userGroups.fetchUserGroupDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () => userGroupDetailsEntity.select().data.id !== apiInput.userGroupId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchUserGroupDetails(userGroup: TUserGroupPatch) {
    return userGroupDetailsEntity.async.update<TUserGroupPatch, TUserGroup>({
        api: api.userGroups.patchUserGroup,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                userGroupDetailsEntity.select().data,
                userGroup,
            ),
            id: userGroup.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: ({ apiResult }) => {
            triggerResetUserGroupsFetch();
            apiCacheManager.resetUserGroup(apiResult);
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreateUserGroup(userGroup: TUserGroupCreate) {
    return userGroupDetailsEntity.async.create<TUserGroupCreate, TUserGroup>({
        api: api.userGroups.createUserGroup,
        apiInputSelector: () => userGroup,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetUserGroupsFetch();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_USER_GROUP_DETAIL,
                params: {
                    groupId: apiResult.id,
                    userGroupTab: DEFAULT_USERGROUP_TAB_KEY,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeleteUserGroup(userGroupIdentifier?: IBaseSingleEntityApiInput) {
    const identifier = userGroupIdentifier || {
        id: userGroupDetailsEntity.select().data.id,
    };

    return apiEntityAsyncDeleteAction<TUserGroup>({
        detailsEntity: userGroupDetailsEntity,
        api: api.userGroups.deleteUserGroup,
        apiInputSelector: () => identifier,
        onPreSuccess: () => {
            triggerResetUserGroupsFetch();
            apiCacheManager.clearUserGroup({ userGroupId: identifier.id });
            redirectTo({ routeKey: ROUTE_KEY.R_USER_GROUPS_LIST });
        },
    });
}
