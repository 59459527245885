import React, { useRef, useEffect } from 'react';
import { APP_COLORS } from 'config/styling/colors';
import d3, { AxisDomain } from 'utils/libs/d3';
import { makeStyles } from 'views/styling';
import { TXScale } from './types';

interface IXAxisProps<XDomain extends AxisDomain = Date> extends IXAxisStyleProps {
    xScale: TXScale<XDomain>;
    tickFormatter: (domainValue: XDomain, index: number) => string;
    nrOfTicks?: number;
    tickValues?: XDomain[]; // takes precedence over nrOfTicks if specified
    showAxisLine?: boolean; // default false
    tickSize?: number; /* length of each tick line - default 0 (= no line) */
    tickPadding?: number; /* distance from X axis - default 14 */
}

interface IXAxisStyleProps {
    textColor?: string; /* default '#183755' */
    textSize?: number; /* default 14 */
}

const useStyles = makeStyles((/* theme */) => ({
    XAxis: {
        color: ({ textColor }) => textColor,
        size: ({ textSize }: IXAxisStyleProps) => textSize,
    },
}));

export default function XAxis({
    xScale,
    tickFormatter,
    nrOfTicks,
    tickValues,
    showAxisLine = false,
    tickSize = 0,
    tickPadding = 14,
    textColor = APP_COLORS.TEXT['500'],
    textSize = 14,
}: IXAxisProps) {
    const ref = useRef();
    const classes = useStyles({ textColor, textSize });

    useEffect(
        () => {
            const xAxis = d3.axisBottom(xScale)
                .tickFormat(tickFormatter)
                .tickSize(tickSize)
                .tickPadding(tickPadding);

            if (tickValues) {
                xAxis.tickValues(tickValues);
            } else if (nrOfTicks) {
                xAxis.ticks(nrOfTicks);
            }

            const g = d3.select<SVGGElement, unknown>(ref.current)
                .call(xAxis);

            if (!showAxisLine) {
                // g.call((gElem) => gElem.select('.domain').remove());
                g.select('.domain').remove();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [xScale],
    );

    return (
        <g ref={ref} className={classes.XAxis} />
    );
}
