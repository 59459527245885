import React from 'react';
import Grid from '@material-ui/core/Grid';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { makeStyles } from 'views/styling';

interface IPublicProps {
    /**
     * By default, the 'InputGroup' will on wider screens equally divide the children over the available 12-part grid.
     * With this number array, you can overrule this default division.
     * Example: when 2 children/columns and you pass [9, 3] then the 1st child will get a 9/12 part
     * and the 2nd childe a 3/12 part.
     */
    childrenGrid?: TGridPart[];
    children: React.ReactNode[];
}

type TGridPart = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const useStyles = makeStyles((/* theme */) => ({
    InputGroup: {
        // undo negative Mui margins
        marginTop: 0,
        marginBottom: 0,

        '& > .MuiGrid-item': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
}));

export default function InputGroup({
    childrenGrid,
    children,
}: IPublicProps) {
    const classes = useStyles();
    const childrenToShow = children.filter((col) => isSet(col));

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            alignContent="stretch"
            spacing={2}
            className={classes.InputGroup}
        >
            {childrenToShow.map((col, colIndex) => {
                const colKey = `input-group-col-${colIndex}`;

                const sm = (childrenGrid && childrenGrid.length === childrenToShow.length)
                    ? childrenGrid[colIndex]
                    : 6;
                const md = childrenToShow.length > 2
                    ? 4
                    : undefined;
                const lg = childrenToShow.length > 3
                    ? 3
                    : undefined;

                return (
                    <Grid
                        item
                        xs={12}
                        sm={sm}
                        md={md}
                        lg={lg}
                        key={colKey}
                        className="InputGroupCol"
                    >
                        {col}
                    </Grid>
                );
            })}
        </Grid>
    );
}
