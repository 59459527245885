import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    getSelectedStoryManagerDatabaseOutputKeyScenario,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { isStoryManagerInScenarioMode } from 'state/ui/storyManager.selector';
import {
    canUserModifyStoryManagerScenarios,
    canUserModifyStoryManagerTextOutputs,
} from 'state/auth/apiEntityAuthorization.selectors';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import { observe, IObserveProps } from 'views/observe';
import ScenarioDetailsForm from './ScenarioDetailsForm';

interface IPublicProps {
    readonly?: boolean;
}

function ScenarioDetail({ state, readonly }: IObserveProps & IPublicProps) {
    const { outputKeyId, scenarioIndex } = useRouteParams();
    const selectedScenario = getSelectedStoryManagerDatabaseOutputKeyScenario(outputKeyId, scenarioIndex);

    if (!selectedScenario) {
        return null;
    }

    const isScenarioEditorMode = isStoryManagerInScenarioMode(state);
    const canUserModifyScenarios = canUserModifyStoryManagerScenarios(state);
    const canUserModifyTextOutputs = canUserModifyStoryManagerTextOutputs(state);

    return (
        <ScenarioDetailsForm
            scenario={selectedScenario}
            isScenarioEditorMode={isScenarioEditorMode}
            canUserModifyScenarios={readonly ? false : canUserModifyScenarios}
            canUserModifyTextOutputs={readonly ? false : canUserModifyTextOutputs}
        />
    );
}

export default observe<IPublicProps>(
    [
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
        StateChangeNotification.STORY_MANAGER_DATABASES,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER,
    ],
    ScenarioDetail,
);
