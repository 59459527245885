import React from 'react';
import { useParams } from 'react-router-dom';
import { IBaseApiEntity } from '@console/core-api/models/api.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { DEFAULT_PUBLIC_INSTRUMENT_TAB_KEY, PUBLIC_INSTRUMENT_TAB_KEY } from 'config/tabs.config';
import {
    getPublicInstrumentTitle,
    publicInstrumentDetailsEntity,
    triggerDeletePublicInstrument,
} from 'state/entities/thematicSearch/publicInstrumentDetails';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { PublicInstrumentAccessibleByTab } from './PublicInstrumentAccessibleByTab';
import { PublicInstrumentPropertiesTab } from './PublicInstrumentPropertiesTab';

const PUBLIC_INSTRUMENT_TABS: ITabItem[] = [{
    key: PUBLIC_INSTRUMENT_TAB_KEY.PROPERTIES,
    label: 'thematic_search.public_instruments.detail.tabs.properties.title',
    component: PublicInstrumentPropertiesTab,
    wrapWithContentBox: false,
}, {
    key: PUBLIC_INSTRUMENT_TAB_KEY.ACCESSIBLE_BY,
    label: 'thematic_search.public_instruments.detail.tabs.accessible_by.title',
    component: PublicInstrumentAccessibleByTab,
}];

const PUBLIC_INSTRUMENT_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getPublicInstrumentTitle,
    notifications: [StateChangeNotification.PUBLIC_INSTRUMENT_DETAILS_DATA],
};

export function PublicInstrumentDetail() {
    const { publicInstrumentTab = DEFAULT_PUBLIC_INSTRUMENT_TAB_KEY } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={PUBLIC_INSTRUMENT_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: PUBLIC_INSTRUMENT_TABS,
                activeTabKey: publicInstrumentTab,
                onTabChange: tabChange,
                entity: {
                    notifications: [StateChangeNotification.PUBLIC_INSTRUMENT_DETAILS_DATA],
                    asyncEntitySelector: publicInstrumentDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getPublicInstrumentDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => publicInstrumentDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.publicInstrumentId as string,
            }}
        />
    );

    function tabChange({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                publicInstrumentTab: tabKey,
            },
        });
    }

    function getPublicInstrumentDetailActions(apiEntity: IBaseApiEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: CoreApiEntityType.publicInstruments,
            deleteTrigger: triggerDeletePublicInstrument,
        })];
    }
}
