import React from 'react';
import clsx from 'clsx';
import { TApiEntity } from '@console/core-api/models/api.models';
import { IGoalEntityData } from '@console/core-api/models/portfolioMgmt/goal.models';
import { goalDetailsEntity, triggerPatchGoalDetails } from 'state/entities/portfolioMgmt/goalDetails';
import { canUserModifyGoal } from 'state/auth/apiEntityAuthorization.selectors';
import { ensureMultiTranslationsLabelHasAllLocales } from 'state/appConfig/selectors';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import { getGoalDetailsSchema } from './goalDetailsSchema';
import { IGoalFormValues, mapGoalFormValuesToApiEntity, renderGoalPropertiesFormFields } from './GoalPropertiesForm';

const LABEL_PREFIX = 'portfolio_mgmt.goals.detail';

export default function GoalPropertiesTab() {
    const goalData = goalDetailsEntity.select().data;
    const isReadOnly = !canUserModifyGoal(goalData);

    return (
        <div className={clsx(readOnlyEntityClass(isReadOnly))}>
            <PropertiesCategoryBox<IGoalFormValues>
                id="goal_details_fields"
                category="details"
                labelPrefix={LABEL_PREFIX}
                isReadOnly={isReadOnly}
                fields={getDetailsFields(goalData)}
                editGlobalForm={getEditDetailsForm(goalData)}
                maxWidth={800}
                centralizeContentBox
            />
        </div>
    );
}

function getDetailsFields(goal: TApiEntity<IGoalEntityData>): IDetailField[] {
    return [{
        label: 'name.label',
        value: goal.name,
    }, {
        label: 'description.label',
        value: goal.description,
        alignValueLeft: true,
    }, {
        label: 'external_id.label',
        value: goal.external_id,
    }, {
        label: 'min_horizon_years.label',
        value: goal.min_horizon_years.toString(),
    }, {
        label: 'type.label',
        value: `portfolio_mgmt.goals.goal_types.${goal.type.toLowerCase()}`,
        shouldTranslateValue: true,
    }, {
        label: 'image_url.label',
        value: goal.image_url,
    }];
}

function getEditDetailsForm(goal: TApiEntity<IGoalEntityData>): IEditForm<IGoalFormValues> {
    return {
        initialValues: {
            name: ensureMultiTranslationsLabelHasAllLocales(goal.name),
            description: ensureMultiTranslationsLabelHasAllLocales(goal.description),
            externalId: goal.external_id,
            minHorizonYears: goal.min_horizon_years,
            type: goal.type,
            imgUrl: goal.image_url,
        },
        schema: getGoalDetailsSchema(),
        renderFormFields: renderGoalPropertiesFormFields,
        submit: {
            onSubmit: ({ values }) => triggerPatchGoalDetails({
                id: goal.id,
                ...mapGoalFormValuesToApiEntity(values),
            }),
        },
        modalMaxWidth: 'sm',
        modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
        labelPrefix: LABEL_PREFIX,
    };
}
