import React from 'react';
import clsx from 'clsx';
import { SIZES } from 'config/styling/sizes';
import { makeStyles, mixins } from 'views/styling';

interface IPublicProps extends IStyleProps {
    className?: string;
    children: React.ReactNode;
}

interface IStyleProps {
    maxWidth?: number;
}

const useStyles = makeStyles((/* theme */) => ({
    CenteredDetailWrapper: {
        ...mixins.flexColTopCenter(),
        ...mixins.widthMax(),

        '& .__centeredDetailContent': {
            ...mixins.widthMax(),
            maxWidth: ({ maxWidth }: IStyleProps) => maxWidth,
        },
    },
}));

export default function CenteredDetail({
    className,
    maxWidth = SIZES.DETAIL_FORM.MAX_WIDTH,
    children,
}: IPublicProps) {
    const classes = useStyles({ maxWidth });

    return (
        <div className={classes.CenteredDetailWrapper}>
            <div className={clsx('__centeredDetailContent', className)}>
                {children}
            </div>
        </div>
    );
}
