import { IRoute } from 'models/route.models';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { triggerFetchMgmtReportsGeneric } from 'state/entities/mgmtReporting/triggerFetchMgmtReportsGeneric';
import { ROUTE_KEY } from '../routeKeys';
import ManagementReporting from './ManagementReporting';

export const MGMT_REPORTING_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_MGMT_REPORTING,
    path: '/management-reporting/:mgmtReportingTab',
    exact: true,
    component: ManagementReporting,
    isEnabled: ({ tenantSettings }) => tenantSettings?.managementReporting?.isEnabled,
    // requiredPermissions: [OPERATION_PERMISSION_KEY.xxx], // TODO
    breadcrumb: {
        translationKey: 'mgmt_reporting.breadcrumb',
        translationPlaceholdersSelector: ({ pathParams, state, translator }) => {
            const tabKey = (pathParams && pathParams.mgmtReportingTab)
                || getCurrentRouteParam(state, 'mgmtReportingTab');
            return {
                mgmtReportingTab: translator(`mgmt_reporting.tabs.${tabKey}.breadcrumb_part`),
            };
        },
    },
    executeOnRoute: [{
        execute: triggerFetchMgmtReportsGeneric,
        executeInputSelector: ({ routeLocation }) => ({
            mgmtReportingTab: routeLocation.params.mgmtReportingTab,
        }),
    }],
}];
