import isSet from '@snipsonian/core/cjs/is/isSet';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import {
    TEnhancedPortfoliosData,
    TFetchEnhancedPortfoliosClientApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { IFetchPortfoliosApiInput } from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { getEntitiesManager } from '../entitiesManager';

export const portfolioGroupPortfoliosEntity = getEntitiesManager().registerEntity<TEnhancedPortfoliosData>({
    asyncEntityKey: AsyncEntityKeys.portfolioGroupPortfolios,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_GROUP_PORTFOLIOS],
    includeUpdaters: true,
});

export function triggerFetchPortfolioGroupPortfolios(apiInput: IFetchPortfoliosApiInput = {}) {
    return portfolioGroupPortfoliosEntity.async.fetch<TFetchEnhancedPortfoliosClientApiInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolios,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars({
            pageKey: UiPageKey.portfolioGroupPortfolios,
            state,
            apiInput: {
                ...apiInput,
                limit: 100, // Fetch all portfolios for a portfoliogroup
            },
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: portfolioGroupPortfoliosEntity.select().data,
        }),
        refreshMode: 'always',
        resetDataOnTriggerMode: () => {
            if (apiInput && isSet(apiInput.pageNr)) {
                return false;
            }
            return true;
        },
    });
}
