import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IAdvancedPublicInstrumentsFilters,
    IFetchPublicInstrumentsApiInput,
    TPublicInstrument,
    TPublicInstrumentsData,
} from '@console/core-api/models/thematicSearch/publicInstrument.models';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import ListPageForApiEntity, { IListPageForApiEntityProps } from 'views/common/list/ListPageForApiEntity';
import {
    publicInstrumentsEntity,
    triggerFetchPublicInstruments,
} from 'state/entities/thematicSearch/publicInstruments';
import { UiPageKey } from 'models/state/ui.models';
import DataTable from 'views/common/list/DataTable';
import InputGroup from 'views/common/inputs/base/InputGroup';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { IRenderAdvancedFiltersProps } from 'views/common/list/DataSearch';
import InputTextField, { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import { TApiEntityId } from '@console/core-api/models/api.models';

const LIST_TRANSLATION_PREFIX = 'thematic_search.public_instruments.list';
const FILTERS_TRANSLATION_PREFIX = `${LIST_TRANSLATION_PREFIX}.filters`;
export const DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX = `${LIST_TRANSLATION_PREFIX}.columns`;

// eslint-disable-next-line max-len
interface IPublicProps<ColValues extends IColValues, ExtraPublicInstrumentItemData extends TObjectWithProps = unknown> extends Pick<IListPageForApiEntityProps<IFetchPublicInstrumentsApiInput, IAdvancedPublicInstrumentsFilters, TPublicInstrumentsData>, 'className' | 'create' | 'actions' | 'box' | 'includeRefreshButton'> {
    overrideEntity?: Partial<IOverrideEntity>;
    overrideUiVars?: IOverrideUiVars;
    overrideCols?: IOverrideCols<ColValues, ExtraPublicInstrumentItemData>;
    onPublicInstrumentRowClicked?: (publicInstrumentId: TApiEntityId) => void;
}

// eslint-disable-next-line max-len
interface IOverrideEntity extends Pick<IListPageForApiEntityProps<IFetchPublicInstrumentsApiInput, IAdvancedPublicInstrumentsFilters, TPublicInstrumentsData>, 'asyncListEntity' | 'asyncListEntityFetchTrigger' | 'setStateOnPageNrChange'> {
    dataNotification: StateChangeNotification; // default PUBLIC_INSTRUMENTS_DATA
}

// eslint-disable-next-line max-len
interface IOverrideUiVars extends Pick<IListPageForApiEntityProps<IFetchPublicInstrumentsApiInput, IAdvancedPublicInstrumentsFilters, TPublicInstrumentsData>, 'uiPageKey'> {
    uiVarsNotification: StateChangeNotification; // default PUBLIC_INSTRUMENTS_UI_VARS
}

interface IOverrideCols<ColValues extends IColValues, ExtraPublicInstrumentItemData extends TObjectWithProps> {
    cols: TDataColumns<ColValues, ExtraPublicInstrumentItemData>;
    toPublicInstrumentListItem: TToPublicInstrumentListItem<ColValues, ExtraPublicInstrumentItemData>;
}

// eslint-disable-next-line max-len
type TToPublicInstrumentListItem<ColValues extends IColValues, ExtraPublicInstrumentItemData extends TObjectWithProps> =
    (props: IToPublicInstrumentListItemProps) => IDataItem<ColValues, ExtraPublicInstrumentItemData>;

interface IToPublicInstrumentListItemProps {
    publicInstrument: TPublicInstrument;
    defaultPublicInstrumentCols: IDefaultPublicInstrumentCols;
}

function toDefaultPublicInstrumentListItem({
    publicInstrument,
    defaultPublicInstrumentCols,
}: IToPublicInstrumentListItemProps): IDataItem<IDefaultPublicInstrumentCols> {
    return {
        id: publicInstrument.id,
        colValues: defaultPublicInstrumentCols,
    };
}

export interface IDefaultPublicInstrumentCols extends IColValues {
    name: string;
    type: string;
    tags: string;
    externalId: string;
    updated: string;
}

interface IDesiredCol {
    colName: keyof IDefaultPublicInstrumentCols;
    percentWidth: number;
}

const DEFAULT_DESIRED_PUBLIC_INSTRUMENT_COLS: IDesiredCol[] = [
    { colName: 'externalId', percentWidth: 10 },
    { colName: 'name', percentWidth: 40 },
    { colName: 'tags', percentWidth: 15 },
    { colName: 'type', percentWidth: 15 },
    { colName: 'updated', percentWidth: 20 },
];

export function getDefaultPublicInstrumentCols({
    desiredCols = DEFAULT_DESIRED_PUBLIC_INSTRUMENT_COLS,
}: {
    desiredCols?: IDesiredCol[];
} = {}): TDataColumns<Partial<IDefaultPublicInstrumentCols>> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'externalId': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX}.external_id`,
                            },
                            data: {
                                className: UtilityClass.table.cellBold,
                            },
                            align: 'right',
                            percentWidth,
                        };
                        break;
                    }
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX}.name`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'tags': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX}.tags`,
                            },
                            percentWidth: 15,
                        };
                        break;
                    }
                    case 'type': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX}.type`,
                            },
                            data: {
                                render: ({ item }) => {
                                    if (!isSet(item.colValues.type)) {
                                        return null;
                                    }

                                    return (
                                        <Translate
                                            // eslint-disable-next-line max-len
                                            msg={`thematic_search.public_instruments.types.${item.colValues.type.toLowerCase()}`}
                                        />
                                    );
                                },
                            },
                            percentWidth: 15,
                        };
                        break;
                    }
                    case 'updated': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX}.updated`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected col name '${colName}'`);
                }
            }

            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultPublicInstrumentCols>>,
    );
}

const INITIAL_ADVANCED_PUBLIC_INSTRUMENTS_FILTERS: IAdvancedPublicInstrumentsFilters = {
    name: '',
    externalId: '',
};

export default function GenericPublicInstrumentsList<
    ColValues extends IColValues = IDefaultPublicInstrumentCols,
    ExtraPublicInstrumentItemData extends TObjectWithProps = unknown,
>({
    overrideCols,
    overrideEntity = {},
    overrideUiVars,
    onPublicInstrumentRowClicked,
    ...otherListPageProps
}: IPublicProps<ColValues, ExtraPublicInstrumentItemData>) {
    const entityConfig: IOverrideEntity = {
        dataNotification: StateChangeNotification.PUBLIC_INSTRUMENTS_DATA,
        asyncListEntity: publicInstrumentsEntity,
        asyncListEntityFetchTrigger: triggerFetchPublicInstruments,
        setStateOnPageNrChange: (pageNr) => ({
            toState: (draftState) => {
                // eslint-disable-next-line no-param-reassign
                draftState.entities.publicInstruments.data.pageNr = pageNr;
            },
            notificationsToTrigger: [StateChangeNotification.PUBLIC_INSTRUMENTS_DATA],
        }),
        ...overrideEntity,
    };
    const uiVarsConfig: IOverrideUiVars = overrideUiVars || {
        uiVarsNotification: StateChangeNotification.PUBLIC_INSTRUMENTS_UI_VARS,
        uiPageKey: UiPageKey.publicInstrumentsList,
    };

    const colsConfig: IOverrideCols<ColValues, ExtraPublicInstrumentItemData> = overrideCols || {
        cols: getDefaultPublicInstrumentCols() as TDataColumns<ColValues>,
        // eslint-disable-next-line max-len
        toPublicInstrumentListItem: toDefaultPublicInstrumentListItem as unknown as TToPublicInstrumentListItem<ColValues, ExtraPublicInstrumentItemData>,
    };

    return (
        <ListPageForApiEntity
            notifications={[entityConfig.dataNotification, uiVarsConfig.uiVarsNotification]}

            asyncListEntity={entityConfig.asyncListEntity}
            asyncListEntityFetchTrigger={entityConfig.asyncListEntityFetchTrigger}
            setStateOnPageNrChange={entityConfig.setStateOnPageNrChange}

            uiPageKey={uiVarsConfig.uiPageKey}
            notificationToTriggerOnUiVarChanges={uiVarsConfig.uiVarsNotification}

            search={{
                simple: {
                    tipTranslationKey: 'portfolio_mgmt.policies.list.filter.simple.tip',
                    mapSimpleSearchInputToFetchFilter,
                },
                advanced: {
                    initialValues: INITIAL_ADVANCED_PUBLIC_INSTRUMENTS_FILTERS,
                    renderFilters: renderAdvancedPublicInstrumentsFilters,
                },
            }}

            list={{
                renderData: renderPublicInstrumentsList,
            }}

            {...otherListPageProps}
        />
    );

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            externalId: simpleInput,
        };
    }

    function renderAdvancedPublicInstrumentsFilters({
        filterValues,
        onChangeFilterValue,
    }: IRenderAdvancedFiltersProps<IAdvancedPublicInstrumentsFilters>) {
        return (
            <InputGroup>
                <InputWrapper
                    label={`${FILTERS_TRANSLATION_PREFIX}.advanced.name.label`}
                >
                    <InputTextField
                        value={filterValues.name}
                        onChange={changeInputHandler('name')}
                        placeholder={`${FILTERS_TRANSLATION_PREFIX}.advanced.name.tip`}
                    />
                </InputWrapper>
                <InputWrapper
                    label={`${FILTERS_TRANSLATION_PREFIX}.advanced.external_id.label`}
                >
                    <InputTextField
                        value={filterValues.externalId}
                        onChange={changeInputHandler('externalId')}
                        placeholder={`${FILTERS_TRANSLATION_PREFIX}.advanced.external_id.tip`}
                    />
                </InputWrapper>
            </InputGroup>
        );

        function changeInputHandler(fieldName: string) {
            return ({ value }: IOnChangeTextInputProps) => onChangeFilterValue({
                [fieldName]: value,
            });
        }
    }

    function renderPublicInstrumentsList({ data }: { data: TPublicInstrument[] }) {
        if (!data) {
            return null;
        }

        const publicInstrumentItems: IDataItem<ColValues, ExtraPublicInstrumentItemData>[] =
            data.map((publicInstrument) =>
                colsConfig.toPublicInstrumentListItem({
                    publicInstrument,
                    defaultPublicInstrumentCols: {
                        name: publicInstrument.name,
                        type: publicInstrument.type,
                        tags: publicInstrument.tags.join(', '),
                        externalId: publicInstrument.external_id,
                        updated: formatDateRelativeToNow({ date: publicInstrument.version_datetime }),
                    },
                }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={publicInstrumentItems}
                onItemRowClicked={onPublicInstrumentRowClicked
                    ? (publicInstrument) => onPublicInstrumentRowClicked(publicInstrument.id)
                    : null
                }
            />
        );
    }
}
