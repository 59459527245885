import listenForTranslationKeyToggles
    from '@snipsonian/react/cjs/components/i18n/translator/listenForTranslationKeyToggles';
import { toggleTranslationKeys } from 'state/i18n/actions';
import { isAuthenticatedUser } from 'state/auth/selectors';
import { refreshAuthenticatedUserPermissions } from 'state/auth/actions';
import { refreshTenantSettings } from 'state/appConfig/actions';
import { getCurrentRouteKey } from 'state/ui/selectors';
import { ROUTE_KEY } from 'views/routeKeys';
import { getStore } from '../index';

/**
 * Put here those functionalities that are to be initiated on load of the app (which can be both
 * the initial load as after a page refresh).
 */
export default function initOnLoad() {
    const { getState, dispatch } = getStore();

    fetchUserRelatedDataIfAuthenticated();

    listenForTranslationKeyToggles({
        onToggle: () => dispatch(toggleTranslationKeys()),
    });

    function fetchUserRelatedDataIfAuthenticated() {
        /**
         * We don't want to fetch the user permissions when on the login page, because otherwise this also gets
         * triggered on login-success-callback-from-adb2c, but at that time the access token is not updated yet.
         */
        if ((getCurrentRouteKey(getState()) !== ROUTE_KEY.R_LOGIN) && isAuthenticatedUser(getState())) {
            dispatch(refreshAuthenticatedUserPermissions());
            dispatch(refreshTenantSettings());
        }
    }
}
