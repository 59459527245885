export function getBaseSiteUrl(): string {
    const port = window.location.port && window.location.port.length > 0
        ? `:${window.location.port}`
        : '';

    return `${window.location.protocol}//${window.location.hostname}${port}`;
}

export function getUrlQueryPart(): string {
    const parts = window.location.href.split('?');
    return parts && parts.length > 1 ? parts[1] : '';
}
