import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import { ISinglePortfolioApiInput } from '../../models/portfolioMgmt/portfolio.models';
import { IPortfolioAllocation } from '../../models/portfolioMgmt/portfolioAllocation.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { get } from '../coreApiRequestWrapper';

export function fetchPortfolioAllocation({
    portfolioId,
    underlyingApiRequestConfig,
}: ISinglePortfolioApiInput & IUnderlyingRequestApiInput) {
    return get<IPortfolioAllocation, Omit<IPortfolioAllocation, 'portfolioId'>>({
        url: CoreApiPath.PORTFOLIO_LOOK_THROUGH,
        pathParams: {
            portfolioId,
        },
        mapResponse: ({ data }) => ({
            portfolioId,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}
