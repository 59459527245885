import React from 'react';
import { IDataItem } from 'models/list.models';
import { DEFAULT_PORTFOLIO_TAB_KEY } from 'config/tabs.config';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import GenericPortfoliosList, { IDefaultPortfolioCols } from './GenericPortfoliosList';

export default function PortfoliosList() {
    return (
        <GenericPortfoliosList
            box={{
                title: 'portfolio_mgmt.portfolios.title',
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_PORTFOLIO_ADD,
                },
                tooltip: 'portfolio_mgmt.portfolios.list.actions.create_portfolio',
            }}
            onPortfolioRowClicked={openPortfolioDetail}
            enableStorytellerBulkReportGeneration
        />
    );

    function openPortfolioDetail({ id }: IDataItem<IDefaultPortfolioCols>) {
        redirectTo({
            routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
            params: {
                portfolioId: id,
                portfolioTab: DEFAULT_PORTFOLIO_TAB_KEY,
            },
        });
    }
}
