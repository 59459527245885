import React from 'react';
import ContentBox from 'views/common/layout/ContentBox';

export default function Releases() {
    return (
        <ContentBox titleLabel="documentation.releases.title">
            Under construction
        </ContentBox>
    );
}
