import { IApiEntityVersionsComparisonChangesResponse } from '../models/apiEntityVersions.models';

export const CORE_ENTITY_FIELD_CHAIN_DELIMITER = '.';

type TShouldIgnoreCoreEntityField = (props: {
    entityType: string;
    fieldKey: string; // e.g. root['version_datetime']
    fieldNameChain: string[]; // the full chain (not just the part matching with the ignore map)
    allChanges: IApiEntityVersionsComparisonChangesResponse;
    allFieldKeys: { [fieldKey: string]: true };
}) => boolean;

/**
 * Use dots (.) as delimiter for generating a field chain. See CORE_ENTITY_FIELD_CHAIN_DELIMITER !
 * For example:
 *   a nested portfolio field like 'brokerage_account' > 'bank_account_number'
 *   should be noted as
 *   'brokerage_account.bank_account_number'
 */
// eslint-disable-next-line max-len
export const CORE_ENTITY_FIELD_CHAINS_TO_IGNORE_FOR_VERSION_DIFF: Record<string, true | TShouldIgnoreCoreEntityField> = {
    // --- for all entities ---
    version: true,
    version_authored_by_user_id: true,
    version_datetime: true,
    // --- for policies ---
    // (only show 'coerced_config' as that will always be there when at least in one of the 3 configs there is a change)
    config: ({ entityType, fieldKey, allFieldKeys }) => {
        if (entityType !== 'POLICY') {
            // the config of e.g. type PORTFOLIO not to be ignored !!!
            return false;
        }

        /* as a rule, we don't show config changes within a policy,
           EXCEPT when there is no equivalent change within coerced_config */
        const equivalentCoercedConfigFieldKey = fieldKey.replace("['config']", "['coerced_config']");

        if (!allFieldKeys[equivalentCoercedConfigFieldKey]) {
            return false;
        }

        return true;
    },
    parent_coerced_config: true,
};
