import React from 'react';
import AddPage from 'views/common/add/AddPage';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { userDetailsEntity } from 'state/entities/userMgmt/userDetails';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { IEntityWrapperProps } from 'views/common/widget/EntityWrapper';
import { TI18nLabelOrString } from 'models/general.models';
import GenericUserAddPropertiesForm, { IUserAddFormValues } from 'views/userMgmt/shared/GenericUserAddPropertiesForm';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';

interface IGenericUserAddProps {
    overrideListRouteKey?: ROUTE_KEY;
    overrideTitleLabel?: TI18nLabelOrString;
    overrideEntity?: Partial<IOverrideEntity>;
    onSubmit(props: IOnSubmitProps<IUserAddFormValues>): Promise<unknown>;
}

interface IOverrideEntity extends Pick<IEntityWrapperProps, 'asyncEntitySelector'> {
    notifications: StateChangeNotification[];
}

export default function GenericUserAdd({
    overrideListRouteKey,
    overrideEntity = {},
    onSubmit,
    overrideTitleLabel,
}: IGenericUserAddProps) {
    const initialValues: IUserAddFormValues = {
        externalId: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        brokerageId: '',
        featureFlags: [],
        language: null,
        /* shouldUserBeLinkedToIdp is default true so that the email is required
           even when this boolean toggle is not shown (when 'allowUsersWithoutIdpLink' tenant config is false) */
        shouldUserBeLinkedToIdp: true,
        email: '',
        bankId: null,
        bankIdType: undefined,
        bankAccountNumberType: undefined,
        bankAccountNumber: null,
    };

    return (
        <AddPage
            titleLabel={overrideTitleLabel || 'user_mgmt.users.add.title'}
            entity={{
                notifications: [StateChangeNotification.USER_DETAILS_DATA],
                asyncEntitySelector: userDetailsEntity.select,
                ...overrideEntity,
            }}
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <GenericUserAddPropertiesForm
                initialValues={initialValues}
                submit={{
                    onSubmit,
                }}
            />
        </AddPage>
    );

    function redirectToList() {
        redirectTo({
            routeKey: overrideListRouteKey || ROUTE_KEY.R_USERS_LIST,
        });
    }
}
