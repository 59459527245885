import {
    IApiEntityListResponse, IBaseSingleEntityApiInput, IDefaultUserLocale,
} from '../../models/api.models';
import {
    IFetchPublicInstrumentGroupsApiInput,
    IPublicInstrumentGroupEntityData,
    ISinglePublicInstrumentGroupApiInput,
    TPublicInstrumentGroup,
    TPublicInstrumentGroupsData,
    TTPublicInstrumentGroupCreate,
    TTPublicInstrumentGroupPatch,
} from '../../models/thematicSearch/publicInstrumentGroup.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchPublicInstrumentGroups({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    name,
    externalId,
    category,
    defaultUserLocale,
    count,
}: IFetchPublicInstrumentGroupsApiInput & IDefaultUserLocale) {
    return get<TPublicInstrumentGroupsData, IApiEntityListResponse<IPublicInstrumentGroupEntityData>>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_GROUPS,
        queryParams: {
            offset,
            limit,
            ...fetchApiEntityUrlParamBuilder()
                .contains({ field: `name.${defaultUserLocale}`, value: name })
                .contains({ field: `category.${defaultUserLocale}`, value: category })
                .contains({ field: 'external_id', value: externalId })
                .build(),
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function patchPublicInstrumentGroupDetails({
    id,
    ...patchFields
}: TTPublicInstrumentGroupPatch) {
    return patch<TPublicInstrumentGroup>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_GROUP_DETAILS,
        pathParams: {
            instrumentGroupId: id,
        },
        body: patchFields,
    });
}

export function createPublicInstrumentGroup(
    publicInstrumentGroupToCreate: TTPublicInstrumentGroupCreate,
) {
    return post<TPublicInstrumentGroup>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_GROUPS,
        body: publicInstrumentGroupToCreate,
    });
}

export function deletePublicInstrumentGroup({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_GROUP_DETAILS,
        pathParams: {
            instrumentGroupId: id,
        },
    });
}

export function fetchPublicInstrumentGroupDetails({
    publicInstrumentGroupId,
}: ISinglePublicInstrumentGroupApiInput) {
    return get<TPublicInstrumentGroup>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_GROUP_DETAILS,
        pathParams: {
            instrumentGroupId: publicInstrumentGroupId,
        },
    });
}
