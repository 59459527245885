import isSet from '@snipsonian/core/cjs/is/isSet';

export const ENVIRONMENT = window.CONSOLE_CONFIG.env;

export const IS_LOCALHOST_ENV = ENVIRONMENT === null;

export const INSTANCE = determineInstance();

function determineInstance(): string {
    const { instance } = window.CONSOLE_CONFIG;

    if (isSet(instance)) {
        return instance;
    }

    return ''; /* instance not known */
}
