import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetObject from '@snipsonian/core/cjs/object/verification/isSetObject';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IPolicyChain } from '@console/bff/models/policies/policyChain.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS } from 'config/styling/colors';
import { POLICY_TAB_KEY } from 'config/tabs.config';
import { policyDetailsEntity } from 'state/entities/portfolioMgmt/policyDetails';
import { policyParentChainEntity } from 'state/entities/portfolioMgmt/policyParentChain';
import Spinner from 'views/common/loading/Spinner';
import Breadcrumbs, { IBreadcrumb } from 'views/common/widget/Breadcrumbs';
import { observe } from 'views/observe';
import { ROUTE_KEY } from 'views/routeKeys';
import { makeStyles, mixins } from 'views/styling';

const useStyles = makeStyles((theme) => ({
    PolicyParentChain: {
        paddingLeft: theme.spacing(3),
        ...mixins.flexRowCenterLeft(),

        '& .__prefix': {
            paddingRight: theme.spacing(1),
            ...mixins.typoBold({ color: APP_COLORS.TEXT['500'], size: 10 }),
            textTransform: 'uppercase',
        },
    },
}));

function PolicyParentChain() {
    const classes = useStyles();

    const policyDetails = policyDetailsEntity.select()?.data;
    const policyChain = policyParentChainEntity.select()?.data;
    const policyChainFetchStatus = policyParentChainEntity.select()?.fetch?.status;

    if (!isSet(policyDetails?.parent_policy_id) || policyChainFetchStatus === AsyncStatus.Error) {
        return null;
    }

    if (policyChainFetchStatus === AsyncStatus.Initial || policyChainFetchStatus === AsyncStatus.Busy) {
        return (
            <div className={classes.PolicyParentChain}>
                <Spinner size="S" />
            </div>
        );
    }

    const currentPolicyBreadcrumb: IBreadcrumb = {
        label: {
            text: policyDetails?.name,
            shouldTranslate: false,
        },
        shouldHighlightBreadcrumb: true,
    };
    const policyChainBreadcrumbs = extractPolicyChainToBreadcrumbArray({ policyChain })
        .concat([currentPolicyBreadcrumb]);

    return (
        <div className={classes.PolicyParentChain}>
            <div className="__prefix">
                <Translate msg="portfolio_mgmt.policies.detail.policy_parent_chain_prefix" />
            </div>
            <Breadcrumbs
                breadcrumbs={policyChainBreadcrumbs}
                noPadding
            />
        </div>
    );
}

function extractPolicyChainToBreadcrumbArray({
    policyChain,
    policyChainBreadcrumbs,
}: {
    policyChain: IPolicyChain;
    policyChainBreadcrumbs?: IBreadcrumb[];
}): IBreadcrumb[] {
    if (isSetObject(policyChain.parent)) {
        return extractPolicyChainToBreadcrumbArray({
            policyChain: policyChain.parent,
            policyChainBreadcrumbs,
        }).concat([mapPolicyDataToBreadcrumb(policyChain)]);
    }

    return [mapPolicyDataToBreadcrumb(policyChain)];
}

function mapPolicyDataToBreadcrumb({ name, id }: IPolicyChain): IBreadcrumb {
    return {
        label: {
            text: name,
            shouldTranslate: false,
        },
        route: {
            routeKey: ROUTE_KEY.R_POLICY_DETAIL,
            params: {
                policyId: id,
                policyTab: POLICY_TAB_KEY.CONFIGURATION,
            },
        },
    };
}

export default observe(
    [StateChangeNotification.POLICY_PARENT_CHAIN_DATA, StateChangeNotification.POLICY_DETAILS_DATA],
    PolicyParentChain,
);
