import React from 'react';
import { ROUTE_KEY } from 'views/routeKeys';
import GenericUserPortfolioReports from 'views/userMgmt/shared/GenericUserPortfolioReports';
import { CLIENT_TAB_KEY } from 'config/tabs.config';

export default function ClientPortfolioReports() {
    return (
        <GenericUserPortfolioReports
            overrideNoResults={{
                translationPrefix: 'client_mgmt.detail.portfolio_reports.list.no_results',
                userPortfoliosRouteKey: ROUTE_KEY.R_CLIENT_DETAIL_PORTFOLIOS,
                userPortfoliosTabKey: CLIENT_TAB_KEY.PORTFOLIOS,
            }}
        />
    );
}
