import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import {
    IApiEntityListResponse, IBaseSingleEntityApiInput,
} from '../../models/api.models';
import {
    IPolicyEntityData,
    TPoliciesData,
    IFetchPoliciesApiInput,
    TPolicy,
    ISinglePolicyApiInput,
    TPolicyPatch,
    IFetchPolicyPerformanceFutureApiInput,
    IPolicyPerformanceFuture,
    TPolicyCreate,
} from '../../models/portfolioMgmt/policy.models';
import {
    DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR,
    DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
} from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchPolicies({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    externalId,
    policyName,
    ids,
    riskProfileId,
    algorithmTypes,
    currencies,
    tags,
    orderBy,
    count,
    underlyingApiRequestConfig,
}: IFetchPoliciesApiInput & IUnderlyingRequestApiInput = {}) {
    return get<TPoliciesData, IApiEntityListResponse<IPolicyEntityData>>({
        url: CoreApiPath.POLICIES,
        queryParams: {
            offset,
            limit,
            ...fetchApiEntityUrlParamBuilder()
                .exactMatch({ field: 'external_id', value: externalId })
                .contains({ field: 'name', value: policyName })
                .exactMatch({ field: 'risk_profile_id', value: riskProfileId })
                .arrayContains({ field: 'tags', value: tags })
                .fieldIn({ field: 'id', value: ids })
                .fieldIn({ field: 'config.algorithm_settings.execution_settings.base_currency', value: currencies })
                .fieldIn({ field: 'config.algorithm', value: algorithmTypes })
                .orderBy(orderBy)
                .build(),
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}

export function fetchPolicyDetails({
    policyId,
    underlyingApiRequestConfig,
}: ISinglePolicyApiInput & IUnderlyingRequestApiInput) {
    return get<TPolicy>({
        url: CoreApiPath.POLICY_DETAILS,
        pathParams: {
            policyId,
        },
        ...underlyingApiRequestConfig,
    });
}

export function deletePolicy({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.POLICY_DETAILS,
        pathParams: {
            policyId: id,
        },
    });
}

export function createPolicy({
    underlyingApiRequestConfig,
    ...policyToCreate
}: TPolicyCreate & IUnderlyingRequestApiInput) {
    return post<TPolicy>({
        url: CoreApiPath.POLICIES,
        body: policyToCreate,
        ...underlyingApiRequestConfig,
    });
}

export function patchPolicy({
    id,
    underlyingApiRequestConfig,
    ...patchFields
}: TPolicyPatch & IUnderlyingRequestApiInput) {
    return patch<TPolicy>({
        url: CoreApiPath.POLICY_DETAILS,
        pathParams: {
            policyId: id,
        },
        body: patchFields,
        ...underlyingApiRequestConfig,
    });
}

export function fetchPolicyPerformanceFuture({
    policyId,
    useExpectedReturns = DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
    ...other
}: IFetchPolicyPerformanceFutureApiInput) {
    return post<IPolicyPerformanceFuture, Omit<IPolicyPerformanceFuture, 'policyId'>>({
        url: CoreApiPath.POLICY_PERFORMANCE_FUTURE,
        pathParams: {
            policyId,
        },
        body: {
            method: 'PORTFOLIO_FUTURE_PERFORMANCE',
            method_settings: {
                start_date: other.startDate,
                end_date: other.endDate,
                start_amount: other.startAmount,
                recurring_deposit_amount: other.recurringDepositAmount,
                recurring_deposit_frequency: other.recurringDepositFrequency,
                quantiles: other.quantiles,
                sample_frequency: other.sampleFrequency,
                use_expected_returns: useExpectedReturns,
            },
        },
        mapResponse: ({ data }) => ({
            policyId,
            ...data,
        }),
    });
}
