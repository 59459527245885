import React, { ReactElement, MouseEvent } from 'react';
import clsx from 'clsx';
import { IIconButtonProps } from 'views/common/buttons/types';
import IconButton from 'views/common/buttons/IconButton';
import { ArrowUpIcon, ArrowDownIcon } from 'views/common/icons';

interface IPublicProps extends Pick<IIconButtonProps, 'id' | 'className' | 'disabled'> {
    isCollapsed: boolean;
    onCollapseChange: (isCollapsed: boolean) => void;
    isCollapsedIcon?: ReactElement;
    isNotCollapsedIcon?: ReactElement;
}

export default function IconButtonCollapse({
    isCollapsed,
    onCollapseChange,
    className,
    isCollapsedIcon,
    isNotCollapsedIcon,
    ...otherIconButtonProps
}: IPublicProps) {
    return (
        <IconButton
            {...otherIconButtonProps}
            className={clsx(
                'IconButton--Collapse',
                isCollapsed ? 'IconButton--Collapsed' : 'IconButton--Not-Collapsed',
                className,
            )}
            variant="bare"
            color="text"
            size="S"
            isCircle={false}
            onClick={onClick}
            icon={isCollapsed
                ? isCollapsedIcon || <ArrowDownIcon />
                : isNotCollapsedIcon || <ArrowUpIcon />
            }
        />
    );

    function onClick(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        onCollapseChange(!isCollapsed);
    }
}
