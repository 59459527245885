import { IRoute } from 'models/route.models';
import { ROUTE_KEY } from '../routeKeys';
import Login from './Login';

export const AUTH_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_LOGIN,
    path: '/login',
    allowAnonymousAccess: true,
    component: Login,
    appShell: false,
    breadcrumb: null,
}];
