import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isString from '@snipsonian/core/cjs/is/isString';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { TLabel } from 'models/general.models';
import {
    IColValues,
    IDataItem,
    IRenderDataItemSubRowProps,
    IRenderItemCellProps,
    TDataColumns,
} from 'models/list.models';
import { INavigateToRoute } from 'models/routing.models';
import { IApiMultiTranslationsLabel, TAsyncValueMapper } from '@console/core-api/models/api.models';
import { APP_COLORS } from 'config/styling/colors';
import { NO_DATA_CHARACTER } from 'config/styling/typography';
import {
    getUserLocalesSortedDefaultFirstMemoized,
    isMultipleUserLocales,
} from 'state/appConfig/selectors';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import useAsyncFetch from 'utils/react/hooks/useAsyncFetch';
import { copyToClipboard } from 'utils/dom/copyToClipboard';
import { isMultiTranslationsLabelTypeGuard } from 'utils/entities/multiTranslationsLabelUtils';
import { getI18nLabelMsg, isI18nLabelOrString, isUntranslatableLabelTypeGuard } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins, UtilityClass } from 'views/styling';
import ContentTitle, { IContentTitleProps } from 'views/common/layout/ContentTitle';
import { prependLabelWithPrefix } from 'views/common/inputs/extended/ExtendedInputWrapper';
import DataTable from 'views/common/list/DataTable';
import IconButton from 'views/common/buttons/IconButton';
import { ArrowRightIcon, CopyIcon, EditFullIcon } from 'views/common/icons';
import { redirectTo } from 'views/routes';
import Spinner from 'views/common/loading/Spinner';
import { IInfoIconTooltipProps, TShowUntilDismissed } from 'views/common/widget/InfoIconTooltip.models';
import CondensedValue from 'views/common/widget/CondensedValue';
import Text from 'views/common/widget/Text';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import { TTooltipParams } from 'views/common/widget/Tooltip';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';
import IconButtonEdit from '../buttons/IconButtonEdit';
import MultiTranslationsLabelLocaleTags from './multiTranslationsLabel/MultiTranslationsLabelLocaleTags';
import MultiTranslationsLabelLocaleTitle from './multiTranslationsLabel/MultiTranslationsLabelLocaleTitle';
import TextButton from '../buttons/TextButton';
import { IAskConfirmationConfig } from '../buttons/types';

export interface IDetailFieldsListProps<FieldKey extends string = string> {
    /* the 'content title variant' is - if not provided - by default 'section' */
    title?: Omit<IContentTitleProps, 'infoTooltip'> & {
        shouldPrefixLabel?: boolean; // default true - only used when 'labelPrefix' is provided
        tooltip?: TLabel;
        shouldPrefixTooltip?: boolean; // default true - only used when 'labelPrefix' is provided
        showTooltipUntilDismissed?: TShowUntilDismissed; // default false
    };
    fields: IDetailField<FieldKey>[];
    labelPrefix?: string;
    /**
     * 'onEditField' will be called with the fieldKey depending on which field's pencil icon was clicked.
     * Special case: when the user clicked the 'global edit icon', then 'onEditField' is not called, but 'onEditGlobal'.
     */
    onEditField?: (onEditFieldProps: IOnEditFieldProps<FieldKey>) => void;
    /* An extra action will be shown when 'clearAll' is set AND when the title is set. */
    clearAll?: {
        label?: TLabel;
        onClear: () => void;
        confirmationModal?: IAskConfirmationConfig;
    };
    /** The 'global' pencil icon will only be shown when 'onEditGlobal' is set AND when the title is set. */
    onEditGlobal?: () => void;
    /* if true, then the pencil icons - if any was configured - won't be displayed */
    isReadOnly?: boolean; // default false
    className?: string;
}

export interface IDetailField<FieldKey extends string = string> {
    label: TLabel | IApiMultiTranslationsLabel;
    /**
     * when the value is a multi-translation label, then:
     * - initially (collapsed state) only the default language will be shown
     * - after un-collapsing also the other languages will be shown
     * - always an indication for each language if it has a value or not
     */
    value: TDetailFieldValue;
    customValueRender?: TCustomRenderer;
    customLabelRender?: TCustomRenderer;
    shouldTranslateValue?: boolean; // default false
    hideIfValueUnset?: boolean; // default false - if true, then the field will not be shown when no value
    hide?: boolean; // default false - if true, then the field will not be shown
    /* optional async mapper to map the value to another display value */
    asyncValueMapper?: TAsyncValueMapper;
    /* only provide 'edit' if the field is editable --> a clickable pencil icon will be shown next to it */
    edit?: {
        fieldKey: FieldKey;
    };
    /* only provide 'link' if next to the field an arrow icon is to be shown to navigate to another (entity) page  */
    link?: INavigateToRoute;
    /* 'overrulePrefixLabel' is only used when 'labelPrefix' is provided
     * - IF false : the label will not be prefixed
     * - IF string : this prefix will be used instead of the main labelPrefix */
    overrulePrefixLabel?: false | string;
    /** if true, then the copy icon shows, and functionality copy value is available */
    copyValue?: boolean;
    /** if true, value shows with first few characters and few last characters, after hover whole value is showing */
    condensedValue?: boolean;
    alignValueLeft?: boolean; // default false (= aligned to the right)
    valueInBold?: boolean; // default false
    tooltip?: Pick<TTooltipParams, 'label'>;
}

type TDetailFieldValue = string | IApiMultiTranslationsLabel;
type TCustomRenderer =
    (renderProps: IRenderItemCellProps<IDetailFieldsCols, IDetailFieldExtraData, unknown>) => React.ReactNode;

export interface IOnEditFieldProps<FieldKey extends string = string> {
    fieldKey: FieldKey;
}

const COLS: TDataColumns<IDetailFieldsCols, IDetailFieldExtraData> = {
    label: {
        label: EMPTY_COL_TITLE,
        data: {
            render: (renderProps) => {
                if (renderProps.item?.extra?.customLabelRender) {
                    return renderProps.item.extra.customLabelRender(renderProps);
                }
                if (renderProps.item.extra.tooltip) {
                    return (
                        <div className="__detailFieldLabel">
                            <Text label={renderProps.cellValue as TLabel} />
                            <InfoIconTooltip
                                name={`detail-field-tooltip_${renderProps.cellValue}`}
                                simpleContent={{
                                    info: renderProps.item.extra.tooltip.label,
                                }}
                            />
                        </div>
                    );
                }
                return (
                    <Text label={renderProps.cellValue as TLabel} />
                );
            },
            className: UtilityClass.table.cellBold,
        },
        percentWidth: 40,
    },
    value: {
        label: EMPTY_COL_TITLE,
        data: {
            render: (renderProps) => {
                if (renderProps.item?.extra?.customValueRender) {
                    return renderProps.item.extra.customValueRender(renderProps);
                }
                return (
                    <DetailFieldValueAndActions
                        value={renderProps.cellValue as string}
                        multiTranslationsLabel={renderProps.item.extra.multiTranslationsLabel}
                        asyncValueMapper={renderProps.item.extra.asyncValueMapper}
                        edit={renderProps.item.extra.edit}
                        link={renderProps.item.extra.link}
                        copyValue={renderProps.item.extra.copyValue}
                        condensedValue={renderProps.item.extra.condensedValue}
                        onEdit={renderProps.item.extra.onEdit}
                        shouldTranslateValue={renderProps.item.extra.shouldTranslateValue}
                        valueInBold={renderProps.item.extra.valueInBold}
                    />
                );
            },
        },
        align: 'right',
        percentWidth: 60,
    },
};

interface IDetailFieldsCols extends IColValues {
    label: TLabel;
    value: TDetailFieldValue;
}

interface IDetailFieldExtraData {
    link?: INavigateToRoute;
    edit?: {
        fieldKey: string;
    };
    copyValue?: boolean;
    condensedValue?: boolean;
    onEdit: () => void;
    asyncValueMapper?: TAsyncValueMapper;
    shouldTranslateValue?: boolean;
    isMultiTranslationsField: boolean;
    multiTranslationsLabel?: IApiMultiTranslationsLabel;
    alignValueLeft: boolean;
    valueInBold: boolean;
    customValueRender?: TCustomRenderer;
    customLabelRender?: TCustomRenderer;
    tooltip?: Pick<TTooltipParams, 'label'>;
}

const useStyles = makeStyles((theme) => ({
    DetailFieldsList: {
        ...mixins.widthMax(),

        '& .__header': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            paddingBottom: theme.spacing(0),

            '& .__title': {
                paddingBottom: theme.spacing(1),
            },

            '& .__actions': {
                ...mixins.flexRow({ alignCross: 'center' }),

                '& .__clearAll': {
                    paddingRight: theme.spacing(2),
                },
            },
        },

        '& .__tableRow': {
            height: 48,
        },

        '& .__detailFieldLabel': {
            ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'center' }),

            '& .__info-icon': {
                marginLeft: theme.spacing(1),
            },
        },
        '& .__detailFieldValue': {
            ...mixins.flexRow({ alignMain: 'flex-end', alignCross: 'center', wrap: 'wrap' }),
            wordBreak: 'break-word',
        },
        '& .__valueInBold': {
            ...mixins.typoBold(),
        },
        '& .__detailFieldAction': {
            paddingRight: 0,
            paddingLeft: theme.spacing(4),
        },

        '& .__asyncError': {
            color: APP_COLORS.FEEDBACK.ERROR,
        },

        '& .__copyIcon': {
            marginLeft: theme.spacing(1),
        },

        '& .__copyIcon svg': {
            height: 20,
        },

        '& .__tableRow.__multiTranslationsFieldRow.__firstLocale .__tableCell': {
            borderTop: 0,
        },
        '& .__tableRow.__multiTranslationsFieldRow .__localeTitle': {
            color: APP_COLORS.TEXT['400'],
        },
        '& .__tableRow.__multiTranslationsFieldRow .__localeValue': {
            ...mixins.flexRowCenterLeft(),
        },
        '& .__tableRow.__multiTranslationsFieldRow .__noTranslation': {
            color: APP_COLORS.TEXT['200'],
        },

        '& .__multiTranslationsLabelSeparator': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),

            '& svg': {
                marginTop: 4,
            },
        },
    },
}));

export default function DetailFieldsList<FieldKey extends string = string>({
    title,
    fields,
    labelPrefix,
    onEditField,
    onEditGlobal,
    isReadOnly = false,
    clearAll,
    className,
}: IDetailFieldsListProps<FieldKey>) {
    const classes = useStyles();

    const {
        variant: titleVariant = 'section',
        shouldPrefixLabel: shouldPrefixTitleLabel = true,
        label: titleLabelOrig,
        shouldPrefixTooltip: shouldPrefixTitleTooltip = true,
        tooltip: titleTooltipOrig,
        showTooltipUntilDismissed = false,
        ...otherTitleProps
    } = title || {};

    const titleLabel = title && prependLabelWithPrefix({
        label: titleLabelOrig,
        labelPrefix,
        shouldPrefixLabel: shouldPrefixTitleLabel,
    });
    const titleTooltip = title && prependLabelWithPrefix({
        label: titleTooltipOrig,
        labelPrefix,
        shouldPrefixLabel: shouldPrefixTitleTooltip,
    });
    const titleInfoTooltip: IInfoIconTooltipProps = titleTooltip
        ? {
            name: `detail-fields-tooltip_${getI18nLabelMsg(titleTooltip)}`,
            simpleContent: {
                // title: titleLabel, // no reason to again show the title within the tooltip
                info: titleTooltip,
            },
            showUntilDismissed: showTooltipUntilDismissed,
        }
        : null;

    const fieldItems: IDataItem<IDetailFieldsCols, IDetailFieldExtraData>[] = fields
        .filter((field) => {
            if (field.hide) {
                return false;
            }
            return isSet(field.value) || !field.hideIfValueUnset;
        })
        .map((field, index) => {
            const { edit, link, copyValue, condensedValue, asyncValueMapper } = field;

            const { fieldLabel, multiTranslationsLabel } = determineFieldLabel({ field });

            return {
                id: index.toString(),
                colValues: {
                    label: fieldLabel,
                    value: field.value,
                },
                extra: {
                    edit: !isReadOnly ? edit : undefined,
                    link,
                    copyValue,
                    condensedValue,
                    onEdit: () => onEditField && onEditField({ fieldKey: edit && edit.fieldKey }),
                    asyncValueMapper,
                    shouldTranslateValue: field.shouldTranslateValue,
                    isMultiTranslationsField: isMultiTranslationsLabelTypeGuard(field.value),
                    multiTranslationsLabel,
                    alignValueLeft: field.alignValueLeft,
                    valueInBold: field.valueInBold,
                    customValueRender: field.customValueRender,
                    customLabelRender: field.customLabelRender,
                    tooltip: field.tooltip,
                },
            };
        });

    return (
        <div className={clsx(classes.DetailFieldsList, className)}>
            {title && (
                <div className="__header">
                    <ContentTitle
                        {...otherTitleProps}
                        label={titleLabel}
                        variant={titleVariant}
                        infoTooltip={titleInfoTooltip}
                        className="__title"
                    />

                    <div className="__actions">
                        {clearAll && (
                            <div className="__clearAll">
                                <TextButton
                                    id="clear-all-fields"
                                    variant="bare"
                                    label={clearAll.label || 'common.action.clear_all'}
                                    onClick={clearAll.onClear}
                                    askConfirmation={clearAll?.confirmationModal}
                                    showLoaderWhenAsync={false}
                                />
                            </div>
                        )}

                        {onEditGlobal && (
                            <IconButtonEdit
                                id="to-edit-global"
                                tooltip={(!isReadOnly) ? 'common.action.edit' : 'common.action.edit_disabled'}
                                onClick={onGlobalEditIcon}
                                disabled={isReadOnly}
                            />
                        )}
                    </div>
                </div>
            )}

            <DataTable
                cols={COLS}
                items={fieldItems}
                noHeader
                subRow={{
                    isCollapsible: isMultipleUserLocales(),
                    isRowCollapsible: isMultiTranslationsField,
                    shouldAddExtraColumnForCollapsibleIcon: false,
                    render: renderSubRowIfDataItemIsMultiTranslationsLabel,
                }}
            />
        </div>
    );

    function onGlobalEditIcon() {
        if (onEditGlobal) {
            onEditGlobal();
        }
    }

    function isMultiTranslationsField(dataItem: IDataItem<IDetailFieldsCols, IDetailFieldExtraData>) {
        return dataItem.extra.isMultiTranslationsField;
    }

    function determineFieldLabel({
        field,
    }: {
        field: IDetailField<FieldKey>;
    }): { fieldLabel: TLabel; multiTranslationsLabel: IApiMultiTranslationsLabel } {
        const { label, overrulePrefixLabel } = field;

        if (isUntranslatableLabelTypeGuard(label)) {
            return {
                fieldLabel: label,
                multiTranslationsLabel: null,
            };
        }

        if (isI18nLabelOrString(label)) {
            const fieldLabel = isString(overrulePrefixLabel)
                ? prependLabelWithPrefix({
                    label,
                    labelPrefix: overrulePrefixLabel,
                    shouldPrefixLabel: true,
                })
                : prependLabelWithPrefix({
                    label,
                    labelPrefix,
                    shouldPrefixLabel: overrulePrefixLabel !== false,
                });

            return {
                fieldLabel,
                multiTranslationsLabel: null,
            };
        }

        /* label is a IApiMultiTranslationsLabel */
        return {
            fieldLabel: {
                text: getDefaultTranslationFromApiLabel(label),
                shouldTranslate: false,
            },
            multiTranslationsLabel: label,
        };
    }

    function renderSubRowIfDataItemIsMultiTranslationsLabel({
        RowComponent,
        CellComponent,
        // nrOfCols,
        isCollapsed,
        dataItem,
        // itemIndex,
    }: IRenderDataItemSubRowProps<IDetailFieldsCols, IDetailFieldExtraData, null>) {
        if (!isMultiTranslationsField(dataItem)) {
            return null;
        }

        const localesDefaultFirst = getUserLocalesSortedDefaultFirstMemoized();
        const localesToShow = isCollapsed
            ? [localesDefaultFirst[0]]
            : localesDefaultFirst;

        return (
            <>
                {localesToShow.map((locale, index) => {
                    const valueTranslation = (dataItem.colValues.value as IApiMultiTranslationsLabel)[locale];
                    const isValueTranslation = isSetString(valueTranslation);

                    return (
                        <RowComponent
                            className={clsx(
                                '__multiTranslationsFieldRow',
                                index === 0 && '__firstLocale',
                            )}
                            key={`multi-translations-locale_${locale}`}
                        >
                            <CellComponent className="__localeTitle">
                                <MultiTranslationsLabelLocaleTitle locale={locale} />
                            </CellComponent>
                            <CellComponent
                                className={clsx(
                                    '__localeValue',
                                    !dataItem.extra.alignValueLeft && 'MuiTableCell-alignRight',
                                    !isValueTranslation && '__noTranslation',
                                )}
                            >
                                {renderLocaleSpecificLabelIfThatIsAlsoMultiTranslation()}
                                {isValueTranslation
                                    ? valueTranslation
                                    : (<Translate msg="common.multi_translations_label.no_translation" />)
                                }
                            </CellComponent>
                        </RowComponent>
                    );

                    function renderLocaleSpecificLabelIfThatIsAlsoMultiTranslation() {
                        if (!isSet(dataItem.extra.multiTranslationsLabel)) {
                            return null;
                        }

                        const labelTranslation = dataItem.extra.multiTranslationsLabel[locale];
                        const isLabelTranslation = isSetString(labelTranslation);

                        return (
                            <>
                                {isLabelTranslation
                                    ? labelTranslation
                                    : (<Translate msg="common.multi_translations_label.no_translation" />)
                                }
                                <div className="__multiTranslationsLabelSeparator">
                                    <ArrowRightIcon />
                                </div>
                            </>
                        );
                    }
                })}
            </>
        );
    }
}

function DetailFieldValueAndActions({
    value,
    multiTranslationsLabel,
    edit,
    link,
    copyValue = false,
    condensedValue = false,
    onEdit,
    asyncValueMapper,
    shouldTranslateValue = false,
    valueInBold = false,
}: Pick<IDetailField, 'value' | 'edit' | 'link' | 'copyValue' | 'condensedValue' |
'asyncValueMapper' | 'shouldTranslateValue'>
& Pick<IDetailFieldExtraData, 'onEdit' | 'multiTranslationsLabel' | 'valueInBold'>) {
    const isValueAsync = isSet(asyncValueMapper);

    /**
     * We use 'useAsyncFetch' instead 0f 'useAsyncFetchOnMount'
     * because otherwise the asyncValueMapper is not called again after the user updated an async field.
     */
    const [asyncValueItem] = useAsyncFetch({
        /* assumption: no 'async' when the value is a IApiMultiTranslationsLabel */
        fetcher: () => asyncValueMapper(value as string),
        shouldFetch: () => isValueAsync,
        deps: [value],
    });

    const fieldValue = getFieldValue();
    const isFieldValueKnown = isString(fieldValue)
        ? isSetString(fieldValue)
        : isSet(fieldValue);

    const shownValue = condensedValue && isFieldValueKnown
        ? (
            <CondensedValue value={fieldValue.toString()} />
        )
        : fieldValue;

    return (
        <div className={clsx('__detailFieldValue', valueInBold && '__valueInBold')}>
            {isFieldValueKnown ? shownValue : NO_DATA_CHARACTER}

            {edit && (
                <IconButton
                    id={`to-edit-field_${edit.fieldKey}`}
                    className="__detailFieldAction"
                    icon={<EditFullIcon />}
                    tooltip="common.action.edit"
                    variant="bare"
                    color="grey"
                    size="S"
                    svgSize={24}
                    onClick={onEdit}
                />
            )}

            {copyValue && isFieldValueKnown && (
                <IconButton
                    id="copy-value"
                    className="__copyIcon"
                    icon={<CopyIcon />}
                    onClick={() => copyToClipboard(fieldValue.toString())}
                    variant="bare"
                    size="XS"
                    color="text"
                    isCircle
                    tooltip="common.action.copy_to_clipboard"
                    tooltipPlacement="top-end"
                />
            )}

            {link && isFieldValueKnown && (
                <IconButton
                    id={`link-field_${link.routeKey}`}
                    className="__detailFieldAction"
                    icon={<ArrowRightIcon />}
                    tooltip="common.action.navigate_to"
                    variant="bare"
                    color="text"
                    size="S"
                    onClick={navigateToLink}
                />
            )}
        </div>
    );

    function navigateToLink() {
        redirectTo(link);
    }

    function getFieldValue() {
        if (isMultiTranslationsLabelTypeGuard(value)) {
            return (
                <MultiTranslationsLabelLocaleTags
                    label={multiTranslationsLabel ? [value, multiTranslationsLabel] : value}
                    addRightPaddingIfMultipleLocales
                />
            );
        }

        if (!isValueAsync) {
            return shouldTranslateValue
                ? <Translate msg={value} />
                : value;
        }

        switch (asyncValueItem.status) {
            case AsyncStatus.Success: {
                return shouldTranslateValue
                    ? <Translate msg={asyncValueItem.data} />
                    : asyncValueItem.data;
            }
            case AsyncStatus.Busy: {
                return <Spinner className="__asyncLoading" size="M" />;
            }
            case AsyncStatus.Error: {
                return (
                    <div className="__asyncError">
                        <Translate msg="error.operation_failed.fetch_short" />
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    }
}
