import React, { useRef, useEffect } from 'react';
import { TStrokeLinecap } from 'models/chart.models';
import { APP_COLORS } from 'config/styling/colors';
import d3, { AxisDomain } from 'utils/libs/d3';
import { makeStyles } from 'views/styling';
import { TYScale } from './types';
import { IYAxisSpecialTickLineProps, YAxisSpecialTickLine } from './YAxisSpecialTickLine';

interface IYAxisProps<YDomain extends AxisDomain = number> extends IYAxisStyleProps {
    yScale: TYScale<YDomain>;
    tickFormatter: (domainValue: YDomain, index: number) => string;
    nrOfTicks?: number;
    tickValues?: YDomain[]; // takes precedence over nrOfTicks if specified
    showAxisLine?: boolean; // default false
    showTickLines?: boolean; // default true
    tickLinesLength?: number; // only needed if showTickLines = true
    tickLineColor?: string; // only needed if showTickLines = true
    tickLineStrokeDasharray?: string; // only needed if showTickLines = true - Default '1, 4'
    tickLineStrokeLinecap?: TStrokeLinecap; // only needed if showTickLines = true - Default 'butt'
    /* only when you want certain tick lines to display differently, e.g. the 0 line */
    specialTickLines?: TSpecialTickLine[];
}

type TSpecialTickLine =
    Pick<IYAxisSpecialTickLineProps, 'yValue' | 'strokeColor' | 'strokeWidth' | 'strokeDasharray' | 'strokeLinecap'>;

interface IYAxisStyleProps {
    textColor?: string; /* default '#183755' */
    textSize?: number; /* default 14 */
}

const useStyles = makeStyles((/* theme */) => ({
    YAxis: {
        color: ({ textColor }) => textColor,
        size: ({ textSize }: IYAxisStyleProps) => textSize,
    },
}));

export default function YAxis({
    yScale,
    tickFormatter,
    nrOfTicks,
    tickValues,
    showAxisLine = false,
    showTickLines = true,
    tickLinesLength,
    tickLineColor,
    tickLineStrokeDasharray = '1, 4',
    tickLineStrokeLinecap = 'butt',
    textColor = APP_COLORS.TEXT['500'],
    textSize = 14,
    specialTickLines,
}: IYAxisProps) {
    const ref = useRef();
    const classes = useStyles({ textColor, textSize });

    useEffect(
        () => {
            const yAxis = d3.axisLeft(yScale)
                .tickFormat(tickFormatter)
                .tickSize(0)
                .tickPadding(8);

            if (tickValues) {
                yAxis.tickValues(tickValues);
            } else if (nrOfTicks) {
                yAxis.ticks(nrOfTicks);
            }

            const g = d3.select<SVGGElement, unknown>(ref.current)
                .call(yAxis);

            if (showTickLines) {
                g.call(
                    (gElem) => gElem.selectAll('.tick line')
                        .attr('stroke', tickLineColor)
                        .attr('stroke-dasharray', tickLineStrokeDasharray)
                        .attr('stroke-linecap', tickLineStrokeLinecap)
                        .attr('x2', tickLinesLength),
                );
            }

            if (!showAxisLine) {
                // g.call((gElem) => gElem.select('.domain').remove());
                g.select('.domain').remove();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [yScale],
    );

    return (
        <>
            <g ref={ref} className={classes.YAxis} />

            {specialTickLines && (renderSpecialTickLines())}
        </>
    );

    function renderSpecialTickLines() {
        return (
            <g>
                {specialTickLines.map((specialTick) => (
                    <YAxisSpecialTickLine
                        key={`y-axis-cpecial-tick_${specialTick.yValue}`}
                        yScale={yScale}
                        lineLength={tickLinesLength}
                        {...specialTick}
                    />
                ))}
            </g>
        );
    }
}
