import { TFastifyRouteGenericInterface } from '@typsy/rest-api/dist/server/endpoint/endpointValidation.models';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { OptimizationOwnerChoice } from '@console/core-api/models/portfolioMgmt/portfolioOptimization.models';
import {
    IEnhancedOptimizationPortfolioUpdate,
    IEnhancedPortfolioOptimization,
} from './enhancedPortfolioOptimization.models';
import { EnhancedOptimizationStatus } from '../enhancedOptimization.models';

export interface IFetchPortfolioOptimizationComparisonApiContract extends TFastifyRouteGenericInterface {
    Params: IFetchPortfolioOptimizationComparisonApiParams;
    Body: IFetchPortfolioOptimizationComparisonApiBody;
    Reply: IPortfolioOptimizationComparison;
}

export interface IFetchPortfolioOptimizationComparisonApiParams {
    portfolioId: TApiEntityId;
    optimizationId: 'latest' | TApiEntityId;
}

export interface IFetchPortfolioOptimizationComparisonApiBody {
    optimization?: Omit<IEnhancedPortfolioOptimization, 'portfolioId'>;
}

export type TFetchPortfolioOptimizationComparisonApiInput = IFetchPortfolioOptimizationComparisonApiParams
& IFetchPortfolioOptimizationComparisonApiBody;

export interface IPortfolioOptimizationComparison {
    portfolioId: TApiEntityId;
    optimizationId: TApiEntityId;
    currency: SupportedCurrency;
    status: EnhancedOptimizationStatus;
    ownerChoice: OptimizationOwnerChoice;
    portfolioUpdate: Omit<IEnhancedOptimizationPortfolioUpdate, 'reasons' | 'is_recommended'>;
    comparison?: {
        portfolioValue: number;
        cash: ICurrentOptimalComparison;
        instruments: IInstrumentRecommendationComparison[];
        instrumentsTotalValue: {
            current: number;
            optimal: number;
        };
        instrumentsPercentageWithinHoldings: {
            current: number;
            optimal: number;
        };
        expectedTransactionCosts: number;
    };
    creationDatetime?: string;
}

export interface IInstrumentRecommendationComparison extends ICurrentOptimalComparison {
    isin: string;
    name: string;
    recommendation: TInstrumentRecommendation;
}

export type TInstrumentRecommendation = {
    type: InstrumentRecommendationType.HOLD;
} | IInstrumentRecommendationWithUnits;

export interface IInstrumentRecommendationWithUnits {
    type: InstrumentRecommendationType.BUY | InstrumentRecommendationType.SELL;
    units: number;
    pricePer: number;
}

export interface ICurrentOptimalComparison {
    current: {
        units?: number;
        amount: number;
        pct: number;
    };
    optimal: {
        units?: number;
        amount: number;
        pct: number;
    };
}

export enum InstrumentRecommendationType {
    HOLD = 'HOLD',
    BUY = 'BUY',
    SELL = 'SELL',
}
