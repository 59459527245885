import { TFastifyRouteGenericInterface } from '@typsy/rest-api/dist/server/endpoint/endpointValidation.models';
import {
    TApiEntityCreate,
    TApiEntityDetail,
    TApiEntityId, TApiEntityPatch,
} from '@console/core-api/models/api.models';
import {
    IPolicyAlgorithmSettings, IPolicyConfig,
    IPolicyEntityData, IPolicyMetaPortfolioSettings,
    IPolicyModelPortfolioSettings,
} from '@console/core-api/models/portfolioMgmt/policy.models';

export interface IFetchEnhancedPolicyDetailsApiContract extends TFastifyRouteGenericInterface {
    Params: IFetchEnhancedPolicyDetailsApiParams;
    Reply: TEnhancedPolicy;
}

export interface IFetchEnhancedPolicyDetailsApiParams {
    policyId: TApiEntityId;
}

export type TFetchEnhancedPolicyDetailsApiInput = IFetchEnhancedPolicyDetailsApiParams;

export interface IEnhancedModelPortfolioComposition {
    [instrumentId: string]: IEnhancedPolicyCompositionElementData;
}

export interface IEnhancedMetaPortfolioComposition {
    [policyOrPortfolioId: TApiEntityId]: IEnhancedPolicyCompositionElementData;
}

export type TEnhancedPolicyComposition =
    IEnhancedModelPortfolioComposition | IEnhancedMetaPortfolioComposition;

export interface IEnhancedPolicyCompositionElementData {
    name?: string;
    weight: number;
}

export enum PolicyMeasureOfRisk {
    IVAR = 'IVAR',
}

export enum PolicyAlgorithmType {
    RISK_BASED = 'RISK_BASED',
    MODEL_PORTFOLIO = 'MODEL_PORTFOLIO',
    META_PORTFOLIO = 'META_PORTFOLIO',
}

export interface IEnhancedPolicyEntityData extends Omit<IPolicyEntityData, 'config'> {
    config: IEnhancedPolicyConfig;
}

export interface IEnhancedPolicyConfig extends Omit<IPolicyConfig, 'algorithm_settings' | 'algorithm'> {
    algorithm: PolicyAlgorithmType;
    measureOfRisk?: PolicyMeasureOfRisk;
    algorithm_settings: IEnhancedPolicyAlgorithmSettings;
}

export interface IEnhancedPolicyAlgorithmSettings
    extends Omit<IPolicyAlgorithmSettings, 'model_portfolio_settings' | 'meta_portfolio_settings'> {
    model_portfolio_settings?: IEnhancedModelPortfolioSettings;
    meta_portfolio_settings?: IEnhancedMetaPortfolioSettings;
}

export interface IEnhancedModelPortfolioSettings extends Omit<IPolicyModelPortfolioSettings, 'model_portfolio'> {
    model_portfolio: IEnhancedModelPortfolioComposition;
}

export interface IEnhancedMetaPortfolioSettings extends Omit<IPolicyMetaPortfolioSettings, 'meta_portfolio'> {
    meta_portfolio: IEnhancedMetaPortfolioComposition;
}

export type TEnhancedPolicy = TApiEntityDetail<IEnhancedPolicyEntityData>;

export interface ICreatePolicyApiContract extends TFastifyRouteGenericInterface {
    Body: TCreatePolicyApiBody;
    Reply: TEnhancedPolicy;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEnhancedPolicyAddEntityData
    extends Omit<IEnhancedPolicyEntityData, 'coerced_config' | 'parent_coerced_config'> {
}

export type TCreatePolicyApiBody = TApiEntityCreate<IEnhancedPolicyAddEntityData>;
export type TCreatePolicyApiInput = TCreatePolicyApiBody;

export interface IPatchPolicyApiContract extends TFastifyRouteGenericInterface {
    Params: TPatchPolicyApiParams;
    Body: TPatchPolicyApiBody;
    Reply: TEnhancedPolicy;
}

export type TPatchPolicyApiParams = IFetchEnhancedPolicyDetailsApiParams;
export type TPatchPolicyApiBody = Omit<TApiEntityPatch<IEnhancedPolicyAddEntityData>, 'id'>;
export type TPatchPolicyApiInput = TPatchPolicyApiBody & TPatchPolicyApiParams;
