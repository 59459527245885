import React from 'react';
import { useParams } from 'react-router-dom';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { IBaseApiEntity } from '@console/core-api/models/api.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { USER_TAB_KEY } from 'config/tabs.config';
import {
    getSelectedUserNameLabel,
    triggerDeleteUser,
    userDetailsEntity,
} from 'state/entities/userMgmt/userDetails';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import DetailPage, { TDetailPageTitleConfig } from 'views/common/detail/DetailPage';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { triggerResetUsersFetch } from 'state/entities/userMgmt/users';
import { getTenantSettings, isStorytellerEnabled } from 'state/appConfig/selectors';
import UserPortfolios from './UserPortfolios';
import UserProperties from './UserProperties';
import UserAccessibleBy from './UserAccessibleBy';
import UserPortfolioReports from './UserPortfolioReports';

function getUserDetailTabs(shouldDisplayStoryteller: boolean): ITabItem[] {
    return [{
        key: USER_TAB_KEY.PROPERTIES,
        label: 'user_mgmt.users.detail.tabs.properties.title',
        component: UserProperties,
        wrapWithContentBox: false,
    }, {
        key: USER_TAB_KEY.PORTFOLIOS,
        label: 'user_mgmt.users.detail.tabs.portfolios.title',
        component: UserPortfolios,
        noHorizontalPadding: true,
    }, {
        key: USER_TAB_KEY.PORTFOLIO_REPORTS,
        label: 'user_mgmt.users.detail.tabs.portfolio_reports.title',
        component: UserPortfolioReports,
        noHorizontalPadding: true,
        hidden: !shouldDisplayStoryteller,
    }, {
        key: USER_TAB_KEY.ACCESSIBLE_BY,
        label: 'user_mgmt.users.detail.tabs.accessible_by.title',
        component: UserAccessibleBy,
    }];
}

const USER_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getSelectedUserNameLabel,
    notifications: [StateChangeNotification.USER_DETAILS_DATA],
};

export default function UserDetail() {
    const {
        userId,
        userTab = USER_TAB_KEY.PORTFOLIOS, // default 'portfolios' because that route does not have a 'userTab' part
    } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={USER_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: getUserDetailTabs(isStorytellerEnabled()),
                activeTabKey: userTab,
                onTabChange: tabChanged,
                entity: {
                    notifications: [StateChangeNotification.USER_DETAILS_DATA],
                    asyncEntitySelector: userDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getUserDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => userDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.userId as string,
            }}
        />
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        /* we can't use redirectToCurrentRouteButOverrideParams() here because then we would keep redirecting to the
         * route R_USER_DETAIL_PORTFOLIOS if from there */
        redirectTo({
            routeKey: ROUTE_KEY.R_USER_DETAIL,
            params: {
                userId,
                userTab: tabKey,
            },
        });
    }

    function getUserDetailActions(apiEntity: IBaseApiEntity): IActionItem[] {
        const tenantSettings = getTenantSettings();

        return tenantSettings?.user?.allowPatchingIdpSpecificFields && [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: CoreApiEntityType.users,
            deleteTrigger: () => triggerDeleteUser({
                onPreSuccess: () => {
                    triggerResetUsersFetch();
                    redirectTo({ routeKey: ROUTE_KEY.R_USERS_LIST });
                },
            }),
        })];
    }
}
