import {
    AsyncOperation,
    TOnAsyncEntityOperationSuccess,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    reshiftArrayLikeObject,
} from '@console/common/utils/object/objectUtils';
import { generateAlphaNumericId } from '@console/common/utils/string/idUtils';
import { nowAsDayJs } from '@console/common/utils/date/dateUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { applyPatch, deepClone } from 'fast-json-patch';
import { api } from 'api';
import {
    IFetchStoryManagerDatabaseApiInput,
    IOutputKey,
    JSONPatchModels,
    IScenario,
    IOutputStatuses,
    IPatchStoryManagerDatabaseCmsApiInput,
    IStoryManagerDatabase,
    IPublishStoryManagerDatabaseCmsApiInput,
    IResetStoryManagerDatabaseCmsApiInput,
} from '@console/bff/models/storyteller/storymanager.models';
import { triggerFlashSuccess } from 'state/ui/flashMessages.actions';
import { errorFlashDispatcher } from 'state/flashDispatcher';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { getCurrentRouteParams, getPrevRouteLocation } from 'state/ui/selectors';
import { IState } from 'models/state.models';
import { OUTPUT_KEY_ID_LENGTH } from 'config/storyTeller/storymanager.config';
import { isUpdateBusy } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getEntitiesManager } from '../entitiesManager';

export const storyManagerDatabaseDetailEntity = getEntitiesManager().registerEntity<IStoryManagerDatabase>({
    asyncEntityKey: AsyncEntityKeys.storyManagerDatabaseDetail,
    operations: [AsyncOperation.fetch, AsyncOperation.update],
    notificationsToTrigger: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
    includeUpdaters: true,
});

export const triggerResetStoryManagerConfigFetch =
    () => storyManagerDatabaseDetailEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchStoryManagerDatabaseDetail({
    forceRefresh = false,
    ...apiInput
}: IFetchStoryManagerDatabaseApiInput & IForceStateRefreshFilter = {}) {
    // eslint-disable-next-line max-len
    return storyManagerDatabaseDetailEntity.async.fetch<IFetchStoryManagerDatabaseApiInput, IStoryManagerDatabase, IStoryManagerDatabase>({
        api: api.bff.storyTeller.fetchStoryManagerDatabase,
        apiInputSelector: () => ({
            databaseId: apiInput.databaseId,
        }),
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput),
        resetDataOnTriggerMode: 'never',
        shouldFetch: ({ state }) => {
            const prevRouteLocation = getPrevRouteLocation(state);
            const storyManagerDatabaseDetail = getSelectedStoryManagerDatabaseDetail();
            return !storyManagerDatabaseDetail
                || prevRouteLocation.routeKey === ROUTE_KEY.R_STORY_MANAGER_VERSIONS;
        },
    });
}

export function triggerPublishStoryManagerConfig({
    databaseId,
    onSuccess,
}: IPublishStoryManagerDatabaseCmsApiInput & {
    // eslint-disable-next-line max-len
    onSuccess?: TOnAsyncEntityOperationSuccess<IState, IPublishStoryManagerDatabaseCmsApiInput, IStoryManagerDatabase>,
}) {
    return storyManagerDatabaseDetailEntity.async.update({
        api: api.bff.storyTeller.publishStoryManagerDatabaseConfig,
        apiInputSelector: () => ({
            databaseId,
        }),
        updateDataOnSuccess: true,
        onSuccess: (props) => {
            props.dispatch(triggerFlashSuccess({
                translationKey: 'apps.story_teller.template_databases.flash_messages.publish_database_success',
            }));
            if (onSuccess) {
                onSuccess(props);
            }
        },
        onError: errorFlashDispatcher('apps.story_teller.template_databases.flash_messages.publish_database_error'),
    });
}

export function triggerResetStoryManagerConfig({
    databaseId,
    onSuccess,
}: IResetStoryManagerDatabaseCmsApiInput & {
    onSuccess?: TOnAsyncEntityOperationSuccess<IState, IResetStoryManagerDatabaseCmsApiInput, IStoryManagerDatabase>,
}) {
    return storyManagerDatabaseDetailEntity.async.update({
        api: api.bff.storyTeller.resetStoryManagerDatabaseConfig,
        apiInputSelector: () => ({
            databaseId,
        }),
        updateDataOnSuccess: true,
        onSuccess: (props) => {
            props.dispatch(triggerFlashSuccess({
                translationKey: 'apps.story_teller.template_databases.flash_messages.reset_database_success',
            }));
            if (onSuccess) {
                onSuccess(props);
            }
        },
        onError: errorFlashDispatcher('apps.story_teller.template_databases.flash_messages.reset_database_error'),
    });
}

export function triggerPatchStoryManagerConfig({
    onSuccess,
    ...apiInput
}: IPatchStoryManagerDatabaseCmsApiInput & {
    onSuccess?: TOnAsyncEntityOperationSuccess<IState, IPatchStoryManagerDatabaseCmsApiInput, unknown>,
}) {
    return storyManagerDatabaseDetailEntity.async.update<IPatchStoryManagerDatabaseCmsApiInput, unknown>({
        api: api.bff.storyTeller.patchStoryManagerDatabaseConfig,
        apiInputSelector: ({ state }) => ({
            databaseId: getCurrentRouteParams(state)?.databaseId as string,
            jsonPatch: apiInput.jsonPatch,
        }),
        onTrigger: () => {
            const currentData: IStoryManagerDatabase = deepClone(getSelectedStoryManagerDatabaseDetail());
            const currentConfig = currentData.config_draft;
            const updatedConfig = applyPatch(currentConfig, apiInput.jsonPatch).newDocument;

            storyManagerDatabaseDetailEntity.updaters.fetch.succeeded({
                ...currentData,
                config_draft: updatedConfig,
                config_modification_datetime: nowAsDayJs().toISOString(),
            }, {
                notificationsToTrigger: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
            });
        },
        onSuccess,
        onError: errorFlashDispatcher('apps.story_teller.output_keys.flash_messages.update_db_error'),
    });
}

export function triggerAddOutputKey(
    inputData: Omit<IOutputKey, 'scenarios'>,
    options?: {
        insertBeforeOutputKeyId?: string;
    },
): string {
    const newId = generateAlphaNumericId(OUTPUT_KEY_ID_LENGTH);

    const addOperation: JSONPatchModels.Operation = {
        op: 'add',
        path: `/outputKeys/${newId}`,
        value: getNewOutputKey(),
    };

    const updateOrderOperation: JSONPatchModels.Operation = {
        op: 'replace',
        path: '/order',
        value: getUpdatedOrder(),
    };

    triggerPatchStoryManagerConfig({
        jsonPatch: [
            addOperation,
            updateOrderOperation,
        ],
        onSuccess: ({ state }) => {
            const { databaseId } = getCurrentRouteParams(state);
            redirectTo({
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
                params: {
                    databaseId,
                    outputKeyId: newId,
                },
            });
        },
    });

    return newId;

    function getUpdatedOrder() {
        const newOrder = [...getStoryManagerOutputKeysOrder()];
        if (options?.insertBeforeOutputKeyId) {
            const indexToInsertAt = newOrder.indexOf(options.insertBeforeOutputKeyId);
            newOrder.splice(indexToInsertAt, 0, newId);
        } else {
            newOrder.push(newId);
        }
        return newOrder;
    }

    function getNewOutputKey() {
        const newOutputKey: IOutputKey = {
            description: inputData.description,
            name: inputData.name,
            scenarios: {
                0: {
                    description: '_',
                    context: '_',
                    example: '_',
                    status: 'TO_DO',
                    conditions: {},
                    output: {
                        statuses: {},
                        variations: {},
                    },
                },
            },
        };

        return newOutputKey;
    }
}

export function triggerDuplicateOutputKey(
    baseOutputKeyId: string,
    baseOutputKey: IOutputKey,
): string {
    const newId = generateAlphaNumericId(OUTPUT_KEY_ID_LENGTH);

    const addOperation: JSONPatchModels.Operation = {
        op: 'add',
        path: `/outputKeys/${newId}`,
        value: getNewOutputKey(),
    };

    const updateOrderOperation: JSONPatchModels.Operation = {
        op: 'replace',
        path: '/order',
        value: getUpdatedOrder(),
    };

    triggerPatchStoryManagerConfig({
        jsonPatch: [
            addOperation,
            updateOrderOperation,
        ],
        onSuccess: ({ state }) => {
            const { databaseId } = getCurrentRouteParams(state);
            redirectTo({
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
                params: {
                    databaseId,
                    outputKeyId: newId,
                },
            });
        },
    });

    return newId;

    function getUpdatedOrder() {
        const newOrder = [...getStoryManagerOutputKeysOrder()];
        const indexToInsertAt = newOrder.indexOf(baseOutputKeyId);
        newOrder.splice(indexToInsertAt + 1, 0, newId);
        return newOrder;
    }

    function getNewOutputKey() {
        const newOutputKey: IOutputKey = {
            description: baseOutputKey.description,
            name: `copy.of.${baseOutputKey.name}`,
            scenarios: resetAllStatusesForScenarios(baseOutputKey.scenarios),
        };
        return newOutputKey;

        function resetAllStatusesForScenarios(scenarios: TObjectWithProps<IScenario>): TObjectWithProps<IScenario> {
            return Object.keys(scenarios)
                .reduce(
                    (newScenarios, scenarioIndex) => {
                        const scenario: IScenario = {
                            ...scenarios[scenarioIndex],
                            status: 'TO_DO',
                            output: {
                                ...scenarios[scenarioIndex].output,
                                statuses: Object.keys(scenarios[scenarioIndex].output.statuses).reduce(
                                    (acc, locale) => {
                                        acc[locale] = 'TO_DO';
                                        return acc;
                                    },
                                    {} as IOutputStatuses,
                                ),
                            },
                        };
                        newScenarios[scenarioIndex] = scenario;

                        return newScenarios;
                    },
                    {} as TObjectWithProps<IScenario>,
                );
        }
    }
}

export async function triggerDeleteOutputKeys(outputKeyIds: string[]) {
    const newOrder = [...getStoryManagerOutputKeysOrder()];
    outputKeyIds.forEach((id) => {
        newOrder.splice(newOrder.indexOf(id), 1);
    });

    const updateOrderOperation: JSONPatchModels.Operation = {
        op: 'replace',
        path: '/order',
        value: newOrder,
    };

    const patch: JSONPatchModels.Operation[] = outputKeyIds.map((id) => ({
        op: 'remove',
        path: `/outputKeys/${id}`,
    }));

    triggerPatchStoryManagerConfig({
        jsonPatch: [
            ...patch,
            updateOrderOperation,
        ],
    });
}

export async function triggerAddScenario(outputKeyId: string, initialData: Partial<IScenario> = {}) {
    triggerPatchStoryManagerConfig({
        jsonPatch: [{
            op: 'replace',
            path: `/outputKeys/${outputKeyId}/scenarios`,
            value: getNewScenarios(),
        }],
    });

    function getNewScenarios() {
        const scenarios = storyManagerDatabaseDetailEntity.select()
            .data?.config_draft?.outputKeys[outputKeyId]?.scenarios;
        const newScenarios = { ...scenarios };

        const newScenario: IScenario = {
            description: initialData.description || '',
            conditions: initialData.conditions || {},
            context: initialData.context || '',
            example: initialData.example || '',
            output: {
                statuses: {},
                variations: initialData?.output?.variations || {},
            },
            status: 'TO_DO',
        };

        newScenarios[Object.keys(newScenarios).length] = newScenario;

        return newScenarios;
    }
}

export async function triggerDeleteScenarios(outputKeyId: string, scenarioIndexes: string[]) {
    triggerPatchStoryManagerConfig({
        jsonPatch: [{
            op: 'replace',
            path: `/outputKeys/${outputKeyId}/scenarios`,
            value: getNewScenarios(),
        }],
    });

    function getNewScenarios() {
        const scenarios = storyManagerDatabaseDetailEntity.select()
            .data?.config_draft?.outputKeys[outputKeyId].scenarios;
        const newScenarios = { ...scenarios };

        scenarioIndexes.forEach((scenarioIndex) => {
            delete newScenarios[scenarioIndex];
        });

        return reshiftArrayLikeObject(newScenarios);
    }
}

export function getSelectedStoryManagerDatabaseDetail() {
    return storyManagerDatabaseDetailEntity.select().data;
}

export function getStoryManagerOutputKeysOrder() {
    return getSelectedStoryManagerDatabaseDetail()?.config_draft?.order || [];
}

export function getStoryManagerLocales(): string[] {
    return getSelectedStoryManagerDatabaseDetail()?.locales || [];
}

export function getStoryManagerAvailableVariables(): string[] {
    return getSelectedStoryManagerDatabaseDetail()?.available_variables || [];
}

export const getSelectedStoryManagerDatabaseTitle: TTitleLabelSelector = () => ({
    text: getSelectedStoryManagerDatabaseDetail()?.name,
    shouldTranslate: false,
});

export function isBusyUpdatingStoryManagerDatabase() {
    return isUpdateBusy(storyManagerDatabaseDetailEntity.select());
}

export function getSelectedStoryManagerDatabaseOutputKey(outputKeyId: string) {
    const outputKeysData = getSelectedStoryManagerDatabaseDetail()?.config_draft;

    if (!outputKeysData) {
        return null;
    }

    return outputKeysData.outputKeys[outputKeyId];
}

export function getSelectedStoryManagerDatabaseOutputKeyScenario(outputKeyId: string, scenarioIndex: string) {
    const outputKeysData = getSelectedStoryManagerDatabaseDetail()?.config_draft;
    if (!outputKeysData) {
        return null;
    }

    const outputKey = outputKeysData.outputKeys[outputKeyId];
    if (!outputKey) {
        return null;
    }

    return outputKey.scenarios[Number(scenarioIndex)];
}
