import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { diffObjects } from '@console/common/utils/object/diffObjects';
import {
    IOutputStatuses,
    IScenario,
    IVariations,
    IVariationTranslations,
    JSONPatchModels,
    TStoryManagerStatus,
} from '@console/bff/models/storyteller/storymanager.models';
import { APP_COLORS } from 'config/styling/colors';
import {
    getStoryManagerScenarioStatus,
} from 'state/ui/storyManager.selector';
import { getStore } from 'state';
import {
    getSelectedStoryManagerDatabaseOutputKey,
    triggerAddScenario,
    triggerDeleteScenarios,
    triggerPatchStoryManagerConfig,
    getStoryManagerLocales,
    isBusyUpdatingStoryManagerDatabase,
    getStoryManagerAvailableVariables,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import { truncateString } from 'utils/string/truncateString';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtraFieldChangesOnSetFieldProps,
    IOnSubmitProps,
    ISingleFieldValueToSet,
} from 'views/common/inputs/extended/ExtendedInputForm';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import Text from 'views/common/widget/Text';
import { mixins } from 'views/styling';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import { DuplicateIcon, KeyIcon, ScenarioEditorIcon, TrashIcon } from 'views/common/icons';
import ContentTitle from 'views/common/layout/ContentTitle';
import ActionButtons from 'views/common/buttons/ActionButtons';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import CollapsableSection from 'views/common/layout/CollapsableSection';
import {
    conditionallyDisableStoryManagerActions,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';
import { getScenarioDetailsSchema } from './scenarioDetailsSchema';
import { ConditionEditorForm, ReadOnlyConditions } from './ConditionEditor';
import { OutputEditor } from './OutputEditor';
import { IScenarioDetailsFormValues } from './shared.models';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.scenario_detail';

interface IPublicProps {
    scenario: IScenario;
    isScenarioEditorMode: boolean;
    canUserModifyScenarios: boolean;
    canUserModifyTextOutputs: boolean;
}

const useStyles = makeStyles((theme) => ({
    ScenarioDetailForm: {
        paddingBottom: theme.spacing(5),
    },
    ScenarioDetailHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        padding: theme.spacing(2, 0, 3),
        borderTop: 'none',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,

        '& > svg': {
            marginRight: theme.spacing(1),
        },

        '& > .title': {
            margin: 0,
        },

        '& .header-action-buttons': {
            flex: 1,
        },
    },
    ScenarioDetailHeaderBreadCrumb: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        cursor: 'pointer',

        '& > svg': {
            marginRight: theme.spacing(1),
            fill: theme.palette.grey[300],
        },

        '& > .title': {
            margin: 0,
            color: theme.palette.grey[300],
        },
    },
    CustomInputWrapper: {
        position: 'relative',
        color: APP_COLORS.TEXT['500'],
        padding: theme.spacing(1, 0, 1, 0),
        '& .input-label': {
            ...mixins.flexRowCenterLeft(),
            ...mixins.typo({ size: 16, weight: 700 }),
            padding: theme.spacing(0.5, 0),
            position: 'relative',
            width: 'fit-content',
        },
    },
}));

export default function ScenarioDetailsForm({
    scenario,
    isScenarioEditorMode,
    canUserModifyScenarios,
    canUserModifyTextOutputs,
}: IPublicProps) {
    const { outputKeyId, scenarioIndex, databaseId } = useRouteParams();
    const classes = useStyles();
    const state = getStore().getState();
    const selectedOutputKey = getSelectedStoryManagerDatabaseOutputKey(outputKeyId);
    const storyManagerLocales = getStoryManagerLocales();
    const availableVariables = getStoryManagerAvailableVariables();
    const isUpdating = isBusyUpdatingStoryManagerDatabase();

    const initialValues: IScenarioDetailsFormValues = {
        status: scenario.status,
        description: scenario.description,
        context: scenario.context,
        example: scenario.example,
        conditions: scenario.conditions,
        variations: getInitialOutputVariations({
            locales: storyManagerLocales,
            scenarioOutputVariations: scenario.output.variations,
        }),
        statuses: getInitialOutputStatuses({
            locales: storyManagerLocales,
            scenarioOutputStatuses: scenario.output.statuses,
        }),
    };

    return (
        <ExtendedInputForm<IScenarioDetailsFormValues>
            className={classes.ScenarioDetailForm}
            name={ExtendedInputFormName.storyManagerOutputKeyScenarioDetails}
            labelPrefix={TRANSLATION_PREFIX}
            submit={{
                onSubmit,
                additionalConditionToDisable: () => isBusyUpdatingStoryManagerDatabase(),
            }}
            initialValues={initialValues}
            renderFormFields={renderFormFields}
            schema={getScenarioDetailsSchema()}
            placeFormActionsInFixedFooter
            reset={{
                triggerOnChangeDependencies: [outputKeyId, scenarioIndex, storyManagerLocales, isUpdating],
            }}
            readOnly={!canUserModifyScenarios && !canUserModifyTextOutputs}
            extraFieldChangesOnSetField={downgradeStatusOnFieldChange}
        />
    );

    function getInitialOutputStatuses(
        input: { locales: string[]; scenarioOutputStatuses: IOutputStatuses; },
    ): IOutputStatuses {
        const data = input.locales.reduce(
            (modifiedStatuses, locale) => {
                // eslint-disable-next-line no-param-reassign
                modifiedStatuses[locale] = input.scenarioOutputStatuses?.[locale] || 'TO_DO';
                return modifiedStatuses;
            },
            {} as IOutputStatuses,
        );
        return data;
    }

    function getInitialOutputVariations(
        input: { locales: string[]; scenarioOutputVariations: IVariations; },
    ): IVariations {
        const data = Object.keys(input.scenarioOutputVariations)
            .reduce(
                (modifiedVariations, key) => {
                    const translations: IVariationTranslations = {};
                    input.locales.forEach((locale) => {
                        translations[locale] = input.scenarioOutputVariations[key][locale] || '';
                    });
                    // eslint-disable-next-line no-param-reassign
                    modifiedVariations[key] = translations;
                    return modifiedVariations;
                },
                {} as IVariations,
            );
        return data;
    }

    async function onSubmit(submitProps: IOnSubmitProps<IScenarioDetailsFormValues>) {
        const patchPathPrefix = `/outputKeys/${outputKeyId}/scenarios/${scenarioIndex}`;
        const {
            conditions: newConditions,
            statuses: newStatuses,
            variations: newVariations,
            ...newSimpleFields
        } = submitProps.values;
        const {
            conditions: initialConditions,
            statuses: initialStatuses,
            variations: initialVariations,
            ...initialSimpleFields
        } = initialValues;

        const simpleFieldsDiffResult = diffObjects(newSimpleFields, initialSimpleFields);
        const simpleFieldsPatch = simpleFieldsDiffResult.diffs.map((item): JSONPatchModels.Operation => ({
            op: 'replace',
            path: `${patchPathPrefix}/${item.propChain[0]}`,
            value: item.master,
        }));

        const patch: JSONPatchModels.Operation[] = [...simpleFieldsPatch];

        if (diffObjects(newConditions, initialConditions).areDiffsDetected) {
            patch.push({
                op: 'replace',
                path: `${patchPathPrefix}/conditions`,
                value: newConditions,
            });
        }
        if (diffObjects(newStatuses, initialStatuses).areDiffsDetected) {
            patch.push({
                op: 'replace',
                path: `${patchPathPrefix}/output/statuses`,
                value: newStatuses,
            });
        }
        if (diffObjects(newVariations, initialVariations).areDiffsDetected) {
            patch.push({
                op: 'replace',
                path: `${patchPathPrefix}/output/variations`,
                value: newVariations,
            });
        }

        triggerPatchStoryManagerConfig({
            jsonPatch: patch,
        });
    }

    /**
     * When making a change to a scenario while the scenario status is DONE,
     * or when making a change to an output variation for a language in DONE,
     * downgrade the status to IN REVIEW.
     */
    function downgradeStatusOnFieldChange({
        fieldsToSet,
        currentValues,
    }: IExtraFieldChangesOnSetFieldProps<IScenarioDetailsFormValues>): ISingleFieldValueToSet[] {
        const changedFieldName = fieldsToSet[0].fieldName;
        const statusToDowngradeTo: TStoryManagerStatus = 'IN_REVIEW';

        if (isScenarioEditorMode) {
            if (changedFieldName !== 'status' && currentValues.status === 'DONE') {
                return [{
                    fieldName: 'status',
                    value: statusToDowngradeTo,
                }];
            }
            return [];
        }

        if (changedFieldName.startsWith('statuses')) {
            return [];
        }

        if (changedFieldName === 'variations') {
            const localesToChange: ISingleFieldValueToSet[] = [];
            storyManagerLocales.forEach((locale) => {
                if (currentValues.statuses[locale] === 'DONE') {
                    localesToChange.push({
                        fieldName: `statuses.${locale}`,
                        value: statusToDowngradeTo,
                    });
                }
            });
            return localesToChange;
        }

        const fieldNameParts = changedFieldName.split('.');
        const locale = fieldNameParts[fieldNameParts.length - 1];

        if (currentValues.statuses[locale] === 'DONE') {
            return [{
                fieldName: `statuses.${locale}`,
                value: statusToDowngradeTo,
            }];
        }

        return [];
    }

    function renderFormFields(
        context: IExtendedInputFormContext<IScenarioDetailsFormValues>,
    ) {
        return (
            <>
                <div className={classes.ScenarioDetailHeader}>
                    {/* eslint-disable-next-line */}
                    <div
                        className={classes.ScenarioDetailHeaderBreadCrumb}
                        onClick={navigateToOutputKeyDetail}
                        role="button"
                    >
                        <KeyIcon />
                        <ContentTitle
                            className="title"
                            variant="section"
                            label={{ text: truncateString(selectedOutputKey.name), shouldTranslate: false }}
                        />
                    </div>
                    <ChevronRight />
                    <ScenarioEditorIcon />
                    <ContentTitle
                        className="title"
                        variant="section"
                        label={{
                            msg: `${TRANSLATION_PREFIX}.breadcrumb`,
                            placeholders: {
                                number: Number(scenarioIndex) + 1,
                            },
                        }}
                    />
                    <StoryManagerStatus
                        status={isScenarioEditorMode
                            ? context.fields.status.value as TStoryManagerStatus
                            : getStoryManagerScenarioStatus(state, scenario)}
                        infoIconTooltip={!isScenarioEditorMode && ({
                            name: 'storymanager-status-scenario',
                            label: 'apps.story_teller.output_keys.status.tooltip.scenario',
                        })}
                        form={isScenarioEditorMode && {
                            formField: context.fields.status,
                            readOnly: !canUserModifyScenarios,
                        }}
                    />
                    {isScenarioEditorMode && (
                        <ActionButtons
                            className="header-action-buttons"
                            actions={getScenarioDetailHeaderActions()}
                        />
                    )}
                </div>
                <CollapsableSection
                    id="scenario-detail-description"
                    title={`${TRANSLATION_PREFIX}.fields.description.label`}
                    defaultClosed
                >
                    <ExtendedInputText
                        formField={context.fields.description}
                        wrapper={{}}
                        multilineRows={2}
                        fullWidth
                        readOnly={!isScenarioEditorMode || !canUserModifyScenarios}
                    />
                </CollapsableSection>

                {!isScenarioEditorMode && (
                    <CollapsableSection
                        id="scenario-detail-conditions"
                        title={`${TRANSLATION_PREFIX}.fields.conditions.label`}
                        defaultClosed
                    >
                        <ReadOnlyConditions {...context} />
                    </CollapsableSection>
                )}

                <CollapsableSection
                    id="scenario-detail-copy-guidelines"
                    title={`${TRANSLATION_PREFIX}.fields.copywriting_guidelines.label`}
                    defaultClosed
                >
                    <ExtendedInputText
                        formField={context.fields.context}
                        wrapper={{
                            label: 'fields.context.label',
                        }}
                        multilineRows={2}
                        fullWidth
                        readOnly={!isScenarioEditorMode || !canUserModifyScenarios}
                    />
                    <ExtendedInputText
                        formField={context.fields.example}
                        wrapper={{
                            label: 'fields.example.label',
                        }}
                        multilineRows={2}
                        fullWidth
                        readOnly={!isScenarioEditorMode || !canUserModifyScenarios}
                    />
                </CollapsableSection>

                {isScenarioEditorMode && (
                    <div className={classes.CustomInputWrapper}>
                        <div className="input-label">
                            <Text label={`${TRANSLATION_PREFIX}.fields.conditions.label`} />
                        </div>
                        {canUserModifyScenarios
                            ? (
                                <ConditionEditorForm
                                    {...context}
                                    availableVariables={availableVariables}
                                />
                            )
                            : (
                                <ReadOnlyConditions
                                    {...context}
                                />
                            )
                        }
                    </div>
                )}

                {!isScenarioEditorMode && (
                    <div className={classes.CustomInputWrapper}>
                        <div className="input-label">
                            <Text label={`${TRANSLATION_PREFIX}.fields.copy.label`} />
                        </div>
                        <OutputEditor
                            {...context}
                            canUserModifyTextOutputs={canUserModifyTextOutputs}
                        />
                    </div>
                )}
            </>
        );
    }

    function getScenarioDetailHeaderActions() {
        return conditionallyDisableStoryManagerActions({
            canUserModify: canUserModifyScenarios,
            actions: [{
                id: 'scenario-form-duplicate',
                label: `${TRANSLATION_PREFIX}.actions.duplicate`,
                onExecute: async () => triggerAddScenario(
                    outputKeyId,
                    selectedOutputKey.scenarios[scenarioIndex],
                ),
                variant: 'extra',
                icon: <DuplicateIcon />,
            }, {
                id: 'scenario-form-delete',
                label: `${TRANSLATION_PREFIX}.actions.delete`,
                onExecute: async () => {
                    triggerDeleteScenarios(outputKeyId, [scenarioIndex]);
                    redirectTo({
                        routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
                        params: {
                            databaseId,
                            outputKeyId,
                        },
                    });
                },
                variant: 'extra',
                icon: <TrashIcon />,
            }],
        });
    }

    function navigateToOutputKeyDetail() {
        redirectTo({
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
            params: {
                databaseId,
                outputKeyId,
            },
        });
    }
}
