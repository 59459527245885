import { fetchReportInfo } from './generic/fetchReportInfo.client';
import { fetchRoboAumReport } from './reports/robo/fetchRoboAumReport.client';
import { fetchSelfAumReport } from './reports/self/fetchSelfAumReport.client';
import { fetchRoboClientCashTransferReport } from './reports/robo/fetchRoboClientCashTransferReport.client';
import { fetchSelfClientCashTransferReport } from './reports/self/fetchSelfClientCashTransferReport.client';
import { fetchRoboClientGrowthReport } from './reports/robo/fetchRoboClientGrowthReport.client';
import { fetchSelfClientGrowthReport } from './reports/self/fetchSelfClientGrowthReport.client';

export const reportingApiClient = {
    fetchReportInfo,
    fetchRoboAumReport,
    fetchSelfAumReport,
    fetchRoboClientCashTransferReport,
    fetchSelfClientCashTransferReport,
    fetchRoboClientGrowthReport,
    fetchSelfClientGrowthReport,
};
