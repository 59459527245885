import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import {
    array, number, object, string, Schema, StringSchema,
    REGEX_COMMA_SEPARATED_STRING,
    REGEX_COMMA_SEPARATED_CURRENCIES,
    REGEX_COMMA_SEPARATED_ENTITY_IDS,
} from '@console/common/utils/schema';
import { CoreApiPolicyAlgorithmType, PolicyManagerTag } from '@console/core-api/models/portfolioMgmt/policy.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { entityId } from '@console/core-api/utils/schema/entityIdSchema';
import { PolicyAlgorithmType, PolicyMeasureOfRisk } from '../../../models/policies/enhancedPolicyDetails.models';
import {
    coreApiCreateEntityBodySchema,
    coreApiPatchEntityBodySchema,
} from './commonBff.schemas';

export const policyIdSchema = decorateSchema(entityId(CoreApiEntityType.policies), { description: 'Policy ID' });

export const policyManagerTagsSchema = array().of(
    string().required().oneOf(Object.values(PolicyManagerTag)),
) as Schema<PolicyManagerTag[]>;

export const policyAlgorithmTypeSchema = string()
    .oneOf(Object.values(PolicyAlgorithmType)) as StringSchema<PolicyAlgorithmType>;

export const policyMeasureOfRiskSchema = string()
    .oneOf(Object.values(PolicyMeasureOfRisk)) as StringSchema<PolicyMeasureOfRisk>;

const policyConfigSchema = object({
    algorithm: policyAlgorithmTypeSchema.required(),
    measureOfRisk: policyMeasureOfRiskSchema,
    algorithm_settings: object().required(),
    algorithm_version: number().positive().required(),
});

export const policyPatchBodySchema = getPolicyBodySchema();
export const policyCreateBodySchema = getPolicyBodySchema({ isCreate: true });

function getPolicyBodySchema({
    isCreate = false,
}: {
    isCreate?: boolean;
} = {}) {
    return object({
        config: isCreate ? policyConfigSchema.required() : policyConfigSchema,
        external_id: string(),
        name: isCreate ? string().required() : string(),
        parent_policy_id: entityId(CoreApiEntityType.policies).nullable(),
        risk_profile_id: entityId(CoreApiEntityType.riskProfiles).nullable(),
        tags: policyManagerTagsSchema,
    }).concat(isCreate ? coreApiCreateEntityBodySchema : coreApiPatchEntityBodySchema);
}

export const advancedPolicyFiltersServerSideSchema = object({
    externalId: string(),
    policyName: string(),
    riskProfileId: string(),
    currencies: decorateSchema(
        string().matches(
            REGEX_COMMA_SEPARATED_CURRENCIES, 'currencies should be a comma-separated string',
        ),
        { description: 'A comma-separated list of currencies to optionally filter on.' },
    ),
    tags: decorateSchema(
        string().matches(
            REGEX_COMMA_SEPARATED_STRING, 'tags should be a comma-separated string',
        ),
        { description: 'A comma-separated list of policy tags to optionally filter on.' },
    ),
    ids: decorateSchema(
        string().matches(
            REGEX_COMMA_SEPARATED_ENTITY_IDS, 'ids should be a comma-separated string',
        ),
        { description: 'A comma-separated list of policy ids to optionally filter on.' },
    ),
    algorithmType: string().oneOf(Object.values(CoreApiPolicyAlgorithmType)),
});
