import { IApiEntityListResponse, IBaseSingleEntityApiInput } from '../../models/api.models';
import {
    IFetchUserGroupsApiInput, ISingleUserGroupApiInput,
    TUserGroupsData, IUserGroupEntityData,
    TUserGroup, TUserGroupPatch, TUserGroupCreate,
} from '../../models/userMgmt/userGroup.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchUserGroups({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    userGroupIds = null,
    orderBy,
    count,
}: IFetchUserGroupsApiInput = {}) {
    return get<TUserGroupsData, IApiEntityListResponse<IUserGroupEntityData>>({
        url: CoreApiPath.USERGROUPS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .orderBy(orderBy)
                .fieldIn({ field: 'id', value: userGroupIds })
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchUserGroupDetails({ userGroupId }: ISingleUserGroupApiInput) {
    return get<TUserGroup>({
        url: CoreApiPath.USERGROUP_DETAILS,
        pathParams: {
            userGroupId,
        },
    });
}

export function patchUserGroup({
    id,
    ...patchFields
}: TUserGroupPatch) {
    return patch<TUserGroup>({
        url: CoreApiPath.USERGROUP_DETAILS,
        pathParams: {
            userGroupId: id,
        },
        body: patchFields,
    });
}

export function createUserGroup(userGroupToCreate: TUserGroupCreate) {
    return post<TUserGroup>({
        url: CoreApiPath.USERGROUPS,
        body: userGroupToCreate,
    });
}

export function deleteUserGroup({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.USERGROUP_DETAILS,
        pathParams: {
            userGroupId: id,
        },
    });
}
