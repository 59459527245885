import {
    IApiEntityListResponse,
    IApiEntityResponseSupportingEmbedding,
    IBaseSingleEntityApiInput,
    IDefaultUserLocale,
} from '../../models/api.models';
import {
    IFetchPortfolioReportsApiInput,
    IPortfolioReportEntityData,
    TPortfolioReportCreate,
    TPortfolioReportEmbeddableFields,
    TPortfolioReportsData,
    IFetchPortfolioReportFileUrlApiInput,
    TPortfolioReportUpdate,
} from '../../models/portfolioMgmt/portfolioReport.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchPortfolioReports({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    portfolioId,
    name,
    userId,
    defaultUserLocale,
    orderBy,
    count,
}: IFetchPortfolioReportsApiInput & IDefaultUserLocale) {
    // eslint-disable-next-line max-len
    return get<TPortfolioReportsData, IApiEntityListResponse<IPortfolioReportEntityData> & IApiEntityResponseSupportingEmbedding>({
        url: CoreApiPath.PORTFOLIO_REPORTS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .embed<TPortfolioReportEmbeddableFields>({ fields: ['user_id', 'portfolio_id'] })
                .exactMatch({ field: 'user_id', value: userId })
                .exactMatch({ field: 'portfolio_id', value: portfolioId })
                .contains({ field: `name.${defaultUserLocale}`, value: name })
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function createPortfolioReports(
    reportsInputData: TPortfolioReportCreate[],
): Promise<IPortfolioReportEntityData[]> {
    return post<IPortfolioReportEntityData[]>({
        url: CoreApiPath.PORTFOLIO_REPORT_GENERATE,
        body: reportsInputData,
    });
}

export function updatePortfolioReport({
    name,
    portfolioReportId,
}: TPortfolioReportUpdate) {
    return patch<IPortfolioReportEntityData>({
        url: CoreApiPath.PORTFOLIO_REPORT_DETAIL,
        pathParams: {
            portfolioReportId,
        },
        body: {
            name,
        },
    });
}

export function deletePortfolioReport({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.PORTFOLIO_REPORT_DETAIL,
        pathParams: {
            portfolioReportId: id,
        },
    });
}

export function fetchPortfolioReportFileUrls({
    portfolioReportId,
    format,
}: IFetchPortfolioReportFileUrlApiInput) {
    return get<string>({
        url: CoreApiPath.PORTFOLIO_REPORT_FILE_URL,
        pathParams: {
            portfolioReportId,
            format,
        },
    });
}
