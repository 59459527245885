import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { observe, IObserveProps } from 'views/observe';
import { mixins } from 'views/styling';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    storyManagerDatabasesEntity,
} from 'state/entities/storyTeller/storyManagerDatabases';
import SpinnerOverlay from 'views/common/loading/SpinnerOverlay';
import {
    storyManagerDatabaseDetailEntity,
    getSelectedStoryManagerDatabaseTitle,
    getSelectedStoryManagerDatabaseDetail,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import TextButton from 'views/common/buttons/TextButton';
import ContentBoxWithSide, {
    ContentBoxMainContent,
    ContentBoxSideContent,
} from 'views/common/layout/ContentBoxWithSide';
import { getStoryManagerSidebarWidth } from 'state/ui/storyManager.selector';
import { setStoryManagerPageVars } from 'state/ui/uiPages.actions';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { isFetchBusy, hasAsyncOperationFailed } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import SideNavigation from './SideNavigation';
import EditorModeSwitch from './StoryManagerActionsHeader';
import StoryManagerFooter from './StoryManagerFooter';

const useStyles = makeStyles((theme) => ({
    StoryManagerTemplate: {
        '& .resizableContainer': {
            ...mixins.flex({ wrap: 'nowrap', alignMain: 'flex-start', alignCross: 'stretch' }),
            alignContent: 'stretch',

            overflow: 'hidden',

            '& > aside': {
                order: 0,
                flex: '0 0 auto',
                alignSelf: 'auto',

                paddingRight: theme.spacing(1),

                minWidth: '220px',
                maxWidth: '50%',
            },
            '& > main': {
                order: 0,
                flex: '1 1 auto',
                alignSelf: 'auto',

                paddingLeft: theme.spacing(3),
            },
            '& > .resizeHandle': {
                flex: '0 0 auto',

                position: 'relative',
                width: '1px',
                background: theme.palette.grey[300],
                cursor: 'ew-resize',
            },
        },
    },
    UpdateError: {
        padding: theme.spacing(3),

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),

        textAlign: 'center',
    },
}));

interface IPublicProps {
    children: ReactNode;
}

function StoryManagerTemplate({ children, state, dispatch }: IPublicProps & IObserveProps) {
    const classes = useStyles();
    const isLoading = isFetchBusy(storyManagerDatabaseDetailEntity.select())
        || isFetchBusy(storyManagerDatabasesEntity.select());
    const hasUpdateError = hasAsyncOperationFailed(storyManagerDatabaseDetailEntity.select(), AsyncOperation.update);

    const storyManagerDatabase = getSelectedStoryManagerDatabaseDetail();
    const initialWidth = getStoryManagerSidebarWidth(state);

    return (
        <>
            <ContentBoxWithSide
                titleLabelSelector={{
                    selector: getSelectedStoryManagerDatabaseTitle,
                    notifications: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
                }}
                className={classes.StoryManagerTemplate}
                customAction={{
                    component: EditorModeSwitch,
                }}
                initialWidth={initialWidth}
                onResize={onResize}
            >
                <ContentBoxSideContent>
                    <SideNavigation />
                </ContentBoxSideContent>
                <ContentBoxMainContent>
                    {hasUpdateError ? (
                        <div className={classes.UpdateError}>
                            <Translate msg="apps.story_teller.output_keys.error.out_of_sync_error" />
                            <TextButton
                                id="storymanager-refresh"
                                label="apps.story_teller.output_keys.error.out_of_sync_error_confirm"
                                onClick={() => window.location.reload()}
                                size="S"
                            />
                        </div>
                    ) : children}
                    {storyManagerDatabase && <StoryManagerFooter />}
                </ContentBoxMainContent>
            </ContentBoxWithSide>
            <SpinnerOverlay open={isLoading} position="fixed" variant="white" />
        </>
    );

    function onResize(width: number) {
        dispatch(setStoryManagerPageVars({
            sidebarWidth: width,
        }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_SIDE_NAV_WIDTH]));
    }
}

export default observe([
    StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
], StoryManagerTemplate);
