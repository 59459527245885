import {
    TExtendedFormFields,
    IFormValues,
} from 'views/common/inputs/extended/ExtendedInputForm';

export function areThereErrorsInFormFields<Values extends IFormValues>(
    fields: TExtendedFormFields<Values>,
): boolean {
    return Object.values(fields).some((field) => field.error);
}
