import consoleGroupLogger from '@snipsonian/browser/cjs/logging/consoleGroupLogger';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { createGroupApiLogger } from '@snipsonian/axios/cjs/logging/groupApiLogger';
import { HEADER_NAME } from '@console/core-api/config/coreApi.config';
import { initCoreApiRequestWrapper } from '@console/core-api/client/coreApiRequestWrapper';
import { initConsoleBffRequestWrapper } from '@console/bff/client/consoleBffRequestWrapper';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { IOnApiWarningProps } from '@console/api-base/models/api.models';
import { isApiLoggingEnabled } from 'config/develop.config';
import { CONSOLE_BFF_BASE_URL, CORE_API_BASE_URL } from 'config/api.config';
import { flashApiWarning } from 'api/flashMessageNoCircularDependencies';

export interface IApiErrorHandler {
    resolve?: (error: IBaseApiErrorClientSide) => boolean;
    handle?: (error: IBaseApiErrorClientSide) => void;
}

const apiLogger = isApiLoggingEnabled
    ? createGroupApiLogger({ groupLogger: consoleGroupLogger })
    : undefined;

const apiErrorHandlers: IApiErrorHandler[] = [];

export const registerApiErrorHandler = (handler: IApiErrorHandler) => {
    apiErrorHandlers.push(handler);
};

type TAccessTokenGetter = () => string;

let accessTokenGetter: (TAccessTokenGetter) = () => null;

export function setAccessTokenGetter(getter: TAccessTokenGetter) {
    accessTokenGetter = getter;
}

initCoreApiRequestWrapper({
    apiLogger,
    defaultBaseUrl: CORE_API_BASE_URL,
    onError: (error) => {
        handleApiError(error);
    },
    requestCustomTransformer: ({ request, customConfig = {} }) => {
        const {
            requiresAuthentication = true,
        } = customConfig;

        if (requiresAuthentication) {
            addAuthorizationHeader(request.headers);
        }

        return request;
    },
    onApiWarning,
});

initConsoleBffRequestWrapper({
    apiLogger,
    defaultBaseUrl: CONSOLE_BFF_BASE_URL,
    onError: (error) => {
        handleApiError(error);
    },
    requestCustomTransformer: ({ request, customConfig = {} }) => {
        const {
            requiresAuthentication = true,
        } = customConfig;

        if (requiresAuthentication) {
            addAuthorizationHeader(request.headers);
        }

        return request;
    },
    onApiWarning,
});

function handleApiError(error: IBaseApiErrorClientSide) {
    apiErrorHandlers.reduce((acc, handler) => {
        if (!acc && handler.resolve) {
            return handler.resolve(error);
        }
        return acc;
    }, false);
    apiErrorHandlers.forEach((handler) => handler.handle && handler.handle(error));
}

function addAuthorizationHeader(headers: TAnyObject) {
    // eslint-disable-next-line no-param-reassign
    headers[HEADER_NAME.AUTHORIZATION] = `Bearer ${accessTokenGetter()}`;
}

function onApiWarning({ warningCode }: IOnApiWarningProps) {
    flashApiWarning(warningCode);
}
