import isSetString from '@snipsonian/core/cjs/string/isSetString';
import addItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import { IBaseApiEntityAccess, TApiEntityId } from '@console/core-api/models/api.models';

export function enrichApiEntityToEnsureUserAccess<ApiEntityData>({
    entity,
    userId,
}: {
    entity: ApiEntityData & IBaseApiEntityAccess;
    userId: TApiEntityId;
}): ApiEntityData & IBaseApiEntityAccess {
    if (entity && isSetString(userId)) {
        // eslint-disable-next-line no-param-reassign
        entity.readable_by = addItemAndGetResultingArray(
            entity.readable_by,
            userId,
            { resultInNewArray: true, addIfAlreadyPresent: false },
        );

        // eslint-disable-next-line no-param-reassign
        entity.modifiable_by = addItemAndGetResultingArray(
            entity.modifiable_by,
            userId,
            { resultInNewArray: true, addIfAlreadyPresent: false },
        );
    }

    return entity;
}
