import React, { useEffect } from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS } from 'config/styling/colors';
import d3 from 'utils/libs/d3';
import getObjectKeyVals from '@snipsonian/core/cjs/object/keyVals/getObjectKeyVals';
import { makeStyles, mixins } from 'views/styling';
import XAxis from 'views/common/charts/XAxis';
import YAxis from 'views/common/charts/YAxis';
import AreaBetweenTwoLines from 'views/common/charts/AreaBetweenTwoLines';
import PathLine from 'views/common/charts/PathLine';
import VerticalMarker from 'views/common/charts/VerticalMarker';
import { IChartDimensions, IGenericPerformanceChartConfig, IPerformanceMarkedDateBase } from 'models/chart.models';
import { CHART, PERFORMANCE_GRAPH_ELEMENT_ID } from 'config/styling/chart';
import { getElementDimensionsById } from 'utils/dom/selectorUtils';
import {
    initMarkedDateBox,
    TMarkedDateDataSelector,
} from './MarkedDateBox';

export interface IGenericPerformanceGraphProps<MarkedDateValues
extends IPerformanceMarkedDateBase = IPerformanceMarkedDateBase> {
    baseCurrency?: string;
    chartDimensions: IChartDimensions;
    markedDateBoxNotifications: StateChangeNotification[];
    getChartConfig: (chartDimensions: IChartDimensions) => IGenericPerformanceChartConfig<MarkedDateValues>;
    markedDateDataSelector: TMarkedDateDataSelector;
    onChangedMarkedDate: (markedDate: MarkedDateValues) => void;
}

const useStyles = makeStyles((theme) => ({
    GenericPerformanceGraph: {
        width: '100%',
        ...mixins.flexCenter(),
        marginTop: theme.spacing(6),
        position: 'relative',
    },
}));

let prevMarkedDate: Date = null;

export default function GenericPerformanceGraph<MarkedDateValues extends IPerformanceMarkedDateBase>({
    baseCurrency,
    getChartConfig,
    markedDateBoxNotifications,
    chartDimensions,
    markedDateDataSelector,
    onChangedMarkedDate,
}: IGenericPerformanceGraphProps<MarkedDateValues>) {
    const classes = useStyles();
    const chartConfig = getChartConfig(chartDimensions);

    let mouseClientX = 0;

    useEffect(
        () => {
            prevMarkedDate = null;
        },
        [],
    );

    useEffect(
        () => {
            if (chartConfig && !prevMarkedDate) {
                /* set the initial marked Date */
                const marked = chartConfig.getValuesForInitialMarkedDate();
                prevMarkedDate = marked.date;
                onChangedMarkedDate(marked);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chartConfig],
    );

    const MarkedDateBox = initMarkedDateBox({
        notifications: markedDateBoxNotifications,
    });

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div className={classes.GenericPerformanceGraph}>
            <svg
                viewBox={`${0} ${0} ${chartConfig.svgWidth} ${chartConfig.svgHeight}`}
                style={{
                    maxHeight: chartConfig.svgHeight,
                    maxWidth: chartConfig.svgWidth,
                }}
                id={PERFORMANCE_GRAPH_ELEMENT_ID}
            >
                <g transform={`translate(${CHART.MARGIN_LEFT}, ${chartConfig.chartHeight + CHART.MARGIN_TOP})`}>
                    <XAxis
                        xScale={chartConfig.xScale}
                        // nrOfTicks={chartConfig.xAxis.nrOfTicks}
                        tickValues={chartConfig.xAxis.tickValues}
                        tickFormatter={chartConfig.xAxis.tickFormatter}
                    />
                </g>

                <g transform={`translate(${CHART.MARGIN_LEFT}, ${CHART.MARGIN_TOP})`}>
                    {/* rectangle without visible purpose but just to capture the mouse movement */}
                    <rect
                        x={0}
                        y={0}
                        width={chartConfig.chartWidth}
                        height={chartConfig.chartHeight}
                        strokeWidth={0}
                        fill={APP_COLORS.SYSTEM.WHITE}
                        onMouseDown={onMouseClickGraph}
                    />

                    {/* BEFORE the YAxis so that it's horizontal ticklines are drawn on top of the area */}
                    <AreaBetweenTwoLines
                        xScale={chartConfig.xScale}
                        yScale={chartConfig.yScale}
                        areaData={chartConfig.area.data}
                        fillColor={chartConfig.area.color}
                        curveFactory={chartConfig.area.curveFactory}
                    />

                    <YAxis
                        yScale={chartConfig.yScale}
                        nrOfTicks={chartConfig.yAxis.nrOfTicks}
                        tickValues={chartConfig.yAxis.tickValues}
                        tickFormatter={chartConfig.yAxis.tickFormatter}
                        tickLinesLength={chartConfig.yAxis.tickLinesLength}
                        tickLineColor={CHART.Y_AXIS.TICK_LINE_COLOR}
                    />

                    {getObjectKeyVals(chartConfig.lines).map(({ key, value }) => (
                        <PathLine
                            key={`performance-graph-line_${key}`}
                            xScale={chartConfig.xScale}
                            yScale={chartConfig.yScale}
                            lineData={value.data}
                            strokeColor={value.color}
                            curveFactory={value.curveFactory}
                        />
                    ))}

                    <VerticalMarker
                        xScale={chartConfig.xScale}
                        yScale={chartConfig.yScale}
                        markerData={{
                            x: chartConfig.markerStartingPos.x,
                            y: Object.values(chartConfig.markerStartingPos.y),
                        }}
                        markerLineColor={CHART.MARKER.LINE_COLOR}
                        chartHeight={chartConfig.chartHeight}
                    />
                </g>
            </svg>
            <MarkedDateBox
                baseCurrency={baseCurrency}
                dataSelector={markedDateDataSelector}
            />
        </div>
    );

    function onMouseClickGraph(event: React.MouseEvent) {
        mouseClientX = event.clientX;
        executeClickOnGraph();
    }

    function executeClickOnGraph() {
        const { left, width } = getElementDimensionsById(PERFORMANCE_GRAPH_ELEMENT_ID);

        const chartResizeRatio = width / chartDimensions.maxWidth;

        const xCoordinateWithinGraph = (mouseClientX - left) / chartResizeRatio - CHART.MARGIN_LEFT;
        const xDateEquivalent = chartConfig.xScale.invert(xCoordinateWithinGraph);

        const marked = chartConfig.getValuesForDate(xDateEquivalent);

        if (!marked || (prevMarkedDate === marked.date)) {
            return;
        }

        prevMarkedDate = marked.date;

        const xStartingPos = chartConfig.xScale(chartConfig.markerStartingPos.x);
        const xNewPos = chartConfig.xScale(marked.date);

        d3.select<SVGGElement, unknown>('.VerticalMarker')
            .attr('transform', `translate(${xNewPos - xStartingPos}, 0)`);

        getObjectKeyVals(chartConfig.markerStartingPos.y)
            .forEach(({ key, value }) => {
                const yStartingPos = chartConfig.yScale(chartConfig.markerStartingPos.y[key].val);
                const yNewPos = chartConfig.yScale(marked.values[key]);

                d3.select<SVGCircleElement, unknown>(`.${value.className}`)
                    .attr('transform', `translate(0, ${yNewPos - yStartingPos})`);
            });

        onChangedMarkedDate(marked);
    }
}
