import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { IApiEntityVersions, IFetchEntityVersionsApiInput } from '@console/core-api/models/apiEntityVersions.models';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';

export const apiEntityVersionsEntity = getEntitiesManager().registerEntity<IApiEntityVersions>({
    asyncEntityKey: AsyncEntityKeys.apiEntityVersions,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.API_ENTITY_VERSIONS_DATA],
    includeUpdaters: true,
});

export const triggerResetApiEntityVersionsFetch = () => apiEntityVersionsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchApiEntityVersions(apiInput: IFetchEntityVersionsApiInput) {
    return apiEntityVersionsEntity.async.fetch<IFetchEntityVersionsApiInput>({
        api: api.apiEntityVersions.fetchApiEntityVersions,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchEntityVersionsApiInput, unknown>({
            pageKey: UiPageKey.apiEntityVersionsList,
            state,
            apiInput,
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData<unknown>({
            response,
            existingData: apiEntityVersionsEntity.select().data,
        }) as IApiEntityVersions,
        refreshMode: 'always',
        resetDataOnTriggerMode: () => isDifferentEntityTypeOrId(apiInput),
    });
}

function isDifferentEntityTypeOrId(apiInput: IFetchEntityVersionsApiInput) {
    const { type, id } = apiEntityVersionsEntity.select().data;

    return (type !== apiInput.type) || (id !== apiInput.id);
}
