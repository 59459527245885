import React from 'react';
import clsx from 'clsx';
import { TI18nLabelOrString } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import ContentBox, { IContentBoxProps } from 'views/common/layout/ContentBox';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IEntityWrapperProps, initEntityWrapper } from 'views/common/widget/EntityWrapper';

interface IPublicProps extends Pick<IContentBoxProps, 'cancel'> {
    className?: string;
    titleLabel: TI18nLabelOrString;
    entity: {
        notifications: StateChangeNotification[];
    } & Pick<IEntityWrapperProps, 'asyncEntitySelector'>;
    children: React.ReactNode;
    noPadding?: boolean; // default false
}

const useStyles = makeStyles((/* theme */) => ({
    AddPage: {
        '& .AddPageContent': {
            ...mixins.flexColTopCenter(),
        },
    },
}));

export default function AddPage({
    className,
    titleLabel,
    entity,
    children,
    noPadding = false,
    cancel,
}: IPublicProps) {
    const classes = useStyles();

    const EntityWrapper = initEntityWrapper({
        notifications: entity.notifications,
    });

    return (
        <ContentBox
            titleLabel={titleLabel}
            className={clsx(classes.AddPage, className)}
            noVertialPadding={noPadding}
            noHorizontalPadding={noPadding}
            cancel={cancel}
        >
            <EntityWrapper
                asyncEntitySelector={entity.asyncEntitySelector}
                shouldRenderIfNoDataSet
                renderData={renderChildren}
            />
        </ContentBox>
    );

    function renderChildren() {
        return (
            <div className="AddPageContent">
                {children}
            </div>
        );
    }
}
