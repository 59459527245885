import React from 'react';
import { SortOrder } from '@console/common/models/sort.models';
import { IColValues, IDataItem, TDataColumns, TDataItemId } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { TRiskProfile } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { DEFAULT_RISK_PROFILE_TAB_KEY } from 'config/tabs.config';
import {
    riskProfilesEntity,
    triggerFetchRiskProfiles,
} from 'state/entities/portfolioMgmt/riskProfiles';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { formatScoreRange } from 'utils/entities/portfolioMgmt/riskProfileUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, {
    ISortConfigFunctions,
    TSetStateOnPageNrChange,
} from 'views/common/list/ListPageForApiEntity';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';

const COL_TRANSLATION_PREFIX = 'portfolio_mgmt.risk_profiles.list.columns';

interface IRiskProfilesCols extends IColValues {
    name: string;
    description: string;
    scoreRange: string;
    updated: string;
}

const COLS: TDataColumns<IRiskProfilesCols> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        data: {
            className: UtilityClass.table.cellBold,
        },
        percentWidth: 20,
    },
    description: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.description`,
        },
        percentWidth: 48,
    },
    scoreRange: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.score_range`,
        },
        percentWidth: 12,
    },
    updated: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.updated`,
        },
        percentWidth: 20,
        sort: {
            initialOrder: SortOrder.Descending,
            serverSide: {
                field: 'version_datetime',
            },
        },
    },
};

export default function RiskProfilesList() {
    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.riskProfiles.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.RISK_PROFILES_DATA],
    });

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <ListPageForApiEntity
            notifications={[StateChangeNotification.RISK_PROFILES_DATA, StateChangeNotification.RISK_PROFILES_UI_VARS]}
            asyncListEntity={riskProfilesEntity}
            asyncListEntityFetchTrigger={triggerFetchRiskProfiles}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.riskProfilesList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.RISK_PROFILES_UI_VARS}
            box={{
                title: 'portfolio_mgmt.risk_profiles.title',
            }}
            list={{
                renderData: renderRiskProfilesList,
            }}
            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_RISK_PROFILE_ADD,
                },
                tooltip: 'portfolio_mgmt.risk_profiles.list.actions.create_risk_profile',
            }}
        />
    );

    function renderRiskProfilesList({ data }: { data: TRiskProfile[] }) {
        if (!data) {
            return null;
        }

        const riskProfileItems: IDataItem<IRiskProfilesCols>[] = data.map((riskProfile) => ({
            id: riskProfile.id,
            colValues: {
                name: getDefaultTranslationFromApiLabel(riskProfile.name),
                description: getDefaultTranslationFromApiLabel(riskProfile.description),
                scoreRange: formatScoreRange(riskProfile.score_range),
                updated: formatDateRelativeToNow({ date: riskProfile.version_datetime }),
            },
        }));

        return (
            <DataTable
                cols={COLS}
                items={riskProfileItems}
                onItemRowClicked={(riskProfile) => openRiskProfileDetail(riskProfile.id)}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );
    }

    function openRiskProfileDetail(riskProfileId: TDataItemId) {
        redirectTo({
            routeKey: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
            params: {
                riskProfileId,
                riskProfileTab: DEFAULT_RISK_PROFILE_TAB_KEY,
            },
        });
    }
}
