import {
    IFetchEnhancedPortfolioPerformanceApiBody,
    IEnhancedPortfolioPerformance,
    TFetchEnhancedPortfolioPerformanceApiInput, TFetchEnhancedPortfolioPerformancePastApiBody,
} from '@console/bff/models/portfolios/enhancedPortfolioPerformance.models';
import {
    AsyncOperation,
    TOnAsyncEntityOperationSuccess,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { TApiEntityId } from '@console/core-api/models/api.models';
import {
    SampleFrequencyPerformanceFuture,
    SampleFrequencyPerformancePast,
} from '@console/core-api/models/performance.models';
import {
    IFetchPortfolioPerformanceBaseApiInput,
    TFetchPortfolioPerformanceFutureApiInput,
} from '@console/core-api/models/portfolioMgmt/portfolioPerformance.models';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetObject from '@snipsonian/core/cjs/object/verification/isSetObject';
import { api } from 'api';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { getStore } from 'state';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { updatePortfolioPerformancePageVars } from 'state/ui/uiPages.actions';
import { getPortfolioPerformancePageVars } from 'state/ui/uiPages.selectors';
import {
    getFuturePerformanceFilterBasedOnPageVars,
    getPastPerformanceFilterBasedOnPageVars,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioPerformance/portfolioPerformanceFilterUtils';
import { getEntitiesManager } from '../entitiesManager';

export const portfolioPerformanceEntity = getEntitiesManager().registerEntity<IEnhancedPortfolioPerformance>({
    asyncEntityKey: AsyncEntityKeys.portfolioPerformance,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [],
});

export const selectPortfolioFuturePerformanceEntityData = () => portfolioPerformanceEntity.select()?.data?.future;
export const selectPortfolioPastPerformanceEntityData = () => portfolioPerformanceEntity.select()?.data?.past;
export const selectPortfolioStatusEntityData = () => portfolioPerformanceEntity.select()?.data?.past?.status;

type TFetchEnhancedPortfolioPerformancePastApiInput =
    IFetchPortfolioPerformanceBaseApiInput & TFetchEnhancedPortfolioPerformancePastApiBody;

export function triggerFetchPortfolioPastPerformance({
    portfolioId,
    forceRefresh = false,
    ...pastInput
}: TFetchEnhancedPortfolioPerformancePastApiInput) {
    return triggerFetchPortfolioPerformanceGeneric({
        apiInput: {
            portfolioId,
            forceRefresh,
            past: {
                sampleFrequency: SampleFrequencyPerformancePast.D,
                ...getPastPerformanceFilterBasedOnPageVars(getPortfolioPerformancePageVars(getStore().getState())),
                ...pastInput,
            },
        },
        notificationsToTrigger: [StateChangeNotification.PORTFOLIO_PERFORMANCE_PAST_DATA],
        onSuccess: ({ apiInput, apiResult, dispatch }) => {
            if (!apiInput?.past?.startDate) {
                /* period SINCE_START */
                const portfolioStartDate = getFirstItemOfArray(apiResult?.past?.date);

                dispatch(updatePortfolioPerformancePageVars({
                    past: {
                        portfolioStartDate,
                    },
                }, [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_FILTER]));
            }
        },
    });
}

export function triggerFetchPortfolioFuturePerformance({
    portfolioId,
    forceRefresh = false,
    ...futureInput
}: TFetchPortfolioPerformanceFutureApiInput) {
    return triggerFetchPortfolioPerformanceGeneric({
        apiInput: {
            portfolioId,
            forceRefresh,
            future: {
                sampleFrequency: SampleFrequencyPerformanceFuture.M,
                quantiles: [0.2, 0.5, 0.8],
                ...getFuturePerformanceFilterBasedOnPageVars(getPortfolioPerformancePageVars(getStore().getState())),
                ...futureInput,
            },
        },
        notificationsToTrigger: [StateChangeNotification.PORTFOLIO_PERFORMANCE_FUTURE_DATA],
    });
}

type TFetchPortfolioPerformanceGenericApiInput =
    IFetchPortfolioPerformanceBaseApiInput & IFetchEnhancedPortfolioPerformanceApiBody;

function triggerFetchPortfolioPerformanceGeneric({
    apiInput,
    notificationsToTrigger,
    onSuccess,
}: {
    apiInput: TFetchPortfolioPerformanceGenericApiInput;
    notificationsToTrigger: StateChangeNotification[];
    // eslint-disable-next-line max-len
    onSuccess?: TOnAsyncEntityOperationSuccess<IState, TFetchEnhancedPortfolioPerformanceApiInput, IEnhancedPortfolioPerformance>;
}) {
    const portfolioId = apiInput.portfolioId || getCurrentRouteParam(getStore().getState(), 'portfolioId');
    const { forceRefresh, ...remainingApiInput } = apiInput;

    return portfolioPerformanceEntity.async.fetch<TFetchEnhancedPortfolioPerformanceApiInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolioPerformance,
        apiInputSelector: () => ({
            ...remainingApiInput,
            portfolioId,
        }),
        resetDataOnTriggerMode: () => doesPortfolioIdInEntityDataDifferFromGiven(portfolioId),
        refreshMode: () => forceRefresh
            || isRequestedPerformanceMissingInEntityData(remainingApiInput)
            || doesPortfolioIdInEntityDataDifferFromGiven(portfolioId),
        mapApiResponse: ({ response }) => mergeNewPerformanceDataIntoExisting(response),
        notificationsToTrigger,
        onSuccess,
    });
}

function isRequestedPerformanceMissingInEntityData({
    past,
    future,
}: TFetchPortfolioPerformanceGenericApiInput): boolean {
    return (isSetObject(past) && !isObjectWithProps(selectPortfolioPastPerformanceEntityData()))
        || (isSetObject(future) && !isObjectWithProps(selectPortfolioFuturePerformanceEntityData()));
}

function doesPortfolioIdInEntityDataDifferFromGiven(givenPortfolioId: TApiEntityId): boolean {
    const futurePerformanceData = selectPortfolioFuturePerformanceEntityData();
    const pastPerformanceData = selectPortfolioPastPerformanceEntityData();

    return ((isSet(futurePerformanceData?.portfolioId) && futurePerformanceData.portfolioId !== givenPortfolioId)
        || (isSet(pastPerformanceData?.portfolioId) && pastPerformanceData.portfolioId !== givenPortfolioId));
}

function mergeNewPerformanceDataIntoExisting({
    past: incomingPastPerformanceData,
    future: incomingFuturePerformanceData,
}: IEnhancedPortfolioPerformance): IEnhancedPortfolioPerformance {
    const existingPastPerformanceData = selectPortfolioPastPerformanceEntityData();
    const existingFuturePerformanceData = selectPortfolioFuturePerformanceEntityData();

    const portfolioStatus = {
        ...existingPastPerformanceData?.status,
        ...incomingPastPerformanceData?.status,
    };

    return {
        past: {
            ...existingPastPerformanceData,
            ...incomingPastPerformanceData,
            ...(isObjectWithProps(portfolioStatus) ? { portfolioStatus } : {}),
        },
        future: {
            ...existingFuturePerformanceData,
            ...incomingFuturePerformanceData,
        },
    };
}
