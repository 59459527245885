import React, { ReactText, useState, useEffect } from 'react';
import { TLabel } from 'models/general.models';
import IconButtonCollapse from 'views/common/buttons/IconButtonCollapse';
import ContentTitle from 'views/common/layout/ContentTitle';
import Tooltip from 'views/common/widget/Tooltip';
import { WarningIcon } from 'views/common/icons';
import clsx from 'clsx';
import { IInfoIconTooltipProps } from 'views/common/widget/InfoIconTooltip.models';
import { makeStyles, mixins } from 'views/styling';
import { APP_COLORS } from 'config/styling/colors';
import isSet from '@snipsonian/core/cjs/is/isSet';

interface ICollapsableSectionProps {
    id: ReactText;
    isOpen?: boolean; /** ONLY provide this WHEN the collapsable state is managed OUTSIDE of this component */
    onChange?: (open: boolean) => void; /** to be used in combo with 'isOpen' */
    defaultClosed?: boolean; /** default state when collapsable state is managed INSIDE this component */
    className?: string;
    hasError?: boolean;
    title: TLabel;
    infoTooltip?: IInfoIconTooltipProps;
    children?: React.ReactNode;
    showBottomBorderWhenOpen?: boolean; /** default false (= bottom border only shown when closed) */
}

const useStyles = makeStyles((theme) => ({
    CollapsableSection: {
        paddingTop: theme.spacing(1),

        '&.ClosedSection': {
            paddingTop: theme.spacing(1),
            height: 48,
            ...mixins.borderBottom({ width: 1, style: 'solid', color: APP_COLORS.GREY['100'] }),
        },
        '&.BorderedOpenSection': {
            ...mixins.borderBottom({ width: 1, style: 'solid', color: APP_COLORS.GREY['100'] }),
        },
        '&:last-child': {
            paddingTop: theme.spacing(1),
        },
        '& .SectionTitle': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            paddingBottom: theme.spacing(1),
            cursor: 'pointer',
        },
        '& .SectionFields': {
            paddingBottom: theme.spacing(2),
        },
        '& .__row': {
            ...mixins.flexRowCenterRight(),
        },
        '& .warningIcon': {
            transform: 'translate(0px, 5px)',
        },
    },
}));

export default function CollapsableSection({
    id,
    isOpen,
    onChange,
    className,
    title,
    hasError,
    infoTooltip,
    children,
    defaultClosed,
    showBottomBorderWhenOpen = false,
}: ICollapsableSectionProps) {
    const [open, setOpen] = useState(!defaultClosed);
    const classes = useStyles();
    const isSectionOpen = isSet(isOpen) ? isOpen : open;

    useEffect(() => {
        if (isSet(defaultClosed)) {
            if (!defaultClosed !== open) {
                setOpen(!defaultClosed);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultClosed]);

    return (
        <div
            className={clsx(
                classes.CollapsableSection,
                className,
                !isSectionOpen && 'ClosedSection',
                showBottomBorderWhenOpen && isSectionOpen && 'BorderedOpenSection',
            )}
        >
            <div
                className="SectionTitle"
                role="button"
                tabIndex={0}
                onClick={onCollapseChange}
                onKeyDown={() => null}
            >
                <ContentTitle
                    label={title}
                    infoTooltip={infoTooltip}
                    variant={isSectionOpen ? 'list-section-open' : 'list-section'}
                />
                <div className="__row">
                    {hasError && (
                        <Tooltip label="common.section_info.error" className="warningIcon">
                            <WarningIcon />
                        </Tooltip>
                    )}
                    <IconButtonCollapse
                        id={`open-section-button_${id}`}
                        isCollapsed={!isSectionOpen}
                        onCollapseChange={onCollapseChange}
                    />
                </div>
            </div>
            {isSectionOpen && (
                <div className="SectionFields">
                    {children}
                </div>
            )}
        </div>
    );

    function onCollapseChange() {
        if (isSet(onChange)) {
            onChange(!isSectionOpen);
        } else {
            setOpen(!isSectionOpen);
        }
    }
}
