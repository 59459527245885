import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IInstrumentPerformancePastMarkedDate } from 'models/ui/instrument.ui.models';
import { IChartDimensions } from 'models/chart.models';
import { CHART, MODAL_CHART_DIMENTIONS } from 'config/styling/chart';
import { instrumentPerformancePastEntity } from 'state/entities/portfolioMgmt/instrumentPerformancePast';
import { getInstrumentPerformancePastPageVars } from 'state/ui/uiPages.selectors';
import { formatDate } from '@console/common/utils/date/formatDate';
import calculateExpectedReturn from 'utils/entities/portfolioMgmt/calculateExpectedReturn';
import GenericPerformanceGraph, { IGenericPerformanceGraphProps } from 'views/common/charts/GenericPerformanceGraph';
import { observe } from 'views/observe';
import { IMarkedDateData, IMarkedDateValueItem } from 'views/common/charts/MarkedDateBox';
import { getInstrumentsPerformanceChartConfig } from './instrumentPerformancePastChartConfig';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    // eslint-disable-next-line max-len
    extends Pick<IGenericPerformanceGraphProps<IInstrumentPerformancePastMarkedDate>, 'baseCurrency' | 'onChangedMarkedDate'> {
}

const TRANSLATION_PREFIX = 'portfolio_mgmt.policies.detail.configuration.simulation';
const LABEL_PREFIX = `${TRANSLATION_PREFIX}.tabs.expected_performance.section.instruments`;

function PolicySimulationExpectedPerformanceChart({
    baseCurrency,
    onChangedMarkedDate,
}: IPublicProps) {
    const { data: performanceData } = instrumentPerformancePastEntity.select();

    const instrumentInfoPrefix = performanceData.instrumentInfo[performanceData.isin];
    const expectedValue = getLastItemOfArray(performanceData.value[performanceData.isin]);
    const initialValue = getFirstItemOfArray(performanceData.value[performanceData.isin]);

    if (!expectedValue) {
        return (
            <div className="policyInfo">
                <Translate
                    msg={`${LABEL_PREFIX}.instrumentNotAvailable`}
                    placeholders={{
                        instrument: instrumentInfoPrefix.name,
                    }}
                    raw
                />
            </div>
        );
    }

    const expectedReturn = calculateExpectedReturn(expectedValue, initialValue, 10);

    return (
        <>
            <div className="policyInfo">
                <Translate
                    msg={`${LABEL_PREFIX}.instrumentReturn`}
                    placeholders={{
                        instrument: instrumentInfoPrefix.name,
                        return: expectedReturn,
                    }}
                    raw
                />
            </div>
            <GenericPerformanceGraph
                baseCurrency={baseCurrency}
                chartDimensions={MODAL_CHART_DIMENTIONS}
                getChartConfig={(dimensions: IChartDimensions) => getInstrumentsPerformanceChartConfig({
                    chartDimensions: dimensions,
                    performanceData,
                })}
                markedDateBoxNotifications={
                    [StateChangeNotification.UI_PAGE_INSTRUMENT_PERFORMANCE_PAST_MARKED_DATE]
                }
                markedDateDataSelector={markedDateDataSelector}
                onChangedMarkedDate={onChangedMarkedDate}
            />
        </>
    );

    function markedDateDataSelector(state: IState): IMarkedDateData {
        const { markedDate } = getInstrumentPerformancePastPageVars(state);

        if (!markedDate || markedDate.values.instrument === null) {
            return null;
        }

        const valueItems: IMarkedDateValueItem[] = [{
            label: '',
            labelColor: CHART.FUTURE.OPTIMISTIC_COLOR,
            amount: markedDate.values.instrument,
        }];

        return {
            formattedDate: formatDate({ date: markedDate.date }),
            valueItems,
        };
    }
}

export default observe<IPublicProps>(
    [StateChangeNotification.POLICY_PERFORMANCE_FUTURE_DATA],
    PolicySimulationExpectedPerformanceChart,
);
