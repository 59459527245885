import React from 'react';
import InputSearchableSelectField,
{
    IInputSearchableSelectFieldProps,
    IOnChangeSearchableSelectProps,
} from './InputSearchableSelectField';

export interface IInputSearchableMultiSelectFieldProps
    extends Omit<IInputSearchableSelectFieldProps<string[]>, 'value' | 'onChange'> {
    values: string[];
    onChange: (onChangeProps: IOnChangeSearchableMultiSelectProps) => void;
}

export interface IOnChangeSearchableMultiSelectProps extends Omit<IOnChangeSearchableSelectProps<string[]>, 'value'> {
    values: string[];
}

export default function InputSearchableMultiSelectField({
    values,
    onChange,
    ...otherProps
}: IInputSearchableMultiSelectFieldProps) {
    return (
        <InputSearchableSelectField<string[]>
            value={values}
            onChange={({ value }) => onChange({ values: value })}
            {...otherProps}
        />
    );
}
