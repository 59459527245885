import isSet from '@snipsonian/core/cjs/is/isSet';
import { ONE_MINUTE_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import isBoolean from '@snipsonian/core/cjs/is/isBoolean';
import extendPromise, { IExtendedPromise } from '@snipsonian/core/cjs/promise/extendPromise';
import executePeriodicallyWhenBrowserTabActive
    from '@snipsonian/browser/cjs/tabVisibility/executePeriodicallyWhenBrowserTabActive';
import { TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { ISingleUserApiInput, TUser } from '@console/core-api/models/userMgmt/user.models';
import { ISingleUserGroupApiInput, TUserGroup } from '@console/core-api/models/userMgmt/userGroup.models';
import { ISingleGoalApiInput, TGoal, TGoalsData } from '@console/core-api/models/portfolioMgmt/goal.models';
import { ISingleHorizonApiInput, THorizon, THorizonsData } from '@console/core-api/models/portfolioMgmt/horizon.models';
import { ISinglePolicyApiInput } from '@console/core-api/models/portfolioMgmt/policy.models';
import {
    ISingleRiskProfileApiInput,
    TRiskProfile,
    TRiskProfilesData,
} from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { TApiEntityId } from '@console/core-api/models/api.models';
import {
    IEnhancedPortfolioOptimization,
    TFetchEnhancedPortfolioOptimizationApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolioOptimization.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { api } from 'api';
import {
    ApiCacheKey,
    IApiCacheRef,
    IApiDetailCacheRef,
    IApiEntityResponseCache,
    IApiListCacheRef,
    IEntityCacheExpiry,
    IFetchForCacheConfig,
    TApiResponseCache,
    TShouldRefresh,
} from './types';

const apiCache: TApiResponseCache<unknown, unknown> = {
    goals: initApiEntityResponseCache({ listExpiryInMins: 10 }),
    horizons: initApiEntityResponseCache({ listExpiryInMins: 10 }),
    policies: initApiEntityResponseCache({ detailExpiryInMins: 10 }),
    portfolioOptimizations: initApiEntityResponseCache({ detailExpiryInMins: 10 }),
    riskProfiles: initApiEntityResponseCache({ listExpiryInMins: 10 }),
    users: initApiEntityResponseCache({ detailExpiryInMins: 10 }),
    userGroups: initApiEntityResponseCache({ detailExpiryInMins: 10 }),
};

export const apiCacheManager = {
    clearAllGoals() {
        updateApiCache({ apiKey: ApiCacheKey.goals });
    },
    fetchAllGoals() {
        return fetchList<TGoalsData>({
            apiKey: ApiCacheKey.goals,
            onFetch: () => api.goals.fetchAllGoals(),
        });
    },
    fetchGoal({ goalId }: ISingleGoalApiInput): Promise<TGoal> {
        /* as there aren't much goals, we first fetch them all */
        return apiCacheManager.fetchAllGoals()
            .then((allGoals) =>
                allGoals.results.find((goal) => goal.id === goalId));
    },
    clearAllHorizons() {
        updateApiCache({ apiKey: ApiCacheKey.horizons });
    },
    fetchAllHorizons() {
        return fetchList<THorizonsData>({
            apiKey: ApiCacheKey.horizons,
            onFetch: () => api.horizons.fetchAllHorizons(),
        });
    },
    fetchHorizon({ horizonId }: ISingleHorizonApiInput): Promise<THorizon> {
        /* as there aren't much horizons, we first fetch them all */
        return apiCacheManager.fetchAllHorizons()
            .then((allHorizons) =>
                allHorizons.results.find((horizon) => horizon.id === horizonId));
    },
    clearPolicy({ policyId }: ISinglePolicyApiInput) {
        updateApiCache({
            apiKey: ApiCacheKey.policies,
            detail: {
                entityKey: policyId,
            },
        });
    },
    resetPolicy(policy: TEnhancedPolicy) {
        updateApiCache({
            apiKey: ApiCacheKey.policies,
            detail: {
                entityKey: policy.id,
                newData: policy,
            },
        });
    },
    fetchPolicy({ policyId }: ISinglePolicyApiInput) {
        return fetchDetail<TEnhancedPolicy>({
            apiKey: ApiCacheKey.policies,
            entityKey: policyId,
            onFetch: () => api.bff.policies.fetchEnhancedPolicyDetails({ policyId }),
        });
    },
    clearPortfolioOptimization({ portfolioId }: { portfolioId: TApiEntityId }) {
        updateApiCache({
            apiKey: ApiCacheKey.portfolioOptimizations,
            detail: {
                entityKey: portfolioId,
            },
        });
    },
    fetchPortfolioOptimizationLatest(apiInput: Omit<TFetchEnhancedPortfolioOptimizationApiInput, 'optimizationId'>) {
        return fetchDetail<IEnhancedPortfolioOptimization>({
            apiKey: ApiCacheKey.portfolioOptimizations,
            entityKey: apiInput.portfolioId,
            onFetch: () => api.bff.portfolios.fetchEnhancedPortfolioOptimization(apiInput),
            shouldRefresh: ({ prevResponse, millisSinceResolved }) =>
                /* a PENDING (originally a 202 ~ optimization not ready yet) will only be re-tried if it was
                   more than 3 minutes ago (to prevent too much re-fetches on each re-render) */
                (prevResponse.status === EnhancedOptimizationStatus.PENDING)
                && (millisSinceResolved >= 3 * ONE_MINUTE_IN_MILLIS),
        });
    },
    clearAllRiskProfiles() {
        updateApiCache({ apiKey: ApiCacheKey.riskProfiles });
    },
    fetchAllRiskProfiles() {
        return fetchList<TRiskProfilesData>({
            apiKey: ApiCacheKey.riskProfiles,
            onFetch: () => api.riskProfiles.fetchAllRiskProfiles(),
        });
    },
    fetchRiskProfile({ riskProfileId }: ISingleRiskProfileApiInput): Promise<TRiskProfile> {
        /* as there aren't much risk profiles, we first fetch them all */
        return apiCacheManager.fetchAllRiskProfiles()
            .then((allRiskProfiles) =>
                allRiskProfiles.results.find((riskProfile) => riskProfile.id === riskProfileId));
    },
    clearUser({ userId }: ISingleUserApiInput) {
        updateApiCache({
            apiKey: ApiCacheKey.users,
            detail: {
                entityKey: userId,
            },
        });
    },
    resetUser(user: TUser) {
        updateApiCache({
            apiKey: ApiCacheKey.users,
            detail: {
                entityKey: user.id,
                newData: user,
            },
        });
    },
    fetchUser({ userId }: ISingleUserApiInput) {
        return fetchDetail<TUser>({
            apiKey: ApiCacheKey.users,
            entityKey: userId,
            onFetch: () => api.users.fetchUserDetails({ userId }),
        });
    },
    clearUserGroup({ userGroupId }: ISingleUserGroupApiInput) {
        updateApiCache({
            apiKey: ApiCacheKey.userGroups,
            detail: {
                entityKey: userGroupId,
            },
        });
    },
    resetUserGroup(userGroup: TUserGroup) {
        updateApiCache({
            apiKey: ApiCacheKey.userGroups,
            detail: {
                entityKey: userGroup.id,
                newData: userGroup,
            },
        });
    },
    fetchUserGroup({ userGroupId }: ISingleUserGroupApiInput) {
        return fetchDetail<TUserGroup>({
            apiKey: ApiCacheKey.userGroups,
            entityKey: userGroupId,
            onFetch: () => api.userGroups.fetchUserGroupDetails({ userGroupId }),
        });
    },
};

/**
 * To reset a specific cache, e.g. for ApiCacheKey.goals
 * - This will always clear the list (if available)
 * - Additionally, if the optional detail.entityKey is provided as input:
 *     - then, if the new data is not provided, only that detail entity will be cleared
 *     - but if the new data is provided, then that new data will be set in the cache
 *   If detail.entityKey not provided, then all detail entities that are available in this cache will be cleared.
 */
export function updateApiCache<ApiDetailResponse = unknown>({
    apiKey,
    detail,
}: IApiCacheRef & {
    detail?: {
        entityKey: string;
        newData?: ApiDetailResponse;
    };
}) {
    if (apiCache[apiKey]) {
        apiCache[apiKey].list = null;

        if (isSet(detail)) {
            if (isSet(detail.newData)) {
                apiCache[apiKey].details[detail.entityKey] = extendPromise(
                    Promise.resolve(detail.newData),
                );
            } else {
                delete apiCache[apiKey].details[detail.entityKey];
            }
        } else {
            apiCache[apiKey].details = {};
        }
    }
}

executePeriodicallyWhenBrowserTabActive({
    toBeExecuted: cleanupOutdatedCaches,
    intervalInMillis: 30 * ONE_MINUTE_IN_MILLIS,
    executeImmediatelyInActiveTab: true,
});

async function fetchList<ApiListResponse>({
    apiKey,
    onFetch,
    shouldRefresh,
}: IApiListCacheRef & IFetchForCacheConfig<ApiListResponse>): Promise<ApiListResponse> {
    const shouldFetchResult = shouldFetchList<ApiListResponse>({ apiKey, shouldRefresh });

    const shouldFetch = isBoolean(shouldFetchResult)
        ? shouldFetchResult
        : await shouldFetchResult;

    if (shouldFetch) {
        apiCache[apiKey].list = extendPromise(onFetch());
    }

    return getApiListCache<ApiListResponse>({ apiKey });
}

async function fetchDetail<ApiDetailResponse>({
    apiKey,
    entityKey,
    onFetch,
    shouldRefresh,
}: IApiDetailCacheRef & IFetchForCacheConfig<ApiDetailResponse>): Promise<ApiDetailResponse> {
    const shouldFetchResult = shouldFetchDetail<ApiDetailResponse>({ apiKey, entityKey, shouldRefresh });

    const shouldFetch = isBoolean(shouldFetchResult)
        ? shouldFetchResult
        : await shouldFetchResult;

    if (shouldFetch) {
        apiCache[apiKey].details[entityKey] = extendPromise(onFetch());
    }

    return getApiDetailCache<ApiDetailResponse>({ apiKey, entityKey });
}

function shouldFetchList<ApiListResponse>({
    apiKey,
    shouldRefresh,
}: IApiListCacheRef & { shouldRefresh?: TShouldRefresh<ApiListResponse> }): boolean | Promise<boolean> {
    const listCache = getApiListCache<ApiListResponse>({ apiKey });

    return shouldFetchGeneric({
        expiryInMins: apiCache[apiKey].listExpiryInMins,
        cacheItem: listCache,
        shouldRefresh,
    });
}

function shouldFetchDetail<ApiDetailResponse>({
    apiKey,
    entityKey,
    shouldRefresh,
}: IApiDetailCacheRef & { shouldRefresh?: TShouldRefresh<ApiDetailResponse> }): boolean | Promise<boolean> {
    if (!isSet(entityKey)) {
        return false;
    }

    const detailCache = getApiDetailCache<ApiDetailResponse>({ apiKey, entityKey });

    return shouldFetchGeneric({
        expiryInMins: apiCache[apiKey].detailExpiryInMins,
        cacheItem: detailCache,
        shouldRefresh,
    });
}

function shouldFetchGeneric<ApiResponse>({
    expiryInMins = 0,
    cacheItem,
    shouldRefresh,
}: {
    expiryInMins?: number;
    cacheItem: IExtendedPromise<ApiResponse>;
    shouldRefresh?: TShouldRefresh<ApiResponse>;
}): boolean | Promise<boolean> {
    if (!cacheItem
        || cacheItem.wasResolvedPriorTo({
            minutesAgo: expiryInMins,
        })) {
        return true;
    }

    if (isSet(shouldRefresh)) {
        if (isBoolean(shouldRefresh)) {
            return shouldRefresh;
        }

        if (cacheItem.isResolved()) {
            return new Promise<boolean>((resolve) => {
                cacheItem.then((apiResponse) => {
                    resolve(
                        shouldRefresh({
                            prevResponse: apiResponse,
                            millisSinceResolved: cacheItem.getMillisSinceResolved(),
                        }),
                    );
                });
            });
        }
    }

    /* by default try again if it previously failed */
    return cacheItem.isRejected();
}

function getApiListCache<ApiListResponse>({
    apiKey,
}: IApiListCacheRef): IExtendedPromise<ApiListResponse> {
    return apiCache[apiKey].list as IExtendedPromise<ApiListResponse>;
}

function getApiDetailCache<ApiDetailResponse>({
    apiKey,
    entityKey,
}: IApiDetailCacheRef): IExtendedPromise<ApiDetailResponse> {
    return apiCache[apiKey].details[entityKey] as IExtendedPromise<ApiDetailResponse>;
}

function initApiEntityResponseCache<ApiListResponse, ApiDetailResponse>({
    listExpiryInMins = 0,
    detailExpiryInMins = 0,
}: IEntityCacheExpiry): IApiEntityResponseCache<ApiListResponse, ApiDetailResponse> {
    return {
        listExpiryInMins,
        detailExpiryInMins,
        list: null,
        details: {},
    };
}

function cleanupOutdatedCaches() {
    Object.values(apiCache)
        .forEach((apiEntityCache) => {
            /** cleanup outdated list */
            if (apiEntityCache.list && apiEntityCache.list.wasResolvedPriorTo({
                minutesAgo: apiEntityCache.listExpiryInMins || 0,
            })) {
                // eslint-disable-next-line no-param-reassign
                apiEntityCache.list = null;
            }

            /** cleanup outdated details */
            Object.keys(apiEntityCache.details)
                .forEach((entityKey) => {
                    if (apiEntityCache.details[entityKey] && apiEntityCache.details[entityKey].wasResolvedPriorTo({
                        minutesAgo: apiEntityCache.detailExpiryInMins || 0,
                    })) {
                        // eslint-disable-next-line no-param-reassign
                        delete apiEntityCache.details[entityKey];
                    }
                });
        });
}
