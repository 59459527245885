import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    hasFetchFailed,
    hasFetchSucceeded,
    isAnyAsyncOperationBusy,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { addOrRemoveItemFromArray } from '@console/common/utils/array/addOrRemoveItemFromArray';
import { TI18nLabelOrString } from 'models/general.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISimpleFilterToggles, TListSearchMode, UiPageKey } from 'models/state/ui.models';
import { INavigateToRoute } from 'models/routing.models';
import { IActiveSortColumn, ISelectData, TOnSelectAction, TDataItemId } from 'models/list.models';
import { FIRST_PAGE_NR, DEFAULT_PAGE_ITEM_LIMIT } from '@console/core-api/config/coreApi.config';
import { areListPageFiltersSet, getListPageVars } from 'state/ui/uiPages.selectors';
import {
    updateListSearchMode,
    updateListSimpleSearch,
    updateListAdvancedSearch,
    updateListPagination,
    updateListSorting,
} from 'state/ui/uiPages.actions';
import { isI18nLabelOrString } from 'utils/i18n/i18nUtils';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import ContentBox, { IContentBoxProps } from 'views/common/layout/ContentBox';
import { initEntityWrapper, IEntityWrapperProps } from 'views/common/widget/EntityWrapper';
import DataSearch, {
    ISimpleFilterProps,
    IAdvancedFilterProps,
    IAdvancedWithSchemaFilterProps,
    ISimpleExtraFilters,
} from 'views/common/list/DataSearch';
import DataPagination, {
    getPaginationType,
    IDataPaginationProps,
    IOnChangeItemsPerPageProps,
    IOnChangePageNrProps,
} from 'views/common/list/DataPagination';
import { extractDataItemsOfSelectedPage } from 'views/common/list/dataUtils';
import IconButtonCreate from 'views/common/buttons/IconButtonCreate';
import { redirectTo } from 'views/routes';
import ShowIfAllowed from 'views/auth/ShowIfAllowed';
import { IDynamicTitleLabelConfig } from 'views/common/layout/PageTitleBasedOnState';
import ActionButtons, { IActionItem } from '../buttons/ActionButtons';
import InputCheckboxField, { IOnChangeCheckboxProps } from '../inputs/base/InputCheckboxField';
import Tooltip from '../widget/Tooltip';
import ListActionsHeader, { IListAction } from './ListActionsHeader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IListPageProps<AdvancedFilters = any, EntityData = any, ListItem = any> {
    className?: string;
    uiPageKey: UiPageKey;
    notificationToTriggerOnUiVarChanges: StateChangeNotification;
    box?: {
        title: TI18nLabelOrString | IDynamicTitleLabelConfig;
    };
    list: IListConfig<EntityData, ListItem>;
    search?: ISearchConfig<AdvancedFilters>;
    pagination?: Pick<IDataPaginationProps, 'onChangeItemsPerPage'> & {
        getPaginationInfoSelector: (state: IState, listItems: ListItem[]) => IPaginationInfo;
        onChangePageNr: (onChangePageNrProps: IOnChangePageNrWithOffsetProps) => void;
    };
    sort?: ISortConfig;
    create?: {
        toRoute: INavigateToRoute;
        tooltip: TI18nLabelOrString;
    };
    actions?: IActionItem[];
    listActions?: IListAction[];
    noResultsRender?: () => ReactElement;
    // eslint-disable-next-line max-len
    selectConfig?: TSelectConfig<EntityData, ListItem>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ISelectConfigBase<EntityData = any, ListItem = any> {
    maxItems?: number;
    withSelectAll?: boolean;
    selectActions?: ISelectActionItem<EntityData, ListItem>[];
    isItemSelectable?: (listItem: ListItem) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TSelectConfig<EntityData = any, ListItem = any> =
    ISelectConfigManagedInternally<EntityData, ListItem> | ISelectConfigManagedExternally<EntityData, ListItem>;

// This config internally stores the selected items
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISelectConfigManagedInternally<EntityData = any, ListItem = any>
    extends ISelectConfigBase<EntityData, ListItem> {
    // default 'id'. Use this property if entity id !== listItem id
    // for example if portfolio.user was used for the listItem id instead of portfolio.id
    listItemId?: string;
}

// This config allows for more customisation, selected ids are managed outside the component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISelectConfigManagedExternally<EntityData = any, ListItem = any>
    extends ISelectConfigBase<EntityData, ListItem> {
    onChange: (selectedItemIds: TDataItemId[]) => void;
    selectedItemIds: TDataItemId[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IListConfig<EntityData = any, ListItem = any, ExtraData = unknown>
    extends Omit<IEntityWrapperProps, 'entityDataTransformer'> {
    toListItems?: TToListItems<EntityData, ListItem, ExtraData>;
}

export type TToListItems<EntityData, ListItem, ExtraData = unknown> =
    (toListItemsProps: IToListItemsProps<EntityData, ExtraData>) => ListItem[];

export interface IToListItemsProps<EntityData, ExtraData> {
    entityData: EntityData;
    extraData: ExtraData;
}

export interface ISearchConfig<AdvancedFilters> {
    simple?: TListPageSimpleFilterProps;
    advanced?: TListPageAdvancedFilterProps<AdvancedFilters>;
    advancedWithSchema?: TListPageAdvancedWithSchemaFilterProps<AdvancedFilters>;
}

export type TListPageSimpleFilterProps = Omit<ISimpleFilterProps, 'onSetSearchValue' | 'extraFilters'> & {
    extraFilters?: Omit<ISimpleExtraFilters, 'onSetFilterToggles'>;
};

export type TListPageAdvancedFilterProps<AdvancedFilters> =
    Omit<IAdvancedFilterProps<AdvancedFilters>, 'onSetFilterValues'>;

export type TListPageAdvancedWithSchemaFilterProps<AdvancedFilters> =
    Omit<IAdvancedWithSchemaFilterProps<AdvancedFilters>, 'onSetFilterValues'>;

export interface IOnChangePageNrWithOffsetProps {
    pageNr: number;
    offset: string;
    itemsPerPage: number;
}

export interface IPaginationInfo {
    pageNr?: number;
    nrOfItems: number;
    totalNrOfItems: number;
    offset?: string;
}

export interface ISortConfig {
    getSortConfigFunctionsCallback: (sortConfigFunctions: ISortConfigFunctions) => void;
    onChangeSortColumn: (onChangeSortColumnProps: IOnChangeSortColumnProps) => void;
}

export interface ISortConfigFunctions {
    /* saves the selected column in the ui page state */
    setStateOnSortColumnChange: (newSortColumn: IActiveSortColumn<never>) => void;
    /* returns the current selected sort column (if any) */
    getActiveSortColumn: () => IActiveSortColumn<unknown>;
}

export interface IOnChangeSortColumnProps extends Pick<IOnChangePageNrWithOffsetProps, 'itemsPerPage'> {
    sortColumn: IActiveSortColumn<unknown>;
}

interface ISelectActionItem<EntityData, ListItem> extends Omit<IActionItem, 'onExecute'> {
    onExecute: TOnSelectAction<EntityData, ListItem>;
}

export interface IExtraSelectData<ListItem> {
    selectData: ISelectData<ListItem>;
}

const useStyles = makeStyles((theme) => ({
    ListPage: {
        position: 'relative',

        '& .row': {
            ...mixins.flexRow({ alignMain: 'space-between' }),
        },

        '& .listActions': {
            ...mixins.widthMax(),
            ...mixins.flexRowCenterRight(),
        },

        '& .selectActions': {
            ...mixins.flexRowCenterLeft(),
            '& > *': {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                '&:first-child': {
                    paddingLeft: theme.spacing(3),
                },
                '& button': {
                    margin: 0,
                },
            },
        },

        '& .actions': {
            ...mixins.flexRowCenterRight(),
            position: 'absolute',
            top: -56,
            right: 0,
        },
    },
}));

function ListPage({
    className,
    uiPageKey,
    notificationToTriggerOnUiVarChanges,
    box,
    list,
    search,
    pagination,
    sort,
    create,
    actions,
    listActions,
    state,
    dispatch,
    noResultsRender,
    selectConfig,
}: IObserveProps & IListPageProps) {
    const classes = useStyles();
    const [selectedItemIdsLocalState, setSelectedItemIdsLocalState] = useState<TDataItemId[]>([]);
    const asyncEntity = list.asyncEntitySelector(state);
    const extraData = list.extraDataSelector ? list.extraDataSelector(state) : null;
    const listItems = list.toListItems
        ? list.toListItems({ entityData: asyncEntity.data, extraData })
        : asyncEntity.data as unknown[];

    const isListDataAvailable = isSet(asyncEntity.data) || hasFetchFailed(asyncEntity);
    const isLoading = isAnyAsyncOperationBusy(asyncEntity);
    const isFetched = hasFetchSucceeded(asyncEntity);
    const listPageVars = getListPageVars(state, uiPageKey);

    const paginationInfo = pagination
        ? pagination.getPaginationInfoSelector(state, listItems)
        : ({} as IPaginationInfo);
    const nrOfItemsPerPage = listPageVars.pagination && listPageVars.pagination.itemsPerPage;
    const showNoResults = noResultsRender && isFetched && (!listItems || listItems.length <= 0)
        && !areListPageFiltersSet(state, uiPageKey);
    const listItemsOnCurrentPage = entityDataTransformer();
    const selectableListItemsOnCurrentPage = selectConfig?.isItemSelectable
        ? listItemsOnCurrentPage.filter(selectConfig.isItemSelectable)
        : listItemsOnCurrentPage;
    const areThereAnySelectableItemsOnCurrentPage = selectableListItemsOnCurrentPage.length > 0;

    const selectedItemIds = selectConfig ?
        isSelectConfigManagedExternallyTypeGuard(selectConfig) ?
            selectConfig.selectedItemIds : getDisplayedListItemsSelectedLocalIds()
        : [];
    const setSelectedItemIds = selectConfig ?
        isSelectConfigManagedExternallyTypeGuard(selectConfig) ?
            selectConfig.onChange : (ids: TDataItemId[]) => setSelectedItemIdsLocalState(ids)
        : null;

    const EntityWrapper = initEntityWrapper({
        notifications: [],
    });

    const WrapperElem = box
        ? ContentBox
        : PseudoContentBox;

    if (sort) {
        sort.getSortConfigFunctionsCallback({
            setStateOnSortColumnChange,
            getActiveSortColumn,
        });
    }

    return (
        <WrapperElem
            className={clsx(classes.ListPage, className, '__listPageTitle')}
            titleLabel={box && isI18nLabelOrString(box.title) && box.title}
            titleLabelSelector={box && !isI18nLabelOrString(box.title) && box.title}
            noHorizontalPadding
        >
            {showNoResults ? noResultsRender() : (
                <>
                    {search && isListDataAvailable && (
                        <DataSearch
                            listPageVars={listPageVars}
                            onSetSearchMode={setSearchMode}
                            simple={search.simple
                                ? {
                                    ...search.simple,
                                    onSetSearchValue: setSimpleSearchValue,
                                    extraFilters: search.simple.extraFilters
                                        ? {
                                            ...search.simple.extraFilters,
                                            onSetFilterToggles: setSimpleFilterToggles,
                                        }
                                        : undefined,
                                }
                                : undefined}
                            advanced={search.advanced
                                ? {
                                    ...search.advanced,
                                    onSetFilterValues: setAdvancedFilterValues,
                                }
                                : undefined}
                            advancedWithSchema={search.advancedWithSchema
                                ? {
                                    ...search.advancedWithSchema,
                                    onSetFilterValues: setAdvancedFilterValues,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                } as IAdvancedWithSchemaFilterProps<any>
                                : undefined}
                        />
                    )}

                    <div className="row">
                        {selectConfig && listItems?.length > 0 && (
                            <div className="selectActions">
                                {selectConfig.withSelectAll && (
                                    <Tooltip
                                        label={{
                                            msg: getSelectAllTooltipLabel(),
                                            placeholders: {
                                                max: selectConfig.maxItems,
                                            },
                                        }}
                                    >
                                        <InputCheckboxField
                                            name="list-page-select-all"
                                            checked={getIsSelectAllChecked()}
                                            onChange={onChangeSelectAll}
                                            disabled={!areThereAnySelectableItemsOnCurrentPage}
                                        />
                                    </Tooltip>
                                )}
                                {isArrayWithValues(selectConfig.selectActions) && (
                                    <ActionButtons
                                        actions={selectConfig.selectActions.map((item) => ({
                                            ...item,
                                            disabled: selectedItemIds.length <= 0 || item.disabled,
                                            onExecute: () => (
                                                item.onExecute({
                                                    selectedItems: getSelectedItemIdsCurrentlyDisplayed(),
                                                    entityData: asyncEntity.data,
                                                })
                                            ),
                                        }))}
                                    />
                                )}
                            </div>
                        )}

                        {listActions && isListDataAvailable && (
                            <div className="listActions">
                                <ListActionsHeader actions={listActions} />
                            </div>
                        )}
                    </div>

                    <EntityWrapper
                        {...list}
                        entityDataTransformer={entityDataTransformer}
                        extraDataSelector={(s) => {
                            const existingExtraData = list.extraDataSelector ? list.extraDataSelector(s) : {};
                            return {
                                ...existingExtraData,
                                selectData: selectConfig && {
                                    selectedItemIds,
                                    maxSelection: selectConfig.maxItems,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    getOnSelectForItem: (item: any) => (
                                        ({ checked }: IOnChangeCheckboxProps) => {
                                            const newSelectedItemIds = addOrRemoveItemFromArray<string>(
                                                checked,
                                                selectedItemIds,
                                                item.id,
                                                { resultInNewArray: true },
                                            );

                                            setSelectedItemIds(newSelectedItemIds);
                                        }
                                    ),
                                },
                            };
                        }}
                    />

                    {pagination && isListDataAvailable && (
                        <DataPagination
                            itemsPerPage={nrOfItemsPerPage}
                            pageNr={paginationInfo.pageNr}
                            nrOfItems={paginationInfo.nrOfItems}
                            totalNrOfItems={determineTotalNumberOfItems()}
                            onChangeItemsPerPage={changeItemsPerPage}
                            onChangePageNr={changePageNr}
                        />
                    )}
                </>
            )}

            {(create || actions) && !isLoading && (
                <div className="actions">
                    {create && (
                        <ShowIfAllowed
                            routeKey={create.toRoute.routeKey}
                            disableInsteadOfHide={{
                                tooltip: 'common.action.add_not_allowed',
                            }}
                        >
                            <IconButtonCreate
                                id={`${uiPageKey}_create`}
                                onClick={navigateToCreateRoute}
                                tooltip={create.tooltip}
                            />
                        </ShowIfAllowed>
                    )}
                    <ActionButtons actions={actions} />
                </div>
            )}
        </WrapperElem>
    );

    function getSelectAllTooltipLabel() {
        return areThereAnySelectableItemsOnCurrentPage
            ? selectConfig.maxItems
                ? 'common.action.select_all_with_max'
                : 'common.action.select_all'
            : 'common.action.no_items_selectable';
    }

    function setSearchMode(newSearchMode: TListSearchMode) {
        dispatch(updateListSearchMode({
            uiPageKey,
            notificationToTrigger: notificationToTriggerOnUiVarChanges,
            searchMode: newSearchMode,
        }));
    }

    function setSimpleSearchValue(newSearchValue: string) {
        dispatch(updateListSimpleSearch({
            uiPageKey,
            /* the SimpleSearch (see DataSearch.tsx) has it's own custom state (where it keeps the
               actual search input) so that we don't have to trigger a re-render on the whole page --> pass null */
            notificationToTrigger: search.simple.isClientSideSearch ? notificationToTriggerOnUiVarChanges : null,
            filterValue: newSearchValue,
        }));
    }

    function setSimpleFilterToggles(newToggles: ISimpleFilterToggles) {
        dispatch(updateListSimpleSearch({
            uiPageKey,
            notificationToTrigger: notificationToTriggerOnUiVarChanges,
            extraFilterToggles: newToggles,
        }));
    }

    function setAdvancedFilterValues(newFilterValues: TAnyObject) {
        dispatch(updateListAdvancedSearch({
            uiPageKey,
            /* when the advancedWithSchema is used, underneath the extended input form is used (with it's own
               state) so that we don't have to trigger a re-render on the whole page --> pass null */
            notificationToTrigger: search.advancedWithSchema ? null : notificationToTriggerOnUiVarChanges,
            filterValues: newFilterValues,
        }));
    }

    function changeItemsPerPage({ itemsPerPage }: IOnChangeItemsPerPageProps) {
        dispatch(updateListPagination({
            uiPageKey,
            notificationToTrigger: notificationToTriggerOnUiVarChanges,
            itemsPerPage,
        }));

        pagination.onChangeItemsPerPage({ itemsPerPage });
    }

    function changePageNr({ pageNr, areItemsAvailable, itemsPerPage }: IOnChangePageNrProps) {
        pagination.onChangePageNr({
            pageNr,
            offset: !areItemsAvailable && isListDataAvailable && paginationInfo.offset,
            itemsPerPage,
        });
    }

    function determineTotalNumberOfItems(): number {
        const paginationType = getPaginationType({
            totalNrOfItems: paginationInfo.totalNrOfItems,
        });

        if (paginationType === 'pages') {
            return paginationInfo.totalNrOfItems;
        }

        /* paginationType === 'next' */
        const {
            pageNr = FIRST_PAGE_NR,
            nrOfItems,
        } = paginationInfo;

        const nrOfItemsUntilEndOfCurrentPage = (nrOfItemsPerPage || DEFAULT_PAGE_ITEM_LIMIT) * pageNr;
        if (nrOfItemsUntilEndOfCurrentPage > nrOfItems) {
            /* the page is not entirely full, so we assume that all items have been fetched */
            return nrOfItems;
        }

        return undefined;
    }

    /**
     * The input 'entityData' and 'extraData' (coming from EntityWrapper) are not used here, but instead
     * the already-computed "listItems" (because the DataPagination also needs that data)
     */
    function entityDataTransformer() {
        return extractDataItemsOfSelectedPage({
            allDataItems: listItems,
            itemsPerPage: nrOfItemsPerPage,
            pageNr: paginationInfo.pageNr,
        });
    }

    function getSelectedItemIdsCurrentlyDisplayed() {
        if (!isSelectConfigManagedExternallyTypeGuard(selectConfig)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return selectableListItemsOnCurrentPage?.filter((dataItem: any) =>
                selectedItemIds.includes(
                    dataItem[selectConfig.listItemId || 'id'],
                ));
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return selectableListItemsOnCurrentPage?.filter((dataItem: any) => selectedItemIds.includes(dataItem.id));
    }

    function getDisplayedListItemsSelectedLocalIds(): string[] {
        return selectedItemIdsLocalState.filter((id) =>
            selectableListItemsOnCurrentPage?.find((item) => item.id === id));
    }

    function navigateToCreateRoute() {
        redirectTo(create.toRoute);
    }

    function setStateOnSortColumnChange(newSortColumn: IActiveSortColumn<never>) {
        dispatch(updateListSorting({
            uiPageKey,
            notificationToTrigger: notificationToTriggerOnUiVarChanges,
            column: newSortColumn as IActiveSortColumn<unknown>,
        }));

        sort.onChangeSortColumn({
            sortColumn: newSortColumn as IActiveSortColumn<unknown>,
            itemsPerPage: nrOfItemsPerPage,
        });
    }

    function getActiveSortColumn(): IActiveSortColumn<unknown> {
        if (listPageVars?.sorting) {
            return listPageVars.sorting.column;
        }

        return null;
    }

    function onChangeSelectAll({ checked }: IOnChangeCheckboxProps) {
        if (checked) {
            const newSelectedItems = selectConfig.maxItems
                ? selectableListItemsOnCurrentPage.slice(0, selectConfig.maxItems)
                : selectableListItemsOnCurrentPage;
            setSelectedItemIds(
                newSelectedItems.map((item) => item.id),
            );
        } else {
            setSelectedItemIds([]);
        }
    }

    function getIsSelectAllChecked() {
        if (!selectedItemIds || !isArrayWithValues(selectableListItemsOnCurrentPage)) {
            return false;
        }
        if (selectConfig.maxItems) {
            return selectedItemIds.length === selectConfig.maxItems
                || selectedItemIds.length === selectableListItemsOnCurrentPage.length;
        }
        return selectedItemIds.length === selectableListItemsOnCurrentPage.length;
    }
}

export function initListPage({
    notifications,
}: {
    notifications: StateChangeNotification[];
}) {
    return observe<IListPageProps>(
        notifications,
        ListPage,
    );
}

function PseudoContentBox({ className, children }: IContentBoxProps) {
    return (
        <div className={className}>
            {children}
        </div>
    );
}

function isSelectConfigManagedExternallyTypeGuard(
    config: ISelectConfigManagedInternally | ISelectConfigManagedExternally,
): config is ISelectConfigManagedExternally {
    return (config as ISelectConfigManagedExternally).selectedItemIds !== undefined;
}
