import React from 'react';
import clsx from 'clsx';
import { INavigateToRoute } from 'models/routing.models';
import { getStore } from 'state';
import { getPrevRouteLocation } from 'state/ui/selectors';
import { makeStyles, mixins } from 'views/styling';
import TextButton from 'views/common/buttons/TextButton';
import { ArrowLeftIcon } from 'views/common/icons';
import { redirectTo } from 'views/routes';

interface IGoBackProps {
    id: string;
    targetRouteWhenNoPrevious: INavigateToRoute;
    className?: string;
}

const useStyles = makeStyles((/* theme */) => ({
    GoBack: {
        ...mixins.flexRowCenterLeft(),
    },
}));

export default function GoBack({
    id,
    targetRouteWhenNoPrevious,
    className,
}: IGoBackProps) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.GoBack, className)}>
            <TextButton
                id={id}
                label="common.action.back"
                variant="bare"
                color="text"
                noMargin={false}
                icon={<ArrowLeftIcon />}
                iconPlacement="start"
                onClick={onClick}
            />
        </div>
    );

    function onClick() {
        const prevRouteLocation = getPrevRouteLocation(getStore().getState());

        redirectTo(prevRouteLocation || targetRouteWhenNoPrevious);
    }
}
