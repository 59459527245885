import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { isStoryManagerInScenarioMode } from 'state/ui/storyManager.selector';
import { canUserModifyStoryManagerScenarios } from 'state/auth/apiEntityAuthorization.selectors';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import { AddIcon, KeyIcon } from 'views/common/icons';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import ActionButtons from 'views/common/buttons/ActionButtons';
import { observe, IObserveProps } from 'views/observe';
import {
    conditionallyDisableStoryManagerAddAction,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';
import AddOutputKeyModal from '../../OutputKeyDetails/AddOutputKeyModal';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.side_nav';

const useStyles = makeStyles((theme) => ({
    SideNavigationTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        marginBottom: theme.spacing(1),

        background: 'none',
        border: 'none',
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            '& > .title--add': {
                display: 'block',
            },
        },
        '&.selected:not(:hover)': {
            backgroundColor: theme.palette.grey[100],
        },

        '& > .title--add': {
            display: 'none',
            '& .main-action-button': {
                marginLeft: 0,
            },
        },

        '& > .title--button': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            padding: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,

            background: 'none',
            border: 'none',
            width: '100%',
            cursor: 'pointer',

            '& > svg': {
                marginRight: theme.spacing(1),
            },
        },
    },
}));

function SideNavigationTitle({ state }: IObserveProps) {
    const [isAddOutputKeyModalOpen, setAddOutputKeyModalOpen] = useState(false);
    const classes = useStyles();
    const { databaseId, outputKeyId, scenarioIndex } = useRouteParams();
    const selected = !outputKeyId && !scenarioIndex;
    const isScenarioEditorMode = isStoryManagerInScenarioMode(state);
    const canUserModifyScenarios = canUserModifyStoryManagerScenarios(state);

    return (
        <>
            <div
                className={clsx(classes.SideNavigationTitle, { selected })}
            >
                <button
                    className="title--button"
                    onClick={navigateToOutputKeysOverview}
                    type="button"
                >
                    <KeyIcon />
                    <Translate msg={`${TRANSLATION_PREFIX}.title.label`} />
                </button>
                {isScenarioEditorMode && (
                    <ActionButtons
                        className="title--add"
                        actions={[getAddActionItem()]}
                    />
                )}
            </div>
            <AddOutputKeyModal
                open={isAddOutputKeyModalOpen}
                onClose={() => setAddOutputKeyModalOpen(false)}
            />
        </>
    );

    function getAddActionItem() {
        return conditionallyDisableStoryManagerAddAction({
            canUserModify: canUserModifyScenarios,
            action: {
                id: 'side-nav-add-output-key',
                label: `${TRANSLATION_PREFIX}.title.actions.add`,
                onExecute: async () => setAddOutputKeyModalOpen(true),
                icon: <AddIcon />,
                variant: 'main-icon-bare',
            },
        });
    }

    function navigateToOutputKeysOverview() {
        if (!selected) {
            redirectTo({
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
                params: {
                    databaseId,
                },
            });
        }
    }
}

export default observe(
    [
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
    ],
    SideNavigationTitle,
);
