import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import { IDepositOrWithdrawPortfolioCashInput } from '../../models/portfolioMgmt/portfolioCash.models';
import { TPortfolio } from '../../models/portfolioMgmt/portfolio.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { get } from '../coreApiRequestWrapper';

export function depositOrWithdrawPortfolioCash({
    portfolioId,
    amount,
    underlyingApiRequestConfig,
}: IDepositOrWithdrawPortfolioCashInput & IUnderlyingRequestApiInput) {
    /* even though this endpoint changes the cash, it is a GET */
    return get<TPortfolio>({
        url: CoreApiPath.PORTFOLIO_CASH,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            amount,
        },
        ...underlyingApiRequestConfig,
    });
}
