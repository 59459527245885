import {
    errorFlashDispatcher,
    successFlashDispatcher,
    errorMessageFlashDispatcher,
} from 'state/flashDispatcher';

export * from 'state/flashDispatcher';

export const flashSuccessApiEntityPatch = successFlashDispatcher('error.api_entity.patch_succeeded');
export const flashSuccessApiEntityCreate = successFlashDispatcher('error.api_entity.create_succeeded');
export const flashSuccessApiEntityDelete = successFlashDispatcher('error.api_entity.delete_succeeded');

export const flashErrorApiEntityPatch = errorFlashDispatcher('error.api_entity.patch_failed');
export const flashErrorApiEntityCreate = errorFlashDispatcher('error.api_entity.create_failed');
export const flashErrorApiEntityDelete = errorFlashDispatcher('error.api_entity.delete_failed');

export const flashErrorFileImportWrongFormat = errorMessageFlashDispatcher('error.file_import.wrong_format');
export const flashErrorFileImportMissingColumns = (requiredColumns: string[]) => errorFlashDispatcher(
    'error.file_import.missing_columns',
    {
        requiredColumns: requiredColumns.join(', '),
    },
)({ error: null });
export const flashErrorFileImportMissingData = errorFlashDispatcher('error.file_import.missing_data');
export const flashSuccessFileImport = successFlashDispatcher('error.file_import.success');

export const flashErrorInvalidPolicySettings = errorFlashDispatcher('error.invalid_policy_settings');

export const flashErrorEntityNotFound = (entityId: string) => errorFlashDispatcher(
    'error.code.entity_not_found',
    { entityId },
)({ error: null });
export const flashErrorInvalidEntityId = (invalidEntityId: string) => errorFlashDispatcher(
    'error.invalid_entity_id',
    { invalidEntityId },
)({ error: null });
