import React from 'react';
import {
    publicInstrumentDetailsEntity,
    triggerPatchPublicInstrumentDetails,
} from 'state/entities/thematicSearch/publicInstrumentDetails';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export function PublicInstrumentAccessibleByTab() {
    const publicInstrumentDetailsData = publicInstrumentDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={publicInstrumentDetailsData}
            nameForDisplay={publicInstrumentDetailsData.name || publicInstrumentDetailsData.external_id}
            asyncEntityPatchTrigger={(values) => triggerPatchPublicInstrumentDetails((currentInstrument) => {
                /* eslint-disable no-param-reassign */
                currentInstrument.modifiable_by = values.modifiable_by;
                currentInstrument.readable_by = values.readable_by;
                /* eslint-enable no-param-reassign */
            })}
        />
    );
}
