import React from 'react';
import { ALL_CURRENCIES } from '@console/common/config/currencies.config';
import { CombineTransactionCostMode } from '@console/core-api/models/portfolioMgmt/policy.models';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import { DEFAULT_NR_OF_DECIMALS_FOR_NUMBER_VALUE, DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE } from 'config/ui.config';
import { SIZES } from 'config/styling/sizes';
import { isCannotDifferRule } from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import ExtendedInputCheckbox from 'views/common/inputs/extended/ExtendedInputCheckbox';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';
import ExtendedInputRadio, { IRadioOption } from 'views/common/inputs/extended/ExtendedInputRadio';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ContentTitle from 'views/common/layout/ContentTitle';
import { IInputSearchableSelectItem } from 'views/common/inputs/base/InputSearchableSelectField';
import { IPolicySettingsFormValues } from './types';

const currencySelectItems: IInputSearchableSelectItem[] = ALL_CURRENCIES.map((currency) => ({
    value: currency,
    label: currency,
}));

function policyCombineTransactionsCostsFormContent({
    fields,
    policySettingsRules,
}: Pick<IExtendedInputFormContext<IPolicySettingsFormValues>, 'fields'> & {
    policySettingsRules: IPolicySettingsRules;
}) {
    const isFieldReadOnly = isCannotDifferRule(
        policySettingsRules.executionConstraints.combineTransactionCostMode.rule,
    );

    const radioOptions: IRadioOption[] = [{
        value: CombineTransactionCostMode.MAX,
        label: 'fields.combine_transaction_cost_components.max',
        extraDescription: 'fields.combine_transaction_cost_components.max_extra_info',
    }, {
        value: CombineTransactionCostMode.SUM,
        label: 'fields.combine_transaction_cost_components.sum',
        extraDescription: 'fields.combine_transaction_cost_components.sum_extra_info',
    }];

    return (
        <ExtendedInputRadio
            baseId="policy-combine-transaction-cost-mode"
            name="combineTransactionCostMode"
            formField={fields.combineTransactionCostMode}
            readOnly={isFieldReadOnly}
            wrapper={{
                label: 'fields.combine_transaction_cost_components.label',
                shouldStyleInputWrapped: false,
                shouldShowLabelInBold: false,
                tooltip: {
                    msg: 'fields.combine_transaction_cost_components.tooltip',
                    raw: true,
                },
            }}
            radioOptions={radioOptions}
            showValueIndicatorsIfDisabled
        />
    );
}

export function PolicyBaseCurrencyFormContent({
    fields,
    smallerSize,
    policySettingsRules,
}: Pick<IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'baseCurrency'>>, 'fields'> & {
    smallerSize: boolean;
    policySettingsRules: IPolicySettingsRules;
}) {
    const canUserChangeCurrency = !isCannotDifferRule(policySettingsRules.executionConstraints.currency.rule);

    return (
        <ExtendedInputSearchableSelect
            formField={fields.baseCurrency}
            items={currencySelectItems}
            readOnly={!canUserChangeCurrency}
            wrapper={{
                label: canUserChangeCurrency ? 'fields.base_currency.choose_label' : 'fields.base_currency.label',
                minHeight: smallerSize ? SIZES.INPUT.HEIGHT.S : SIZES.INPUT.HEIGHT.M,
            }}
        />
    );
}

export function renderPolicyExecutionConstraintsFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>,
    policySettingsRules: IPolicySettingsRules,
) {
    const { fields } = context;
    const executionConstraintsRules = policySettingsRules.executionConstraints;

    return (
        <>
            <PolicyBaseCurrencyFormContent
                fields={fields}
                smallerSize
                policySettingsRules={policySettingsRules}
            />
            <ContentTitle
                label="portfolio_mgmt.policies.detail.fields.transaction_boundaries.label"
                variant="field-group"
            />
            <InputGroup>
                <ExtendedInputNumber
                    formField={fields.minimumTransactionAmount}
                    inputProps={{
                        adornment: fields.baseCurrency.value as string,
                    }}
                    readOnly={isCannotDifferRule(executionConstraintsRules.transactionAmountRange.rule)}
                    wrapper={{
                        label: 'fields.transaction_amount.min.label',
                        tooltip: {
                            msg: 'fields.transaction_amount.min.tooltip',
                        },
                        shouldShowLabelInBold: false,
                        minHeight: SIZES.INPUT.HEIGHT.S,
                    }}
                    shouldAllowNumbersBelow0={false}
                />
                <ExtendedInputNumber
                    formField={fields.maximumTransactionAmount}
                    readOnly={isCannotDifferRule(executionConstraintsRules.transactionAmountRange.rule)}
                    inputProps={{
                        adornment: fields.baseCurrency.value as string,
                    }}
                    wrapper={{
                        label: 'fields.transaction_amount.max.label',
                        tooltip: {
                            msg: 'fields.transaction_amount.max.tooltip',
                        },
                        shouldShowLabelInBold: false,
                        minHeight: SIZES.INPUT.HEIGHT.S,
                    }}
                    noDataLabel={{
                        msg: 'fields.transaction_amount.max.no_data',
                    }}
                    shouldAllowNumbersBelow0={false}
                />
            </InputGroup>
            <ExtendedInputNumber
                formField={fields.transactionCostAmount}
                readOnly={isCannotDifferRule(executionConstraintsRules.costPerTransactionAmount.rule)}
                shouldAllowNumbersBelow0={false}
                nrOfDecimals={DEFAULT_NR_OF_DECIMALS_FOR_NUMBER_VALUE}
                inputProps={{
                    adornment: fields.baseCurrency.value as string,
                }}
                wrapper={{
                    shouldShowLabelInBold: false,
                    label: 'fields.cost_per_transaction_amount.label_with_info',
                    minHeight: SIZES.INPUT.HEIGHT.S,
                }}
            />
            <ExtendedInputNumber
                formField={fields.transactionCostFraction}
                readOnly={isCannotDifferRule(executionConstraintsRules.costPerTransactionFraction.rule)}
                type="percentage"
                shouldAllowNumbersBelow0={false}
                nrOfDecimals={DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE}
                wrapper={{
                    shouldShowLabelInBold: false,
                    label: 'fields.cost_per_transaction_fraction.label_with_info',
                    minHeight: SIZES.INPUT.HEIGHT.S,
                }}
            />
            {policyCombineTransactionsCostsFormContent({
                fields,
                policySettingsRules,
            })}
            <ExtendedInputCheckbox
                formField={fields.fractionalShares}
                readOnly={isCannotDifferRule(executionConstraintsRules.includeFractionalShares.rule)}
                wrapper={{
                    label: 'fields.fractional_shares.label',
                }}
                shouldIncludeTextValueDescription
                showValueIndicatorsIfDisabled
            />
        </>
    );
}
