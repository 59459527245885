type TPart = string | number;

const DEFAULT_JOIN_SEPARATOR = ' ';

interface IJoinPartsOptions {
    joinSeparator?: string;
}

export function joinParts(
    parts: TPart[],
    { joinSeparator = DEFAULT_JOIN_SEPARATOR }: IJoinPartsOptions = {},
) {
    return parts
        .filter(isNotBlank)
        .join(joinSeparator)
        .trim();
}

function isNotBlank(input: TPart): boolean {
    if (!input) {
        return false;
    }

    return `${input}`.trim().length > 0;
}
