/**
 * Radians:
 *    2 PI radians = 360 °
 * OR 1 radian = 180/PI °
 *
 * so e.g. 90° = 2 PI / 4 = PI / 2
 */

export const DEGREES_IN_RADIANS = {
    1: (2 * Math.PI) / 360,
    90: Math.PI / 2,
    180: Math.PI,
    270: 3 * (Math.PI / 2),
};

export const RADIANS_IN_DEGREES = {
    1: 180 / Math.PI,
};

export function degreesToRadians(degrees: number): number {
    return degrees * DEGREES_IN_RADIANS['1'];
}

export function radiansToDegrees(radians: number): number {
    return radians * RADIANS_IN_DEGREES['1'];
}
