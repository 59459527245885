import { TApiEntityId } from '@console/core-api/models/api.models';

export enum AccessibleByEntityType {
    Users = 'users',
    UserGroups = 'user_groups',
}

export enum AccessibleByPermissionType {
    Modify = 'modify',
    Read = 'read',
}

export interface IAccessibleByPageVars {
    entityType: AccessibleByEntityType;
    showOnlyEnabledPermissions: boolean;
}

export interface IAccessibleByState {
    [entityId: string]: boolean;
}

export interface IChangeAccessProps {
    isEnabled: boolean;
    entityId: TApiEntityId;
    permissionType: AccessibleByPermissionType;
}
