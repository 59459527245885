import { getStoryManagerSortedOutputKeysAsArray } from 'state/ui/storyManager.selector';
import { CONDITION_OPERATORS } from 'config/storyTeller/storymanager.config';
import {
    JSONPatchModels,
    IOutputKeyWithId,
    TStoryManagerStatus,
    IScenario,
} from '@console/bff/models/storyteller/storymanager.models';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import { II18nLabel } from 'models/general.models';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.find_and_replace.side_content';

export interface IOutputKeyResults {
    [outputKeyId: string]: {
        name: string;
        results: IResult[];
    };
}

export interface IResult {
    outputKeyId: string;
    scenarioIndex: number;
    value: string;
    context: II18nLabel;
    highlight: string;
    jsonPatchOperationsGetter: (replaceValue: string) => JSONPatchModels.Operation[];
}

type TConditionField = 'left' | 'right';

export function calculateScenarioResults({
    findValue,
    outputKeysAsSortedArray = getStoryManagerSortedOutputKeysAsArray(),
    translator = getTranslator(getStore().getState()),
}: {
    findValue: string;
    outputKeysAsSortedArray?: IOutputKeyWithId[];
    translator?: TTranslator;
}) {
    if (!findValue) {
        return {};
    }

    return outputKeysAsSortedArray.reduce(
        (acc, outputKey) => {
            Object.keys(outputKey.scenarios).forEach((scenarioIndex) => {
                const scenario = outputKey.scenarios[scenarioIndex];
                Object.keys(scenario.conditions).forEach((conditionIndex) => {
                    const conditionGroup = scenario.conditions[conditionIndex];
                    Object.keys(conditionGroup).forEach((subconditionIndex) => {
                        const condition = conditionGroup[subconditionIndex];
                        if (condition.left.includes(findValue)) {
                            addResultToAcc('left');
                        }
                        if (condition.right.includes(findValue)) {
                            addResultToAcc('right');
                        }

                        function addResultToAcc(side: TConditionField) {
                            if (!acc[outputKey.id]) {
                                acc[outputKey.id] = {
                                    name: outputKey.name,
                                    results: [],
                                };
                            }

                            const value = side === 'left' ?
                                `${condition.left} ${getOpLabel(condition.operator)} ...`
                                : `... ${getOpLabel(condition.operator)} ${condition.right}`;

                            acc[outputKey.id].results.push({
                                context: {
                                    msg: `${TRANSLATION_PREFIX}.results.scenario_editor.context`,
                                    placeholders: {
                                        scenarioNumber: Number(scenarioIndex) + 1,
                                        conditionNumber: Number(conditionIndex) + 1,
                                        subconditionNumber: Number(subconditionIndex) + 1,
                                        // eslint-disable-next-line max-len
                                        side: translator(`${TRANSLATION_PREFIX}.results.scenario_editor.context_side.${side}`),
                                    },
                                },
                                highlight: findValue,
                                value,
                                outputKeyId: outputKey.id,
                                scenarioIndex: Number(scenarioIndex),
                                jsonPatchOperationsGetter: (replaceValue) =>
                                    getOnReplaceJsonPatchOperations(side, replaceValue),
                            });
                        }

                        function getOpLabel(operator: string) {
                            return CONDITION_OPERATORS.find((item) => item.value === operator)?.label || operator;
                        }

                        function getOnReplaceJsonPatchOperations(
                            conditionField: TConditionField,
                            replaceValue: string,
                        ): JSONPatchModels.Operation[] {
                            const scenarioPathPrefix = `/outputKeys/${outputKey.id}/scenarios/${scenarioIndex}`;
                            const operations: JSONPatchModels.Operation[] = [{
                                op: 'replace',
                                // eslint-disable-next-line max-len
                                path: `${scenarioPathPrefix}/conditions/${conditionIndex}/${subconditionIndex}/${conditionField}`,
                                value: condition[conditionField]?.replaceAll(
                                    findValue,
                                    replaceValue || '',
                                ),
                            }];

                            operations.push(
                                ...getScenarioStatusDowngradeJsonOperationsIfNeeded({ scenario, scenarioPathPrefix }),
                            );

                            return operations;
                        }
                    });
                });
            });
            return acc;
        },
        {} as IOutputKeyResults,
    );
}

export function calculateOutputResults({
    findValue,
    outputKeysAsSortedArray = getStoryManagerSortedOutputKeysAsArray(),
}: {
    findValue: string;
    outputKeysAsSortedArray?: IOutputKeyWithId[];
}) {
    if (!findValue) {
        return {};
    }

    return outputKeysAsSortedArray.reduce(
        (acc, outputKey) => {
            Object.keys(outputKey.scenarios).forEach((scenarioIndex) => {
                const scenario = outputKey.scenarios[scenarioIndex];
                Object.keys(scenario.output.variations).forEach((variationIndex) => {
                    const variation = scenario.output.variations[variationIndex];
                    Object.keys(variation).forEach((locale) => {
                        const translation = variation[locale];
                        if (translation.includes(findValue)) {
                            if (!acc[outputKey.id]) {
                                acc[outputKey.id] = {
                                    name: outputKey.name,
                                    results: [],
                                };
                            }
                            acc[outputKey.id].results.push({
                                value: translation,
                                context: {
                                    msg: `${TRANSLATION_PREFIX}.results.text_output_editor.context`,
                                    placeholders: {
                                        scenarioNumber: Number(scenarioIndex) + 1,
                                        variationNumber: Number(variationIndex) + 1,
                                        locale,
                                    },
                                },
                                jsonPatchOperationsGetter: (replaceValue) =>
                                    getOnReplaceJsonPatchOperations(replaceValue),
                                outputKeyId: outputKey.id,
                                scenarioIndex: Number(scenarioIndex),
                                highlight: findValue,
                            });
                        }

                        function getOnReplaceJsonPatchOperations(replaceValue: string): JSONPatchModels.Operation[] {
                            const scenarioPathPrefix = `/outputKeys/${outputKey.id}/scenarios/${scenarioIndex}`;
                            const operations: JSONPatchModels.Operation[] = [{
                                op: 'replace',
                                path: `${scenarioPathPrefix}/output/variations/${variationIndex}/${locale}`,
                                value: translation.replaceAll(findValue, replaceValue || ''),
                            }];

                            operations.push(
                                ...getScenarioStatusDowngradeJsonOperationsIfNeeded({
                                    scenario,
                                    scenarioPathPrefix,
                                    replaceTranslationData: { locale },
                                }),
                            );

                            return operations;
                        }
                    });
                });
            });
            return acc;
        },
        {} as IOutputKeyResults,
    );
}

function getScenarioStatusDowngradeJsonOperationsIfNeeded({
    scenario,
    scenarioPathPrefix,
    replaceTranslationData,
}: {
    scenario: IScenario;
    scenarioPathPrefix: string;
    replaceTranslationData?: { // Only include when replacing translations
        locale: string;
    };
}): JSONPatchModels.Operation[] {
    const statusToDowngradeTo: TStoryManagerStatus = 'IN_REVIEW';
    const operations: JSONPatchModels.Operation[] = [];

    if (scenario.status === 'DONE') {
        operations.push({
            op: 'replace',
            path: `${scenarioPathPrefix}/status`,
            value: statusToDowngradeTo,
        });
    }

    if (replaceTranslationData) {
        if (scenario.output.statuses[replaceTranslationData.locale] === 'DONE') {
            operations.push({
                op: 'replace',
                path: `${scenarioPathPrefix}/output/statuses/${replaceTranslationData.locale}`,
                value: statusToDowngradeTo,
            });
        }
    }

    return operations;
}
