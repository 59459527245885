import isSet from '@snipsonian/core/cjs/is/isSet';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { TNumberRange } from '@console/core-api/models/api.models';

export function formatPercentage(
    percentage: number,
    {
        nrOfDecimals = 2,
        stripTrailingDecimalZeros = false,
    }: {
        nrOfDecimals?: number;
        stripTrailingDecimalZeros?: boolean;
    } = {},
): string {
    const formattedPercentage = formatFloat(percentage, { nrOfDecimals, stripTrailingDecimalZeros });

    return `${formattedPercentage}%`;
}

export function convertPercentageRelative1ToRelative100(percentageRelativeTo1: number) {
    return (percentageRelativeTo1 - 1) * 100;
}

export function countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
}

export function roundPercentageWith2Decimals(number: number) {
    return Math.round(number * 100) / 100;
}

/* Converts [0.01, 0.25] to [1, 25] */
export function convertNumberRangeToPercentageRange(numberRange: TNumberRange) {
    return numberRange
        .map((rangePart) => {
            if (isSet(rangePart)) {
                return roundPercentageWith2Decimals(rangePart * 100);
            }
            return rangePart; // e.g. null remains null
        }) as TNumberRange;
}

/* Converts [1, 25] to [0.01, 0.25] */
export function convertPercentageRangeToNumberRange(percentageRange: TNumberRange) {
    return percentageRange
        .map((rangePart) => {
            if (isSet(rangePart)) {
                return rangePart / 100;
            }
            return rangePart; // e.g. null remains null
        }) as TNumberRange;
}
