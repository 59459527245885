import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IStoryManagerDatabaseBase } from '@console/bff/models/storyteller/storymanager.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { api } from 'api';
import { errorFlashDispatcher } from 'state/flashDispatcher';
import { getEntitiesManager } from '../entitiesManager';

export const storyManagerDatabasesEntity = getEntitiesManager().registerEntity<IStoryManagerDatabaseBase[]>({
    asyncEntityKey: AsyncEntityKeys.storyManagerDatabases,
    operations: [AsyncOperation.fetch, AsyncOperation.create, AsyncOperation.remove, AsyncOperation.update],
    notificationsToTrigger: [StateChangeNotification.STORY_MANAGER_DATABASES],
    includeUpdaters: true,
});

export const triggerResetStoryManagerDatabasesFetch =
    () => storyManagerDatabasesEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchStoryManagerDatabases({
    forceRefresh = false,
}: IForceStateRefreshFilter = {}) {
    return storyManagerDatabasesEntity.async.fetch({
        api: api.bff.storyTeller.fetchStoryManagerDatabases,
        refreshMode: () => forceRefresh,
        resetDataOnTriggerMode: 'never',
        onError: errorFlashDispatcher('apps.story_teller.template_databases.flash_messages.get_databases_error'),
    });
}
