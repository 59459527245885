import React, { useState } from 'react';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import Modal, { TModalMaxWidth } from 'views/common/layout/Modal';
import TextButton from 'views/common/buttons/TextButton';
import { APP_COLORS } from 'config/styling/colors';
import { TAskConfirmation } from 'views/common/buttons/types';
import ExtendedInputForm, { IExtendedInputFormProps, IOnSubmitProps } from '../extended/ExtendedInputForm';

export interface IRenderSelectedItemProps<Item extends TObjectWithProps> {
    item: Item;
}

export interface ISelectOneViaModalProps<Item extends TObjectWithProps> {
    name: string; // must be unique - is used for the modal id
    currentSelectedItem: Item;
    renderSelectedItem: (renderSelectedItemProps: IRenderSelectedItemProps<Item>) => React.ReactNode;
    edit: {
        label?: TLabel;
        modalTitle: TLabel;
        modalMaxWidth: TModalMaxWidth;
        disabled?: boolean; // default false
    } & Omit<IExtendedInputFormProps<Item>, 'name' | 'cancel' | 'readOnly'>;
    remove?: {
        label?: TLabel;
        onRemoveItem: () => void;
        confirmationModal?: TAskConfirmation;
        disabled?: boolean; // default false
    };
    readOnly?: boolean; // default false
    renderActionsBelowItem?: boolean; // default false
}

const useStyles = makeStyles((theme) => ({
    SelectOneViaModal: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'left' }),

        '& .itemLabel': {
            paddingTop: theme.spacing(1),
            ...mixins.typo({ size: 16, color: APP_COLORS.TEXT['900'] }),
            ...mixins.widthMax(),
        },
        '& .actions': {
            paddingTop: theme.spacing(1) - 2,
            ...mixins.flexRow(),

            '& .__remove': {
                paddingLeft: theme.spacing(0.5),
            },
        },
    },
    ActionsBelow: {
        ...mixins.flexRowCenterRight(),

        '& .actions': {
            paddingTop: theme.spacing(2) - 1,
            ...mixins.flexRow(),

            '& .__remove': {
                paddingLeft: theme.spacing(0.5),
            },
        },
    },
}));

export default function SelectOneViaModal<Item extends TObjectWithProps>({
    currentSelectedItem,
    name,
    readOnly = false,
    renderSelectedItem,
    edit,
    remove,
    renderActionsBelowItem = false,
}: ISelectOneViaModalProps<Item>) {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className={classes.SelectOneViaModal}>
                <div className="itemLabel">
                    {renderSelectedItem({ item: currentSelectedItem || {} as Item })}
                </div>

                {!renderActionsBelowItem && !readOnly && renderActions()}

                <Modal
                    id={`${name}_select_modal`}
                    title={edit.modalTitle}
                    maxWidth={edit.modalMaxWidth}
                    open={isModalOpen}
                    onClose={closeSelectModal}
                >
                    <ExtendedInputForm<Item>
                        name={`${name}_select-modal-form`}
                        readOnly={readOnly}
                        {...edit}
                        submit={{
                            actionLabel: 'common.action.update',
                            ...edit?.submit,
                            onSubmit: onSubmitSelectModal,
                        }}
                        cancel={{
                            onCancel: closeSelectModal,
                        }}
                        checkUnsavedChangesOnRouteChange={false}
                    />
                </Modal>
            </div>
            <div className={classes.ActionsBelow}>
                {renderActionsBelowItem && !readOnly && renderActions()}
            </div>
        </>
    );

    function renderActions() {
        return (
            <div className="actions">
                <TextButton
                    id={`${name}_edit_button`}
                    label={edit.label || 'common.action.edit'}
                    variant="outlined"
                    noMargin
                    fontSize="M"
                    onClick={openSelectModal}
                    disabled={edit?.disabled}
                    size="S"
                />
                {remove && (
                    <div className="__remove">
                        <TextButton
                            id={`${name}_remove_button`}
                            label={remove.label || 'common.action.remove'}
                            variant="outlined"
                            noMargin
                            fontSize="M"
                            disabled={remove?.disabled}
                            onClick={remove.onRemoveItem}
                            size="S"
                            askConfirmation={remove?.confirmationModal}
                        />
                    </div>
                )}
            </div>
        );
    }

    function closeSelectModal() {
        setIsModalOpen(false);
    }

    function openSelectModal() {
        setIsModalOpen(true);
    }

    function onSubmitSelectModal(onSubmitProps: IOnSubmitProps<Item>) {
        closeSelectModal();

        return edit.submit.onSubmit(onSubmitProps);
    }
}
