import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import {
    reportError, reportBreadcrumb,
} from '@typsy/sentry-react/dist/reporting/reportErrorReact';
// eslint-disable-next-line import/no-extraneous-dependencies
import { transformErrorDataForSentry } from '@typsy/sentry-base/dist/reporting/transformErrorDataForSentry';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import {
    determineApiErrorGroupingFingerprint,
    IApiErrorReportingInfo,
    shouldReportApiError,
} from '@console/api-base/config/apiErrorReporting.config';

const BREADCRUMB_CATEGORY = {
    API_ERROR: 'api_error',
};

export default function reportApiError(apiError: IBaseApiErrorClientSide) {
    const apiErrorReportingInfo = extractApiErrorReportingInfo(apiError);

    const shouldReport = shouldReportApiError(apiErrorReportingInfo);

    if (shouldReport) {
        const { method, url, status, body } = apiErrorReportingInfo;

        const reportMessage = `[ApiError] ${body?.message || '???'} - ${status} ${method} ${url}`;

        if (shouldReport === 'breadcrumb') {
            reportBreadcrumb(
                reportMessage,
                {
                    category: BREADCRUMB_CATEGORY.API_ERROR,
                },
            );
        } else {
            const level = (shouldReport === 'info')
                ? 'info'
                : null;

            reportError(
                new Error(reportMessage),
                {
                    extra: transformErrorDataForSentry(body as unknown as TAnyObject),
                    groupingFingerprint: determineApiErrorGroupingFingerprint(apiErrorReportingInfo),
                    level,
                },
            );
        }
    }
}

function extractApiErrorReportingInfo(
    apiError: IBaseApiErrorClientSide,
): IApiErrorReportingInfo {
    const { originatingRequest, response } = apiError;

    const methodAndUrl = {
        method: originatingRequest?.method,
        url: originatingRequest?.url,
    };

    return {
        ...methodAndUrl,
        status: apiError.status,
        body: response,
    };
}
