import React from 'react';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { TLabel } from 'models/general.models';
import SelectOneViaModal, { ISelectOneViaModalProps } from 'views/common/inputs/base/SelectOneViaModal';
import { ExtendedInputFormContext, IExtendedFormField } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputWrapper, {
    IExtendedInputWrapperProps,
    prependLabelWithPrefix,
} from 'views/common/inputs/extended/ExtendedInputWrapper';

interface IExtendedSelectOneViaModalProps<Item extends TObjectWithProps>
    extends Omit<ISelectOneViaModalProps<Item>, 'name' | 'remove' | 'error' | 'emphasizeError'>,
    // eslint-disable-next-line max-len
    Pick<IExtendedInputWrapperProps, 'shouldPrefixLabel' | 'tooltip' | 'shouldPrefixTooltip' | 'showTooltipUntilDismissed'> {
    label?: TLabel;
    shouldPrefixAddModalTitle?: boolean; // default true
    remove: {
        shouldAllow: boolean;
        label?: TLabel;
        onRemoveItem?: () => void;
    };
    readOnly?: boolean; // default false
    formField: IExtendedFormField;
}

export default function ExtendedSelectOneViaModal<Item extends TObjectWithProps>({
    label,
    shouldPrefixLabel = true,
    tooltip,
    showTooltipUntilDismissed,
    shouldPrefixTooltip = true,
    shouldPrefixAddModalTitle = true,
    readOnly = false,
    formField,
    edit,
    remove,
    ...otherSelectOneViaModalProps
}: IExtendedSelectOneViaModalProps<Item>) {
    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, readOnlyForm, setFieldValue }) => {
                const adjustedEditModalTitle = prependLabelWithPrefix({
                    label: edit.modalTitle,
                    labelPrefix,
                    shouldPrefixLabel: shouldPrefixAddModalTitle,
                });

                const isFieldReadOnly = readOnly || readOnlyForm;

                const selectOneField = (
                    <SelectOneViaModal<Item>
                        name={`select_${formField.fieldName}`}
                        remove={remove.shouldAllow ? {
                            label: remove.label,
                            onRemoveItem: remove.onRemoveItem || onRemoveSelectedItem,
                        } : null}
                        readOnly={isFieldReadOnly}
                        edit={{
                            labelPrefix,
                            ...edit, // after 'labelPrefix' so that you can still overrule the prefix set by the form
                            modalTitle: adjustedEditModalTitle,
                        }}
                        {...otherSelectOneViaModalProps}
                    />
                );

                if (label) {
                    return (
                        <ExtendedInputWrapper
                            readOnly={isFieldReadOnly}
                            hasError={!!formField.error && formField.emphasizeError}
                            label={label}
                            shouldPrefixLabel={shouldPrefixLabel}
                            tooltip={tooltip}
                            showTooltipUntilDismissed={showTooltipUntilDismissed}
                            shouldPrefixTooltip={shouldPrefixTooltip}
                            shouldStyleInputWrapped={false}
                        >
                            {selectOneField}
                        </ExtendedInputWrapper>
                    );
                }

                return selectOneField;

                function onRemoveSelectedItem() {
                    setFieldValue({
                        fieldName: formField.fieldName,
                        value: null,
                    });
                }
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
