import React from 'react';
import { makeStyles, mixins } from 'views/styling';
import Tooltip from 'views/common/widget/Tooltip';
import {
    IIconButtonProps,
    IIconButtonStyleProps,
} from './types';
import {
    BORDER_RADIUS,
    enrichAskConfirmationLabelIfNotProvided,
} from './buttonUtils';
import BaseButton from './BaseButton';
import { setIconButton } from './buttonsNoCircularDependencies';

export type { IIconButtonProps } from './types';

const SIZE = {
    L: 68,
    M: 48,
    S: 32,
    XS: 24,
};

const useStyles = makeStyles((/* theme */) => ({
    IconButton: {
        ...mixins.flexCenter(),

        width: ({ size }: IIconButtonStyleProps) => SIZE[size],
        height: ({ size }: IIconButtonStyleProps) => SIZE[size],
        borderRadius: ({ isCircle }: IIconButtonStyleProps) => {
            if (isCircle) {
                return '50%';
            }
            return BORDER_RADIUS;
        },
    },
}));

setIconButton(IconButton);

export default function IconButton(props: IIconButtonProps) {
    const {
        icon,
        className,
        variant = 'filled',
        color = 'primary',
        size = 'L',
        isCircle = false,
        disabled = false,
        tooltip,
        tooltipPlacement = 'top',
        askConfirmation = false,
        ...baseProps
    } = props;

    const classes = useStyles({ color, size, variant, isCircle, disabled });

    const button = (
        <BaseButton
            {...baseProps}
            color={color}
            variant={variant}
            disabled={disabled}
            classNames={[classes.IconButton, className]}
            addBoxShadowOnHover={variant !== 'bare'}
            askConfirmation={enrichAskConfirmationLabelIfNotProvided(askConfirmation, tooltip)}
        >
            {icon}
        </BaseButton>
    );

    if (!tooltip) {
        return button;
    }

    return (
        <Tooltip
            label={tooltip}
            placement={tooltipPlacement}
        >
            {button}
        </Tooltip>
    );
}
