import React from 'react';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import InputToggleField, { IInputToggleFieldProps } from 'views/common/inputs/base/InputToggleField';
import Text from 'views/common/widget/Text';

interface IPublicProps<Name extends string = string> extends Omit<IInputToggleFieldProps<Name>, 'description'> {
    description: TLabel;
}

const useStyles = makeStyles((theme) => ({
    InputToggleFieldWithDescription: {
        ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'center' }),

        '& .__toggleDescription': {
            marginLeft: theme.spacing(1),
        },
    },
}));

export default function InputToggleFieldWithDescription<Name extends string = string>({
    description,
    ...toggleProps
}: IPublicProps<Name>) {
    const classes = useStyles();

    return (
        <div className={classes.InputToggleFieldWithDescription}>
            <InputToggleField<Name>
                {...toggleProps}
            />

            <div className="__toggleDescription">
                <Text label={description} />
            </div>
        </div>
    );
}
