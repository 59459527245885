import { IExtendedPromise } from '@snipsonian/core/cjs/promise/extendPromise';

export enum ApiCacheKey {
    goals = 'goals',
    horizons = 'horizons',
    policies = 'policies',
    portfolioOptimizations = 'portfolioOptimizations',
    riskProfiles = 'riskProfiles',
    users = 'users',
    userGroups = 'userGroups',
}

export type TApiResponseCache<ApiListResponse, ApiDetailResponse> = {
    [apiKey in ApiCacheKey]: IApiEntityResponseCache<ApiListResponse, ApiDetailResponse>;
};

export interface IApiEntityResponseCache<ApiListResponse, ApiDetailResponse> extends IEntityCacheExpiry {
    list: IExtendedPromise<ApiListResponse>;
    details: {
        [entityKey: string]: IExtendedPromise<ApiDetailResponse>;
    };
}

export interface IEntityCacheExpiry {
    listExpiryInMins?: number; // default 0 (= expires immediately)
    detailExpiryInMins?: number; // default 0 (= expires immediately)
}

export interface IApiCacheRef {
    apiKey: ApiCacheKey;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApiListCacheRef extends IApiCacheRef {}

export interface IApiDetailCacheRef extends IApiCacheRef {
    entityKey: string;
}

export interface IFetchForCacheConfig<ApiResponse> {
    onFetch: () => Promise<ApiResponse>;
    shouldRefresh?: TShouldRefresh<ApiResponse>;
}

export type TShouldRefresh<ApiResponse> = boolean | TShouldRefreshPreviousApiResponse<ApiResponse>;

export type TShouldRefreshPreviousApiResponse<ApiResponse> =
    (props: {
        prevResponse: ApiResponse;
        millisSinceResolved: number;
    }) => boolean;
