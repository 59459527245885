import React from 'react';
import { PolicyMeasureOfRisk } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { isPolicyOfTypeRiskBased } from '@console/bff/utils/policies/policyTypeUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { canUserCreateBasePolicy } from 'state/auth/apiEntityAuthorization.selectors';
import {
    getPolicyDetailsSchema,
    policyDetailsEntity,
    triggerCreatePolicy,
} from 'state/entities/portfolioMgmt/policyDetails';
import {
    getDefaultPolicyAlgorithm,
    getDefaultTenantCurrency,
    getPoliciesSettingsBoundariesConfig,
} from 'state/appConfig/selectors';
import { initPolicyForCreate } from 'state/entities/portfolioMgmt/policyDetailsFactory';
import Wizard, { IWizardStep } from 'views/common/flow/Wizard';
import WizardBackground from 'views/assets/img/backgrounds/policy_background.png';
import AddPage from 'views/common/add/AddPage';
import { redirectTo } from 'views/routes';
import { makeStyles } from 'views/styling';
import { ROUTE_KEY } from 'views/routeKeys';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { TAddPolicyFormValues } from '../PolicyDetail/policyFormContents/types';
import { getPolicyAddStepOneSchema } from '../PolicyDetail/policyDetailsSchema';
import {
    renderPolicyStepFiveAddFormContents,
    renderPolicyStepFourAddFormContents,
    renderPolicyStepOneAddFormContents,
    renderPolicyStepThreeAddFormContents,
    renderPolicyStepTwoAddFormContents,
} from './policyAddFormContents';

const FIELDS_LABEL_PREFIX = 'portfolio_mgmt.policies.detail';

function getPolicyAddWizardSteps(
    canUserCreateBasePolicies: boolean,
): IWizardStep<TAddPolicyFormValues>[] {
    return [
        {
            id: 'one',
            schema: {
                getSchema: () => getPolicyAddStepOneSchema({
                    isParentPolicyRequired: !canUserCreateBasePolicies,
                }),
            },
            renderFormFields: renderPolicyStepOneAddFormContents,
            onToNextStep: ({ values, initialValues }) => {
                /* eslint-disable no-param-reassign,prefer-destructuring,max-len */
                if (values.selectedParentPolicy) {
                    const parentSettings = values.selectedParentPolicy.coerced_config.algorithm_settings;

                    values.baseCurrency = parentSettings.execution_settings.base_currency;
                    values.benchmarkId = parentSettings.execution_settings.benchmark_id;
                    values.minCashAmount = parentSettings.portfolio_constraints.holdings.cash_amount[0];
                    values.maxCashAmount = parentSettings.portfolio_constraints.holdings.cash_amount[1];
                    values.minPortfolioOptimalityImprovement = parentSettings.portfolio_update_constraints.min_portfolio_update_optimality_improvement_fraction;
                    values.maxPortfolioConstraintsViolation = parentSettings.portfolio_update_constraints.max_portfolio_constraint_violation_pctpoints;
                    values.minPortfolioValue = parentSettings.portfolio_update_constraints.min_portfolio_value;
                } else {
                    values.baseCurrency = initialValues.baseCurrency;
                    values.benchmarkId = initialValues.benchmarkId;
                    values.minCashAmount = initialValues.minCashAmount;
                    values.maxCashAmount = initialValues.maxCashAmount;
                    values.minPortfolioOptimalityImprovement = initialValues.minPortfolioOptimalityImprovement;
                    values.maxPortfolioConstraintsViolation = initialValues.maxPortfolioConstraintsViolation;
                    values.minPortfolioValue = initialValues.minPortfolioValue;
                }
                /* eslint-enable no-param-reassign,prefer-destructuring,max-len */
                return values;
            },
        },
        {
            id: 'two',
            schema: {
                getSchema: ({ values }) => getPolicyDetailsSchema({
                    parentPolicy: values.selectedParentPolicy,
                })
                    .pick(['baseCurrency', 'benchmarkId']),
            },
            renderFormFields: renderPolicyStepTwoAddFormContents,
        },
        {
            id: 'three',
            schema: {
                getSchema: ({ values }) => getPolicyDetailsSchema({
                    parentPolicy: values.selectedParentPolicy,
                })
                    .pick(['modelPortfolioComposition', 'metaPortfolioComposition']),
            },
            renderFormFields: renderPolicyStepThreeAddFormContents,
            disableStep: ({ values }) => isPolicyOfTypeRiskBased(values.algorithm),
        },
        {
            id: 'four',
            schema: {
                getSchema: ({ values }) => getPolicyDetailsSchema({
                    parentPolicy: values.selectedParentPolicy,
                })
                    .pick([
                        'minPortfolioOptimalityImprovement', 'maxPortfolioConstraintsViolation',
                        'minCashAmount', 'maxCashAmount', 'minPortfolioValue',
                    ]),
            },
            renderFormFields: renderPolicyStepFourAddFormContents,
        },
        {
            id: 'five',
            hint: 'portfolio_mgmt.policies.add.last_step_hint',
            schema: {
                getSchema: ({ values }) => getPolicyDetailsSchema({
                    parentPolicy: values.selectedParentPolicy,
                })
                    .pick(['name']),
            },
            renderFormFields: renderPolicyStepFiveAddFormContents,
        },
    ];
}

const useStyles = makeStyles((theme) => ({
    PolicyAdd: {
        '& .compositionInfoSpacing': {
            marginTop: theme.spacing(3),
        },
    },
}));

export default function PolicyAdd() {
    const tenantSettingsConfig = getPoliciesSettingsBoundariesConfig();
    const classes = useStyles();

    const initialValues: TAddPolicyFormValues = {
        algorithm: getDefaultPolicyAlgorithm(),
        measureOfRisk: PolicyMeasureOfRisk.IVAR,
        name: '',
        parentPolicyId: null,
        selectedParentPolicy: null,
        baseCurrency: getDefaultTenantCurrency(),
        benchmarkId: null,
        minPortfolioOptimalityImprovement: tenantSettingsConfig?.minPortfolioOptimalityImprovementFraction.default,
        maxPortfolioConstraintsViolation: tenantSettingsConfig?.maxPortfolioConstraintsViolationFraction.default,
        minPortfolioValue: tenantSettingsConfig?.minPortfolioValue.default,
        minCashAmount: tenantSettingsConfig?.cashAmount.default[0],
        maxCashAmount: tenantSettingsConfig?.cashAmount.default[1],
        modelPortfolioComposition: {},
        metaPortfolioComposition: {},
    };

    const wizardSteps = getPolicyAddWizardSteps(canUserCreateBasePolicy());

    return (
        <AddPage
            entity={{
                notifications: [StateChangeNotification.POLICY_DETAILS_DATA],
                asyncEntitySelector: policyDetailsEntity.select,
            }}
            titleLabel="portfolio_mgmt.policies.add.title"
            noPadding
            cancel={{
                onCancel: redirectToList,
            }}
            className={classes.PolicyAdd}
        >
            <Wizard<TAddPolicyFormValues>
                name="add-policy-wizard"
                backgroundImagePath={WizardBackground}
                backgroundImageSize={500}
                cancel={{
                    onCancel: redirectToList,
                }}
                steps={wizardSteps}
                labelPrefix={FIELDS_LABEL_PREFIX}
                initialValues={initialValues}
                onWizardComplete={createPolicy}
                completeWizardActionLabel="portfolio_mgmt.policies.add.submit"
            />
        </AddPage>
    );

    function createPolicy({ values }: IOnSubmitProps<TAddPolicyFormValues>) {
        return triggerCreatePolicy(
            initPolicyForCreate({
                addFormValues: values,
            }),
        );
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_POLICIES_LIST,
        });
    }
}
