import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import { BUTTON_COLORS } from 'config/styling/colors';
import { TLabel } from 'models/general.models';
import { toCssBorder } from 'views/assets/cssInJs/mixins/box';
import {
    TAskConfirmation,
    IAskConfirmationConfig,
    TButtonColor,
    TButtonSize,
    TButtonState,
    TButtonVariant,
} from './types';

export const BORDER_PX = 2;
export const BORDER_RADIUS = 8;

export function getButtonBackgroundColor({
    color,
    state,
}: {
    color: TButtonColor;
    state?: TButtonState;
}) {
    const flavor = color.toUpperCase();

    if (state === 'hover') {
        return BUTTON_COLORS[flavor].BACKGROUND_HOVER;
    }
    if (state === 'focus') {
        return BUTTON_COLORS[flavor].BACKGROUND_FOCUS;
    }
    if (state === 'disabled') {
        return BUTTON_COLORS[flavor].BACKGROUND_DISABLED;
    }

    return BUTTON_COLORS[flavor].BACKGROUND;
}

export function getButtonBorderColor({
    color,
    state,
}: {
    color: TButtonColor;
    state?: TButtonState;
}) {
    if (state === 'focus') {
        return BUTTON_COLORS[color.toUpperCase()].BORDER_FOCUS;
    }

    return getButtonBackgroundColor({ color, state });
}

export function getButtonBorder({
    color,
    state,
}: {
    color: TButtonColor;
    state?: TButtonState;
}) {
    const borderColor = getButtonBorderColor({ color, state });
    return toButtonBorder(borderColor);
}

export function toButtonBorder(borderColor: string) {
    return toCssBorder({
        width: BORDER_PX,
        color: borderColor,
    });
}

export function getButtonFontSizeInPx({
    size,
}: {
    size: TButtonSize;
}): number {
    switch (size) {
        case 'XL':
            return 18;
        case 'L':
            return 17;
        case 'MD':
            return 16;
        case 'S':
            return 12;
        case 'XS':
            return 10;
        default:
            return 14;
    }
}

export function getButtonTextColor({
    variant,
    color,
    state,
}: {
    variant: TButtonVariant;
    color: TButtonColor;
    state?: TButtonState;
}) {
    const flavor = color.toUpperCase();

    if (variant === 'filled') {
        if (state === 'hover') {
            return BUTTON_COLORS[flavor].TEXT_HOVER;
        }
        if (state === 'focus') {
            return BUTTON_COLORS[flavor].TEXT_FOCUS;
        }
        if (state === 'disabled') {
            return BUTTON_COLORS[flavor].TEXT_DISABLED;
        }
        return BUTTON_COLORS[flavor].TEXT;
    }

    if (variant === 'bare') {
        if (state === 'hover') {
            return BUTTON_COLORS[flavor].BARE_HOVER;
        }
    }

    return getButtonBackgroundColor({ color, state });
}

export function getButtonIconColor({
    variant,
    color,
    state,
}: {
    variant: TButtonVariant;
    color: TButtonColor;
    state?: TButtonState;
}) {
    return getButtonTextColor({ variant, color, state });
}

export function enrichAskConfirmationLabelIfNotProvided(
    askConfirmationInput: TAskConfirmation,
    confirmLabelToUseIfNotSet: TLabel,
): TAskConfirmation {
    if (askConfirmationInput === false) {
        return false;
    }

    const askConfirmationInputObj = isObjectPure<IAskConfirmationConfig>(askConfirmationInput)
        ? askConfirmationInput
        : {};

    return {
        confirmLabel: confirmLabelToUseIfNotSet,
        ...askConfirmationInputObj,
    };
}

export function toAskConfirmationConfig(
    askConfirmation: TAskConfirmation,
): IAskConfirmationConfig {
    return isObjectPure<IAskConfirmationConfig>(askConfirmation)
        ? askConfirmation
        : {};
}
