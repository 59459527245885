import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { IFetchUsersApiInput, IAdvancedUserFilters, TUsersData } from '@console/core-api/models/userMgmt/user.models';
import { IBaseSingleEntityApiInput } from '@console/core-api/models/api.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { USER_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { getDeepestPathParamNameOfCurrentRoute, isRouteLocation } from 'state/ui/selectors';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { errorFlashDispatcher } from 'state/flashDispatcher';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { presentFileForDownload } from 'utils/file/presentFileForDownload';
import { ROUTE_KEY } from 'views/routeKeys';

export const usersEntity = getEntitiesManager().registerEntity<TUsersData>({
    asyncEntityKey: AsyncEntityKeys.users,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.USERS_DATA],
    includeUpdaters: true,
});

export const triggerResetUsersFetch = () => usersEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchUsers({
    forceRefresh = false,
    ...apiInput
}: IFetchUsersApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchUsersGeneric({
        apiInput,
        pageKey: UiPageKey.usersList,
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_USER_DETAIL,
        }) || isRouteLocation(state, {
            prevParams: {
                userTab: USER_TAB_KEY.ACCESSIBLE_BY,
            },
        }),
    });
}

export function triggerFetchUsersForAccessibleBy({
    forceRefresh = false,
    ...apiInput
}: IFetchUsersApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchUsersGeneric({
        apiInput,
        pageKey: UiPageKey.accessibleByUsersList,
        refreshMode: ({ state }) => {
            if (forceRefresh) {
                return true;
            }

            const deepestTabParam = getDeepestPathParamNameOfCurrentRoute(state, 'tab');

            return !isRouteLocation(state, {
                paramsSameAsPrevRoute: [deepestTabParam],
            });
        },
    });
}

/**
 * For the users-list in the members tab of a user group, we re-use the same users entity,
 * but use a separate trigger (with separate ui vars)
 */
export function triggerFetchUserGroupMembers({
    forceRefresh = false,
    ...apiInput
}: IFetchUsersApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchUsersGeneric({
        apiInput,
        pageKey: UiPageKey.userGroupMembersList,
        refreshMode: ({ state }) => forceRefresh || isRouteLocation(state, {
            isPrevRouteKeySameAsCurrent: false,
        }),
    });
}

export function triggerFetchUsersForSearch(apiInput?: IFetchUsersApiInput) {
    return triggerFetchUsersGeneric({
        apiInput,
        pageKey: UiPageKey.userSearchList,
        refreshMode: 'always',
    });
}

export function findSpecificUser({ id }: IBaseSingleEntityApiInput) {
    const usersData = usersEntity.select().data;

    if (!usersData || !isArrayWithValues(usersData.results)) {
        return null;
    }

    return usersData.results.find((singleUser) => singleUser.id === id);
}

export function triggerExportUsers() {
    return api.users.exportUsers()
        .then(presentFileForDownload)
        .catch(errorFlashDispatcher('error.download_failed'));
}

function triggerFetchUsersGeneric({
    apiInput,
    pageKey,
    refreshMode,
}: {
    apiInput?: IFetchUsersApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return usersEntity.async.fetch<IFetchUsersApiInput>({
        api: api.users.fetchUsers,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchUsersApiInput, IAdvancedUserFilters>({
            pageKey,
            state,
            apiInput,
            mapSimpleFilterValue: (simpleFilterValue) => ({
                email: simpleFilterValue,
            }),
            mapAdvancedFilterValues: (advancedFilterValues) => ({
                email: advancedFilterValues.email,
                externalId: advancedFilterValues.externalId,
                brokerageUserId: advancedFilterValues.brokerageUserId,
                virtualIban: advancedFilterValues.virtualIban,
                lastName: advancedFilterValues.lastName,
                phoneNumber: advancedFilterValues.phoneNumber,
                featureFlags: advancedFilterValues.featureFlags,
            }),
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: usersEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}
