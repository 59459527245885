import { object, array, string } from '@console/common/utils/schema';

const operationPermission = string().required();
const userId = string().required();

export const userGroupDetailsSchema = object({
    name: string().required().trim(),
    externalId: string(),
    permissions: array().of(operationPermission)
        .unique({ uniqueLabelForDisplay: 'permissions' }),
    user_ids: array().of(userId)
        .unique({ uniqueLabelForDisplay: 'group members' }),
}).required();

export const userGroupPermissionsSchema = userGroupDetailsSchema.pick(['permissions']);
export const userGroupMembersSchema = userGroupDetailsSchema.pick(['user_ids']);
