import React from 'react';
import { IUserGroupEntityData } from '@console/core-api/models/userMgmt/userGroup.models';
import { SIZES } from 'config/styling/sizes';
import { makeStyles } from 'views/styling';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { getSubmitActionLabelBasedOnMode } from 'utils/entities/entityDetailUtils';
import { userGroupDetailsSchema } from './userGroupDetailsSchema';

/**
 * This form is to be re-used for both "adding" as "updating".
 */

interface IPublicProps
    extends Pick<IExtendedInputFormProps<IUserGroupFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
    mode: TPropertiesFormDisplayMode;
}

export interface IUserGroupFormValues extends IFormValues {
    name: string;
    externalId: string;
}

const LABEL_PREFIX = 'user_mgmt.user_groups.detail';

const useStyles = makeStyles((/* theme */) => ({
    UserGroupMainForm: {},
}));

export default function UserGroupPropertiesForm({
    mode,
    submit,
    ...formProps
}: IPublicProps) {
    const classes = useStyles();

    return (
        <ExtendedInputForm<IUserGroupFormValues>
            name={ExtendedInputFormName.userGroupProperties}
            className={classes.UserGroupMainForm}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: getSubmitActionLabelBasedOnMode(mode),
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderUserGroupFormFields}
            schema={userGroupDetailsSchema}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );
}

export function renderUserGroupFormFields({
    fields,
}: IExtendedInputFormContext<IUserGroupFormValues>) {
    return (
        <>
            <ExtendedInputText<string>
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.label',
                }}
            />
            <ExtendedInputText<string>
                formField={fields.externalId}
                wrapper={{
                    label: 'fields.external_id.label',
                }}
            />
        </>
    );
}

export function mapUserGroupFormValuesToApiEntity(
    formValues: IUserGroupFormValues,
): Pick<IUserGroupEntityData, 'name' | 'external_id'> {
    return {
        name: formValues.name,
        external_id: formValues.externalId,
    };
}
