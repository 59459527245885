import { createRange } from 'utils/number/range';

export const POLICY_INVESTMENT_RANGE = [
    ...createRange(10000, 250000, 10000),
    ...createRange(300000, 1000000, 50000),
];

export const POLICY_HORIZON_RANGE = createRange(5, 30, 5);

export const DEFAULT_POLICY_INVESTMENT_AMOUNT = 10000;
export const DEFAULT_POLICY_HORIZON_YEARS = 10;
