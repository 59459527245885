import React from 'react';
import { triggerPatchUserDetails, userDetailsEntity } from 'state/entities/userMgmt/userDetails';
import { triggerResetClientsFetch } from 'state/entities/userMgmt/clients';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function ClientAccessibleBy() {
    const clientDetailsData = userDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={clientDetailsData}
            nameForDisplay={getUserFullName(clientDetailsData)}
            asyncEntityPatchTrigger={({ readable_by, modifiable_by }) => triggerPatchUserDetails((currentUser) => {
                /* eslint-disable no-param-reassign */
                currentUser.readable_by = readable_by;
                currentUser.modifiable_by = modifiable_by;
                /* eslint-enable no-param-reassign */
            }, {
                onPreSuccess: triggerResetClientsFetch,
            })}
        />
    );
}
