import React from 'react';
import SideNavigationFilters from './SideNavigationFilters';
import SideNavigationOutputKeys from './SideNavigationOutputKeys';
import SideNavigationTitle from './SideNavigationTitle';

export default function SideNavigation() {
    return (
        <div>
            <SideNavigationFilters />
            <SideNavigationTitle />
            <SideNavigationOutputKeys />
        </div>
    );
}
