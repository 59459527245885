import React from 'react';
import { SIZES } from 'config/styling/sizes';
import ErrorWidget from 'views/common/error/ErrorWidget';
import { redirectToHome } from 'views/routes';
import { makeStyles, mixins } from 'views/styling';

const useStyles = makeStyles((/* theme */) => ({
    NotFound: {
        ...mixins.heightMax(),
        marginTop: `-${SIZES.BODY.HEADER_HEIGHT}px`,
    },
}));

export default function NotFound() {
    const classes = useStyles();

    return (
        <div className={classes.NotFound}>
            <ErrorWidget
                centerVertically
                title={{
                    label: 'error.page_not_found',
                }}
                action={{
                    label: 'app_shell.error.widget.action_to_home',
                    onExecute: redirectToHome,
                }}
            />
        </div>
    );
}
