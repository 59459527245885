export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TObjectWithProps<Value = any> = {
    [key: string]: Value;
};

/* No operation */
export const NOOP = (): void => {};
