import { IParseDateOptions, parseInputDate, TDateToFormat } from './dateUtils';

export const DATE_FORMAT = {
    MONTH_YEAR_SHORT: 'MM/YY',
    MONTH_DAY_YEAR: 'MM/DD/YYYY',
    MONTH_DAY_YEAR_SHORT: 'MM/DD/YY',
    MONTH_DAY: 'MMM DD',
    MONTH_DAY_SHORT: 'MM/DD',
    BACK_END: 'YYYY-MM-DD',
    DAY_MONTH_YEAR: 'DD.MM.YYYY',
    DAY_MONTH_YEAR_WITH_TIME: 'DD.MM.YYYY H:mm',
    DAY_MONTH_YEAR_WITH_TIME_AND_ZONE: 'DD.MM.YYYY H:mm Z',
    WITH_TIME: 'MM/DD/YYYY H:mm',
    WEEKDAY_DAY_MONTH_SHORT_HR_WITH_MINS: 'ddd D MMM H:mm',
    TIMESTAMP: 'YYYYMMDD-HHmmss',
    /**
     * E.g. 2022-05-12T14:54:10.987590+00:00
     *
     * Some explanation:
     *   .SSS[000]
     *     In the core api the datetimes have the microseconds after the dot, which is a 6-digit number,
     *     but as dayjs only supports 3-digit-milliseconds (SSS) and not microseconds, we just append 3 zero's
     *   Z
     *     The timezone = offset from UTC. e.g. +02:00
     */
    BACK_END_DATETIME: 'YYYY-MM-DD[T]HH:mm:ss.SSS[000]Z',
    TIMEZONE_DATETIME: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
    WEEK_NUMBER: '[W]W', /* W = 'ISO Week of year' - see https://day.js.org/docs/en/plugin/advanced-format */
    QUARTER_OF_YEAR: '[Q]Q[ - ]YYYY', /* Q = 'Quarter' - see https://day.js.org/docs/en/plugin/advanced-format */
};

export const TIME_FORMAT = {
    SHORT_HR_WITH_MINS: 'H:mm',
    LONG_HR_WITH_MINS: 'HH:mm',
};

const CALENDAR_RELATIVE_DATE_FORMATS = {
    sameDay: `[Today at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The same day ( Today at 2:30 )
    nextDay: `[Tomorrow at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The next day ( Tomorrow at 14:30 )
    nextWeek: `[Next] dddd [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The next week ( Next Sunday at 2:30 )
    lastDay: `[Yesterday at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The day before ( Yesterday at 2:30 )
    // lastWeek: `[Last] dddd [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // Last week ( Last Monday at 2:30 )
    lastWeek: DATE_FORMAT.MONTH_DAY_YEAR, // see 'sameElse'
    sameElse: DATE_FORMAT.MONTH_DAY_YEAR, // Everything else ( 10/17/2011 )
};

const CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME = {
    ...CALENDAR_RELATIVE_DATE_FORMATS,
    lastWeek: `${DATE_FORMAT.MONTH_DAY_YEAR} [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // see 'sameElse'
    sameElse: `${DATE_FORMAT.MONTH_DAY_YEAR} [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // Everything else ( 10/17/2011 )
};

export function formatDate({
    date,
    format = DATE_FORMAT.MONTH_DAY_YEAR,
    options = {},
}: {
    date: TDateToFormat;
    format?: string;
    options?: IParseDateOptions;
}) {
    return parseInputDate(date, options)
        .format(format);
}

interface IFormatDateRelativeOptions {
    alwaysIncludeTime?: boolean; // default false
}

export function formatDateRelativeTo({
    date,
    referenceDate,
    options = {},
}: {
    date: TDateToFormat;
    referenceDate: TDateToFormat;
    options?: IParseDateOptions & IFormatDateRelativeOptions;
}) {
    if (!date) {
        return null;
    }

    const {
        alwaysIncludeTime = false,
        ...parseOptions
    } = options;

    return parseInputDate(date, parseOptions)
        /* see https://day.js.org/docs/en/plugin/calendar */
        .calendar(
            referenceDate,
            alwaysIncludeTime ? CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME : CALENDAR_RELATIVE_DATE_FORMATS,
        );
}

export function formatDateRelativeToNow({
    date,
    options = {},
}: {
    date: TDateToFormat;
    options?: IParseDateOptions & IFormatDateRelativeOptions;
}) {
    return formatDateRelativeTo({
        date,
        referenceDate: null, // = NOW
        options,
    });
}

/**
 * Returns a date in the format of YYYY-MM-DDTHH:mm:ss.sssZ
 * (where the timezone is always zero UTC offset)
 */
export function formatDateForBE(date: Date) {
    if (!date) {
        return null;
    }

    return new Date(date).toISOString();
}

/**
 * Returns a date in the format of YYYY-MM-DDTHH:mm:ss.SSS000+00.00
 * To be used if you really need a data in the exact format as python uses, e.g. to get a datetime value
 * that will be stored in the database.
 *
 * TODO As this is the format returned by the BE, do we still need the similar formatDateForBE function
 * TODO     which is used to format e.g. a date filter?
 */
export function formatDateAsBackendDatetime(date: TDateToFormat) {
    if (!date) {
        return null;
    }

    return parseInputDate(date)
        .utc() /* converts the date to the utc timezone as all dates are stored in the BE as UTC */
        .format(DATE_FORMAT.BACK_END_DATETIME);
}
