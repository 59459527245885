import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import isSet from '@snipsonian/core/cjs/is/isSet';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { ENABLE_USE_EXPECTED_RETURNS_OPTION } from '@console/core-api/config/coreApi.config';
import {
    mapEnhancedToCoreApiPolicyAlgorithmSettings,
} from '@console/bff/server/utils/policies/policyEnhancedSettingsUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { POLICY_HORIZON_RANGE, POLICY_INVESTMENT_RANGE } from 'config/portfolioMgmt/policySimulation.config';
import { policyDetailsEntity } from 'state/entities/portfolioMgmt/policyDetails';
import { triggerFetchPolicyFuturePerformance } from 'state/entities/portfolioMgmt/policyPerformanceFuture';
import { triggerFetchPolicySimulationDetails } from 'state/entities/portfolioMgmt/policySimulation';
import {
    updatePolicySimulationHorizonPageVar,
    updatePolicySimulationInvestmentPageVar,
    updatePolicySimulationExpectedReturnsPageVar,
} from 'state/ui/uiPages.actions';
import { getPolicySimulationPageVars } from 'state/ui/uiPages.selectors';
import { flashErrorInvalidPolicySettings } from 'state/entities/entitiesFlashDispatcher';
import { getUnsavedPolicySettingsIfAny } from 'utils/entities/portfolioMgmt/policyUtils';
import { yearOffset } from '@console/common/utils/date/getSpecificDate';
import TextButton from 'views/common/buttons/TextButton';
import { RenderIcon } from 'views/common/icons';
import InputSelectField, { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import { IObserveProps, observe } from 'views/observe';
import InputToggleField from 'views/common/inputs/base/InputToggleField';

const investmentRangeEntities: IInputSelectItem<number>[] = POLICY_INVESTMENT_RANGE.map((element) => ({
    value: element,
    label: formatFloat(element, { nrOfDecimals: 0 }),
}));

interface IPublicProps {
    translationPrefix: string;
    isDisplayedOnModal?: boolean; // default false
    onRunSimulation?: () => void;
}

function PolicySimulationFilters({
    translationPrefix,
    isDisplayedOnModal = false,
    onRunSimulation,
    dispatch,
    state,
}: IPublicProps & IObserveProps) {
    const policyDetailsData = policyDetailsEntity.select().data;
    /*
        Chose the parent config over the child config for the optimization,
        as that is what will be used by the backend anyway. Exception: The model and meta settings
        are enhanced by the BFF in only the config (not coerced_config) so we use that as input. This is not
        a problem as there are no inheritance rules regarding model or meta settings.
    */
    const policyAlgorithmSettings = isSet(policyDetailsData.parent_policy_id)
        ? {
            ...policyDetailsData.coerced_config.algorithm_settings,
            model_portfolio_settings: policyDetailsData.config.algorithm_settings.model_portfolio_settings,
            meta_portfolio_settings: policyDetailsData.config.algorithm_settings.meta_portfolio_settings,
        }
        : policyDetailsData.config.algorithm_settings;
    const { base_currency: currency } = policyAlgorithmSettings.execution_settings;
    const { policyId } = useParams<IPossiblePathParams>();
    const pageVars = getPolicySimulationPageVars(state);

    const horizonRangeEntities: IInputSelectItem<number>[] = POLICY_HORIZON_RANGE.map((element) => ({
        value: element,
        label: {
            msg: `${translationPrefix}.dropdowns.horizonYears`,
            placeholders: {
                nrOfYears: element,
            },
        },
    }));

    return (
        <>
            <Box
                display="flex"
                justifyContent="start"
                flexWrap="wrap"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    className="entityChoice"
                >
                    <Translate
                        msg={`${translationPrefix}.dropdowns.investment`}
                        placeholders={{
                            currency,
                        }}
                    />
                    <InputWrapper
                        noPadding
                        className="entityChoiceSelect"
                        minHeight={32}
                    >
                        <InputSelectField<number>
                            items={investmentRangeEntities}
                            value={pageVars.investmentAmount}
                            onChange={onInvestmentEntityChange}
                        />
                    </InputWrapper>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    className="entityChoice"
                >
                    <Translate msg={`${translationPrefix}.dropdowns.horizon`} />
                    <InputWrapper
                        noPadding
                        className="entityChoiceSelect"
                        minHeight={32}
                    >
                        <InputSelectField<number>
                            items={horizonRangeEntities}
                            value={pageVars.horizonYears}
                            onChange={onHorizonEntityChange}
                            itemLabelsAreTranslationKeys
                        />
                    </InputWrapper>
                </Box>
                {ENABLE_USE_EXPECTED_RETURNS_OPTION && (
                    <Box display="flex" flexDirection="column" className="entityChoice">
                        <Translate msg={`${translationPrefix}.toggles.use_expected_returns`} />
                        <InputWrapper noPadding minHeight={32} className="expectedReturnsToggle">
                            <InputToggleField
                                checked={pageVars.useExpectedReturns}
                                name="use-expected-returns-toggle"
                                onChange={({ checked }) => onUseExpectedReturnsChange(checked)}
                            />
                        </InputWrapper>
                    </Box>
                )}
            </Box>
            <Box className="bottomSimulationControls">
                {isDisplayedOnModal && (
                    <div className="displayCurrency">
                        <Translate msg={`${translationPrefix}.currency`} placeholders={{ currency }} />
                    </div>
                )}
                <div className="runSimulationButton">
                    <TextButton
                        id="run-simulation"
                        label={{
                            msg: `${translationPrefix}.button.label`,
                        }}
                        variant="filled"
                        size="S"
                        onClick={handleRunSimulation}
                        icon={<RenderIcon className="btn-icon" />}
                        svgSize={24}
                        fontSize="L"
                    />
                </div>
            </Box>
        </>
    );

    function onInvestmentEntityChange({ value }: IOnChangeTextInputProps<number>) {
        dispatch(updatePolicySimulationInvestmentPageVar({
            investmentAmount: value,
        }));
    }

    function onHorizonEntityChange({ value }: IOnChangeTextInputProps<number>) {
        dispatch(updatePolicySimulationHorizonPageVar({
            horizonYears: value,
        }));
    }

    function onUseExpectedReturnsChange(value: boolean) {
        dispatch(updatePolicySimulationExpectedReturnsPageVar({
            useExpectedReturns: value,
        }));
    }

    function handleRunSimulation() {
        const { settings, areThereUnsavedSettings, isFormValid } = getUnsavedPolicySettingsIfAny();
        const algorithmSettingsForSimulation = areThereUnsavedSettings ? settings : policyAlgorithmSettings;

        if (!isFormValid && areThereUnsavedSettings) {
            flashErrorInvalidPolicySettings({ error: null });
        } else {
            triggerFetchPolicyFuturePerformance({
                policyId,
                startAmount: pageVars.investmentAmount,
                useExpectedReturns: pageVars.useExpectedReturns,
                startDate: formatDate({
                    date: yearOffset({ yearsToAdd: 0 }),
                    format: DATE_FORMAT.BACK_END,
                }),
                endDate: formatDate({
                    date: yearOffset({ yearsToAdd: pageVars.horizonYears }),
                    format: DATE_FORMAT.BACK_END,
                }),
                forceRefresh: true,
            });

            triggerFetchPolicySimulationDetails({
                policyId,
                algorithmSettings: mapEnhancedToCoreApiPolicyAlgorithmSettings({
                    enhancedAlgorithmSettings: algorithmSettingsForSimulation,
                    algorithmType: policyDetailsData.config.algorithm,
                }),
                investmentAmount: pageVars.investmentAmount,
                forceAdHocCalculation: areThereUnsavedSettings,
            });

            if (onRunSimulation) {
                onRunSimulation();
            }
        }
    }
}

export default observe<IPublicProps>(
    [StateChangeNotification.UI_PAGE_POLICY_SIMULATION],
    PolicySimulationFilters,
);
