import { IColValues, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import React from 'react';
import {
    publicInstrumentGroupDetailsEntity,
    triggerPatchPublicInstrumentGroupDetails,
} from 'state/entities/thematicSearch/publicInstrumentGroupDetails';
import { triggerFetchPublicInstrumentGroupInstruments } from 'state/entities/thematicSearch/publicInstruments';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IFormValues,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import GenericPublicInstrumentsList, {
    DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX,
    getDefaultPublicInstrumentCols,
} from 'views/thematicSearch/PublicInstruments/GenericPublicInstrumentsList';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import { publicInstrumentGroupInstrumentsSchema } from './publicInstrumentGroupDetailsSchema';

interface IPublicInstrumentGroupInstrumentsCols extends IColValues {
    selected: boolean;
    displayName: string;
    type: string;
    externalId: string;
    updated: string;
}

interface IInstrumentsState {
    [instrumentId: string]: boolean;
}

interface IPublicInstrumentGroupInstrumentsExtraData {
    changeInstrumentGroupInstruments: (isin: string) => void;
}

interface IPublicInstrumentGroupInstrumentsFormValues extends IFormValues {
    instrumentIds: string[];
}

const DEFAULT_PUBLIC_INSTRUMENT_COLS = getDefaultPublicInstrumentCols({
    desiredCols: [
        { colName: 'type', percentWidth: 20 },
        { colName: 'externalId', percentWidth: 20 },
        { colName: 'updated', percentWidth: 20 },
    ],
});

const COLS: TDataColumns<IPublicInstrumentGroupInstrumentsCols, IPublicInstrumentGroupInstrumentsExtraData> = {
    selected: {
        label: EMPTY_COL_TITLE,
        data: {
            render: ({ item }) => (
                <InputToggleField
                    name={item.id}
                    checked={item.colValues.selected}
                    onChange={() => item.extra.changeInstrumentGroupInstruments(item.id)}
                />
            ),
        },
        percentWidth: 5,
        align: 'right',
    },
    externalId: DEFAULT_PUBLIC_INSTRUMENT_COLS.externalId,
    displayName: {
        label: {
            msg: `${DEFAULT_PUBLIC_INSTRUMENT_COL_TRANSLATION_PREFIX}.name`,
        },
        percentWidth: 35,
    },
    type: DEFAULT_PUBLIC_INSTRUMENT_COLS.type,
    updated: DEFAULT_PUBLIC_INSTRUMENT_COLS.updated,
};

export function PublicInstrumentGroupInstrumentsTab() {
    const initialPublicInstrumentGroupInstruments =
            (publicInstrumentGroupDetailsEntity.select().data.instrument_ids as string[]) || [];

    return (
        <ExtendedInputForm<IPublicInstrumentGroupInstrumentsFormValues>
            name="instrument-group-instruments-form"
            initialValues={{
                instrumentIds: initialPublicInstrumentGroupInstruments,
            }}
            submit={{
                onSubmit: patchPublicInstrumentGroupInstruments,
            }}
            schema={publicInstrumentGroupInstrumentsSchema}
            renderFormFields={renderList}
            reset={{}}
            placeFormActionsInFixedFooter
        />
    );

    function renderList({
        setFieldValue,
        fields,
    }: IExtendedInputFormContext<IPublicInstrumentGroupInstrumentsFormValues>) {
        const instruments = (fields.instrumentIds.value as string[]) || [];
        const instrumentsState = instruments.reduce(
            (accumulator, isin) => {
                accumulator[isin] = true;

                return accumulator;
            },
            {} as IInstrumentsState,
        );

        return (
            // eslint-disable-next-line max-len
            <GenericPublicInstrumentsList<IPublicInstrumentGroupInstrumentsCols, IPublicInstrumentGroupInstrumentsExtraData>
                overrideUiVars={{
                    uiVarsNotification: StateChangeNotification.PUBLIC_INSTRUMENT_GROUP_INSTRUMENTS_UI_VARS,
                    uiPageKey: UiPageKey.publicInstrumentGroupInstrumentsList,
                }}
                overrideEntity={{
                    asyncListEntityFetchTrigger: triggerFetchPublicInstrumentGroupInstruments,
                }}
                overrideCols={{
                    cols: COLS,
                    toPublicInstrumentListItem: ({ defaultPublicInstrumentCols, publicInstrument }) => ({
                        id: publicInstrument.id,
                        colValues: {
                            selected: instrumentsState[publicInstrument.id],
                            externalId: defaultPublicInstrumentCols.externalId,
                            displayName: publicInstrument.display_name,
                            type: defaultPublicInstrumentCols.type,
                            updated: defaultPublicInstrumentCols.updated,
                        },
                        extra: {
                            changeInstrumentGroupInstruments,
                        },
                    }),
                }}
                includeRefreshButton={false}
            />
        );

        function changeInstrumentGroupInstruments(isin: string) {
            if (instrumentsState[isin]) {
                delete instrumentsState[isin];
            } else {
                instrumentsState[isin] = true;
            }
            const newInstruments = Object.entries(instrumentsState)
                .map(([instrumentId]) => instrumentId);
            setFieldValue({
                fieldName: fields.instrumentIds.fieldName,
                value: newInstruments,
            });
        }
    }

    function patchPublicInstrumentGroupInstruments({
        values,
    }: IOnSubmitProps<IPublicInstrumentGroupInstrumentsFormValues>) {
        return triggerPatchPublicInstrumentGroupDetails((currentInstrumentGroup) => {
            /* eslint-disable no-param-reassign */
            currentInstrumentGroup.instrument_ids = values.instrumentIds;
            /* eslint-enable no-param-reassign */
        });
    }
}
