import { hexToRgba } from 'utils/styling/colorUtils';
import { APP_COLORS } from 'config/styling/colors';

export const Z_INDEX = {
    DETAIL_PAGE_FOOTER: 100,
    DETAIL_PAGE_FOOTER_FORM_ACTIONS: 110, // higher than DETAIL_PAGE_FOOTER !
    MODAL: 1300, // 1300 is the default z-index of the material-ui Dialog component
    FOOTER: 3000,
    CONFIRM_SUBMIT_EXTENDED_FORM: 8000,
    SPINNER_OVERLAY: 10000,
    POPOVER: 11000,
    ROUTE_LEAVE_PROMPT: 15000, // higher than the SPINNER_OVERLAY so that you can leave the route while spinning
};

export const BOX_SHADOW = {
    DEFAULT: '0 12px 24px rgba(0, 0, 0, 0.6)',
    CARD: `0 1px 4px ${hexToRgba(APP_COLORS.GREY['100'], 1)}`,
    BUTTON: `0 6px 16px ${hexToRgba(APP_COLORS.NAV.DARK, 0.5)}`,
    BUTTON_LARGE: `0 12px 24px ${hexToRgba(APP_COLORS.NAV.DARK, 0.6)}`,
};

export const FOCUS_OUTLINE = `2px dashed ${APP_COLORS.PRIMARY['500']}`;
export const FOCUS_OUTLINE_OFFSET = 2;
