import React from 'react';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import isPromise from '@snipsonian/core/cjs/is/isPromise';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { BOX_SHADOW, Z_INDEX } from 'config/styling/elevation';
import useIsMounted from 'utils/react/hooks/useIsMounted';
import { makeStyles, mixins } from 'views/styling';
import TextButton, { ITextButtonProps } from 'views/common/buttons/TextButton';
import IconButton from 'views/common/buttons/IconButton';
import { InfoIcon, MoreIcon } from 'views/common/icons';
import Card from 'views/common/layout/Card';
import { TI18nLabelOrString } from 'models/general.models';
import { stopPropagation } from 'utils/browser/eventUtils';
import Tooltip, { TTooltipPlacement } from '../widget/Tooltip';

interface IPublicProps {
    className?: string;
    actions: IActionItem[];
    moreVariant?: 'bare';
}

export interface IActionItem
    // eslint-disable-next-line max-len
    extends Pick<ITextButtonProps, 'id' | 'label' | 'icon' | 'disabled' | 'askConfirmation' | 'showLoaderWhenAsync'> {
    /* the 'label' will be used for the tooltip if it's an icon button */
    onExecute: () => unknown | Promise<unknown>;
    tooltip?: TI18nLabelOrString;
    tooltipPlacement?: TTooltipPlacement;
    /**
     * 'variant' determines if the action is either:
     * - a directly shown icon button ('main-icon')
     * - a directly shown text button ('main-text')
     * - an item in the action menu behind the 3-dots-button ('extra')
     */
    variant?: 'main-icon' | 'main-icon-grey' | 'main-text' | 'main-icon-bare' | 'extra'; // default 'extra'
}

const useStyles = makeStyles((theme) => ({
    ActionButtons: {
        ...mixins.flexRowCenterRight(),

        '& .main-action-button': {
            marginLeft: theme.spacing(1),
        },
    },
    extraActionsPopoverWrapper: {
        zIndex: Z_INDEX.POPOVER,

        '& .MuiPopover-paper': {
            // minWidth: 220,
            borderRadius: 12,
            boxShadow: BOX_SHADOW.DEFAULT,
            marginTop: theme.spacing(1),
        },
    },
}));

export default function ActionButtons({
    className,
    actions,
    moreVariant,
}: IPublicProps) {
    const classes = useStyles();
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
    const isExtraActionsPopperOpen = Boolean(popoverAnchorEl);
    const isMounted = useIsMounted();

    if (!isArrayWithValues(actions)) {
        return null;
    }

    const mainActions = actions.filter((action) => action.variant !== 'extra');
    const extraActions = actions.filter((action) => action.variant === 'extra');

    return (
        <div className={clsx(classes.ActionButtons, className)}>
            {mainActions.map((mainAction) => {
                const key = `main-action_${mainAction.id}`;
                const { variant, onExecute, ...otherButtonProps } = mainAction;

                if (variant === 'main-text') {
                    return (
                        <TextButton
                            key={key}
                            className="main-action-button"
                            {...otherButtonProps}
                            onClick={stopPropagation(onExecute)}
                            loaderPosition="fixed"
                            variant="filled"
                            color="primary"
                            size="S"
                            fontSize="L"
                            svgSize={24}
                            noMargin
                        />
                    );
                }

                return (
                    <IconButton
                        key={key}
                        className="main-action-button"
                        {...otherButtonProps}
                        icon={otherButtonProps.icon || <InfoIcon />}
                        onClick={stopPropagation(onExecute)}
                        loaderPosition="fixed"
                        tooltip={otherButtonProps.label}
                        variant={variant === 'main-icon-bare' ? 'bare' : 'filled'}
                        color={variant === 'main-icon-bare'
                            ? 'text'
                            : variant === 'main-icon-grey'
                                ? 'grey_inverted'
                                : 'primary'}
                        size="S"
                        svgSize={24}
                    />
                );
            })}

            {isArrayWithValues(extraActions) && (
                <>
                    <IconButton
                        id="open-extra-actions-menu"
                        className={clsx('main-action-button', 'extra-actions-button')}
                        icon={<MoreIcon />}
                        tooltip="common.action.extra_actions"
                        variant={moreVariant || 'filled'}
                        color="grey"
                        size="S"
                        svgSize={26}
                        onClick={stopPropagation(openExtraActionsPopover)}
                    />

                    <Popover
                        open={isExtraActionsPopperOpen}
                        anchorEl={popoverAnchorEl}
                        onClose={stopPropagation(closeExtraActionsPopover)}
                        className={classes.extraActionsPopoverWrapper}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        marginThreshold={8}
                    >
                        <Card>
                            {extraActions.map((extraAction) => {
                                const key = `extra-action_${extraAction.id}`;
                                const { variant, onExecute, ...other } = extraAction;
                                const button = (
                                    <TextButton
                                        key={key}
                                        className="extra-action-card-item"
                                        {...other}
                                        onClick={stopPropagation(onClickExtraActionsButton)}
                                        loaderPosition="fixed"
                                        variant="bare"
                                        color="text"
                                        size="S"
                                        fontSize="L"
                                        svgSize={24}
                                        fontWeight="initial"
                                        noMargin
                                    />
                                );

                                if (!extraAction.tooltip) {
                                    return button;
                                }

                                return (
                                    <Tooltip
                                        key={key}
                                        label={extraAction.tooltip}
                                        placement={extraAction.tooltipPlacement}
                                    >
                                        {button}
                                    </Tooltip>
                                );

                                function onClickExtraActionsButton() {
                                    const executeResult = onExecute();

                                    if (isPromise(executeResult)) {
                                        executeResult
                                            .then(() => {
                                                if (isMounted.current) {
                                                    closeExtraActionsPopover();
                                                }
                                            });
                                    } else {
                                        closeExtraActionsPopover();
                                    }

                                    return executeResult;
                                }
                            })}
                        </Card>
                    </Popover>
                </>
            )}
        </div>
    );

    function openExtraActionsPopover(event: React.MouseEvent) {
        setPopoverAnchorEl(event.currentTarget);
    }

    function closeExtraActionsPopover() {
        setPopoverAnchorEl(null);
    }
}
