import isSet from '@snipsonian/core/cjs/is/isSet';
import assert from '@snipsonian/core/cjs/assert';
import {
    IApiEntityListResponseWithPageNr,
    IApiOrderByField,
} from '@console/core-api/models/api.models';
import { IActiveSortColumn } from 'models/list.models';
import { FIRST_PAGE_NR } from '@console/core-api/config/coreApi.config';

export function mapActiveSortColumnToApiOrderBy<ColValues>(sortColumn: IActiveSortColumn<ColValues>): IApiOrderByField {
    assert(
        null,
        () => isSet(sortColumn?.serverSide),
        'For back-end sorting, a column with a server-side field config is needed.',
    );

    return {
        field: sortColumn.serverSide.field,
        order: sortColumn.order,
    };
}

export function addListResponseToExistingEntityData<ApiEntityData>({
    response,
    existingData,
}: {
    response: IApiEntityListResponseWithPageNr<ApiEntityData>;
    existingData: IApiEntityListResponseWithPageNr<ApiEntityData>;
}) {
    if (response.pageNr > FIRST_PAGE_NR) {
        /* not the first page was retrieved >> append results to existing results array */
        return {
            ...response,
            _embedded: {
                /* eslint-disable no-underscore-dangle */
                ...existingData._embedded,
                ...response._embedded,
                /* eslint-disable no-underscore-dangle */
            },
            results: existingData.results.concat(response.results),
            count: existingData.count, /* so that we only have to ask the count for page 1 (better for performance) */
        };
    }

    /* initial/first page >> just overwrite the data results */
    return response;
}
