import React from 'react';
import { triggerFetchPortfolios } from 'state/entities/portfolioMgmt/portfolios';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { IColValues, IDataItem } from 'models/list.models';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import GenericPortfoliosList, {
    getDefaultPortfolioColumns,
    IDefaultPortfolioCols,
} from 'views/portfolioMgmt/Portfolios/GenericPortfoliosList';
import { TPortfolioGroupPortfoliosFormValues } from './types';

interface IPortfoliosSelectCols
    extends IColValues, Pick<Partial<IDefaultPortfolioCols>, 'name' | 'owner' | 'externalId' | 'type'> { }

interface IPublicProps {
    formContext: IExtendedInputFormContext<TPortfolioGroupPortfoliosFormValues>;
    unselectablePortfolioIds?: string[];
}

const COLS = getDefaultPortfolioColumns({
    desiredCols: [
        { colName: 'name', percentWidth: 35 },
        { colName: 'owner', percentWidth: 30 },
        { colName: 'externalId', percentWidth: 30 },
        { colName: 'type', percentWidth: 5 },
    ],
    hideActions: {
        createReport: true,
    },
});

export default function PortfolioGroupPortfoliosFormContent({
    formContext: {
        fields,
        setFieldValue,
    },
    unselectablePortfolioIds = [],
}: IPublicProps) {
    useExecuteOnMount({
        execute: () => triggerFetchPortfolios(),
    });

    return (
        <GenericPortfoliosList<IPortfoliosSelectCols>
            overrideCols={{
                cols: COLS,
                toPortfolioListItem: ({ portfolio, defaultPortfolioCols }) => ({
                    id: portfolio.id,
                    colValues: {
                        owner: defaultPortfolioCols.owner,
                        name: defaultPortfolioCols.name,
                        externalId: defaultPortfolioCols.externalId,
                        type: defaultPortfolioCols.type,
                    },
                }),
            }}
            selectConfig={{
                onChange: updateFormWithSelectedPortfolioIds,
                selectedItemIds: fields.portfolioIds.value as string[],
            }}
            isPortfolioRowClickable={isPortfolioSelectable}
            isDataTableRowSelectable={isPortfolioSelectable}
        />
    );

    function updateFormWithSelectedPortfolioIds(selectedIds: string[]) {
        setFieldValue({
            fieldName: fields.portfolioIds.fieldName,
            value: selectedIds,
        });
    }

    function isPortfolioSelectable({ id }: IDataItem<IPortfoliosSelectCols>) {
        return !unselectablePortfolioIds.includes(id);
    }
}
