import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
    MarginProperty,
    PaddingProperty,
} from 'csstype';
import { THEME } from 'config/styling/theme';

interface ISpacingProps {
    margin?: MarginProperty<string>;
    padding?: PaddingProperty<string>;
}

export const spacing = ({
    margin,
    padding,
}: ISpacingProps): CSSProperties => ({
    margin,
    padding,
});

export const spacingBodyContent = () => spacing({
    padding: THEME.spacing(2, 0),
});
