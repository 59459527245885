import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import { ConsoleCommon } from '@console/common';
import { DataPointFrequency, TFetchReportCommonApiBody } from '../../../models/reports/commonReport.models';

const ALL_DATAPOINT_FREQUENCIES = Object.values(DataPointFrequency);

export const dataPointFrequencySchema = decorateSchema(
    ConsoleCommon.schema.string()
        .oneOf(ALL_DATAPOINT_FREQUENCIES) as ConsoleCommon.schema.StringSchema<DataPointFrequency>,
    {
        description: 'The desired interval period between data points for the resulting time series.'
            + ' Either D(ay), W(eek), M(onth), Q(uarter) or Y(ear)',
        enumValues: ALL_DATAPOINT_FREQUENCIES,
    },
);

export const acceptLanguageSchema = ConsoleCommon.schema.string()
    .oneOf(Object.values(ConsoleCommon.Locale)) as ConsoleCommon.schema.StringSchema<ConsoleCommon.Locale>;

export const fetchReportCommonApiBodySchema = ConsoleCommon.schema.object({
    frequency: dataPointFrequencySchema.required(),
    period: decorateSchema(
        ConsoleCommon.schema.datePeriodSchema,
        // eslint-disable-next-line max-len
        { description: 'The graph period. This is the entire period for which datapoints are needed. Dates should be in the YYYY-MM-DD format.' },
    ).required(),
    strictPeriod: decorateSchema(
        ConsoleCommon.schema.datePeriodSchema,
        // eslint-disable-next-line max-len
        { description: 'Optional. The period that the user selected. Only to be used if different from the graph period.  Dates should be in the YYYY-MM-DD format.' },
    ),
}) as ConsoleCommon.schema.ObjectSchema<TFetchReportCommonApiBody>;

export const reportInfoSchema = ConsoleCommon.schema.object({
    firstSnapshotDatetime: ConsoleCommon.schema.datetimeStringSchema.required(),
    lastSnapshotDatetime: ConsoleCommon.schema.datetimeStringSchema.required(),
    timezone: ConsoleCommon.schema.string().required(),
});
