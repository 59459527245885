import React, { useRef } from 'react';
import { gsap } from 'gsap';
import MuiIconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { IMenuItemBase } from 'models/state/ui.models';
import { APP_COLORS, MENU_COLORS } from 'config/styling/colors';
import { getAppMenuItemById, getTopMenuItemBySelectedMenuItem } from 'config/menu.config';
import { toIdSelector } from 'utils/dom/selectorUtils';
import { makeStyles, mixins } from 'views/styling';

type TColorIndex = 'A' | 'B' | 'C' | 'D' | 'E';

const CONFIG = {
    BACK_ARROW: {
        ID: 'to-top-arrow',
        COLOR: APP_COLORS.PRIMARY['400'],
    },
    TITLE: {
        ID: 'animated-header-title',
    },
    HEADER_ICON: {
        ID: 'header-icon',
    },
    SQUARE: {
        COLORS: {
            A: MENU_COLORS.ICON.BASIC.A,
            B: MENU_COLORS.ICON.BASIC.B,
            C: MENU_COLORS.ICON.BASIC.C,
            D: MENU_COLORS.ICON.BASIC.D,
            E: MENU_COLORS.ICON.BASIC.E,
        },
        COLORS_HOVER: {
            A: MENU_COLORS.ICON.HOVER.A,
            B: MENU_COLORS.ICON.HOVER.B,
            C: MENU_COLORS.ICON.HOVER.C,
            D: MENU_COLORS.ICON.HOVER.D,
            E: MENU_COLORS.ICON.HOVER.E,
        },
        SIZES: {
            RADIUS: 1,
            RADIUS_CIRCLE: 3,
            SIDE: 6,
        },
    },
};

interface ISquares {
    [rowKey: string]: ISquare[];
    ROW1: ISquare[];
    ROW2: ISquare[];
    ROW3: ISquare[];
}

interface ISquare {
    colorIndex: TColorIndex;
    isRounded?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref?: React.MutableRefObject<any>;
}

const SQUARES: ISquares = {
    ROW1: [
        { colorIndex: 'B' },
        { colorIndex: 'D' },
        { colorIndex: 'E', isRounded: true },
    ],
    ROW2: [
        { colorIndex: 'E', isRounded: true },
        { colorIndex: 'A' },
        { colorIndex: 'B' },
    ],
    ROW3: [
        { colorIndex: 'A' },
        { colorIndex: 'C', isRounded: true },
        { colorIndex: 'E' },
    ],
};

const useStyles = makeStyles((theme) => ({
    AnimatedMenuToggle: {
        ...mixins.flexRowCenterLeft(),
        position: 'relative',

        '& h1': {
            cursor: 'pointer',
        },

        '& #animated-header-title': {
            whiteSpace: 'nowrap',
        },
    },
    headerIcon: {
        padding: theme.spacing(0, 2),

        '& svg': {
            ...mixins.typo({ size: 24, color: MENU_COLORS.TITLE.TEXT }),
        },
    },
    ToTopIcon: {
        ...mixins.typo({ size: 12, color: CONFIG.BACK_ARROW.COLOR }),
        position: 'absolute',
        top: '2px',
        left: '-2px',
        padding: 0,
        zIndex: 1,
    },
}));

/**
 * Kept outside of the component so that the state is remembered
 * for when this component gets re-rendered because of a state change in its
 * parent component.
 * We don't use react useState for this, because just saving the state then
 * would trigger an additional re-render.
 */
let isInHoverState = false;

interface IPublicProps {
    onToggle: () => void;
    isTopMenuShown: boolean;
    mainMenuItem: IMenuItemBase;
}

export default function AnimatedMenuToggle({
    onToggle,
    isTopMenuShown,
    mainMenuItem,
}: IPublicProps) {
    const classes = useStyles();
    const svgRef = useRef(null);

    /* element refs for the individual rectangles */
    SQUARES.ROW1[0].ref = useRef(null);
    SQUARES.ROW1[1].ref = useRef(null);
    SQUARES.ROW1[2].ref = useRef(null);
    SQUARES.ROW2[0].ref = useRef(null);
    SQUARES.ROW2[1].ref = useRef(null);
    SQUARES.ROW2[2].ref = useRef(null);
    SQUARES.ROW3[0].ref = useRef(null);
    SQUARES.ROW3[1].ref = useRef(null);
    SQUARES.ROW3[2].ref = useRef(null);

    const headerMenuItem = getTopMenuItemBySelectedMenuItem(mainMenuItem);
    if (headerMenuItem && !isSet(headerMenuItem.icon) && isSet(headerMenuItem.parentId)) {
        /* take the icon from the parent item */
        headerMenuItem.icon = getAppMenuItemById(headerMenuItem.parentId).icon;
    }

    return (
        <div
            className={classes.AnimatedMenuToggle}
            onMouseEnter={animateHover}
            onMouseLeave={animateReverse}
        >
            {!isTopMenuShown && (
                <MuiIconButton
                    id={CONFIG.BACK_ARROW.ID}
                    aria-label="to top menu"
                    className={classes.ToTopIcon}
                    onClick={onToggle}
                >
                    <ChevronLeftOutlinedIcon />
                </MuiIconButton>
            )}

            <MuiIconButton
                aria-label="toggle top menu"
                className={classes.headerIcon}
                onClick={onToggle}
            >
                {isTopMenuShown && <AnimatedMenuToggleSvg />}
                {!isTopMenuShown && <headerMenuItem.icon id="header-icon" />}
            </MuiIconButton>

            <Typography
                id={CONFIG.TITLE.ID}
                variant="h6"
                component="h1"
                onClick={onToggle}
                style={{
                    color: getTitleColor(isInHoverState),
                }}
            >
                {isTopMenuShown && <Translate msg="app_shell.header.title" />}
                {!isTopMenuShown && <Translate msg={headerMenuItem.translationKey} />}
            </Typography>
        </div>
    );

    function animateHover() {
        doAnimation(true);
    }
    function animateReverse() {
        doAnimation(false);
    }

    function doAnimation(isHovered: boolean) {
        isInHoverState = isHovered;

        if (document.getElementById(CONFIG.TITLE.ID)) {
            gsap.to(toIdSelector(CONFIG.TITLE.ID), {
                color: getTitleColor(isHovered),
                duration: 0.2,
            });
        }

        if (document.getElementById(CONFIG.BACK_ARROW.ID)) {
            gsap.to(toIdSelector(CONFIG.BACK_ARROW.ID), {
                color: isHovered ? getTitleColor(isHovered) : CONFIG.BACK_ARROW.COLOR,
                duration: 0.2,
            });
        }

        if (svgRef && svgRef.current) {
            Object.keys(SQUARES).forEach((rowKey) => {
                SQUARES[rowKey].forEach((square) => {
                    gsap.to(square.ref.current, {
                        fill: getSquareColor(square.colorIndex, isHovered),
                        rx: getSquareRadius(square.isRounded, isHovered),
                        duration: 0.5,
                        ease: 'none',
                    });
                });
            });
        } else if (document.getElementById(CONFIG.HEADER_ICON.ID)) {
            gsap.to(toIdSelector(CONFIG.HEADER_ICON.ID), {
                fill: getTitleColor(isHovered),
                duration: 0,
            });
        }
    }

    /** See views/assets/img/icons/menu-toggle.svg for the equivalent svg file */
    function AnimatedMenuToggleSvg() {
        return (
            <svg
                id="animated-menu-toggle"
                ref={svgRef}
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-16.000000, -12.000000)">
                        <g transform="translate(16.000000, 12.000000)">
                            <RectanglesRow
                                rowNumber={1}
                                rowSquares={SQUARES.ROW1}
                            />
                            <RectanglesRow
                                rowNumber={2}
                                rowSquares={SQUARES.ROW2}
                                transform="translate(0.000000, 9.000000)"
                            />
                            <RectanglesRow
                                rowNumber={3}
                                rowSquares={SQUARES.ROW3}
                                transform="translate(0.000000, 18.000000)"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

function RectanglesRow({
    rowNumber,
    rowSquares,
    transform,
}: {
    rowNumber: number;
    rowSquares: ISquare[];
    transform?: string;
}) {
    return (
        <g key={`rectangle-row-${rowNumber}`} transform={transform}>
            {rowSquares.map((square, index) => {
                const xPos = index + 1;

                return (
                    <Rectangle
                        key={`rectangle-${rowNumber}_${xPos}`}
                        square={square}
                        xPos={xPos}
                    />
                );
            })}
        </g>
    );
}

function Rectangle({
    square,
    xPos,
}: {
    square: ISquare;
    xPos: number; // 1 ir 2 or 3
}) {
    const { ref, colorIndex, isRounded = false } = square;

    const x = (xPos === 1)
        ? 0
        : (xPos === 2)
            ? 9
            : 18;

    return (
        <rect
            ref={ref}
            fill={getSquareColor(colorIndex, isInHoverState)}
            x={x}
            y="0"
            width={CONFIG.SQUARE.SIZES.SIDE}
            height={CONFIG.SQUARE.SIZES.SIDE}
            rx={getSquareRadius(isRounded, isInHoverState)}
        />
    );
}

function getTitleColor(isHovered: boolean) {
    return isHovered
        ? CONFIG.SQUARE.COLORS_HOVER.C
        : 'white';
}

function getSquareColor(colorIndex: TColorIndex, isHovered: boolean): string {
    return isHovered
        ? CONFIG.SQUARE.COLORS_HOVER[colorIndex]
        : CONFIG.SQUARE.COLORS[colorIndex];
}

function getSquareRadius(isRounded: boolean, isHovered: boolean): number {
    return isHovered
        ? isRounded ? CONFIG.SQUARE.SIZES.RADIUS : CONFIG.SQUARE.SIZES.RADIUS_CIRCLE
        : isRounded ? CONFIG.SQUARE.SIZES.RADIUS_CIRCLE : CONFIG.SQUARE.SIZES.RADIUS;
}
