import { TFastifyRouteGenericInterface } from '@typsy/rest-api/dist/server/endpoint/endpointValidation.models';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { IInstrumentsMap } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { IOptimizationPortfolioUpdate } from '@console/core-api/models/portfolioMgmt/optimization.models';
import { OptimizationOwnerChoice } from '@console/core-api/models/portfolioMgmt/portfolioOptimization.models';
import { IPortfolioHoldings } from '@console/core-api/models/portfolioMgmt/portfolioHoldings.models';
import { EnhancedOptimizationStatus } from '../enhancedOptimization.models';

export const LATEST_OPTIMIZATION_ID = 'latest';

export interface IFetchEnhancedPortfolioOptimizationApiContract extends TFastifyRouteGenericInterface {
    Params: IFetchEnhancedPortfolioOptimizationApiParams;
    Reply: IEnhancedPortfolioOptimization;
}

export interface IFetchEnhancedPortfolioOptimizationApiParams {
    portfolioId: TApiEntityId;
    optimizationId: 'latest' | TApiEntityId;
}

export type TFetchEnhancedPortfolioOptimizationApiInput = IFetchEnhancedPortfolioOptimizationApiParams;

export interface IEnhancedPortfolioOptimization {
    portfolioId: TApiEntityId;
    status: EnhancedOptimizationStatus;
    optimizationId?: TApiEntityId;
    currency?: SupportedCurrency;
    ownerChoice?: OptimizationOwnerChoice;
    portfolio_update?: IEnhancedOptimizationPortfolioUpdate;
    holdingsAtTimeOfOptim?: IPortfolioHoldings; /* the portfolio holdings at the time of the optimization */
    estimatedCashAfterOptim?: number;
    instrumentMap?: IInstrumentsMap;
    creation_datetime?: string;
    version_datetime?: string;
}

export interface IEnhancedOptimizationPortfolioUpdate extends IOptimizationPortfolioUpdate {
    nrOfBuys: number;
    nrOfSells: number;
}
