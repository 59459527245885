import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import { enhanceApiOptimizationMissingError } from '@console/api-base/client/error/clientSideApiErrorUtils';
import { ISinglePortfolioApiInput } from '../../models/portfolioMgmt/portfolio.models';
import {
    TFetchPortfolioOptimizationsApiInput,
    IPortfolioOptimization,
    IPortfolioOptimizationEntityData,
    TPortfolioOptimizationsData, OptimizationTypeFilter,
    OptimizationOwnerChoiceFilter,
} from '../../models/portfolioMgmt/portfolioOptimization.models';
import { IApiEntityListResponse, TApiEntity } from '../../models/api.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { get } from '../coreApiRequestWrapper';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { OptimizationStatus } from '../../models/portfolioMgmt/optimization.models';

export function fetchPortfolioSuccessOptimizations({
    portfolioId,
    startDate,
    endDate,
    ownerChoice,
    type,
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    orderBy,
    count,
    underlyingApiRequestConfig,
}: TFetchPortfolioOptimizationsApiInput & IUnderlyingRequestApiInput) {
    const filterParamBuilder = fetchApiEntityUrlParamBuilder()
        .exactMatch({
            field: 'status',
            value: OptimizationStatus.SUCCESS,
        })
        .dateInRange({
            field: 'creation_datetime',
            startDate,
            endDate,
        })
        .exactMatch({
            field: 'portfolio_update.is_recommended',
            value: type === OptimizationTypeFilter.RECOMMENDED
                ? true
                : type === OptimizationTypeFilter.NOT_RECOMMENDED
                    ? false
                    : undefined,
        })
        .orderBy(orderBy);
    if (ownerChoice) {
        if (ownerChoice === OptimizationOwnerChoiceFilter.NO_CHOICE) {
            filterParamBuilder.isNull({ field: 'owner_choice' });
        } else {
            filterParamBuilder.exactMatch({
                field: 'owner_choice',
                value: ownerChoice,
            });
        }
    }

    return get<TPortfolioOptimizationsData, IApiEntityListResponse<IPortfolioOptimizationEntityData>>({
        url: CoreApiPath.PORTFOLIO_OPTIMIZATIONS,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            ...filterParamBuilder.build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}

export function fetchPortfolioOptimizationLatest({
    portfolioId,
    underlyingApiRequestConfig,
}: ISinglePortfolioApiInput & IUnderlyingRequestApiInput) {
    return get<IPortfolioOptimization, TApiEntity<IPortfolioOptimizationEntityData>>({
        url: CoreApiPath.PORTFOLIO_OPTIMIZATION_LATEST,
        pathParams: {
            portfolioId,
        },
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            optimization: data,
        }),
        enhanceError: enhanceApiOptimizationMissingError,
        ...underlyingApiRequestConfig,
    });
}
