import { useRef, useEffect } from 'react';

/**
 * Usage:
 *   const isMounted = useIsMounted();
 *   ...
 *   if (isMounted.current) {
 *       // do your thing
 *   }
 */
export default function useIsMounted() {
    const isMounted = useRef(false);

    useEffect(
        () => {
            isMounted.current = true;

            return () => {
                isMounted.current = false;
            };
        },
        [],
    );

    return isMounted;
}
