import React from 'react';
import I18nContext from '@snipsonian/react/cjs/components/i18n/I18nContext';
import { SHOW_MSG_KEY_TRANSLATOR } from '@snipsonian/react/cjs/components/i18n/translator/translatorManager';
import { TTranslatorInput } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getLocale, getTranslator, getTranslatorIfNeeded } from 'state/i18n/selectors';
import { observe, IObserveProps, IPublicPropsWithChildren } from 'views/observe';
import CustomI18nContext from 'views/appShell/providers/CustomI18nContext';
import 'views/translations';
import { IUntranslatableLabel, TTranslatorIfNeeded } from 'models/general.models';
import { isUntranslatableLabelTypeGuard } from 'utils/i18n/i18nUtils';

const DEFAULT_TRANSLATOR_IF_NEEDED: TTranslatorIfNeeded = (input: TTranslatorInput | IUntranslatableLabel) => {
    if (isUntranslatableLabelTypeGuard(input)) {
        return input.text;
    }

    return SHOW_MSG_KEY_TRANSLATOR(input);
};

function I18nProvider({
    state,
    children,
}: IObserveProps & IPublicPropsWithChildren) {
    const locale = getLocale(state);
    const translator = getTranslator(state) || SHOW_MSG_KEY_TRANSLATOR;
    const translatorIfNeeded = getTranslatorIfNeeded(state) || DEFAULT_TRANSLATOR_IF_NEEDED;

    return (
        <I18nContext.Provider value={{ translator, locale }}>
            <CustomI18nContext.Provider value={{ translator, locale, translatorIfNeeded }}>
                {children}
            </CustomI18nContext.Provider>
        </I18nContext.Provider>
    );
}

export default observe<IPublicPropsWithChildren>(
    [StateChangeNotification.I18N_TRANSLATIONS],
    I18nProvider,
);
