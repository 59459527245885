import { TApiEntityId } from '@console/core-api/models/api.models';
import { PossibleApiErrorClientSide } from '@console/api-base/client/error/clientSideApiErrorUtils';

type TBusinessLogicErrorResponseDetail = IBusinessLogicErrorEntity[];

interface IBusinessLogicErrorEntity {
    id: string;
    external_id: string;
    reason: string;
}

export function extractEntityIdsFromBusinessLogicErrorDetailText(
    apiError: PossibleApiErrorClientSide,
): TApiEntityId[] {
    const detailText = apiError?.response?.data?.detailText || '';

    const entities: TBusinessLogicErrorResponseDetail = JSON.parse(ensureErrorDetailIsValidForJsonParsing(detailText));

    return entities.map(({ id }) => id);
}

export function extractEntityIdFromEntityNotFoundErrorDetailText(
    apiError: PossibleApiErrorClientSide,
): TApiEntityId {
    try {
        const { detailText = '' } = apiError?.response?.data || {};
        const entityIdStartIndex = detailText.indexOf('\'') + 1;
        const entityIdEndIndex = detailText.indexOf('\'', entityIdStartIndex);

        return detailText.substring(entityIdStartIndex, entityIdEndIndex);
    } catch {
        return '-';
    }
}

export function ensureErrorDetailIsValidForJsonParsing(errorDetail: string): string {
    return errorDetail.replaceAll('\'', '"').replaceAll('None', 'null');
}
