import { string, object, array, ObjectSchema } from '@console/common/utils/schema';
import { IState } from 'models/state.models';
import { getMultiTranslationsLabelSchema } from 'state/appConfig/selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let publicInstrumentGroupDetailsSchema: ObjectSchema<any> = null;

export function getPublicInstrumentGroupDetailsSchema(stateForTestPurposes?: IState) {
    if (!publicInstrumentGroupDetailsSchema) {
        publicInstrumentGroupDetailsSchema = object({
            name: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            externalId: string().trim(),
            imgUri: string().url().required().trim(),
            category: getMultiTranslationsLabelSchema(stateForTestPurposes, false),
            description: getMultiTranslationsLabelSchema(stateForTestPurposes, false),
        });
    }

    return publicInstrumentGroupDetailsSchema;
}

export const publicInstrumentGroupInstrumentsSchema = object({
    instrumentIds: array().of(string()),
});
