import React from 'react';
import { PolicyAlgorithmType, PolicyMeasureOfRisk } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { isPolicyOfTypeRiskBased } from '@console/bff/utils/policies/policyTypeUtils';
import { getPoliciesAlgorithmsConfig } from 'state/appConfig/selectors';
import { getPolicyAlgorithmLabel, getPolicyMeasureOfRiskLabel } from 'utils/entities/portfolioMgmt/policyUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { TAddPolicyFormValues } from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';

const MEASURE_OF_RISK_ITEMS: IInputSelectItem<PolicyMeasureOfRisk>[] =
    Object.values(PolicyMeasureOfRisk).map((value) => ({
        value,
        label: getPolicyMeasureOfRiskLabel(value),
    }));

export function PolicyAlgorithmTypeFormContent({
    fields,
    setFieldValue,
}: IExtendedInputFormContext<TAddPolicyFormValues>) {
    const algoItems: IInputSelectItem<string>[] = getPoliciesAlgorithmsConfig().map((value) => ({
        value,
        label: getPolicyAlgorithmLabel(value),
    }));

    return (
        <>
            <ExtendedInputSelect<string>
                formField={fields.algorithm}
                wrapper={{
                    label: 'fields.algorithm.add_label',
                }}
                itemLabelsAreTranslationKeys
                shouldPrefixItemLabels={false}
                items={algoItems}
                readOnly={algoItems.length === 1}
                onChange={({ value }) => {
                    /** We overrule the default onChange as we also have to reset the parent policy
                     * when the algorithm changes */
                    setFieldValue({
                        fieldName: fields.algorithm.fieldName,
                        value,
                    }, {
                        fieldName: fields.parentPolicyId.fieldName,
                        value: null,
                    }, {
                        fieldName: fields.selectedParentPolicy.fieldName,
                        value: null,
                    });
                }}
            />
            {isPolicyOfTypeRiskBased(fields.algorithm.value as PolicyAlgorithmType) && (
                <ExtendedInputSelect<string>
                    formField={fields.measureOfRisk}
                    wrapper={{
                        label: 'fields.measure_of_risk.add_label',
                    }}
                    itemLabelsAreTranslationKeys
                    shouldPrefixItemLabels={false}
                    items={MEASURE_OF_RISK_ITEMS}
                    readOnly={MEASURE_OF_RISK_ITEMS.length === 1}
                />
            )}
        </>
    );
}
