import isEqual from 'lodash/isEqual';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isUndefined from '@snipsonian/core/cjs/is/isUndefined';
import isDate from '@snipsonian/core/cjs/is/isDate';
import isSetObject from '@snipsonian/core/cjs/object/verification/isSetObject';
import getObjectKeyVals from '@snipsonian/core/cjs/object/keyVals/getObjectKeyVals';
import { TObjectWithProps } from '../../models/genericTypes.models';

interface IDiffObjectsOptions {
    deepDiff: boolean; // default true
}

interface IDiffObjectsResult {
    areDiffsDetected: boolean;
    diffs: IDetectedDiffs[];
}

export interface IDetectedDiffs {
    propChain: string[];
    type: 'add' | 'upd' | 'del';
    master: unknown;
    slave: unknown;
}

/**
 * Compares the master & slave input objects with each other.
 * Result:
 * - areDiffsDetected: true if at least one diff was detected.
 * - diffs type 'add': only those (nested) properties that are in 'master' but not in 'slave'
 * - diffs type 'upd': only those (nested) properties that have another value in 'master' compared to 'slave' object
 * - diffs type 'del': only those (nested) properties that are in 'slave' but not in 'master'
 */
export function diffObjects<ObjectType extends TObjectWithProps = TObjectWithProps>(
    master: ObjectType,
    slave: ObjectType,
    options: Partial<IDiffObjectsOptions> = {},
): IDiffObjectsResult {
    const consolidatedOptions: IDiffObjectsOptions = {
        deepDiff: true,
        ...options,
    };

    const diffs = diffObjectsRecursive(master, slave, consolidatedOptions, [], []);

    return {
        areDiffsDetected: diffs.length > 0,
        diffs,
    };
}

function diffObjectsRecursive(
    master: TObjectWithProps,
    slave: TObjectWithProps,
    options: IDiffObjectsOptions,
    accumulatedDiffs: IDetectedDiffs[],
    parentPropChain: string[],
): IDetectedDiffs[] {
    if (Object.keys(master).length !== Object.keys(slave).length) {
        // TODO removed
    }

    getObjectKeyVals(master)
        .forEach(({ key, value: masterValue }) => {
            const propChain = [...parentPropChain, key];
            const slaveValue = slave[key];

            if (isSet(masterValue) && isUndefined(slaveValue)) {
                accumulatedDiffs.push({
                    propChain,
                    type: 'add',
                    master: masterValue,
                    slave: slaveValue,
                });
            } else if (isSetObject(masterValue) && isSetObject(slaveValue)
                && !isDate(masterValue)) {
                /* use isSetObject as otherwise null values are also considered as objects */

                if (options.deepDiff) {
                    diffObjectsRecursive(masterValue, slaveValue, options, accumulatedDiffs, propChain);

                    // eslint-disable-next-line eqeqeq
                } else if (!isEqual(masterValue, slaveValue)) {
                    accumulatedDiffs.push({
                        propChain,
                        type: 'upd',
                        master: masterValue,
                        slave: slaveValue,
                    });
                }
            } else if (
                (typeof masterValue !== typeof slaveValue)
                || (masterValue !== slaveValue)
            ) {
                accumulatedDiffs.push({
                    propChain,
                    type: 'upd',
                    master: masterValue,
                    slave: slaveValue,
                });
            }
        });

    getObjectKeyVals(slave)
        .forEach(({ key, value: slaveValue }) => {
            const propChain = [...parentPropChain, key];
            const masterValue = master[key];

            if (isSet(slaveValue) && isUndefined(masterValue)) {
                accumulatedDiffs.push({
                    propChain,
                    type: 'del',
                    master: masterValue,
                    slave: slaveValue,
                });
            }
        });

    return accumulatedDiffs;
}
