import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { TDataItemId } from 'models/list.models';
import { UiPageKey } from 'models/state/ui.models';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { DEFAULT_PORTFOLIO_TAB_KEY } from 'config/tabs.config';
import {
    userPortfoliosEntity,
    triggerFetchUserPortfolios,
} from 'state/entities/portfolioMgmt/userPortfolios';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import GenericPortfoliosList, {
    getDefaultPortfolioColumns,
    IDefaultPortfolioCols,
} from 'views/portfolioMgmt/Portfolios/GenericPortfoliosList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserPortfolioCols extends Omit<IDefaultPortfolioCols, 'owner'> { }

const COLS = getDefaultPortfolioColumns({
    desiredCols: [
        { colName: 'type', percentWidth: 10 },
        { colName: 'name', percentWidth: 30 },
        { colName: 'externalId', percentWidth: 20 },
        { colName: 'updated', percentWidth: 15 },
        { colName: 'status', percentWidth: 10 },
        { colName: 'actions', percentWidth: 15 },
    ],
});

interface IPublicProps {
    overridePortfolioDetailRouteKey?: ROUTE_KEY;
    noResultsRender?: () => ReactElement;
}

export default function GenericUserPortfolios({
    overridePortfolioDetailRouteKey,
    noResultsRender,
}: IPublicProps) {
    const { userId } = useParams<IPossiblePathParams>();

    return (
        <GenericPortfoliosList<IUserPortfolioCols>
            overrideCols={{
                cols: COLS,
                toPortfolioListItem: ({ portfolio, defaultPortfolioCols }) => ({
                    id: portfolio.id,
                    colValues: {
                        type: defaultPortfolioCols.type,
                        name: defaultPortfolioCols.name,
                        externalId: defaultPortfolioCols.externalId,
                        updated: defaultPortfolioCols.updated,
                        status: defaultPortfolioCols.status,
                        actions: {
                            ...defaultPortfolioCols.actions,
                            onViewDetail: () => openPortfolioDetail(portfolio.id),
                        },
                    },
                }),
            }}
            overrideEntity={{
                dataNotification: StateChangeNotification.USER_PORTFOLIOS_DATA,
                asyncListEntity: userPortfoliosEntity,
                asyncListEntityFetchTrigger: triggerFetchUserPortfolios,
                setStateOnPageNrChange: (pageNr) => ({
                    toState: (draftState) => {
                        // eslint-disable-next-line no-param-reassign
                        draftState.entities.userPortfolios.data.pageNr = pageNr;
                    },
                    notificationsToTrigger: [StateChangeNotification.USER_PORTFOLIOS_DATA],
                }),
            }}
            overrideUiVars={{
                uiPageKey: UiPageKey.userPortfoliosList,
                uiVarsNotification: StateChangeNotification.USER_PORTFOLIOS_UI_VARS,
            }}
            onPortfolioRowClicked={({ id }) => openPortfolioDetail(id)}
            noResultsRender={noResultsRender}
            enableStorytellerBulkReportGeneration
            includeRefreshButton={false}
        />
    );

    function openPortfolioDetail(portfolioId: TDataItemId) {
        redirectTo({
            routeKey: overridePortfolioDetailRouteKey || ROUTE_KEY.R_USER_DETAIL_PORTFOLIO_DETAIL,
            params: {
                userId,
                portfolioId,
                portfolioTab: DEFAULT_PORTFOLIO_TAB_KEY,
            },
        });
    }
}
