import { IAsyncEntitiesManager } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import initAsyncEntitiesManager
    from '@snipsonian/observable-state/cjs/actionableStore/entities/initAsyncEntitiesManager';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { IExtraProcessInput, IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getStore } from 'state';

// eslint-disable-next-line max-len
let entitiesManager: IAsyncEntitiesManager<IState, StateChangeNotification, IBaseApiErrorClientSide> = null;

// eslint-disable-next-line max-len
export function getEntitiesManager(): IAsyncEntitiesManager<IState, StateChangeNotification, IBaseApiErrorClientSide> {
    if (!entitiesManager) {
        entitiesManager =
            initAsyncEntitiesManager<IState, StateChangeNotification, IExtraProcessInput, IBaseApiErrorClientSide>({
                getStore,
            });
    }

    return entitiesManager;
}
