import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import { IBaseSingleEntityApiInput } from '@console/core-api/models/api.models';
import {
    ISingleHorizonApiInput,
    THorizon,
    TTHorizonCreate,
    TTHorizonPatch,
} from '@console/core-api/models/portfolioMgmt/horizon.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { DEFAULT_HORIZON_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import {
    flashErrorApiEntityCreate,
    flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate,
    flashSuccessApiEntityPatch,
} from '../entitiesFlashDispatcher';
import { getEntitiesManager } from '../entitiesManager';
import { triggerResetHorizonsFetch } from './horizons';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const horizonDetailsEntity = getEntitiesManager().registerEntity<THorizon>({
    asyncEntityKey: AsyncEntityKeys.horizonDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.HORIZON_DETAILS_DATA],
    includeUpdaters: true,
});

export const getHorizonTitle: TTitleLabelSelector = () => {
    const horizonDetails = horizonDetailsEntity.select();

    if (hasFetchSucceeded(horizonDetails)) {
        return {
            text: getDefaultTranslationFromApiLabel(
                horizonDetails.data.name,
            ),
            shouldTranslate: false,
        };
    }

    return 'portfolio_mgmt.horizons.detail.title';
};

export function triggerFetchHorizonDetails(apiInput: ISingleHorizonApiInput) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.horizonId, entityType: CoreApiEntityType.horizons })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<THorizon, ISingleHorizonApiInput>({
        detailsEntity: horizonDetailsEntity,
        entityType: CoreApiEntityType.horizons,
        api: api.horizons.fetchHorizonDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () => horizonDetailsEntity.select().data.id !== apiInput.horizonId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchHorizonDetails(horizon: TTHorizonPatch) {
    return horizonDetailsEntity.async.update<TTHorizonPatch, THorizon>({
        api: api.horizons.patchHorizon,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                horizonDetailsEntity.select().data,
                horizon,
            ),
            id: horizon.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetHorizonsFetch();
            apiCacheManager.clearAllHorizons();
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreateHorizon(horizon: TTHorizonCreate) {
    return horizonDetailsEntity.async.create<TTHorizonCreate, THorizon>({
        api: api.horizons.createHorizon,
        apiInputSelector: () => horizon,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetHorizonsFetch();
            apiCacheManager.clearAllHorizons();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_HORIZON_DETAIL,
                params: {
                    horizonId: apiResult.id,
                    horizonTab: DEFAULT_HORIZON_TAB_KEY,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeleteHorizon(horizonIdentifier?: IBaseSingleEntityApiInput) {
    return apiEntityAsyncDeleteAction<THorizon>({
        detailsEntity: horizonDetailsEntity,
        api: api.horizons.deleteHorizon,
        apiInputSelector: () => horizonIdentifier || {
            id: horizonDetailsEntity.select().data.id,
        },
        onPreSuccess: () => {
            triggerResetHorizonsFetch();
            apiCacheManager.clearAllHorizons();
            redirectTo({ routeKey: ROUTE_KEY.R_HORIZONS_LIST });
        },
    });
}
