import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { IEnhancedPortfolioPerformance } from '@console/bff/models/portfolios/enhancedPortfolioPerformance.models';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    IPortfolioFuturePerformanceMarkedDate,
    IPortfolioPastPerformanceMarkedDate,
    TPortfolioPerformanceMode,
} from 'models/ui/portfolioPerformance.ui.models';
import { getPortfolioPerformancePageVars } from 'state/ui/uiPages.selectors';
import { setPortfolioPerformanceMode, updatePortfolioPerformancePageVars } from 'state/ui/uiPages.actions';
import { convertPercentageRelative1ToRelative100, formatPercentage } from 'utils/number/percentageUtils';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import {
    portfolioPerformanceEntity,
    triggerFetchPortfolioFuturePerformance,
    triggerFetchPortfolioPastPerformance,
} from 'state/entities/portfolioMgmt/portfolioPerformance';
import CustomI18nContext from 'views/appShell/providers/CustomI18nContext';
import { EntityWrapperNoExtraObserve } from 'views/common/widget/EntityWrapper';
import LabelWithValue from 'views/common/layout/LabelWithValue';
import ButtonGroup, { IButtonGroupItem } from 'views/common/buttons/ButtonGroup';
import ContentTitle from 'views/common/layout/ContentTitle';
import ContentDivider from 'views/common/layout/ContentDivider';
import PortfolioPerformanceGraphPast from './PortfolioPerformanceGraphPast';
import PortfolioPerformanceGraphFuture from './PortfolioPerformanceGraphFuture';
import { getPastPeriodLabel } from './portfolioPerformanceFilterUtils';
import PortfolioPerformanceFilter from './PortfolioPerformanceFilter';

interface IPublicProps {
    portfolioId: string;
    baseCurrency: string;
}

const useStyles = makeStyles((theme) => ({
    PortfolioNoPerformance: {
        '& .ContentTitle': {
            marginBottom: theme.spacing(1),
        },
    },
    PortfolioPerformanceGraphsHeader: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),

        '& .modeButtonGroup': {},
    },

    PortfolioPerformanceGraphs: {
        marginTop: theme.spacing(3),

        '& .__past': {
            ...mixins.flexColTopLeft(),
        },
        '& .__past .__return': {
            ...mixins.flexRow(),
        },
        '& .__past .__return > div': {
            marginRight: theme.spacing(4),
            marginTop: theme.spacing(1),
        },

        '& .__future': {
            paddingTop: theme.spacing(6),
        },
    },
}));

let shouldRefreshFuturePerformanceOnModeSwitch = false;

function PortfolioPerformanceGraphs({
    portfolioId,
    baseCurrency,
    state,
    dispatch,
}: IPublicProps & IObserveProps) {
    const classes = useStyles();
    const { mode } = getPortfolioPerformancePageVars(state);

    useExecuteOnMount({
        execute: () => {
            shouldRefreshFuturePerformanceOnModeSwitch = true;
        },
    });

    if (portfolioPerformanceEntity.select().data?.past?.noRelevantData) {
        return (
            <div className={classes.PortfolioNoPerformance}>
                <ContentTitle label="portfolio_mgmt.portfolios.detail.performance.title" />

                <Translate msg="portfolio_mgmt.portfolios.detail.performance.no_transactions" />
            </div>
        );
    }

    const performanceModeButtons: IButtonGroupItem[] = [{
        key: 'past',
        label: 'portfolio_mgmt.portfolios.detail.performance.mode.past',
        onClick: () => switchToPerformanceMode('past'),
    }, {
        key: 'future',
        label: 'portfolio_mgmt.portfolios.detail.performance.mode.future',
        onClick: () => switchToPerformanceMode('future'),
        // disabled: cashMovements.length === 0, /* no use because we don't show any graph at all */
        // TODO disable in case of user-managed=self portfolio
    }];

    return (
        <>
            <div className={classes.PortfolioPerformanceGraphsHeader}>
                <ContentTitle label="portfolio_mgmt.portfolios.detail.performance.title" />

                <ButtonGroup
                    id="set-portfolio-performance-mode"
                    className="modeButtonGroup"
                    buttons={performanceModeButtons}
                    size="S"
                    fontSize="L"
                    activeKey={mode}
                />
            </div>

            <PortfolioPerformanceFilter
                baseCurrency={baseCurrency}
                onChangeFilter={onChangePortfolioPerformanceFilter}
            />

            <ContentDivider />

            <div className={classes.PortfolioPerformanceGraphs}>
                <EntityWrapperNoExtraObserve
                    asyncEntitySelector={portfolioPerformanceEntity.select}
                    renderData={mode === 'future' ? renderPortfolioFuturePerformance : renderPortfolioPastPerformance}
                />
            </div>
        </>
    );

    function renderPortfolioPastPerformance({ data }: { data: IEnhancedPortfolioPerformance }) {
        const { past } = data;
        const periodReturn = formatPortfolioAmount(getLastItemOfArray(past.return_amount));
        const twr = formatTwr(getLastItemOfArray(past.time_weighted_return));
        /* this component does NOT listen to UI_PAGE_PORTFOLIO_PERFORMANCE changes, so this component
           will keep on showing the previous value until the new past performance data is fetched */
        const { period } = getPortfolioPerformancePageVars(state).past;
        const periodLabel = getPastPeriodLabel(period);

        return (
            <div className="__past">
                <div className="__return">
                    <CustomI18nContext.Consumer>
                        {({ translator }) => (
                            <LabelWithValue
                                label={{
                                    msg: 'portfolio_mgmt.portfolios.detail.performance.past.periodReturn',
                                    placeholders: {
                                        period: translator(periodLabel).toLowerCase(),
                                    },
                                }}
                                value={periodReturn}
                            />
                        )}
                    </CustomI18nContext.Consumer>

                    <LabelWithValue
                        label="portfolio_mgmt.portfolios.detail.performance.past.twr"
                        value={twr}
                    />
                </div>

                <PortfolioPerformanceGraphPast
                    baseCurrency={baseCurrency}
                    pastPerformanceData={data?.past}
                    onChangedMarkedDate={updatePastMarkedDate}
                />
            </div>
        );
    }

    function renderPortfolioFuturePerformance({ data }: { data: IEnhancedPortfolioPerformance }) {
        if (!isObjectWithProps(data?.future)) {
            return null;
        }

        return (
            <div className="__future">
                <PortfolioPerformanceGraphFuture
                    baseCurrency={baseCurrency}
                    futurePerformanceData={data?.future}
                    onChangedMarkedDate={updateFutureMarkedDate}
                />
            </div>
        );
    }

    function updatePastMarkedDate(markedDate: IPortfolioPastPerformanceMarkedDate) {
        dispatch(updatePortfolioPerformancePageVars({
            past: {
                markedDate,
            },
        }, [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_PAST_MARKED_DATE]));
    }

    function updateFutureMarkedDate(markedDate: IPortfolioFuturePerformanceMarkedDate) {
        dispatch(updatePortfolioPerformancePageVars({
            future: {
                markedDate,
            },
        }, [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_FUTURE_MARKED_DATE]));
    }

    function switchToPerformanceMode(newMode: TPortfolioPerformanceMode) {
        if (newMode !== mode) {
            dispatch(setPortfolioPerformanceMode(newMode));

            if (newMode === 'past') {
                triggerFetchPortfolioPastPerformance({
                    portfolioId,
                    baseCurrency,
                });
            } else {
                triggerFetchPortfolioFuturePerformance({
                    portfolioId,
                    forceRefresh: shouldRefreshFuturePerformanceOnModeSwitch,
                });

                shouldRefreshFuturePerformanceOnModeSwitch = false;
            }
        }
    }

    function onChangePortfolioPerformanceFilter() {
        if (mode === 'past') {
            triggerFetchPortfolioPastPerformance({
                portfolioId,
                baseCurrency,
                includeStatus: false,
                forceRefresh: true,
            });
        } else {
            triggerFetchPortfolioFuturePerformance({
                portfolioId,
                forceRefresh: true,
            });
        }
    }

    function formatPortfolioAmount(amount: number) {
        return formatAmount(amount, { currency: baseCurrency });
    }

    function formatTwr(twrRelativeTo1: number) {
        const twrRelativeTo100 = convertPercentageRelative1ToRelative100(twrRelativeTo1);
        return formatPercentage(twrRelativeTo100);
    }
}

export default observe<IPublicProps>(
    [
        StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_MODE,
        StateChangeNotification.PORTFOLIO_PERFORMANCE_PAST_DATA,
        StateChangeNotification.PORTFOLIO_PERFORMANCE_FUTURE_DATA,
    ],
    PortfolioPerformanceGraphs,
);
