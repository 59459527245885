import React, { useEffect } from 'react';
import {
    getSelectedStoryManagerDatabaseDetail,
    getSelectedStoryManagerDatabaseTitle,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import ContentBoxWithSide, {
    ContentBoxMainContent,
    ContentBoxSideContent,
} from 'views/common/layout/ContentBoxWithSide';
import { getStore } from 'state';
import EditorModeSwitch from 'views/apps/StoryTeller/StoryManager/StoryManagerTemplate/StoryManagerActionsHeader';
import { setStoryManagerPageVars } from 'state/ui/uiPages.actions';
import { getStoryManagerSidebarWidth } from 'state/ui/storyManager.selector';
import SideContent from './SideContent';
import MainContent from './MainContent';

export default function FindAndReplace() {
    const store = getStore();
    const initialWidth = getStoryManagerSidebarWidth(store.getState());
    const storyManagerDatabase = getSelectedStoryManagerDatabaseDetail();

    useEffect(() => {
        resetLocaleFiltersForFindAndReplaceDetailView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storyManagerDatabase?.locales]);

    return (
        <ContentBoxWithSide
            titleLabelSelector={{
                selector: getSelectedStoryManagerDatabaseTitle,
                notifications: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
            }}
            customAction={{
                component: EditorModeSwitch,
            }}
            onResize={onResize}
            initialWidth={initialWidth}
        >
            <ContentBoxSideContent>
                <SideContent />
            </ContentBoxSideContent>
            <ContentBoxMainContent>
                <MainContent />
            </ContentBoxMainContent>

        </ContentBoxWithSide>
    );

    function onResize(width: number) {
        store.dispatch(setStoryManagerPageVars({
            sidebarWidth: width,
        }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_SIDE_NAV_WIDTH]));
    }

    /**
     * Enables all locales, this is needed to show all translations in the scenario detail view
     */
    function resetLocaleFiltersForFindAndReplaceDetailView() {
        if (storyManagerDatabase?.locales) {
            store.dispatch(setStoryManagerPageVars({
                filters: {
                    locale: storyManagerDatabase.locales,
                },
            }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER]));
        }
    }
}
