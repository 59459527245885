import {
    IFetchStoryManagerDatabaseApiInput,
    IStoryManagerDatabaseBase,
    IStoryManagerDatabase,
    IPatchStoryManagerDatabaseCmsApiInput,
    IPublishStoryManagerDatabaseCmsApiInput,
    IResetStoryManagerDatabaseCmsApiInput,
} from '../../models/storyteller/storymanager.models';
import { ConsoleBffPath } from '../../server/consoleBffUrls';
import { get, patch, post } from '../consoleBffRequestWrapper';

export function fetchStoryManagerDatabases() {
    return get<IStoryManagerDatabaseBase[]>({
        url: ConsoleBffPath.STORY_MANAGER_DATABASES,
    });
}

export function fetchStoryManagerDatabase({
    databaseId,
}: IFetchStoryManagerDatabaseApiInput) {
    return get<IStoryManagerDatabase>({
        url: ConsoleBffPath.STORY_MANAGER_DATABASE,
        pathParams: {
            databaseId,
        },
    });
}

export function patchStoryManagerDatabaseConfig({
    databaseId,
    jsonPatch,
}: IPatchStoryManagerDatabaseCmsApiInput) {
    return patch({
        url: ConsoleBffPath.STORY_MANAGER_DATABASE_CONFIG,
        pathParams: {
            databaseId,
        },
        body: {
            jsonPatch,
        },
    });
}

export function publishStoryManagerDatabaseConfig({
    databaseId,
}: IPublishStoryManagerDatabaseCmsApiInput) {
    return post<IStoryManagerDatabase>({
        url: ConsoleBffPath.STORY_MANAGER_DATABASE_PUBLISH_CONFIG,
        pathParams: {
            databaseId,
        },
    });
}

export function resetStoryManagerDatabaseConfig({
    databaseId,
}: IResetStoryManagerDatabaseCmsApiInput) {
    return post<IStoryManagerDatabase>({
        url: ConsoleBffPath.STORY_MANAGER_DATABASE_RESET_CONFIG,
        pathParams: {
            databaseId,
        },
    });
}
