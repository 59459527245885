import getRequestWrapper, {
    IRequestWrapper, IRequestWrapperConfig,
} from '@snipsonian/axios/cjs/request/getRequestWrapper';
import { IBodyRequestConfig, IGetRequestConfig } from '@snipsonian/axios/cjs/request/types';
import {
    convertFastifyUrlToRequestWrapperUrl,
} from '@typsy/rest-api/dist/server/request/convertFastifyUrlToRequestWrapperUrl';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { ICustomApiConfig, IOnApiWarningProps } from '@console/api-base/models/api.models';
import { DEFAULT_TIMEOUT_IN_MILLIS } from '../config/consoleBff.config';

interface IConsoleBffRequestWrapperConfig
    extends Pick<IRequestWrapperConfig<ICustomApiConfig, IBaseApiErrorClientSide>,
    'apiLogger' | 'defaultBaseUrl' | 'onError' | 'requestCustomTransformer'> {
    onApiWarning: TOnConsoleBffWarning;
}

type TOnConsoleBffWarning = (props: IOnApiWarningProps) => void;

let requestWrapper: IRequestWrapper<ICustomApiConfig, IBaseApiErrorClientSide> = null;
let onApiWarning: TOnConsoleBffWarning = null;

export function initConsoleBffRequestWrapper({
    apiLogger,
    defaultBaseUrl,
    onError,
    requestCustomTransformer,
    onApiWarning: newOnApiWarning,
}: IConsoleBffRequestWrapperConfig) {
    onApiWarning = newOnApiWarning;

    requestWrapper = getRequestWrapper<ICustomApiConfig, IBaseApiErrorClientSide>({
        apiLogger,
        defaultBaseUrl,
        defaultTimeoutInMillis: DEFAULT_TIMEOUT_IN_MILLIS,
        onError,
        requestCustomTransformer,
        trackNrOfRunningApiCalls: process.env.NODE_ENV === 'test',
    });
}

export function notifyApiWarning(warningCode: string) {
    onApiWarning({ warningCode });
}

export function get<Result, ResponseData = Result>(
    config: IGetRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.get({
        ...config,
        url: convertFastifyUrlToRequestWrapperUrl(config.url),
    });
}

export function post<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.post({
        ...config,
        url: convertFastifyUrlToRequestWrapperUrl(config.url),
    });
}

export function put<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.put({
        ...config,
        url: convertFastifyUrlToRequestWrapperUrl(config.url),
    });
}

export function patch<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.patch({
        ...config,
        url: convertFastifyUrlToRequestWrapperUrl(config.url),
    });
}

export function remove<Result, ResponseData = Result>(
    config: IBodyRequestConfig<Result, ResponseData, IBaseApiErrorClientSide> & ICustomApiConfig,
): Promise<Result> {
    return requestWrapper.remove({
        ...config,
        url: convertFastifyUrlToRequestWrapperUrl(config.url),
    });
}
