import { formatFloat } from '../float/floatUtils';

export const DEFAULT_CURRENCY_POSITION_RELATIVE_TO_AMOUNT = 'before';

let currentCurrencyPositionRelativeToAmount = DEFAULT_CURRENCY_POSITION_RELATIVE_TO_AMOUNT;

export function setCurrencyDisplayCustomizationConfig({
    positionRelativeToAmount,
}: {
    positionRelativeToAmount: string;
}) {
    currentCurrencyPositionRelativeToAmount = positionRelativeToAmount;
}

export function formatAmount(
    amount: number,
    {
        currency,
        nrOfDecimals = 2,
        stripTrailingDecimalZeros = false,
        useMagnitudeFlags = false,
    }: {
        currency?: string;
        nrOfDecimals?: number;
        stripTrailingDecimalZeros?: boolean;
        /**
         * if true, the K (thousand) and M (million) flags nay be used in the result:
         * - if amount >= 1.000.000 --> M flag & amount divided by 1.000.000
         * - if amount >= 1.000 --> K flag & amount divided by 1.000
         * - if amount < 1.000 --> no flag
         */
        useMagnitudeFlags?: boolean;
    } = {},
) {
    const formattedAmountParts: string[] = [];

    if (currency && currentCurrencyPositionRelativeToAmount === 'before') {
        formattedAmountParts.push(`${currency.toUpperCase()} `);
    }

    formattedAmountParts.push(
        formatFloat(amount, { nrOfDecimals, stripTrailingDecimalZeros, useMagnitudeFlags }),
    );

    if (currency && currentCurrencyPositionRelativeToAmount === 'after') {
        formattedAmountParts.push(` ${currency.toUpperCase()}`);
    }

    return formattedAmountParts.join('');
}
