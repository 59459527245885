import { IApiEntityListResponse, IBaseSingleEntityApiInput } from '../../models/api.models';
import {
    IFetchGoalsApiInput,
    TGoalsData,
    IGoalEntityData,
    ISingleGoalApiInput,
    TGoal,
    TTGoalPatch,
    TTGoalCreate,
} from '../../models/portfolioMgmt/goal.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { fetchAllPagesOfApiEntity } from '../../utils/fetch/fetchAllPagesOfApiEntity';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchAllGoals() {
    return fetchAllPagesOfApiEntity<IFetchGoalsApiInput, IGoalEntityData>({
        apiInput: {
            count: false,
        },
        api: fetchGoals,
    });
}

export function fetchGoals({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    orderBy,
    count,
}: IFetchGoalsApiInput = {}) {
    return get<TGoalsData, IApiEntityListResponse<IGoalEntityData>>({
        url: CoreApiPath.GOALS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchGoalDetails({ goalId }: ISingleGoalApiInput) {
    return get<TGoal>({
        url: CoreApiPath.GOAL_DETAILS,
        pathParams: {
            goalId,
        },
    });
}

export function patchGoal({
    id,
    ...patchFields
}: TTGoalPatch) {
    return patch<TGoal>({
        url: CoreApiPath.GOAL_DETAILS,
        pathParams: {
            goalId: id,
        },
        body: patchFields,
    });
}

export function createGoal(goalToCreate: TTGoalCreate) {
    return post<TGoal>({
        url: CoreApiPath.GOALS,
        body: goalToCreate,
    });
}

export function deleteGoal({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.GOAL_DETAILS,
        pathParams: {
            goalId: id,
        },
    });
}
