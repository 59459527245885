import isSet from '@snipsonian/core/cjs/is/isSet';
import { array } from '@typsy/schema-validation/dist/baseSchemas/arraySchema';
import { number } from '@typsy/schema-validation/dist/baseSchemas/numberSchema';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { SchemaErrorType } from '../types';

interface INumberRangeOptions {
    isMinRequired?: boolean; /* default true */
    isMaxRequired?: boolean; /* default true */
    shouldMaxBeGreaterOrEqual?: boolean; /* default true */
    isNullable?: boolean; /* default false */
}

export const numberRange = ({
    isMinRequired = true,
    isMaxRequired = true,
    shouldMaxBeGreaterOrEqual = true,
    isNullable = false,
}: INumberRangeOptions = {}) => initBaseArraySchema({ isNullable })
    .of(number())
    .length(2)
    .test(
        SchemaErrorType.NumberRangeMinRequired,
        // eslint-disable-next-line no-template-curly-in-string
        '${path}: the "min" value is required',
        /* It's ok if the value is not set so that this test would not influence any nullable/required tests */
        (value) => !isArrayWithValues(value) || !isMinRequired || isMinSet(value),
    )
    .test(
        SchemaErrorType.NumberRangeMaxRequired,
        // eslint-disable-next-line no-template-curly-in-string
        '${path}: the "max" value is required',
        /* It's ok if the value is not set so that this test would not influence any nullable/required tests */
        (value) => !isArrayWithValues(value) || !isMaxRequired || isMaxSet(value),
    )
    .test(
        SchemaErrorType.NumberRangeMaxSmallerThanMin,
        // eslint-disable-next-line no-template-curly-in-string
        '${path}: the "max" value should not be smaller than the "min" value',
        /* It's ok if the value is not set so that this test would not influence any nullable/required tests */
        (value) => !isArrayWithValues(value) || !shouldMaxBeGreaterOrEqual || isMaxGreaterOrEqualThanMin(value),
    );

function initBaseArraySchema({
    isNullable = false,
}: Pick<INumberRangeOptions, 'isNullable'>) {
    if (isNullable) {
        return array().nullable();
    }

    return array();
}

function isMinSet([min]: number[]) {
    return isSet(min);
}

function isMaxSet([, max]: number[]) {
    return isSet(max);
}

function isMaxGreaterOrEqualThanMin([min, max]: number[]) {
    if (!isSet(min) || !isSet(max)) {
        /* no comparison as at least one of the 2 is not set */
        return true;
    }

    return min <= max;
}
