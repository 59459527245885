import React from 'react';
import { IMenuItemBase } from 'models/state/ui.models';
import { MENU_COLORS } from 'config/styling/colors';
import { toggleIsUserSelectingMenu } from 'state/ui/actions';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import AnimatedMenuToggle from './AnimatedMenuToggle';

interface IPublicProps {
    isTopMenuShown: boolean;
    mainMenuItem: IMenuItemBase;
}

const useStyles = makeStyles((theme) => ({
    MenuHeader: {
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        color: MENU_COLORS.TITLE.TEXT,
        backgroundColor: MENU_COLORS.TOP.BACKGROUND,
    },
}));

function MenuHeader({
    dispatch,
    isTopMenuShown,
    mainMenuItem,
}: IObserveProps & IPublicProps) {
    const classes = useStyles();

    return (
        <div className={classes.MenuHeader}>
            <AnimatedMenuToggle
                onToggle={toggleTopMenu}
                isTopMenuShown={isTopMenuShown}
                mainMenuItem={mainMenuItem}
            />
        </div>
    );

    function toggleTopMenu() {
        dispatch(toggleIsUserSelectingMenu());
    }
}

export default observe<IPublicProps>(
    [],
    MenuHeader,
);
