import { getCurrentTimestamp } from '@snipsonian/core/cjs/date/currentDate';
import getCounter from 'utils/number/counter';

const counter = getCounter();

export default function generateUniqueKey({
    prefix = 'key',
}: {
    prefix?: string;
} = {}) {
    /* only the counter number would not be enough because that one is reset on refresh */
    return `${prefix}-${counter.next()}-${getCurrentTimestamp()}`;
}
