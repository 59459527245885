import isSet from '@snipsonian/core/cjs/is/isSet';
import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import {
    boolean, number, object, string, array,
    SchemaErrorType, ObjectSchema,
} from '@console/common/utils/schema';
import { IInstrumentIdToNameMap, IInstrumentsMap } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { IApiEntityCreateBase, TApiEntityPatchBase } from '@console/core-api/models/api.models';
import { IConsoleBffEndpointListInput } from '../../../models/consoleBffEndpoint.models';

export { partialDatetimePeriodSchema } from '@console/common/utils/schema';

export const instrumentMapSchema = object() as ObjectSchema<IInstrumentsMap>;

export const instrumentNameMapSchema = object() as ObjectSchema<IInstrumentIdToNameMap>;

export const coreApiListInputSchema: ObjectSchema<IConsoleBffEndpointListInput> = object({
    offset: decorateSchema(
        string().test(
            SchemaErrorType.Integer,
            // eslint-disable-next-line no-template-curly-in-string
            '${path} should contain an integer',
            (value) => !isSet(value) || !Number.isNaN(Number(value)),
        ),
        {
            description: 'The number of records to omit, from the start of the result set, when returning the results.',
            defaultVal: 0,
        },
    ),
    limit: decorateSchema(
        number().minVal(1),
        { description: 'Number of records to be returned in the results array.' },
    ),
    count: decorateSchema(
        boolean(),
        // eslint-disable-next-line max-len
        {
            // eslint-disable-next-line max-len
            description: 'Pass "true" to receive the total number of records, regardless of the offset & limit, that match the filters.',
            defaultVal: false,
        },
    ),
    pageNr: number().minVal(1),
    orderBy: decorateSchema(
        string().matches(
            /^[A-Za-z0-9_]+,(ascending|descending)$/,
            'should be a string of the format "<field>,<order>"',
        ),
        // eslint-disable-next-line max-len
        { description: 'To optionally order the results array. Has to be in the format "{field},{order}" where the order has to be either "ascending" or "descending"' },
    ),
});

export const coreApiCreateEntityBodySchema: ObjectSchema<IApiEntityCreateBase> = object({
    readable_by: array().of(string()).required(),
    modifiable_by: array().of(string()).required(),
});

export const coreApiPatchEntityBodySchema: ObjectSchema<Omit<TApiEntityPatchBase, 'id'>> = object({
    readable_by: array().of(string()),
    modifiable_by: array().of(string()),
});
