import isUndefined from '@snipsonian/core/cjs/is/isUndefined';
import { ITriggerFlashMessagePayload, ITriggerFlashMessagePayloadBase, TFlashMessageKey } from 'models/state/ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { FLASH_CONFIG } from 'config/ui.config';
import { createAction } from 'state';
import { isAlreadyFlashMessageWithSameTranslationKey } from 'state/ui/flashMessages.selectors';
import generateUniqueKey from 'utils/string/generateUniqueKey';

export const triggerFlashMessage = (payload: ITriggerFlashMessagePayload) => createAction<ITriggerFlashMessagePayload>({
    type: 'TRIGGER_FLASH_MESSAGE',
    payload,
    filter({ getState, action }) {
        if (isAlreadyFlashMessageWithSameTranslationKey(getState(), action.payload.translationKey)) {
            return false;
        }
        return action;
    },
    process({ setStateImmutable, action }) {
        setStateImmutable({
            toState: (draftState) => {
                const options = action.payload.options || {};
                if (isUndefined(options.autoHideDuration)) {
                    options.autoHideDuration = FLASH_CONFIG.AUTO_HIDE_DURATION_IN_MILLIS.DEFAULT;
                }

                draftState.ui.flashMessages.push({
                    key: generateUniqueKey({ prefix: 'flash' }),
                    translationKey: action.payload.translationKey,
                    translationPlaceholders: action.payload.translationPlaceholders,
                    options: {
                        ...options,
                        variant: action.payload.type || 'default',
                    },
                    dismissed: false,
                    navigateToRoute: action.payload.navigateToRoute,
                });
            },
            notificationsToTrigger: [StateChangeNotification.UI_FLASH_MESSAGES],
        });
    },
});

export const triggerFlashSuccess = (payload: ITriggerFlashMessagePayloadBase) => triggerFlashMessage({
    ...payload,
    type: 'success',
});

export const triggerFlashWarning = (payload: ITriggerFlashMessagePayloadBase) => triggerFlashMessage({
    ...payload,
    type: 'warning',
});

export const triggerFlashInfo = (payload: ITriggerFlashMessagePayloadBase) => triggerFlashMessage({
    ...payload,
    type: 'info',
});

export const triggerFlashError = (payload: ITriggerFlashMessagePayloadBase) => triggerFlashMessage({
    ...payload,
    type: 'error',
    options: {
        ...payload.options,
        autoHideDuration: FLASH_CONFIG.AUTO_HIDE_DURATION_IN_MILLIS.ERROR,
    },
});

interface IFlashMessageKeyPayload {
    key: TFlashMessageKey;
}

export const closeFlashMessage = (payload: IFlashMessageKeyPayload) => createAction<IFlashMessageKeyPayload>({
    type: 'CLOSE_FLASH_MESSAGE',
    payload,
    process({ setStateImmutable, action }) {
        setStateImmutable({
            toState: (draftState) => {
                const flashMessage = draftState.ui.flashMessages
                    .find((item) => item.key === action.payload.key);
                if (flashMessage) {
                    flashMessage.dismissed = true;
                }
            },
            notificationsToTrigger: [StateChangeNotification.UI_FLASH_MESSAGES],
        });
    },
});

export const removeFlashMessage = (payload: IFlashMessageKeyPayload) => createAction<IFlashMessageKeyPayload>({
    type: 'REMOVE_FLASH_MESSAGE',
    payload,
    process({ setStateImmutable, action }) {
        setStateImmutable({
            toState: (draftState) => {
                const index = draftState.ui.flashMessages
                    .findIndex((item) => item.key === action.payload.key);
                if (index !== -1) {
                    draftState.ui.flashMessages.splice(index, 1);
                }
            },
            notificationsToTrigger: [StateChangeNotification.UI_FLASH_MESSAGES],
        });
    },
});
