import React from 'react';
import { IDonutChartOptions } from 'models/donutChart.models';
import { APP_COLORS } from 'config/styling/colors';
import { COLOR_OPACTITY_WHEN_UN_SELECTED } from 'config/styling/chart';
import d3, { PieArcDatum } from 'utils/libs/d3';
import { determineDonutChartCenter } from 'utils/chart/donutChartUtils';
import { IPieDataItem } from 'views/common/charts/types';
import PieSlice from 'views/common/charts/PieSlice';
import PieSlicePercentageLine from 'views/common/charts/PieSlicePercentageLine';

export interface IPieProps {
    id: string;
    data: IPieDataItem[];
    options: IDonutChartOptions;
    selectedSliceColor?: string;
    onSelectSlice?: (color: string | null) => void; /* will be null when a slice gets deselected */
}

export default function Pie({
    id,
    data,
    options,
    selectedSliceColor,
    onSelectSlice,
}: IPieProps) {
    const pieGenerator = d3.pie();
    const generatedArcs = pieGenerator(data.map((item) => item.value));

    const { centerX, centerY } = determineDonutChartCenter(options);

    return (
        <g id={id}>
            {/* rectangle without visible purpose but just to capture the mouse movement */}
            <rect
                x={0}
                y={0}
                width={options.dimensions.maxWidth}
                height={options.dimensions.maxHeight}
                strokeWidth={0}
                fill={APP_COLORS.SYSTEM.WHITE}
                onMouseDown={onClickOutsidePieSlices}
            />

            <g transform={`translate(${centerX}, ${centerY})`}>
                {generatedArcs.map((arcData, index) => renderPieSlice({
                    arcData: arcData as PieArcDatum<number>,
                    index,
                    item: data[index],
                }))}
            </g>
        </g>
    );

    function renderPieSlice({
        arcData,
        index,
        item,
    }: {
        arcData: PieArcDatum<number>;
        index: number;
        item: IPieDataItem;
    }) {
        const opacity = determineOpacityBasedOnSelection(item.color);

        return (
            <g key={`pie-slice-${index}`}>
                <PieSlice
                    id={`${id}_slice-${index}`}
                    outerRadius={options.pie.outerRadius}
                    innerRadius={options.pie.innerRadius}
                    cornerRadius={options.slices.cornerRadius}
                    padAngleInRadians={options.slices.padAngleInRadians}
                    arcData={arcData}
                    color={item.color}
                    opacity={opacity}
                    transitionDurationInMillis={options.transitionDurationInMillis}
                    onPieSliceClicked={() => onClickPieSlice(item.color)}
                />
                {options.percentageLines && (
                    <PieSlicePercentageLine
                        {...item}
                        lineOuterRadius={options.percentageLines.outerRadius}
                        lineInnerRadius={options.percentageLines.innerRadius}
                        lineStrokeWidth={options.percentageLines.strokeWidth}
                        lineStrokeDasharray={options.percentageLines.strokeDasharray}
                        lineStrokeLinecap={options.percentageLines.strokeLinecap}
                        sliceStartAngleInRadians={arcData.startAngle}
                        sliceEndAngleInRadians={arcData.endAngle}
                        transitionDurationInMillis={options.transitionDurationInMillis}
                        opacity={opacity}
                    />
                )}
            </g>
        );
    }

    function determineOpacityBasedOnSelection(pieSliceColor: string): number {
        if (!selectedSliceColor) {
            /* no slice selected */
            return null;
        }

        if (selectedSliceColor === pieSliceColor) {
            /* this slice selected --> leave color untouched */
            return null;
        }

        /* other slice selected --> blur this one */
        return COLOR_OPACTITY_WHEN_UN_SELECTED;
    }

    function onClickPieSlice(newPieSliceColor: string) {
        if (selectedSliceColor === newPieSliceColor) {
            /* re-click on already selected slice --> de-select */
            onSelectSlice(null);
        } else {
            onSelectSlice(newPieSliceColor);
        }
    }

    function onClickOutsidePieSlices(/* event: React.MouseEvent */) {
        onSelectSlice(null);
    }
}
