import React from 'react';
import clsx from 'clsx';
import isFunction from '@snipsonian/core/cjs/is/isFunction';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { IBarChartOptions, TBarGroupValueLineAggregator } from 'models/barChart.models';
import { CHART_STYLING } from 'config/styling/chart';
import {
    determineAxisAmountTicks,
    determineAxisTimeTicks,
    determineXTimeScale,
    determineYLinearScale,
} from 'utils/chart/chartConfig.utils';
import d3, { AxisDomain } from 'utils/libs/d3';
import {
    determineBarChartSizeWithoutAxis,
    determineBarSizesForVerticalBarChart,
} from 'utils/chart/barChartUtils';
import { makeStyles } from 'views/styling';
import { IGroupedBarChartDataPoint, IValueLineItem, TXScale } from 'views/common/charts/types';
import XAxis from 'views/common/charts/XAxis';
import YAxis from 'views/common/charts/YAxis';
import { VerticalBarGroup } from 'views/common/charts/VerticalBarGroup';
import { YAxisLabel } from 'views/common/charts/YAxisLabel';
import { ValueLine } from 'views/common/charts/ValueLine';

export interface IGenericVerticalBarChartProps<XDomain extends AxisDomain> {
    id: string;
    className?: string;
    data: IGroupedBarChartDataPoint<XDomain, number>[];
    options: IBarChartOptions;
    // eslint-disable-next-line max-len
}

const useStyles = makeStyles((/* theme */) => ({
    GenericVerticalBarChart: {
        width: '100%',
        position: 'relative',
    },
}));

export default function GenericVerticalBarChart<XDomain extends AxisDomain>({
    id,
    className,
    data,
    options,
}: IGenericVerticalBarChartProps<XDomain>) {
    const classes = useStyles();

    const {
        chartWidth,
        chartHeight,
    } = determineBarChartSizeWithoutAxis(options);

    const xValues = getAllXValues();
    const yValues = getAllYValues();
    let minMaxYValues = d3.extent(yValues);

    // TODO tweak x ticks to the way we want them
    const xAxisTicks = options.axis.x.scaleType === 'time'
        ? determineAxisTimeTicks({
            xDates: xValues as unknown as Date[],
        })
        : null;

    const yAxisTicks = options.axis.x.scaleType === 'time'
        ? determineAxisAmountTicks({
            minAmount: minMaxYValues[0],
            maxAmount: minMaxYValues[1],
            tickLinesLength: chartWidth,
            autoTickValues: false,
            alwaysIncludeZeroTick: true,
            widerTickValues: true,
        })
        : null;
    if (yAxisTicks.tickValues) {
        minMaxYValues = d3.extent(yAxisTicks.tickValues);
    }

    const {
        // paddingInnerWidth,
        // paddingOuterWidth,
        barGroupSize,
        xAxisPaddingOuterWidth,
    } = determineBarSizesForVerticalBarChart({
        chartWidth,
        nrOfBarGroups: xAxisTicks.tickValues.length,
        // TODO
        nrOfAdjacentBarsWithinEachGroup: isArrayWithValues(data)
            ? data[0].bars.length
            : 1,
        bars: options.bars,
    });

    const xScale = options.axis.x.scaleType === 'time'
        ? determineXTimeScale({
            xDates: xValues as unknown as Date[],
            chartWidth: chartWidth - (2 * xAxisPaddingOuterWidth),
        })
        : null;

    const yScale = determineYLinearScale({
        globalMinMaxAmount: minMaxYValues,
        chartHeight,
    }).nice();

    const valueLineAggregator = isFunction(options.bars?.groups?.valueLineAggregator)
        ? options.bars.groups.valueLineAggregator as TBarGroupValueLineAggregator<XDomain>
        : null;
    const valueLineData: IValueLineItem<XDomain, number>[] = valueLineAggregator
        ? xAxisTicks.tickValues.map((xAxisDate, xTickIndex) => {
            const matchingDataPoint = data
                .find((dataItem) => dataItem.x === (xAxisDate as unknown as XDomain));

            const { y, variant: valueLineItemVariant } = valueLineAggregator({
                barGroup: matchingDataPoint,
                barGroupIndex: xTickIndex,
                nrOfBarGroups: data.length,
            });

            return {
                x: xAxisDate as unknown as XDomain,
                y,
                variant: valueLineItemVariant,
            };
        })
        : null;

    return (
        <svg
            id={id}
            className={clsx(classes.GenericVerticalBarChart, className)}
            style={{
                maxWidth: options.dimensions.maxWidth,
                maxHeight: options.dimensions.maxHeight,
            }}
            viewBox={`0 0 ${options.dimensions.maxWidth} ${options.dimensions.maxHeight}`}
            preserveAspectRatio="xMidYMid meet"
        >
            {options.axis.y.label && (
                <g transform={`translate(0, ${options.axis.y.marginTop})`}>
                    <YAxisLabel
                        label={options.axis.y.label.text}
                        chartHeight={chartHeight}
                        paddingLeft={options.axis.y.label.paddingLeft}
                    />
                </g>
            )}

            <g transform={`translate(${options.axis.y.width}, ${options.axis.y.marginTop})`}>

                <g transform={`translate(${xAxisPaddingOuterWidth}, ${chartHeight})`}>
                    <XAxis
                        xScale={xScale}
                        textColor={CHART_STYLING.colors.neutral['900']}
                        textSize={10}
                        {...xAxisTicks}
                    />
                </g>

                <YAxis
                    yScale={yScale}
                    {...yAxisTicks}
                    showTickLines
                    tickLineColor={CHART_STYLING.colors.neutral['400']}
                    tickLineStrokeDasharray="0.25 6"
                    tickLineStrokeLinecap="round"
                    textColor={CHART_STYLING.colors.neutral['900']}
                    textSize={10}
                    specialTickLines={[{
                        yValue: 0,
                        strokeColor: CHART_STYLING.colors.neutral['300'],
                        strokeLinecap: 'round',
                    }]}
                />

                <g transform={`translate(${xAxisPaddingOuterWidth}, 0)`}>
                    {xAxisTicks.tickValues.map((xAxisDate) => {
                        const matchingDataPoint = data
                            .find((dataItem) => dataItem.x === (xAxisDate as unknown as XDomain));

                        const barSeparatorLine = options.bars?.groups?.barSeparatorLine;

                        return (
                            <VerticalBarGroup<XDomain>
                                key={`vertical-bar-group_${xAxisDate}`}
                                xScale={xScale as unknown as TXScale<XDomain>}
                                yScale={yScale}
                                data={matchingDataPoint}
                                {...barGroupSize}
                                endCornerRadius={options.bars?.endCornerRadius}
                                transitionDurationInMillis={options.transitionDurationInMillis}
                                barSeparatorLine={barSeparatorLine}
                            />
                        );
                    })}

                    {valueLineAggregator && (
                        <ValueLine<XDomain, number>
                            xScale={xScale as unknown as TXScale<XDomain>}
                            yScale={yScale}
                            data={valueLineData}
                        />
                    )}
                </g>
            </g>
        </svg>
    );

    function getAllXValues(): XDomain[] {
        return data.map((dataItem) => dataItem.x);
    }

    function getAllYValues(): number[] {
        return data.reduce(
            (accumulator, barGroup) => {
                barGroup.bars.forEach((adjacentBar) => {
                    const sumOfPositiveBars = adjacentBar
                        .filter((stackedBar) => stackedBar.y >= 0)
                        .reduce(
                            (sumAccumulator, stackedBar) => sumAccumulator + stackedBar.y,
                            0,
                        );

                    const sumOfNegativeBars = adjacentBar
                        .filter((stackedBar) => stackedBar.y < 0)
                        .reduce(
                            (sumAccumulator, stackedBar) => sumAccumulator + stackedBar.y,
                            0,
                        );

                    accumulator.push(sumOfNegativeBars, sumOfPositiveBars);
                });

                return accumulator;
            },
            [],
        );
    }
}
