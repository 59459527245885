import React from 'react';
import { SortOrder } from '@console/common/models/sort.models';
import {
    IAdvancedPortfoliosFilters,
    IFetchPortfoliosApiInput,
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { IEnhancedPortfolioStatus } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import {
    TEnhancedPortfolioInList,
    TEnhancedPortfoliosData,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns, TDataItemId } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { portfoliosEntity, triggerFetchPortfolios } from 'state/entities/portfolioMgmt/portfolios';
import { UiPageKey } from 'models/state/ui.models';
import { isStorytellerEnabled } from 'state/appConfig/selectors';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { MAX_PORTFOLIO_REPORT_ITEM_SELECTION } from 'config/portfolioMgmt/portfolioReport.config';
import { getStore } from 'state';
import { canUserCreatePortfolioReport } from 'state/auth/apiEntityAuthorization.selectors';
import { DEFAULT_PORTFOLIO_TAB_KEY } from 'config/tabs.config';
import { openCreatePortfolioReportAppModal } from 'state/ui/appModals.actions';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import { PortfolioTypeIndicator } from 'views/common/icons/indicators';
import CondensedValue from 'views/common/widget/CondensedValue';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import { ViewIcon, ReportIcon } from 'views/common/icons';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import EnhancedPortfolioStatus from 'views/portfolioMgmt/Portfolios/EnhancedPortfolioStatus';
import ListPageForApiEntity, {
    IListPageForApiEntityProps,
    ISortConfigFunctions,
} from 'views/common/list/ListPageForApiEntity';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { IRenderAdvancedWithSchemaFiltersProps } from 'views/common/list/DataSearch';
import InputGroup from 'views/common/inputs/base/InputGroup';
import DataTable from 'views/common/list/DataTable';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { IExtraSelectData, ISelectConfigManagedInternally } from 'views/common/list/ListPage';
import {
    advancedPortfolioFiltersClientSideSchema,
} from 'views/portfolioMgmt/Portfolios/shared/advancedPortfolioFiltersSchema';
import { IFormValues } from 'views/common/inputs/extended/types';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';

export const DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX = 'portfolio_mgmt.portfolios.list.columns';

interface IPublicProps<ColValues extends IColValues, ExtraPortfolioItemData extends TObjectWithProps = unknown>
    // eslint-disable-next-line max-len
    extends Pick<IListPageForApiEntityProps<IFetchPortfoliosApiInput, IAdvancedPortfoliosFilters, TEnhancedPortfoliosData>, 'create' | 'actions' | 'box' | 'selectConfig' | 'includeRefreshButton' | 'noResultsRender'> {
    overrideEntity?: Partial<IOverrideEntity>;
    overrideUiVars?: IOverrideUiVars;
    overrideCols?: IOverrideCols<ColValues, ExtraPortfolioItemData>;
    onPortfolioRowClicked?: (portfolio: IDataItem<ColValues, ExtraPortfolioItemData>) => void;
    isPortfolioRowClickable?: (portfolio: IDataItem<ColValues, ExtraPortfolioItemData>) => boolean;
    isDataTableRowSelectable?: (item: IDataItem<ColValues, ExtraPortfolioItemData>) => boolean;
    enableStorytellerBulkReportGeneration?: boolean;
}

// eslint-disable-next-line max-len
interface IOverrideEntity extends Pick<IListPageForApiEntityProps<IFetchPortfoliosApiInput, IAdvancedPortfoliosFilters, TEnhancedPortfoliosData>, 'asyncListEntity' | 'asyncListEntityFetchTrigger' | 'setStateOnPageNrChange'> {
    dataNotification: StateChangeNotification; // default PORTFOLIOS_DATA
}

// eslint-disable-next-line max-len
interface IOverrideUiVars extends Pick<IListPageForApiEntityProps<IFetchPortfoliosApiInput, IAdvancedPortfoliosFilters, TEnhancedPortfoliosData>, 'uiPageKey'> {
    uiVarsNotification: StateChangeNotification; // default PORTFOLIOS_UI_VARS
}

interface IOverrideCols<ColValues extends IColValues, ExtraPortfolioItemData extends TObjectWithProps> {
    cols: TDataColumns<ColValues, ExtraPortfolioItemData>;
    toPortfolioListItem: TToPortfolioListItem<ColValues, ExtraPortfolioItemData>;
}

type TToPortfolioListItem<ColValues extends IColValues, ExtraPortfolioItemData> =
    (props: IToPortfolioListItemProps) => IDataItem<ColValues, ExtraPortfolioItemData>;

interface IToPortfolioListItemProps {
    portfolio: TEnhancedPortfolioInList;
    defaultPortfolioCols: IDefaultPortfolioCols;
}

function toDefaultPortfolioListItem({
    portfolio,
    defaultPortfolioCols,
}: IToPortfolioListItemProps): IDataItem<IDefaultPortfolioCols> {
    return {
        id: portfolio.id,
        colValues: defaultPortfolioCols,
    };
}

export interface IDefaultPortfolioCols extends IColValues {
    type: {
        managerType: PortfolioManagerType;
        moneyType: PortfolioMoneyType;
    };
    name: string;
    owner: string;
    externalId: string;
    status: IEnhancedPortfolioStatus;
    updated: string;
    actions: {
        onViewDetail: () => void;
        onCreateReport: () => void;
    };
}

interface IDesiredCol {
    colName: keyof IDefaultPortfolioCols;
    percentWidth: number;
}

interface IAdvancedWithSchemaPortfoliosFilters extends IAdvancedPortfoliosFilters, IFormValues {}

export const INITIAL_ADVANCED_PORTFOLIO_FILTERS: IAdvancedWithSchemaPortfoliosFilters = {
    externalId: '',
    email: '',
    userLastName: '',
    portfolioName: '',
    virtualIban: '',
    ids: [],
};

const DEFAULT_DESIRED_PORTFOLIO_COLS: IDesiredCol[] = [
    { colName: 'type', percentWidth: 5 },
    { colName: 'name', percentWidth: 25 },
    { colName: 'owner', percentWidth: 20 },
    { colName: 'externalId', percentWidth: 20 },
    { colName: 'status', percentWidth: 10 },
    { colName: 'updated', percentWidth: 15 },
    { colName: 'actions', percentWidth: 5 },
];

export function getDefaultPortfolioColumns({
    desiredCols = DEFAULT_DESIRED_PORTFOLIO_COLS,
    hideActions,
    enableSort = true,
}: {
    desiredCols?: IDesiredCol[];
    hideActions?: { createReport?: boolean; viewDetail?: boolean };
    enableSort?: boolean;
} = {}): TDataColumns<Partial<IDefaultPortfolioCols>> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'type': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.type`,
                            },
                            data: {
                                style: {
                                    paddingTop: 12,
                                },
                                render: ({ item }) => (
                                    <PortfolioTypeIndicator
                                        managerType={item.colValues.type.managerType}
                                        moneyType={item.colValues.type.moneyType}
                                    />
                                ),
                            },
                            align: 'center',
                            percentWidth,
                        };
                        break;
                    }
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.name`,
                            },
                            data: {
                                className: UtilityClass.table.cellBold,
                            },
                            percentWidth,
                            sort: enableSort
                                ? {
                                    initialOrder: SortOrder.Ascending,
                                    serverSide: {
                                        field: 'name',
                                    },
                                }
                                : null,
                        };
                        break;
                    }
                    case 'owner': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.owner`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'externalId': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.external_id`,
                            },
                            data: {
                                render: ({ cellValue }) => (
                                    <CondensedValue
                                        value={cellValue as string}
                                        tooltip={{
                                            enableClickListener: false,
                                        }}
                                    />
                                ),
                            },
                            percentWidth,
                            sort: enableSort
                                ? {
                                    initialOrder: SortOrder.Ascending,
                                    serverSide: {
                                        field: 'external_id',
                                    },
                                }
                                : null,
                        };
                        break;
                    }
                    case 'status': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.status`,
                            },
                            data: {
                                render: ({ item }) => (
                                    <EnhancedPortfolioStatus
                                        enhancedStatus={item.colValues.status}
                                    />
                                ),
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'updated': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.updated`,
                            },
                            percentWidth,
                            sort: enableSort
                                ? {
                                    initialOrder: SortOrder.Descending,
                                    serverSide: {
                                        field: 'version_datetime',
                                    },
                                }
                                : null,
                        };
                        break;
                    }
                    case 'actions': {
                        accumulator[colName] = {
                            label: EMPTY_COL_TITLE,
                            data: {
                                render: ({ item }) => {
                                    const actions: IActionItem[] = [];

                                    if (!hideActions?.viewDetail) {
                                        actions.push({
                                            onExecute: item.colValues.actions.onViewDetail,
                                            id: 'view-detail',
                                            label: {
                                                // eslint-disable-next-line max-len
                                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.actions.view_portfolio`,
                                            },
                                            variant: 'extra',
                                            icon: <ViewIcon />,
                                        });
                                    }
                                    if (!hideActions?.createReport && isStorytellerEnabled()) {
                                        actions.push({
                                            onExecute: item.colValues.actions.onCreateReport,
                                            id: 'create-report',
                                            label: {
                                                // eslint-disable-next-line max-len
                                                msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.actions.create_report`,
                                            },
                                            variant: 'extra',
                                            icon: <ReportIcon />,
                                        });
                                    }
                                    return (
                                        <ActionButtons
                                            actions={actions}
                                        />
                                    );
                                },
                            },
                            percentWidth,
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected col name '${colName}'`);
                }
            }

            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultPortfolioCols>>,
    );
}

export default function GenericPortfoliosList<
    ColValues extends IColValues = IDefaultPortfolioCols,
    ExtraPortfolioItemData extends TObjectWithProps = unknown,
>({
    overrideEntity = {},
    overrideUiVars,
    overrideCols,
    onPortfolioRowClicked,
    isPortfolioRowClickable,
    isDataTableRowSelectable,
    selectConfig: extraSelectConfig,
    enableStorytellerBulkReportGeneration = false,
    ...otherListPageProps
}: IPublicProps<ColValues, ExtraPortfolioItemData>) {
    const entityConfig: IOverrideEntity = {
        dataNotification: StateChangeNotification.PORTFOLIOS_DATA,
        asyncListEntity: portfoliosEntity,
        asyncListEntityFetchTrigger: triggerFetchPortfolios,
        setStateOnPageNrChange: (pageNr) => ({
            toState: (draftState) => {
                // eslint-disable-next-line no-param-reassign
                draftState.entities.portfolios.data.pageNr = pageNr;
            },
            notificationsToTrigger: [StateChangeNotification.PORTFOLIOS_DATA],
        }),
        ...overrideEntity,
    };
    const uiVarsConfig: IOverrideUiVars = overrideUiVars || {
        uiVarsNotification: StateChangeNotification.PORTFOLIOS_UI_VARS,
        uiPageKey: UiPageKey.portfoliosList,
    };
    const colsConfig: IOverrideCols<ColValues, ExtraPortfolioItemData> = overrideCols || {
        cols: getDefaultPortfolioColumns() as TDataColumns<ColValues>,
        // eslint-disable-next-line max-len
        toPortfolioListItem: toDefaultPortfolioListItem as unknown as TToPortfolioListItem<ColValues, ExtraPortfolioItemData>,
    };
    const storytellerBulkReportGenerationSelectConfig: ISelectConfigManagedInternally = isStorytellerEnabled() && {
        withSelectAll: true,
        maxItems: MAX_PORTFOLIO_REPORT_ITEM_SELECTION,
        selectActions: [{
            id: 'open-create-portfolio-report',
            label: 'portfolio_mgmt.portfolios.list.select_actions.create_reports_in_bulk',
            icon: <ReportIcon />,
            disabled: !canUserCreatePortfolioReport(),
            variant: 'main-icon-bare',
            onExecute: onOpenCreatePortfolioReportInBulkAppModal,
        }],
    };

    let sortConfigFunctions: ISortConfigFunctions;
    const store = getStore();

    return (
        <ListPageForApiEntity
            notifications={[entityConfig.dataNotification, uiVarsConfig.uiVarsNotification]}

            asyncListEntity={entityConfig.asyncListEntity}
            asyncListEntityFetchTrigger={entityConfig.asyncListEntityFetchTrigger}
            setStateOnPageNrChange={entityConfig.setStateOnPageNrChange}

            uiPageKey={uiVarsConfig.uiPageKey}
            notificationToTriggerOnUiVarChanges={uiVarsConfig.uiVarsNotification}

            list={{
                renderData: renderPortfolioList,
            }}
            search={{
                simple: {
                    tipTranslationKey: 'portfolio_mgmt.portfolios.list.filter.simple.tip',
                    mapSimpleSearchInputToFetchFilter,
                },
                advancedWithSchema: {
                    initialValues: INITIAL_ADVANCED_PORTFOLIO_FILTERS,
                    renderFilters: renderAdvancedPortfolioFilters,
                    schema: advancedPortfolioFiltersClientSideSchema,
                },
            }}

            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}
            selectConfig={enableStorytellerBulkReportGeneration
                ? storytellerBulkReportGenerationSelectConfig
                : extraSelectConfig}

            {...otherListPageProps}
        />
    );

    function renderAdvancedPortfolioFilters({
        fields,
    }: IRenderAdvancedWithSchemaFiltersProps<IAdvancedWithSchemaPortfoliosFilters>) {
        return (
            <>
                <InputGroup>
                    <ExtendedInputText
                        formField={fields.externalId}
                        wrapper={{
                            label: 'portfolio_mgmt.portfolios.list.filter.advanced.external_id.label',
                        }}
                        placeholder="portfolio_mgmt.portfolios.list.filter.advanced.external_id.tip"
                    />
                    <ExtendedInputSearchableSelect<string[]>
                        formField={fields.ids}
                        wrapper={{
                            label: 'portfolio_mgmt.portfolios.list.filter.advanced.ids.label',
                        }}
                        placeholder="portfolio_mgmt.portfolios.list.filter.advanced.ids.tip"
                        shouldAllowCustomInput
                        sortItemsByLabel={false}
                    />
                    <ExtendedInputText
                        formField={fields.virtualIban}
                        wrapper={{
                            label: 'portfolio_mgmt.portfolios.list.filter.advanced.virtual_iban.label',
                        }}
                        placeholder="portfolio_mgmt.portfolios.list.filter.advanced.virtual_iban.tip"
                    />
                </InputGroup>
                <InputGroup>
                    <ExtendedInputText
                        formField={fields.portfolioName}
                        wrapper={{
                            label: 'portfolio_mgmt.portfolios.list.filter.advanced.portfolio_name.label',
                        }}
                        placeholder="portfolio_mgmt.portfolios.list.filter.advanced.portfolio_name.tip"
                    />
                    <ExtendedInputText
                        formField={fields.userLastName}
                        wrapper={{
                            label: 'portfolio_mgmt.portfolios.list.filter.advanced.user_last_name.label',
                        }}
                        placeholder="portfolio_mgmt.portfolios.list.filter.advanced.user_last_name.tip"
                    />
                    <ExtendedInputText
                        formField={fields.email}
                        wrapper={{
                            label: 'portfolio_mgmt.portfolios.list.filter.advanced.email.label',
                        }}
                        placeholder="portfolio_mgmt.portfolios.list.filter.advanced.email.tip"
                    />
                </InputGroup>
            </>
        );
    }

    function renderPortfolioList({
        data,
        entityData,
        extraData,
    }: IRenderDataProps<
    TEnhancedPortfolioInList[],
    IExtraSelectData<IDataItem<ColValues, ExtraPortfolioItemData>>,
    TEnhancedPortfoliosData
    >) {
        if (!data) {
            return null;
        }

        const portfolioItems: IDataItem<ColValues, ExtraPortfolioItemData>[] = data.map((portfolio) => ({
            ...colsConfig.toPortfolioListItem({
                portfolio,
                defaultPortfolioCols: {
                    externalId: portfolio.external_id,
                    name: portfolio.name,
                    owner: getUserFullName(
                        extractEmbeddedEntity<IUserEntityData>({
                            data: entityData,
                            id: portfolio.owned_by_user_id,
                        }),
                    ),
                    updated: formatDateRelativeToNow({ date: portfolio.version_datetime }),
                    status: portfolio.enhancedStatus,
                    type: {
                        managerType: portfolio.config && portfolio.config.manager,
                        moneyType: portfolio.money_type,
                    },
                    actions: {
                        onCreateReport: () => {
                            store.dispatch(openCreatePortfolioReportAppModal({
                                extraData: {
                                    entityType: CoreApiEntityType.portfolios,
                                    reportsInputData: [{
                                        entityName: portfolio.name,
                                        entityCreationDatetime: portfolio.creation_datetime,
                                        entityId: portfolio.id,
                                        preferredLanguage: extractEmbeddedEntity<IUserEntityData>({
                                            data: entityData,
                                            id: portfolio.owned_by_user_id,
                                        })?.language,
                                        userId: portfolio.owned_by_user_id,
                                        portfolioManagerType: portfolio.config.manager,
                                        portfolioMoneyType: portfolio.money_type,
                                    }],
                                },
                            }));
                        },
                        onViewDetail: () => openPortfolioDetail(portfolio.id),
                    },
                },
            }),
        }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={portfolioItems}
                onItemRowClicked={onPortfolioRowClicked}
                isItemClickable={isPortfolioRowClickable}
                toDynamicCellClass={styleItemIfNotClickable}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
                selectData={extraData.selectData && {
                    ...extraData.selectData,
                    isItemSelectable: isDataTableRowSelectable,
                }}
            />
        );
    }

    function styleItemIfNotClickable({ dataItem }: IToDynamicCellClassProps<ColValues, ExtraPortfolioItemData>) {
        if (isPortfolioRowClickable && !isPortfolioRowClickable(dataItem)) {
            return [UtilityClass.table.cellDisabled];
        }

        return null;
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            externalId: simpleInput,
        };
    }

    function openPortfolioDetail(portfolioId: TDataItemId) {
        redirectTo({
            routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
            params: {
                portfolioId,
                portfolioTab: DEFAULT_PORTFOLIO_TAB_KEY,
            },
        });
    }

    function onOpenCreatePortfolioReportInBulkAppModal({
        selectedItems,
        entityData,
    }: { selectedItems: TEnhancedPortfolioInList[]; entityData: TEnhancedPortfoliosData }) {
        store.dispatch(openCreatePortfolioReportAppModal({
            extraData: {
                entityType: CoreApiEntityType.portfolios,
                reportsInputData: selectedItems.map((portfolio) => ({
                    entityName: portfolio.name,
                    entityCreationDatetime: portfolio.creation_datetime,
                    entityId: portfolio.id,
                    preferredLanguage: extractEmbeddedEntity<IUserEntityData>({
                        data: entityData,
                        id: portfolio.owned_by_user_id,
                    })?.language,
                    userId: portfolio.owned_by_user_id,
                    portfolioManagerType: portfolio.config.manager,
                    portfolioMoneyType: portfolio.money_type,
                })),
            },
        }));
    }
}
