import { IPeriodFilter, PeriodType, IPeriodBoundaries } from '@console/common/models/period.models';
import { getDatesForPeriodType } from '@console/common/utils/date/getDatesForPeriodType';
import { DEFAULT_PERIOD_TYPE } from 'config/ui.config';

export function getInitialPeriodData({
    defaultPeriodType = DEFAULT_PERIOD_TYPE,
    minDate,
    maxDate,
}: IPeriodBoundaries & {
    defaultPeriodType?: PeriodType;
}): IPeriodFilter {
    return {
        type: defaultPeriodType,
        ...getDatesForPeriodType({
            periodType: defaultPeriodType,
            minDate,
            maxDate,
        }),
    };
}
