import React from 'react';
import { DEFAULT_PUBLIC_INSTRUMENT_TAB_KEY } from 'config/tabs.config';
import { TDataItemId } from 'models/list.models';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import GenericPublicInstrumentsList from './GenericPublicInstrumentsList';

export function PublicInstrumentsList() {
    return (
        <GenericPublicInstrumentsList
            box={{
                title: 'thematic_search.public_instruments.title',
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_ADD,
                },
                tooltip: 'thematic_search.public_instruments.list.actions.create',
            }}
            onPublicInstrumentRowClicked={openPublicInstrumentDetail}
        />
    );

    function openPublicInstrumentDetail(publicInstrumentId: TDataItemId) {
        redirectTo({
            routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_DETAIL,
            params: {
                publicInstrumentId,
                publicInstrumentTab: DEFAULT_PUBLIC_INSTRUMENT_TAB_KEY,
            },
        });
    }
}
