import isSet from '@snipsonian/core/cjs/is/isSet';
import { stringLike } from '@typsy/schema-validation/dist/baseSchemas/stringSchema';
import { BankAccountType } from '../../../models/banking.models';
import { SchemaErrorType } from '../types';
import { combineRegexesWithOR } from '../../string/regexUtils';

const BANK_ACCOUNT_NUMBER_VALIDATION_CONFIG: {
    [key in BankAccountType]: { validation: RegExp, error: SchemaErrorType }
} = {
    [BankAccountType.IBAN]: {
        validation: /^[A-Z]{2}[A-Z0-9]{14,30}$/,
        error: SchemaErrorType.IBANBankAccountNumber,
    },
    [BankAccountType.BBAN]: {
        validation: /^[A-Z0-9]{6,30}$/,
        error: SchemaErrorType.BbanBankAccountNumber,
    },
};

const ANY_BANK_ACCOUNT_NUMBER_TYPE_REGEX = combineRegexesWithOR(Object.values(BANK_ACCOUNT_NUMBER_VALIDATION_CONFIG)
    .map((config) => config.validation));

export const bankAccountNumber = (bankAccountType?: BankAccountType) => {
    if (isSet(bankAccountType)) {
        const { validation, error } = BANK_ACCOUNT_NUMBER_VALIDATION_CONFIG[bankAccountType];
        return stringLike().format(validation, '', error);
    }

    return stringLike().format(ANY_BANK_ACCOUNT_NUMBER_TYPE_REGEX, '', SchemaErrorType.BankAccountNumber);
};
