import React from 'react';
import { useParams } from 'react-router-dom';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import ContentDivider from 'views/common/layout/ContentDivider';
import { isUserManagedPortfolio } from 'utils/entities/portfolioMgmt/portfolioUtils';
import FunctionalityNotAvailableForPortfolioType
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/FunctionalityNotAvailableForPortfolioType';
import {
    triggerFetchPortfolioPastPerformance,
} from 'state/entities/portfolioMgmt/portfolioPerformance';
import PortfolioPerformanceStatus from './PortfolioPerformanceStatus';
import PortfolioPerformanceGraphs from './PortfolioPerformanceGraphs';

export default function PortfolioPerformance() {
    const { portfolioId } = useParams<IPossiblePathParams>();

    const portfolioData = portfolioDetailsEntity.select().data;

    const doNotShowPerformance = isUserManagedPortfolio(portfolioData);
    const baseCurrency = portfolioData.base_currency;

    useExecuteOnMount({
        execute: () => {
            if (doNotShowPerformance) {
                return;
            }

            triggerFetchPortfolioPastPerformance({
                portfolioId,
                baseCurrency,
                includeStatus: true,
            });
        },
    });

    if (doNotShowPerformance) {
        return (
            <FunctionalityNotAvailableForPortfolioType />
        );
    }

    return (
        <div>
            <PortfolioPerformanceStatus
                baseCurrency={baseCurrency}
            />

            <ContentDivider />

            <PortfolioPerformanceGraphs
                portfolioId={portfolioId}
                baseCurrency={baseCurrency}
            />
        </div>
    );
}
