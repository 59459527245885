import React from 'react';
import { triggerPatchUserGroupDetails, userGroupDetailsEntity } from 'state/entities/userMgmt/userGroupDetails';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function UserGroupAccessibleBy() {
    const userGroupDetailsData = userGroupDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={userGroupDetailsData}
            nameForDisplay={userGroupDetailsData.name}
            asyncEntityPatchTrigger={triggerPatchUserGroupDetails}
        />
    );
}
