export enum SchemaErrorType {
    /* provided by Yup itself */
    Integer = 'integer',
    Matches = 'matches', // when doing string().matches(..)
    Max = 'max', // max string length
    Min = 'min', // min string length
    OneOf = 'oneOf',
    Required = 'required',
    /** Not sure what the difference is between 'optionality' and 'required'
     * but when bumping yup this popped up in a unit test */
    Optionality = 'optionality',

    /* provided by @typsy */
    CsvInjectionRisk = 'CsvInjectionRisk',
    Format = 'Format',
    MaxVal = 'MaxVal', // to differentiate between the string().max (max length) & number().max (max value)
    MinVal = 'MinVal', // to differentiate between the string().min (min length) & number().min (min value)
    MaxItems = 'MaxItems', // to differentiate between the string().max (max length) & array().max (max nr of items)
    MinItems = 'MinItems', // to differentiate between the string().min (min length) & array().min (min nr of items)
    MaxDate = 'MaxDate', // to differentiate between the string().max (max length) & date().max
    MinDate = 'MinDate', // to differentiate between the string().min (min length) & date().min
    OnlyTypeNumberAllowed = 'OnlyTypeNumberAllowed',
    OnlyTypeStringAllowed = 'OnlyTypeStringAllowed',
    ShouldBeNull = 'ShouldBeNull',
    UnallowedHtml = 'UnallowedHtml',
    Unique = 'Unique',

    /* custom */
    BankId = 'BankId',
    ABABankId = 'AbaBankId',
    BICBankId = 'BicBankId',

    BankAccountNumber = 'BankAccountNumber',
    IBANBankAccountNumber = 'IbanBankAccountNumber',
    BbanBankAccountNumber = 'BbanBankAccountNumber',

    BankAccountType = 'BankAccountType',
    CountryPhoneFormat = 'CountryPhoneFormat',
    PhoneFormat = 'PhoneFormat',
    NumberRangeMinRequired = 'NumberRangeMinRequired',
    NumberRangeMaxRequired = 'NumberRangeMaxRequired',
    NumberRangeMaxSmallerThanMin = 'NumberRangeMaxSmallerThanMin',
    HoldingsItem = 'HoldingsItem',
    PolicyTagsAtLeastOfParent = 'PolicyTagsAtLeastOfParent',
    PolicyUniverseSubsetOfParent = 'PolicyUniverseSubsetOfParent',
    PolicyBuyUniverseSubsetOfParent = 'PolicyBuyUniverseSubsetOfParent',
    PolicySettingShouldBeSameAsParent = 'PolicySettingShouldBeSameAsParent',
    PolicyNumberWithinParentBoundary = 'PolicyNumberWithinParentBoundary',
    PolicyAllocationInfeasibleLowerBoundaries = 'PolicyAllocationInfeasibleLowerBoundaries',
    PolicyAllocationInfeasibleUpperBoundaries = 'PolicyAllocationInfeasibleUpperBoundaries',
    NumberWithinBoundary = 'NumberWithinBoundary',
    CompositionTotalWeight = 'CompositionTotalWeight',
    MetaPortfolioComposition = 'MetaPortfolioComposition',
    ModelPortfolioComposition = 'ModelPortfolioComposition',
    ArrayOfEntityIds = 'ArrayOfEntityIds',
    StoryManagerPatch = 'StoryManagerPatch',
}
