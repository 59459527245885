import React, { useRef, useEffect } from 'react';
import { DEGREES_IN_RADIANS, radiansToDegrees } from '@console/common/utils/geometry/radianUtils';
import { TStrokeLinecap } from 'models/chart.models';
import { CHART_STYLING } from 'config/styling/chart';
import d3 from 'utils/libs/d3';
import { formatPercentage } from 'utils/number/percentageUtils';
import { IPieDataItem } from 'views/common/charts/types';
import Text from 'views/common/widget/Text';

export interface IPieSlicePercentageLineProps extends IPieDataItem {
    /* length of line = (lineOuterRadius - lineInnerRadius) */
    lineOuterRadius: number; /* the pie radius where the line has to end */
    lineInnerRadius: number; /* the pie radius where the line has to start */
    lineStrokeWidth?: number; /* default 1 */
    /* stroke-dasharray >> see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray */
    lineStrokeDasharray?: string; /* default '4' */
    /* stroke-linecap >> see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linecap */
    lineStrokeLinecap?: TStrokeLinecap; /* default 'butt' */
    /* in radians with 0 at -y (12 o’clock) and positive angles proceeding clockwise */
    sliceStartAngleInRadians: number;
    sliceEndAngleInRadians: number;
    transitionDurationInMillis?: number;
    opacity?: number;
}

interface ITextAnchorPoint {
    labelX: number;
    labelY: number;
    valueX: number;
    valueY: number;
    textAnchor: 'start' | 'middle' | 'end';
}

export default function PieSlicePercentageLine({
    value,
    label,
    lineOuterRadius,
    lineInnerRadius,
    lineStrokeWidth = 1,
    lineStrokeDasharray = '4',
    lineStrokeLinecap = 'butt',
    sliceStartAngleInRadians,
    sliceEndAngleInRadians,
    transitionDurationInMillis = 0,
    opacity = 1,
}: IPieSlicePercentageLineProps) {
    const lineRef = useRef();

    useEffect(
        () => {
            if (transitionDurationInMillis > 0) {
                const line = d3.select<SVGLineElement, unknown>(lineRef.current);

                line.transition()
                    .duration(transitionDurationInMillis)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    .attrTween('x2', () => {
                        const i = d3.interpolate(lineInnerRadius, lineOuterRadius);
                        return (t: number) => i(t);
                    });
            }
        },
        [transitionDurationInMillis, lineInnerRadius, lineOuterRadius],
    );

    const textAnchorPoint = getTextAnchorPoint();

    return (
        <g>
            <line
                ref={lineRef}
                x1={lineInnerRadius}
                y1={0}
                x2={lineOuterRadius}
                y2={0}
                transform={`rotate(${getPercentageLineRotationInDegrees()})`}
                stroke={CHART_STYLING.colors.neutral['900']}
                strokeWidth={lineStrokeWidth}
                strokeDasharray={lineStrokeDasharray}
                strokeLinecap={lineStrokeLinecap}
                opacity={opacity}
            />
            {label && (
                <text
                    x={textAnchorPoint.labelX}
                    y={textAnchorPoint.labelY}
                    textAnchor={textAnchorPoint.textAnchor}
                    fontSize="14px"
                    fontWeight="400"
                    fill={CHART_STYLING.colors.neutral['700']}
                    opacity={opacity}
                >
                    <Text label={label} />
                </text>
            )}
            <text
                x={textAnchorPoint.valueX}
                y={textAnchorPoint.valueY}
                textAnchor={textAnchorPoint.textAnchor}
                fontSize="14px"
                fontWeight="700"
                fill={CHART_STYLING.colors.neutral['900']}
                opacity={opacity}
            >
                {formatPercentage(value * 100)}
            </text>
        </g>
    );

    /**
     * The startAngle/endAngle have the Y axis as 0
     * but css rotation has the X axis as 0
     * so therefore we do minus 90
     */
    function getPercentageLineRotationInDegrees() {
        return radiansToDegrees(getSliceMiddleAngleInRadians()) - 90;
    }

    /**
     * Calculates where the labels of each slice have to be positioned.
     *
     * sinus = opposite side / hypotenuse side
     * cosinus = adjacent side / hypotenuse side
     *
     * middleAngleInRadians (Y axis as 0)  ~  degrees (X axis as 0)
     *   0      ~   90°
     *   PI/2   ~    0°
     *   PI     ~  -90°
     *   3/2 PI ~ -180°
     */
    function getTextAnchorPoint(): ITextAnchorPoint {
        const middleAngleInRadians = getSliceMiddleAngleInRadians();
        /* further from the line the lower you get */
        const hypotenuseSide = lineOuterRadius + calculateLabelDistanceFromLine(middleAngleInRadians);

        const valuePoint = {
            valueX: Math.sin(middleAngleInRadians) * hypotenuseSide,
            valueY: Math.cos(middleAngleInRadians) * hypotenuseSide * -1,
        };

        /* top side of pie --> above | bottom side --> below */
        const labelOffsetY = isRadianAngleInTopPartOfPie(middleAngleInRadians)
            ? -20
            : 20;

        return {
            ...valuePoint,
            labelX: valuePoint.valueX,
            labelY: valuePoint.valueY + labelOffsetY,
            /* right side of pie --> 'start' | left side --> 'end' */
            textAnchor: middleAngleInRadians <= DEGREES_IN_RADIANS['180'] ? 'start' : 'end',
        };
    }

    function getSliceMiddleAngleInRadians() {
        return (sliceEndAngleInRadians + sliceStartAngleInRadians) / 2;
    }

    /* further from the line the lower you get in the pie */
    function calculateLabelDistanceFromLine(lineAngleInRadians: number) {
        const relativeLineAngle = lineAngleInRadians > Math.PI
            ? (Math.PI - (lineAngleInRadians - Math.PI)) / Math.PI
            : lineAngleInRadians / Math.PI;

        return 4 + (8 * relativeLineAngle);
    }

    function isRadianAngleInTopPartOfPie(radianAngle: number) {
        if (radianAngle <= DEGREES_IN_RADIANS['90']) {
            return true;
        }

        if (radianAngle >= DEGREES_IN_RADIANS['270']) {
            return true;
        }

        return false;
    }
}
