import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TI18nLabelOrString, TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import { TTooltipPlacement } from 'views/common/widget/Tooltip';
import { TButtonColor } from './types';
import TextButton, { ITextButtonProps } from './TextButton';
import IconButton from './IconButton';

interface IPublicProps extends Pick<ITextButtonProps, 'size' | 'fontSize'> {
    id: string;
    className?: string;
    buttons: IButtonGroupItem[];
    activeKey?: string;
    buttonType?: 'text' | 'icon';
    activeColor?: TButtonColor;
    inactiveColor?: TButtonColor;
}

export interface IButtonGroupItem {
    key: string;
    label?: TI18nLabelOrString;
    onClick: React.MouseEventHandler;
    disabled?: boolean;
    icon?: React.ReactNode;
    className?: string;
    tooltip?: TLabel;
    tooltipPlacement?: TTooltipPlacement; // default "top"
}

const useStyles = makeStyles((/* theme */) => ({
    ButtonGroup: {
        ...mixins.flexRow({ alignMain: 'left', alignCross: 'center', wrap: 'nowrap' }),

        '& .__item': {
            marginLeft: 1,
            marginRight: 1,
        },
        '& .__middleItem': {
            borderRadius: 0,
        },
        '& .__firstItem': {
            marginLeft: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& .__lastItem': {
            marginRight: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },

        '& .__iconButton': {
            width: '48px',
            '& svg': {
                width: 24,
                height: 24,
            },
        },
    },
}));

export default function ButtonGroup({
    id,
    className,
    buttons,
    activeKey,
    buttonType = 'text',
    size,
    fontSize,
    activeColor,
    inactiveColor,
}: IPublicProps) {
    const classes = useStyles();

    return (
        <div
            id={id}
            className={clsx(classes.ButtonGroup, className)}
        >
            {buttonType === 'text' && buttons.map((buttonGroupItem, index) => (
                <TextButton
                    key={`ButtonGroup-item_${buttonGroupItem.key}`}
                    id={`${id}_${buttonGroupItem.key}`}
                    className={clsx(
                        '__item',
                        (index === 0) && '__firstItem',
                        (index !== 0 && index !== buttons.length - 1) && '__middleItem',
                        (index === buttons.length - 1) && '__lastItem',
                        buttonGroupItem.className,
                    )}
                    label={buttonGroupItem.label}
                    onClick={buttonGroupItem.onClick}
                    size={size}
                    fontSize={fontSize}
                    color={getButtonGroupItemColor(buttonGroupItem)}
                    disabled={buttonGroupItem.disabled}
                    tooltip={buttonGroupItem.tooltip}
                    tooltipPlacement={buttonGroupItem.tooltipPlacement}
                />
            ))}
            {buttonType === 'icon' && buttons.map((buttonGroupItem, index) => (
                <IconButton
                    key={`ButtonGroup-item_${buttonGroupItem.key}`}
                    id={`${id}_${buttonGroupItem.key}`}
                    className={clsx(
                        '__iconButton',
                        '__item',
                        (index === 0) && '__firstItem',
                        (index === buttons.length - 1) && '__lastItem',
                        buttonGroupItem.className,
                    )}
                    onClick={buttonGroupItem.onClick}
                    color={getButtonGroupItemColor(buttonGroupItem)}
                    disabled={buttonGroupItem.disabled}
                    icon={buttonGroupItem.icon}
                    size="S"
                    tooltip={buttonGroupItem.tooltip}
                    tooltipPlacement={buttonGroupItem.tooltipPlacement}
                />
            ))}
        </div>
    );

    function getButtonGroupItemColor({ key }: IButtonGroupItem): TButtonColor {
        if (!isSet(activeKey)) {
            return activeColor || 'primary';
        }

        return key === activeKey
            ? activeColor || 'primary'
            : inactiveColor || 'grey';
    }
}
