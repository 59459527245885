import React from 'react';
import { IIconButtonProps, ITextButtonProps } from 'views/common/buttons/types';

/**
 * This mechanism is needed to avoid a circular dependency as for example the Modal
 * uses the 'IconButton', but the 'BaseButton' uses the Modal:
 *   BaseButton >> Modal >> IconButton >> BaseButton
 * So by using these 'cached' properties, instead of directly importing the IconButton, the Modal
 * will only have a dependency to the (icon)Button at runtime (instead of at build time).
 */

let IconButton: React.ElementType<IIconButtonProps> = null;

export function getIconButton() {
    return IconButton;
}

export function setIconButton(element: React.ElementType<IIconButtonProps>) {
    IconButton = element;
}

let TextButton: React.ElementType<ITextButtonProps> = null;

export function getTextButton() {
    return TextButton;
}

export function setTextButton(element: React.ElementType<ITextButtonProps>) {
    TextButton = element;
}
