import { IRoute } from 'models/route.models';
import { ROUTE_KEY } from '../routeKeys';
import Releases from './Releases';

export const DOCUMENTATION_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_RELEASES,
    path: '/release-notes',
    exact: true,
    breadcrumb: {
        translationKey: 'documentation.releases.breadcrumb',
    },
    component: Releases,
}];
