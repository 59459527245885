import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IFetchHorizonsApiInput, THorizonsData } from '@console/core-api/models/portfolioMgmt/horizon.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { isRouteLocation } from 'state/ui/selectors';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { getEntitiesManager } from '../entitiesManager';

export const horizonsEntity = getEntitiesManager().registerEntity<THorizonsData>({
    asyncEntityKey: AsyncEntityKeys.horizons,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.HORIZONS_DATA],
    includeUpdaters: true,
});

export const triggerResetHorizonsFetch = () => horizonsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchHorizons({
    forceRefresh = false,
    ...apiInput
}: IFetchHorizonsApiInput & IForceStateRefreshFilter = {}) {
    return horizonsEntity.async.fetch<IFetchHorizonsApiInput>({
        api: api.horizons.fetchHorizons,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchHorizonsApiInput>({
            pageKey: UiPageKey.horizonsList,
            state,
            apiInput,
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: horizonsEntity.select().data,
        }),
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_HORIZON_DETAIL,
        }),
        resetDataOnTriggerMode: 'never',
    });
}
