import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TApiEntityId } from '@console/core-api/models/api.models';
import {
    PolicyAlgorithmType,
    TEnhancedPolicyComposition,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import GenericPortfolioSelect from 'views/portfolioMgmt/Portfolios/GenericPortfolioSelect';
import GenericPolicySelect from '../GenericPolicySelect';
import { IAddCompositionElementProps } from './types';

interface IAddMetaPortfolioCompositionTabProps {
    listEntityType: CoreApiEntityType.policies | CoreApiEntityType.portfolios;
    policyAlgorithmType?: PolicyAlgorithmType;
    setSelectedCompositionElement: (props: IAddCompositionElementProps) => void;
    selectedCompositionElementId: TApiEntityId;
    currentComposition: TEnhancedPolicyComposition;
}

export default function AddMetaPortfolioCompositionTab({
    listEntityType,
    policyAlgorithmType,
    selectedCompositionElementId,
    currentComposition,
    setSelectedCompositionElement,
}: IAddMetaPortfolioCompositionTabProps) {
    if (listEntityType === CoreApiEntityType.portfolios) {
        return (
            <GenericPortfolioSelect
                selectedPortfolioId={selectedCompositionElementId}
                onPortfolioSelected={({ portfolio }) => setSelectedCompositionElement({
                    compositionElementId: portfolio.id,
                    compositionElementName: portfolio.name,
                })}
                isPortfolioSelectable={(portfolioId) => !isSet(currentComposition[portfolioId])}
            />
        );
    }

    return (
        <GenericPolicySelect
            selectedPolicyId={selectedCompositionElementId}
            onPolicySelected={({ policy }) => setSelectedCompositionElement({
                compositionElementId: policy.id,
                compositionElementName: policy.name,
            })}
            isPolicySelectable={(policyId) => !isSet(currentComposition[policyId])}
            preFilledPolicyAlgorithmTypeFilter={policyAlgorithmType}
        />
    );
}
