import React from 'react';
import { IBarSeparatorLine } from 'models/barChart.models';
import { AxisDomain } from 'utils/libs/d3';
import { IGroupedBarChartDataPoint, TXScale, TYScale } from 'views/common/charts/types';
import { VerticalBar } from 'views/common/charts/VerticalBar';

interface IVerticalBarGroupProps<XDomain extends AxisDomain> {
    xScale: TXScale<XDomain>;
    yScale: TYScale<number>;
    data: IGroupedBarChartDataPoint<XDomain, number>;
    barGroupWidth: number;
    singleBarWidth: number;
    /** Only used when 'adjacent' bars */
    paddingInnerWidth: number;
    endCornerRadius?: number;
    transitionDurationInMillis?: number;
    /**
     * Only used for 'stacked' bars!
     * If defined, this results in horizontal lines being drawn between 2 touching bars.
     */
    barSeparatorLine?: IBarSeparatorLine;
}

export function VerticalBarGroup<XDomain extends AxisDomain>({
    xScale,
    yScale,
    data,
    barGroupWidth,
    singleBarWidth,
    paddingInnerWidth,
    endCornerRadius,
    transitionDurationInMillis,
    barSeparatorLine,
}: IVerticalBarGroupProps<XDomain>) {
    const xCoordinateOfMiddleOfBarGroup = xScale(data.x);

    return (
        <g className="VerticalBarGroup">
            {renderBarGroup()}
        </g>
    );

    /**
     * Render all the bars within the bar-group, which can be a combo of both adjacent & stacked bars.
     */
    function renderBarGroup() {
        /* for the first bar, we take the left side of the bar group */
        let xCoordinateOfNextAdjacentBarInGroup = xCoordinateOfMiddleOfBarGroup - (barGroupWidth / 2);

        return (
            <>
                {data.bars.map((adjacentBar, adjacentIndex) => {
                    const adjacentBarKey = `bar-group-rect_${adjacentIndex}`;

                    let yStartingValueOfNextStackedPositiveBar = 0;
                    let yStartingValueOfNextStackedNegativeBar = 0;

                    const xCoordinate = xCoordinateOfNextAdjacentBarInGroup;
                    xCoordinateOfNextAdjacentBarInGroup += singleBarWidth + paddingInnerWidth;

                    return (
                        <React.Fragment key={adjacentBarKey}>
                            {adjacentBar.map((stackedBar, stackedIndex) => {
                                const stackedBarKey = `${adjacentBarKey}_${stackedIndex}`;

                                const isPositiveBar = stackedBar.y > 0;
                                const isFirstStackedBar = stackedIndex === 0;

                                const isLastStackedBar = !adjacentBar
                                    .filter((otherStackedBar, otherStackedIndex) => otherStackedIndex > stackedIndex)
                                    .some((remainingStackedBar) => {
                                        if (isPositiveBar) {
                                            return remainingStackedBar.y > 0;
                                        }
                                        return remainingStackedBar.y <= 0;
                                    });

                                const yStartingValue = isPositiveBar
                                    ? yStartingValueOfNextStackedPositiveBar
                                    : yStartingValueOfNextStackedNegativeBar;
                                const yEndingValue = yStartingValue + stackedBar.y;

                                if (isPositiveBar) {
                                    yStartingValueOfNextStackedPositiveBar = yEndingValue;
                                } else {
                                    yStartingValueOfNextStackedNegativeBar = yEndingValue;
                                }

                                return (
                                    <VerticalBar
                                        key={stackedBarKey}
                                        yScale={yScale}
                                        xCoordinateLeft={xCoordinate}
                                        width={singleBarWidth}
                                        yStartingValue={yStartingValue}
                                        yEndingValue={yEndingValue}
                                        displayEndingValue={isLastStackedBar}
                                        color={stackedBar.color}
                                        endCornerRadius={isLastStackedBar ? endCornerRadius : 0}
                                        transitionDurationInMillis={transitionDurationInMillis}
                                        yStartingValueLine={isFirstStackedBar ? null : barSeparatorLine}
                                    />
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
}
