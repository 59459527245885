import { useState, useEffect, DependencyList } from 'react';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IAsyncItem } from '@console/core-api/models/api.models';
import useIsMounted from './useIsMounted';

export interface IUseAsyncFetchProps<Data> {
    shouldFetch?: () => boolean; // default true
    fetcher: () => Promise<Data>;
    deps: DependencyList;
}

export default function useAsyncFetch<Data>({
    shouldFetch,
    fetcher,
    deps,
}: IUseAsyncFetchProps<Data>): [IAsyncItem<Data>] {
    const [asyncItem, setAsyncItem] = useState<IAsyncItem<Data>>(getInitialAsyncItem<Data>());
    const isMounted = useIsMounted();

    useEffect(
        () => {
            if (shouldAsyncFetch()) {
                setAsyncItem({
                    ...asyncItem,
                    status: AsyncStatus.Busy,
                });

                fetcher()
                    .then((fetchResponse) => {
                        if (isMounted.current) {
                            setAsyncItem({
                                status: AsyncStatus.Success,
                                data: fetchResponse,
                                error: null,
                            });
                        }
                    })
                    .catch((error) => {
                        if (isMounted.current) {
                            setAsyncItem({
                                status: AsyncStatus.Error,
                                data: null,
                                error,
                            });
                        }
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );

    return [asyncItem];

    function shouldAsyncFetch(): boolean {
        return !shouldFetch || shouldFetch();
    }
}

function getInitialAsyncItem<Data>(): IAsyncItem<Data> {
    return {
        status: AsyncStatus.Initial,
        data: null,
        error: null,
    };
}
