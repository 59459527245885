/**
 * A list of all locales that are supported for one or more use cases.
 * E.g. the actual list of locales available in the client-facing apps can be different per tenant.
 */
export enum Locale {
    /** used by console */
    en_US = 'en-US', // English (used by console)

    /** used by localizely */
    en = 'en', // English (main)
    es_ES = 'es-ES', // Spanish
    de_DE = 'de-DE', // German
    zh_Hans = 'zh-Hans', // Simplified Chinese, Mandarin (中文)
    ar = 'ar', // Arabic
    el_GR = 'el-GR', // Greek
    fr = 'fr', // French
    nl = 'nl', // Dutch
    tr = 'tr', // Turkish
}

/**
 * All the potentially available locales (languages), but this list
 * can further be restricted per client in the appConfig.
 */
export const SUPPORTED_LOCALES: string[] = Object.values(Locale);

/**
 * Currently the console is always in 1 language, and not configurable per tenant.
 */
export const CONSOLE_LOCALES: string[] = [Locale.en_US];

export const DEFAULT_LOCALE = Locale.en_US;

export const TRANSLATION_KEY_DELIMITER = '.';
