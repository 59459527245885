import React from 'react';
import { SnackbarProvider } from 'notistack';
import { FLASH_CONFIG } from 'config/ui.config';
import { APP_COLORS } from 'config/styling/colors';
import { BOX_SHADOW } from 'config/styling/elevation';
import { makeStyles, mixins } from 'views/styling';
import FlashMessageManager from './FlashMessageManager';

const useStyles = makeStyles((theme) => ({
    FlashMessage: {
        '& > div': {
            ...mixins.typo({ size: 16 }),
            borderRadius: 12,
            boxShadow: BOX_SHADOW.DEFAULT,
        },

        '& .flash-navigate-icon': {
            padding: theme.spacing(1, 1, 1, 0),
        },

        '& .flash-close-icon': {
            marginBottom: '26px',
            marginRight: '-3px',

            '& svg': {
                ...mixins.typo({ size: 12 }),
            },
        },
    },
    success: {
        backgroundColor: `${APP_COLORS.FEEDBACK.SUCCESS} !important`,
    },
    error: {
        backgroundColor: `${APP_COLORS.FEEDBACK.ERROR} !important`,
    },
    warning: {
        backgroundColor: `${APP_COLORS.FEEDBACK.WARNING} !important`,
    },
    info: {
        backgroundColor: `${APP_COLORS.FEEDBACK.INFO} !important`,
    },
}));

export default function FlashMessages() {
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={FLASH_CONFIG.MAX_NR_MESSAGES_SHOWN}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            classes={{
                root: classes.FlashMessage,
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
        >
            <FlashMessageManager />
        </SnackbarProvider>
    );
}
