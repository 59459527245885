import isSet from '@snipsonian/core/cjs/is/isSet';
import { RecursivePartial } from '@console/common/models/genericTypes.models';
import {
    IListPageVars,
    IListSimpleSearch,
    IListAdvancedSearch,
    IListPagination,
    IListSorting,
    TListSearchMode,
    UiPageKey,
} from 'models/state/ui.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IPortfolioPerformancePageVars,
    TPortfolioPerformanceMode,
} from 'models/ui/portfolioPerformance.ui.models';
import { IAccessibleByPageVars } from 'models/ui/apiEntityAccessibleBy.ui.models';
import {
    IPolicySimulationPageVars,
    IPolicySimulationPerformancePageVars,
} from 'models/ui/policySimulation.ui.models';
import { IInstrumentPerformancePastPageVars } from 'models/ui/instrument.ui.models';
import { IUniverseInstrumentsPageVars } from 'models/ui/instruments.ui.models';
import { IUserGroupMembersPageVars } from 'models/ui/userGroup.ui.models';
import { IStoryManagerPageVars } from 'models/ui/storymanager.ui.models';
import {
    IMgmtReportingUiVars,
    IRoboMgmtReportingUiVars,
    ISelfMgmtReportingUiVars,
} from 'models/ui/mgmtReporting.ui.models';
import { INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS } from 'config/portfolioMgmt/portfolioPerformance.config';
import { createAction } from 'state';

interface IUpdateListCommonPayload {
    uiPageKey: UiPageKey;
    notificationToTrigger?: StateChangeNotification;
}

export const updateListSearchMode = (payload: { searchMode: TListSearchMode } & IUpdateListCommonPayload) =>
    createAction<{ searchMode: TListSearchMode } & IUpdateListCommonPayload>({
        type: 'UPDATE_LIST_SEARCH_MODE',
        payload,
        process({ setStateImmutable }) {
            const { uiPageKey, notificationToTrigger, searchMode } = payload;

            setStateImmutable({
                toState: (draftState) => {
                    const requestedPage =
                        getOrCreateSpecificUiPage<IListPageVars<unknown>>(draftState, uiPageKey);

                    if (!requestedPage.search) {
                        requestedPage.search = {};
                    }
                    requestedPage.search.mode = searchMode;
                },
                notificationsToTrigger: [StateChangeNotification.UI_LIST_PAGE_SEARCH_MODE]
                    .concat(isSet(notificationToTrigger) ? [notificationToTrigger] : []),
            });
        },
    });

export const updateListSimpleSearch = (payload: Partial<IListSimpleSearch> & IUpdateListCommonPayload) =>
    createAction<Partial<IListSimpleSearch> & IUpdateListCommonPayload>({
        type: 'UPDATE_LIST_SIMPLE_SEARCH',
        payload,
        process({ setStateImmutable }) {
            const { uiPageKey, notificationToTrigger, ...listSimpleSearch } = payload;

            setStateImmutable({
                toState: (draftState) => {
                    const requestedPage =
                        getOrCreateSpecificUiPage<IListPageVars<unknown>>(draftState, uiPageKey);

                    if (!requestedPage.search) {
                        requestedPage.search = {};
                    }
                    requestedPage.search.simple = {
                        ...requestedPage.search.simple,
                        ...listSimpleSearch,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_LIST_PAGE_SEARCH_SIMPLE]
                    .concat(isSet(notificationToTrigger) ? [notificationToTrigger] : []),
            });
        },
    });

// eslint-disable-next-line max-len
export const updateListAdvancedSearch = <AdvancedFilters = unknown>(payload: IListAdvancedSearch<Partial<AdvancedFilters>> & IUpdateListCommonPayload) =>
    createAction<IListAdvancedSearch<Partial<AdvancedFilters>> & IUpdateListCommonPayload>({
        type: 'UPDATE_LIST_ADVANCED_SEARCH',
        payload,
        process({ setStateImmutable }) {
            const { uiPageKey, notificationToTrigger, filterValues } = payload;

            setStateImmutable({
                toState: (draftState) => {
                    const requestedPage =
                        getOrCreateSpecificUiPage<IListPageVars<unknown>>(draftState, uiPageKey);

                    if (!requestedPage.search) {
                        requestedPage.search = {};
                    }
                    requestedPage.search = {
                        ...requestedPage.search,
                        mode: 'advanced',
                        advanced: {
                            filterValues: {
                                ...(requestedPage.search.advanced?.filterValues as AdvancedFilters),
                                ...filterValues,
                            },
                        },
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_LIST_PAGE_SEARCH_ADVANCED]
                    .concat(isSet(notificationToTrigger) ? [notificationToTrigger] : []),
            });
        },
    });

export const updateListPagination = (payload: IListPagination & IUpdateListCommonPayload) =>
    createAction<IListPagination & IUpdateListCommonPayload>({
        type: 'UPDATE_LIST_PAGINATION',
        payload,
        process({ setStateImmutable }) {
            const { uiPageKey, notificationToTrigger, itemsPerPage } = payload;

            setStateImmutable({
                toState: (draftState) => {
                    const requestedPage =
                        getOrCreateSpecificUiPage<IListPageVars<unknown>>(draftState, uiPageKey);

                    requestedPage.pagination = {
                        itemsPerPage,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_LIST_PAGE_PAGINATION]
                    .concat([notificationToTrigger]),
            });
        },
    });

export const updateListSorting = (payload: IListSorting & IUpdateListCommonPayload) =>
    createAction<IListSorting & IUpdateListCommonPayload>({
        type: 'UPDATE_LIST_SORTING',
        payload,
        process({ setStateImmutable }) {
            const { uiPageKey, notificationToTrigger, column } = payload;

            setStateImmutable({
                toState: (draftState) => {
                    const requestedPage =
                        getOrCreateSpecificUiPage<IListPageVars<unknown>>(draftState, uiPageKey);

                    requestedPage.sorting = {
                        column,
                    };
                },
                notificationsToTrigger: [StateChangeNotification.UI_LIST_PAGE_PAGINATION]
                    .concat([notificationToTrigger]),
            });
        },
    });

function getOrCreateSpecificUiPage<PageVars>(draftState: IState, uiPageKey: UiPageKey): PageVars {
    if (!draftState.ui.pages[uiPageKey]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draftState.ui.pages[uiPageKey] = {};
    }

    return draftState.ui.pages[uiPageKey] as PageVars;
}

export const setPortfolioPerformanceMode = (mode: TPortfolioPerformanceMode) =>
    createPartiallyUpdatePageVarsAction<IPortfolioPerformancePageVars>({
        type: 'SET_PORTFOLIO_PERFORMANCE_MODE',
        payload: {
            mode,
        },
        uiPageKey: UiPageKey.portfolioPerformance,
        pageVarsUpdater: ({ page }) => {
            page.mode = mode;
        },
        notificationsToTrigger: [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_MODE],
    });

export const updatePolicySimulationPerformancePageVars = (
    payload: IPolicySimulationPerformancePageVars,
) =>
    createAction({
        type: 'UPDATE_PAGE_POLICY_SIMULATION_PERFORMANCE',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IPolicySimulationPerformancePageVars>(
                        draftState, UiPageKey.policySimulationPerformance,
                    );

                    page.markedDate = payload.markedDate;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_POLICY_PERFORMANCE_FUTURE_MARKED_DATE],
            });
        },
    });

export const updateInstrumentPerformancePastPageVars = (
    payload: IInstrumentPerformancePastPageVars,
) =>
    createAction({
        type: 'UPDATE_PAGE_INSTRUMENT_PAST_PERFORMANCE',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IInstrumentPerformancePastPageVars>(
                        draftState, UiPageKey.instrumentPerformancePast,
                    );

                    page.markedDate = payload.markedDate;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_INSTRUMENT_PERFORMANCE_PAST_MARKED_DATE],
            });
        },
    });

export const updatePortfolioPerformancePageVars = (
    payload: RecursivePartial<IPortfolioPerformancePageVars>,
    notificationsToTrigger: StateChangeNotification[] = [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE],
) =>
    createPartiallyUpdatePageVarsAction<IPortfolioPerformancePageVars>({
        type: 'UPDATE_PAGE_PORTFOLIO_PERFORMANCE',
        payload,
        uiPageKey: UiPageKey.portfolioPerformance,
        pageVarsUpdater: ({ page, input }) => {
            if (input.past) {
                page.past = {
                    ...(page.past || {}),
                    ...(input as Partial<IPortfolioPerformancePageVars>).past,
                };
            }

            if (input.future) {
                page.future = {
                    ...(page.future || {}),
                    ...(input as Partial<IPortfolioPerformancePageVars>).future,
                };
            }
        },
        notificationsToTrigger,
    });

export const resetPortfolioPerformancePageVars = () =>
    createPartiallyUpdatePageVarsAction<IPortfolioPerformancePageVars>({
        type: 'RESET_PAGE_PORTFOLIO_PERFORMANCE',
        payload: {},
        uiPageKey: UiPageKey.portfolioPerformance,
        pageVarsUpdater: ({ page }) => {
            page.mode = INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS.mode;
            page.past = INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS.past;
            page.future = INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS.future;
        },
        notificationsToTrigger: [StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE],
    });

export const updateAccessibleByPageVars = (payload: Partial<IAccessibleByPageVars>) =>
    createAction({
        type: 'UPDATE_PAGE_ACCESSIBLE_BY',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IAccessibleByPageVars>(
                        draftState, UiPageKey.accessibleBy,
                    );

                    if (isSet(payload.entityType)) {
                        page.entityType = payload.entityType;
                    }
                    if (isSet(payload.showOnlyEnabledPermissions)) {
                        page.showOnlyEnabledPermissions = payload.showOnlyEnabledPermissions;
                    }
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_ACCESSIBLE_BY],
            });
        },
    });

export const updateUserGroupMembersPageVars = (payload: IUserGroupMembersPageVars) =>
    createAction({
        type: 'UPDATE_USERGROUP_MEMBERS_PAGE_VARS',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IUserGroupMembersPageVars>(
                        draftState, UiPageKey.userGroupMembersList,
                    );

                    page.showOnlyActiveMembers = payload.showOnlyActiveMembers;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_USERGROUP_MEMBERS_FILTER],
            });
        },
    });

export const updateUniverseInstrumentsPageVars = (payload: IUniverseInstrumentsPageVars) =>
    createAction({
        type: 'UPDATE_UNIVERSE_INSTRUMENTS_PAGE_VARS',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IUniverseInstrumentsPageVars>(
                        draftState, UiPageKey.universeInstrumentsList,
                    );

                    page.showOnlySelectedInstruments = payload.showOnlySelectedInstruments;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_UNIVERSE_INSTRUMENTS_FILTER],
            });
        },
    });

export const updatePolicySimulationInvestmentPageVar = (payload: Pick<IPolicySimulationPageVars, 'investmentAmount'>) =>
    createAction({
        type: 'UPDATE_POLICY_SIMULATION_INVESTMENT',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IPolicySimulationPageVars>(
                        draftState, UiPageKey.policySimulation,
                    );

                    page.investmentAmount = payload.investmentAmount;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_POLICY_SIMULATION],
            });
        },
    });

// eslint-disable-next-line max-len
export const updatePolicySimulationExpectedReturnsPageVar = (payload: Pick<IPolicySimulationPageVars, 'useExpectedReturns'>) =>
    createAction({
        type: 'UPDATE_POLICY_SIMULATION_EXPECTED_RETURNS',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IPolicySimulationPageVars>(
                        draftState, UiPageKey.policySimulation,
                    );

                    page.useExpectedReturns = payload.useExpectedReturns;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_POLICY_SIMULATION],
            });
        },
    });

export const updatePolicySimulationHorizonPageVar = (payload: Pick<IPolicySimulationPageVars, 'horizonYears'>) =>
    createAction({
        type: 'UPDATE_POLICY_SIMULATION_HORIZON',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IPolicySimulationPageVars>(
                        draftState, UiPageKey.policySimulation,
                    );

                    page.horizonYears = payload.horizonYears;
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_POLICY_SIMULATION],
            });
        },
    });

export const setStoryManagerPageVars = (
    payload: Partial<IStoryManagerPageVars>,
    notifications: StateChangeNotification[],
) =>
    createAction({
        type: 'UPDATE_PAGE_STORYMANAGER',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IStoryManagerPageVars>(
                        draftState, UiPageKey.storyManager,
                    );
                    if (isSet(payload.editorMode)) {
                        page.editorMode = payload.editorMode;
                    }
                    if (isSet(payload.sidebarWidth)) {
                        page.sidebarWidth = payload.sidebarWidth;
                    }
                    if (isSet(payload.collapsedOutputKeyIds)) {
                        page.collapsedOutputKeyIds = payload.collapsedOutputKeyIds;
                    }
                    if (isSet(payload.filters)) {
                        page.filters = payload.filters;
                    }
                },
                notificationsToTrigger: notifications,
            });
        },
    });

export const setStoryManagerOutputKeyCollapsePageVar = (payload: { outputKeyId: string, collapsed: boolean; }) =>
    createAction<{ outputKeyId: string, collapsed: boolean; }>({
        type: 'UPDATE_PAGE_STORYMANAGER_OUTPUT_KEY_COLLAPSE',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<IStoryManagerPageVars>(
                        draftState, UiPageKey.storyManager,
                    );

                    if (!isSet(page.collapsedOutputKeyIds)) {
                        page.collapsedOutputKeyIds = [];
                    }

                    if (payload.collapsed) {
                        page.collapsedOutputKeyIds.push(payload.outputKeyId);
                    } else {
                        const indexOfOutputKey = page.collapsedOutputKeyIds.indexOf(payload.outputKeyId);
                        page.collapsedOutputKeyIds.splice(indexOfOutputKey, 1);
                    }
                },
                notificationsToTrigger: [StateChangeNotification.UI_PAGE_STORY_MANAGER_SIDE_NAV_OUTPUT_KEYS],
            });
        },
    });

function createPartiallyUpdatePageVarsAction<PageVars>({
    type,
    payload,
    uiPageKey,
    pageVarsUpdater,
    notificationsToTrigger,
}: {
    type: string;
    payload: RecursivePartial<PageVars>;
    uiPageKey: UiPageKey;
    pageVarsUpdater: (props: { page: PageVars; input: RecursivePartial<PageVars> }) => void;
    notificationsToTrigger: StateChangeNotification[];
}) {
    return createAction({
        type,
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    const page = getOrCreateSpecificUiPage<PageVars>(
                        draftState, uiPageKey,
                    );

                    pageVarsUpdater({ page, input: payload });
                },
                notificationsToTrigger,
            });
        },
    });
}

export const updateRoboMgmtReportingPageVars = (payload: Partial<IRoboMgmtReportingUiVars>) =>
    createPartiallyUpdatePageVarsAction<IMgmtReportingUiVars>({
        type: 'UPDATE_MGMT_REPORTING_ROBO',
        payload: {
            robo: payload,
        },
        uiPageKey: UiPageKey.mgmtReporting,
        pageVarsUpdater: ({ page }) => {
            page.robo = {
                ...page.robo,
                ...payload,
            };
        },
        notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_ROBO_UI_VARS],
    });

export const updateSelfMgmtReportingPageVars = (payload: Partial<ISelfMgmtReportingUiVars>) =>
    createPartiallyUpdatePageVarsAction<IMgmtReportingUiVars>({
        type: 'UPDATE_MGMT_REPORTING_SELF',
        payload: {
            self: payload,
        },
        uiPageKey: UiPageKey.mgmtReporting,
        pageVarsUpdater: ({ page }) => {
            page.self = {
                ...page.self,
                ...payload,
            };
        },
        notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_SELF_UI_VARS],
    });
