import React from 'react';
import clsx from 'clsx';
import {
    IEnhancedOptimizationPortfolioUpdate,
} from '@console/bff/models/portfolios/enhancedPortfolioOptimization.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';

const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.optimizations.nr_of_orders';

interface IPortfolioOptimizationNrOfOrdersProps
    extends Pick<IEnhancedOptimizationPortfolioUpdate, 'nrOfBuys' | 'nrOfSells'> {
    status: EnhancedOptimizationStatus;
    className?: string;
    skipStatusPrefix?: boolean; // default false
}

const useStyles = makeStyles((/* theme */) => ({
    OptimizationNrOfOrders: {
        whiteSpace: 'nowrap',

        '& .__buyOrSell': {
            ...mixins.typoBold(),
        },
    },
}));

export default function OptimizationNrOfOrders({
    status,
    className,
    nrOfBuys,
    nrOfSells,
    skipStatusPrefix = false,
}: IPortfolioOptimizationNrOfOrdersProps) {
    const classes = useStyles();

    const nrOfOrdersLabelSuffix = skipStatusPrefix
        ? 'only_buy_sell'
        : status === EnhancedOptimizationStatus.RECOMMENDED
            ? 'optimization_needed'
            : status === EnhancedOptimizationStatus.NOT_RECOMMENDED
                ? 'optimization_optional'
                : null;

    if (!nrOfOrdersLabelSuffix) {
        return null;
    }

    return (
        <div className={clsx(classes.OptimizationNrOfOrders, className)}>
            <Text
                label={{
                    msg: `${LABEL_PREFIX}.${nrOfOrdersLabelSuffix}`,
                    placeholders: {
                        nrOfBuys: (<span className="__buyOrSell">{nrOfBuys}</span>),
                        nrOfSells: (<span className="__buyOrSell">{nrOfSells}</span>),
                    },
                }}
            />
        </div>
    );
}
