import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import d3 from 'utils/libs/d3';
import {
    IPortfolioPerformanceFuture,
} from '@console/core-api/models/portfolioMgmt/portfolioPerformance.models';
import { IEnhancedPortfolioPerformancePast } from '@console/bff/models/portfolios/enhancedPortfolioPerformance.models';
import {
    IPortfolioFuturePerformanceMarkedDate,
    IPortfolioPastPerformanceMarkedDate,
} from 'models/ui/portfolioPerformance.ui.models';
import { IChartDimensions, IGenericPerformanceChartConfig } from 'models/chart.models';
import { CHART, MIN_Y_AXIS_AMOUNT_RANGE } from 'config/styling/chart';
import { getGenericPerformanceChartConfig } from 'utils/chart/getGenericPerformanceChartConfig';
import { ensureMinimumRange } from 'utils/number/range';
import { IAreaDataItem, ILineDataItem } from 'views/common/charts/types';

interface IDate2FuturePerformanceValuesMap {
    [date: string]: IPortfolioFuturePerformanceMarkedDate;
}

interface IDate2PastPerformanceValuesMap {
    [date: string]: IPortfolioPastPerformanceMarkedDate;
}

export function getPortfolioPastPerformanceChartConfig({
    chartDimensions,
    pastPerformanceData,
}: {
    chartDimensions: IChartDimensions;
    pastPerformanceData: IEnhancedPortfolioPerformancePast;
}): IGenericPerformanceChartConfig<IPortfolioPastPerformanceMarkedDate> {
    const date2valuesMap: IDate2PastPerformanceValuesMap = pastPerformanceData.date.reduce(
        (accumulator, dateString, index) => {
            accumulator[dateString] = {
                date: new Date(dateString),
                values: {
                    value: pastPerformanceData.value_amount[index],
                    invested: pastPerformanceData.invested_amount[index],
                    return: pastPerformanceData.return_amount[index],
                    twr: pastPerformanceData.time_weighted_return[index],
                },
            };
            return accumulator;
        },
        {} as IDate2PastPerformanceValuesMap,
    );

    const valueLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.value,
        }),
    );
    const investedLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.invested,
        }),
    );
    const areaData: IAreaDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y0: values.value,
            y1: values.invested,
        }),
    );

    const xDates = valueLineData.map((valueLineItem) => valueLineItem.x);

    const valueMinMax = d3.extent(pastPerformanceData.value_amount) as number[];
    const investedMinMax = d3.extent(pastPerformanceData.invested_amount) as number[];
    const globalMinMaxAmount = d3.extent(valueMinMax.concat(investedMinMax));

    // eslint-disable-next-line max-len
    const baseConfig = getGenericPerformanceChartConfig<IDate2PastPerformanceValuesMap, IPortfolioPastPerformanceMarkedDate>({
        chartDimensions,
        xDates,
        globalMinMaxAmount: ensureMinimumRange(globalMinMaxAmount, MIN_Y_AXIS_AMOUNT_RANGE),
        date2valuesMap,
    });

    return {
        ...baseConfig,
        lines: {
            value: {
                data: valueLineData,
                color: CHART.PAST.VALUE_COLOR,
            },
            invested: {
                data: investedLineData,
                color: CHART.PAST.INVESTED_COLOR,
            },
        },
        area: {
            data: areaData,
            color: CHART.PAST.AREA_COLOR,
        },
        markerStartingPos: {
            x: baseConfig.xScale.domain()[1],
            y: {
                invested: {
                    val: getLastItemOfArray(investedLineData).y,
                    color: CHART.PAST.INVESTED_COLOR,
                    className: 'InvestedMarker',
                },
                value: {
                    val: getLastItemOfArray(valueLineData).y,
                    color: CHART.PAST.VALUE_COLOR,
                    className: 'ValueMarker',
                },
            },
        },
    };
}

export function getPortfolioFuturePerformanceChartConfig({
    chartDimensions,
    futurePerformanceData,
}: {
    chartDimensions: IChartDimensions;
    futurePerformanceData: IPortfolioPerformanceFuture;
}): IGenericPerformanceChartConfig<IPortfolioFuturePerformanceMarkedDate> {
    const date2valuesMap: IDate2FuturePerformanceValuesMap = futurePerformanceData.date.reduce(
        (accumulator, dateString, index) => {
            accumulator[dateString] = {
                date: new Date(dateString),
                values: {
                    invested: futurePerformanceData.invested_amount[index],
                    optimistic: futurePerformanceData.value_amount[2][index],
                    realistic: futurePerformanceData.value_amount[1][index],
                    pessimistic: futurePerformanceData.value_amount[0][index],
                },
            };
            return accumulator;
        },
        {} as IDate2FuturePerformanceValuesMap,
    );

    const optimisticLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.optimistic,
        }),
    );
    const realisticLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.realistic,
        }),
    );
    const pessimisticLineData: ILineDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y: values.pessimistic,
        }),
    );
    const areaData: IAreaDataItem<Date, number>[] = Object.values(date2valuesMap).map(
        ({ date, values }) => ({
            x: date,
            y0: values.optimistic,
            y1: values.pessimistic,
        }),
    );

    const xDates = optimisticLineData.map((optimisticLineItem) => optimisticLineItem.x);

    const optimisticMinMax = d3.extent(futurePerformanceData.value_amount[2]) as number[];
    const pessimisticMinMax = d3.extent(futurePerformanceData.value_amount[0]) as number[];
    const globalMinMaxAmount = d3.extent(optimisticMinMax.concat(pessimisticMinMax));

    // eslint-disable-next-line max-len
    const baseConfig = getGenericPerformanceChartConfig<IDate2FuturePerformanceValuesMap, IPortfolioFuturePerformanceMarkedDate>({
        chartDimensions,
        xDates,
        globalMinMaxAmount,
        date2valuesMap,
    });

    return {
        ...baseConfig,
        lines: {
            optimistic: {
                data: optimisticLineData,
                color: CHART.FUTURE.OPTIMISTIC_COLOR,
                curveFactory: d3.curveBasis,
            },
            realistic: {
                data: realisticLineData,
                color: CHART.FUTURE.REALISTIC_COLOR,
                curveFactory: d3.curveBasis,
            },
            pessimistic: {
                data: pessimisticLineData,
                color: CHART.FUTURE.PESSIMISTIC_COLOR,
                curveFactory: d3.curveBasis,
            },
        },
        area: {
            data: areaData,
            color: CHART.FUTURE.AREA_COLOR,
            curveFactory: d3.curveBasis,
        },
        markerStartingPos: {
            x: baseConfig.xScale.domain()[1],
            y: {
                pessimistic: {
                    val: getLastItemOfArray(pessimisticLineData).y,
                    color: CHART.FUTURE.PESSIMISTIC_COLOR,
                    className: 'PessimisticMarker',
                },
                realistic: {
                    val: getLastItemOfArray(realisticLineData).y,
                    color: CHART.FUTURE.REALISTIC_COLOR,
                    className: 'RealisticMarker',
                },
                optimistic: {
                    val: getLastItemOfArray(optimisticLineData).y,
                    color: CHART.FUTURE.OPTIMISTIC_COLOR,
                    className: 'OptimisticMarker',
                },
            },
        },
    };
}
