import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import { IBaseSingleEntityApiInput } from '@console/core-api/models/api.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import {
    ISingleGoalApiInput, TGoal, TTGoalCreate, TTGoalPatch,
} from '@console/core-api/models/portfolioMgmt/goal.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { DEFAULT_GOAL_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import {
    flashErrorApiEntityCreate,
    flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate,
    flashSuccessApiEntityPatch,
} from '../entitiesFlashDispatcher';
import { getEntitiesManager } from '../entitiesManager';
import { triggerResetGoalsFetch } from './goals';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const goalDetailsEntity = getEntitiesManager().registerEntity<TGoal>({
    asyncEntityKey: AsyncEntityKeys.goalDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.GOAL_DETAILS_DATA],
    includeUpdaters: true,
});

export const getGoalTitle: TTitleLabelSelector = () => {
    const goalDetails = goalDetailsEntity.select();

    if (hasFetchSucceeded(goalDetails)) {
        return {
            text: getDefaultTranslationFromApiLabel(
                goalDetails.data.name,
            ),
            shouldTranslate: false,
        };
    }

    return 'portfolio_mgmt.goals.detail.title';
};

export function triggerFetchGoalDetails(apiInput: ISingleGoalApiInput) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.goalId, entityType: CoreApiEntityType.goals })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TGoal, ISingleGoalApiInput>({
        detailsEntity: goalDetailsEntity,
        entityType: CoreApiEntityType.goals,
        api: api.goals.fetchGoalDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () => goalDetailsEntity.select().data.id !== apiInput.goalId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchGoalDetails(goal: TTGoalPatch) {
    return goalDetailsEntity.async.update<TTGoalPatch, TGoal>({
        api: api.goals.patchGoal,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                goalDetailsEntity.select().data,
                goal,
            ),
            id: goal.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetGoalsFetch();
            apiCacheManager.clearAllGoals();
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreateGoal(goal: TTGoalCreate) {
    return goalDetailsEntity.async.create<TTGoalCreate, TGoal>({
        api: api.goals.createGoal,
        apiInputSelector: () => goal,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetGoalsFetch();
            apiCacheManager.clearAllGoals();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_GOAL_DETAIL,
                params: {
                    goalId: apiResult.id,
                    goalTab: DEFAULT_GOAL_TAB_KEY,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeleteGoal(goalIdentifier?: IBaseSingleEntityApiInput) {
    return apiEntityAsyncDeleteAction<TGoal>({
        detailsEntity: goalDetailsEntity,
        api: api.goals.deleteGoal,
        apiInputSelector: () => goalIdentifier || {
            id: goalDetailsEntity.select().data.id,
        },
        onPreSuccess: () => {
            triggerResetGoalsFetch();
            apiCacheManager.clearAllGoals();
            redirectTo({ routeKey: ROUTE_KEY.R_GOALS_LIST });
        },
    });
}
