import isSet from '@snipsonian/core/cjs/is/isSet';
import { stringLike } from '@typsy/schema-validation/dist/baseSchemas/stringSchema';
import { BankIdType } from '../../../models/banking.models';
import { SchemaErrorType } from '../types';
import { combineRegexesWithOR } from '../../string/regexUtils';

const BANK_ID_VALIDATION_CONFIG: { [key in BankIdType]: { validation: RegExp, error: SchemaErrorType } } = {
    [BankIdType.ABA]: {
        validation: /^\d{9}$/,
        error: SchemaErrorType.ABABankId,
    },
    /**
     * The BIC is an 8 character code, defined as ‘business party identifier’, consisting of
     * - the business party prefix (4 alphanumeric)
     * - the country code as defined in ISO 3166-1 (2 alphabetic)
     * - and the business party suffix (2 alphanumeric)
     * - The branch identifier is a 3 character optional element that can supplement the 8 character BIC,
     *   used to identify specific locations, departments, services or units of the same business party
     */
    [BankIdType.BIC]: {
        validation: /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
        error: SchemaErrorType.BICBankId,
    },
};

const ANY_BANK_ID_TYPE_REGEX = combineRegexesWithOR(Object.values(BANK_ID_VALIDATION_CONFIG)
    .map((config) => config.validation));

export const bankId = (bankIdType?: BankIdType) => {
    if (isSet(bankIdType)) {
        const { validation, error } = BANK_ID_VALIDATION_CONFIG[bankIdType];
        return stringLike().format(validation, '', error);
    }

    return stringLike().format(ANY_BANK_ID_TYPE_REGEX, '', SchemaErrorType.BankId);
};
