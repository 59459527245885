import { observe } from 'views/observe';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { makeStyles, mixins } from 'views/styling';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { getClientConfig, areTenantSettingsSet } from 'state/appConfig/selectors';

const useStyles = makeStyles((theme) => ({
    TenantBanner: {
        ...mixins.flex({ alignCross: 'center', wrap: 'nowrap' }),

        '& img': {
            paddingRight: theme.spacing(2),
        },
    },
}));

function TenantBanner() {
    const classes = useStyles();

    if (!areTenantSettingsSet()) {
        return <div />;
    }

    const client = getClientConfig();

    return (
        <div className={classes.TenantBanner}>
            <img src={client.logoUrl} alt={client.name} height={32} />
            <Typography variant="h6">
                {client.name}
            </Typography>
        </div>
    );
}

export default observe(
    [StateChangeNotification.APP_CONFIG_TENANT],
    TenantBanner,
);
