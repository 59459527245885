import { IOutputKey } from '@console/bff/models/storyteller/storymanager.models';

export function getOutputKeyNameCompact(outputKey: IOutputKey): string {
    const name = outputKey?.name;
    if (!name) {
        return '';
    }
    const { length } = name;
    const parts = name.split('.');
    if (length > 50 && parts.length > 3) {
        return `${parts[0]} [...] ${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }
    return name;
}
