import React from 'react';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { isPolicyOfTypeModelPortfolio } from '@console/bff/utils/policies/policyTypeUtils';
import { PolicyAlgorithmType } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import {
    IPolicySettingsFormValues,
} from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';
import Text from 'views/common/widget/Text';
import PolicyComposition from '../../PolicyComposition/PolicyComposition';

export function renderPolicyCompositionSectionFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>,
    algorithm: PolicyAlgorithmType,
) {
    const compositionFormField = isPolicyOfTypeModelPortfolio(algorithm)
        ? context.fields.modelPortfolioComposition
        : context.fields.metaPortfolioComposition;

    return (
        <>
            <Text
                label="portfolio_mgmt.policies.detail.configuration.settings.sections.composition.info"
            />
            <PolicyComposition
                compositionFormField={compositionFormField}
                setCompositionFormFieldValue={context.setFieldValue}
                policyAlgorithmType={algorithm}
                currency={context.fields.baseCurrency.value as SupportedCurrency}
                isReadOnly={context.readOnlyForm}
            />
        </>
    );
}
