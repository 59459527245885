import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';

export const ALWAYS_ON_LOCALES = ['en_US', 'en_GB'];

export const SIDE_NAV_OUTPUT_KEY_HEIGHT = 40;

export const OUTPUT_KEY_ID_LENGTH = 8;

export const CONDITION_OPERATORS: IInputSelectItem<string>[] = [{
    label: '==',
    value: 'EQUALS',
}, {
    label: '!=',
    value: 'DIFFERENT_THAN',
}, {
    label: '<',
    value: 'LESS_THAN',
}, {
    label: '<=',
    value: 'LESS_THAN_OR_EQUAL',
}, {
    label: '>',
    value: 'GREATER_THAN',
}, {
    label: '>=',
    value: 'GREATER_THAN_OR_EQUAL',
}];
