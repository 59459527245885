import isSet from '@snipsonian/core/cjs/is/isSet';
import isString from '@snipsonian/core/cjs/is/isString';
import isSetString from '@snipsonian/core/cjs/string/isSetString';

type TGetValueIfSetInput = number | string;

export function getValueIfSet<Input = TGetValueIfSetInput>(input: Input): Input {
    if (isString(input)) {
        if (isSetString(input)) {
            return input;
        }
    } else if (isSet(input)) {
        return input;
    }

    return null;
}
