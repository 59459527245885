import React, { useState } from 'react';
import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'ramda/src/pick';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isArray from '@snipsonian/core/cjs/is/isArray';
import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import isFunction from '@snipsonian/core/cjs/is/isFunction';
import isSetObject from '@snipsonian/core/cjs/object/verification/isSetObject';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { NOOP, TObjectWithProps } from '@console/common/models/genericTypes.models';
import { TI18nLabelOrString } from 'models/general.models';
import { Z_INDEX } from 'config/styling/elevation';
import getObjectKeyVals from '@snipsonian/core/cjs/object/keyVals/getObjectKeyVals';
import {
    validateAgainstSchema,
    Schema,
    ISchemaValidationResult,
    SchemaErrorType,
} from '@console/common/utils/schema';
import updateObjectField from '@snipsonian/core/cjs/object/manipulation/updateObjectField';
import { diffObjects } from '@console/common/utils/object/diffObjects';
import useIsMounted from 'utils/react/hooks/useIsMounted';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { mapSchemaValidationErrorToI18Label } from 'utils/i18n/mapSchemaValidationErrorToI18Label';
import { makeStyles, mixins } from 'views/styling';
import InputForm, { IInputFormProps } from 'views/common/inputs/base/InputForm';
import TextButton from 'views/common/buttons/TextButton';
import RouteLeavingGuard from 'views/common/routing/RouteLeavingGuard';
import {
    ExtendedInputFormName,
    getManagedExtendedInputForm,
    setManagedExtendedInputForm,
} from 'views/common/inputs/extended/extendedInputFormManager';
import ConfirmationModal from 'views/common/layout/ConfirmationModal';
import { IAskConfirmationConfig } from 'views/common/buttons/types';
import { IFormStatus, IFormValues, TFormFieldValue } from './types';

export type { IFormValues, TFormFieldValue } from './types';

export const extendedInputFormClassName = 'ExtendedInputForm';

export interface IExtendedInputFormProps<Values extends IFormValues>
    extends Omit<IInputFormProps, 'onSubmit' | 'children'>, IStyleProps {
    labelPrefix?: string;
    readOnly?: boolean; // default false
    initialValues: Values;
    // use this option if you want to pre-fill the form with some values while keeping the indication of change
    currentValues?: Values;
    /* to make sure that for some fields, the initial value is taken instead of the current value */
    preferTheseInitialValuesOverCurrentValues?: (keyof Values)[];
    renderFormFields?: (renderFormFieldsProps: IExtendedInputFormContext<Values>) => React.ReactNode;
    /* a hook to know if any of the field values has changed */
    onAtLeastOneFieldValueChanged?: (props: IOnAtLeastOneFieldValueChangedProps<Values>) => void;
    /* ability to change additional fields based on incoming field changes (e.g. derived fields) */
    extraFieldChangesOnSetField?: (props: IExtraFieldChangesOnSetFieldProps<Values>) => ISingleFieldValueToSet[];
    children?: React.ReactNode; // optional for if the 'render' function(s) are not sufficient
    schema: Schema | IDynamicSchema<Values>;
    onlyShowErrorsWhenFormDirty?: boolean; // default false
    /* true --> initial errors are shown in black <vs> false --> initial errors are already shown in red */
    onlyEmphasizeErrorsWhenFieldTouchedOrFormSubmitted?: boolean; // default true
    // a reset button will be added when this is specified. It will reset to the initialValues.
    reset?: {
        actionLabel?: TI18nLabelOrString; // default label can be overruled
        buttonId?: string; // default id of the button can be overruled
        onReset?: () => void;
        /* if true, the current values (if provided) will be used for the new state */
        useCurrentValuesIfProvided?: boolean; // default true
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        triggerOnChangeDependencies?: any[]; // When any of the dependencies change, trigger a reset
    };
    // a cancel button will be added when this is specified.
    cancel?: {
        actionLabel?: TI18nLabelOrString; // default label can be overruled
        buttonId?: string; // default id of the button can be overruled
        onCancel: () => void;
    };
    back?: {
        actionLabel?: TI18nLabelOrString; // default label can be overruled
        buttonId?: string; // default id of the button can be overruled
        onBack: (onBackProps: IOnBackProps<Values>) => void;
    };
    // a submit button will be visible unless you specifically do not provide it
    submit: null | {
        shouldBeAllowedWithNoChanges?: boolean; // default false
        additionalConditionToDisable?: (onSubmitProps: IOnSubmitProps<Values>) => boolean; // default false
        confirmationModal?: IAskConfirmationConfig;
        actionLabel?: TI18nLabelOrString; // default label can be overruled
        buttonId?: string; // default id of the button can be overruled
        onSubmit: (onSubmitProps: IOnSubmitProps<Values>) => Promise<unknown>;
    };
    placeFormActionsInFixedFooter?: boolean; // default false
    checkUnsavedChangesOnRouteChange?: boolean; // default true
    /**
     * See extendedInputFormManager
     * Set to true if you need to know the state of the form (e.g. isDirty) in a totally other component.
     */
    keepTheManagedFormStateInSync?: boolean; // default false
}

interface IDynamicSchema<Values extends IFormValues> {
    getSchema: (input: { values: Values }) => Schema;
}

interface IStyleProps {
    maxWidthPixels?: number; // default no max-width
}

export interface IOnSubmitProps<Values extends IFormValues> {
    values: Values;
    getOnlyChangedValues: () => Values;
}

export interface IOnBackProps<Values extends IFormValues> {
    values: Values;
}

export interface IOnAtLeastOneFieldValueChangedProps<Values extends IFormValues> {
    values: Values;
    isValid: boolean;
}

export interface IExtraFieldChangesOnSetFieldProps<Values extends IFormValues> {
    fieldsToSet: ISingleFieldValueToSet[];
    currentValues: Values;
}

export const ExtendedInputFormContext = React.createContext<IExtendedInputFormContext>({
    formName: null,
    labelPrefix: null,
    readOnlyForm: false,
    isDirty: false,
    isValid: false,
    isDiff: false,
    isSubmitting: false,
    isSubmitted: false,
    isConfirmationModalOpen: false,
    initialValues: {},
    fields: {},
    setFieldValue: NOOP,
    overallValidationError: null,
    reInitForm: NOOP,
});

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IExtendedInputFormContext<Values extends IFormValues = {}> extends IFormStatus {
    formName: string;
    labelPrefix: string;
    readOnlyForm: boolean;
    initialValues: Values;
    fields: TExtendedFormFields<Values>;
    setFieldValue: TSetFieldValue;
    /* the error -if any- of the top-level schema object */
    overallValidationError: null | IOverallValidationError;
    /**
     * 'reInitForm' will:
     * - re-initialize the isDirty/isSubmitting/isDiff flags
     *
     * Can e.g. be used after an update was successful and the form page remains active.
     */
    reInitForm: () => void;
}

/* This is a type (instead of an interface) as mapped types can't be used in interfaces
   (otherwise error: "a computed property name must be of type 'string', ...") */
export type TExtendedFormFields<Values extends IFormValues> = {
    [fieldName in keyof Values]: IExtendedFormField;
};

export type TSetFieldValue = (...fieldsToSet: ISingleFieldValueToSet[]) => void;

export interface IOverallValidationError {
    errorType: SchemaErrorType;
    error: TI18nLabelOrString;
}

export interface ISingleFieldValueToSet {
    fieldName: string;
    value: TFormFieldValue;
    resetChildFields?: boolean;
}

export interface IExtendedFormField<Value extends TFormFieldValue = TFormFieldValue> {
    fieldId: string;
    fieldName: string;
    value: Value;
    touched: boolean;
    error: TI18nLabelOrString;
    emphasizeError: boolean;
    isDiff: boolean;
}

interface IComponentFormState<Values extends IFormValues> extends IFormStatus {
    values: Values;
    fields: TExtendedFormFields<Values>;
    overallValidationError: IOverallValidationError;
}

const useStyles = makeStyles((theme) => ({
    ExtendedInputForm: {
        ...mixins.widthMax(),

        maxWidth: ({ maxWidthPixels }: IStyleProps) => {
            if (isSet(maxWidthPixels)) {
                return maxWidthPixels;
            }

            return 'unset';
        },

        '& .extended-form-actions': {
            ...mixins.flexRow({ alignMain: 'space-between' }),
            paddingTop: theme.spacing(2),

            '& .extended-form-back': {
                marginLeft: 0,
            },
            '& .extended-form-submit': {
                marginRight: 0,
            },
        },
        '& .extended-form-actions.form-actions-in-fixed-footer': {
            position: 'fixed',
            bottom: 8,
            right: 8,
            zIndex: Z_INDEX.DETAIL_PAGE_FOOTER_FORM_ACTIONS,

            '& .BaseButton-filled': {
                height: 32,
            },
        },
    },
}));

export default function ExtendedInputForm<Values extends IFormValues>({
    name,
    className,
    labelPrefix,
    readOnly = false,
    currentValues,
    preferTheseInitialValuesOverCurrentValues,
    initialValues,
    renderFormFields,
    onAtLeastOneFieldValueChanged,
    extraFieldChangesOnSetField,
    maxWidthPixels,
    schema: schemaConfig,
    onlyShowErrorsWhenFormDirty = false,
    onlyEmphasizeErrorsWhenFieldTouchedOrFormSubmitted = true,
    children,
    back,
    reset,
    cancel,
    placeFormActionsInFixedFooter = false,
    checkUnsavedChangesOnRouteChange = true,
    submit,
    keepTheManagedFormStateInSync = false,
    ...otherProps
}: IExtendedInputFormProps<Values>) {
    let isInitDone = false;
    const classes = useStyles({ maxWidthPixels });
    const [formState, setFormState] = useState<IComponentFormState<Values>>(getInitialFormState);

    const isMounted = useIsMounted();

    useExecuteOnMount({
        execute: () => {
            setManagedExtendedInputForm<Values>(name as ExtendedInputFormName, {
                reset: onResetExtendedForm,
                formState: keepTheManagedFormStateInSync && formState,
            });
        },
        cleanupOnUnmount: () => {
            setManagedExtendedInputForm(name as ExtendedInputFormName, null);
        },
    });

    React.useEffect(
        () => {
            if (keepTheManagedFormStateInSync) {
                getManagedExtendedInputForm(name as ExtendedInputFormName).formState = formState;
            }
        },
        [formState, name, keepTheManagedFormStateInSync],
    );

    React.useEffect(
        () => {
            if (reset?.triggerOnChangeDependencies?.length > 0) {
                onResetExtendedForm();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...(reset?.triggerOnChangeDependencies || [])],
    );

    const extendedFormContext: IExtendedInputFormContext<Values> = {
        formName: name,
        labelPrefix,
        readOnlyForm: readOnly,
        initialValues,
        ...formState,
        setFieldValue,
        reInitForm,
    };

    /**
     * Important:
     * The form content (by 'renderFormFields' and/or 'children') is rendered OUTSIDE the actual form (InputForm)
     * so that we won't have a form-within-a-form when said form content would also contain a form (e.g. for filtering).
     */
    return (
        <ExtendedInputFormContext.Provider
            value={extendedFormContext}
        >
            {submit?.confirmationModal && (
                <ConfirmationModal
                    open={extendedFormContext.isConfirmationModalOpen}
                    onClose={closeConfirmationModal}
                    onConfirm={submitExtendedForm}
                    zIndex={Z_INDEX.CONFIRM_SUBMIT_EXTENDED_FORM}
                    id={`${name}_confirmation_modal`}
                    {...submit.confirmationModal}
                />
            )}

            {checkUnsavedChangesOnRouteChange && (
                <RouteLeavingGuard
                    enabled={extendedFormContext.isDiff && !extendedFormContext.isSubmitting}
                    message="common.confirmation.leave_route_even_when_form_changes.message"
                />
            )}

            <div
                id={`${name}_wrapper`}
                className={clsx(classes.ExtendedInputForm, extendedInputFormClassName, className)}
            >
                {renderFormFields && (
                    <div className="extended-form-fields">
                        {renderFormFields(extendedFormContext)}
                    </div>
                )}

                {children}

                <InputForm
                    name={name}
                    onSubmit={triggerSubmitExtendedForm}
                    {...otherProps}
                >
                    {!readOnly && (
                        <div
                            className={clsx(
                                'extended-form-actions',
                                placeFormActionsInFixedFooter && 'form-actions-in-fixed-footer',
                            )}
                        >
                            <div>
                                {back && (
                                    <TextButton
                                        id={back.buttonId || `${name}_back`}
                                        className="extended-form-back"
                                        label={back.actionLabel || 'common.action.back'}
                                        variant="outlined"
                                        noMargin={false}
                                        onClick={() => back.onBack({ values: formState.values })}
                                    />
                                )}
                            </div>
                            <div>
                                {cancel && (
                                    <TextButton
                                        id={cancel.buttonId || `${name}_cancel`}
                                        className="extended-form-cancel"
                                        label={cancel.actionLabel || 'common.action.cancel'}
                                        variant="bare"
                                        noMargin={false}
                                        color="primary"
                                        onClick={cancel.onCancel}
                                    />
                                )}

                                {reset && (
                                    <TextButton
                                        id={reset.buttonId || `${name}_reset`}
                                        className="extended-form-reset"
                                        label={reset.actionLabel || 'common.action.reset'}
                                        variant="bare"
                                        noMargin={false}
                                        color="primary"
                                        onClick={onResetExtendedForm}
                                        disabled={!extendedFormContext.isDirty && !extendedFormContext.isDiff}
                                    />
                                )}

                                {submit && (
                                    <TextButton
                                        id={submit.buttonId || `${name}_submit`}
                                        className="extended-form-submit"
                                        label={submit.actionLabel || 'common.action.save'}
                                        variant="filled"
                                        color="primary"
                                        isSubmit
                                        disabled={!extendedFormContext.isValid
                                            || (!submit?.shouldBeAllowedWithNoChanges && !extendedFormContext.isDiff)
                                            || extendedFormContext.isSubmitting
                                            || (submit?.additionalConditionToDisable
                                                && submit.additionalConditionToDisable({
                                                    values: formState.values,
                                                    getOnlyChangedValues,
                                                }))}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </InputForm>
            </div>
        </ExtendedInputFormContext.Provider>
    );

    function validateFieldValues(values: Values) {
        const schema = isDynamicSchemaTypeGuard(schemaConfig)
            ? schemaConfig.getSchema({
                values,
            })
            : schemaConfig;

        return validateAgainstSchema({
            input: values,
            schema,
        });
    }

    /**
     * fieldName can be a 'nested' field name, e.g. "expectedAssetsClasses[0].name"
     * so that's why we use updateObjectField (which uses "eval") to set the value in the nested
     * 'values' object (vs. the 'fields' object which is just a flat map of fields)
     *
     * Updating a nest field value will actually also update the values in the parent-field-chain (because
     * the parent fields reference the same javascript object)
     *
     * You only have to pass 'resetChildFields=true' when field being updated is an array, so that the
     * child fields will be reset (otherwise e.g. the array indexes would not be correct anymore
     * when an item of the array was removed or added)
     */
    function setFieldValue(...fieldsToSet: ISingleFieldValueToSet[]) {
        const extraFieldsToSet = extraFieldChangesOnSetField ?
            extraFieldChangesOnSetField({
                fieldsToSet,
                currentValues: formState.values,
            }) : [];

        const allFieldValuesToSet = [...fieldsToSet, ...extraFieldsToSet];

        const values = updateFormValues(allFieldValuesToSet);

        const validationResult = validateFieldValues(values);

        const fields = updateFormFields(
            allFieldValuesToSet,
            validationResult,
        );

        setFormState({
            ...formState,
            isDirty: true,
            isValid: validationResult.isValid,
            isDiff: isDiff(values, initialValues),
            values,
            fields: clearAndSetFieldErrors(
                fields,
                validationResult,
            ),
            overallValidationError: determineOverallValidationError({ validationResult }),
        });

        if (onAtLeastOneFieldValueChanged) {
            onAtLeastOneFieldValueChanged({
                values,
                isValid: validationResult.isValid,
            });
        }
    }

    function updateFormValues(fieldsToSet: ISingleFieldValueToSet[]): Values {
        const values = {
            ...formState.values,
        };

        fieldsToSet.forEach(({ fieldName, value }) => {
            updateObjectField({
                obj: values,
                fieldToUpdateRef: fieldName as string,
                val: value,
            });
        });

        return values;
    }

    function updateFormFields(
        fieldsToSet: ISingleFieldValueToSet[],
        validationResult: ISchemaValidationResult,
    ): TExtendedFormFields<Values> {
        const fields: TExtendedFormFields<Values> = {
            ...formState.fields,
        };

        fieldsToSet.forEach(({ fieldName, value, resetChildFields }) => {
            fields[fieldName as keyof Values] = {
                ...fields[fieldName],
                isDiff: doesFieldValueDiffer({ value, fieldName }),
                touched: true,
                value,
                emphasizeError: determineIfAnErrorShouldBeEmphasizedOnField(true),
            };

            if (resetChildFields) {
                replaceChildFields(
                    fields,
                    fieldName as string,
                    validationResult,
                );
            }
        });

        return fields;
    }

    function isDiff(values1: Values, values2: Values): boolean {
        return diffObjects(values1, values2).areDiffsDetected;
    }

    function closeConfirmationModal() {
        setFormState({
            ...formState,
            isConfirmationModalOpen: false,
        });
    }

    function triggerSubmitExtendedForm() {
        if (formState.isValid && (!submit?.shouldBeAllowedWithNoChanges ? formState.isDiff : true)) {
            if (submit?.confirmationModal) {
                setFormState({
                    ...formState,
                    isConfirmationModalOpen: true,
                });
            } else {
                submitExtendedForm();
            }
        }
    }

    function submitExtendedForm() {
        setFormState({
            ...formState,
            isSubmitting: true,
            isSubmitted: true,
        });

        submit.onSubmit({
            values: formState.values,
            getOnlyChangedValues,
        })
            .then(() => reInitForm())
            .catch(() => reInitFormAfterSubmitFailure());
    }

    function getOnlyChangedValues(): Values {
        return Object.entries(formState.values).reduce(
            (accumulator, [fieldName, fieldValue]) => {
                if (formState.fields[fieldName].isDiff) {
                    // eslint-disable-next-line no-param-reassign
                    accumulator[fieldName] = fieldValue;
                }

                return accumulator;
            },
            {} as TAnyObject,
        ) as Values;
    }

    function onResetExtendedForm() {
        const resettedFormState = getInitialFormState({ isForReset: true });

        setFormState(resettedFormState);

        if (onAtLeastOneFieldValueChanged) {
            onAtLeastOneFieldValueChanged({
                values: resettedFormState.values,
                isValid: resettedFormState.isValid,
            });
        }

        if (reset.onReset) {
            reset.onReset();
        }
    }

    function getInitialFormState({ isForReset = false }: { isForReset?: boolean } = {}): IComponentFormState<Values> {
        const { useCurrentValuesIfProvided = true } = reset || {};
        const areCurrentValuesToBeUsed = isSet(currentValues) && (!isForReset || useCurrentValuesIfProvided);
        const valuesToBeUsed = areCurrentValuesToBeUsed
            ? {
                /* override the initial ones by the current ones (that way we are sure we have all fields
                   in case the current ones don't have a specific field) */
                ...initialValues,
                ...currentValues,
                /* but for these (if provided) always take the initial ones */
                ...(preferTheseInitialValuesOverCurrentValues
                    ? pick(preferTheseInitialValuesOverCurrentValues, initialValues)
                    : {}),
            }
            : initialValues;
        const values = {} as Values;

        const validationResult = onlyShowErrorsWhenFormDirty
            ? null /* initial form, so form not dirty yet, so no errors to be shown */
            : validateFieldValues(valuesToBeUsed);

        const fields = getObjectKeyVals<TFormFieldValue>(
            cloneDeep(valuesToBeUsed),
        )
            .reduce(
                (accumulator, { key, value }) => {
                    const fieldId = `${name}_${key}`;
                    const fieldName = key;

                    accumulator[fieldName as keyof Values] = {
                        fieldId,
                        fieldName,
                        value,
                        isDiff: areCurrentValuesToBeUsed
                            ? doesFieldValueDiffer({ value: currentValues[key], fieldName: key })
                            : false,
                        touched: false,
                        error: determineFormFieldError({
                            validationResult,
                            fieldName,
                            isErrorToBeShown: true,
                        }),
                        emphasizeError: determineIfAnErrorShouldBeEmphasizedOnField(false),
                    };

                    addNestedPropertiesToFields({
                        fields: accumulator,
                        parentFieldId: fieldId,
                        parentFieldName: fieldName,
                        parentValue: value,
                        isParentFieldTouched: false,
                        validationResult,
                    });

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    values[key] = value;

                    return accumulator;
                },
                {} as TExtendedFormFields<Values>,
            );

        isInitDone = true;

        return {
            isDirty: false,
            isValid: validationResult ? validationResult.isValid : false,
            isDiff: isDiff(values, initialValues),
            isSubmitting: false,
            isSubmitted: false,
            isConfirmationModalOpen: false,
            values,
            fields,
            overallValidationError: determineOverallValidationError({ validationResult }),
        };
    }

    function determineOverallValidationError({
        validationResult,
    }: {
        validationResult: ISchemaValidationResult;
    }): IOverallValidationError {
        if (!validationResult || validationResult.isValid) {
            return null;
        }

        /* validation errors on the top-level schema object, are to be found in the field with an empty path */
        return validationResult.errors['']
            ? {
                errorType: validationResult.errors[''].type,
                error: mapSchemaValidationErrorToI18Label(validationResult.errors['']),
            }
            : null;
    }

    function addNestedPropertiesToFields({
        fields,
        parentFieldId,
        parentFieldName,
        parentValue,
        isParentFieldTouched,
        validationResult,
    }: {
        fields: TExtendedFormFields<Values>;
        parentFieldId: string;
        parentFieldName: string;
        parentValue: TFormFieldValue;
        isParentFieldTouched: boolean;
        validationResult: ISchemaValidationResult;
    }) {
        if (isObjectPure(parentValue) || isArray(parentValue)) {
            getObjectKeyVals<TFormFieldValue>(parentValue as TObjectWithProps<TFormFieldValue>)
                .forEach(({ key, value }) => {
                    const fieldId = `${parentFieldId}_${key}`;

                    /**
                     * Construct the fieldName based on the parent field name and the current one.
                     * This will produce the same fieldName as also yup will generate for the schema
                     * validation errors.
                     * Example:
                     *   someArray: ['abc', 'def']  >>  someArray[0] and someArray[1]
                     *
                     *   someObj: { attr: 'ghi' }  >>  someObj.attr
                     *
                     *   someArrayWithObj: [{ attr: 'abc'}]  >>  someArray[0] (+ someArray[0].attr in the nested call)
                     */
                    const fieldName = isArray(parentValue)
                        ? `${parentFieldName}[${key}]`
                        : `${parentFieldName}.${key}`;

                    // eslint-disable-next-line no-param-reassign
                    fields[fieldName as keyof Values] = {
                        fieldId,
                        fieldName,
                        value,
                        isDiff: false,
                        touched: isParentFieldTouched,
                        error: determineFormFieldError({
                            validationResult,
                            fieldName,
                            isErrorToBeShown: true,
                        }),
                        emphasizeError: determineIfAnErrorShouldBeEmphasizedOnField(isParentFieldTouched),
                    };

                    addNestedPropertiesToFields({
                        fields,
                        parentFieldId: fieldId,
                        parentFieldName: fieldName,
                        parentValue: value,
                        isParentFieldTouched,
                        validationResult,
                    });
                });
        }
    }

    function replaceChildFields(
        fields: TExtendedFormFields<Values>,
        parentFieldName: string,
        validationResult: ISchemaValidationResult,
    ) {
        /* first remove all the child fields */
        Object.keys(fields).forEach((fieldName) => {
            if ((fieldName !== parentFieldName) && fieldName.startsWith(parentFieldName)) {
                // eslint-disable-next-line no-param-reassign
                delete fields[fieldName];
            }
        });

        const parentField = fields[parentFieldName];

        /* re-add the child fields based on the new value */
        addNestedPropertiesToFields({
            fields,
            parentFieldId: parentField.fieldId,
            parentFieldName,
            parentValue: parentField.value,
            isParentFieldTouched: true,
            validationResult,
        });

        return fields;
    }

    function clearAndSetFieldErrors(
        fields: TExtendedFormFields<Values>,
        validationResult: ISchemaValidationResult,
    ): TExtendedFormFields<Values> {
        const areErrorsToBeShown = !onlyShowErrorsWhenFormDirty || formState.isDirty;

        Object.values(fields).forEach((extendedFormField) => {
            // eslint-disable-next-line no-param-reassign
            extendedFormField.error = determineFormFieldError({
                validationResult,
                fieldName: extendedFormField.fieldName,
                isErrorToBeShown: areErrorsToBeShown,
            });
        });

        return fields;
    }

    function determineFormFieldError({
        validationResult,
        fieldName,
        isErrorToBeShown,
    }: {
        validationResult: ISchemaValidationResult;
        fieldName: string;
        isErrorToBeShown: boolean;
    }) {
        const fieldError = validationResult
            ? validationResult.errors[fieldName]
            : null;
        return (fieldError && isErrorToBeShown)
            ? mapSchemaValidationErrorToI18Label(fieldError)
            : null;
    }

    /**
     * Determines - if there would be an error on the field - if that error would be emphasized (in red) or not.
     */
    function determineIfAnErrorShouldBeEmphasizedOnField(isFieldTouched: boolean) {
        if (isInitDone && formState && formState.isSubmitted) {
            return true;
        }

        if (isFieldTouched) {
            return true;
        }

        return !onlyEmphasizeErrorsWhenFieldTouchedOrFormSubmitted;
    }

    function reInitForm() {
        if (isMounted.current) {
            setFormState({
                ...formState,
                isDirty: false,
                isSubmitting: false,
                isDiff: currentValues ? isDiff(formState.values, initialValues) : false,
                isConfirmationModalOpen: false,
            });
        }
    }

    function reInitFormAfterSubmitFailure() {
        if (isMounted.current) {
            setFormState({
                ...formState,
                isSubmitting: false,
            });
        }
    }

    function isDynamicSchemaTypeGuard(input: IDynamicSchema<Values> | unknown): input is IDynamicSchema<Values> {
        return isObjectPure(input)
            && isFunction((input as unknown as IDynamicSchema<Values>).getSchema);
    }

    function doesFieldValueDiffer({
        fieldName,
        value,
    }: {
        fieldName: string;
        value: TFormFieldValue;
    }) {
        if (isSetObject(value) && isSetObject(initialValues[fieldName])) {
            return diffObjects(value as TAnyObject, initialValues[fieldName] as TAnyObject).areDiffsDetected;
        }
        return (value !== initialValues[fieldName]);
    }
}
