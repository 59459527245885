import isString from '@snipsonian/core/cjs/is/isString';
import isSetString from '@snipsonian/core/cjs/string/isSetString';

export function toContainsMatch(queryValue: string | number) {
    if (!isSetString(queryValue)) {
        return undefined;
    }

    const regExpContent = isString(queryValue)
        ? escapeSpecialRegularExpressionChars(queryValue)
        : queryValue;

    return `{${regExpContent}}`;
}

export function toExactMatch(queryValue: string | number) {
    if (!isSetString(queryValue)) {
        return undefined;
    }

    return queryValue;
}

export function escapeSpecialRegularExpressionChars(expression: string) {
    return expression.replace(/[+*?]/, '\\$&');
}
