import { mergeObjectPropsDeeplyOptionable } from '@snipsonian/core/cjs/merge/mergeObjectPropsDeeply';
import { IPolicyBenchmarkConstraints } from '@console/core-api/models/portfolioMgmt/policy.models';
import { RANGE_FROM_0_TO_1, RANGE_FROM_MIN1_TO_1 } from '@console/core-api/config/range.config';
import {
    IEnhancedPolicyAlgorithmSettings,
    TEnhancedPolicy,
    TPatchPolicyApiBody,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import {
    isPolicyOfTypeMetaPortfolio,
    isPolicyOfTypeModelPortfolio,
} from '@console/bff/utils/policies/policyTypeUtils';
import {
    initPolicyForCreate, optimizeEnhancedPolicyAlgorithmSettings,
    shouldBenchmarkConstraintsBeNullBasedOnSelectedBenchmark,
} from 'state/entities/portfolioMgmt/policyDetailsFactory';
import { getPoliciesConfig } from 'state/appConfig/selectors';
import {
    determineBuyUniverse,
    determineExcludedBuyInstrumentsMap,
} from 'utils/entities/portfolioMgmt/policyUniverseUtils';
import {
    IPolicySettingsFormValues, TAddPolicyFormValues,
} from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';

export function mapPolicyToInitialValuesForSettingsPatch(policy: TEnhancedPolicy): IPolicySettingsFormValues {
    const coercedAlgoSettings = policy.coerced_config.algorithm_settings;
    const algoSettings = policy.config.algorithm_settings;
    const coercedBenchmarkConstraints =
        coercedAlgoSettings.benchmark_constraints || getDefaultPolicyBenchmarkConstraints();
    const coercedMomentum =
        coercedAlgoSettings.portfolio_constraints.momentum || getPoliciesConfig().settingsBoundaries.momentum.default;

    return {
        /* ===== Details ===== */
        name: policy.name,
        externalId: policy.external_id,
        riskProfileId: policy.risk_profile_id,
        tags: policy.tags,

        /*
            We don't show this field in the details field,
            however we use the value for conditional composition schema validation
        */
        algorithm: policy.config.algorithm,

        /* ===== Universe/composition ===== */
        investmentUniverse: coercedAlgoSettings.portfolio_constraints.investment_universe,
        excludedBuyInstrumentsMap: determineExcludedBuyInstrumentsMap({
            investment_universe: coercedAlgoSettings.portfolio_constraints.investment_universe,
            buy_universe: coercedAlgoSettings.portfolio_constraints.buy_universe,
        }),

        /*
            We don't use the coercedAlgoSettings here, because there are no hierarchy rules for these fields,
            and the model and meta portfolio settings are enhanced by the BFF in only the config (not coerced_config).
        */
        modelPortfolioComposition: algoSettings.model_portfolio_settings?.model_portfolio,
        metaPortfolioComposition: algoSettings.meta_portfolio_settings?.meta_portfolio,

        /* ===== Execution constraints ===== */
        baseCurrency: coercedAlgoSettings.execution_settings.base_currency,
        minimumTransactionAmount: coercedAlgoSettings.execution_settings.transaction_amount[0],
        maximumTransactionAmount: coercedAlgoSettings.execution_settings.transaction_amount[1],
        transactionCostAmount: coercedAlgoSettings.execution_settings.cost_per_transaction_amount,
        transactionCostFraction: coercedAlgoSettings.execution_settings.cost_per_transaction_fraction,
        combineTransactionCostMode: coercedAlgoSettings.execution_settings.combine_transaction_cost_components,
        fractionalShares: coercedAlgoSettings.execution_settings.fractional_shares,

        /* ===== Portfolio constraints ===== */
        minMomentum: coercedMomentum[0],
        maxMomentum: coercedMomentum[1],
        minPositionAmount: coercedAlgoSettings.portfolio_constraints.holdings.position_amount[0],
        maxPositionAmount: coercedAlgoSettings.portfolio_constraints.holdings.position_amount[1],
        positionFraction: coercedAlgoSettings.portfolio_constraints.holdings.position_fraction,
        minCashAmount: coercedAlgoSettings.portfolio_constraints.holdings.cash_amount[0],
        maxCashAmount: coercedAlgoSettings.portfolio_constraints.holdings.cash_amount[1],
        cashFraction: coercedAlgoSettings.portfolio_constraints.holdings.cash_fraction,

        /* ===== Portfolio update constraints ===== */
        minPortfolioOptimalityImprovement:
            coercedAlgoSettings.portfolio_update_constraints.min_portfolio_update_optimality_improvement_fraction,
        maxPortfolioConstraintsViolation:
            coercedAlgoSettings.portfolio_update_constraints.max_portfolio_constraint_violation_pctpoints,
        minMaxCashPercentage: coercedAlgoSettings.portfolio_update_constraints.holdings.cash_fraction,
        minPortfolioValue: coercedAlgoSettings.portfolio_update_constraints.min_portfolio_value,

        /* eslint-disable max-len */
        /* ===== Benchmark ===== */
        benchmarkId: coercedAlgoSettings.execution_settings.benchmark_id,
        benchmarkPositionFraction: coercedBenchmarkConstraints.holdings?.position_fraction,

        benchmarkAssetClassAlternatives: coercedBenchmarkConstraints?.asset_classes?.alternatives || null,
        benchmarkAssetClassBonds: coercedBenchmarkConstraints?.asset_classes?.bonds || null,
        benchmarkAssetClassCash: coercedBenchmarkConstraints?.asset_classes?.cash || null,
        benchmarkAssetClassCommodities: coercedBenchmarkConstraints?.asset_classes?.commodities || null,
        benchmarkAssetClassStocks: coercedBenchmarkConstraints?.asset_classes?.stocks || null,
        benchmarkAssetClassCorporate: coercedBenchmarkConstraints?.asset_classes?.corporate || null,
        benchmarkAssetClassEquity: coercedBenchmarkConstraints?.asset_classes?.equity || null,
        benchmarkAssetClassEurobond: coercedBenchmarkConstraints?.asset_classes?.eurobond || null,
        benchmarkAssetClassFixedIncome: coercedBenchmarkConstraints?.asset_classes?.fixed_income || null,
        benchmarkAssetClassGovernment: coercedBenchmarkConstraints?.asset_classes?.government || null,
        benchmarkAssetClassMoneyMarket: coercedBenchmarkConstraints?.asset_classes?.money_market || null,
        benchmarkAssetClassRealEstate: coercedBenchmarkConstraints?.asset_classes?.real_estate || null,
        benchmarkAssetClassSukuk: coercedBenchmarkConstraints?.asset_classes?.sukuk || null,
        benchmarkAssetClassOther: coercedBenchmarkConstraints?.asset_classes?.other || null,

        benchmarkRegionBondsAsia: coercedBenchmarkConstraints?.regions?.bonds?.asia_pacific_developed || RANGE_FROM_MIN1_TO_1,
        benchmarkRegionBondsEmerging: coercedBenchmarkConstraints?.regions?.bonds?.emerging || RANGE_FROM_MIN1_TO_1,
        benchmarkRegionBondsEurope: coercedBenchmarkConstraints?.regions?.bonds?.europe_developed || RANGE_FROM_MIN1_TO_1,
        benchmarkRegionBondsAmerica: coercedBenchmarkConstraints?.regions?.bonds?.north_america || RANGE_FROM_MIN1_TO_1,

        benchmarkRegionStocksAsia: coercedBenchmarkConstraints?.regions?.stocks?.asia_pacific_developed || RANGE_FROM_MIN1_TO_1,
        benchmarkRegionStocksEmerging: coercedBenchmarkConstraints?.regions?.stocks?.emerging || RANGE_FROM_MIN1_TO_1,
        benchmarkRegionStocksEurope: coercedBenchmarkConstraints?.regions?.stocks?.europe_developed || RANGE_FROM_MIN1_TO_1,
        benchmarkRegionStocksAmerica: coercedBenchmarkConstraints?.regions?.stocks?.north_america || RANGE_FROM_MIN1_TO_1,

        benchmarkBondTypeConvertible: coercedBenchmarkConstraints?.bond_types?.convertible || RANGE_FROM_MIN1_TO_1,
        benchmarkBondTypeCorporate: coercedBenchmarkConstraints?.bond_types?.corporate || RANGE_FROM_MIN1_TO_1,
        benchmarkBondTypeGovernment: coercedBenchmarkConstraints?.bond_types?.government || RANGE_FROM_MIN1_TO_1,

        benchmarkSectorBasicMaterials: coercedBenchmarkConstraints?.sectors?.basic_materials || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorCommunicationServices: coercedBenchmarkConstraints?.sectors?.communication_services || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorConsumerCyclical: coercedBenchmarkConstraints?.sectors?.consumer_cyclical || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorConsumerDefensive: coercedBenchmarkConstraints?.sectors?.consumer_defensive || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorEnergy: coercedBenchmarkConstraints?.sectors?.energy || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorFinancialServices: coercedBenchmarkConstraints?.sectors?.financial_services || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorHealthcare: coercedBenchmarkConstraints?.sectors?.healthcare || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorIndustrials: coercedBenchmarkConstraints?.sectors?.industrials || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorRealEstate: coercedBenchmarkConstraints?.sectors?.real_estate || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorTechnology: coercedBenchmarkConstraints?.sectors?.technology || RANGE_FROM_MIN1_TO_1,
        benchmarkSectorUtilities: coercedBenchmarkConstraints?.sectors?.utilities || RANGE_FROM_MIN1_TO_1,
        relativeIvarWeight: coercedBenchmarkConstraints?.relative_ivar_weight,

        /* ===== Allocation boundaries ===== */
        assetClassAlternatives: coercedAlgoSettings.portfolio_constraints?.asset_classes?.alternatives || null,
        assetClassBonds: coercedAlgoSettings.portfolio_constraints?.asset_classes?.bonds || null,
        assetClassCash: coercedAlgoSettings.portfolio_constraints?.asset_classes?.cash || null,
        assetClassCommodities: coercedAlgoSettings.portfolio_constraints?.asset_classes?.commodities || null,
        assetClassStocks: coercedAlgoSettings.portfolio_constraints?.asset_classes?.stocks || null,
        assetClassCorporate: coercedAlgoSettings.portfolio_constraints?.asset_classes?.corporate || null,
        assetClassEquity: coercedAlgoSettings.portfolio_constraints?.asset_classes?.equity || null,
        assetClassEurobond: coercedAlgoSettings.portfolio_constraints?.asset_classes?.eurobond || null,
        assetClassFixedIncome: coercedAlgoSettings.portfolio_constraints?.asset_classes?.fixed_income || null,
        assetClassGovernment: coercedAlgoSettings.portfolio_constraints?.asset_classes?.government || null,
        assetClassMoneyMarket: coercedAlgoSettings.portfolio_constraints?.asset_classes?.money_market || null,
        assetClassRealEstate: coercedAlgoSettings.portfolio_constraints?.asset_classes?.real_estate || null,
        assetClassSukuk: coercedAlgoSettings.portfolio_constraints?.asset_classes?.sukuk || null,
        assetClassOther: coercedAlgoSettings.portfolio_constraints?.asset_classes?.other || null,

        regionBondsAsia: coercedAlgoSettings.portfolio_constraints?.regions?.bonds?.asia_pacific_developed || RANGE_FROM_0_TO_1,
        regionBondsEmerging: coercedAlgoSettings.portfolio_constraints?.regions?.bonds?.emerging || RANGE_FROM_0_TO_1,
        regionBondsEurope: coercedAlgoSettings.portfolio_constraints?.regions?.bonds?.europe_developed || RANGE_FROM_0_TO_1,
        regionBondsAmerica: coercedAlgoSettings.portfolio_constraints?.regions?.bonds?.north_america || RANGE_FROM_0_TO_1,

        regionStocksAsia: coercedAlgoSettings.portfolio_constraints?.regions?.stocks?.asia_pacific_developed || RANGE_FROM_0_TO_1,
        regionStocksEmerging: coercedAlgoSettings.portfolio_constraints?.regions?.stocks?.emerging || RANGE_FROM_0_TO_1,
        regionStocksEurope: coercedAlgoSettings.portfolio_constraints?.regions?.stocks?.europe_developed || RANGE_FROM_0_TO_1,
        regionStocksAmerica: coercedAlgoSettings.portfolio_constraints?.regions?.stocks?.north_america || RANGE_FROM_0_TO_1,

        bondTypeCorporate: coercedAlgoSettings.portfolio_constraints?.bond_types?.corporate || RANGE_FROM_0_TO_1,
        bondTypeConvertible: coercedAlgoSettings.portfolio_constraints?.bond_types?.convertible || RANGE_FROM_0_TO_1,
        bondTypeGovernment: coercedAlgoSettings.portfolio_constraints?.bond_types?.government || RANGE_FROM_0_TO_1,

        sectorBasicMaterials: coercedAlgoSettings.portfolio_constraints?.sectors?.basic_materials || RANGE_FROM_0_TO_1,
        sectorCommunicationServices: coercedAlgoSettings.portfolio_constraints?.sectors?.communication_services || RANGE_FROM_0_TO_1,
        sectorConsumerCyclical: coercedAlgoSettings.portfolio_constraints?.sectors?.consumer_cyclical || RANGE_FROM_0_TO_1,
        sectorConsumerDefensive: coercedAlgoSettings.portfolio_constraints?.sectors?.consumer_defensive || RANGE_FROM_0_TO_1,
        sectorEnergy: coercedAlgoSettings.portfolio_constraints?.sectors?.energy || RANGE_FROM_0_TO_1,
        sectorFinancialServices: coercedAlgoSettings.portfolio_constraints?.sectors?.financial_services || RANGE_FROM_0_TO_1,
        sectorHealthcare: coercedAlgoSettings.portfolio_constraints?.sectors?.healthcare || RANGE_FROM_0_TO_1,
        sectorRealEstate: coercedAlgoSettings.portfolio_constraints?.sectors?.real_estate || RANGE_FROM_0_TO_1,
        sectorIndustrials: coercedAlgoSettings.portfolio_constraints?.sectors?.industrials || RANGE_FROM_0_TO_1,
        sectorTechnology: coercedAlgoSettings.portfolio_constraints?.sectors?.technology || RANGE_FROM_0_TO_1,
        sectorUtilities: coercedAlgoSettings.portfolio_constraints?.sectors?.utilities || RANGE_FROM_0_TO_1,
        /* eslint-enable max-len */
    };
}

export function getDefaultPolicyBenchmarkConstraints(): IPolicyBenchmarkConstraints {
    return initPolicyForCreate({
        addFormValues: {} as TAddPolicyFormValues,
        alwaysIncludeBenchmarkConstraints: true,
    }).config.algorithm_settings.benchmark_constraints;
}

export function updatePolicyObjectWithPolicySettingsValues({
    currentPolicy,
    values,
}: {
    currentPolicy: TPatchPolicyApiBody;
    values: IPolicySettingsFormValues;
}) {
    /* eslint-disable no-param-reassign */

    currentPolicy.name = values.name;
    currentPolicy.external_id = values.externalId;
    currentPolicy.risk_profile_id = values.riskProfileId;
    currentPolicy.tags = values.tags;

    currentPolicy.config.algorithm_settings = optimizeEnhancedPolicyAlgorithmSettings(
        mergeObjectPropsDeeplyOptionable({
            sources: [
                currentPolicy.config.algorithm_settings,
                mapPolicySettingsFormValuesToAlgorithmSettings(values),
            ],
            options: {
                ignoreNullSourceProps: false,
                ignoreUndefinedSourceProps: false,
            },
        }) as IEnhancedPolicyAlgorithmSettings,
    );

    /* eslint-enable no-param-reassign */
}

export function mapPolicySettingsFormValuesToAlgorithmSettings(
    values: IPolicySettingsFormValues,
): IEnhancedPolicyAlgorithmSettings {
    return {
        benchmark_constraints: mapPolicySettingsFormValuesToBenchmarkConstraints(values),
        execution_settings: {
            benchmark_id: values.benchmarkId,
            base_currency: values.baseCurrency,
            combine_transaction_cost_components: values.combineTransactionCostMode,
            cost_per_transaction_amount: values.transactionCostAmount,
            cost_per_transaction_fraction: values.transactionCostFraction,
            transaction_amount: [values.minimumTransactionAmount, values.maximumTransactionAmount],
            fractional_shares: values.fractionalShares,
        },
        portfolio_update_constraints: {
            holdings: {
                cash_fraction: values.minMaxCashPercentage,
            },
            min_portfolio_value: values.minPortfolioValue,
            max_portfolio_constraint_violation_pctpoints: values.maxPortfolioConstraintsViolation,
            min_portfolio_update_optimality_improvement_fraction: values.minPortfolioOptimalityImprovement,
        },
        portfolio_constraints: {
            investment_universe: values.investmentUniverse,
            buy_universe: determineBuyUniverse({
                investmentUniverse: values.investmentUniverse,
                excludedBuyInstrumentsMap: values.excludedBuyInstrumentsMap,
            }),
            momentum: [values.minMomentum, values.maxMomentum],
            holdings: {
                cash_amount: [values.minCashAmount, values.maxCashAmount],
                cash_fraction: values.cashFraction,
                position_amount: [values.minPositionAmount, values.maxPositionAmount],
                position_fraction: values.positionFraction,
            },
            asset_classes: {
                alternatives: values.assetClassAlternatives,
                bonds: values.assetClassBonds,
                cash: values.assetClassCash,
                commodities: values.assetClassCommodities,
                stocks: values.assetClassStocks,
                corporate: values.assetClassCorporate,
                equity: values.assetClassEquity,
                eurobond: values.assetClassEurobond,
                fixed_income: values.assetClassFixedIncome,
                government: values.assetClassGovernment,
                money_market: values.assetClassMoneyMarket,
                real_estate: values.assetClassRealEstate,
                sukuk: values.assetClassSukuk,
                other: values.assetClassOther,
            },
            regions: {
                bonds: {
                    asia_pacific_developed: values.regionBondsAsia,
                    emerging: values.regionBondsEmerging,
                    north_america: values.regionBondsAmerica,
                    europe_developed: values.regionBondsEurope,
                },
                stocks: {
                    asia_pacific_developed: values.regionStocksAsia,
                    emerging: values.regionStocksEmerging,
                    north_america: values.regionStocksAmerica,
                    europe_developed: values.regionStocksEurope,
                },
            },
            bond_types: {
                convertible: values.bondTypeConvertible,
                corporate: values.bondTypeCorporate,
                government: values.bondTypeGovernment,
            },
            sectors: {
                basic_materials: values.sectorBasicMaterials,
                communication_services: values.sectorCommunicationServices,
                consumer_cyclical: values.sectorConsumerCyclical,
                consumer_defensive: values.sectorConsumerDefensive,
                energy: values.sectorEnergy,
                financial_services: values.sectorFinancialServices,
                healthcare: values.sectorHealthcare,
                industrials: values.sectorIndustrials,
                real_estate: values.sectorRealEstate,
                technology: values.sectorTechnology,
                utilities: values.sectorUtilities,
            },
        },
        meta_portfolio_settings: isPolicyOfTypeMetaPortfolio(values.algorithm)
            ? {
                meta_portfolio: values.metaPortfolioComposition,
            }
            : null,
        model_portfolio_settings: isPolicyOfTypeModelPortfolio(values.algorithm)
            ? {
                model_portfolio: values.modelPortfolioComposition,
            }
            : null,
    };
}

function mapPolicySettingsFormValuesToBenchmarkConstraints(
    values: IPolicySettingsFormValues,
): IPolicyBenchmarkConstraints {
    if (shouldBenchmarkConstraintsBeNullBasedOnSelectedBenchmark({ benchmarkId: values.benchmarkId })) {
        return null;
    }

    return {
        asset_classes: {
            alternatives: values.benchmarkAssetClassAlternatives,
            bonds: values.benchmarkAssetClassBonds,
            cash: values.benchmarkAssetClassCash,
            commodities: values.benchmarkAssetClassCommodities,
            stocks: values.benchmarkAssetClassStocks,
            corporate: values.benchmarkAssetClassCorporate,
            eurobond: values.benchmarkAssetClassEurobond,
            equity: values.benchmarkAssetClassEquity,
            government: values.benchmarkAssetClassGovernment,
            money_market: values.benchmarkAssetClassMoneyMarket,
            sukuk: values.benchmarkAssetClassSukuk,
            fixed_income: values.benchmarkAssetClassFixedIncome,
            other: values.benchmarkAssetClassOther,
            real_estate: values.benchmarkAssetClassRealEstate,
        },
        regions: {
            stocks: {
                asia_pacific_developed: values.benchmarkRegionStocksAsia,
                europe_developed: values.benchmarkRegionStocksEurope,
                north_america: values.benchmarkRegionStocksAmerica,
                emerging: values.benchmarkRegionStocksEmerging,
            },
            bonds: {
                asia_pacific_developed: values.benchmarkRegionBondsAsia,
                emerging: values.benchmarkRegionBondsEmerging,
                north_america: values.benchmarkRegionBondsAmerica,
                europe_developed: values.benchmarkRegionBondsEurope,
            },
        },
        bond_types: {
            convertible: values.benchmarkBondTypeConvertible,
            corporate: values.benchmarkBondTypeCorporate,
            government: values.benchmarkBondTypeGovernment,
        },
        sectors: {
            basic_materials: values.benchmarkSectorBasicMaterials,
            communication_services: values.benchmarkSectorCommunicationServices,
            consumer_cyclical: values.benchmarkSectorConsumerCyclical,
            consumer_defensive: values.benchmarkSectorConsumerDefensive,
            energy: values.benchmarkSectorEnergy,
            financial_services: values.benchmarkSectorFinancialServices,
            healthcare: values.benchmarkSectorHealthcare,
            industrials: values.benchmarkSectorIndustrials,
            real_estate: values.benchmarkSectorRealEstate,
            technology: values.benchmarkSectorTechnology,
            utilities: values.benchmarkSectorUtilities,
        },
        holdings: {
            position_fraction: values.benchmarkPositionFraction,
        },
        relative_ivar_weight: values.relativeIvarWeight,
    };
}
