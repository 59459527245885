import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { IS_BROWSER } from '@snipsonian/core/cjs/is/isBrowser';
import { HTTP_STATUS } from '@typsy/core/dist/http/httpStatus';
import { BaseErrorCategory } from '@typsy/errors/dist/models/errorCategory';
import { TGroupingFingerprint } from '@typsy/sentry-base/dist/reporting/reportErrorBase.models';
import {
    IShouldReportErrorConfig,
    TErrorReportingInfoBase,
    TShouldReportErrorOutcome,
    shouldReportError,
} from '@typsy/sentry-base/dist/reporting/shouldReportError';
import { BaseApiErrorCode, IBaseApiErrorBody } from '../server/error/apiBaseError.models';

export interface IApiErrorReportingInfo extends TErrorReportingInfoBase {
    method: string;
    url: string;
    body: IBaseApiErrorBody;
    custom?: TAnyObject;
}

type TShouldReportApiErrorConfig = IShouldReportErrorConfig<IApiErrorReportingInfo, TShouldReportApiErrorOutcome>;

export type TShouldReportApiErrorOutcome = TShouldReportErrorOutcome | 'breadcrumb';

const BREADCRUMB_OUTCOME: TShouldReportApiErrorOutcome = IS_BROWSER
    ? 'breadcrumb' /* when in console-web */
    : false; /* when in NodeJs server */

/**
 * when ... ==> the api error will be ...
 * - 'error' ==> reported with the default error level (which will be also reported to slack)
 * - 'info' ==> reported with the info level (in sentry with a lower level, but not in slack)
 * - 'breadcrumb' ==> if console-web, reported as a breadcrumb instead (so will not be reported as a separate issue)
 *                    otherwise not reported !!
 * - false ==> not reported to sentry
 *
 * If a http status is not configured, by default it will be reported as 'error' !!!
 */
export const SHOULD_REPORT_API_ERROR_CONFIG: TShouldReportApiErrorConfig = {
    0: BREADCRUMB_OUTCOME, // Premature close
    [HTTP_STATUS.REQUEST_TIMEOUT_NO_RESPONSE]: BREADCRUMB_OUTCOME, // -1
    [HTTP_STATUS.REQUEST_TIMEOUT]: BREADCRUMB_OUTCOME, // 408
    [HTTP_STATUS.BAD_REQUEST]: ({ body }) => { // 400
        if (body.category === BaseErrorCategory.SchemaValidation) {
            return false;
        }

        if (body.code === BaseApiErrorCode.ENTITY_NEEDS_UNIQUE_EXTERNAL_ID
            || body.code === BaseApiErrorCode.USER_NEEDS_UNIQUE_EMAIL
            || body.code === BaseApiErrorCode.USER_NEEDS_UNIQUE_PHONE) {
            return false;
        }

        return 'error';
    },
    [HTTP_STATUS.UNAUTHORIZED]: false, // 401
    [HTTP_STATUS.FORBIDDEN]: BREADCRUMB_OUTCOME, // 403
    [HTTP_STATUS.NOT_FOUND]: ({ body }) => { // 404
        if (body.code === BaseApiErrorCode.ENTITY_NOT_FOUND
            || body.code === BaseApiErrorCode.OPTIMIZATION_MISSING
            || body.code === BaseApiErrorCode.NOT_FOUND
        ) {
            return false;
        }

        return 'info';
    },
    [HTTP_STATUS.CONFLICT]: BREADCRUMB_OUTCOME, // 409
    [HTTP_STATUS.INTERNAL_SERVER_ERROR]: BREADCRUMB_OUTCOME, // 500
    [HTTP_STATUS.NOT_IMPLEMENTED]: ({ body }) => { // 501
        if (body.message === 'Laser item not available' || body.code === BaseApiErrorCode.RESOURCES_NOT_READY) {
            return false;
        }

        return 'error';
    },
    [HTTP_STATUS.SERVICE_UNAVAILABLE]: BREADCRUMB_OUTCOME, // 503
    [HTTP_STATUS.GATEWAY_TIMEOUT]: BREADCRUMB_OUTCOME, // 504
};

/**
 * We don't want to log everything to sentry
 * - because of rate limit
 * - and because having to triage 'expected' errors is time we don't want to spend
 */
export function shouldReportApiError(apiErrorReportingInfo: IApiErrorReportingInfo): TShouldReportApiErrorOutcome {
    return shouldReportError<IApiErrorReportingInfo, TShouldReportApiErrorOutcome>({
        errorReportingInfo: apiErrorReportingInfo,
        shouldReportErrorConfig: SHOULD_REPORT_API_ERROR_CONFIG,
    });
}

export function determineApiErrorGroupingFingerprint(
    apiErrorReportingInfo: IApiErrorReportingInfo,
): TGroupingFingerprint {
    const apiUrlWithoutHostAndIds = (apiErrorReportingInfo.url || '')
        .replace(/:\/\/[^/]*\//, '://{host}/')
        .replace(/\/[A-Z][0-9A-Z]+\//g, '/{id}/');

    return [
        apiUrlWithoutHostAndIds,
        apiErrorReportingInfo.method,
        apiErrorReportingInfo.status ? apiErrorReportingInfo.status.toString() : '-',
        apiErrorReportingInfo?.body?.message || '-',
    ];
}
