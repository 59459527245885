import {
    IDepositOrWithdrawPortfolioCashApiInput,
    TEnhancedPortfolio,
} from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { api } from 'api';
import { successFlashDispatcher, errorFlashDispatcher } from 'state/flashDispatcher';
import {
    portfolioDetailsEntity,
    triggerPortfolioOptimizationFetchIfCanBeOptimized,
} from 'state/entities/portfolioMgmt/portfolioDetails';

/**
 * As the response of the cash endpoint returns the updated portfolio detail,
 * this trigger works with the portfolio details entity.
 */
export function triggerDepositPortfolioCash(input: IDepositOrWithdrawPortfolioCashApiInput) {
    return portfolioDetailsEntity.async.update<IDepositOrWithdrawPortfolioCashApiInput, TEnhancedPortfolio>({
        api: api.bff.portfolios.depositPortfolioCash,
        apiInputSelector: () => input,
        updateDataOnSuccess: true,
        onPreSuccess: ({ apiResult }) => {
            triggerPortfolioOptimizationFetchIfCanBeOptimized({
                portfolioId: input.portfolioId,
                portfolioManagerType: apiResult.config.manager,
                portfolioStatus: apiResult.enhancedStatus.portfolio,
                forceRefresh: false,
            });
        },
        onSuccess: successFlashDispatcher('portfolio_mgmt.portfolios.cash_deposit.flash.succeeded'),
        onError: errorFlashDispatcher('portfolio_mgmt.portfolios.cash_deposit.flash.failed'),
    });
}
