import React from 'react';
import clsx from 'clsx';
import { TApiEntity } from '@console/core-api/models/api.models';
import {
    IPublicInstrumentGroupDetailsEntityData,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import { canUserModifyPublicInstrumentGroup } from 'state/auth/apiEntityAuthorization.selectors';
import {
    publicInstrumentGroupDetailsEntity,
    triggerPatchPublicInstrumentGroupDetails,
} from 'state/entities/thematicSearch/publicInstrumentGroupDetails';
import { ensureMultiTranslationsLabelHasAllLocales } from 'state/appConfig/selectors';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { getPublicInstrumentGroupDetailsSchema } from './publicInstrumentGroupDetailsSchema';
import {
    IPublicInstrumentGroupFormValues,
    renderPublicInstrumentGroupPropertiesFormFields,
} from './PublicInstrumentGroupPropertiesForm';

const LABEL_PREFIX = 'thematic_search.public_instrument_groups.detail';

export function PublicInstrumentGroupPropertiesTab() {
    const publicInstrumentGroupData = publicInstrumentGroupDetailsEntity.select().data;
    const isReadOnly = !canUserModifyPublicInstrumentGroup(publicInstrumentGroupData);

    return (
        <div className={clsx(readOnlyEntityClass(isReadOnly))}>
            <PropertiesCategoryBox<IPublicInstrumentGroupFormValues>
                id="public_instrument_group_details_fields"
                category="details"
                labelPrefix={LABEL_PREFIX}
                isReadOnly={isReadOnly}
                fields={getDetailsFields(publicInstrumentGroupData)}
                editGlobalForm={getEditDetailsForm(publicInstrumentGroupData)}
                maxWidth={800}
                centralizeContentBox
            />
        </div>
    );

    function getDetailsFields(instrumentGroup: TApiEntity<IPublicInstrumentGroupDetailsEntityData>): IDetailField[] {
        return [{
            label: 'name.label',
            value: instrumentGroup.name,
        }, {
            label: 'category.label',
            value: instrumentGroup.category,
        }, {
            label: 'description.label',
            value: instrumentGroup.description,
        }, {
            label: 'external_id.label',
            value: instrumentGroup.external_id,
        }, {
            label: 'image_uri.label',
            value: instrumentGroup.image_uri,
        }];
    }

    function getEditDetailsForm(
        instrumentGroup: TApiEntity<IPublicInstrumentGroupDetailsEntityData>,
    ): IEditForm<IPublicInstrumentGroupFormValues> {
        return {
            initialValues: {
                name: ensureMultiTranslationsLabelHasAllLocales(instrumentGroup.name),
                externalId: instrumentGroup.external_id,
                category: ensureMultiTranslationsLabelHasAllLocales(instrumentGroup.category),
                description: ensureMultiTranslationsLabelHasAllLocales(instrumentGroup.description),
                imgUri: instrumentGroup.image_uri,
            },
            schema: getPublicInstrumentGroupDetailsSchema(),
            renderFormFields: renderPublicInstrumentGroupPropertiesFormFields,
            submit: {
                onSubmit: ({ values }) => triggerPatchPublicInstrumentGroupDetails((currentInstrumentGroup) => {
                    /* eslint-disable no-param-reassign */
                    currentInstrumentGroup.name = values.name;
                    currentInstrumentGroup.category = values.category;
                    currentInstrumentGroup.description = values.description;
                    currentInstrumentGroup.external_id = values.externalId;
                    currentInstrumentGroup.image_uri = values.imgUri;
                    /* eslint-enable no-param-reassign */
                }),
            },
            modalMaxWidth: 'sm',
            modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
            labelPrefix: LABEL_PREFIX,
        };
    }
}
