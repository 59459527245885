import React from 'react';
import { RANGE_FROM_0_TO_1 } from '@console/core-api/config/range.config';
import {
    IPolicySettingRuleBoundedNumberRangeField,
    IPolicySettingsRules,
} from 'models/ui/policySettings.ui.models';
import { DEFAULT_NR_OF_DECIMALS_FOR_NUMBER_VALUE, DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE } from 'config/ui.config';
import { SIZES } from 'config/styling/sizes';
import { isCannotDifferRule } from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputSlider, { IExtendedInputSliderProps } from 'views/common/inputs/extended/ExtendedInputSlider';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import { IPolicySettingsFormValues } from './types';

export function renderPolicyPortfolioMinOptimalityImprovementFormContent(
    {
        fields,
        // eslint-disable-next-line max-len
    }: Pick<IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'minPortfolioOptimalityImprovement'>>, 'fields'>,
    {
        smallerSize,
        policySettingsRules,
    }: {
        smallerSize: boolean;
        policySettingsRules: IPolicySettingsRules;
    },
) {
    const isFieldReadOnly = isCannotDifferRule(
        policySettingsRules.portfolioUpdateConstraints.minPortfolioOptimalityImprovementFraction.rule,
    );

    return (
        <ExtendedInputNumber
            formField={fields.minPortfolioOptimalityImprovement}
            readOnly={isFieldReadOnly}
            shouldAllowNumbersBelow0={false}
            nrOfDecimals={DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE}
            type="percentage"
            wrapper={{
                label: 'fields.min_portfolio_update_optimality_improvement_fraction.label',
                tooltip: 'fields.min_portfolio_update_optimality_improvement_fraction.tooltip',
                minHeight: smallerSize ? SIZES.INPUT.HEIGHT.S : SIZES.INPUT.HEIGHT.M,
            }}
        />
    );
}

export function renderPolicyPortfolioMaxConstraintsViolationFormContent(
    {
        fields,
        // eslint-disable-next-line max-len
    }: Pick<IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'maxPortfolioConstraintsViolation'>>, 'fields'>,
    {
        smallerSize,
        policySettingsRules,
    }: {
        smallerSize: boolean;
        policySettingsRules: IPolicySettingsRules;
    },
) {
    const isFieldReadOnly = isCannotDifferRule(
        policySettingsRules.portfolioUpdateConstraints.maxPortfolioConstraintsViolationFraction.rule,
    );

    return (
        <ExtendedInputNumber
            formField={fields.maxPortfolioConstraintsViolation}
            readOnly={isFieldReadOnly}
            shouldAllowNumbersBelow0={false}
            nrOfDecimals={DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE}
            type="percentage"
            wrapper={{
                label: 'fields.max_portfolio_constraint_violation_pctpoints.label',
                tooltip: 'fields.max_portfolio_constraint_violation_pctpoints.label',
                minHeight: smallerSize ? SIZES.INPUT.HEIGHT.S : SIZES.INPUT.HEIGHT.M,
            }}
        />
    );
}

export function renderPolicyPortfolioMinPortfolioValueFormContent(
    {
        fields,
        // eslint-disable-next-line max-len
    }: Pick<IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'minPortfolioValue' | 'baseCurrency'>>, 'fields'>,
    {
        smallerSize,
        policySettingsRules,
    }: {
        smallerSize: boolean;
        policySettingsRules: IPolicySettingsRules;
    },
) {
    const isFieldReadOnly = isCannotDifferRule(
        policySettingsRules.portfolioUpdateConstraints.minPortfolioValue.rule,
        // eslint-disable-next-line max-len
    ) && fields.minPortfolioValue.value === policySettingsRules.portfolioUpdateConstraints.minPortfolioValue.parentValue;

    return (
        <ExtendedInputNumber
            formField={fields.minPortfolioValue}
            readOnly={isFieldReadOnly}
            shouldAllowNumbersBelow0={false}
            nrOfDecimals={DEFAULT_NR_OF_DECIMALS_FOR_NUMBER_VALUE}
            inputProps={{
                adornment: fields.baseCurrency.value as string,
            }}
            wrapper={{
                label: 'fields.min_portfolio_value.label',
                tooltip: 'fields.min_portfolio_value.tooltip',
                minHeight: smallerSize ? SIZES.INPUT.HEIGHT.S : SIZES.INPUT.HEIGHT.M,
            }}
        />
    );
}

export function renderPolicyPortfolioUpdateConstraintsSectionFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>,
    initialValues: IPolicySettingsFormValues,
    policySettingsRules: IPolicySettingsRules,
) {
    const { fields } = context;
    const portfolioUpdateConstraintsRules = policySettingsRules.portfolioUpdateConstraints;

    return (
        <>
            {renderPolicyPortfolioMinOptimalityImprovementFormContent({ fields }, {
                smallerSize: true,
                policySettingsRules,
            })}
            {renderPolicyPortfolioMaxConstraintsViolationFormContent({ fields }, {
                smallerSize: true,
                policySettingsRules,
            })}
            <PortfolioUpdateConstraintSlider
                formField={fields.minMaxCashPercentage}
                initialValue={initialValues.minMaxCashPercentage}
                settingRule={portfolioUpdateConstraintsRules.cashFractionRange}
                wrapper={{
                    label: 'fields.min_max_cash_percentage.label',
                    tooltip: 'fields.min_max_cash_percentage.tooltip',
                }}
            />
            {renderPolicyPortfolioMinPortfolioValueFormContent({ fields }, {
                smallerSize: true,
                policySettingsRules,
            })}
        </>
    );
}

function PortfolioUpdateConstraintSlider({
    settingRule,
    ...otherProps
}: Pick<IExtendedInputSliderProps, 'formField' | 'initialValue' | 'wrapper'> & {
    settingRule: IPolicySettingRuleBoundedNumberRangeField;
}) {
    return (
        <ExtendedInputSlider
            {...otherProps}
            size="S"
            readOnly={isCannotDifferRule(settingRule.rule)}
            range={settingRule.range}
            absoluteRange={RANGE_FROM_0_TO_1}
        />
    );
}
