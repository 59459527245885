import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IPeriodFilter, PeriodType } from '@console/common/models/period.models';
import { ConsoleBff } from '@console/bff';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { api } from 'api';
import {
    enrichReportResponseWithFetchDate,
    isEnrichedReportOutdated,
    TEnrichedReportResponse,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';

// eslint-disable-next-line max-len
export const reportInfoEntity = getEntitiesManager().registerEntity<TEnrichedReportResponse<ConsoleBff.TReportInfoBff>>({
    asyncEntityKey: AsyncEntityKeys.reportInfo,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_INFO_DATA],
    includeUpdaters: false,
});

export function selectAvailableReportPeriods(): IPeriodFilter<string>[] {
    return reportInfoEntity.select().data?.availablePeriods || [];
}

export function selectMatchingReportPeriod(periodType: PeriodType): IPeriodFilter<string> {
    const matchingPeriod = selectAvailableReportPeriods()
        .find((period) => period.type === periodType);

    return matchingPeriod || getFirstItemOfArray(selectAvailableReportPeriods());
}

export function isThereReportData() {
    return isArrayWithValues(selectAvailableReportPeriods());
}

export function triggerFetchReportInfo() {
    // eslint-disable-next-line max-len
    return reportInfoEntity.async.fetch<unknown, TEnrichedReportResponse<ConsoleBff.TReportInfoBff>, ConsoleBff.TReportInfoBff>({
        api: api.bff.mgmtReporting.fetchReportInfo,
        mapApiResponse: ({ response }) => enrichReportResponseWithFetchDate(response),
        refreshMode: () => isEnrichedReportOutdated(reportInfoEntity.select().data),
        resetDataOnTriggerMode: 'always',
    });
}
