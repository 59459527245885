import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISingleTooltipFilter } from 'models/state/ui.models';
import { createAction } from 'state';
import { getUserId } from 'state/auth/selectors';

export const setTooltipAutomaticallyShown = (payload: ISingleTooltipFilter) =>
    createAction<ISingleTooltipFilter>({
        type: 'TOOLTIP_TO_BE_SHOWN_AUTOMATICALLY',
        payload,
        process({ setStateImmutable }) {
            setStateImmutable({
                toState: (draftState) => {
                    draftState.ui.tooltips.tooltipKeyAutomaticallyShown = payload.tooltipKey;
                },
                notificationsToTrigger: [StateChangeNotification.UI_TOOLTIPS_AUTO_SHOW],
            });
        },
    });

interface IDismissTooltipPayload extends ISingleTooltipFilter {
    isNotToBeShownAgain: boolean;
}

export const dismissTooltip = (payload: IDismissTooltipPayload) =>
    createAction<IDismissTooltipPayload>({
        type: 'DISMISS_TOOLTIP',
        payload,
        process({ setStateImmutable, getState }) {
            const { tooltipKey, isNotToBeShownAgain } = payload;
            const userId = getUserId(getState());

            setStateImmutable({
                toState: (draftState) => {
                    if (isNotToBeShownAgain) {
                        if (!draftState.ui.tooltips.notAgain[userId]) {
                            draftState.ui.tooltips.notAgain[userId] = {};
                        }

                        draftState.ui.tooltips.notAgain[userId][tooltipKey] = true;
                    } else {
                        if (!draftState.ui.tooltips.closed[userId]) {
                            draftState.ui.tooltips.closed[userId] = {};
                        }

                        draftState.ui.tooltips.closed[userId][tooltipKey] = true;
                    }
                },
                notificationsToTrigger: [StateChangeNotification.UI_TOOLTIPS_DISMISSED],
            });
        },
    });
