import React from 'react';
import { TEnhancedPortfolioInList } from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { IColValues, TDataColumns } from 'models/list.models';
import { UiPageKey } from 'models/state/ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { triggerFetchPortfoliosForSelect } from 'state/entities/portfolioMgmt/portfolios';
import InputRadioField from 'views/common/inputs/base/InputRadioField';
import GenericPortfoliosList, {
    DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX,
    getDefaultPortfolioColumns,
    IDefaultPortfolioCols,
} from './GenericPortfoliosList';

interface IPublicProps {
    selectedPortfolioId: TApiEntityId;
    initialSelectedPortfolioId?: TApiEntityId;
    onPortfolioSelected: (onPortfolioSelectedProps: IOnPortfolioSelectedProps) => void;
    isPortfolioSelectable?: (portfolioId: TApiEntityId) => boolean;
}

export interface IOnPortfolioSelectedProps {
    portfolio: TEnhancedPortfolioInList;
}

interface IPortfolioSelectCols
    extends IColValues, Pick<IDefaultPortfolioCols, 'type' | 'name' | 'owner' | 'externalId'> {
    selected: boolean;
}

interface IPortfolioSelectExtraData {
    portfolioData: TEnhancedPortfolioInList;
    selectable: boolean;
    changeSelected: (portfolio: TEnhancedPortfolioInList) => void;
}

const DEFAULT_PORTFOLIO_COLS = getDefaultPortfolioColumns({
    desiredCols: [
        { colName: 'type', percentWidth: 5 },
        { colName: 'name', percentWidth: 37 },
        { colName: 'owner', percentWidth: 25 },
        { colName: 'externalId', percentWidth: 25 },
    ],
});

const COLS: TDataColumns<IPortfolioSelectCols, IPortfolioSelectExtraData> = {
    selected: {
        label: {
            msg: `${DEFAULT_PORTFOLIOS_LIST_COL_TRANSLATION_PREFIX}.selected`,
        },
        data: {
            render: ({ cellValue, item }) => (
                <InputRadioField
                    name="select-single-portfolio"
                    value={item.id}
                    selected={cellValue as boolean}
                    onSelected={() => item.extra.changeSelected(item.extra.portfolioData)}
                    disabled={!item.extra.selectable}
                />
            ),
        },
        percentWidth: 8,
    },
    type: DEFAULT_PORTFOLIO_COLS.type,
    name: DEFAULT_PORTFOLIO_COLS.name,
    externalId: DEFAULT_PORTFOLIO_COLS.externalId,
    owner: DEFAULT_PORTFOLIO_COLS.owner,
};

export default function GenericPortfolioSelect({
    selectedPortfolioId,
    initialSelectedPortfolioId,
    isPortfolioSelectable = () => true,
    onPortfolioSelected,
}: IPublicProps) {
    return (
        <GenericPortfoliosList<IPortfolioSelectCols, IPortfolioSelectExtraData>
            overrideEntity={{
                asyncListEntityFetchTrigger: triggerFetchPortfoliosForSelect,
            }}
            overrideUiVars={{
                uiVarsNotification: StateChangeNotification.PORTFOLIO_SELECT_UI_VARS,
                uiPageKey: UiPageKey.portfolioSelectList,
            }}
            overrideCols={{
                cols: COLS,
                toPortfolioListItem: ({ portfolio, defaultPortfolioCols }) => ({
                    id: portfolio.id,
                    colValues: {
                        selected: portfolio.id === selectedPortfolioId,
                        type: defaultPortfolioCols.type,
                        name: defaultPortfolioCols.name,
                        owner: defaultPortfolioCols.owner,
                        externalId: defaultPortfolioCols.externalId,
                    },
                    extra: {
                        portfolioData: portfolio,
                        selectable: canPortfolioBeSelected(portfolio.id),
                        changeSelected: changeSelectedPortfolio,
                    },
                }),
            }}
            onPortfolioRowClicked={({ extra }) => changeSelectedPortfolio(extra.portfolioData)}
            isPortfolioRowClickable={({ id }) => canPortfolioBeSelected(id)}
        />
    );

    function changeSelectedPortfolio(portfolio: TEnhancedPortfolioInList) {
        onPortfolioSelected({ portfolio });
    }

    function canPortfolioBeSelected(portfolioId: TApiEntityId) {
        return portfolioId !== initialSelectedPortfolioId && isPortfolioSelectable(portfolioId);
    }
}
