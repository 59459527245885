import { useParams } from 'react-router-dom';
import isSet from '@snipsonian/core/cjs/is/isSet';

interface IParams {
    [key: string]: string;
}

/**
 * Strips out the route params that are not set (that start with :)
 * Not sure if this is useful anymore as we always expect the route params to be explicitly set.
 */
export default function useRouteParams(): IParams {
    const params = useParams<IParams>();

    if (!params) {
        return {};
    }

    return Object.keys(params)
        .reduce(
            (accumulator, paramKey) => {
                const paramValue = params[paramKey];
                if (isSet(paramValue) && paramValue.indexOf(':') !== 0) {
                    accumulator[paramKey] = paramValue;
                }
                return accumulator;
            },
            {} as IParams,
        );
}
