import React from 'react';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { ExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import { IExtendedInputBaseProps } from 'views/common/inputs/extended/ExtendedInputText';
import InputSelectField, {
    IInputSelectFieldProps,
    IOnChangeSelectProps,
    TInputSelectValue,
} from 'views/common/inputs/base/InputSelectField';
import ExtendedInputWrapper from 'views/common/inputs/extended/ExtendedInputWrapper';

interface IExtendedInputSelectProps<Value extends TInputSelectValue>
    // eslint-disable-next-line max-len
    extends IExtendedInputBaseProps, Omit<IInputSelectFieldProps<Value>, 'id' | 'name' | 'value' | 'onChange' | 'labelPrefix' | 'error' | 'emphasizeError'> {
    shouldPrefixItemLabels?: boolean; // default true
    /** Only provide the onChange if you need to overrule the default behaviour (which is to
     * call setFieldValue with the new selected value) */
    onChange?: (props: IOnChangeSelectProps<Value>) => void;
}

export default function ExtendedInputSelect<Value extends TInputSelectValue = string>({
    readOnly = false,
    formField,
    wrapper,
    shouldPrefixItemLabels = true,
    async,
    onChange,
    ...selectFieldProps
}: IExtendedInputSelectProps<Value>) {
    const asyncError = isAsyncError();

    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, readOnlyForm, setFieldValue }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChangeDefault({ value }: IOnChangeSelectProps<Value>) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                return (
                    <ExtendedInputWrapper
                        disabled={selectFieldProps.disabled}
                        readOnly={isFieldReadOnly}
                        hasError={(!!formField.error && formField.emphasizeError) || asyncError}
                        {...wrapper}
                        className={wrapper.className}
                    >
                        <InputSelectField
                            id={formField.fieldId}
                            name={formField.fieldName}
                            disabled={isFieldReadOnly}
                            value={formField.value as Value}
                            onChange={onChange || onChangeDefault}
                            error={formField.error}
                            emphasizeError={formField.emphasizeError}
                            labelPrefix={shouldPrefixItemLabels ? labelPrefix : undefined}
                            crudStylingType={formField.isDiff ? 'edited' : null}
                            async={async}
                            {...selectFieldProps}
                        />
                    </ExtendedInputWrapper>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );

    function isAsyncError(): boolean {
        if (!async) { return false; }

        const { status: asyncStatus } = async.itemsData;

        if (asyncStatus === AsyncStatus.Error) {
            return true;
        }

        return false;
    }
}
