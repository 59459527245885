import {
    string, object, ObjectShape,
    getDynamicObjectSchema, IDynamicObjectSchemaOptions,
} from '@console/common/utils/schema';
import {
    getVariationsSchema,
    storyManagerTranslationSchema,
} from '@console/bff/server/utils/schemas/storymanagerBff.schemas';
import { CONDITION_OPERATORS } from 'config/storyTeller/storymanager.config';

export const getScenarioDetailsSchema = () => object({
    status: string().required(),
    description: string().required(),
    context: string().required(),
    example: storyManagerTranslationSchema.required(),
    conditions: getConditionsSchema({ isRequired: false }),
    variations: getVariationsSchema(),
    statuses: object(),
}).required();

function getConditionsSchema(options: IDynamicObjectSchemaOptions) {
    return getDynamicObjectSchema({
        ...options,
        mapper: (conditions) => Object.entries(conditions).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = getDynamicObjectSchema({
                    isRequired: false,
                    mapper: () => Object.entries(value).reduce(
                        (nestedAcc, [nestedKey]) => {
                            // eslint-disable-next-line no-param-reassign
                            nestedAcc[nestedKey] = object({
                                left: string().required(),
                                operator: string().oneOf(CONDITION_OPERATORS.map((item) => item.value)).required(),
                                right: string().required(),
                            });

                            return nestedAcc;
                        },
                        {} as ObjectShape,
                    ),
                });

                return accumulator;
            },
            {} as ObjectShape,
        ),
    });
}
