import React from 'react';
import { portfolioDetailsEntity, triggerPatchPortfolioDetails } from 'state/entities/portfolioMgmt/portfolioDetails';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function PortfolioAccessibleBy() {
    const portfolioDetailsData = portfolioDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={portfolioDetailsData}
            nameForDisplay={portfolioDetailsData.name}
            asyncEntityPatchTrigger={(values) => triggerPatchPortfolioDetails((currentPortfolio) => {
                /* eslint-disable no-param-reassign */
                currentPortfolio.readable_by = values.readable_by;
                currentPortfolio.modifiable_by = values.modifiable_by;
                /* eslint-enable no-param-reassign */
            })}
        />
    );
}
