import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { PortfolioManagerType, PortfolioMoneyType } from '@console/core-api/models/portfolioMgmt/portfolio.models';

export interface ICreatePortfolioReportModalData {
    entityType: TPortfolioReportEntityType; // the entity that the report is being generated for
    reportsInputData: ISingleReportInputData[];
}

export type TPortfolioReportEntityType = CoreApiEntityType.portfolios | CoreApiEntityType.portfolioGroups;

export interface ISingleReportInputData {
    entityId: string;
    entityName: string;
    entityCreationDatetime: string;

    // Must be provided only for portfolio groups
    portfolioIds?: string[];

    // Must be provided only for portfolios
    userId?: string;
    preferredLanguage?: string;
    portfolioManagerType?: PortfolioManagerType;
    portfolioMoneyType?: PortfolioMoneyType;
}

export enum ReportEntityType {
    Portfolio = 'Portfolio',
    PortfolioGroup = 'PortfolioGroup',
}
