export enum PeriodType {
    /* Standard */
    ALL_TIME = 'ALL_TIME',
    LAST_24H = 'LAST_24H',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',

    /* Management reporting */
    LAST_DAY = 'LAST_DAY',
    LAST_WEEK = 'LAST_WEEK',
    WEEK_TO_DATE = 'WEEK_TO_DATE',
    LAST_MONTH = 'LAST_MONTH',
    MONTH_TO_DATE = 'MONTH_TO_DATE',
    LAST_QUARTER = 'LAST_QUARTER',
    QUARTER_TO_DATE = 'QUARTER_TO_DATE',
    LAST_YEAR = 'LAST_YEAR',
    YEAR_TO_DATE = 'YEAR_TO_DATE',
    SINCE_START = 'SINCE_START',

    /*
        Specific period

        If you want to use custom periods in your input make sure to pass BOTH of the periods below
        to the InputPeriodField component!!!

        The CUSTOM period is there to visualise your chosen period in the dropdown once it has been selected. This
        allows the user to re-select a SPECIFIC_PERIOD without losing the value of what he selected before. Not passing
        it to the InputPeriodField component will result in some strange behaviour.
    */
    CUSTOM = 'CUSTOM',
    SPECIFIC_PERIOD = 'SPECIFIC_PERIOD',
}

export interface IDatePeriod<DateType = Date> {
    startDate: DateType;
    endDate: DateType;
}

export interface IPeriodFilter<DateType = Date> extends IDatePeriod<DateType> {
    type: PeriodType;
}

export interface IPeriodBoundaries {
    minDate?: Date;
    maxDate?: Date;
}
