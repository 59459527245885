import React from 'react';
import flatMap from 'lodash/flatMap';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { getSecondLastItemOfArray } from '@console/common/snipsonian/core/cjs/array/filtering/getSecondLastItemOfArray';
import { parseInputDate } from '@console/common/utils/date/dateUtils';
import { ConsoleBff } from '@console/bff';
import { AggregateRoboAumBy } from '@console/bff/export';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getStore } from 'state';
import {
    selectRoboAumReportAsCommonReport,
    triggerFetchRoboAumReport,
} from 'state/entities/mgmtReporting/roboAumReport';
import { selectRoboClientGrowthReport } from 'state/entities/mgmtReporting/roboClientGrowthReport';
import { selectRoboClientCashTransferReport } from 'state/entities/mgmtReporting/roboClientCashTransferReport';
import { updateRoboMgmtReportingPageVars } from 'state/ui/uiPages.actions';
import { getRoboMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { isThereReportData } from 'state/entities/mgmtReporting/reportInfo';
import {
    determineTrendDirection,
    determineValueLineItemVariant,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';
import MgmtReportingAumBox from '../blocks/MgmtReportingAumBox';
import MgmtReportingClientsBox, { toClientPeriodComparisonRowValues } from '../blocks/MgmtReportingClientsBox';
import { MgmtReportingNoData } from '../blocks/MgmtReportingNoData';
import RoboMgmtReportingFilterBlock from './RoboMgmtReportingFilterBlock';

const LABEL_PREFIX = 'mgmt_reporting';
const LABEL_PREFIX_SECTIONS = `${LABEL_PREFIX}.clients.sections`;

export default function RoboAdvisorMgmtReporting() {
    if (!isThereReportData()) {
        return <MgmtReportingNoData mode="no_data_at_all" />;
    }

    return (
        <>
            <RoboMgmtReportingFilterBlock />

            <MgmtReportingAumBox<AggregateRoboAumBy>
                chartName="robo-aum"
                description={`${LABEL_PREFIX}.aum.robo.box_description`}
                aggregation={{
                    getCurrentAggregateBy: () => getRoboMgmtReportingPageVars().aumAggregateBy,
                    labelPrefix: `${LABEL_PREFIX}.aum.robo.aggregate_by`,
                    multiple: {
                        values: Object.values(AggregateRoboAumBy),
                        notifications: [StateChangeNotification.MGMT_REPORTING_ROBO_UI_VARS],
                        onChange: onChangeAumAggregateBy,
                    },
                }}
                reportData={{
                    notifications: [StateChangeNotification.MGMT_REPORTING_ROBO_AUM_DATA],
                    asyncEntitySelector: selectRoboAumReportAsCommonReport,
                }}
            />

            {/* eslint-disable-next-line max-len */}
            <MgmtReportingClientsBox<ConsoleBff.TFetchRoboClientGrowthReportApiReply, ConsoleBff.TFetchRoboClientCashTransferReportApiReply>
                description={`${LABEL_PREFIX}.clients.robo.box_description`}
                reportData={{
                    growth: {
                        notifications: [StateChangeNotification.MGMT_REPORTING_ROBO_CLIENT_GROWTH_DATA],
                        asyncEntitySelector: selectRoboClientGrowthReport,
                        mainMetricDataSelector: ({ data }) => {
                            const clientTotal = getLastItemOfArray(data.report.time_series).total;

                            return {
                                clientTotal,
                                trendDirection: determineTrendDirection(
                                    getSecondLastItemOfArray(data.report.time_series)?.total || 0,
                                    clientTotal,
                                ),
                            };
                        },
                        periodComparisonTableMapper: ({ data }) => ({
                            rows: [{
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.inflow`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'inflow',
                                }),
                                isGrowthNumber: true,
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.outflow`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'outflow',
                                    convertToNegative: true,
                                }),
                                isGrowthNumber: true,
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.net_inflow`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'inflow',
                                    subtractFieldName: 'outflow',
                                }),
                                isGrowthNumber: true,
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.retention_rate`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'retention_rate',
                                }),
                                isGrowthNumber: true,
                                isPercentage: true,
                            }],
                        }),
                        barChartMapper: ({ data: reportData }) => ({
                            categories: [{
                                key: 'inflow',
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.inflow`,
                            }, {
                                key: 'outflow',
                                label: `${LABEL_PREFIX_SECTIONS}.growth.table.outflow`,
                                useNegativeColor: true,
                                catKeyToStackOn: 'inflow',
                            }],
                            data: reportData.report.time_series.map((item) => ({
                                x: parseInputDate(item.datetime).toDate(),
                                bars: [{
                                    catKey: 'inflow',
                                    y: item.inflow,
                                }, {
                                    catKey: 'outflow',
                                    y: -1 * item.outflow,
                                }],
                            })),
                            valueLineAggregator: ({ barGroup, barGroupIndex, nrOfBarGroups }) => flatMap(barGroup.bars)
                                .reduce(
                                    (accumulator, bar) => {
                                        // eslint-disable-next-line no-param-reassign
                                        accumulator.y += bar.y;

                                        return accumulator;
                                    },
                                    {
                                        y: 0,
                                        variant: determineValueLineItemVariant({
                                            periodType: reportData.input.periodType,
                                            barGroupIndex,
                                            nrOfBarGroups,
                                        }),
                                    },
                                ),
                        }),
                    },
                    cashTransfer: {
                        notifications: [StateChangeNotification.MGMT_REPORTING_ROBO_CLIENT_CASH_TRANSFER_DATA],
                        asyncEntitySelector: selectRoboClientCashTransferReport,
                        periodComparisonTableMapper: ({ data }) => ({
                            rows: [{
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_initial_deposits`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'making_initial_deposits',
                                }),
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_additional_deposits`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'making_additional_deposits',
                                }),
                            }, {
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_withdrawals`,
                                values: toClientPeriodComparisonRowValues({
                                    data,
                                    fieldName: 'making_withdrawals',
                                }),
                            }],
                        }),
                        barChartMapper: ({ data: reportData }) => ({
                            categories: [{
                                key: 'making_initial_deposits',
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_initial_deposits`,
                            }, {
                                key: 'making_additional_deposits',
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_additional_deposits`,
                                catKeyToStackOn: 'making_initial_deposits',
                            }, {
                                key: 'making_withdrawals',
                                label: `${LABEL_PREFIX_SECTIONS}.cash_transfer.table.making_withdrawals`,
                                useNegativeColor: true,
                            }],
                            data: reportData.report.time_series.map((item) => ({
                                x: parseInputDate(item.datetime).toDate(),
                                bars: [{
                                    catKey: 'making_initial_deposits',
                                    y: item.making_initial_deposits,
                                    // color: ,
                                }, {
                                    catKey: 'making_additional_deposits',
                                    y: item.making_additional_deposits,
                                }, {
                                    catKey: 'making_withdrawals',
                                    y: item.making_withdrawals,
                                }],
                            })),
                        }),
                    },
                }}
            />
        </>
    );

    function onChangeAumAggregateBy(newValue: AggregateRoboAumBy) {
        getStore().dispatch(
            updateRoboMgmtReportingPageVars({
                aumAggregateBy: newValue,
            }),
        );

        triggerFetchRoboAumReport({ forceRefresh: true });
    }
}
