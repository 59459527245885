import { IState } from 'models/state.models';
import {
    IOutputStatuses,
    IScenario,
    TItemsWithStoryManagerStatus,
    TStoryManagerStatus,
    IOutputKey,
    IOutputKeyWithId,
} from '@console/bff/models/storyteller/storymanager.models';
import { IStoryManagerFilters } from 'models/ui/storymanager.ui.models';
import { ALWAYS_ON_LOCALES } from 'config/storyTeller/storymanager.config';
import { mapArrayLikeObjectToArray } from '@console/common/utils/object/objectUtils';
import {
    getStoryManagerOutputKeysOrder,
    getStoryManagerLocales,
    getSelectedStoryManagerDatabaseDetail,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { getStoryManagerPageVars } from './uiPages.selectors';

// Order
export const getStoryManagerOutputKeysSortFunction = (
    a: ({ id: string; } & IOutputKey),
    b: ({ id: string; } & IOutputKey),
) => {
    const order = getStoryManagerOutputKeysOrder();
    return order.indexOf(a.id) < order.indexOf(b.id) ? -1
        : order.indexOf(a.id) > order.indexOf(b.id) ? 1 : 0;
};

// UI
export const getStoryManagerEditorMode = (state: IState) => getStoryManagerPageVars(state).editorMode || 'scenario';
export const isStoryManagerInScenarioMode = (state: IState) => getStoryManagerEditorMode(state) === 'scenario';

export const getStoryManagerSidebarWidth = (state: IState) =>
    getStoryManagerPageVars(state).sidebarWidth || null;

export const isStoryManagerOutputKeyCollapsed = (state: IState, outputKeyId: string) =>
    getStoryManagerPageVars(state).collapsedOutputKeyIds?.includes(outputKeyId);

// Filters
export function getStoryManagerAlwaysOnLocales() {
    const storyManagerLocales = getStoryManagerLocales();
    return getAlwaysOnLocalesThatAreAvailableForTheSelectedVersion();

    function getAlwaysOnLocalesThatAreAvailableForTheSelectedVersion() {
        return ALWAYS_ON_LOCALES.filter((item) => storyManagerLocales.includes(item));
    }
}

export const getStoryManagerFilters = (state: IState): IStoryManagerFilters => {
    const filters = getStoryManagerPageVars(state).filters || {} as IStoryManagerFilters;
    const storyManagerLocales = getStoryManagerLocales();
    const alwaysOnLocales = getStoryManagerAlwaysOnLocales();

    return {
        ...filters,
        locale: [
            ...alwaysOnLocales,
            ...(filters?.locale?.filter((locale) =>
                !alwaysOnLocales.includes(locale) && storyManagerLocales.includes(locale)) || []),
        ],
    };
};

export const getStoryManagerOutputKeysBasedOnActiveFilters = (state: IState) => {
    const filters = getStoryManagerFilters(state);
    const databaseData = getSelectedStoryManagerDatabaseDetail()?.config_draft;

    if (!databaseData) {
        return {};
    }

    let filteredOutputKeys = databaseData.outputKeys;

    if (filters?.outputKey) {
        filteredOutputKeys = Object.fromEntries(
            Object.entries(filteredOutputKeys)
                .filter(([, outputKey]) => outputKey.name.includes(filters.outputKey)),
        );
    }

    if (filters?.status?.length >= 1) {
        filteredOutputKeys = Object.fromEntries(
            Object.entries(filteredOutputKeys)
                .filter(([, value]) => filters.status.includes(getStoryManagerOutputKeyStatus(state, value))),
        );
    }

    return filteredOutputKeys;
};

export const getStoryManagerSortedOutputKeysAsArray = (): IOutputKeyWithId[] => {
    const databaseData = getSelectedStoryManagerDatabaseDetail()?.config_draft;

    if (!databaseData) {
        return [];
    }

    const { outputKeys } = databaseData;

    const outputKeysAsArray =
        mapArrayLikeObjectToArray(outputKeys, { addKeyAsId: true }) as IOutputKeyWithId[];

    return outputKeysAsArray.sort(getStoryManagerOutputKeysSortFunction);
};

// Status
export function getStoryManagerStatusOrder(): TStoryManagerStatus[] {
    return [
        'TO_DO',
        'IN_PROGRESS',
        'IN_REVIEW',
        'DONE',
    ];
}

export function getStoryManagerOutputKeyStatus(
    state: IState,
    outputKey: IOutputKey,
): TStoryManagerStatus {
    const filters = getStoryManagerFilters(state);
    const editorMode = getStoryManagerEditorMode(state);

    if (editorMode === 'scenario') {
        return getLeastProgressedStatusInList(mapArrayLikeObjectToArray(outputKey.scenarios));
    }

    return getLeastProgressedStatusInList(
        mapArrayLikeObjectToArray(outputKey.scenarios).reduce(
            (scenarioStatusList, scenario) => {
                let statuses = mapStoryManagerScenarioOutputStatusesToList(state, scenario.output.statuses);
                if (filters?.locale?.length >= 1) {
                    statuses = statuses.filter((item) => filters.locale.includes(item.locale));
                }
                scenarioStatusList.push({
                    status: getLeastProgressedStatusInList(statuses),
                });
                return scenarioStatusList;
            }, [] as TItemsWithStoryManagerStatus,
        ),
    );
}

export function getStoryManagerScenarioStatus(
    state: IState,
    scenario: IScenario,
): TStoryManagerStatus {
    const filters = getStoryManagerFilters(state);
    const editorMode = getStoryManagerEditorMode(state);

    if (editorMode === 'scenario') {
        return scenario.status;
    }

    let statuses = mapStoryManagerScenarioOutputStatusesToList(state, scenario.output.statuses);
    if (filters?.locale?.length >= 1) {
        statuses = statuses.filter((item) => filters.locale.includes(item.locale));
    }

    return getLeastProgressedStatusInList(statuses);
}

function getLeastProgressedStatusInList(
    listOfItemsWithStatus: TItemsWithStoryManagerStatus,
): TStoryManagerStatus {
    return listOfItemsWithStatus.reduce(
        (leastProgressedStatus: TStoryManagerStatus, cur: { status: TStoryManagerStatus; }) => {
            const order = getStoryManagerStatusOrder();
            if (order.indexOf(cur.status) < order.indexOf(leastProgressedStatus)) {
                return cur.status;
            }
            return leastProgressedStatus;
        },
        'DONE' as TStoryManagerStatus,
    );
}

function mapStoryManagerScenarioOutputStatusesToList(
    state: IState, statuses: IOutputStatuses,
): { locale: string; status: TStoryManagerStatus; }[] {
    const storyManagerLocales = getStoryManagerLocales();

    return storyManagerLocales.map((locale) => ({
        locale,
        status: statuses[locale] || 'TO_DO',
    }));
}

// Database
export function getStoryManagerOutputKeyNamesList(options: {
    exclude?: string[];
} = {}) {
    const outputKeys = getSelectedStoryManagerDatabaseDetail()?.config_draft?.outputKeys || {};
    const allNames = Object.keys(outputKeys).map((id) => outputKeys[id].name);
    if (options?.exclude) {
        return allNames.filter((item) => !options?.exclude.includes(item));
    }
    return allNames;
}

export function getStoryManagerDatabaseHasChanges() {
    const database = getSelectedStoryManagerDatabaseDetail();
    return database.config_modification_datetime !== database.config_publish_datetime;
}

export function veryifyStoryManagerDatabase(): { valid: boolean; errorMsg: string; invalidOutputKeys: string[]; } {
    const database = getSelectedStoryManagerDatabaseDetail();

    const config = database.config_draft;
    const locales: string[] = getStoryManagerLocales();

    const invalidOutputKeys: string[] = [];

    Object.keys(config.outputKeys).forEach((outputKeyId) => {
        const outputKey = database.config_draft.outputKeys[outputKeyId];
        let isKeyValid = true;

        Object.keys(outputKey.scenarios).forEach((scenarioIndex) => {
            // Key is already marked as invalid, no need to check the remaining statuses
            if (!isKeyValid) { return; }

            const scenario = outputKey.scenarios[scenarioIndex];

            if (!isStatusReadyForDeployment(scenario.status)) {
                addOutputKeyToInvalidList();
                return;
            }

            locales.forEach((locale) => {
                if (!isKeyValid) { return; }
                if (!isStatusReadyForDeployment(scenario.output.statuses[locale])) {
                    addOutputKeyToInvalidList();
                }
            });
        });

        function addOutputKeyToInvalidList() {
            if (!invalidOutputKeys.includes(outputKey.name)) {
                invalidOutputKeys.push(outputKey.name);
                isKeyValid = false;
            }
        }

        function isStatusReadyForDeployment(status: TStoryManagerStatus) {
            return status === 'DONE' || status === 'IN_REVIEW';
        }
    });

    const valid = invalidOutputKeys.length === 0;
    return {
        valid,
        errorMsg: valid ? '' : 'error.validation.story_manager.output_keys_not_done',
        invalidOutputKeys,
    };
}
