import React from 'react';
import { BankAccountType, BankIdType } from '@console/common/models/banking.models';
import { Locale } from '@console/common/config/i18n.config';
import addItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import removeItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/removeItemAndGetResultingArray';
import {
    IUserEntityData,
    UserFeatureFlag,
} from '@console/core-api/models/userMgmt/user.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { SIZES } from 'config/styling/sizes';
import {
    getUserFeatureFlagsListEnabledForTenant,
    getTenantSettings,
    getUserConfig,
    areTenantSettingsSet,
} from 'state/appConfig/selectors';
import { getUserFeatureFlagLabel } from 'utils/entities/userMgmt/userUtils';
import { getBankAccountTypeSelectItems } from 'utils/entities/entityDetailUtils';
import { getLocaleTranslationLabel } from 'utils/i18n/localeUtils';
import { getValueIfSet } from 'utils/varia/getValueIfSet';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ExtendedInputForm,
{
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputToggle from 'views/common/inputs/extended/ExtendedInputToggle';
import ExtendedInputWrapper from 'views/common/inputs/extended/ExtendedInputWrapper';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import InputCheckboxField, { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import ContentTitle from 'views/common/layout/ContentTitle';
import { observe } from 'views/observe';
import { getUserDetailsSchema } from './genericUserDetailsSchema';

export interface IUserFormValues extends IFormValues {
    externalId: string;
    firstName: string;
    lastName: string;
    phone: string;
    brokerageId: string;
    featureFlags: UserFeatureFlag[];
    language: Locale;
    bankAccountNumberType: BankAccountType;
    bankAccountNumber: string;
    bankIdType: BankIdType;
    bankId: string;
    paymentReference: string;
}

export type TUserCounterAccountFormValues = Pick<IUserFormValues,
'bankId' | 'bankIdType' | 'bankAccountNumber' | 'bankAccountNumberType' | 'paymentReference'>;

export type TUserDetailsFormValues = Pick<IUserFormValues,
'firstName' | 'lastName' | 'externalId' | 'phone' | 'brokerageId' | 'language' | 'email'>;

export type TUserFeatureFlagsFormValues = Pick<IUserFormValues, 'featureFlags'>;

export interface IUserAddFormValues
    extends Pick<IUserFormValues, 'firstName' | 'lastName' | 'externalId' |
    'phone' | 'brokerageId' | 'language' | 'email' | 'featureFlags'>, IFormValues {
    shouldUserBeLinkedToIdp: boolean;
    email: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    extends Pick<IExtendedInputFormProps<IUserAddFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
}

const bankIdTypes: IInputSelectItem<string>[] = Object.values(BankIdType)
    .map((bankIdType) => ({
        label: bankIdType,
        value: bankIdType,
    }));

const LABEL_PREFIX = 'user_mgmt.users.detail';

function GenericUserAddPropertiesForm({
    submit,
    ...formProps
}: IPublicProps) {
    return (
        <ExtendedInputForm<IUserAddFormValues>
            name={ExtendedInputFormName.userProperties}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: 'common.action.add',
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderFormFields}
            schema={getUserDetailsSchema({ isCreateMode: true })}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );

    function renderFormFields(context: IExtendedInputFormContext<IUserAddFormValues>) {
        /* the USERS_CREATE permission also allows setting the feature_flags field */
        const shouldShowFeatureFlags = true;

        const { allowUsersWithoutIdpLink } = getUserConfig();

        return (
            <>
                <ContentTitle
                    label="user_mgmt.users.detail.sub_title.main"
                    variant="section"
                />

                {allowUsersWithoutIdpLink && (
                    <ExtendedInputToggle
                        formField={context.fields.shouldUserBeLinkedToIdp}
                        wrapper={{
                            label: 'fields.shouldUserBeLinkedToIdp.label',
                        }}
                        description="fields.shouldUserBeLinkedToIdp.description"
                    />
                )}

                {context.fields.shouldUserBeLinkedToIdp.value && (
                    <ExtendedInputText<string>
                        formField={context.fields.email}
                        wrapper={{
                            label: 'fields.email.label',
                        }}
                    />
                )}

                {renderCommonUserFormFields(context)}

                {shouldShowFeatureFlags && renderUserFeatureFlagsFormFields(context)}
            </>
        );
    }
}

export function renderUserCounterAccountFormFields({
    fields,
    labelPrefix,
}: IExtendedInputFormContext<TUserCounterAccountFormValues>) {
    return (
        <>
            <ContentTitle
                label={`${labelPrefix}.bank_account_info.label`}
                variant="section"
            />
            <InputGroup childrenGrid={[4, 8]}>
                <ExtendedInputSelect
                    formField={fields.bankAccountNumberType}
                    items={getBankAccountTypeSelectItems()}
                    wrapper={{
                        label: 'fields.bank_account_number_type.label',
                        shouldPrefixTooltip: false,
                        tooltip: {
                            msg: 'common.fields.bank_account_number_type.tooltip',
                            raw: true,
                        },
                    }}
                />
                <ExtendedInputText<string>
                    formField={fields.bankAccountNumber}
                    wrapper={{
                        label: 'fields.bank_account_number.label',
                    }}
                />
            </InputGroup>
            <ContentTitle
                label={`${labelPrefix}.bank_info.label`}
                variant="section"
            />
            <InputGroup childrenGrid={[4, 8]}>
                <ExtendedInputSelect
                    formField={fields.bankIdType}
                    items={bankIdTypes}
                    addNoDataSelectItem
                    wrapper={{
                        label: 'fields.bank_id_type.label',
                        shouldPrefixTooltip: false,
                        tooltip: {
                            msg: 'common.fields.bank_id_type.tooltip',
                            raw: true,
                        },
                    }}
                />
                <ExtendedInputText<string>
                    formField={fields.bankId}
                    wrapper={{
                        label: 'fields.bank_id.label',
                    }}
                />
            </InputGroup>
            <ExtendedInputText
                formField={fields.paymentReference}
                wrapper={{
                    label: 'fields.payment_reference.label',
                }}
            />
        </>
    );
}

function renderCommonUserFormFields({ fields }: IExtendedInputFormContext<TUserDetailsFormValues>) {
    const tenantSettings = getTenantSettings();
    const allowEditingIdpSpecificFields = tenantSettings?.user?.allowPatchingIdpSpecificFields;

    return (
        <>
            <ExtendedInputText<string>
                formField={fields.externalId}
                readOnly={!allowEditingIdpSpecificFields}
                wrapper={{
                    label: 'fields.external_id.label',
                }}
            />

            <InputGroup>
                <ExtendedInputText<string>
                    formField={fields.firstName}
                    readOnly={!allowEditingIdpSpecificFields}
                    wrapper={{
                        label: 'fields.first_name.label',
                    }}
                />
                <ExtendedInputText<string>
                    formField={fields.lastName}
                    readOnly={!allowEditingIdpSpecificFields}
                    wrapper={{
                        label: 'fields.last_name.label',
                    }}
                />
            </InputGroup>

            <ExtendedInputText
                formField={fields.phone}
                readOnly={!allowEditingIdpSpecificFields}
                wrapper={{
                    label: 'fields.phone.label',
                }}
            />

            <ExtendedInputSelect
                formField={fields.language}
                items={getLanguageSelectItems()}
                itemLabelsAreTranslationKeys
                shouldPrefixItemLabels={false}
                wrapper={{
                    label: 'fields.language.label',
                }}
            />
        </>
    );

    function getLanguageSelectItems(): IInputSelectItem<string>[] {
        if (!areTenantSettingsSet()) {
            return [];
        }
        return tenantSettings.user.locales.map((item) => ({
            value: item,
            label: getLocaleTranslationLabel(item),
        }));
    }
}

export function renderUserDetailsFormFields(context: IExtendedInputFormContext<TUserDetailsFormValues>) {
    return (
        <>
            <ExtendedInputText<string>
                formField={context.fields.email}
                readOnly
                wrapper={{
                    label: 'fields.email.label',
                }}
            />

            {renderCommonUserFormFields(context)}

            <ExtendedInputText
                formField={context.fields.brokerageId}
                readOnly
                hideIfValueUnset
                wrapper={{
                    label: 'fields.brokerage_user_id.label',
                }}
            />
        </>
    );
}

export function renderUserFeatureFlagsFormFields(context: IExtendedInputFormContext<TUserFeatureFlagsFormValues>) {
    const currentFeatureFlags = context.fields.featureFlags.value as UserFeatureFlag[];

    return (
        <>
            <ExtendedInputWrapper
                label="fields.feature_flags.label"
                shouldStyleInputWrapped={false}
                tooltip={{
                    msg: 'fields.feature_flags.tooltip',
                    raw: true,
                }}
            >
                {getUserFeatureFlagsListEnabledForTenant().map((featureFlag) => (
                    <InputCheckboxField<UserFeatureFlag>
                        key={featureFlag}
                        name={featureFlag}
                        checked={currentFeatureFlags.indexOf(featureFlag) > -1}
                        description={getUserFeatureFlagLabel(featureFlag)}
                        onChange={onChangeFeatureFlagCheckbox}
                    />
                ))}
            </ExtendedInputWrapper>
        </>
    );

    function onChangeFeatureFlagCheckbox({ name: flag, checked }: IOnChangeCheckboxProps<UserFeatureFlag>) {
        const newFeatureFlags = checked
            ? addItemAndGetResultingArray<UserFeatureFlag>(currentFeatureFlags, flag, { resultInNewArray: true })
            : removeItemAndGetResultingArray<UserFeatureFlag>(currentFeatureFlags, flag, { resultInNewArray: true });

        context.setFieldValue({
            fieldName: context.fields.featureFlags.fieldName,
            value: newFeatureFlags,
        });
    }
}

export function mapUserDetailsFormValuesToApiEntityFields(
    formValues: TUserDetailsFormValues,
): Pick<IUserEntityData, 'external_id' | 'first_name'
    | 'last_name' | 'phone' | 'language' | 'brokerage_user_id'> {
    return {
        external_id: formValues.externalId,
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        phone: formValues.phone,
        language: formValues.language,
        brokerage_user_id: getValueIfSet(formValues.brokerageId),
    };
}

export function mapUserAddFormValuesToApiEntity(
    formValues: IUserAddFormValues,
): IUserEntityData {
    const optionalEmail = formValues.shouldUserBeLinkedToIdp
        ? {
            email: formValues.email,
        }
        : {};
    return {
        ...mapUserDetailsFormValuesToApiEntityFields(formValues),
        feature_flags: formValues.featureFlags,
        ...optionalEmail,
    };
}

export default observe<IPublicProps>(
    [StateChangeNotification.APP_CONFIG_TENANT],
    GenericUserAddPropertiesForm,
);
