import React, { ReactNode } from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import {
    hasAsyncOperationFailed,
    isAnyAsyncOperationBusy,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ICustomAsyncEntity } from 'models/state/entities.models';
import { IObserveProps, observe } from 'views/observe';
import CustomI18nContext from 'views/appShell/providers/CustomI18nContext';
import { makeStyles } from 'views/styling';
import SpinnerOverlay, { TSpinnerPosition } from 'views/common/loading/SpinnerOverlay';
import Alert, { TAlertSeverity } from 'views/common/widget/Alert';
import { TLabel } from 'models/general.models';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IEntityWrapperProps<EntityData = any, ExtraData = any, DataTransformResult = EntityData> {
    className?: string;
    asyncEntitySelector: (state: IState) => ICustomAsyncEntity<EntityData>;
    /**
     * To get 'data' outside of the async entity and provide it to the renderData and entityDataTransformer functions.
     */
    extraDataSelector?: TExtraDataSelector<ExtraData>;
    /**
     * If provided this will 'transform' the entityData and will present it to the 'renderData' function,
     * otherwise the 'renderData' just gets the entityData as input.
     */
    // eslint-disable-next-line max-len
    entityDataTransformer?: (transformProps: IEntityDataTransformerProps<EntityData, ExtraData>) => DataTransformResult;
    renderData?: (renderProps: IRenderDataProps<DataTransformResult, ExtraData, EntityData>) => ReactNode;
    shouldRenderIfNoDataSet?: boolean; // default false
    fetch?: IEntityOperationErrorOptions;
    create?: IEntityOperationErrorOptions;
    update?: IEntityOperationErrorOptions;
    remove?: IEntityOperationErrorOptions;
    markAsPositionRelative?: boolean; // default false
    showLoader?: boolean; // default true
    loaderPosition?: TSpinnerPosition; // default 'absolute'
    setMinHeight?: boolean; // default true
    setFullWidth?: boolean; // default false
}

interface IEntityOperationErrorOptions {
    showErrorInline?: boolean; // default true
    /* when 'customizeError' returns false, the generic error message will be shown */
    customizeError?: ({ error }: ITCustomizeEntityOperationErrorInput) => TCustomizeEntityOperationErrorOutcome;
}

export interface ITCustomizeEntityOperationErrorInput {
    error: IBaseApiErrorClientSide;
}
export type TCustomizeEntityOperationErrorOutcome = false | ICustomizeErrorAlert | ReactNode;

interface ICustomizeErrorAlert {
    label: TLabel;
    severity?: TAlertSeverity; // default 'error'
}

export type TExtraDataSelector<ExtraData> = (state: IState) => ExtraData;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IEntityDataTransformerProps<EntityData, ExtraData = any> {
    entityData: EntityData;
    extraData: ExtraData;
    state: IState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IRenderDataProps<DataTransformResult, ExtraData = any, EntityData = DataTransformResult> {
    data: DataTransformResult;
    extraData: ExtraData;
    entityData: EntityData; // the untransformed 'original' data
    isAnyAsyncOperationBusy: boolean;
    translator: TTranslator;
}

const useStyles = makeStyles((/* theme */) => ({
    EntityWrapper: {
        '&.min-height': {
            minHeight: 60,
        },

        '&.position-relative': {
            position: 'relative',
        },

        '&.set-full-width': {
            width: '100%',
        },
    },
}));

/**
 * EntityWrapper expects a selector to get an async entity.
 *
 * It will:
 * - show a loader while one of the entity operations are ongoing (e.g. while fetching)
 * - conditionally show inline error(s) when any of the operations result in an error
 *
 * Usage:
 * - use the initEntityWrapper function and pass the notifications that the entity wrapper should subscribe to.
 *   If the parent component already observes the needed notification(s), then you can pass an empty array.
 */
function EntityWrapper({
    state,
    className,
    asyncEntitySelector,
    extraDataSelector,
    entityDataTransformer,
    renderData,
    shouldRenderIfNoDataSet = false,
    fetch = {},
    create,
    update,
    remove,
    markAsPositionRelative = false,
    showLoader = true,
    loaderPosition = 'absolute',
    setMinHeight = true,
    setFullWidth = false,
}: IObserveProps & IEntityWrapperProps) {
    const classes = useStyles();

    const asyncEntity = asyncEntitySelector(state);
    const extraData = extraDataSelector ? extraDataSelector(state) : null;
    const isLoading = isAnyAsyncOperationBusy(asyncEntity);

    const fetchOptions = fetch
        ? {
            showErrorInline: true,
            ...fetch,
        }
        : null;
    const createOptions = create
        ? {
            showErrorInline: true,
            ...create,
        }
        : null;
    const updateOptions = update
        ? {
            showErrorInline: true,
            ...update,
        }
        : null;
    const removeOptions = remove
        ? {
            showErrorInline: true,
            ...remove,
        }
        : null;

    return (
        <CustomI18nContext.Consumer>
            {({ translator }) => (
                <div
                    className={clsx(
                        classes.EntityWrapper,
                        className,
                        markAsPositionRelative && 'position-relative',
                        setMinHeight && 'min-height',
                        setFullWidth && 'set-full-width',
                    )}
                >
                    {showLoader && (
                        <SpinnerOverlay
                            open={isLoading}
                            position={loaderPosition}
                            variant="white"
                        />
                    )}

                    {optionallyRenderOperationError({
                        operation: AsyncOperation.fetch,
                        options: fetchOptions,
                    })}
                    {optionallyRenderOperationError({
                        operation: AsyncOperation.create,
                        options: createOptions,
                    })}
                    {optionallyRenderOperationError({
                        operation: AsyncOperation.update,
                        options: updateOptions,
                    })}
                    {optionallyRenderOperationError({
                        operation: AsyncOperation.remove,
                        options: removeOptions,
                    })}

                    {(shouldRenderIfNoDataSet || isSet(asyncEntity.data)) && (
                        renderData({
                            data: getDataToRender(),
                            extraData,
                            entityData: asyncEntity.data,
                            isAnyAsyncOperationBusy: isLoading,
                            translator,
                        })
                    )}
                </div>
            )}
        </CustomI18nContext.Consumer>
    );

    function getDataToRender() {
        if (entityDataTransformer) {
            return entityDataTransformer({
                entityData: asyncEntity.data,
                extraData,
                state,
            });
        }

        return asyncEntity.data;
    }

    function optionallyRenderOperationError({
        operation,
        options,
    }: {
        operation: AsyncOperation;
        options: IEntityOperationErrorOptions;
    }) {
        if (options && options.showErrorInline && hasAsyncOperationFailed(asyncEntity, operation)) {
            if (options.customizeError) {
                const optionalCustomError = options.customizeError({ error: asyncEntity[operation].error });

                if (optionalCustomError) {
                    const customAlert = optionalCustomError as ICustomizeErrorAlert;

                    if (isSet(customAlert.label)) {
                        return (
                            <Alert
                                message={customAlert.label}
                                severity={customAlert.severity}
                            />
                        );
                    }

                    /* custom error component */
                    return optionalCustomError;
                }
            }

            return (
                <Alert severity="error" message={{ msg: `error.operation_failed.${operation.toLowerCase()}` }} />
            );
        }

        return null;
    }
}

export function initEntityWrapper({
    notifications,
}: { notifications: StateChangeNotification[] }) {
    return observe<IEntityWrapperProps>(
        notifications,
        EntityWrapper,
    );
}

export const EntityWrapperNoExtraObserve = initEntityWrapper({ notifications: [] });
