import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    boolean, ISIN_REGEX, number, numberRange, NumberSchema,
    object, SchemaErrorType, string,
    TestConfig, ObjectShape,
    getDynamicObjectSchema,
} from '@console/common/utils/schema';
import { roundFloat4 } from '@console/common/utils/float/roundFloat';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import {
    CoreApiEntityType,
    CORE_API_ENTITY_TYPE_TO_ID_REGEX,
} from '@console/core-api/config/coreEntities.config';
import { TEnhancedPolicyComposition } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { isNumberInRange } from 'utils/number/range';
import { formatPercentage } from 'utils/number/percentageUtils';
import {
    IPolicySettingRuleBoundedNumberRangeField, IPolicySettingRuleBoundedSingleNumberField,
    IPolicySettingRuleSingleValueField, PolicySettingNumberType,
} from 'models/ui/policySettings.ui.models';
import { isCannotDifferRule, isWithinBoundariesRule } from './policySettingsUtils';

export const policyCompositionElementSchema = object({
    name: string(),
    weight: number().positive().required(),
});

export const policyCompositionTotalWeightTest: TestConfig = {
    name: SchemaErrorType.CompositionTotalWeight,
    test: (composition) => {
        const compositionTotalWeight = Object.values(composition).reduce(
            (sum, { weight }) => {
                // eslint-disable-next-line no-param-reassign
                sum += weight;
                return sum;
            },
            0,
        );

        return roundFloat4(compositionTotalWeight) === 1;
    },
};

export const modelPortfolioCompositionElementIdsTest: TestConfig = {
    name: SchemaErrorType.ModelPortfolioComposition,
    test: (modelComposition) => Object.keys(modelComposition).every((id) =>
        id.search(ISIN_REGEX) > -1),
};

export const metaPortfolioCompositionElementIdsTest: TestConfig = {
    name: SchemaErrorType.MetaPortfolioComposition,
    test: (metaComposition) => Object.keys(metaComposition).every((id) =>
        id.search(CORE_API_ENTITY_TYPE_TO_ID_REGEX[CoreApiEntityType.policies]) > -1
        || id.search(CORE_API_ENTITY_TYPE_TO_ID_REGEX[CoreApiEntityType.portfolios]) > -1),
};

export function getPolicyCompositionSchema(compositionTypeSpecificTest: TestConfig) {
    return getDynamicObjectSchema<TEnhancedPolicyComposition>({
        mapper: (compositionInput) => Object.entries(compositionInput).reduce(
            (accumulator, [key]) => {
                accumulator[key] = policyCompositionElementSchema;
                return accumulator;
            },
            {} as ObjectShape,
        ),
        testsConfig: [policyCompositionTotalWeightTest, compositionTypeSpecificTest],
    });
}

export function stringComparedAgainstValueSchema<Value extends string = string>(
    props: Omit<IPolicySettingRuleSingleValueField<Value>, 'fieldType'>,
) {
    return string()
        .test(initConditionalTestShouldBeSameAsParent<string>(props));
}

export function booleanComparedAgainstValueSchema(
    props: Omit<IPolicySettingRuleSingleValueField<boolean>, 'fieldType'>,
) {
    return boolean()
        .test(initConditionalTestShouldBeSameAsParent<boolean>(props));
}

function numberComparedAgainstValueSchema(
    props: Omit<IPolicySettingRuleSingleValueField<number>, 'fieldType' | 'parentValue'>,
) {
    return number()
        .test(initConditionalTestShouldBeSameAsParent<number>(props));
}

export function minNumberComparedAgainstRangeSchema(
    props: IPolicySettingRuleBoundedNumberRangeField & { hasParentPolicy: boolean },
) {
    return numberComparedAgainstRangeSchema({
        ...props,
        boundaryValue: props.boundaryMin,
    });
}

export function maxNumberComparedAgainstRangeSchema({
    nameOfMinField,
    numberType,
    ...otherProps
}: IPolicySettingRuleBoundedNumberRangeField & { hasParentPolicy: boolean ; nameOfMinField: string }) {
    return numberComparedAgainstRangeSchema({
        ...otherProps,
        numberType,
        boundaryValue: otherProps.boundaryMax,
    })
        .when(nameOfMinField, ([minFieldValue]: number[], schema: NumberSchema) => (
            minFieldValue
                ? schema.test({
                    name: SchemaErrorType.MinVal,
                    test: (value: number) => {
                        if (isSet(value)) {
                            return value > minFieldValue;
                        }

                        return true;
                    },
                    params: {
                        minValue: formatNumberBasedOnType(minFieldValue, numberType),
                    },
                })
                : schema
        ));
}

export function numberComparedAgainstRangeSchema({
    rule,
    boundaryMin: minValue,
    boundaryMax: maxValue,
    boundaryValue, // the value compared against the parent (if needed)
    numberType,
    hasParentPolicy,
    isParentValueSet,
}: Omit<IPolicySettingRuleBoundedSingleNumberField, 'fieldType' | 'parentValue'> & {
    hasParentPolicy: boolean;
}) {
    return numberComparedAgainstValueSchema({
        rule,
        boundaryValue,
        isParentValueSet,
    })
        .test({
            name: hasParentPolicy
                ? SchemaErrorType.PolicyNumberWithinParentBoundary
                : SchemaErrorType.NumberWithinBoundary,
            test: (value: number) => {
                if (isWithinBoundariesRule(rule)) {
                    return isNumberInRange(value, [minValue, maxValue]);
                }

                return true;
            },
            // eslint-disable-next-line no-template-curly-in-string
            message: '${path} should be within the given boundary',
            params: {
                minValue: formatNumberBasedOnType(minValue, numberType),
                maxValue: formatNumberBasedOnType(maxValue, numberType),
            },
        });
}

export function numberRangeComparedAgainstRangeSchema({
    rule,
    boundaryMin: minValue,
    boundaryMax: maxValue,
    numberType,
    hasParentPolicy,
    isParentValueSet,
}: IPolicySettingRuleBoundedNumberRangeField & { hasParentPolicy: boolean }) {
    return numberRange({ isNullable: true })
        .test({
            name: SchemaErrorType.PolicySettingShouldBeSameAsParent,
            test: (value) => {
                if (isCannotDifferRule(rule)) {
                    if (isSet(value)) {
                        return (value[0] === minValue && value[1] === maxValue);
                    }

                    /* value not set --> only ok if parent value/range also not set */
                    return !isParentValueSet();
                }

                return true;
            },
            // eslint-disable-next-line no-template-curly-in-string
            message: '${path} should have the same value as the one in the parent policy',
            params: {
                minValue: formatNumberBasedOnType(minValue, numberType),
                maxValue: formatNumberBasedOnType(maxValue, numberType),
            },
        })
        .test({
            name: hasParentPolicy
                ? SchemaErrorType.PolicyNumberWithinParentBoundary
                : SchemaErrorType.NumberWithinBoundary,
            test: (value) => {
                if (isWithinBoundariesRule(rule)) {
                    if (isSet(value)) {
                        return (isNumberInRange(value[0], [minValue, maxValue])
                            && isNumberInRange(value[1], [minValue, maxValue]));
                    }

                    /* value not set --> only ok if parent value/range also not set */
                    return !isParentValueSet();
                }

                return true;
            },
            // eslint-disable-next-line no-template-curly-in-string
            message: '${path} should be within the given boundary',
            params: {
                minValue: formatNumberBasedOnType(minValue, numberType),
                maxValue: formatNumberBasedOnType(maxValue, numberType),
            },
        });
}

export function initConditionalTestShouldBeSameAsParent<Value>({
    rule,
    boundaryValue,
}: Pick<IPolicySettingRuleSingleValueField<Value>, 'rule' | 'boundaryValue'>) {
    return {
        name: SchemaErrorType.PolicySettingShouldBeSameAsParent,
        test: (value: Value) => {
            if (isCannotDifferRule(rule) && isSet(boundaryValue)) {
                return value === boundaryValue;
            }

            return true;
        },
        // eslint-disable-next-line no-template-curly-in-string
        message: '${path} should have the same value as the one in the parent policy',
        params: {
            parentValue: boundaryValue,
        },
    };
}

export function formatNumberBasedOnType(value: number, type: PolicySettingNumberType) {
    if (!isSet(value)) {
        return value;
    }

    if (type === PolicySettingNumberType.PERCENTAGE_TO_1) {
        return formatPercentage(value * 100);
    }

    return formatFloat(value);
}
