import { string, object, holdingsItem, array } from '@console/common/utils/schema';

export const publicInstrumentDetailsSchema = object({
    name: string().trim(),
    displayName: string().trim(),
    type: string().trim(),
    externalId: holdingsItem().required(),
    imgUri: string().url().required().trim(),
    tags: array().of(string())
        .unique({ uniqueLabelForDisplay: 'tags', ignoreCase: true }),
}).required();
