import flipObjectKeyVals from '@snipsonian/core/cjs/object/keyVals/flipObjectKeyVals';
import { OPERATION_PERMISSION_KEY } from './operationPermissionKeys';

export const ENTITY_ID_REGEX = /^[A-Z][0-9A-Z]+$/;

export const CORE_ENTITY_FIELDS_TO_STRIP_ON_PATCH = [
    'version',
    'version_datetime',
    'version_authored_by_user_id',
    'deleted',
    'creation_datetime',
    '_embedded',
];

export enum CoreApiEntityType {
    goals = 'goals',
    horizons = 'horizons',
    riskProfiles = 'riskProfiles',
    policies = 'policies',
    portfolios = 'portfolios',
    portfolioGroups = 'portfolioGroups',
    optimizations = 'optimizations',
    userGroups = 'userGroups',
    users = 'users',
    publicInstruments = 'publicInstruments',
    publicInstrumentGroups = 'publicInstrumentGroups',
}

export const CORE_API_ENTITY_TYPE_TO_ID_PREFIX: { [key in CoreApiEntityType]: string; } = {
    [CoreApiEntityType.goals]: 'L',
    [CoreApiEntityType.horizons]: 'H',
    [CoreApiEntityType.riskProfiles]: 'K',
    [CoreApiEntityType.policies]: 'Y',
    [CoreApiEntityType.portfolios]: 'P',
    [CoreApiEntityType.portfolioGroups]: 'F',
    [CoreApiEntityType.optimizations]: 'O',
    [CoreApiEntityType.userGroups]: 'G',
    [CoreApiEntityType.users]: 'U',
    [CoreApiEntityType.publicInstruments]: 'I',
    [CoreApiEntityType.publicInstrumentGroups]: 'J',
};

type TEntityType2EntityIdRegexMap = { [type in CoreApiEntityType]: RegExp };

export const CORE_API_ENTITY_TYPE_TO_ID_REGEX: TEntityType2EntityIdRegexMap = Object.values(CoreApiEntityType)
    .reduce(
        (accumulator, type) => {
            const typePrefix = CORE_API_ENTITY_TYPE_TO_ID_PREFIX[type];

            accumulator[type] = new RegExp(`^${typePrefix}[0-9A-Z]+$`);

            return accumulator;
        },
        {} as TEntityType2EntityIdRegexMap,
    );

export const CORE_API_ENTITY_ID_PREFIX_TO_TYPE: { [key: string]: CoreApiEntityType } =
    flipObjectKeyVals(CORE_API_ENTITY_TYPE_TO_ID_PREFIX);

export const CORE_API_ENTITY_TYPE_TO_MODIFY_PERMISSION_MAP: { [key in CoreApiEntityType]: OPERATION_PERMISSION_KEY } = {
    [CoreApiEntityType.goals]: OPERATION_PERMISSION_KEY.GOALS_MODIFY,
    [CoreApiEntityType.horizons]: OPERATION_PERMISSION_KEY.HORIZONS_MODIFY,
    [CoreApiEntityType.riskProfiles]: OPERATION_PERMISSION_KEY.RISKPROFILES_MODIFY,
    [CoreApiEntityType.policies]: OPERATION_PERMISSION_KEY.POLICIES_MODIFY,
    [CoreApiEntityType.portfolios]: OPERATION_PERMISSION_KEY.PORTFOLIOS_MODIFY,
    [CoreApiEntityType.portfolioGroups]: OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_MODIFY,
    [CoreApiEntityType.optimizations]: null,
    [CoreApiEntityType.userGroups]: OPERATION_PERMISSION_KEY.USERGROUPS_MODIFY,
    [CoreApiEntityType.users]: OPERATION_PERMISSION_KEY.USERS_MODIFY,
    [CoreApiEntityType.publicInstruments]: OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_MODIFY,
    [CoreApiEntityType.publicInstrumentGroups]: OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_MODIFY,
};
