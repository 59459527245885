import produce from 'immer';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import {
    ISinglePublicInstrumentGroupApiInput,
    TPublicInstrumentGroup,
    TTPublicInstrumentGroupCreate,
    TTPublicInstrumentGroupPatch,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import { IBaseSingleEntityApiInput } from '@console/core-api/models/api.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { DEFAULT_PUBLIC_INSTRUMENT_GROUP_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import {
    flashErrorApiEntityCreate, flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate, flashSuccessApiEntityPatch,
} from '../entitiesFlashDispatcher';
import { getEntitiesManager } from '../entitiesManager';
import { triggerResetPublicInstrumentGroupsFetch } from './publicInstrumentGroups';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const publicInstrumentGroupDetailsEntity = getEntitiesManager().registerEntity<TPublicInstrumentGroup>({
    asyncEntityKey: AsyncEntityKeys.publicInstrumentGroupDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUP_DETAILS_DATA],
    includeUpdaters: true,
});

export const getPublicInstrumentGroupTitle: TTitleLabelSelector = () => {
    const publicInstrumentGroupDetails = publicInstrumentGroupDetailsEntity.select();

    if (hasFetchSucceeded(publicInstrumentGroupDetails)) {
        return {
            text: getDefaultTranslationFromApiLabel(
                publicInstrumentGroupDetails.data.name,
            ) || publicInstrumentGroupDetails.data.external_id,
            shouldTranslate: false,
        };
    }

    return 'thematic_search.public_instrument_groups.detail.title';
};

export function triggerFetchPublicInstrumentGroupDetails(apiInput: ISinglePublicInstrumentGroupApiInput) {
    if (!validateEntityIdBeforeFetch({
        entityId: apiInput.publicInstrumentGroupId,
        entityType: CoreApiEntityType.publicInstrumentGroups,
    })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TPublicInstrumentGroup, ISinglePublicInstrumentGroupApiInput>({
        detailsEntity: publicInstrumentGroupDetailsEntity,
        entityType: CoreApiEntityType.publicInstrumentGroups,
        api: api.publicInstrumentGroups.fetchPublicInstrumentGroupDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () =>
            publicInstrumentGroupDetailsEntity.select().data.id !== apiInput.publicInstrumentGroupId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchPublicInstrumentGroupDetails(
    instrumentGroupUpdater: (currentInstrumentGroup: TTPublicInstrumentGroupPatch) => void,
) {
    return publicInstrumentGroupDetailsEntity.async.update<TTPublicInstrumentGroupPatch, TPublicInstrumentGroup>({
        api: api.publicInstrumentGroups.patchPublicInstrumentGroupDetails,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                publicInstrumentGroupDetailsEntity.select().data,
                produce(publicInstrumentGroupDetailsEntity.select().data, instrumentGroupUpdater),
            ),
            id: publicInstrumentGroupDetailsEntity.select().data.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPublicInstrumentGroupsFetch();
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreatePublicInstrumentGroup(publicInstrumentGroup: TTPublicInstrumentGroupCreate) {
    return publicInstrumentGroupDetailsEntity.async.create<TTPublicInstrumentGroupCreate, TPublicInstrumentGroup>({
        api: api.publicInstrumentGroups.createPublicInstrumentGroup,
        apiInputSelector: () => publicInstrumentGroup,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPublicInstrumentGroupsFetch();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_DETAIL,
                params: {
                    publicInstrumentGroupId: apiResult.id,
                    publicInstrumentGroupTab: DEFAULT_PUBLIC_INSTRUMENT_GROUP_TAB_KEY,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeletePublicInstrumentGroup(identifier?: IBaseSingleEntityApiInput) {
    return apiEntityAsyncDeleteAction<TPublicInstrumentGroup>({
        detailsEntity: publicInstrumentGroupDetailsEntity,
        api: api.publicInstrumentGroups.deletePublicInstrumentGroup,
        apiInputSelector: () => identifier || {
            id: publicInstrumentGroupDetailsEntity.select().data.id,
        },
        onPreSuccess: () => {
            triggerResetPublicInstrumentGroupsFetch();
            redirectTo({ routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUPS_LIST });
        },
    });
}
