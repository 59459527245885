import isSetString from '@snipsonian/core/cjs/string/isSetString';

/**
 * The instrument ids, e.g. used in the portfolio holdings,
 * - can either be an ISIN of the format US46090E1038
 * - or a listing identifier of the format {ISIN}_{MIC}_{CURRENCY}
 *
 * See also https://investsuite.atlassian.net/wiki/spaces/VAA/pages/3015802960/InvestSuite+Listing+Identifiers
 *
 * This function is to make sure that only the ISIN part is shown
 */
export function formatInstrumentOrListingIdForDisplay(isinOrListingId: string) {
    if (!isSetString(isinOrListingId)) {
        return '';
    }

    return isinOrListingId.split('_')[0];
}
