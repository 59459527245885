import isSetString from '@snipsonian/core/cjs/string/isSetString';
import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import { Locale } from '@console/common/config/i18n.config';
import { IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';

/**
 * Returns the translation for the requested locale.
 * If that is not set, then by default the translation for the default locale is returned.
 */
export function getLabelTranslation({
    label,
    locale = Locale.en,
    defaultLocale = Locale.en,
}: {
    label: IApiMultiTranslationsLabel;
    locale?: Locale;
    defaultLocale?: Locale;
}): string {
    if (!label) {
        return '';
    }

    const translation = label[locale];

    return isSetString(translation)
        ? translation
        : label[defaultLocale];
}

export function isMultiTranslationsLabelTypeGuard(
    input: string | IApiMultiTranslationsLabel,
): input is IApiMultiTranslationsLabel {
    return isObjectPure(input);
}

export function formatLocaleForDisplay(locale: Locale): string {
    const [firstPart] = locale
        .replace('_', '-') // for if the locale unexpectedly contains a '_' instead of a '-'
        .split('-');

    return firstPart.toUpperCase();
}
