import { OptionsObject, SnackbarKey, VariantType } from 'notistack';
import { ITranslatorPlaceholders } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { IAdvancedInstrumentFilters } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { IUniverseInstrumentsPageVars } from 'models/ui/instruments.ui.models';
import { IAdvancedPublicInstrumentsFilters } from '@console/core-api/models/thematicSearch/publicInstrument.models';
import {
    IAdvancedPublicInstrumentGroupsFilters,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import { IAdvancedUserFilters } from '@console/core-api/models/userMgmt/user.models';
import { INavigateToRoute, IRouteLocation } from 'models/routing.models';
import { IPortfolioPerformancePageVars } from 'models/ui/portfolioPerformance.ui.models';
import { IAdvancedPortfoliosFilters } from '@console/core-api/models/portfolioMgmt/portfolio.models';
import {
    IAdvancedPortfolioTransactionsFilters,
} from '@console/core-api/models/portfolioMgmt/portfolioTransaction.models';
import {
    IAdvancedPortfolioOptimizationsFilters,
} from '@console/core-api/models/portfolioMgmt/portfolioOptimization.models';
import { IActiveSortColumn } from 'models/list.models'; // eslint-disable line @import/no-cycle
import { IInstrumentPerformancePastPageVars } from 'models/ui/instrument.ui.models';
import {
    IPolicySimulationPageVars,
    IPolicySimulationPerformancePageVars,
} from 'models/ui/policySimulation.ui.models';
import { ICreatePortfolioReportModalData } from 'models/ui/portfolioReport.ui.models';
import { IUserGroupMembersPageVars } from 'models/ui/userGroup.ui.models';
import { IAdvancedPoliciesFilters } from 'models/ui/policyList.ui.models';
import { IEntityStillUsedModalData } from 'models/ui/entityStillUsed.ui.models';
import { SvgIcon } from 'views/common/icons';
import { IStoryManagerPageVars } from 'models/ui/storymanager.ui.models';
import { IAccessibleByPageVars } from 'models/ui/apiEntityAccessibleBy.ui.models';
import { IMgmtReportingUiVars } from 'models/ui/mgmtReporting.ui.models';

export interface IUiState {
    currentRoute: {
        location: IRouteLocation;
        prevLocation: IRouteLocation;
        appShellConfig: TAppShellConfig;
    };
    appMenu: {
        selectedItemId: number;
        isSelecting: boolean;
        prefersCollapsed: boolean;
    };
    flashMessages: IFlashMessage[];
    appModals: {
        createPortfolioReport: IAppModal<ICreatePortfolioReportModalData>;
        entityStillUsed: IAppModal<IEntityStillUsedModalData>;
    };
    tooltips: ITooltipsState;
    pages: {
        /* Keep keys in sync with UiPageKey !!! */
        usersList?: IListPageVars<IAdvancedUserFilters>;
        userPortfoliosList?: IListPageVars<unknown>;
        userGroupsList?: IListPageVars<unknown>;
        userGroupMembersList?: IListPageVars<unknown> & IUserGroupMembersPageVars;
        userGroupPermissionsList?: IListPageVars<unknown>;

        clientsList?: IListPageVars<IAdvancedUserFilters>;

        policiesList?: IListPageVars<IAdvancedPoliciesFilters>;
        policySearchList?: IListPageVars<IAdvancedPoliciesFilters>;
        policySimulation?: IPolicySimulationPageVars;
        policySimulationPerformance?: IPolicySimulationPerformancePageVars;

        portfoliosList?: IListPageVars<IAdvancedPortfoliosFilters>;
        portfolioSelectList?: IListPageVars<IAdvancedPortfoliosFilters>;
        portfolioPerformance?: IPortfolioPerformancePageVars;
        portfolioTransactionsList?: IListPageVars<IAdvancedPortfolioTransactionsFilters>;
        portfolioOptimizationsList?: IListPageVars<IAdvancedPortfolioOptimizationsFilters>;

        portfolioReportsList?: IListPageVars<unknown>;
        portfolioGroups?: IListPageVars<unknown>;

        storyManagerTemplateDatabasesList?: IListPageVars<unknown>;
        storyManager?: IStoryManagerPageVars;

        riskProfilesList?: IListPageVars<unknown>;
        goalsList?: IListPageVars<unknown>;
        horizonsList?: IListPageVars<unknown>;

        apiEntityVersionsList?: IListPageVars<unknown>;

        publicInstrumentsList?: IListPageVars<IAdvancedPublicInstrumentsFilters>;
        publicInstrumentGroupInstrumentsList?: IListPageVars<IAdvancedPublicInstrumentsFilters>;
        publicInstrumentGroupsList?: IListPageVars<IAdvancedPublicInstrumentGroupsFilters>;

        accessibleBy?: IAccessibleByPageVars;
        accessibleByUsersList?: IListPageVars<IAdvancedUserFilters>;
        accessibleByUserGroupsList?: IListPageVars<unknown>;

        instrumentsList?: IListPageVars<unknown>;
        universeInstrumentsList?: IListPageVars<IAdvancedInstrumentFilters> & IUniverseInstrumentsPageVars;
        instrumentPerformancePast?: IInstrumentPerformancePastPageVars;

        mgmtReporting?: IMgmtReportingUiVars;
    } & TObjectWithProps;
}

export enum UiPageKey {
    usersList = 'usersList',
    userPortfoliosList = 'userPortfoliosList',
    userSearchList = 'userSearchList',
    userGroupsList = 'userGroupsList',
    userGroupMembersList = 'userGroupMembersList',
    userGroupPermissionsList = 'userGroupPermissionsList',

    clientsList = 'clientsList',

    policiesList = 'policiesList',
    policySearchList = 'policySearchList',
    policySimulation = 'policySimulation',
    policySimulationPerformance = 'policySimulationPerformance',

    portfoliosList = 'portfoliosList',
    portfolioSelectList = 'portfolioSelectList',
    portfolioPerformance = 'portfolioPerformance',
    portfolioTransactionsList = 'portfolioTransactionsList',
    portfolioOptimizationsList = 'portfolioOptimizationsList',

    portfolioGroupsList = 'portfolioGroupsList',
    portfolioGroupPortfolios = 'portfolioGroupPortfolios',

    portfolioReportsList = 'portfolioReportsList',

    storyManagerDatabasesList = 'storyManagerDatabasesList',
    storyManagerOutputKeysList = 'storyManagerOutputKeysList',
    storyManagerOutputKeysScenarioList = 'storyManagerOutputKeysScenarioList',
    storyManager = 'storyManager',

    riskProfilesList = 'riskProfilesList',
    goalsList = 'goalsList',
    horizonsList = 'horizonsList',

    apiEntityVersionsList = 'apiEntityVersionsList',

    publicInstrumentsList = 'publicInstrumentsList',
    publicInstrumentGroupInstrumentsList = 'publicInstrumentGroupInstrumentsList',
    publicInstrumentGroupsList = 'publicInstrumentGroupsList',

    accessibleBy = 'accessibleBy',
    accessibleByUsersList = 'accessibleByUsersList',
    accessibleByUserGroupsList = 'accessibleByUserGroupsList',

    instrumentsList = 'instrumentsList',
    universeInstrumentsList = 'universeInstrumentsList',
    instrumentPerformancePast = 'instrumentPerformancePast',

    mgmtReporting = 'mgmtReporting',
}

export type TAppShellConfig = false | IAppShellConfig;

export interface IAppShellConfig {
    // TODO
    // - configs to determine which get rendered in the application shell
    // - e.g.
    //     showFooter: boolean; // default true
    // - OR even allow to override the header and/or footer?
    showBreadcrumbs?: boolean; // default true
    padWithHeader?: boolean; // default true <> false only has effect if showBreadcrumbs=false
    backgroundColor?: string; // if you want e.g. to make the background white
}

export interface IMenuItemBase {
    translationKey: string;
    id?: number; // automatically generated
    parentId?: number;
    icon?: typeof SvgIcon;
    iconFont?: string;
    level?: TMenuLevel;
    isSubItem?: boolean;
}

export type TMenuLevel = 'top' | 'context';

export type TFlashMessageKey = SnackbarKey;

export interface IFlashMessage {
    key: TFlashMessageKey;
    translationKey: string;
    translationPlaceholders: ITranslatorPlaceholders;
    options: OptionsObject;
    dismissed: boolean;
    navigateToRoute?: INavigateToRoute;
}

export interface ITriggerFlashMessagePayload extends ITriggerFlashMessagePayloadBase {
    type?: TFlashMessageType; // default type 'default'
}

export type TFlashMessageType = VariantType;

export interface ITriggerFlashMessagePayloadBase {
    translationKey: string;
    translationPlaceholders?: ITranslatorPlaceholders;
    options?: OptionsObject;
    navigateToRoute?: INavigateToRoute;
}

export interface ITooltipsState {
    tooltipKeyAutomaticallyShown?: string;
    /* when closed by the user by choosing "OK" */
    closed: IDismissedTooltipsPerUser;
    /* when closed by the user by choosing "Do not show again" */
    notAgain: IDismissedTooltipsPerUser;
}

interface IDismissedTooltipsPerUser {
    [userId: string]: {
        [tooltipKey: string]: boolean;
    };
}

export interface ISingleTooltipFilter {
    tooltipKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IListPageVars<AdvancedFilters, ExtraSimpleFilterToggles extends ISimpleFilterToggles = any> {
    search?: {
        mode?: TListSearchMode;
        simple?: IListSimpleSearch<ExtraSimpleFilterToggles>;
        advanced?: IListAdvancedSearch<AdvancedFilters>;
    };
    pagination?: IListPagination;
    sorting?: IListSorting;
}

export interface ISimpleFilterToggles {
    [key: string]: boolean;
}

export type TListSearchMode = 'simple' | 'advanced';

export type TPropertiesFormDisplayMode = 'add' | 'edit';

export interface IListSimpleSearch<ExtraSimpleFilterToggles extends ISimpleFilterToggles = ISimpleFilterToggles> {
    filterValue: string;
    extraFilterToggles: ExtraSimpleFilterToggles;
}

export interface IListAdvancedSearch<AdvancedFilters> {
    filterValues: AdvancedFilters;
}

export interface IListPagination {
    itemsPerPage: number;
    /* we don't keep the pageNr here because:
     * - we don't want to keep it on refresh
     * - instead it's stored in the async entity */
}

export interface IListSorting {
    column: IActiveSortColumn<unknown>;
}

export interface IAppModal<ExtraData> {
    open: boolean;
    extraData?: ExtraData;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOpenAppModalPayload<ExtraData> extends Pick<IAppModal<ExtraData>, 'extraData'> {}
