import isSet from '@snipsonian/core/cjs/is/isSet';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { TErrorEnhancer } from '@snipsonian/axios/cjs/request/types';
import { HTTP_STATUS } from '@typsy/core/dist/http/httpStatus';
import { BaseApiErrorCode, IBaseApiErrorClientSide } from '../../server/error/apiBaseError.models';

/**
 * Interface how the custom error data of an api endpoint can look like.
 *
 * This is part of the 'api-base' package (and not e.g. within the 'core-api' package) because we want to keep
 * the api errors as generic as possible --> doesApiErrorContainData can analyse errors of any api
 */
export interface IPossibleApiErrorData extends TAnyObject {
    title?: string;
    detailItems?: ICoreApiErrorDetailItem[];
    detailText?: string;
}

export interface ICoreApiErrorDetailItem {
    loc: string[]; // e.g. ["warning"]
    msg: string;
    type: string; // e.g. "value_error.missing"
}

export type PossibleApiErrorClientSide = IBaseApiErrorClientSide<BaseApiErrorCode, IPossibleApiErrorData>;

export function getApiErrorCode(error: IBaseApiErrorClientSide): BaseApiErrorCode {
    return error?.response?.code;
}

export function doesApiErrorMessageContainExpectedString(
    error: IBaseApiErrorClientSide,
    expectedString: string,
): boolean {
    return error?.response?.message && error.response.message.search(expectedString) > -1;
}

export function doesApiErrorContainData(
    error: PossibleApiErrorClientSide,
    expected: {
        locEndsWith?: string;
        msgContains?: string;
        detailTextContains?: string;
        titleEquals?: string;
    },
): boolean {
    if (error && error.response) {
        if (isSet(expected.locEndsWith) || isSet(expected.msgContains)) {
            if (isArrayWithValues(error.response.data?.detailItems)) {
                return error.response.data.detailItems.some(
                    (detailItem) => {
                        const locEndsWith = isSet(expected.locEndsWith)
                            ? getLastItemOfArray(detailItem.loc) === expected.locEndsWith
                            : true;

                        const msgContains = isSet(expected.msgContains)
                            ? detailItem.msg && (detailItem.msg.search(expected.msgContains) > -1)
                            : true;

                        return locEndsWith && msgContains;
                    },
                );
            }
        } else if (isSet(expected.detailTextContains)) {
            if (error.response.data?.detailText) {
                return error.response.data.detailText.search(expected.detailTextContains) > -1;
            }
        } else if (isSet(expected.titleEquals)) {
            if (error.response.data?.title) {
                return error.response.data.title === expected.titleEquals;
            }
        }
    }

    return false;
}

export function enhanceApiOptimizationMissingError(error: IBaseApiErrorClientSide): IBaseApiErrorClientSide {
    if (error.status === 404 && doesApiErrorMessageContainExpectedString(
        error,
        'Optimization is not available',
    )) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = BaseApiErrorCode.OPTIMIZATION_MISSING;
    }

    return error;
}

export function enhanceApiEntityNotFoundError(error: IBaseApiErrorClientSide): IBaseApiErrorClientSide {
    if (error.status === 404 && doesApiErrorContainData(error, {
        detailTextContains: 'Entity',
    })) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = BaseApiErrorCode.ENTITY_NOT_FOUND;
    }

    return error;
}

export function enhanceApiResourcesNotReadyError(error: IBaseApiErrorClientSide): IBaseApiErrorClientSide {
    if (error.status === 404 && doesApiErrorContainData(error, {
        detailTextContains: 'App resources are not ready yet',
    })) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = BaseApiErrorCode.RESOURCES_NOT_READY;
    }

    return error;
}

export function enhanceApiEntityDeleteError(error: IBaseApiErrorClientSide): IBaseApiErrorClientSide {
    if (doesApiErrorContainData(error, {
        titleEquals: 'BusinessLogic error',
    }) && error.status === HTTP_STATUS.CONFLICT) { // 409
        // eslint-disable-next-line no-param-reassign
        error.response.code = BaseApiErrorCode.ENTITY_STILL_USED;
    }

    return error;
}

export function enhanceApiEntityUniqueExternalIdError(error: IBaseApiErrorClientSide): IBaseApiErrorClientSide {
    if (doesApiErrorContainData(error, {
        locEndsWith: 'external_id',
        msgContains: 'must be unique',
    })) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = BaseApiErrorCode.ENTITY_NEEDS_UNIQUE_EXTERNAL_ID;
    }

    return error;
}

export function combineApiErrorEnhancers(
    ...enhancers: TErrorEnhancer<IBaseApiErrorClientSide>[]
): TErrorEnhancer<IBaseApiErrorClientSide> {
    return (errorToEnhance) => enhancers.reduce(
        (error, enhancer) => {
            if (enhancer) {
                enhancer(error);
            }

            return error;
        },
        errorToEnhance,
    );
}
