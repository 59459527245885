import React from 'react';
import clsx from 'clsx';
import Slider from '@material-ui/core/Slider';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { TNumberRange } from '@console/core-api/models/api.models';
import { RANGE_FROM_0_TO_1 } from '@console/core-api/config/range.config';
import { TI18nLabelOrString } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { BOX_BORDER_RADIUS } from 'config/styling/border';
import { SIZES } from 'config/styling/sizes';
import { DEFAULT_STEP_FOR_PERCENTAGE_VALUE } from 'config/ui.config';
import {
    convertNumberRangeToPercentageRange,
    convertPercentageRangeToNumberRange,
    countDecimals,
} from 'utils/number/percentageUtils';
import { makeStyles, mixins } from 'views/styling';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import InputNumberField from './InputNumberField';
import InputWrapper from './InputWrapper';

export type TSliderValue = TNumberRange;
export type TSliderSize = 'M' | 'S';
export const DEFAULT_ABSOLUTE_SLIDER_RANGE = RANGE_FROM_0_TO_1;

export interface IInputSliderFieldProps{
    id?: string;
    name?: string;
    value: TSliderValue;
    size?: TSliderSize; // default M
    // this can be used to apply the `edited` styling separately to right/left input (default is same as value)
    initialValue?: TSliderValue;
    onChange: (value: TSliderValue) => void;
    // this is the range the user should be able to move the slider within without getting a validation error
    range?: TSliderValue;
    // the actual range of the slider -> default [0, 1] (0 - 100%)
    absoluteRange?: TSliderValue;
    readOnly?: boolean; // default false
    step?: number; // default 1 for 'number' type, 0.01 for 'percentage' type
    error?: TI18nLabelOrString;
    emphasizeError?: boolean; // default true - if true, then the error will be shown in red
}

interface IAdjustedInputSliderProps {
    adjustedStep: number;
    adjustedValue: TNumberRange;
    adjustedSliderValue: TNumberRange;
    adjustedRange: TNumberRange;
    adjustedAbsoluteRange: TNumberRange;
}

interface IStyleProps {
    verticalMarksAdjustment: string;
    verticalMarkLabelsAdjustment: string;
}

const SLIDER_INPUT_WIDTH = 82;

const useStyles = makeStyles((theme) => ({
    InputSliderField: {
        ...mixins.widthMax(),

        '& .__row': {
            ...mixins.flexRow(),
        },
        '& .Slider': {
            transform: 'translate(0px, 1px)',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            ...mixins.widthMax(),
        },
        '&.RangeSet': {
            paddingTop: theme.spacing(2),

            '& .MuiSlider-root': {
                transform: 'translate(0px, 10px)',

                '& .MuiSlider-markLabel[data-index="0"]': {
                    transform: ({ verticalMarkLabelsAdjustment }: IStyleProps) =>
                        `translate(-26px, ${verticalMarkLabelsAdjustment})`,
                },
                '& .MuiSlider-markLabel': {
                    ...mixins.typoBold({ color: APP_COLORS.GREY[300] }),
                    transform: ({ verticalMarkLabelsAdjustment }: IStyleProps) =>
                        `translate(0px, ${verticalMarkLabelsAdjustment})`,
                },
                '& .MuiSlider-mark[data-index="1"]': {
                    transform: ({ verticalMarksAdjustment }: IStyleProps) =>
                        `translate(-2px, ${verticalMarksAdjustment})`,
                },
                '& .MuiSlider-mark': {
                    height: 20,
                    width: 2,
                    backgroundColor: APP_COLORS.GREY[300],
                    transform: ({ verticalMarksAdjustment }: IStyleProps) =>
                        `translate(0px, ${verticalMarksAdjustment})`,
                },
            },
        },
        '& .Mui-disabled': {
            ...mixins.typo({ color: APP_COLORS.TEXT['500'] }),
        },
        '& .MuiSlider-rail': {
            borderRadius: BOX_BORDER_RADIUS,
            height: 5,
            backgroundColor: APP_COLORS.GREY[400],
        },
        '& .MuiSlider-track': {
            height: 5,
            backgroundColor: APP_COLORS.PRIMARY[200],
        },
        '& .SliderReadOnly .MuiSlider-track': {
            backgroundColor: APP_COLORS.PRIMARY[500],
        },
        '& .SliderReadOnly .MuiSlider-thumb': {
            display: 'none',
        },
        '& .MuiSlider-thumb': {
            transform: 'translate(1px, 1px)',
        },
        '& .MuiSlider-thumb[data-index="1"]': {
            transform: 'translate(-1px, 1px)',
        },

        '& .__errorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.TEXT['300'] }),
            lineHeight: 1.2,
            paddingTop: theme.spacing(1),
        },
        '&.has-error .__errorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },

        '& .__sliderNumberValue': {
            minWidth: 108, // to make sure that a value of -xx,xx can be seen
        },
    },
}));

export default function InputSliderField({
    value,
    onChange,
    range,
    readOnly = false,
    step,
    size = 'M',
    id,
    initialValue = value,
    name,
    error,
    emphasizeError = true,
    absoluteRange = DEFAULT_ABSOLUTE_SLIDER_RANGE,
}: IInputSliderFieldProps) {
    const classes = useStyles({ verticalMarksAdjustment: '-20px', verticalMarkLabelsAdjustment: '-52px' });
    const isRangeSet = isSet(range);
    const defaultSliderValue = getDefaultSliderValues();
    const { adjustedStep, adjustedRange, adjustedAbsoluteRange, adjustedSliderValue } = getAdjustedInputSliderProps();
    const adjustedNrOfDecimals = countDecimals(adjustedStep);
    const wasValueEdited = areMinAndMaxEdited(initialValue, value);
    const inputHeight = size === 'M' ? SIZES.INPUT.HEIGHT.M : SIZES.INPUT.HEIGHT.S;
    const translator = getTranslator(getStore().getState());

    return (
        <div
            className={clsx(
                classes.InputSliderField,
                error && emphasizeError && 'has-error',
                isRangeSet && 'RangeSet',
            )}
        >
            <div className="__row">
                <div>
                    <InputWrapper
                        noPadding
                        readOnly={readOnly}
                        shouldShowOutlineInReadOnly
                        minWidth={SLIDER_INPUT_WIDTH}
                        minHeight={inputHeight}
                    >
                        <InputNumberField
                            className="__sliderNumberValue"
                            value={value[0]} // the number input does the conversion to % internally
                            onChange={({ value: newValue }) => onChange([newValue, value[1]])}
                            disabled={readOnly}
                            crudStylingType={wasValueEdited.min ? 'edited' : null}
                            nrOfDecimals={adjustedNrOfDecimals}
                            type="percentage"
                            height={inputHeight}
                        />
                    </InputWrapper>
                </div>
                <div className={clsx('Slider', readOnly && 'SliderReadOnly')}>
                    <Slider
                        id={id}
                        name={name}
                        value={adjustedSliderValue}
                        step={adjustedStep}
                        min={adjustedAbsoluteRange[0]}
                        max={adjustedAbsoluteRange[1]}
                        marks={isRangeSet && getRangeIndicators()}
                        onChange={(event, currentValue) => onChangeInputSliderValue(currentValue as TNumberRange)}
                        disabled={readOnly}
                        defaultValue={defaultSliderValue}
                    />
                </div>
                <div>
                    <InputWrapper
                        readOnly={readOnly}
                        noPadding
                        shouldShowOutlineInReadOnly
                        minWidth={SLIDER_INPUT_WIDTH}
                        minHeight={inputHeight}
                    >
                        <InputNumberField
                            className="__sliderNumberValue"
                            value={value[1]} // the number input does the conversion to % internally
                            onChange={({ value: newValue }) => onChange([value[0], newValue])}
                            disabled={readOnly}
                            crudStylingType={wasValueEdited.max ? 'edited' : null}
                            nrOfDecimals={adjustedNrOfDecimals}
                            type="percentage"
                            height={inputHeight}
                        />
                    </InputWrapper>
                </div>
            </div>
            {error && (
                <div className={clsx('__row', '__errorText')}>
                    {translator(error)}
                </div>
            )}
        </div>
    );

    function getDefaultSliderValues(): TNumberRange {
        return isRangeSet ? range : absoluteRange;
    }

    function getRangeIndicators() {
        return [{
            value: adjustedRange[0],
            label: translator('common.fields.min').toUpperCase(),
        }, {
            value: adjustedRange[1],
            label: translator('common.fields.max').toUpperCase(),
        }];
    }

    function getAdjustedInputSliderProps(): IAdjustedInputSliderProps {
        const adjStep = step || DEFAULT_STEP_FOR_PERCENTAGE_VALUE;
        const adjValue = convertNumberRangeToPercentageRange(value);
        const adjRange = isRangeSet && convertNumberRangeToPercentageRange(range);
        const adjAbsoluteRange = convertNumberRangeToPercentageRange(absoluteRange);

        /**
         * The adjustedValue can contain null (otherwise the user can not delete the content of the number input fields)
         * but we make sure the adjustedSliderValue has a value so that the slider circles are always shown properly.
         */
        return {
            adjustedStep: adjStep,
            adjustedValue: adjValue,
            adjustedSliderValue: replaceUnsetValuesWithMatchingRangeValues(adjValue, adjAbsoluteRange),
            adjustedRange: adjRange,
            adjustedAbsoluteRange: adjAbsoluteRange,
        };
    }

    function ensureValuesAreWithinRange(sliderValues: TNumberRange) {
        if (!isRangeSet) {
            return sliderValues;
        }

        return [
            sliderValues[0] < range[0]
                ? range[0]
                : sliderValues[0],
            sliderValues[1] > range[1]
                ? range[1]
                : sliderValues[1],
        ] as TNumberRange;
    }

    function onChangeInputSliderValue(newValue: TNumberRange) {
        const newValueWithinRange = ensureValuesAreWithinRange(convertPercentageRangeToNumberRange(newValue));
        onChange(newValueWithinRange);
    }
}

/**
 * This handles the case where we receive a null instead of a number for one of the values.
 */
function replaceUnsetValuesWithMatchingRangeValues(values: TSliderValue, range: TNumberRange): TSliderValue {
    return values
        .map((valuePart, index) => {
            if (!isSet(valuePart)) {
                return range[index];
            }
            return valuePart;
        }) as TSliderValue;
}

export function areSliderValuesSame(valuesA: TSliderValue, valuesB: TSliderValue) {
    if (isArrayWithValues(valuesA) && isArrayWithValues(valuesB)) {
        return valuesA[0] === valuesB[0] && valuesA[1] === valuesB[1];
    }

    return valuesA === valuesB;
}

export function areMinAndMaxEdited(valuesA: TSliderValue, valuesB: TSliderValue): { min: boolean; max: boolean } {
    if (isArrayWithValues(valuesA)) {
        if (isArrayWithValues(valuesB)) {
            return {
                min: valuesA[0] !== valuesB[0],
                max: valuesA[1] !== valuesB[1],
            };
        }

        return {
            min: true,
            max: true,
        };
    }

    if (isArrayWithValues(valuesB)) {
        return {
            min: true,
            max: true,
        };
    }

    return {
        min: false,
        max: false,
    };
}
