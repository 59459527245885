import produce from 'immer';
import localStorage from '@snipsonian/browser/cjs/storage/localStorage';
import sessionStorage from '@snipsonian/browser/cjs/storage/sessionStorage';
import { HTTP_STATUS } from '@typsy/core/dist/http/httpStatus';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { IState } from 'models/state.models';
import { IApiErrorHandler, registerApiErrorHandler, setAccessTokenGetter } from 'api/setupApiClients';
import { getStore } from 'state';
import { getUserAccessToken } from 'state/auth/selectors';
import { triggerFlashError } from 'state/ui/flashMessages.actions';
import { setLoginRedirectUrl } from 'state/auth/actions';
import { getCurrentRouteLocation } from 'state/ui/selectors';
import { getStateStorageConfig, removeStoredStatePartsNotToBeKeptOnInit } from 'state/stateConfig';
import { initErrorReporter } from 'utils/error/initErrorReporter';
import reportApiError from 'utils/error/reportApiError';
import { redirectToLogin } from 'views/routes';

export default function initPreRender() {
    initErrorReporter();

    cleanupUnwantedStateParts();

    setAccessTokenGetter(() => getUserAccessToken(getStore().getState()));

    registerApiErrorHandler(getOnUnauthorisedApiErrorHandler());
    registerApiErrorHandler(getReportApiErrorHandler());
}

/**
 * Will be called when the app gets initialized, so both on first visit as on page refresh (but not when
 * navigating between pages).
 * Responsible for cleaning up some parts of the state, e.g. those parts that are stored in browser storage but should
 * actually not be kept across browser sessions (it was too hard for not storing it in the browser state as it is part
 * of a large object).
 */
export function cleanupUnwantedStateParts() {
    const stateStorageKey = getStateStorageConfig().local.browserStorageKey;

    [localStorage, sessionStorage].forEach((browserStorage) => {
        const state = browserStorage.read({ key: stateStorageKey, defaultValue: {} }) as IState;

        const newState = produce(state, (draftState) => {
            removeStoredStatePartsNotToBeKeptOnInit(draftState);
        });

        browserStorage.save({ key: stateStorageKey, value: newState });
    });
}

function getOnUnauthorisedApiErrorHandler(): IApiErrorHandler {
    return {
        resolve: (error: IBaseApiErrorClientSide) => {
            if (error.status === HTTP_STATUS.UNAUTHORIZED) {
                const { dispatch, getState } = getStore();
                dispatch(triggerFlashError({ translationKey: 'error.auth.not_authenticated' }));
                const currentUrl = getCurrentRouteLocation(getState()).url;
                if (currentUrl) {
                    dispatch(setLoginRedirectUrl({ redirectUrl: currentUrl }));
                }
                redirectToLogin();
                return true;
            }
            return false;
        },
    };
}

function getReportApiErrorHandler(): IApiErrorHandler {
    return {
        handle: (apiError: IBaseApiErrorClientSide) => {
            reportApiError(apiError);
        },
    };
}
