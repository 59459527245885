import React from 'react';
import clsx from 'clsx';
import areArrayItemsSet from '@snipsonian/core/cjs/array/verification/areArrayItemsSet';
import { IPathParams } from 'models/routing.models';
import { IState } from 'models/state.models';
import { getStore } from 'state';
import { getCurrentRouteLocation } from 'state/ui/selectors';
import { makeStyles, mixins } from 'views/styling';
import {
    IDynamicTitleLabelConfig,
    initPageTitleBasedOnState,
} from 'views/common/layout/PageTitleBasedOnState';
import Tabs, { ITabsProps } from 'views/common/layout/Tabs';
import TabsWithEntityWrapper, { ITabsWithEntityWrapperProps } from 'views/common/layout/TabsWithEntityWrapper';
import { redirectTo } from 'views/routes';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import ShowIfEntity from 'views/common/widget/ShowIfEntity';
import EntityId, { IEntityIdProps } from 'views/common/widget/EntityId';
import DetailPageFooter, { IDetailPageFooterProps } from './DetailPageFooter';
import { ITagProps } from '../widget/Tag';

interface IPublicProps {
    className?: string;
    title: TDetailPageTitleConfig;
    tabs?: ITabsProps;
    tabsWithEntityWrapper?: ITabsWithEntityWrapperProps;
    /**
     * if 'actions' in combo with 'tabsWithEntityWrapper', then the actions will only be shown
     * when the 'entity' is available.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions?: (wrappedEntity?: any) => IActionItem[];
    /**
     * If provided, this will show a footer component (fixed to the bottom of the viewport).
     * If in combo with 'tabsWithEntityWrapper', then this footer will only be shown
     * when the 'entity' is available AND will re-render based on the entity notifications.
     */
    footer?: TDetailPageFooterConfig;
    topRight?: IDetailPageExtraConfig;
    extraInfo?: IDetailPageExtraConfig;
    /* If provided, on the app-body-top-right this entity id will be shown together with a 'copy-to-clipboard' */
    entityId?: IEntityIdProps;
}

export type TDetailPageTitleConfig = Omit<IDynamicTitleLabelConfig, 'tagSelector'>;

type TDetailPageFooterConfig = Omit<IDetailPageFooterProps, 'className'>;

interface IDetailPageExtraConfig {
    render: (renderProps: IDetailPageExtraRenderProps) => React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IDetailPageExtraRenderProps<WrappedEntity = any> {
    wrappedEntity?: WrappedEntity; /* only provided if in combo with 'tabsWithEntityWrapper' */
}

const useStyles = makeStyles((theme) => ({
    DetailPageWrapper: {
        /* no position !! (so that the topRight will be positioned relative to the appBodyBox)
         * needed so that e.g. the portfolio recommendations header does not overlap with the entity id */

        '& .__topRight': {
            position: 'absolute',
            top: -36,
            right: 0,
        },
    },
    DetailPage: {
        ...mixins.flexColTopLeft(),
        ...mixins.spacingBodyContent(),
        position: 'relative',

        '&.__hasFooter': {
            paddingBottom: theme.spacing(6),
        },

        '& .__header': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),
            paddingBottom: theme.spacing(2),

            '& .__mainInfo': {
                ...mixins.flexRowCenterLeft(),
            },
        },

        '& .__tabs': {},

        '& .detailActions': {},

        '& .detailFooter': {
            ...mixins.widthMax(),
        },
    },
}));

export default function DetailPage({
    className,
    title,
    tabs,
    tabsWithEntityWrapper,
    actions: getActions,
    extraInfo,
    footer,
    topRight,
    entityId,
}: IPublicProps) {
    const classes = useStyles();

    const PageTitleBasedOnState = initPageTitleBasedOnState({
        notifications: title.notifications,
    });

    if (footer && footer.apiEntityVersion && tabsWithEntityWrapper) {
        if (footer.apiEntityVersion.extraNotifications) {
            footer.apiEntityVersion.extraNotifications.push(...tabsWithEntityWrapper.entity.notifications);
        } else {
            // eslint-disable-next-line no-param-reassign
            footer.apiEntityVersion.extraNotifications = tabsWithEntityWrapper.entity.notifications;
        }
    }

    return (
        <div className={classes.DetailPageWrapper}>
            {(topRight || entityId) && (
                <div className="__topRight">
                    {entityId && (
                        <EntityId {...entityId} />
                    )}

                    {topRight && tabsWithEntityWrapper && (
                        <ShowIfEntity
                            {...tabsWithEntityWrapper.entity}
                            showIfAnyAsyncOperationBusy={false}
                            showLoader={false}
                            setMinHeight={false}
                            renderData={() => renderExtra(topRight)}
                        />
                    )}
                    {topRight && !tabsWithEntityWrapper && renderExtra(topRight)}
                </div>
            )}

            <div
                className={clsx(
                    classes.DetailPage,
                    className,
                    footer && '__hasFooter',
                )}
            >
                <div className="__header">
                    <div className="__mainInfo">
                        <PageTitleBasedOnState
                            labelSelector={title.selector}
                            startIconSelector={title.startIconSelector}
                            endIconSelector={title.endIconSelector}
                            tagSelector={tagSelector}
                        />
                    </div>

                    {extraInfo && tabsWithEntityWrapper && (
                        <ShowIfEntity
                            {...tabsWithEntityWrapper.entity}
                            showIfAnyAsyncOperationBusy={false}
                            showLoader={false}
                            setMinHeight={false}
                            renderData={() => renderExtra(extraInfo)}
                        />
                    )}
                    {extraInfo && !tabsWithEntityWrapper && renderExtra(extraInfo)}

                    {getActions && (
                        <>
                            {tabsWithEntityWrapper && (
                                <ShowIfEntity
                                    className="detailActions"
                                    {...tabsWithEntityWrapper.entity}
                                    showIfAnyAsyncOperationBusy={false}
                                    showLoader={false}
                                    setMinHeight={false}
                                    renderData={renderActions}
                                />
                            )}
                            {!tabsWithEntityWrapper && renderActions()}
                        </>
                    )}
                </div>

                {tabs && <Tabs {...tabs} className="__tabs" />}
                {!tabs && tabsWithEntityWrapper && (
                    <TabsWithEntityWrapper {...tabsWithEntityWrapper} className="__tabs" />
                )}

                {footer && (
                    <>
                        {tabsWithEntityWrapper && (
                            <ShowIfEntity
                                className="detailFooter"
                                {...tabsWithEntityWrapper.entity}
                                showIfAnyAsyncOperationBusy={false}
                                showLoader={false}
                                setMinHeight={false}
                                renderData={renderFooter}
                            />
                        )}
                        {!tabsWithEntityWrapper && (
                            <DetailPageFooter className="detailFooter" {...footer} />
                        )}
                    </>
                )}
            </div>
        </div>
    );

    function renderExtra(extraConfig: IDetailPageExtraConfig) {
        return extraConfig.render({
            wrappedEntity: getApiEntityIfEntityWrapper(),
        });
    }

    function renderActions() {
        const detailActions = getActions(getApiEntityIfEntityWrapper());
        if (!areArrayItemsSet(detailActions)) {
            return null;
        }
        return <ActionButtons className="detailActions" actions={detailActions} />;
    }

    function tagSelector(state: IState): ITagProps {
        if (tabsWithEntityWrapper && tabsWithEntityWrapper.entity.asyncEntitySelector(state).data?.deleted) {
            return {
                variant: 'error',
                label: 'common.generic_api_entity.detail.deleted',
            };
        }

        return null;
    }

    function renderFooter() {
        return <DetailPageFooter {...footer} />;
    }

    function getApiEntityIfEntityWrapper() {
        return tabsWithEntityWrapper
            ? tabsWithEntityWrapper.entity.asyncEntitySelector(getStore().getState()).data
            : null;
    }
}

export function redirectToCurrentRouteButOverrideParams({ newParams }: { newParams: IPathParams }) {
    const { routeKey, params } = getCurrentRouteLocation(getStore().getState());

    redirectTo({
        routeKey,
        params: {
            ...params,
            ...newParams,
        },
    });
}
