import React, { ReactElement, useState } from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { SortOrder } from '@console/common/models/sort.models';
import { IColValues, IDataItem, TDataColumns, IRenderItemCellProps } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IFetchPortfolioReportsApiInput,
    PortfolioReportStatus,
    TPortfolioReport,
    TPortfolioReportsData,
} from '@console/core-api/models/portfolioMgmt/portfolioReport.models';
import { UiPageKey } from 'models/state/ui.models';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import { IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';
import { ReportEntityType } from 'models/ui/portfolioReport.ui.models';
import { APP_COLORS } from 'config/styling/colors';
import { MAX_PORTFOLIO_REPORT_ITEM_SELECTION } from 'config/portfolioMgmt/portfolioReport.config';
import {
    portfolioReportsEntity,
    triggerOpenPortfolioReport,
    triggerDownloadPortfolioReports,
    triggerDeletePortfolioReport,
    triggerFetchReports,
} from 'state/entities/portfolioMgmt/portfolioReports';
import { canUserModifyPortfolioReport } from 'state/auth/apiEntityAuthorization.selectors';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { formatDate, formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { determineReportEntityType } from 'utils/entities/portfolioMgmt/portfolioReportUtils';
import { makeStyles } from 'views/styling';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, {
    IListPageForApiEntityProps, ISortConfigFunctions,
    TSetStateOnPageNrChange,
} from 'views/common/list/ListPageForApiEntity';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import ActionButtons from 'views/common/buttons/ActionButtons';
import { DownloadIcon, EditIcon, TrashIcon, ViewIcon } from 'views/common/icons';
import EditPortfolioReportName
    from 'views/apps/StoryTeller/EditPortfolioReportName';
import { ReportTypeIndicator } from 'views/common/icons/indicators';
import { IExtraSelectData } from 'views/common/list/ListPage';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import Tag from 'views/common/widget/Tag';

const TRANSLATION_PREFIX = 'portfolio_mgmt.portfolio_reports.list';
const COL_TRANSLATION_PREFIX = `${TRANSLATION_PREFIX}.columns`;

interface IEditPortfolioReportNameData {
    portfolioReportId: string;
    currentPortfolioReportName: IApiMultiTranslationsLabel;
}

interface IPublicProps<ColValues extends IColValues>
    // eslint-disable-next-line max-len
    extends Pick<IListPageForApiEntityProps<IFetchPortfolioReportsApiInput>, 'box' | 'listActions'> {
    overrideCols?: IOverrideCols<ColValues>;
    noResultsRender?: () => ReactElement;
    shouldDisplayFilters?: boolean;
    asyncListEntityFetchTriggerOverride?: (apiInput?: IFetchPortfolioReportsApiInput) => unknown;
}

interface IOverrideCols<ColValues extends IColValues> {
    cols: TDataColumns<ColValues, IPortfolioReportExtraData>;
    toReportListItem: TToReportListItem<ColValues>;
}

type TToReportListItem<ColValues extends IColValues> =
    (props: IToReportListItemProps) => IDataItem<ColValues, IPortfolioReportExtraData>;

interface IToReportListItemProps {
    report: TPortfolioReport;
    defaultReportCols: IDefaultPortfolioReportCols;
}

function toDefaultReportListItem({
    report,
    defaultReportCols,
}: IToReportListItemProps): IDataItem<IDefaultPortfolioReportCols, IPortfolioReportExtraData> {
    return {
        id: report.id,
        colValues: defaultReportCols,
    };
}

export interface IDefaultPortfolioReportCols extends IColValues {
    type: ReportEntityType;
    name: string;
    client: string;
    period: string;
    language: string;
    created: string;
    status: PortfolioReportStatus;
    actions: null;
}

interface IDesiredCol {
    colName: keyof IDefaultPortfolioReportCols;
    percentWidth: number;
}

const DEFAULT_DESIRED_REPORT_COLS: IDesiredCol[] = [
    { colName: 'type', percentWidth: 5 },
    { colName: 'name', percentWidth: 30 },
    { colName: 'client', percentWidth: 15 },
    { colName: 'period', percentWidth: 15 },
    { colName: 'language', percentWidth: 5 },
    { colName: 'created', percentWidth: 15 },
    { colName: 'status', percentWidth: 10 },
    { colName: 'actions', percentWidth: 5 },
];

interface IPortfolioReportExtraData {
    startDate: string;
    endDate: string;
    reportUri: string;
    canBeModified: boolean;
    nameLabel: IApiMultiTranslationsLabel;
}

export function getDefaultReportCols({
    desiredCols = DEFAULT_DESIRED_REPORT_COLS,
}: {
    desiredCols?: IDesiredCol[];
} = {}): TDataColumns<Partial<IDefaultPortfolioReportCols>, IPortfolioReportExtraData> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'type': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.type`,
                            },
                            data: {
                                render: ({ item }) => (
                                    <ReportTypeIndicator
                                        reportType={item.colValues.type}
                                    />
                                ),
                            },
                            align: 'center',
                            percentWidth,
                        };
                        break;
                    }
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.name`,
                            },
                            data: {
                                className: UtilityClass.table.cellBold,
                            },
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Ascending,
                                serverSide: {
                                    field: 'name',
                                },
                            },
                        };
                        break;
                    }
                    case 'client': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.client`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'period': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.period`,
                            },
                            data: {
                                render: ({ item }) => (
                                    <Translate
                                        msg={`${TRANSLATION_PREFIX}.period_template`}
                                        placeholders={{
                                            startDate: item.extra.startDate,
                                            endDate: item.extra.endDate,
                                        }}
                                        raw
                                    />
                                ),
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'language': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.language`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'created': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.created`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'status': {
                        accumulator[colName] = {
                            label: {
                                msg: `${COL_TRANSLATION_PREFIX}.status`,
                            },
                            data: {
                                render: ({ item }) => (
                                    <PortfolioReportStatusTag
                                        status={item.colValues.status}
                                    />
                                ),
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'actions': {
                        accumulator[colName] = {
                            label: EMPTY_COL_TITLE,
                            percentWidth,
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected col name '${colName}'`);
                }
            }

            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultPortfolioReportCols>, IPortfolioReportExtraData>,
    );
}

const useStyles = makeStyles((theme) => ({
    ReportsList: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),

        '& .failed': {
            color: APP_COLORS.FEEDBACK.ERROR,
        },

        '& .pending': {
            color: APP_COLORS.GREY['300'],

            '& .__tableCell .extra-actions-button svg': {
                fill: APP_COLORS.GREY['300'],
            },

            '&:hover': {
                '& .__tableCell': {
                    color: APP_COLORS.PRIMARY['300'],
                },

                '& .extra-actions-button svg': {
                    fill: APP_COLORS.PRIMARY['300'],
                },
            },
        },
    },
}));

export default function GenericReportsList<ColValues extends IColValues = IDefaultPortfolioReportCols>({
    noResultsRender,
    overrideCols,
    box,
    listActions,
    shouldDisplayFilters = true,
    asyncListEntityFetchTriggerOverride,
}: IPublicProps<ColValues>) {
    const classes = useStyles();
    const [editPortfolioReportNameData, setEditPortfolioReportNameData] = useState<IEditPortfolioReportNameData>(null);
    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.portfolioReports.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.PORTFOLIO_REPORTS_DATA],
    });
    const colsConfig: IOverrideCols<ColValues> = overrideCols || {
        cols: getDefaultReportCols() as TDataColumns<ColValues, IPortfolioReportExtraData>,
        toReportListItem: toDefaultReportListItem as unknown as TToReportListItem<ColValues>,
    };

    colsConfig.cols.actions.data = { render: renderActions };

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <>
            <ListPageForApiEntity
                className={classes.ReportsList}
                box={box}
                notifications={[
                    StateChangeNotification.PORTFOLIO_REPORTS_DATA,
                    StateChangeNotification.PORTFOLIO_REPORTS_UI_VARS,
                ]}
                asyncListEntity={portfolioReportsEntity}
                asyncListEntityFetchTrigger={asyncListEntityFetchTriggerOverride || triggerFetchReports}
                setStateOnPageNrChange={setStateOnPageNrChange}

                uiPageKey={UiPageKey.portfolioReportsList}
                notificationToTriggerOnUiVarChanges={StateChangeNotification.PORTFOLIO_REPORTS_UI_VARS}
                list={{
                    renderData: renderPortfolioReportsList,
                }}
                noResultsRender={noResultsRender}
                selectConfig={{
                    withSelectAll: true,
                    maxItems: MAX_PORTFOLIO_REPORT_ITEM_SELECTION,
                    isItemSelectable: isPortfolioReportSelectable,
                    selectActions: [{
                        id: 'download-reports',
                        label: 'apps.story_teller.reports.list.select_actions.download',
                        icon: <DownloadIcon />,
                        variant: 'main-icon-bare',
                        onExecute: ({ selectedItems }) => triggerDownloadPortfolioReports(
                            selectedItems.map((item) => ({
                                portfolioReportId: item.id,
                            })),
                        ),
                    }],
                }}
                search={shouldDisplayFilters && {
                    simple: {
                        tipTranslationKey: 'apps.story_teller.reports.list.filter.simple.tip',
                        mapSimpleSearchInputToFetchFilter,
                    },
                }}
                sort={{
                    getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                        sortConfigFunctions = newSortConfigFunctions;
                    },
                }}
                listActions={listActions}
            />
            <EditPortfolioReportName
                open={!!editPortfolioReportNameData}
                currentPortfolioReportName={editPortfolioReportNameData?.currentPortfolioReportName}
                portfolioReportId={editPortfolioReportNameData?.portfolioReportId}
                onClose={() => setEditPortfolioReportNameData(null)}
            />
        </>
    );

    function isPortfolioReportSelectable(portfolioReport: TPortfolioReport) {
        return portfolioReport.status === PortfolioReportStatus.OK;
    }

    function renderPortfolioReportsList({
        data,
        entityData,
        extraData,
        // eslint-disable-next-line max-len
    }: IRenderDataProps<TPortfolioReport[], IExtraSelectData<IDataItem<ColValues, IPortfolioReportExtraData>>, TPortfolioReportsData>) {
        if (!data) {
            return null;
        }

        const portfolioReportItems: IDataItem<ColValues, IPortfolioReportExtraData>[] =
            data.map((report) => ({
                ...colsConfig.toReportListItem({
                    report,
                    defaultReportCols: {
                        type: determineReportEntityType(extractEmbeddedEntity({
                            data: entityData,
                            id: report.portfolio_id,
                        })),
                        name: getDefaultTranslationFromApiLabel(report.name),
                        client: getUserFullName(
                            extractEmbeddedEntity<IUserEntityData>({
                                data: entityData,
                                id: report.user_id,
                            }),
                        ),
                        period: null,
                        language: report.language,
                        status: report.status,
                        created: formatDateRelativeToNow({ date: report.creation_datetime }),
                        actions: null,
                    },
                }),
                extra: {
                    startDate: formatDate({ date: report.start_date }),
                    endDate: formatDate({ date: report.end_date }),
                    reportUri: report.report_uri,
                    canBeModified: canUserModifyPortfolioReport(report),
                    nameLabel: report.name, // all locales
                },
            }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={portfolioReportItems}
                selectData={{
                    ...extraData.selectData,
                    isItemSelectable: (item) => item.colValues.status === PortfolioReportStatus.OK,
                }}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            name: simpleInput,
        };
    }

    function renderActions({ item }: IRenderItemCellProps<ColValues, IPortfolioReportExtraData, unknown>) {
        if (item.colValues.status === PortfolioReportStatus.PENDING) {
            return null;
        }

        if (item.colValues.status === PortfolioReportStatus.FAILED) {
            return (
                <ActionButtons
                    actions={[{
                        onExecute: () => triggerDeletePortfolioReport({ id: item.id }),
                        id: 'delete',
                        label: { msg: `${TRANSLATION_PREFIX}.actions.delete` },
                        variant: 'main-icon-bare',
                        icon: <TrashIcon />,
                        askConfirmation: true,
                    }]}
                />
            );
        }

        return (
            <ActionButtons
                actions={[item.extra.canBeModified && {
                    onExecute: () => setEditPortfolioReportNameData({
                        currentPortfolioReportName: item.extra.nameLabel,
                        portfolioReportId: item.id,
                    }),
                    id: 'rename',
                    label: { msg: `${TRANSLATION_PREFIX}.actions.rename` },
                    variant: 'extra',
                    icon: <EditIcon />,
                }, {
                    onExecute: () => triggerOpenPortfolioReport({ portfolioReportId: item.id }),
                    id: 'view-online',
                    label: { msg: `${TRANSLATION_PREFIX}.actions.view_online` },
                    variant: 'extra',
                    icon: <ViewIcon />,
                }, {
                    onExecute: () => triggerDownloadPortfolioReports([{ portfolioReportId: item.id }]),
                    id: 'download',
                    label: { msg: `${TRANSLATION_PREFIX}.actions.download` },
                    variant: 'extra',
                    icon: <DownloadIcon />,
                }]}
            />
        );
    }
}

export function PortfolioReportStatusTag({
    status,
}: {
    status: PortfolioReportStatus;
}) {
    return (
        <Tag
            label={`portfolio_mgmt.portfolio_reports.status.${status.toLowerCase()}`}
            variant={getReportStatusVariant()}
        />
    );

    function getReportStatusVariant() {
        switch (status) {
            case PortfolioReportStatus.FAILED: return 'error';
            case PortfolioReportStatus.PENDING: return 'regular';

            default: return 'success';
        }
    }
}
