import isSet from '@snipsonian/core/cjs/is/isSet';
import isString from '@snipsonian/core/cjs/is/isString';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import getPossiblyNestedObjectPropValue from '@snipsonian/core/cjs/object/filtering/getPossiblyNestedObjectPropValue';
import { IPropertyPath } from './getPropPathsOfObjectList';

/**
 * For each of the (nested) object properties that match a given property path,
 * the value - if needed converted to a string, will be added to the resulting array (in the order
 * of the given property paths).
 */
export function mapObjectToFlatStringArray({
    obj,
    expectedPropPaths,
    valueTransformer,
}: {
    obj: TAnyObject;
    expectedPropPaths: IPropertyPath[];
    valueTransformer?: (value: string) => string;
}): string[] {
    return expectedPropPaths.map((propPath) => {
        const propValue = getPossiblyNestedObjectPropValue(obj, ...propPath.parts);

        if (!isSet(propValue)) {
            return '';
        }

        if (!isString(propValue)) {
            return propValue.toString();
        }

        return isSet(valueTransformer)
            ? valueTransformer(propValue)
            : propValue;
    });
}
