import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { IGroupThatUserBelongsTo } from '../../../models/userMgmt/user.models';

export interface IOperationPermissions {
    [permissionKey: string]: boolean;
}

export function extractUserOperationPermissions(
    groupsThatUserBelongsTo: IGroupThatUserBelongsTo[],
): IOperationPermissions {
    if (!isArrayWithValues(groupsThatUserBelongsTo)) {
        return {};
    }

    return groupsThatUserBelongsTo.reduce(
        (accumulator, group) => {
            if (group.permissions) {
                group.permissions.forEach((permissionKey) => {
                    accumulator[permissionKey] = true;
                });
            }

            return accumulator;
        },
        {} as IOperationPermissions,
    );
}
