import React from 'react';
import { ALL_CURRENCIES } from '@console/common/config/currencies.config';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import { IInputSearchableSelectItem } from 'views/common/inputs/base/InputSearchableSelectField';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';
import { TPortfolioGroupPropertiesFormValues } from './types';

const currencySelectItems: IInputSearchableSelectItem[] = ALL_CURRENCIES.map((currency) => ({
    value: currency,
    label: currency,
}));

export default function PortfolioGroupPropertiesFormFields(
    formContext: IExtendedInputFormContext<TPortfolioGroupPropertiesFormValues>,
) {
    const { fields } = formContext;

    return (
        <>
            <ExtendedInputText<string>
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.label',
                    tooltip: 'fields.name.tooltip',
                }}
            />
            <ExtendedInputSearchableSelect
                formField={fields.currency}
                items={currencySelectItems}
                wrapper={{
                    label: 'fields.base_currency.label',
                    tooltip: 'fields.base_currency.tooltip',
                }}
            />
        </>
    );
}
