import React from 'react';
import isArray from '@snipsonian/core/cjs/is/isArray';
import isFunction from '@snipsonian/core/cjs/is/isFunction';
import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { TI18nLabelOrString, TLabel } from 'models/general.models';
import { observe, IObserveProps } from 'views/observe';
import PageTitle, { IPageTitleProps } from 'views/common/layout/PageTitle';
import { makeStyles, mixins } from 'views/styling';
import Tag, { ITagProps } from 'views/common/widget/Tag';

export interface IPageTitleBasedOnStateProps extends Omit<IPageTitleProps, 'label' | 'startIcon' | 'endIcon'> {
    labelSelector: TTitleLabelSelector;
    startIconSelector?: (state: IState) => React.ReactNode;
    endIconSelector?: (state: IState) => React.ReactNode;
    tagSelector?: TTagSelector;
}

export type TTagSelector = (state?: IState) => ITagProps;
export type TTitleLabelSelector = (state?: IState) => TLabel;

export interface IDynamicTitleLabelConfig
    extends Pick<IPageTitleBasedOnStateProps, 'startIconSelector' | 'endIconSelector' | 'tagSelector'>{
    selector: TTitleLabelSelector;
    notifications: StateChangeNotification[];
}

const useStyles = makeStyles((theme) => ({
    PageTitleBasedOnState: {
        ...mixins.flexRow(),

        '& .__tag': {
            paddingTop: theme.spacing(0.75),
            paddingLeft: theme.spacing(1),
        },
    },
}));

function PageTitleBasedOnState({
    labelSelector,
    startIconSelector,
    endIconSelector,
    tagSelector,
    className,
    state,
}: IPageTitleBasedOnStateProps & IObserveProps) {
    const classes = useStyles();
    const label = labelSelector(state);
    const startIcon = startIconSelector
        ? startIconSelector(state)
        : null;
    const endIcon = endIconSelector
        ? endIconSelector(state)
        : null;
    const tag = tagSelector
        ? tagSelector(state)
        : null;

    return (
        <div className={classes.PageTitleBasedOnState}>
            <PageTitle
                className={className}
                label={label}
                startIcon={startIcon}
                endIcon={endIcon}
            />

            {tag && (
                <div className="__tag">
                    <Tag {...tag} />
                </div>
            )}
        </div>
    );
}

export function initPageTitleBasedOnState({
    notifications,
}: { notifications: StateChangeNotification[] }) {
    return observe<IPageTitleBasedOnStateProps>(
        notifications,
        PageTitleBasedOnState,
    );
}

export function isDynamicTitleLabelConfig(
    title: TI18nLabelOrString | IDynamicTitleLabelConfig,
): title is IDynamicTitleLabelConfig {
    if (isObjectPure(title)) {
        return isFunction((title as IDynamicTitleLabelConfig).selector)
            && isArray((title as IDynamicTitleLabelConfig).notifications);
    }

    return false;
}
