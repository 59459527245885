import { PolicyAllocationCategory } from '@console/core-api/models/portfolioMgmt/policy.models';

const ALLOCATION_CATEGORIES_LABEL_PREFIX = 'portfolio_mgmt.policies.detail.fields';

export const allocationCategoriesList = [{
    value: PolicyAllocationCategory.assetClasses,
    label: `${ALLOCATION_CATEGORIES_LABEL_PREFIX}.asset_classes.label`,
}, {
    value: PolicyAllocationCategory.regionBonds,
    label: `${ALLOCATION_CATEGORIES_LABEL_PREFIX}.region_bonds.label`,
}, {
    value: PolicyAllocationCategory.regionStocks,
    label: `${ALLOCATION_CATEGORIES_LABEL_PREFIX}.region_stocks.label`,
}, {
    value: PolicyAllocationCategory.bondTypes,
    label: `${ALLOCATION_CATEGORIES_LABEL_PREFIX}.bond_types.label`,
}, {
    value: PolicyAllocationCategory.sectors,
    label: `${ALLOCATION_CATEGORIES_LABEL_PREFIX}.sectors.label`,
}];
