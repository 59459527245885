import isSet from '@snipsonian/core/cjs/is/isSet';
import createObserverManager from '@snipsonian/core/cjs/patterns/createObserverManager';
import { History } from 'history';
import { INavigateToRoute, IRouteLocation } from 'models/routing.models';
import { IRoute, IRoutesMap, IEnhancedRoute } from 'models/route.models';
import { ROUTE_KEY } from 'views/routeKeys';
import replacePathPlaceholders from 'utils/routing/replacePathPlaceholders';

let registeredRoutes: IRoutesMap<ROUTE_KEY> = {};
/**
 * Routes that are rendered completely on their own, so either top routes
 * OR child routes that are rendered NOT within their parent component.
 */
const independantRouteKeys: ROUTE_KEY[] = [];
let browserHistory: History = null;
const routeObserverManager = createObserverManager();

export function registerRoutes(allRoutes: IRoute<ROUTE_KEY>[]) {
    registeredRoutes = allRoutes.reduce(
        (accumulator, route) => {
            accumulator[route.routeKey] = {
                ...route,
                parentRouteKey: null,
            };

            if (route.component && route.exact) {
                /**
                 * If exact = true, then we add the parent BEFORE the child routes.
                 */
                independantRouteKeys.push(route.routeKey);
            }

            const convertedChildRoutes = convertChildRoutes(route);

            if (route.component && !route.exact) {
                /**
                 * If exact = false, then we add the parent AFTER the child routes.
                 * (otherwise /parent/:someVar/child will already be handled by the route /parent/:someVar)
                 */
                independantRouteKeys.push(route.routeKey);
            }

            return {
                ...accumulator,
                ...convertedChildRoutes,
            };
        },
        {} as IRoutesMap<ROUTE_KEY>,
    );
}

function convertChildRoutes(parentRoute: IRoute<ROUTE_KEY>): IRoutesMap<ROUTE_KEY> {
    if (!hasChildRoutes(parentRoute)) {
        return {};
    }

    return parentRoute.childRoutes
        .reduce(
            (accumulator, childRoute) => {
                const enhancedChildRoute = {
                    ...childRoute,
                    path: `${parentRoute.path}${childRoute.path}`,
                    allowAnonymousAccess: isSet(childRoute.allowAnonymousAccess)
                        ? childRoute.allowAnonymousAccess
                        : parentRoute.allowAnonymousAccess,
                    requiredPermissions: isSet(childRoute.requiredPermissions)
                        ? childRoute.requiredPermissions
                        : parentRoute.requiredPermissions,
                    isEnabled: isSet(childRoute.isEnabled)
                        ? childRoute.isEnabled
                        : parentRoute.isEnabled,
                    appShell: isSet(childRoute.appShell)
                        ? childRoute.appShell
                        : parentRoute.appShell,
                    parentRouteKey: parentRoute.routeKey,
                    /* add the parent breadcrumb notifications to the child's, so that the 'upper' breadcrumb parts
                       always will be re-rendered properly */
                    breadcrumb: {
                        ...childRoute.breadcrumb,
                        extraNotifications: (childRoute.breadcrumb?.extraNotifications || [])
                            .concat(parentRoute.breadcrumb?.extraNotifications || []),
                    },
                };

                accumulator[childRoute.routeKey] = enhancedChildRoute;

                if (!childRoute.renderChildRoutesWithinParent && childRoute.component && childRoute.exact) {
                    /** exact=true --> BEFORE child routes (see also comment above) */
                    independantRouteKeys.push(childRoute.routeKey);
                }

                const convertedChildRoutes = convertChildRoutes(enhancedChildRoute);

                if (!childRoute.renderChildRoutesWithinParent && childRoute.component && !childRoute.exact) {
                    /** exact=false --> AFTER child routes (see also comment above) */
                    independantRouteKeys.push(childRoute.routeKey);
                }

                return {
                    ...accumulator,
                    ...convertedChildRoutes,
                };
            },
            {} as IRoutesMap<ROUTE_KEY>,
        );
}

export function getRegisteredRoutes(): IRoutesMap<ROUTE_KEY> {
    return registeredRoutes;
}

export function getRoute({ routeKey }: { routeKey: ROUTE_KEY }): IEnhancedRoute<ROUTE_KEY> {
    return registeredRoutes[routeKey];
}

export function getRoutePath({ routeKey }: { routeKey: ROUTE_KEY }): string {
    return getRoute({ routeKey }).path;
}

export function getRouteByPath({ path }: { path: string }): IRoute<ROUTE_KEY> {
    return Object.values(getRegisteredRoutes())
        .find((route) => route.path === path);
}

export function getRouteKeyByPath({ path }: { path: string }): string {
    return getRouteByPath({ path }).path;
}

export function getAllRouteKeys(): ROUTE_KEY[] {
    return Object.keys(registeredRoutes) as ROUTE_KEY[];
}

export function getIndependantRouteKeys(): ROUTE_KEY[] {
    return independantRouteKeys;
}

export function hasChildRoutes(route: IRoute<ROUTE_KEY>): boolean {
    return route.childRoutes && route.childRoutes.length > 0;
}

export function setBrowserHistory(history: History) {
    if (browserHistory === null) {
        /* history is mutable, so we only have to set it once */
        browserHistory = history;
    }
}

export function redirectToHome() {
    redirectTo({
        routeKey: ROUTE_KEY.R_HOME,
    });
}

export function redirectToLogin() {
    redirectTo({
        routeKey: ROUTE_KEY.R_LOGIN,
    });
}

export function openRouteInNewTab({ routeKey, params }: INavigateToRoute) {
    window.open(replacePathPlaceholders({
        path: getRoutePath({ routeKey }),
        placeholders: params,
    }));
}

export function redirectTo({ routeKey, params }: INavigateToRoute) {
    redirectToPath({
        path: replacePathPlaceholders({
            path: getRoutePath({ routeKey }),
            placeholders: params,
        }),
    });
}

export function redirectToPath({ path }: { path: string }) {
    if (browserHistory) {
        browserHistory.push(path);
    }
}

export function registerRouteObserver(onRoute: (routeLocation: IRouteLocation) => void) {
    routeObserverManager.registerObserver({
        onNotify: onRoute,
        onError: () => {},
    });
}

let previousObservedUrl = '';

export function notifyRouteObservers(routeLocation: IRouteLocation) {
    /**
     * Probably because of the vue-integration, each time a 'legacy route' was triggered,
     * we got notified twice:
     * - 1st: the proper notification
     * - 2nd just after: the same url with a # at the end
     *
     * => before notifying, we check if this is the case so that we notify such route changes only once
     *
     * UPDATE 30/03/2022: to be check if this logic is still needed (but it doesn't really hurt)
     */
    const currentUrl = window.location.href;

    if (currentUrl !== `${previousObservedUrl}#`) {
        routeObserverManager.notifyObservers(routeLocation);
    }

    previousObservedUrl = currentUrl;
}
