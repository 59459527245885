import React from 'react';
import { IDataItem, TDataColumns, TDataItemId } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, {
    ISortConfigFunctions,
    TSetStateOnPageNrChange,
} from 'views/common/list/ListPageForApiEntity';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import {
    IAdvancedPortfolioGroupsFilters,
    TPortfolioGroup,
    TPortfolioGroupsData,
} from '@console/core-api/models/portfolioMgmt/portfolioGroups.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputTextField, { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import InputGroup from 'views/common/inputs/base/InputGroup';
import { IRenderAdvancedFiltersProps } from 'views/common/list/DataSearch';
import { portfolioGroupsEntity, triggerFetchPortfolioGroups } from 'state/entities/portfolioMgmt/portfolioGroups';
import { ROUTE_KEY } from 'views/routeKeys';
import { PORTFOLIO_GROUP_DETAILS_TAB_KEY } from 'config/tabs.config';
import { redirectTo } from 'views/routes';
import portfolioGroupsIllustration from 'views/assets/img/illustrations/portfolio_groups.svg';
import NoResults from 'views/common/list/NoResults';
import { MAX_PORTFOLIO_REPORT_ITEM_SELECTION } from 'config/portfolioMgmt/portfolioReport.config';
import { ReportIcon } from 'views/common/icons';
import { getStore } from 'state';
import { openCreatePortfolioReportAppModal } from 'state/ui/appModals.actions';
import { IExtraSelectData } from 'views/common/list/ListPage';
import { isStorytellerEnabled } from 'state/appConfig/selectors';
import { canUserCreatePortfolioReport } from 'state/auth/apiEntityAuthorization.selectors';
import {
    IDefaultExtraPortfolioGroupsData,
    IDefaultPortfolioGroupsCols,
    getDefaultPortfolioGroupColumns,
} from './portfolioGroupsUtils';

const COL_TRANSLATION_PREFIX = 'portfolio_mgmt.portfolio_groups.list.columns';

const INITIAL_ADVANCED_PORTFOLIO_GROUPS_FILTERS: IAdvancedPortfolioGroupsFilters = {
    externalId: '',
    portfolioGroupName: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPortfolioGroupsCols extends IDefaultPortfolioGroupsCols {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IExtraPortfolioGroupsData extends IDefaultExtraPortfolioGroupsData {}

const COLS: TDataColumns<IPortfolioGroupsCols, IExtraPortfolioGroupsData> = {
    ...getDefaultPortfolioGroupColumns({
        colTranslationPrefix: COL_TRANSLATION_PREFIX,
    }),
};

export default function PortfolioGroupsList() {
    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.portfolios.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.PORTFOLIO_GROUPS],
    });
    const store = getStore();

    let sortConfigFunctions: ISortConfigFunctions;
    const canUserCreateReport = canUserCreatePortfolioReport();

    return (
        <ListPageForApiEntity
            notifications={[
                StateChangeNotification.PORTFOLIO_GROUPS,
                StateChangeNotification.PORTFOLIO_GROUPS_UI_VARS,
            ]}
            asyncListEntity={portfolioGroupsEntity}
            asyncListEntityFetchTrigger={triggerFetchPortfolioGroups}
            setStateOnPageNrChange={setStateOnPageNrChange}
            uiPageKey={UiPageKey.portfolioGroupsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.PORTFOLIO_GROUPS_UI_VARS}
            box={{
                title: 'portfolio_mgmt.portfolio_groups.title',
            }}
            list={{
                renderData: renderPortfolioGroupsList,
            }}
            noResultsRender={() => (
                <NoResults
                    illustration={portfolioGroupsIllustration}
                    title="portfolio_mgmt.portfolio_groups.list.no_results.title"
                    text="portfolio_mgmt.portfolio_groups.list.no_results.text"
                    create={{
                        id: 'nr-create-portfolio-group',
                        label: 'portfolio_mgmt.portfolio_groups.list.no_results.action',
                        toRoute: {
                            routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_ADD,
                        },
                    }}
                />
            )}
            search={{
                simple: {
                    tipTranslationKey: 'portfolio_mgmt.portfolio_groups.search',
                    mapSimpleSearchInputToFetchFilter,
                },
                advanced: {
                    initialValues: INITIAL_ADVANCED_PORTFOLIO_GROUPS_FILTERS,
                    renderFilters: renderAdvancedPortfolioGroupsFilters,
                },
            }}
            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_ADD,
                },
                tooltip: 'portfolio_mgmt.portfolio_groups.list.actions.create',
            }}
            selectConfig={isStorytellerEnabled() && {
                withSelectAll: true,
                maxItems: MAX_PORTFOLIO_REPORT_ITEM_SELECTION,
                selectActions: [{
                    id: 'open-create-portfolio-report',
                    label: 'portfolio_mgmt.portfolios.list.select_actions.create_reports_in_bulk',
                    icon: <ReportIcon />,
                    disabled: !canUserCreateReport,
                    variant: 'main-icon-bare',
                    onExecute: onOpenCreatePortfolioGroupReportInBulkAppModal,
                }],
            }}
        />
    );

    function renderAdvancedPortfolioGroupsFilters({
        filterValues,
        onChangeFilterValue,
    }: IRenderAdvancedFiltersProps<IAdvancedPortfolioGroupsFilters>) {
        return (
            <>
                <InputGroup>
                    <InputWrapper
                        label="portfolio_mgmt.portfolio_groups.filters.name"
                    >
                        <InputTextField
                            value={filterValues.portfolioGroupName}
                            onChange={changeInputHandler('portfolioGroupName')}
                            placeholder="portfolio_mgmt.portfolio_groups.filters.name"
                        />
                    </InputWrapper>
                    <InputWrapper
                        label="portfolio_mgmt.portfolio_groups.filters.external_id"
                    >
                        <InputTextField
                            value={filterValues.externalId}
                            onChange={changeInputHandler('externalId')}
                            placeholder="portfolio_mgmt.portfolio_groups.filters.external_id"
                        />
                    </InputWrapper>
                </InputGroup>
            </>
        );
        function changeInputHandler(fieldName: string) {
            return ({ value }: IOnChangeTextInputProps) => onChangeFilterValue({
                [fieldName]: value,
            });
        }
    }

    function renderPortfolioGroupsList({
        data,
        extraData,
    }: IRenderDataProps<TPortfolioGroup[], IExtraSelectData<IDataItem<IPortfolioGroupsCols>>, TPortfolioGroupsData>) {
        if (!data) {
            return null;
        }

        const portfolioGroupsItems: IDataItem<IPortfolioGroupsCols, IExtraPortfolioGroupsData>[] =
            data.map((portfolioGroup) => ({
                id: portfolioGroup.id,
                colValues: {
                    name: portfolioGroup.name,
                    externalId: portfolioGroup.external_id,
                    updated: formatDateRelativeToNow({ date: portfolioGroup.version_datetime }),
                },
                extra: {
                    onViewDetail: () => openPortfolioGroupDetail(portfolioGroup.id),
                },
            }));

        return (
            <DataTable
                cols={COLS}
                items={portfolioGroupsItems}
                onItemRowClicked={(portfolioGroup) => openPortfolioGroupDetail(portfolioGroup.id)}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
                selectData={extraData.selectData}
            />
        );
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            portfolioGroupName: simpleInput,
        };
    }

    function openPortfolioGroupDetail(portfolioGroupId: TDataItemId) {
        redirectTo({
            routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_DETAIL,
            params: {
                portfolioGroupId,
                portfolioGroupTab: PORTFOLIO_GROUP_DETAILS_TAB_KEY.PORTFOLIOS,
            },
        });
    }

    function onOpenCreatePortfolioGroupReportInBulkAppModal({
        selectedItems,
    }: { selectedItems: TPortfolioGroup[] }) {
        store.dispatch(openCreatePortfolioReportAppModal({
            extraData: {
                entityType: CoreApiEntityType.portfolioGroups,
                reportsInputData: selectedItems.map((portfolioGroup) => ({
                    entityCreationDatetime: portfolioGroup.creation_datetime,
                    entityName: portfolioGroup.name,
                    entityId: portfolioGroup.id,
                    portfolioIds: portfolioGroup.portfolio_ids,
                })),
            },
        }));
    }
}
