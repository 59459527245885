import React, { useState } from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { yearOffset } from '@console/common/utils/date/getSpecificDate';
import { SampleFrequencyPerformancePast } from '@console/core-api/models/performance.models';
import { UniverseType } from '@console/core-api/models/portfolioMgmt/instruments.models';
import {
    IEnhancedPolicyInstrument,
    IEnhancedPolicyOptimization,
} from '@console/bff/models/policies/policyOptimization.models';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { APP_COLORS } from 'config/styling/colors';
import { triggerFetchInstrumentPerformancePast } from 'state/entities/portfolioMgmt/instrumentPerformancePast';
import { formatPercentage } from 'utils/number/percentageUtils';
import ContentTitle from 'views/common/layout/ContentTitle';
import { PerformanceIcon } from 'views/common/icons';
import IconButton from 'views/common/buttons/IconButton';
import { makeStyles } from 'views/styling';
import Modal from 'views/common/layout/Modal';
import { mixins } from 'views/assets/cssInJs/mixins';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import DataTable from 'views/common/list/DataTable';
import AmountDisplay from 'views/common/widget/AmountDisplay';
import EntityFiche from 'views/common/genericApiEntity/EntityFiche';
import InstrumentPerformancePast from './InstrumentPerformancePast';

const TRANSLATION_PREFIX = 'portfolio_mgmt.policies.detail.configuration.simulation';
const LABEL_PREFIX = `${TRANSLATION_PREFIX}.tabs.expected_performance.section.instruments`;
const COL_LABEL_PREFIX = `${LABEL_PREFIX}.columns`;
const DEFAULT_AMOUNT_DECIMAL_POINTS = 2;
const DATA_ITEM_ID = {
    CASH: 'CASH-SIMULATION',
    INSTRUMENTS: 'INSTRUMENTS-SIMULATION',
};

interface IPublicProps {
    enhancedOptimization: IEnhancedPolicyOptimization;
    translator: TTranslator;
}

interface IInstrumentsCols extends IColValues {
    name: string;
    amount: number;
    percentage: string;
    performance?: IInstrumentPerformanceInput;
}

interface IInstrumentPerformanceInput {
    isin: string;
    amount: number;
}

interface IInstrumentsExtraData {
    openPerformanceChart: (cellValue: IInstrumentPerformanceInput) => void;
}

const useStyles = makeStyles((theme) => ({
    InstrumentsList: {
        '& .instrumentName': {
            ...mixins.typo({ size: 15 }),
        },
        '& .sectionTitle': {
            padding: theme.spacing(5, 0, 1, 0),
        },

        '& .performance-icon svg': {
            fill: APP_COLORS.SYSTEM.BLACK,
            width: '24px',
            height: '24px',
            '&:hover': {
                fill: APP_COLORS.PRIMARY[500],
            },
        },
    },
}));

function getCols({
    currency,
}: { currency: string }) {
    const cols: TDataColumns<IInstrumentsCols, IInstrumentsExtraData> = {
        name: {
            label: EMPTY_COL_TITLE,
            data: {
                render: ({ item }) => {
                    if (item.id === DATA_ITEM_ID.CASH) {
                        return (
                            <div className={clsx(UtilityClass.table.cellBold, 'CashNameDisplay')}>
                                {item.colValues.name}
                            </div>
                        );
                    }

                    return (
                        <InstrumentNameCell dataItem={item} />
                    );
                },
            },
            percentWidth: 50,
        },
        amount: {
            label: {
                msg: `${COL_LABEL_PREFIX}.total`,
                placeholders: {
                    currency,
                },
            },
            data: {
                type: 'number',
                render: ({ cellValue, item }) => {
                    const amountDisplay = (
                        <AmountDisplay
                            value={cellValue as number}
                            nrOfDecimals={DEFAULT_AMOUNT_DECIMAL_POINTS}
                        />
                    );
                    if ((item.id === DATA_ITEM_ID.CASH || item.id === DATA_ITEM_ID.INSTRUMENTS) && cellValue) {
                        return (
                            <div className={UtilityClass.table.cellBold}>
                                {amountDisplay}
                            </div>
                        );
                    }
                    return cellValue && amountDisplay;
                },
            },
            align: 'right',
            percentWidth: 15,
        },
        percentage: {
            label: {
                msg: `${COL_LABEL_PREFIX}.weight`,
            },
            align: 'right',
            percentWidth: 10,
            data: {
                type: 'number',
                render: ({ cellValue, item }) => {
                    if (item.id === DATA_ITEM_ID.CASH || item.id === DATA_ITEM_ID.INSTRUMENTS) {
                        return (
                            <div className={UtilityClass.table.cellBold}>
                                {cellValue}
                            </div>
                        );
                    }
                    return cellValue;
                },
            },
        },
        performance: {
            label: EMPTY_COL_TITLE,
            align: 'right',
            percentWidth: 10,
            data: {
                render: ({ cellValue, item }) => (
                    cellValue && (
                        <IconButton
                            id="instrument-performance"
                            className="performance-icon"
                            aria-label="instrument performance"
                            variant="bare"
                            size="XS"
                            onClick={() => item.extra.openPerformanceChart(cellValue as IInstrumentPerformanceInput)}
                            icon={<PerformanceIcon />}
                        />
                    )
                ),
            },
        },
    };

    return cols;
}

export default function PolicySimulationListOfInstruments({
    translator,
    enhancedOptimization,
}: IPublicProps) {
    const classes = useStyles();

    const [isPerformanceChartOpen, setIsPerformanceChartOpen] = useState<boolean>(false);

    const { cash, currency, instruments, instrumentsPercentageWithinHoldings, instrumentsTotalValue }
        = enhancedOptimization;

    const instrumentsList = [{
        id: DATA_ITEM_ID.CASH,
        colValues: {
            name: translator(`${LABEL_PREFIX}.rows.cash`),
            amount: setFloatDecimals(cash.amount, DEFAULT_AMOUNT_DECIMAL_POINTS),
            percentage: formatHoldingsPercentage(cash.percentageWithinHoldings),
        },
    }, {
        id: DATA_ITEM_ID.INSTRUMENTS,
        colValues: {
            name: translator(`${LABEL_PREFIX}.rows.instruments`),
            amount: setFloatDecimals(instrumentsTotalValue, DEFAULT_AMOUNT_DECIMAL_POINTS),
            percentage: formatHoldingsPercentage(instrumentsPercentageWithinHoldings),
        },
    }].concat(instruments.map((instrument: IEnhancedPolicyInstrument) => ({
        id: instrument.isin,
        colValues: {
            name: instrument.body.name || instrument.isin,
            amount: setFloatDecimals(instrument.body.amount, DEFAULT_AMOUNT_DECIMAL_POINTS),
            percentage: formatHoldingsPercentage(instrument.body.percentageWithinHoldings),
            performance: { isin: instrument.isin, amount: instrument.body.amount },
        },
        extra: {
            openPerformanceChart,
        },
    })));

    function openPerformanceChart({ isin, amount }: IInstrumentPerformanceInput) {
        setIsPerformanceChartOpen(true);

        triggerFetchInstrumentPerformancePast({
            isin,
            currency,
            universe_type: UniverseType.Robo,
            startDate: formatDate({
                date: yearOffset({ yearsToAdd: -10 }),
                format: DATE_FORMAT.BACK_END,
            }),
            sampleFrequency: SampleFrequencyPerformancePast.BM,
            instrumentAmount: amount,
        });
    }

    return (
        <div className={classes.InstrumentsList}>
            <ContentTitle
                label={`${LABEL_PREFIX}.title`}
                className="sectionTitle"
            />
            <DataTable
                cols={getCols({ currency })}
                items={instrumentsList}
                toDynamicCellClass={omitInstrumentRowBorder}
            />
            <Modal
                id="instrumentPerformance"
                title={`${LABEL_PREFIX}.performance`}
                open={isPerformanceChartOpen}
                onClose={closePerformanceChart}
                maxWidth="lg"
            >
                <div>
                    <div className="performanceChart">
                        <InstrumentPerformancePast />
                    </div>
                </div>
            </Modal>
        </div>
    );

    function omitInstrumentRowBorder({
        prevDataItem,
    }: IToDynamicCellClassProps<IInstrumentsCols>) {
        if (prevDataItem && prevDataItem.id === DATA_ITEM_ID.INSTRUMENTS) {
            return [UtilityClass.table.cellNoBorder];
        }

        return null;
    }

    function closePerformanceChart() {
        setIsPerformanceChartOpen(false);
    }
}

function setFloatDecimals(value: number, nrOfDecimals: number): number {
    if (value > 0) {
        return Number(value.toFixed(nrOfDecimals));
    }

    return value;
}

function formatHoldingsPercentage(percentageTo1: number) {
    if (!isSet(percentageTo1) || percentageTo1 === 0) {
        return '-';
    }

    return formatPercentage(percentageTo1 * 100);
}

function InstrumentNameCell({
    dataItem,
}: {
    dataItem: IDataItem<IInstrumentsCols>;
}) {
    if (dataItem.id === DATA_ITEM_ID.INSTRUMENTS) {
        return (
            <div className={UtilityClass.table.cellBold}>
                {dataItem.colValues.name}
            </div>
        );
    }
    return (
        <div className="instrumentName">
            <EntityFiche
                id={dataItem.id}
                name={dataItem.colValues.name}
                variant="instrument"
            />
        </div>
    );
}
