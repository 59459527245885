import React from 'react';
import { useParams } from 'react-router-dom';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { APP_COLORS } from 'config/styling/colors';
import { USER_TAB_KEY } from 'config/tabs.config';
import { makeStyles } from 'views/styling';
import GenericReportsList from 'views/apps/StoryTeller/GenericReportsList';
import NoResults from 'views/common/list/NoResults';
import reportsIllustration from 'views/assets/img/illustrations/reports.svg';
import RouteLink from 'views/common/routing/RouteLink';
import { ROUTE_KEY } from 'views/routeKeys';
import { triggerFetchUserPortfolioReports } from 'state/entities/portfolioMgmt/portfolioReports';

const TRANSLATION_PREFIX = 'user_mgmt.users.detail.portfolio_reports.list.no_results';

const useStyles = makeStyles(() => ({
    UserPortfolioReports: {
        '& .__routeLink': {
            color: APP_COLORS.PRIMARY['500'],
            '&:hover': {
                color: APP_COLORS.PRIMARY['500'],
                textDecoration: 'underline',
            },
        },
    },
}));

interface IPublicProps {
    overrideNoResults?: {
        translationPrefix: string;
        userPortfoliosRouteKey: ROUTE_KEY;
        userPortfoliosTabKey: string;
    };
}

export default function GenericUserPortfolioReports({ overrideNoResults }: IPublicProps) {
    const { userId } = useParams<IPossiblePathParams>();
    const classes = useStyles();

    const translationPrefix = overrideNoResults?.translationPrefix || TRANSLATION_PREFIX;

    return (
        <div className={classes.UserPortfolioReports}>
            <GenericReportsList
                noResultsRender={renderNoResults}
                asyncListEntityFetchTriggerOverride={(apiInput) => triggerFetchUserPortfolioReports({
                    ...apiInput,
                    userId,
                })}
            />
        </div>
    );

    function renderNoResults() {
        return (
            <NoResults
                illustration={reportsIllustration}
                title={`${translationPrefix}.title`}
                text={{
                    msg: `${translationPrefix}.text`,
                    placeholders: {
                        portfolioLink: (
                            <RouteLink
                                to={overrideNoResults?.userPortfoliosRouteKey || ROUTE_KEY.R_USER_DETAIL_PORTFOLIOS}
                                params={{
                                    userId,
                                    userTab: overrideNoResults?.userPortfoliosTabKey || USER_TAB_KEY.PORTFOLIOS,
                                }}
                                className="__routeLink"
                            >
                                <Translate
                                    msg={`${translationPrefix}.placeholder.portfolio_link`}
                                />
                            </RouteLink>
                        ),
                        portfolioMgmtLink: (
                            <RouteLink to={ROUTE_KEY.R_PORTFOLIOS_LIST} className="__routeLink">
                                <Translate
                                    msg={`${translationPrefix}.placeholder.portfolio_mgmt_link`}
                                />
                            </RouteLink>
                        ),
                    },
                }}
            />
        );
    }
}
