import { api } from 'api';
import { fetchAllPagesOfApiEntity } from '@console/core-api/utils/fetch/fetchAllPagesOfApiEntity';
import {
    TEnhancedPortfoliosData,
    TFetchEnhancedPortfoliosClientApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import {
    IFetchPortfolioGroupReportPortfoliosApiInput,
} from '@console/core-api/models/portfolioMgmt/portfolioReport.models';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { getEntitiesManager } from '../entitiesManager';

export const portfolioGroupReportPortfoliosEntity = getEntitiesManager().registerEntity<TEnhancedPortfoliosData>({
    asyncEntityKey: AsyncEntityKeys.portfolioGroupReportPortfolios,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_GROUP_REPORT_PORTFOLIOS],
    includeUpdaters: true,
});

export function triggerFetchPortfolioGroupReportPortfolios({ ids }: IFetchPortfolioGroupReportPortfoliosApiInput) {
    return portfolioGroupReportPortfoliosEntity.async.fetch<TFetchEnhancedPortfoliosClientApiInput>({
        api: () => fetchAllPagesOfApiEntity({
            apiInput: {
                ids,
            },
            api: api.bff.portfolios.fetchEnhancedPortfolios,
        }),
        resetDataOnTriggerMode: 'never',
    });
}
