import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { ConsoleBff } from '@console/bff';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { getSelfMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { api } from 'api';
import {
    enrichReportResponseWithFetchDate,
    isEnrichedReportOutdated,
    TEnrichedReportResponse,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';

// eslint-disable-next-line max-len
export const selfClientGrowthReportEntity = getEntitiesManager().registerEntity<TEnrichedReportResponse<ConsoleBff.TFetchSelfClientGrowthReportApiReply>>({
    asyncEntityKey: AsyncEntityKeys.selfClientGrowthReport,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_SELF_CLIENT_GROWTH_DATA],
    includeUpdaters: false,
});

export function selectSelfClientGrowthReport() {
    return selfClientGrowthReportEntity.select();
}

export function triggerFetchSelfClientGrowthReport({
    forceRefresh = false,
}: IForceStateRefreshFilter = {}) {
    const {
        period,
    } = getSelfMgmtReportingPageVars();

    // eslint-disable-next-line max-len
    return selfClientGrowthReportEntity.async.fetch<ConsoleBff.TFetchSelfClientGrowthReportApiInputClient, TEnrichedReportResponse<ConsoleBff.TFetchSelfClientGrowthReportApiReply>, ConsoleBff.TFetchSelfClientGrowthReportApiReply>({
        api: api.bff.mgmtReporting.fetchSelfClientGrowthReport,
        apiInputSelector: () => ({
            period,
        }),
        mapApiResponse: ({ response }) => enrichReportResponseWithFetchDate(response),
        refreshMode: () => forceRefresh || isEnrichedReportOutdated(selfClientGrowthReportEntity.select().data),
        resetDataOnTriggerMode: 'always',
    });
}
