import React from 'react';
import { SortOrder } from '@console/common/models/sort.models';
import {
    IColValues,
    TDataColumns,
} from 'models/list.models';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import { ViewIcon } from 'views/common/icons';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import { EMPTY_COL_TITLE } from 'views/common/list/dataUtils';

export interface IDefaultPortfolioGroupsCols extends IColValues {
    name: string;
    externalId: string;
    updated: string;
}

export interface IDefaultExtraPortfolioGroupsData {
    onViewDetail: () => void;
}

export function getDefaultPortfolioGroupColumns({
    colTranslationPrefix,
    nameWidth = 45,
    externalIdWidth = 35,
    updatedWidth = 15,
    actionsWidth = 5,
}: {
    colTranslationPrefix: string;
    managerTypeWidth?: number;
    nameWidth?: number;
    externalIdWidth?: number;
    updatedWidth?: number;
    actionsWidth?: number;
}): TDataColumns<IDefaultPortfolioGroupsCols, IDefaultExtraPortfolioGroupsData> {
    return {
        name: {
            label: {
                msg: `${colTranslationPrefix}.name`,
            },
            data: {
                className: UtilityClass.table.cellBold,
            },
            percentWidth: nameWidth,
            sort: {
                initialOrder: SortOrder.Descending,
                serverSide: {
                    field: 'name',
                },
            },
        },
        externalId: {
            label: {
                msg: `${colTranslationPrefix}.external_id`,
            },
            percentWidth: externalIdWidth,
            align: 'right',
        },
        updated: {
            label: {
                msg: `${colTranslationPrefix}.updated`,
            },
            percentWidth: updatedWidth,
            sort: {
                initialOrder: SortOrder.Descending,
                serverSide: {
                    field: 'version_datetime',
                },
            },
        },
        actions: {
            label: EMPTY_COL_TITLE,
            data: {
                render: ({ item }) => {
                    const PORTFOLIO_GROUP_ACTIONS: IActionItem[] = [{
                        id: `${item.id}-viewDetail`,
                        label: `${colTranslationPrefix}.action.view_detail`,
                        variant: 'extra',
                        icon: <ViewIcon />,
                        onExecute: item.extra.onViewDetail,
                    }];

                    return <ActionButtons actions={PORTFOLIO_GROUP_ACTIONS} />;
                },
            },
            percentWidth: actionsWidth,
        },
    };
}
