import { RecurringDepositFrequency } from '@console/core-api/models/performance.models';
import { DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS } from '@console/core-api/config/coreApi.config';
import {
    IPortfolioPerformancePageVars,
    PortfolioPerformanceFuturePeriod,
    PortfolioPerformancePastPeriod,
} from 'models/ui/portfolioPerformance.ui.models';

export const INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS: IPortfolioPerformancePageVars = {
    mode: 'past',
    past: {
        period: PortfolioPerformancePastPeriod.SINCE_START,
    },
    future: {
        period: PortfolioPerformanceFuturePeriod.YEARS_10,
        recurringDepositFrequency: RecurringDepositFrequency.MONTHLY,
        useExpectedReturns: DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
    },
};
