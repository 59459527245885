import React from 'react';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import {
    IEnhancedPortfolioStatus,
    IPortfolioCashStatus,
    IPortfolioOrdersStatus,
} from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { makeStyles, mixins } from 'views/styling';
import Tooltip from 'views/common/widget/Tooltip';
import { CashIcon, OrdersIcon } from 'views/common/icons';
import {
    OptimizationStatusIndicator,
    PortfolioStatusIndicator,
} from 'views/common/icons/indicators';
import { TLabel } from 'models/general.models';

interface IEnhancedPortfolioStatusProps {
    enhancedStatus: IEnhancedPortfolioStatus;
}

const useStyles = makeStyles((theme) => ({
    EnhancedPortfolioStatus: {
        ...mixins.flexRowCenterLeft(),

        '& svg': {
            ...mixins.widthHeightPixelsSame(28),
        },
        '& .TooltipContentWrapper:not(:first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
}));

export default function EnhancedPortfolioStatus({
    enhancedStatus,
}: IEnhancedPortfolioStatusProps) {
    const classes = useStyles();

    return (
        <div className={classes.EnhancedPortfolioStatus}>
            <PortfolioStatusIndicator portfolioStatus={enhancedStatus.portfolio} />
            {enhancedStatus.cash && <CashStatus {...enhancedStatus.cash} />}
            {enhancedStatus.orders && <OrdersStatus {...enhancedStatus.orders} />}
            {enhancedStatus.optimization && (
                <OptimizationStatusIndicator enhancedOptimizationStatus={enhancedStatus.optimization} />
            )}
        </div>
    );
}

function CashStatus(portfolioCashStatus: IPortfolioCashStatus) {
    const relevantCashStatusValues = getPositiveObjectValuesAsArray(portfolioCashStatus);

    if (relevantCashStatusValues.length === 0) {
        return null;
    }

    // When there are only withdrawals or fundings we display a different tooltip
    if (relevantCashStatusValues.length === 1) {
        const [cashEventType, cashEventCount] = relevantCashStatusValues[0];
        return (
            <StatusIconWithTooltip
                tooltipLabel={{
                    msg: 'portfolio_mgmt.portfolios.data.enhanced_status.cash.single',
                    placeholders: {
                        cashEventType,
                        cashEventCount,
                    },
                }}
                Icon={<CashIcon />}
            />
        );
    }

    return (
        <StatusIconWithTooltip
            tooltipLabel={{
                msg: 'portfolio_mgmt.portfolios.data.enhanced_status.cash.multi',
                placeholders: {
                    withdrawalsCount: portfolioCashStatus.withdrawals,
                    fundingsCount: portfolioCashStatus.fundings,
                },
                raw: true,
            }}
            Icon={<CashIcon />}
        />
    );
}

function OrdersStatus(portfolioOrdersStatus: IPortfolioOrdersStatus) {
    const relevantOrdersStatusValues = getPositiveObjectValuesAsArray(portfolioOrdersStatus);

    if (relevantOrdersStatusValues.length === 0) {
        return null;
    }

    // When all orders are in one status we display a different tooltip
    if (relevantOrdersStatusValues.length === 1) {
        const [ordersStatus, ordersCount] = relevantOrdersStatusValues[0];
        return (
            <StatusIconWithTooltip
                tooltipLabel={{
                    msg: 'portfolio_mgmt.portfolios.data.enhanced_status.orders.single',
                    placeholders: {
                        ordersCount,
                        ordersStatus,
                    },
                }}
                Icon={<OrdersIcon />}
            />
        );
    }

    return (
        <StatusIconWithTooltip
            tooltipLabel={{
                msg: 'portfolio_mgmt.portfolios.data.enhanced_status.orders.multi',
                placeholders: {
                    plannedOrdersCount: portfolioOrdersStatus.planned,
                    pendingOrdersCount: portfolioOrdersStatus.pending,
                    placedOrdersCount: portfolioOrdersStatus.placed,
                    executedOrdersCount: portfolioOrdersStatus.executed,
                },
                raw: true,
            }}
            Icon={<OrdersIcon />}
        />
    );
}

function getPositiveObjectValuesAsArray(statusObject: TObjectWithProps<number>): [string, number][] {
    return Object.entries(statusObject).filter(([, value]) => value > 0);
}

function StatusIconWithTooltip({
    tooltipLabel,
    Icon,
}: {
    tooltipLabel: TLabel;
    Icon: JSX.Element;
}) {
    return (
        <Tooltip
            label={tooltipLabel}
        >
            {Icon}
        </Tooltip>
    );
}
