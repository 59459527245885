import React from 'react';
import InputSearchableSelectField, {
    IInputSearchableSelectFieldProps,
    IOnChangeSearchableSelectProps,
    TInputSearchableSelectValue,
} from '../base/InputSearchableSelectField';
import { ExtendedInputFormContext } from './ExtendedInputForm';
import { IExtendedInputBaseProps } from './ExtendedInputText';
import ExtendedInputWrapper from './ExtendedInputWrapper';

export interface IExtendedInputSearchableSelectProps<Value extends TInputSearchableSelectValue>
    // eslint-disable-next-line max-len
    extends IExtendedInputBaseProps, Omit<IInputSearchableSelectFieldProps<Value>, 'value' | 'onChange' | 'labelPrefix' | 'error' | 'emphasizeError'> {
    shouldPrefixItemLabels?: boolean; // default true
}

/**
 * Behaves differently depending on the field type:
 * - if it's a single string -> the searchable select will only allow 1 element to be selected
 * - if it's a string-array (even if the array just contains one element) -> will allow multiple items to be selected
 */
export default function ExtendedInputSearchableSelect<Value extends TInputSearchableSelectValue>({
    formField,
    readOnly,
    wrapper,
    disabled,
    shouldPrefixItemLabels = true,
    ...searchableSelectProps
}: IExtendedInputSearchableSelectProps<Value>) {
    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, setFieldValue, readOnlyForm }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                /* this value can be either a string or a string[] */
                function onChange({ value }: IOnChangeSearchableSelectProps<Value>) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                return (
                    <ExtendedInputWrapper
                        disabled={disabled}
                        readOnly={isFieldReadOnly}
                        hasError={!!formField.error && formField.emphasizeError}
                        {...wrapper}
                        className={wrapper.className}
                    >
                        <InputSearchableSelectField<Value>
                            disabled={isFieldReadOnly}
                            value={formField.value as Value}
                            onChange={onChange}
                            error={formField.error}
                            emphasizeError={formField.emphasizeError}
                            labelPrefix={shouldPrefixItemLabels ? labelPrefix : undefined}
                            crudStylingType={formField.isDiff ? 'edited' : null}
                            {...searchableSelectProps}
                        />
                    </ExtendedInputWrapper>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
