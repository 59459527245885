import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import {
    IAdvancedPublicInstrumentsFilters,
    IFetchPublicInstrumentsApiInput,
    TPublicInstrumentsData,
} from '@console/core-api/models/thematicSearch/publicInstrument.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { isRouteLocation } from 'state/ui/selectors';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { getEntitiesManager } from '../entitiesManager';

export const publicInstrumentsEntity = getEntitiesManager().registerEntity<TPublicInstrumentsData>({
    asyncEntityKey: AsyncEntityKeys.publicInstruments,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PUBLIC_INSTRUMENTS_DATA],
    includeUpdaters: true,
});

export const triggerResetPublicInstrumentsFetch = () =>
    publicInstrumentsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchPublicInstrumentGroupInstruments(apiInput?: IFetchPublicInstrumentsApiInput) {
    return triggerFetchPublicInstrumentsGeneric({
        apiInput,
        pageKey: UiPageKey.publicInstrumentGroupInstrumentsList,
        refreshMode: 'always',
    });
}

export function triggerFetchPublicInstruments({
    forceRefresh = false,
    ...apiInput
}: IFetchPublicInstrumentsApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchPublicInstrumentsGeneric({
        apiInput,
        pageKey: UiPageKey.publicInstrumentsList,
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_DETAIL,
        }),
    });
}

function triggerFetchPublicInstrumentsGeneric({
    apiInput,
    pageKey,
    refreshMode,
}: {
    apiInput?: IFetchPublicInstrumentsApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return publicInstrumentsEntity.async.fetch<IFetchPublicInstrumentsApiInput>({
        api: api.publicInstruments.fetchPublicInstruments,
        // eslint-disable-next-line max-len
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchPublicInstrumentsApiInput, IAdvancedPublicInstrumentsFilters>({
            pageKey,
            state,
            apiInput,
            mapSimpleFilterValue: (simpleFilterValue) => ({
                externalId: simpleFilterValue,
            }),
            mapAdvancedFilterValues: (advancedFilterValues) => ({
                externalId: advancedFilterValues.externalId,
                name: advancedFilterValues.name,
            }),
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: publicInstrumentsEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}
