import React, { useRef, useEffect } from 'react';
import d3, { PieArcDatum } from 'utils/libs/d3';

export interface IPieSliceProps<DataType = number> {
    id: string;
    outerRadius: number;
    innerRadius?: number;
    cornerRadius?: number;
    padAngleInRadians?: number;
    arcData: PieArcDatum<DataType>;
    color: string;
    opacity?: number;
    transitionDurationInMillis?: number;
    onPieSliceClicked?: () => void;
}

export default function PieSlice({
    id,
    outerRadius,
    innerRadius = 0,
    cornerRadius = 0,
    padAngleInRadians = 0,
    arcData,
    color,
    opacity = 1,
    transitionDurationInMillis = 0,
    onPieSliceClicked,
}: IPieSliceProps) {
    const ref = useRef();

    /* via useRef so that the value is persisted across re-renders */
    /* see https://www.smashingmagazine.com/2020/11/react-useref-hook/ */
    const hasTransitionEnded = useRef(false);

    useEffect(
        () => {
            const arcGenerator = d3.arc()
                .outerRadius(outerRadius)
                .innerRadius(innerRadius)
                .cornerRadius(cornerRadius)
                .padAngle(padAngleInRadians);

            const arc = d3.select<SVGPathElement, unknown>(ref.current)
                .data([arcData])
                .attr('class', 'pie-slice')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .attr('d', arcGenerator)
                .attr('fill', color);

            if (transitionDurationInMillis > 0) {
                arc.transition()
                    .duration(transitionDurationInMillis)
                    .attrTween('d', (d) => {
                        const i = d3.interpolate(d.startAngle, d.endAngle);
                        return (t) => {
                            // eslint-disable-next-line no-param-reassign
                            d.endAngle = i(t);
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            return arcGenerator(d);
                        };
                    })
                    .on('end', () => {
                        hasTransitionEnded.current = true;
                    });
            } else {
                hasTransitionEnded.current = true;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id], /* the transition will only be done again when the id changes --> should be unique across all pies */
    );

    useEffect(
        () => {
            d3.select<SVGPathElement, unknown>(ref.current)
                .attr('opacity', opacity);
        },
        [opacity],
    );

    return (
        <path
            id={id}
            ref={ref}
            style={{
                cursor: onPieSliceClicked ? 'pointer' : 'default',
            }}
            onMouseDown={onClickPieSlice}
        />
    );

    function onClickPieSlice(/* event: React.MouseEvent */) {
        if (hasTransitionEnded.current && onPieSliceClicked) {
            onPieSliceClicked();
        }
    }
}
