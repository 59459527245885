import React, { useEffect, useState, MouseEvent, HTMLAttributes, ReactElement, Children } from 'react';
import { makeStyles } from '@material-ui/core';
import ContentBox, { IContentBoxProps } from 'views/common/layout/ContentBox';
import { mixins } from 'views/styling';
import clsx from 'clsx';
import isFunction from '@snipsonian/core/cjs/is/isFunction';

const DEFAULT_SIDE_WIDTH = 350;

const useStyles = makeStyles((theme) => ({
    SplitContentBox: {
        '& .resizableContainer': {
            ...mixins.flex({ wrap: 'nowrap', alignMain: 'flex-start', alignCross: 'stretch' }),
            alignContent: 'stretch',

            overflow: 'hidden',

            '& > aside': {
                order: 0,
                flex: '0 0 auto',
                alignSelf: 'auto',

                paddingRight: theme.spacing(1),

                minWidth: '220px',
                maxWidth: '50%',
            },
            '& > main': {
                order: 0,
                flex: '1 1 auto',
                alignSelf: 'auto',

                paddingLeft: theme.spacing(3),
            },
            '& > .resizeHandle': {
                flex: '0 0 auto',

                position: 'relative',
                width: '1px',
                background: theme.palette.grey[300],
                cursor: 'ew-resize',
            },
        },
    },
}));

// Resizable sidebar based on https://codepen.io/Zodiase/pen/qmjyKL
interface IResizeData {
    tracking: boolean;
    startWidth: number;
    startCursorScreenX: number;
    handleWidth: number;
    resizeTarget: HTMLElement;
    parentElement: HTMLElement;
    maxWidth: number;
    currentWidth: number;
}

interface IPublicProps {
    children: (ReactElement<IContentBoxMainContentProps> | ReactElement<IContentBoxSideContentProps>)[];
    initialWidth?: number;
    onResize?: (width: number) => void;
}

export default function ContentBoxWithSide({
    children,
    initialWidth,
    onResize,
    ...contentBoxProps
}: IPublicProps & Omit<IContentBoxProps, 'children'>) {
    const classes = useStyles();

    const [resizeData, setResizeData] = useState<IResizeData>({
        tracking: false,
        startWidth: initialWidth || DEFAULT_SIDE_WIDTH,
        startCursorScreenX: null,
        handleWidth: 10,
        resizeTarget: null,
        parentElement: null,
        maxWidth: null,
        currentWidth: initialWidth || DEFAULT_SIDE_WIDTH,
    });

    // The event handlers here are re-added because they need the current resizeData to calculate
    // and update resizeData values every render
    // (the side content needs to resize/rerender in real time while dragging the mouse)
    useEffect(() => {
        window.addEventListener('mousemove', handleResize);
        window.addEventListener('mouseup', stopTracking);

        return () => {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', stopTracking);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resizeData]);

    const { mainChildren, sideChildren } = mapChildrenToMainAndSubChildren(children);

    return (
        <>
            <ContentBox
                {...contentBoxProps}
                className={clsx(classes.SplitContentBox, contentBoxProps.className)}
            >
                <div className="resizableContainer">
                    <aside style={resizeData.currentWidth ? { width: resizeData.currentWidth } : {}}>
                        {sideChildren}
                    </aside>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div className="resizeHandle" onMouseDown={startTracking} data-target="aside" />
                    <main>
                        {mainChildren}
                    </main>
                </div>
            </ContentBox>
        </>
    );

    function startTracking(event: MouseEvent<HTMLDivElement>) {
        // Only continue if the main (usually left) mousebutton was pressed
        if (event.button !== 0) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const handleElement = event.currentTarget;

        if (!handleElement.parentElement) {
            console.error(new Error('Parent element not found.'));
            return;
        }

        // Use the target selector on the handle to get the resize target.
        const targetSelector = handleElement.getAttribute('data-target');
        const targetElement = handleElement.parentElement.querySelector(targetSelector) as HTMLElement;

        if (!targetElement) {
            console.error(new Error('Resize target element not found.'));
            return;
        }

        setResizeData({
            ...resizeData,
            startWidth: targetElement.getBoundingClientRect().width,
            startCursorScreenX: event.screenX,
            resizeTarget: targetElement,
            parentElement: handleElement.parentElement,
            maxWidth: targetElement.parentElement.getBoundingClientRect().width - resizeData.handleWidth,
            tracking: true,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleResize(event: any) {
        if (resizeData.tracking) {
            const cursorScreenXDelta = event.screenX - resizeData.startCursorScreenX;
            const newWidth = Math.min(resizeData.startWidth + cursorScreenXDelta, resizeData.maxWidth);

            setResizeData({
                ...resizeData,
                currentWidth: newWidth,
            });
        }
    }

    function stopTracking() {
        if (resizeData.tracking) {
            setResizeData({
                ...resizeData,
                tracking: false,
            });
            if (isFunction(onResize)) {
                onResize(resizeData.currentWidth);
            }
        }
    }

    function mapChildrenToMainAndSubChildren(
        elements: (ReactElement<IContentBoxMainContentProps> | ReactElement<IContentBoxSideContentProps>)[],
    ) {
        const mainElements: ReactElement<IContentBoxMainContentProps>[] = [];
        const sideElements: ReactElement<IContentBoxSideContentProps>[] = [];

        Children.map(elements, (child) => {
            switch (child?.type) {
                case ContentBoxMainContent:
                    mainElements.push(child);
                    break;
                case ContentBoxSideContent:
                    sideElements.push(child);
                    break;
                default:
                    console.log('Unknown child type provided to ContentBoxWithSide.', child);
                    break;
            }
        });

        return {
            mainChildren: mainElements,
            sideChildren: sideElements,
        };
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IContentBoxMainContentProps { }
export function ContentBoxMainContent({ children }: HTMLAttributes<HTMLOrSVGElement>) {
    return <>{children}</>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IContentBoxSideContentProps { }
export function ContentBoxSideContent({ children }: HTMLAttributes<HTMLOrSVGElement>) {
    return <>{children}</>;
}
