import assert from '@snipsonian/core/cjs/assert';

type TRgbColor = number[];

/**
 * Returns an array [r, g, b].
 * Works also with shorthand hex triplets such as "#03F".
 *
 * Copied from https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 */
export function hexToRgb(hex: string): TRgbColor {
    return hex.replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        // eslint-disable-next-line prefer-template
        (m, r, g, b) => '#' + r + r + g + g + b + b,
    )
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));
}

export function hexToRgba(hex: string, opacity: number): string {
    assert(opacity, (val) => val >= 0 && val <= 1, 'The opacity {val} should be between 0.0 and 1.0');

    const rgb = hexToRgb(hex);

    return `rgba(${rgb.concat([opacity]).join(', ')})`;
}
