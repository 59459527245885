import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    IApiEntityVersionsComparisonResponse,
    IApiEntityVersionsComparisonIterableItemChangeResponse,
    IApiEntityVersionsComparisonValueChangeResponse,
    IEnhancedApiEntityVersionsComparisonField,
    IVersionsFieldChange,
} from '../../../models/apiEntityVersions.models';
import {
    CORE_ENTITY_FIELD_CHAIN_DELIMITER, CORE_ENTITY_FIELD_CHAINS_TO_IGNORE_FOR_VERSION_DIFF,
} from '../../../config/coreEntitiesVersions.config';

export function enhanceVersionsComparisonResponse(
    responseData: IApiEntityVersionsComparisonResponse,
): IEnhancedApiEntityVersionsComparisonField {
    let allFieldKeys: { [fieldKey: string]: true };

    const rootComparisonField: IEnhancedApiEntityVersionsComparisonField = {
        displayFieldName: 'root',
        changes: [],
        nestedFields: {},
    };

    const {
        entity_type: entityType,
        changes: allChanges,
    } = responseData;

    enhanceValuesChanged(allChanges.values_changed);
    enhanceValuesChanged(allChanges.type_changes);
    enhanceDictionaryItemsAddedOrRemoved(allChanges.dictionary_item_added, 'added');
    enhanceDictionaryItemsAddedOrRemoved(allChanges.dictionary_item_removed, 'removed');
    enhanceIterableItemsAddedOrRemoved(allChanges.iterable_item_added, 'added');
    enhanceIterableItemsAddedOrRemoved(allChanges.iterable_item_removed, 'removed');

    return rootComparisonField;

    function getAllFieldKeys() {
        if (!allFieldKeys) {
            allFieldKeys = {};

            [
                allChanges.values_changed,
                allChanges.type_changes,
                allChanges.dictionary_item_added,
                allChanges.dictionary_item_removed,
                allChanges.iterable_item_added,
                allChanges.iterable_item_removed,
            ].forEach((changesObj) => {
                if (changesObj) {
                    Object.keys(changesObj).forEach((fieldKey) => {
                        allFieldKeys[fieldKey] = true;
                    });
                }
            });
        }

        return allFieldKeys;
    }

    function enhanceValuesChanged(input: IApiEntityVersionsComparisonValueChangeResponse) {
        if (!input) {
            return;
        }

        Object.keys(input).forEach((fieldKey) => {
            const change = input[fieldKey];

            if (!isSet(change.old_value) && isSet(change.new_value)) {
                addChangeToMatchingComparisonField({
                    fieldKey,
                    fieldChangeToAdd: {
                        changeType: 'set',
                        value: change.new_value,
                    },
                });
            } else if (isSet(change.old_value) && !isSet(change.new_value)) {
                addChangeToMatchingComparisonField({
                    fieldKey,
                    fieldChangeToAdd: {
                        changeType: 'removed',
                        value: change.old_value,
                    },
                });
            } else {
                addChangeToMatchingComparisonField({
                    fieldKey,
                    fieldChangeToAdd: {
                        changeType: 'changed',
                        value: change.new_value,
                        oldValue: change.old_value,
                    },
                });
            }
        });
    }

    function enhanceDictionaryItemsAddedOrRemoved(
        input: IApiEntityVersionsComparisonIterableItemChangeResponse,
        changeType: 'added' | 'removed',
    ) {
        if (!input) {
            return;
        }

        Object.keys(input).forEach((fieldKey) => {
            const value = input[fieldKey];

            addChangeToMatchingComparisonField({
                fieldKey,
                fieldChangeToAdd: {
                    changeType,
                    value,
                },
            });
        });
    }

    function enhanceIterableItemsAddedOrRemoved(
        input: IApiEntityVersionsComparisonIterableItemChangeResponse,
        changeType: 'added' | 'removed',
    ) {
        if (!input) {
            return;
        }

        Object.keys(input).forEach((fieldKey) => {
            const value = input[fieldKey];

            addChangeToMatchingComparisonField({
                fieldKey,
                fieldChangeToAdd: {
                    changeType,
                    value,
                },
            });
        });
    }

    function addChangeToMatchingComparisonField({
        fieldKey,
        fieldChangeToAdd,
    }: {
        fieldKey: string;
        fieldChangeToAdd: IVersionsFieldChange;
    }) {
        const fieldNameChain = mapResponseFieldNameToFieldNameChain(fieldKey);

        const comparisonField = getOrAddFieldToComparison({
            fieldKey,
            childFieldNameChain: fieldNameChain,
            parentFieldNameChain: [],
            parentComparisonField: rootComparisonField,
        });

        if (comparisonField) {
            comparisonField.changes.push(fieldChangeToAdd);
        }
    }

    /**
     * Will search the correct field in the tree based on the chain,
     * and will create the needed tree field(s) if not found.
     * Except when the field - or some parent field in the tree - is to be ignored, then this will return null,
     */
    function getOrAddFieldToComparison({
        fieldKey,
        childFieldNameChain,
        parentFieldNameChain,
        parentComparisonField,
    }: {
        fieldKey: string;
        childFieldNameChain: string[];
        parentFieldNameChain: string[];
        parentComparisonField: IEnhancedApiEntityVersionsComparisonField;
    }): IEnhancedApiEntityVersionsComparisonField | null {
        const [fieldName, ...remainingChain] = childFieldNameChain;
        const newParentFieldNameChain = parentFieldNameChain.concat(fieldName);

        if (shouldIgnoreField({
            fieldKey,
            parentFieldNameChain: newParentFieldNameChain,
            childFieldNameChain,
        })) {
            return null;
        }

        let comparisonField = parentComparisonField.nestedFields && parentComparisonField.nestedFields[fieldName];

        if (!comparisonField) {
            comparisonField = {
                displayFieldName: null, // will be filled in by the component
                changes: [],
            };

            if (!parentComparisonField.nestedFields) {
                // eslint-disable-next-line no-param-reassign
                parentComparisonField.nestedFields = {};
            }

            // eslint-disable-next-line no-param-reassign
            parentComparisonField.nestedFields[fieldName] = comparisonField;
        }

        if (remainingChain.length === 0) {
            return comparisonField;
        }

        return getOrAddFieldToComparison({
            fieldKey,
            childFieldNameChain: remainingChain,
            parentFieldNameChain: newParentFieldNameChain,
            parentComparisonField: comparisonField,
        });
    }

    function shouldIgnoreField({
        fieldKey,
        parentFieldNameChain,
        childFieldNameChain,
    }: {
        fieldKey: string;
        parentFieldNameChain: string[];
        childFieldNameChain: string[];
    }): boolean {
        const shouldIgnore = CORE_ENTITY_FIELD_CHAINS_TO_IGNORE_FOR_VERSION_DIFF[
            parentFieldNameChain.join(CORE_ENTITY_FIELD_CHAIN_DELIMITER)
        ];

        if (typeof shouldIgnore === 'function') {
            return shouldIgnore({
                entityType,
                fieldKey,
                fieldNameChain: parentFieldNameChain.concat(childFieldNameChain),
                allChanges,
                allFieldKeys: getAllFieldKeys(),
            });
        }

        return shouldIgnore;
    }
}

function mapResponseFieldNameToFieldNameChain(fieldKey: string): string[] {
    /**
     * This will extract the fieldnames that are between quotes from the string and put them in an array,
     * simultaneously loosing the 'root' level at the beginning.
     * Also indexes of iterable lists will be dropped.
     *
     * E.g. "root['portfolio']['US3160928731']" will be converted to ['portfolio', 'US3160928731']
     *
     * p.s.
     *   [^'[\]]+  Indicates 1 or more (+) times any character that is not: ' or [ or ]
     */
    const fieldsArray = [...fieldKey.matchAll(/(?!=')[^'[\]]+(?=')/g)];
    return fieldsArray.map((match) => match[0]);
}
