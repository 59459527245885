import { currencyArraySchema, object } from '@console/common/utils/schema';
import { CORE_API_ENTITY_TYPE_TO_ID_REGEX, CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { entityIdArray } from '@console/core-api/utils/schema/entityIdSchema';
import {
    advancedPolicyFiltersServerSideSchema,
    policyManagerTagsSchema,
} from '@console/bff/server/utils/schemas/policy.schemas';

export const POLICY_ID_REGEX = CORE_API_ENTITY_TYPE_TO_ID_REGEX[CoreApiEntityType.policies];

export const advancedPolicyFiltersClientSideSchema = advancedPolicyFiltersServerSideSchema
    .omit(['currencies', 'tags', 'ids'])
    .concat(object({
        currencies: currencyArraySchema,
        tags: policyManagerTagsSchema,
        ids: entityIdArray({
            entityType: CoreApiEntityType.policies,
            entityName: 'policy',
        }),
    }));
