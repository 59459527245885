import { YupValidationError } from '@typsy/schema-validation/dist';
import * as validateAgainstSchemaOrig from '@typsy/schema-validation/dist/validate/validateAgainstSchema';
import { setDateFormatter } from '@typsy/schema-validation/dist/baseSchemas/dateSchema';
import { formatDate } from '../date/formatDate';
import { SchemaErrorType } from './types';

export {
    Schema,
    DateSchema, NumberSchema, StringSchema,
    ArraySchema, ObjectSchema,
    mixed,
    lazy,
} from '@typsy/schema-validation/dist';

export type { YupValidationError, ObjectShape, TestConfig } from '@typsy/schema-validation/dist';

export * from './types';
export * from '@typsy/schema-validation/dist/advancedSchemas/getDynamicObjectSchema';

export * from '@typsy/schema-validation/dist/baseSchemas/arraySchema';
export * from '@typsy/schema-validation/dist/baseSchemas/booleanSchema';
export * from '@typsy/schema-validation/dist/baseSchemas/dateSchema';
export * from '@typsy/schema-validation/dist/baseSchemas/numberSchema';
export * from '@typsy/schema-validation/dist/baseSchemas/objectSchema';
export * from '@typsy/schema-validation/dist/baseSchemas/stringSchema';

export * from './customSchemas/bankAccountNumber';
export * from './customSchemas/bankId';
export * from './customSchemas/email';
export * from './customSchemas/multiTranslationsLabel';
export * from './customSchemas/numberRange';
export * from './customSchemas/phoneNumber';
export * from './customSchemas/holdingsItem';
export * from './customSchemas/common.schemas';

setDateFormatter((date) => formatDate({ date }));

export type ISchemaValidationResult = validateAgainstSchemaOrig.ISchemaValidationResult<SchemaErrorType>;
export type ISchemaValidationErrors = validateAgainstSchemaOrig.ISchemaValidationErrors<SchemaErrorType>;
export type ISchemaValidationError = validateAgainstSchemaOrig.ISchemaValidationError<SchemaErrorType>;

export function validateAgainstSchema(
    validateProps: validateAgainstSchemaOrig.IValidateAgainstSchemaProps,
): ISchemaValidationResult {
    return validateAgainstSchemaOrig.validateAgainstSchema<SchemaErrorType>(validateProps);
}

export function mapValidationErrorToSchemaValidationErrors(error: YupValidationError) {
    return validateAgainstSchemaOrig.mapValidationErrorToSchemaValidationErrors<SchemaErrorType>(error);
}
