/**
 * Some etxra info:
 * - SUCCESS -> Not used in case of Portfolio Optimization (the SUCCESS returned by the core-api gets converted
 *              into RECOMMENDED or NOT_RECOMMENDED).
 *              So only in case of a Policy Optimization.
 */
export enum EnhancedOptimizationStatus {
    /* from 'status' returned by core api: */
    SUCCESS = 'SUCCESS', /* Success */
    FAILURE = 'FAILURE', /* Failed */
    INFEASIBLE = 'INFEASIBLE', /* Infeasible */
    NOT_OPTIMIZED = 'NOT_OPTIMIZED', /* Awaiting funding */

    /* console-web only: */
    PENDING = 'PENDING', /* in case of 202 http status */
    RECOMMENDED = 'RECOMMENDED', /* X recommended orders */
    NOT_RECOMMENDED = 'NOT_RECOMMENDED', /* there is an optimization, but it is not recommended */
}

export const ALL_ENHANCED_OPTIMIZATION_STATUSES = Object.values(EnhancedOptimizationStatus);
