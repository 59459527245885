import React from 'react';
import { getStore } from 'state';
import {
    instrumentPerformancePastEntity,
} from 'state/entities/portfolioMgmt/instrumentPerformancePast';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IInstrumentPerformancePastMarkedDate } from 'models/ui/instrument.ui.models';
import { initEntityWrapper } from 'views/common/widget/EntityWrapper';
import { updateInstrumentPerformancePastPageVars } from 'state/ui/uiPages.actions';
import { policyDetailsEntity } from 'state/entities/portfolioMgmt/policyDetails';
import { getUnsavedPolicySettingsIfAny } from 'utils/entities/portfolioMgmt/policyUtils';
import InstrumentPerformancePastChart from './InstrumentPerformancePastChart';

export default function InstrumentPerformancePast() {
    const InstrumentPerformancePastEntityWrapper = initEntityWrapper({
        notifications: [StateChangeNotification.INSTRUMENT_PERFORMANCE_PAST],
    });

    return (
        <InstrumentPerformancePastEntityWrapper
            asyncEntitySelector={instrumentPerformancePastEntity.select}
            renderData={renderInstrumentPerformancePastChart}
        />
    );
}

function renderInstrumentPerformancePastChart() {
    const { dispatch } = getStore();

    return (
        <>
            <InstrumentPerformancePastChart
                baseCurrency={getPolicyBaseCurrency()}
                onChangedMarkedDate={updatePerformancePastMarkedDate}
            />
        </>
    );

    function updatePerformancePastMarkedDate(markedDate: IInstrumentPerformancePastMarkedDate) {
        dispatch(updateInstrumentPerformancePastPageVars({ markedDate }));
    }
}

export function getPolicyBaseCurrency() {
    const unsavedPolicyResult = getUnsavedPolicySettingsIfAny();
    const policyDetailsDataBaseCurrency =
        policyDetailsEntity.select().data.coerced_config.algorithm_settings.execution_settings.base_currency;

    return unsavedPolicyResult.areThereUnsavedSettings
        ? unsavedPolicyResult?.settings?.execution_settings?.base_currency
        : policyDetailsDataBaseCurrency;
}
