import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';

const PATH_PARTS_SEPARATOR = '.';

export interface IPropertyPath {
    path: string;
    parts: string[];
}

export function getPropPathsOfObjectList({
    list,
}: {
    list: TAnyObject[];
}): IPropertyPath[] {
    return list.reduce(
        (distinctPathsAccumulator, row, index) => {
            const rowPaths = extractPropPathsRecursive(row, []);

            if (index === 0) {
                return rowPaths;
            }

            rowPaths.forEach((rowPath) => {
                if (!distinctPathsAccumulator.find((distinctPath) => distinctPath.path === rowPath.path)) {
                    distinctPathsAccumulator.push(rowPath);
                }
            });

            return distinctPathsAccumulator;
        },
        [] as IPropertyPath[],
    );
}

function extractPropPathsRecursive(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: any,
    pathParts: string[],
): IPropertyPath[] {
    if (isObjectPure(input)) {
        return Object.keys(input)
            .reduce(
                (accumulator, key) => {
                    accumulator.push(...extractPropPathsRecursive(
                        input[key],
                        [...pathParts, key],
                    ));

                    return accumulator;
                },
                [],
            );
    }

    return [{
        path: concatenatePathParts(pathParts),
        parts: pathParts,
    }];
}

function concatenatePathParts(pathParts: string[] = []) {
    return pathParts.join(PATH_PARTS_SEPARATOR);
}
