import { ONE_SECOND_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { PeriodType } from '@console/common/models/period.models';

export const FLASH_CONFIG = {
    MAX_NR_MESSAGES_SHOWN: 3,
    AUTO_HIDE_DURATION_IN_MILLIS: {
        DEFAULT: 10 * ONE_SECOND_IN_MILLIS,
        ERROR: null as number, // null = no autohide
    },
};

export const DEFAULT_STEP_FOR_PERCENTAGE_VALUE = 0.01;
export const DEFAULT_STEP_FOR_NUMBER_VALUE = 1;

export const DEFAULT_NR_OF_DECIMALS_FOR_PERCENTAGE_VALUE = 2;
export const DEFAULT_NR_OF_DECIMALS_FOR_NUMBER_VALUE = 2;

export const DEFAULT_PERIOD_TYPE = PeriodType.ALL_TIME;
export const DEFAULT_SELECTABLE_PERIOD_TYPES: PeriodType[] = [
    PeriodType.ALL_TIME, PeriodType.LAST_24H, PeriodType.LAST_7_DAYS, PeriodType.LAST_30_DAYS,
    PeriodType.SPECIFIC_PERIOD, PeriodType.CUSTOM,
];
