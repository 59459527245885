import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { getStore } from 'state/index';
import { getIsUserSelectingInMenu, getPrevRouteLocation } from 'state/ui/selectors';
import { setCurrentRouteLocation, toggleIsUserSelectingMenu } from 'state/ui/actions';
import { isAuthenticatedUser } from 'state/auth/selectors';
import { setLoginRedirectUrl } from 'state/auth/actions';
import { joinParts } from '@console/common/utils/string/joinParts';
import { ROUTE_KEY } from 'views/routeKeys';
import { getRoute, registerRouteObserver } from 'views/routes';

registerRouteObserver((routeLocation) => {
    const { allowAnonymousAccess, executeOnRoute, isEnabled } = getRoute({ routeKey: routeLocation.routeKey });

    if (!isRouteAllowed()) {
        return;
    }

    getStore().dispatch(setCurrentRouteLocation({ routeLocation }));

    doOnRouteExecutions();
    setAppMenuStatusToNotSelecting();

    function doOnRouteExecutions() {
        if (executeOnRoute && executeOnRoute.length > 0) {
            if (isSet(isEnabled) && !isEnabled({ tenantSettings: getStore().getState()?.appConfig?.tenant })) {
                return;
            }

            executeOnRoute.forEach(({ execute, executeInputSelector, shouldExecute, dispatchResult }) => {
                if (
                    shouldExecute &&
                    !shouldExecute({ routeLocation, prevRouteLocation: getPrevRouteLocation(getStore().getState()) })
                ) {
                    return;
                }

                const executeInput = executeInputSelector
                    ? executeInputSelector({ routeLocation })
                    : undefined;

                const result = execute(executeInput);

                if (dispatchResult) {
                    getStore().dispatch(result);
                }
            });
        }
    }

    function setAppMenuStatusToNotSelecting() {
        if (getIsUserSelectingInMenu(getStore().getState())) {
            getStore().dispatch(toggleIsUserSelectingMenu(false));
        }
    }

    function isRouteAllowed(): boolean {
        if (!allowAnonymousAccess && !isAuthenticatedUser(getStore().getState())) {
            const redirectUrl = joinParts(
                [window.location.pathname, window.location.search],
                { joinSeparator: '' },
            );

            if (isSetString(redirectUrl)) {
                /**
                 * Pass along (redirectUrl) the original path so that after the login-success
                 * this originally requested route can be loaded automatically.
                 */
                getStore().dispatch(setLoginRedirectUrl({ redirectUrl }));
            }

            /**
             * Really set the new location (instead of via a redirect)
             * because otherwise the AppHeader & AppMenu were shown on the login page when
             * navigating to the root url.
             */
            const loginRoute = getRoute({ routeKey: ROUTE_KEY.R_LOGIN });

            if (process.env.NODE_ENV !== 'test') {
                // eslint-disable-next-line no-param-reassign
                window.location.href = loginRoute.path;
            }

            return false;
        }

        return true;
    }
});
