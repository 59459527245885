import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { BOX_BORDER_RADIUS } from 'config/styling/border';
import { makeStyles, mixins } from 'views/styling';
import { IPublicPropsWithChildren } from 'views/observe';
import PageTitle from 'views/common/layout/PageTitle';
import ErrorBoundary from 'views/common/error/ErrorBoundary';
import { initPageTitleBasedOnState, IDynamicTitleLabelConfig } from 'views/common/layout/PageTitleBasedOnState';
import isSet from '@snipsonian/core/cjs/is/isSet';
import IconButtonCancel from '../buttons/IconButtonCancel';

const useStyles = makeStyles((theme) => ({
    ContentBoxWrapper: {
        ...mixins.spacingBodyContent(),
        minWidth: 200,

        '&.no-wrapper-padding': {
            padding: 0,
        },

        '& .boxOuterTitle': {
            paddingBottom: theme.spacing(2),
        },

        '& .titleActions': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),

            '& .cancelCreate': {
                paddingBottom: theme.spacing(2),
            },
        },
    },
    ContentBox: {
        backgroundColor: APP_COLORS.SYSTEM.WHITE,
        borderRadius: BOX_BORDER_RADIUS,
        padding: theme.spacing(2, 3),

        '&.position-relative': {
            position: 'relative',
        },

        '&.no-horizontal-padding': {
            paddingLeft: 0,
            paddingRight: 0,
        },

        '&.no-vertical-padding': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
}));

export interface IContentBoxProps extends IPublicPropsWithChildren {
    className?: string;
    titleLabel?: TLabel; // takes precedence over the titleLabelSelector
    titleLabelSelector?: IDynamicTitleLabelConfig;
    noBackground?: boolean; // default false
    noWrapperPadding?: boolean; // default false
    noHorizontalPadding?: boolean; // default false
    noVertialPadding?: boolean; // default false
    markBoxAsPositionRelative?: boolean; // default true
    cancel?: {
        onCancel: () => void;
    };
    customAction?: {
        component: React.ComponentType;
    };
}

export default function ContentBox({
    children,
    className,
    titleLabel,
    titleLabelSelector,
    noBackground = false,
    noWrapperPadding = false,
    noHorizontalPadding = false,
    noVertialPadding = false,
    markBoxAsPositionRelative = true,
    cancel,
    customAction,
}: IContentBoxProps) {
    const classes = useStyles();

    const PageTitleBasedOnState = !titleLabel && titleLabelSelector
        ? initPageTitleBasedOnState({
            notifications: titleLabelSelector.notifications,
        })
        : null;

    const CustomActionComponent = customAction?.component;

    return (
        <div
            className={clsx(
                classes.ContentBoxWrapper,
                noWrapperPadding && 'no-wrapper-padding',
                className,
            )}
        >
            <div className="titleActions">
                {titleLabel && (
                    <PageTitle label={titleLabel} className="boxOuterTitle" />
                )}
                {PageTitleBasedOnState && (
                    <PageTitleBasedOnState
                        labelSelector={titleLabelSelector.selector}
                        startIconSelector={titleLabelSelector.startIconSelector}
                        endIconSelector={titleLabelSelector.endIconSelector}
                        tagSelector={titleLabelSelector.tagSelector}
                    />
                )}

                {isSet(cancel) && (
                    <div className="cancelCreate">
                        <IconButtonCancel
                            id="add_page_close_button"
                            tooltip="common.action.cancel"
                            onClick={cancel.onCancel}
                        />
                    </div>
                )}
                {isSet(customAction) && (
                    <CustomActionComponent />
                )}
            </div>

            <ErrorBoundary>
                {noBackground && children}
                {!noBackground && (
                    <div
                        className={clsx(
                            classes.ContentBox,
                            'ContentBox',
                            noHorizontalPadding && 'no-horizontal-padding',
                            noVertialPadding && 'no-vertical-padding',
                            markBoxAsPositionRelative && 'position-relative',
                        )}
                    >
                        {children}
                    </div>
                )}
            </ErrorBoundary>
        </div>
    );
}
