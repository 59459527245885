import React from 'react';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import GenericUserAdd from 'views/userMgmt/shared/GenericUserAdd';
import { triggerCreateUser } from 'state/entities/userMgmt/userDetails';
import {
    IUserAddFormValues,
    mapUserAddFormValuesToApiEntity,
} from 'views/userMgmt/shared/GenericUserAddPropertiesForm';
import { triggerResetClientsFetch } from 'state/entities/userMgmt/clients';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { DEFAULT_CLIENT_TAB_KEY } from 'config/tabs.config';

export default function ClientAdd() {
    return (
        <GenericUserAdd
            onSubmit={onSubmitAdd}
            overrideListRouteKey={ROUTE_KEY.R_CLIENTS_LIST}
            overrideTitleLabel="client_mgmt.add.title"
        />
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IUserAddFormValues>) {
        return triggerCreateUser({
            user: enrichApiEntityDataForCreate<IUserEntityData>(mapUserAddFormValuesToApiEntity(values)),
            onPreSuccess: triggerResetClientsFetch,
            onSuccess: ({ apiResult }) => redirectTo({
                routeKey: ROUTE_KEY.R_CLIENT_DETAIL,
                params: {
                    userId: apiResult.id,
                    userTab: DEFAULT_CLIENT_TAB_KEY,
                },
            }),
        });
    }
}
