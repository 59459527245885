import React from 'react';
import { IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';
import { IHorizonEntityData } from '@console/core-api/models/portfolioMgmt/horizon.models';
import { SIZES } from 'config/styling/sizes';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ExtendedInputForm,
{
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ContentTitle from 'views/common/layout/ContentTitle';
import ExtendedInputToggle from 'views/common/inputs/extended/ExtendedInputToggle';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import ExtendedMultiTranslationsInput from 'views/common/inputs/extended/ExtendedMultiTranslationsInput';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { getSubmitActionLabelBasedOnMode } from 'utils/entities/entityDetailUtils';
import { getHorizonDetailsSchema } from './horizonDetailsSchema';

export interface IHorizonFormValues extends IFormValues {
    name: IApiMultiTranslationsLabel;
    description: IApiMultiTranslationsLabel;
    years: number;
    warning: boolean;
    warningMessage: IApiMultiTranslationsLabel;
    disabled: boolean;
    disabledMessage: IApiMultiTranslationsLabel;
    externalId: string;
}

interface IPublicProps
    extends Pick<IExtendedInputFormProps<IHorizonFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
    mode: TPropertiesFormDisplayMode;
}

const LABEL_PREFIX = 'portfolio_mgmt.horizons.detail';

export default function HorizonPropertiesForm({
    mode,
    submit,
    ...formProps
}: IPublicProps) {
    return (
        <ExtendedInputForm<IHorizonFormValues>
            name={ExtendedInputFormName.horizonProperties}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: getSubmitActionLabelBasedOnMode(mode),
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderAddFormFields}
            schema={getHorizonDetailsSchema()}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );

    function renderAddFormFields(context: IExtendedInputFormContext<IHorizonFormValues>) {
        return (
            <>
                <ContentTitle
                    label="portfolio_mgmt.horizons.detail.sub_title.main"
                    variant="section"
                />

                {renderHorizonDetailsFormFields(context)}

                <ContentTitle
                    label="portfolio_mgmt.horizons.detail.sub_title.settings"
                    variant="section"
                />

                {renderHorizonSettingsFormFields(context)}
            </>
        );
    }
}

export function renderHorizonPropertiesFormFields(context: IExtendedInputFormContext<IHorizonFormValues>) {
    return (
        <>
            {renderHorizonDetailsFormFields(context)}
            {renderHorizonSettingsFormFields(context)}
        </>
    );
}

function renderHorizonDetailsFormFields({ fields }: IExtendedInputFormContext<IHorizonFormValues>) {
    return (
        <>
            <ExtendedMultiTranslationsInput
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.label',
                }}
            />

            <ExtendedMultiTranslationsInput
                formField={fields.description}
                multilineRows
                wrapper={{
                    label: 'fields.description.label',
                }}
            />

            <InputGroup childrenGrid={[9, 3]}>
                <ExtendedInputText<string>
                    formField={fields.externalId}
                    wrapper={{
                        label: 'fields.external_id.label',
                    }}
                />
                <ExtendedInputNumber
                    formField={fields.years}
                    wrapper={{
                        label: 'fields.years.label',
                    }}
                />
            </InputGroup>
        </>
    );
}

function renderHorizonSettingsFormFields({ fields }: IExtendedInputFormContext<IHorizonFormValues>) {
    return (
        <>
            <InputGroup childrenGrid={[4, 8]}>
                <ExtendedInputToggle
                    formField={fields.disabled}
                    description="fields.disabled.label"
                    inForm
                />
                <ExtendedMultiTranslationsInput
                    formField={fields.disabledMessage}
                    wrapper={{
                        label: 'fields.disabled_message.label',
                    }}
                />
            </InputGroup>

            <InputGroup childrenGrid={[4, 8]}>
                <ExtendedInputToggle
                    formField={fields.warning}
                    description="fields.warning.label"
                    inForm
                />
                <ExtendedMultiTranslationsInput
                    formField={fields.warningMessage}
                    wrapper={{
                        label: 'fields.warning_message.label',
                    }}
                />
            </InputGroup>
        </>
    );
}

export function mapHorizonFormValuesToApiEntity(
    formValues: IHorizonFormValues,
): IHorizonEntityData {
    return {
        name: formValues.name,
        description: formValues.description,
        years: formValues.years,
        warning: formValues.warning,
        warning_message: formValues.warningMessage,
        disabled: formValues.disabled,
        disabled_message: formValues.disabledMessage,
        external_id: formValues.externalId,
    };
}
