import React from 'react';
import clsx from 'clsx';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TI18nLabelOrString } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { TDateEnhanced } from '@console/common/utils/date/dateUtils';
import { DATE_FORMAT } from '@console/common/utils/date/formatDate';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import { GENERIC_CLASS } from 'views/assets/cssInJs/genericClasses';

export interface IInputDateFieldProps {
    id?: string;
    className?: string;
    value: Date;
    onChange: (onChangeProps: IOnChangeDateInputProps) => void;
    error?: TI18nLabelOrString;
    emphasizeError?: boolean; // default true - if true, then the error will be shown in red
    disabled?: boolean;
    clearable?: boolean; // default true
    markAsWrappedInputField?: boolean; // default true
    maxDate?: Date;
    minDate?: Date;
}

export interface IOnChangeDateInputProps {
    value: Date;
    valueEnhanced: TDateEnhanced;
}

const useStyles = makeStyles((theme) => ({
    InputDateField: {
        ...mixins.flexColTopLeft(),

        '& .MuiInputBase-root': {
            margin: theme.spacing(0, 1),
        },
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'unset',

            '& .MuiSvgIcon-root': {
                fill: APP_COLORS.PRIMARY['500'],
            },
        },

        '& .__date-error': {
            ...mixins.typo({ size: 14 }),
            padding: theme.spacing(1, 0),
        },
        '& .__date-error.__emphasized-error': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },
    },
}));

export default function InputDateField({
    id,
    className,
    value,
    onChange,
    error,
    emphasizeError = true,
    disabled,
    clearable = true,
    markAsWrappedInputField = true,
    maxDate,
    minDate,
}: IInputDateFieldProps) {
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.InputDateField,
                error && emphasizeError && 'has-error',
            )}
        >
            <KeyboardDatePicker
                id={id}
                className={clsx(
                    markAsWrappedInputField && GENERIC_CLASS.WRAPPED_INPUT_FIELD,
                    className,
                )}
                value={value}
                onChange={(newDate) => onChangeInput(newDate as TDateEnhanced)}
                disabled={disabled}
                format={DATE_FORMAT.MONTH_DAY_YEAR}
                clearable={clearable}
                invalidDateMessage={null}
                invalidLabel={null}
                maxDateMessage={null}
                minDateMessage={null}
                InputProps={{
                    disableUnderline: true,
                }}
                placeholder={DATE_FORMAT.MONTH_DAY_YEAR}
                maxDate={maxDate}
                minDate={minDate}
            />

            {error && (
                <div className={clsx('__date-error', emphasizeError && '__emphasized-error')}>
                    <Translate {...toI18nLabel(error)} />
                </div>
            )}
        </div>
    );

    function onChangeInput(newDate: TDateEnhanced) {
        onChange({
            value: newDate ? newDate.toDate() : null,
            valueEnhanced: newDate,
        });
    }
}
