interface IToFloatOptions {
    returnValIfNaN?: number; // return value if "not-a-number"
}

export function toFloat(
    input: string,
    { returnValIfNaN = undefined }: IToFloatOptions = {},
): number {
    const float = parseFloat(input);

    // eslint-disable-next-line no-restricted-globals
    return isNaN(float) ? returnValIfNaN : float;
}
