import { BankAccountType } from '@console/common/models/banking.models';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';

export function getSubmitActionLabelBasedOnMode(mode: TPropertiesFormDisplayMode) {
    return mode === 'add'
        ? 'common.action.add'
        : 'common.action.save';
}

export function getBankAccountTypeSelectItems(): IInputSelectItem<string>[] {
    return Object.values(BankAccountType).map((bankAccountType) => ({
        value: bankAccountType,
        label: bankAccountType,
    }));
}
