import {
    IApiEntityInputSupportingEmbedding,
    IApiEntityListResponseWithPageNr,
    IApiMultiTranslationsLabel,
    IBaseFetchEntityListApiInput,
    TApiEntity,
    TApiEntityCreate,
    TApiEntityId,
} from '../api.models';

export enum PortfolioReportStatus {
    PENDING = 'PENDING',
    OK = 'OK',
    FAILED = 'FAILED',
}

export type TPortfolioReportEmbeddableFields = 'user_id' | 'portfolio_id';

export interface IPortfolioReportEntityData {
    name: IApiMultiTranslationsLabel;
    user_id: TApiEntityId;
    portfolio_id: TApiEntityId; // can be the id of a portfolio or portfolio group
    language: string; // to do - enum?
    start_date: string;
    end_date: string;
    status: PortfolioReportStatus;
    report_uri: string;
}

export interface IFetchPortfolioGroupReportPortfoliosApiInput {
    ids: TApiEntityId[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPortfolioReportAddEntityData
    extends Omit<IPortfolioReportEntityData, 'status' | 'report_uri'> {
}

export interface IPortfolioReportUpdateEntityData
    extends Pick<IPortfolioReportEntityData, 'name'> {
    portfolioReportId: string;
}

export interface IFetchPortfolioReportsApiInput
    extends IBaseFetchEntityListApiInput, IApiEntityInputSupportingEmbedding<TPortfolioReportEmbeddableFields> {
    portfolioId?: string;
    name?: string;
    userId?: string;
}

export type TPortfolioReportsData = IApiEntityListResponseWithPageNr<IPortfolioReportEntityData>;
export type TPortfolioReport = TApiEntity<IPortfolioReportEntityData>;
export type TPortfolioReportCreate = TApiEntityCreate<IPortfolioReportAddEntityData>;
export type TPortfolioReportUpdate = IPortfolioReportUpdateEntityData;

export type TTriggerPortfolioReportFileUrlInput = Pick<IFetchPortfolioReportFileUrlApiInput, 'portfolioReportId'>;

export interface IFetchPortfolioReportFileUrlApiInput {
    portfolioReportId: string;
    format: PortfolioReportFileUrlFormat;
}

export enum PortfolioReportFileUrlFormat {
    JSON = 'json',
    PDF = 'pdf',
    WEB = 'webpage',
}
