import { IActionableObservableStateStore } from '@snipsonian/observable-state/cjs/actionableStore/types';
import { ONE_SECOND_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISingleTooltipFilter } from 'models/state/ui.models';
import { getStore } from 'state';
import { getTooltipKeyAutomaticallyShown, isTooltipDismissedByUser } from 'state/ui/tooltips.selectors';
import { dismissTooltip, setTooltipAutomaticallyShown } from 'state/ui/tooltips.actions';
import waitSeconds from '@snipsonian/core/cjs/promise/waitSeconds';

const NR_OF_SECONDS_BEFORE_SHOWING_NEXT_AUTO_TOOLTIP = 1;
const NR_OF_MILLIS_BEFORE_SHOWING_THE_FIRST_AUTO_TOOLTIP = 1 * ONE_SECOND_IN_MILLIS;
const DEFAULT_TOOLTIP_PRIOTITY = 100;

interface IOptionalStoreForTestPurposes {
    store?: IActionableObservableStateStore<IState, StateChangeNotification>;
}

let afterLiveTimeoutId: number = null;

/**
 * List of all tooltips that are "live" = available on the page
 */
const tooltipMap: {
    [tooltipKey: string]: {
        isLive: boolean;
        priority: number; // 1-... where 1 is the highest priority
    };
} = {};

/**
 * To be called for each tooltip that is a candidate to be shown automatically.
 *
 * It triggers an async mechanism to determine which of these tooltips (if there are more than 1)
 * has to be automatically shown first.
 */
export function markTooltipAsLive({
    tooltipKey,
    priority,
    store = getStore(),
}: ISingleTooltipFilter & IOptionalStoreForTestPurposes & {
    priority?: number;
}) {
    tooltipMap[tooltipKey] = {
        ...tooltipMap[tooltipKey],
        isLive: !isTooltipDismissedByUser(store.getState(), { tooltipKey }),
        priority: priority || DEFAULT_TOOLTIP_PRIOTITY,
    };

    if (afterLiveTimeoutId) {
        window.clearTimeout(afterLiveTimeoutId);
    }

    afterLiveTimeoutId = window.setTimeout(
        () => determineNextTooltipToShowAutomatically({ store }),
        NR_OF_MILLIS_BEFORE_SHOWING_THE_FIRST_AUTO_TOOLTIP,
    );
}

export function unMarkTooltipAsLive({
    tooltipKey,
    store = getStore(),
}: ISingleTooltipFilter & IOptionalStoreForTestPurposes) {
    tooltipMap[tooltipKey].isLive = false;

    if (tooltipKey === getTooltipKeyAutomaticallyShown(store.getState())) {
        clearTooltipKeyAutomaticallyShown({ store });
    }
}

function clearTooltipKeyAutomaticallyShown({
    store,
}: {
    store: IActionableObservableStateStore<IState, StateChangeNotification>;
}) {
    const tooltipKeyAutomaticallyShown = getTooltipKeyAutomaticallyShown(store.getState());

    if (tooltipMap[tooltipKeyAutomaticallyShown]) {
        tooltipMap[tooltipKeyAutomaticallyShown].isLive = false;
    }

    store.dispatch(
        setTooltipAutomaticallyShown({ tooltipKey: null }),
    );
}

/**
 * After clearing the current one, it will wait a time interval
 * before determining the next tooltip - if any - that should be shown automatically.
 * Afterwards it returns a promise containing the new tooltip key to be shown automatically, or null.
 */
export async function clearTooltipKeyAutomaticallyShownAndDetermineTheNextOneIfAny({
    tooltipKey,
    isTooltipNotToBeShownAgain,
    store = getStore(),
}: ISingleTooltipFilter & IOptionalStoreForTestPurposes & {
    /**
     * true = user closed the tooltip by clicking 'Do not show again'
     * false = user closed the tooltip by clicking 'OK'
     */
    isTooltipNotToBeShownAgain: boolean;
}): Promise<string> {
    clearTooltipKeyAutomaticallyShown({ store });

    store.dispatch(
        dismissTooltip({
            tooltipKey,
            isNotToBeShownAgain: isTooltipNotToBeShownAgain,
        }),
    );

    await waitSeconds(NR_OF_SECONDS_BEFORE_SHOWING_NEXT_AUTO_TOOLTIP);

    return Promise.resolve(
        determineNextTooltipToShowAutomatically({ store }),
    );
}

function determineNextTooltipToShowAutomatically({
    store = getStore(),
}: IOptionalStoreForTestPurposes = {}) {
    let tooltipKeyAutomaticallyShown = getTooltipKeyAutomaticallyShown(store.getState());

    if (tooltipKeyAutomaticallyShown) {
        // there is already one
        return tooltipKeyAutomaticallyShown;
    }

    const liveTooltipEntryWithHighestPrio = Object.entries(tooltipMap)
        .sort(([, { priority: priority1 }], [, { priority: priority2 }]) =>
            anyComparerAscending(priority1, priority2))
        .find(([, { isLive }]) => isLive);

    if (liveTooltipEntryWithHighestPrio) {
        // eslint-disable-next-line prefer-destructuring
        tooltipKeyAutomaticallyShown = liveTooltipEntryWithHighestPrio[0];

        store.dispatch(
            setTooltipAutomaticallyShown({ tooltipKey: tooltipKeyAutomaticallyShown }),
        );

        return tooltipKeyAutomaticallyShown;
    }

    return null;
}
