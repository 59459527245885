import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton, { IIconButtonProps } from './IconButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps extends Pick<IIconButtonProps, 'id' | 'onClick' | 'disabled' | 'tooltip'> {}

export default function IconButtonCreate({
    id,
    onClick,
    disabled,
    tooltip,
}: IPublicProps) {
    return (
        <IconButton
            id={id}
            className="IconButton--Create"
            variant="filled"
            color="grey_inverted"
            size="S"
            isCircle={false}
            onClick={onClick}
            disabled={disabled}
            tooltip={tooltip}
            tooltipPlacement="left"
            icon={<AddIcon />}
        />
    );
}
