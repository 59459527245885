/**
 * Convenience object that only groups the d3 functionality that we really use (to reduce import size)
 *
 * Remove functionalities if they are not used anymore !!
 */

/* eslint-disable import/no-extraneous-dependencies */

import { extent, bisector, min, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleTime, scaleBand } from 'd3-scale';
import { select } from 'd3-selection';
import { arc, area, line, pie, curveBasis } from 'd3-shape';
import { interpolate } from 'd3-interpolate';
import 'd3-transition';

export type { AxisScale, AxisDomain } from 'd3-axis';
export type { ScaleLinear, ScaleTime } from 'd3-scale';
export type { CurveFactory, CurveFactoryLineOnly, PieArcDatum } from 'd3-shape';

/* eslint-enable import/no-extraneous-dependencies */

export default {
    extent,
    bisector,
    axisBottom,
    axisLeft,
    scaleLinear,
    scaleTime,
    scaleBand,
    select,
    arc,
    area,
    line,
    pie,
    curveBasis,
    min,
    max,
    interpolate,
};
