import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import {
    extractInstrumentIsinFromOptimizationReason,
    getOptimizationReasonType,
    OptimizationReasonType,
} from '@console/core-api/utils/entities/portfolios/portfolioOptimizationReasonUtils';
import { IInstrumentsMap } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { IOptimizationReason } from '@console/core-api/models/portfolioMgmt/optimization.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS } from 'config/styling/colors';
import { REALISTIC_CASH_VIOLATION_RANGE } from 'config/portfolioMgmt/portfolioOptimization.config';
import { portfolioOptimizationEntity } from 'state/entities/portfolioMgmt/portfolioOptimization';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { getTranslator } from 'state/i18n/selectors';
import { formatPercentage } from 'utils/number/percentageUtils';
import { isNumberInRange } from 'utils/number/range';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import Banner, { MessagePriorityVariant } from 'views/common/layout/MessageBanner';
import TextButton from 'views/common/buttons/TextButton';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import OptimizationNrOfOrders
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioOptimizations/OptimizationNrOfOrders';

export interface IReadableOptimizationReason {
    body: string;
    header: string;
}

const useStyles = makeStyles((theme) => ({
    OptimizationBanner: {
        '& .__optimizationDate': {
            ...mixins.typoBold({ size: 10, color: APP_COLORS.TEXT['500'] }),
            whiteSpace: 'nowrap',
        },

        '& .__bannerContent': {
            ...mixins.flexColTopLeft(),
        },

        '& .__bannerFooter': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),
            paddingTop: theme.spacing(2),
        },

        '& .reasons': {
            ...mixins.flexRowCenterLeft(),
        },

        '& .reason': {
            ...mixins.typo({ color: APP_COLORS.TEXT['800'] }),
            verticalAlign: 'top',
            padding: theme.spacing(0, 2, 1, 2),
            borderBottom: 'none',
        },
        '& .reason:not(:last-child)': {
            ...mixins.borderRight({ color: APP_COLORS.GREY['100'], width: 1 }),
        },
        '& .reason:first-child': {
            paddingLeft: 0,
        },
        '& .reason:last-child': {
            paddingRight: 0,
        },

        '& .reasonBody': {
            ...mixins.typoBold(),
        },
    },
}));

const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail';
const OPTIMIZATION_BANNER_LABEL_PREFIX = `${LABEL_PREFIX}.optimization_banner`;
const ALLOCATION_LABEL_PREFIX = `${LABEL_PREFIX}.allocation`;

function PortfolioOptimizationBanner({ state }: IObserveProps) {
    const portfolioOptimization = portfolioOptimizationEntity.select().data;
    const portfolio = portfolioDetailsEntity.select().data;
    const classes = useStyles();
    const translator = getTranslator(state);

    if (!portfolioOptimization || !portfolio || portfolioOptimization.portfolioId !== portfolio.id) {
        return null;
    }

    const isOptimizationRecommended = optimizationRecommended();
    const readableOptimizationReasons = isOptimizationRecommended
        ? getReadableOptimizationReasons()
        : [];
    const mainOptimizationMessageLabelSuffix = isOptimizationRecommended
        ? 'optimization_needed'
        : 'optimization_optional';

    return (
        <div className={classes.OptimizationBanner}>
            {optimizationExists() && (
                <Banner
                    title={`${OPTIMIZATION_BANNER_LABEL_PREFIX}.main_message.${mainOptimizationMessageLabelSuffix}`}
                    variant={isOptimizationRecommended ? MessagePriorityVariant.Warning : MessagePriorityVariant.Info}
                    renderHeaderContent={renderOptimizationDate}
                    renderBodyContent={renderOptimizationBannerContent}
                />
            )}
        </div>
    );

    function renderOptimizationDate() {
        return (
            <div className="__optimizationDate">
                {formatDateRelativeToNow({ date: portfolioOptimization.creation_datetime })}
            </div>
        );
    }

    function renderOptimizationBannerContent() {
        const { nrOfBuys, nrOfSells } = portfolioOptimization.portfolio_update;

        return (
            <div className="__bannerContent">
                {isOptimizationRecommended && <OptimizationReasons />}

                <div className="__bannerFooter">
                    <OptimizationNrOfOrders
                        status={portfolioOptimization.status}
                        nrOfBuys={nrOfBuys}
                        nrOfSells={nrOfSells}
                    />

                    <TextButton
                        id="optimizations-banner-view-detail"
                        label={`${OPTIMIZATION_BANNER_LABEL_PREFIX}.actions.to_optimization_comparison`}
                        color="grey_inverted"
                        size="M"
                        onClick={() => {
                            redirectTo({
                                routeKey: ROUTE_KEY.R_PORTFOLIO_OPTIMIZATION_DETAIL,
                                params: {
                                    portfolioId: portfolioOptimization.portfolioId,
                                    optimizationId: portfolioOptimization.optimizationId,
                                },
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    function OptimizationReasons() {
        return (
            <Table className="reasons">
                <TableBody>
                    <TableRow>
                        {readableOptimizationReasons.map((reason) => (
                            <TableCell
                                key={`optimization_reason_${reason.header}`}
                                className="reason"
                            >
                                <div className="reasonHeader">
                                    {reason.header}
                                </div>
                                <div className="reasonBody">
                                    {reason.body}
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    /**
     * Only when status RECOMMENDED or NOT_RECOMMENDED, then the portfolio_update will be an object containing
     * something --> then an optimization is possible (but that optimization does not have to be necessary).
     */
    function optimizationExists(): boolean {
        return optimizationRecommended()
            || optimizationNotRecommendedButContainsOrders();
    }

    function optimizationRecommended(): boolean {
        if (portfolioOptimization.status === EnhancedOptimizationStatus.RECOMMENDED) {
            return true;
        }

        return false;
    }

    function optimizationNotRecommendedButContainsOrders(): boolean {
        if (portfolioOptimization.status === EnhancedOptimizationStatus.NOT_RECOMMENDED) {
            if (isObjectWithProps(portfolioOptimization.portfolio_update?.orders)) {
                return true;
            }
        }

        return false;
    }

    function getPortfolioUpdate() {
        return portfolioOptimization?.portfolio_update;
    }

    function getReadableOptimizationReasons(): IReadableOptimizationReason[] {
        const { reasons } = getPortfolioUpdate();

        if (!areOptimizationReasonsRealistic(reasons)) {
            return [{
                header: translator(`${OPTIMIZATION_BANNER_LABEL_PREFIX}.cash_operation`),
                body: null,
            }];
        }

        return reasons.reduce(
            (accumulator, reason) => {
                const readableReason = getReadableOptimizationReason({
                    reason,
                    translator,
                    instrumentMap: portfolioOptimization.instrumentMap,
                });
                if (readableReason) {
                    accumulator.push(readableReason);
                }
                return accumulator;
            },
            [] as IReadableOptimizationReason[],
        );
    }

    function areOptimizationReasonsRealistic(reasons: IOptimizationReason[]): boolean {
        const cashViolationReason = reasons.find(
            (reason) => getOptimizationReasonType(reason) === OptimizationReasonType.CASH_HOLDINGS_VIOLATION,
        );
        if (cashViolationReason && !isNumberInRange(
            cashViolationReason.portfolio_update_constraint_value,
            REALISTIC_CASH_VIOLATION_RANGE,
        )) {
            return false;
        }

        return true;
    }
}

export default observe(
    [StateChangeNotification.PORTFOLIO_DETAILS_DATA, StateChangeNotification.PORTFOLIO_OPTIMIZATION],
    PortfolioOptimizationBanner,
);

export function getReadableOptimizationReason({
    reason,
    translator,
    instrumentMap,
}: {
    reason: IOptimizationReason;
    translator: TTranslator;
    instrumentMap: IInstrumentsMap;
}): IReadableOptimizationReason {
    const reasonType = getOptimizationReasonType(reason);

    // Reason 1: The objective function has been improved sufficiently
    if (reasonType === OptimizationReasonType.MIN_OPTIMALITY_IMPROVEMENT) {
        return {
            header: translator(`${OPTIMIZATION_BANNER_LABEL_PREFIX}.min_optimality_improvement`),
            body: formatUpdateConstraintValue({
                value: reason.portfolio_update_constraint_value,
                translator,
                includeTooHighOrTooLow: false,
            }),
        };
    }

    // Reason 2: Portfolio constraint violations
    if (reasonType === OptimizationReasonType.MAX_CONSTRAINT_VIOLATIONS) {
        const readableReason: IReadableOptimizationReason = { body: null, header: null };
        const constraintIdParts = reason.portfolio_constraint_id.split('.');

        // Set the correct header title
        switch (constraintIdParts[0]) {
            case 'asset_classes':
            case 'bond_types':
            case 'sectors': {
                readableReason.header = translator(
                    `${OPTIMIZATION_BANNER_LABEL_PREFIX}.${constraintIdParts[0]}.title`,
                );
                break;
            }
            case 'holdings': {
                if (constraintIdParts[1] === 'cash') {
                    /* the second part (constraintIdParts[1]) will be "bonds" or "stocks" */
                    readableReason.header = translator(
                        `${OPTIMIZATION_BANNER_LABEL_PREFIX}.${constraintIdParts[0]}.${constraintIdParts[1]}.title`,
                    );
                } else {
                    readableReason.header = translator({
                        msg: `${OPTIMIZATION_BANNER_LABEL_PREFIX}.${constraintIdParts[0]}.instrument`,
                        placeholders: {
                            isin: constraintIdParts[1],
                        },
                    });
                }
                break;
            }
            case 'regions': {
                /* the second part (constraintIdParts[1]) will be "bonds" or "stocks" */
                readableReason.header = translator(
                    `${OPTIMIZATION_BANNER_LABEL_PREFIX}.${constraintIdParts[0]}.${constraintIdParts[1]}.title`,
                );
                break;
            }

            default: {
                readableReason.header = translator(
                    `${OPTIMIZATION_BANNER_LABEL_PREFIX}.unknown_portfolio_constraint`,
                );
            }
        }

        if (constraintIdParts[0] !== 'holdings') {
            // Append the rest of the header
            readableReason.header = readableReason.header.concat(` '${translator(
                `${ALLOCATION_LABEL_PREFIX}.${reason.portfolio_constraint_id}`,
            )}'`);
        }

        // Add the body
        readableReason.body = formatUpdateConstraintValue({
            value: reason.portfolio_update_constraint_value,
            translator,
        });

        return readableReason;
    }

    // Reason 3: Cash holdings violations
    if (reasonType === OptimizationReasonType.CASH_HOLDINGS_VIOLATION) {
        return {
            header: translator(`${OPTIMIZATION_BANNER_LABEL_PREFIX}.${reason.portfolio_constraint_id}`),
            body: formatUpdateConstraintValue({
                value: reason.portfolio_update_constraint_value,
                translator,
            }),
        };
    }

    // Reason 4: Instrument doesn't exist in the universe
    if (reasonType === OptimizationReasonType.INSTRUMENT_NOT_IN_UNIVERSE) {
        const instrumentIsin = extractInstrumentIsinFromOptimizationReason(reason);
        const instrumentName = instrumentMap
            ? instrumentMap[instrumentIsin]?.name
            : null;

        return {
            header: `${translator(
                `${OPTIMIZATION_BANNER_LABEL_PREFIX}.instrument_not_in_universe`,
            )}:`,
            body: instrumentName || instrumentIsin,
        };
    }

    return null;
}

function formatUpdateConstraintValue({
    value,
    translator,
    includeTooHighOrTooLow = true,
}: {
    value: number;
    translator: TTranslator;
    includeTooHighOrTooLow?: boolean; // default true
}): string {
    const formattedValue = formatPercentage(Math.abs(value * 100), { nrOfDecimals: 0 });

    if (!includeTooHighOrTooLow) {
        return formattedValue;
    }

    const tooHighOrTooLowSuffix = value > 0
        ? 'too_high'
        : 'too_low';

    return translator({
        msg: `${OPTIMIZATION_BANNER_LABEL_PREFIX}.comparison.${tooHighOrTooLowSuffix}`,
        placeholders: {
            percentage: formattedValue,
        },
    });
}
