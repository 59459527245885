import { roundFloat, roundFloat2 } from '@console/common/utils/float/roundFloat';
import { IBarChartOptions, IBarGroupOptions } from 'models/barChart.models';
import { IChartSizeWithoutAxis } from 'models/chart.models';

export function determineBarChartSizeWithoutAxis({
    dimensions,
    axis,
}: IBarChartOptions): IChartSizeWithoutAxis {
    return {
        chartWidth: dimensions.maxWidth - axis.y.width - axis.x.marginRight,
        chartHeight: dimensions.maxHeight - axis.y.marginTop - axis.x.height,
    };
}

interface IBarChartPaddings {
    paddingInner: number;
    paddingOuter: number;
    groupsPaddingInner: number;
}

export function calculateBarChartPaddingsByAbsoluteBarWidth({
    chartWidth,
    nrOfBarGroups,
    nrOfAdjacentBarsWithinEachGroup,
    barWidth,
    innerVsOuterPaddingRatio,
    groupInnerVsOuterPaddingRatio,
}: {
    chartWidth: number;
    /** = nr of XAxis ticks */
    nrOfBarGroups: number;
    /** bars that are stacked on another one do not count! */
    nrOfAdjacentBarsWithinEachGroup: number;
    barWidth: number;
    innerVsOuterPaddingRatio: number;
    /** for the padding WITHIN a barGroup (so between adjacent bars within a group) */
    groupInnerVsOuterPaddingRatio: number;
}): IBarChartPaddings {
    const widthOfAllBars = nrOfBarGroups * nrOfAdjacentBarsWithinEachGroup * barWidth;
    const widthOfChartWithoutBars = chartWidth - widthOfAllBars;

    const nrOfOuterSpaces = 2; // one on the left and 1 on the right
    const nrOfInnerSpacesBetweenGroups = nrOfBarGroups - 1; // between the bar groups
    const nrOfInnerSpacesWithinGroups = nrOfBarGroups * (nrOfAdjacentBarsWithinEachGroup - 1);

    const spaceUnitsByRatio =
        (nrOfOuterSpaces * 1)
        + (nrOfInnerSpacesBetweenGroups * innerVsOuterPaddingRatio)
        + (nrOfInnerSpacesWithinGroups * groupInnerVsOuterPaddingRatio);
    const withPerSpaceUnit = widthOfChartWithoutBars / spaceUnitsByRatio;
    const widthOfAllOuterSpaces = withPerSpaceUnit * nrOfOuterSpaces * 1;
    const widthOfAllInnerSpaces = withPerSpaceUnit * nrOfInnerSpacesBetweenGroups * innerVsOuterPaddingRatio;
    // eslint-disable-next-line max-len
    const widthOfSingleGroupInnerSpaces = withPerSpaceUnit * (nrOfAdjacentBarsWithinEachGroup - 1) * groupInnerVsOuterPaddingRatio;
    const widthOfSingleBarGroup = (chartWidth - widthOfAllOuterSpaces - widthOfAllInnerSpaces) / nrOfBarGroups;

    return {
        paddingInner: roundFloat2(widthOfAllInnerSpaces / chartWidth),
        paddingOuter: roundFloat2(widthOfAllOuterSpaces / (chartWidth - widthOfAllInnerSpaces)),
        groupsPaddingInner: roundFloat2(widthOfSingleGroupInnerSpaces / widthOfSingleBarGroup),
    };
}

export function determineBarSizesForVerticalBarChart({
    chartWidth,
    nrOfBarGroups,
    nrOfAdjacentBarsWithinEachGroup,
    bars = {},
}: Pick<IBarChartOptions, 'bars'> & {
    chartWidth: number;
    nrOfBarGroups: number;
    nrOfAdjacentBarsWithinEachGroup: number;
}) {
    const {
        paddingInner = 0.1,
        paddingOuter = 0,
        groups,
    } = bars;

    validateBartChartRange(paddingInner);
    validateBartChartRange(paddingOuter);

    const paddingInnerTotalWidth = roundFloat2(chartWidth * paddingInner);
    const rangeWithoutInnerPaddingWidth = roundFloat2(chartWidth - paddingInnerTotalWidth);
    const paddingOuterTotalWidth = roundFloat2(rangeWithoutInnerPaddingWidth * paddingOuter);
    const rangeWithoutInnerAndOuterPaddingWidth = roundFloat2(rangeWithoutInnerPaddingWidth - paddingOuterTotalWidth);
    const barGroupWidth = roundFloat2(rangeWithoutInnerAndOuterPaddingWidth / nrOfBarGroups);
    /* one less inner padding than there are ticks */
    const paddingInnerWidth = nrOfBarGroups > 1
        ? roundFloat(paddingInnerTotalWidth / (nrOfBarGroups - 1))
        : 0;
    /* one left and one right */
    const paddingOuterWidth = roundFloat(paddingOuterTotalWidth / 2);

    return {
        paddingInnerWidth,
        paddingOuterWidth,
        barGroupSize: {
            barGroupWidth,
            ...determineBarGroupSizesForVerticalBarChart({
                nrOfAdjacentBarsWithinEachGroup,
                barGroupWidth,
                barGroupOptions: groups,
            }),
        },
        xAxisPaddingOuterWidth: roundFloat(paddingOuterWidth + (barGroupWidth / 2)),
    };
}

function determineBarGroupSizesForVerticalBarChart({
    nrOfAdjacentBarsWithinEachGroup,
    barGroupWidth,
    barGroupOptions,
}: {
    nrOfAdjacentBarsWithinEachGroup: number;
    barGroupWidth: number;
    barGroupOptions: IBarGroupOptions;
}): { singleBarWidth: number; paddingInnerWidth: number } {
    if (nrOfAdjacentBarsWithinEachGroup === 1) {
        return {
            singleBarWidth: barGroupWidth,
            paddingInnerWidth: 0,
        };
    }

    const {
        paddingInner = 0,
    } = barGroupOptions;

    validateBartChartRange(paddingInner);

    const barGroupPaddingInnerTotalWidth = roundFloat2(barGroupWidth * paddingInner);
    const barGroupWidthWithoutInnerPadding = roundFloat2(barGroupWidth - barGroupPaddingInnerTotalWidth);

    return {
        singleBarWidth: roundFloat(barGroupWidthWithoutInnerPadding / nrOfAdjacentBarsWithinEachGroup),
        /* one less inner padding than there are bars within the group */
        paddingInnerWidth: nrOfAdjacentBarsWithinEachGroup > 1
            ? roundFloat(barGroupPaddingInnerTotalWidth / (nrOfAdjacentBarsWithinEachGroup - 1))
            : 0,
    };
}

function validateBartChartRange(range: number) {
    if (range < 0 || range > 1) {
        throw new Error(`The input range should be in the 0 to 1 range, but received: ${range}`);
    }
}
