import React from 'react';
import { policyDetailsEntity, triggerPatchPolicyDetails } from 'state/entities/portfolioMgmt/policyDetails';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function PolicyAccessibleBy() {
    const policyDetailsData = policyDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={policyDetailsData}
            nameForDisplay={policyDetailsData.name}
            asyncEntityPatchTrigger={(values) => triggerPatchPolicyDetails((currentPolicy) => {
                /* eslint-disable no-param-reassign */
                currentPolicy.readable_by = values.readable_by;
                currentPolicy.modifiable_by = values.modifiable_by;
                /* eslint-enable no-param-reassign */
            })}
        />
    );
}
