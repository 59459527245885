import React from 'react';
import Wizard, { IWizardStep } from 'views/common/flow/Wizard';
import WizardBackground from 'views/assets/img/illustrations/portfolio_groups.svg';
import AddPage from 'views/common/add/AddPage';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { IExtendedInputFormContext, IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import {
    portfolioGroupDetailsEntity,
    triggerCreatePortfolioGroup,
} from 'state/entities/portfolioMgmt/portfolioGroupDetails';
import { IPortfolioGroupAddEntityData } from '@console/core-api/models/portfolioMgmt/portfolioGroups.models';
import { makeStyles } from 'views/styling';
import { mixins } from 'views/assets/cssInJs/mixins';
import { getDefaultTenantCurrency } from 'state/appConfig/selectors';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import {
    addPortfolioGroupStepOneSchema,
    addPortfolioGroupStepTwoSchema,
} from '../PortfolioGroupDetail/portfolioGroupDetailsSchema';
import { IPortfolioGroupFormValues } from '../shared/types';
import PortfolioGroupPortfoliosFormContent from '../shared/PortfolioGroupPortfoliosFormContent';
import PortfolioGroupPropertiesFormFields from '../shared/PortfolioGroupPropertiesFormFields';

const FIELDS_LABEL_PREFIX = 'portfolio_mgmt.portfolio_groups.detail';

const useStyles = makeStyles((theme) => ({
    PortfolioSelect: {
        width: '100%',
        padding: theme.spacing(4),

        '& .__subtitle': {
            marginBottom: theme.spacing(4),
            ...mixins.typoBold({ size: 16 }),
        },
    },
}));

export default function PortfolioGroupAdd() {
    const initialValues: IPortfolioGroupFormValues = {
        name: '',
        currency: getDefaultTenantCurrency(),
        portfolioIds: [],
    };

    const classes = useStyles();

    return (
        <AddPage
            entity={{
                notifications: [StateChangeNotification.PORTFOLIO_GROUP_DETAILS_DATA],
                asyncEntitySelector: portfolioGroupDetailsEntity.select,
            }}
            titleLabel="portfolio_mgmt.portfolio_groups.add.title"
            noPadding
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <Wizard<IPortfolioGroupFormValues>
                name="add-portfolio_group-wizard"
                backgroundImagePath={WizardBackground}
                backgroundImageSize={258}
                cancel={{
                    onCancel: redirectToList,
                }}
                steps={getWizardSteps()}
                labelPrefix={FIELDS_LABEL_PREFIX}
                initialValues={initialValues}
                onWizardComplete={createPortfolioGroup}
                completeWizardActionLabel="portfolio_mgmt.portfolio_groups.add.submit"
            />
        </AddPage>
    );

    function getWizardSteps(): IWizardStep<IPortfolioGroupFormValues>[] {
        return [
            {
                id: 'one',
                schema: addPortfolioGroupStepOneSchema,
                renderFormFields: PortfolioGroupPropertiesFormFields,
            },
            {
                id: 'two',
                schema: addPortfolioGroupStepTwoSchema,
                renderFormFields: renderStepTwoFormFields,
                className: classes.PortfolioSelect,
            },
        ];
    }

    function renderStepTwoFormFields(formContext: IExtendedInputFormContext<IPortfolioGroupFormValues>) {
        return (
            <>
                <p className="__subtitle">
                    <Translate
                        msg={`${FIELDS_LABEL_PREFIX}.fields.portfolios.select`}
                        placeholders={{ name: formContext.fields.name.value }}
                    />
                </p>
                <PortfolioGroupPortfoliosFormContent formContext={formContext} />
            </>
        );
    }

    function createPortfolioGroup({ values }: IOnSubmitProps<IPortfolioGroupFormValues>) {
        return triggerCreatePortfolioGroup(
            enrichApiEntityDataForCreate<IPortfolioGroupAddEntityData>({
                name: values.name,
                currency: values.currency,
                portfolio_ids: values.portfolioIds,
            }),
        );
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST,
        });
    }
}
