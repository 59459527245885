import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IEntityWrapperProps, initEntityWrapper, IRenderDataProps } from 'views/common/widget/EntityWrapper';

interface IPublicProps<EntityData = unknown>
    extends IEntityWrapperProps<EntityData> {
    notifications: StateChangeNotification[];
    showIfAnyAsyncOperationBusy?: boolean; // default true
}

export default function ShowIfEntity<EntityData = unknown>({
    notifications,
    shouldRenderIfNoDataSet = false,
    fetch = null, // by default no fetch errors are shown
    showIfAnyAsyncOperationBusy = true,
    renderData: renderDataInput,
    ...otherEntityWrapperProps
}: IPublicProps<EntityData>) {
    const EntityWrapper = initEntityWrapper({
        notifications,
    });

    return (
        <EntityWrapper
            shouldRenderIfNoDataSet={shouldRenderIfNoDataSet}
            fetch={fetch}
            {...otherEntityWrapperProps}
            renderData={renderIfEntityDataAvailable}
        />
    );

    function renderIfEntityDataAvailable(props: IRenderDataProps<EntityData>) {
        const { isAnyAsyncOperationBusy } = props;
        if (!showIfAnyAsyncOperationBusy && isAnyAsyncOperationBusy) {
            return null;
        }

        return renderDataInput(props);
    }
}
