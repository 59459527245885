import isSet from '@snipsonian/core/cjs/is/isSet';
import packageJson from '../../package.json';

/**
 * Only used when the authentication config is not injected in the global var CONSOLE_CONFIG
 * in index.html (coming from terraform/helm environment variables),
 * so normally only when on local dev.
 */

const DEFAULT_AUTH_ENV = packageJson?.localDev?.backendEnv || 'DEV_CBD';

const commonAuthConfig = {
    signInPolicy: 'B2C_1A_CONSOLE_signin',
    passwordResetPolicy: 'B2C_1A_CONSOLE_PasswordReset',
};

const IDP_PROVIDERS_WITHOUT_RESET_PW_LINK: TConsoleIdpProvider[] = [
    /* For IDCS, the reset password link is within it's login page itself, so we have to remove it from our own page */
    'idcs',
    'monosign',
    'azureAD',
];

let DEFAULT_AUTHENTICATION_CONFIG: {
    [env: string]: TConsoleConfigAuthentication;
} = {
    DEV_DEMO: {
        ...commonAuthConfig,
        signInPolicy: 'B2C_1A_DEMO_CONSOLE_signin',
        passwordResetPolicy: 'B2C_1A_DEMO_CONSOLE_PasswordReset',
        clientId: 'ffea581c-95a2-4ce5-a08d-1611c7246d36',
        tenantName: 'investsuitedev',
    },
};

if (process.env.NODE_ENV !== 'production') {
    /* this code is only included in the development builds on localhost */

    DEFAULT_AUTHENTICATION_CONFIG = {
        ...DEFAULT_AUTHENTICATION_CONFIG,
        FTR_CBD: {
            ...commonAuthConfig,
            signInPolicy: 'B2C_1A_CBD_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_CBD_CONSOLE_PasswordReset',
            clientId: 'f6ef69d9-6e48-4461-a65e-21d94fcff78c',
            tenantName: 'ivsapp',
        },
        DEV_BDP: {
            ...commonAuthConfig,
            signInPolicy: 'B2C_1A_BDP_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_BDP_CONSOLE_PasswordReset',
            clientId: 'ffea581c-95a2-4ce5-a08d-1611c7246d36',
            tenantName: 'investsuitedev',
        },
        DEV_CBD: {
            ...commonAuthConfig,
            clientId: 'fdc33064-88db-4f09-940b-526645da1d64',
            tenantName: 'investsuitecbddev',
        },
        DEV_ODEA: {
            ...commonAuthConfig,
            signInPolicy: 'B2C_1A_ODEABANK_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_ODEABANK_CONSOLE_PasswordReset',
            clientId: 'ffea581c-95a2-4ce5-a08d-1611c7246d36',
            tenantName: 'investsuitedev',
        },
        TST_ARC: {
            provider: 'idcs',
            clientId: '3acdd366deeb465a8228eed90dca9194',
            authority: 'https://idcs-0ba3a32b7c574cb6bbaf1252fd09f943.identity.oraclecloud.com',
        },
        TST_CBD: {
            ...commonAuthConfig,
            clientId: '9c8dcb15-f17c-4dcb-a9d0-7690aa78526d',
            tenantName: 'investsuitecbdtst',
        },
        TST_DEMO: {
            ...commonAuthConfig,
            signInPolicy: 'B2C_1A_DEMO_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_DEMO_CONSOLE_PasswordReset',
            clientId: '9bf0d82c-7bff-4d81-b865-47d0453b0ce9',
            tenantName: 'investsuitetst',
        },
        TST_ODEA: {
            ...commonAuthConfig,
            signInPolicy: 'B2C_1A_ODEABANK_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_ODEABANK_CONSOLE_PasswordReset',
            clientId: '9bf0d82c-7bff-4d81-b865-47d0453b0ce9',
            tenantName: 'investsuitetst',
        },
        UAT_ARC: {
            provider: 'idcs',
            clientId: '9eeaeaebeb524675a8085fa91caa9f51',
            authority: 'https://idcs-d6387025ba3944e48bb406569decbf4f.identity.oraclecloud.com',
        },
        UAT_BDP: {
            provider: 'azureAD',
            clientId: 'fb892e94-6b77-4fc7-8b01-2515c42f3d91',
            authority: 'https://login.microsoftonline.com/c26c2a32-78d1-474e-8d40-ba7bf5ad2172/v2.0',
            scope: 'api://console-web-app/read openid',
        },
        UAT_CBD: {
            ...commonAuthConfig,
            clientId: '1126d42f-601b-41a4-86c5-0b279d83a9f6',
            tenantName: 'investsuitecbduat',
        },
        UAT_DPAM: {
            ...commonAuthConfig,
            clientId: '23a6a246-5f01-453b-9d0d-d5a9f6afed20',
            tenantName: 'investsuiteuat',
            signInPolicy: 'B2C_1A_DPAM_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_DPAM_CONSOLE_PasswordReset',
        },
        UAT_LS: {
            ...commonAuthConfig,
            clientId: '23a6a246-5f01-453b-9d0d-d5a9f6afed20',
            tenantName: 'investsuiteuat',
            signInPolicy: 'B2C_1A_LEOSTEVENS_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_LEOSTEVENS_CONSOLE_PasswordReset',
        },
        UAT_ODEA: {
            provider: 'monosign',
            clientId: 'fcc55bdb-f828-47a2-a383-9a77b855322e',
            authority: 'https://account.odeabank.com.tr',
        },
        UAT_TODAYS: {
            ...commonAuthConfig,
            clientId: '23a6a246-5f01-453b-9d0d-d5a9f6afed20',
            tenantName: 'investsuiteuat',
            signInPolicy: 'B2C_1A_TODAYSGROUP_CONSOLE_signin',
            passwordResetPolicy: 'B2C_1A_TODAYSGROUP_CONSOLE_PasswordReset',
        },
        PRD_CBD: {
            ...commonAuthConfig,
            clientId: '6819e793-c9ee-4353-9412-bbdc1c3e8f76',
            tenantName: 'investsuitecbd',
        },
    };
}

export function getConsoleConfigAuthentication(): TConsoleConfigAuthentication {
    if (isSet(window?.CONSOLE_CONFIG?.authentication?.clientId)) {
        return window.CONSOLE_CONFIG.authentication;
    }

    return DEFAULT_AUTHENTICATION_CONFIG[DEFAULT_AUTH_ENV];
}

export function isConsoleConfigAuthenticationOtherTypeGuard(
    input: TConsoleConfigAuthentication,
): input is IConsoleConfigAuthenticationOther {
    if (isSet((input as IConsoleConfigAuthenticationOther)?.provider)) {
        return true;
    }

    return false;
}

export function getConsoleIdpProvider(): TConsoleIdpProvider {
    const authConfig = getConsoleConfigAuthentication();

    if (isConsoleConfigAuthenticationOtherTypeGuard(authConfig)) {
        return authConfig.provider;
    }

    return 'adb2c';
}

export const shouldShowResetPasswordFlow = !IDP_PROVIDERS_WITHOUT_RESET_PW_LINK.includes(getConsoleIdpProvider());
