import React, { useState } from 'react';
import { DEFAULT_POLICY_SIMULATION_TAB_KEY, POLICY_SIMULATION_TAB_KEY } from 'config/tabs.config';
import Tabs, { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { makeStyles } from 'views/styling';
import ContentTitle from 'views/common/layout/ContentTitle';
import PolicySimulationExpectedPerformance
    from './PolicySimulationExpectedPerformance/PolicySimulationExpectedPerformance';

const LABEL_PREFIX = 'portfolio_mgmt.policies.detail.configuration.simulation';

const POLICY_SIMULATION_TABS: ITabItem[] = [{
    key: POLICY_SIMULATION_TAB_KEY.EXPECTED_PERFORMANCE,
    label: 'portfolio_mgmt.policies.detail.configuration.simulation.tabs.expected_performance.title',
    component: PolicySimulationExpectedPerformance,
    wrapWithContentBox: false,
}];

const useStyles = makeStyles((theme) => ({
    PolicySimulation: {
        '& .__title': {
            // paddingLeft: theme.spacing(1),
        },
        '& .__tabs': {
            paddingTop: theme.spacing(5),
        },
    },
}));

export default function PolicySimulation() {
    const classes = useStyles();
    const [activePolicySimulationTabKey, setActivePolicySimulationTabKey] = useState(DEFAULT_POLICY_SIMULATION_TAB_KEY);

    return (
        <div className={classes.PolicySimulation}>
            <ContentTitle
                label={`${LABEL_PREFIX}.title`}
                variant="section"
                className="__title"
            />
            <Tabs
                items={POLICY_SIMULATION_TABS}
                activeTabKey={activePolicySimulationTabKey}
                onTabChange={tabChanged}
                className="__tabs"
                showSingleTab
            />

        </div>
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        setActivePolicySimulationTabKey(tabKey);
    }
}
