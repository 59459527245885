/**
 * When importing an excel file to update the portfolio holdings,
 * these are the expected column headers.
 */
export const UPLOAD_HOLDINGS_EXCEL_HEADER_NAMES = ['ISIN', 'Total Units'];

/** When the ISIN column contains the word 'CASH', then the number value will be used for the cash position */
export const CASH_CELL_NAME = 'CASH';

export const INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS = 1;
