/**
 * The server-side fastify server expects path params in the format of '/:pathParam/'
 * while the client-side requestWrapper expects them in the format '/{pathParam}'.
 * --> here we use the '/:pathParam/' format, which will be replaced automatically in the requestWrapper !!!
 */
export enum ReportingApiPath {
    REPORTS_INFO = '/reports/info/',

    REPORTS_ROBO_AUM = '/reports/robo/aum/',
    REPORTS_ROBO_CLIENT_CASH_TRANSFER = '/reports/robo/client_cash_transfer/',
    REPORTS_ROBO_CLIENT_GROWTH = '/reports/robo/client_growth/',

    REPORTS_SELF_AUM = '/reports/self/aum/',
    REPORTS_SELF_CLIENT_CASH_TRANSFER = '/reports/self/client_cash_transfer/',
    REPORTS_SELF_CLIENT_GROWTH = '/reports/self/client_growth/',
}
