import isObject from '@snipsonian/core/cjs/is/isObject';
import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import cloneDeep from 'lodash/cloneDeep';
import { TObjectWithProps } from '../../models/genericTypes.models';

export function mapArrayLikeObjectToArray<Value = unknown>(
    object: TObjectWithProps<Value>,
    options?: { addKeyAsId: boolean; },
): Value[] {
    return Object.entries(object).map(([key, value]) => {
        if (options?.addKeyAsId && isObject(value)) {
            return { ...value, id: key };
        }
        return value;
    });
}

/**
 * E.g. reshifts array like object with "holes". { 0: 'a', 2: 'b' } will result in { 0: 'a', 1: 'b' }
 */
export function reshiftArrayLikeObject<Value = unknown>(
    object: TObjectWithProps<Value>,
) {
    if (!object) {
        return object;
    }
    const newObject: TObjectWithProps<Value> = {};
    Object.keys(object).forEach((key, i) => {
        newObject[i.toString()] = object[key];
    });
    return newObject;
}

/**
 * E.g. add value to end of an array like object.
 */
export function appendValueToArrayLikeObject<Value = unknown>(
    object: TObjectWithProps<Value>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueToAppend: any,
) {
    const newObject = cloneDeep(object);
    // eslint-disable-next-line no-param-reassign
    newObject[Object.keys(object).length] = valueToAppend;
    return newObject;
}

/**
 * E.g. replaces the value of all keys with the specified key name with the given value
 */
export function replaceValueForAllNestedKeyNamesInObject<Value = unknown>({
    object,
    keyName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value,
}: {
    object: TObjectWithProps,
    keyName: string,
    value: Value,
}) {
    return Object.keys(object).reduce((modifiedObject, currentKey) => {
        if (currentKey === keyName) {
            // eslint-disable-next-line no-param-reassign
            modifiedObject[currentKey] = value;
        } else if (isObjectPure(object[currentKey])) {
            // eslint-disable-next-line no-param-reassign
            modifiedObject[currentKey] = replaceValueForAllNestedKeyNamesInObject({
                object: object[currentKey],
                keyName,
                value,
            });
        } else {
            // eslint-disable-next-line no-param-reassign
            modifiedObject[currentKey] = object[currentKey];
        }

        return modifiedObject;
    }, {} as TObjectWithProps);
}

export type TKeyMap = { [key: string]: boolean };

export function mapKeysToKeyMap(keys: string[]): TKeyMap {
    return keys.reduce(
        (accumulator, key) => {
            accumulator[key] = true;
            return accumulator;
        },
        {} as TKeyMap,
    );
}
