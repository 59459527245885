import React, { useState } from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { isFetchBusy } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { IPolicySimulationFuturePerformanceMarkedDate } from 'models/ui/policySimulation.ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPolicyPerformanceFuture } from '@console/core-api/models/portfolioMgmt/policy.models';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { TPolicyOptimizationApiResponse } from '@console/bff/models/policies/policyOptimization.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { APP_COLORS } from 'config/styling/colors';
import { CHART } from 'config/styling/chart';
import { NO_DATA_CHARACTER } from 'config/styling/typography';
import {
    policySimulationEntity,
} from 'state/entities/portfolioMgmt/policySimulation';
import { getPolicySimulationPageVars } from 'state/ui/uiPages.selectors';
import {
    updatePolicySimulationPerformancePageVars,
} from 'state/ui/uiPages.actions';
import {
    policyFuturePerformanceEntity,
} from 'state/entities/portfolioMgmt/policyPerformanceFuture';
import { getStore } from 'state';
import calculateExpectedReturn from 'utils/entities/portfolioMgmt/calculateExpectedReturn';
import { makeStyles, mixins } from 'views/styling';
import { initEntityWrapper, IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { IObserveProps, observe } from 'views/observe';
import ContentTitle from 'views/common/layout/ContentTitle';
import TextButton from 'views/common/buttons/TextButton';
import Modal from 'views/common/layout/Modal';
import SpinnerOverlay from 'views/common/loading/SpinnerOverlay';
import PolicySimulationListOfInstruments from './PolicySimulationListOfInstruments';
import PolicySimulationExpectedPerformanceChart from './PolicySimulationExpectedPerformanceChart';
import PolicySimulationFilters from './PolicySimulationFilters';
import { getPolicyBaseCurrency } from './InstrumentPerformancePast';

const TRANSLATION_PREFIX = 'portfolio_mgmt.policies.detail.configuration.simulation';
const SECTIONS_PREEFIX = `${TRANSLATION_PREFIX}.tabs.expected_performance.section`;

const useStyles = makeStyles((theme) => ({
    RunSimulationSection: {
        '&': {
            padding: theme.spacing(0, 0, 3, 0),
        },
        '& .entityChoice': {
            width: 219,
            fontWeight: 'bold',
            padding: theme.spacing(0, 3, 2.5, 0),
        },
        '& .expectedReturnsToggle': {
            padding: theme.spacing(1, 0, 0, 0),
        },
        '& .entityChoice .entityChoiceSelect': {
            padding: theme.spacing(0.5, 0, 0, 0),
        },
        '& .performanceChart': {
            ...mixins.borderTopGrey(),
            marginTop: theme.spacing(2),
        },
        '& .runSimulationButton': {
            ...mixins.flexRowCenterRight(),
            ...mixins.widthMax(),
        },
        '& .bottomSimulationControls': {
            ...mixins.flexRow({ alignMain: 'space-between' }),
        },
        '& .displayCurrency': {
            minWidth: 200,
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        '& .policyCircle': {
            height: 13,
            width: 13,
            borderRadius: '50%',
            borderColor: APP_COLORS.PRIMARY[500],
            backgroundColor: APP_COLORS.PRIMARY[500],
            translate: 'transform(0px, -2px)',
            margin: 5,
        },
        '& .policyInfo': {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(6),
            ...mixins.flexRow(),
        },
        '& .benchmarkCircle': {
            height: 13,
            width: 13,
            borderRadius: '50%',
            borderColor: CHART.BENCHMARK_COLOR,
            backgroundColor: CHART.BENCHMARK_COLOR,
            margin: 5,
            translate: 'transform(0px, -2px)',
        },
        '& .benchmarkInfo': {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            ...mixins.flexRow(),
        },
    },
    ExpectedPerformance: {
        ...mixins.widthMax(),
        '&': {
            padding: theme.spacing(5, 0, 5.5, 0),
        },
        '& .simulationCard': {
            padding: theme.spacing(2, 5, 2, 0),
        },
        '& .simulationCard .simulationReturn': {
            fontWeight: 'bold',
            color: APP_COLORS.PRIMARY['500'],
            padding: theme.spacing(0.5, 0, 0, 0),
        },
        '& .viewAsChartButton': {
            ...mixins.flexRowCenterRight(),
            paddingRight: theme.spacing(3),
        },
    },
}));

function PolicySimulationExpectedPerformance({ dispatch }: IObserveProps) {
    const classes = useStyles();
    const [isExpectedPerformanceChartOpen, setIsExpectedPerformanceChartOpen] = useState<boolean>(false);
    const [shouldRenderSimulation, setShouldRenderSimulation] = useState<boolean>(false);

    const ExpectedPerformanceEntityWrapper = initEntityWrapper({
        notifications: [StateChangeNotification.POLICY_PERFORMANCE_FUTURE_DATA],
    });

    const InstrumentsEntityWrapper = initEntityWrapper({
        notifications: [StateChangeNotification.POLICY_OPTIMIZATION],
    });

    return (
        <>
            <div className={classes.RunSimulationSection}>
                <PolicySimulationFilters
                    translationPrefix={TRANSLATION_PREFIX}
                    onRunSimulation={startSimulationRender}
                />
            </div>

            <GeneratedSimulation />
        </>
    );

    function startSimulationRender() {
        setShouldRenderSimulation(true);
    }

    function GeneratedSimulation() {
        return shouldRenderSimulation && (
            <>
                <ExpectedPerformanceEntityWrapper
                    asyncEntitySelector={policyFuturePerformanceEntity.select}
                    renderData={renderExpectedPerformance}
                    showLoader={!isExpectedPerformanceChartOpen}
                />

                <InstrumentsEntityWrapper
                    asyncEntitySelector={policySimulationEntity.select}
                    renderData={renderSimulationInstrumentsList}
                    showLoader={!isExpectedPerformanceChartOpen}
                    fetch={{
                        /* 'false' because error is shown as flash from triggerFetchPolicySimulationDetails() */
                        showErrorInline: false,
                    }}
                />
            </>
        );
    }

    function renderExpectedPerformance({
        data: policyPerformanceFuture,
        isAnyAsyncOperationBusy,
    }: IRenderDataProps<IPolicyPerformanceFuture>) {
        const newState = getStore().getState();
        const pageVars = getPolicySimulationPageVars(newState);
        const optimisticQuantiles = getLastItemOfArray(policyPerformanceFuture.value_amount);
        const expectedValue = getLastItemOfArray(optimisticQuantiles);
        const expectedReturn = isAnyAsyncOperationBusy
            ? NO_DATA_CHARACTER
            : calculateExpectedReturn(
                expectedValue,
                pageVars.investmentAmount,
                pageVars.horizonYears,
            );
        const isFetchingPolicyFuturePerformance = isFetchBusy(policyFuturePerformanceEntity.select());

        return (
            <>
                <Divider />

                <div className={classes.ExpectedPerformance}>
                    <ContentTitle
                        label={`${SECTIONS_PREEFIX}.policy.title`}
                        className="sectionTitle"
                    />
                    <Box
                        display="flex"
                        flexWrap="wrap"
                    >
                        <div className="simulationCard">
                            <Translate
                                msg={`${SECTIONS_PREEFIX}.policy.expectedValue`}
                            />
                            <div className="simulationReturn">
                                {expectedValue !== 0
                                    ? `${formatFloat(expectedValue)} ${getPolicyBaseCurrency()}`
                                    : NO_DATA_CHARACTER
                                }
                            </div>
                        </div>
                        <div className="simulationCard">
                            <Translate
                                msg={`${SECTIONS_PREEFIX}.policy.estimatedReturn`}
                            />
                            <div className="simulationReturn">
                                {expectedValue !== 0 ? `${expectedReturn}` : NO_DATA_CHARACTER}
                            </div>
                        </div>
                    </Box>
                    <div className="viewAsChartButton">
                        <TextButton
                            id="view_simulation_as_chart_button"
                            label={`${TRANSLATION_PREFIX}.view_as_chart`}
                            variant="outlined"
                            onClick={openExpectedPerformanceChart}
                            size="S"
                            fontSize="L"
                        />
                    </div>
                    <Modal
                        id="expected_performance_modal"
                        title={`${TRANSLATION_PREFIX}.tabs.expected_performance.title`}
                        open={isExpectedPerformanceChartOpen}
                        onClose={closeExpectedPerformanceChart}
                        maxWidth="lg"
                    >
                        <div className={classes.RunSimulationSection}>
                            {/* In the modal, a separate spinner is shown - opposed to the spinner within
                              * the ExpectedPerformanceEntityWrapper - so that the spinner is inline in the modal.
                              */}
                            <SpinnerOverlay
                                open={isFetchingPolicyFuturePerformance}
                                variant="white"
                            />

                            <PolicySimulationFilters
                                translationPrefix={TRANSLATION_PREFIX}
                                isDisplayedOnModal
                                onRunSimulation={startSimulationRender}
                            />
                            <PolicyExpectedPerformanceChart />
                        </div>
                    </Modal>
                </div>
            </>
        );

        function PolicyExpectedPerformanceChart() {
            return (
                <div className="performanceChart">
                    <div className="policyInfo">
                        <div className="policyCircle">
                            &nbsp;
                        </div>
                        <Translate
                            msg={`${TRANSLATION_PREFIX}.chart_policy`}
                            placeholders={{
                                estimatedYearlyReturn: expectedReturn,
                            }}
                            raw
                        />
                    </div>
                    {/* <div className="benchmarkInfo">
                        <span className="benchmarkCircle" />
                        <Translate
                            msg={`${TRANSLATION_PREFIX}.chart_benchmark`}
                            placeholders={{
                                estimatedYearlyReturn: 3,
                            }}
                        />
                    </div> */}
                    <PolicySimulationExpectedPerformanceChart
                        onChangedMarkedDate={updateFutureMarkedDate}
                    />
                </div>
            );

            function updateFutureMarkedDate(markedDate: IPolicySimulationFuturePerformanceMarkedDate) {
                dispatch(updatePolicySimulationPerformancePageVars({ markedDate }));
            }
        }
    }

    function renderSimulationInstrumentsList({
        data: policyOptimizationData,
        translator,
    }: IRenderDataProps<TPolicyOptimizationApiResponse>) {
        if (policyOptimizationData.status !== EnhancedOptimizationStatus.SUCCESS) {
            return null;
        }

        return (
            <>
                <Divider />

                <PolicySimulationListOfInstruments
                    enhancedOptimization={policyOptimizationData}
                    translator={translator}
                />
            </>
        );
    }

    function closeExpectedPerformanceChart() {
        setIsExpectedPerformanceChartOpen(false);
    }

    function openExpectedPerformanceChart() {
        setIsExpectedPerformanceChartOpen(true);
    }
}

export default observe(
    [],
    PolicySimulationExpectedPerformance,
);
