import React from 'react';
import omit from 'ramda/src/omit';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import {
    IEnhancedPolicyCompositionElementData,
    PolicyAlgorithmType,
    TEnhancedPolicyComposition,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { roundFloat4 } from '@console/common/utils/float/roundFloat';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { isPolicyOfTypeModelPortfolio } from '@console/bff/utils/policies/policyTypeUtils';
import { APP_COLORS } from 'config/styling/colors';
import { formatPercentage } from 'utils/number/percentageUtils';
import { IExtendedFormField, TSetFieldValue } from 'views/common/inputs/extended/ExtendedInputForm';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';
import InputNumberField from 'views/common/inputs/base/InputNumberField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import IconButton from 'views/common/buttons/IconButton';
import { RemoveIcon } from 'views/common/icons';
import ContentDivider from 'views/common/layout/ContentDivider';
import LabelWithValue from 'views/common/layout/LabelWithValue';
import EntityFiche from 'views/common/genericApiEntity/EntityFiche';
import AddPolicyCompositionElement from './AddPolicyCompositionElement';
import { IAddCompositionElementProps } from './types';

interface IPolicyCompositionProps {
    compositionFormField: IExtendedFormField;
    setCompositionFormFieldValue: TSetFieldValue;
    policyAlgorithmType: PolicyAlgorithmType;
    currency: SupportedCurrency;
    isReadOnly?: boolean; // default false
}

interface IUpdatePolicyCompositionProps {
    compositionElementId: TApiEntityId;
    compositionElementData: IEnhancedPolicyCompositionElementData;
}

const LABEL_PREFIX = 'portfolio_mgmt.policies.composition';
const DEFAULT_COMPOSITION_ELEMENT_WEIGHT = 0;

const useStyles = makeStyles((theme) => ({
    PolicyComposition: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),

        '& .footer': {
            ...mixins.flexColTopLeft(),

            '& .compositionFieldError': {
                marginBottom: theme.spacing(3),
                color: APP_COLORS.FEEDBACK.ERROR,
            },

            '& .total': {
                ...mixins.widthMax(),
                ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
                marginBottom: theme.spacing(3),
            },
        },

        '& .compositionListItem': {
            marginBottom: theme.spacing(3),
            ...mixins.widthMax(),

            '& .elementInput': {
                color: APP_COLORS.FEEDBACK.ERROR,
            },

            '& .itemDetails': {
                ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            },
        },
    },
}));

export default function PolicyComposition({
    compositionFormField,
    setCompositionFormFieldValue,
    policyAlgorithmType,
    currency,
    isReadOnly = false,
}: IPolicyCompositionProps) {
    const classes = useStyles();
    const compositionListItems = Object.entries(compositionFormField.value);
    const policyComposition = compositionFormField.value as TEnhancedPolicyComposition;

    return (
        <div className={classes.PolicyComposition}>
            {compositionListItems.map(([id, data]) => renderCompositionListItem({
                policyAlgorithmType,
                compositionElementId: id,
                compositionElementData: data,
                updatePolicyComposition,
                removePolicyCompositionElement,
                isReadOnly,
            }))}

            <ContentDivider />

            <div className="footer">
                <LabelWithValue
                    className="total"
                    variant="row"
                    label={`${LABEL_PREFIX}.total`}
                    value={getFormattedCompositionElementsSum()}
                    emphasizeValue={!doCompositionElementsAddUpTo100Percent() && APP_COLORS.FEEDBACK.ERROR}
                />

                <div className="compositionFieldError">
                    <Text label={compositionFormField.error} />
                </div>

                {!isReadOnly && (
                    <AddPolicyCompositionElement
                        compositionLabelPrefix={LABEL_PREFIX}
                        policyComposition={policyComposition}
                        onCompositionElementAdded={onCompositionElementAdded}
                        policyAlgorithmType={policyAlgorithmType}
                        currency={currency}
                    />
                )}
            </div>
        </div>
    );

    function removePolicyCompositionElement(compositionElementId: TApiEntityId) {
        setCompositionFormFieldValue({
            fieldName: compositionFormField.fieldName,
            value: omit([compositionElementId], policyComposition),
        });
    }

    function onCompositionElementAdded({
        compositionElementId,
        compositionElementName,
    }: IAddCompositionElementProps) {
        updatePolicyComposition({
            compositionElementId,
            compositionElementData: {
                weight: DEFAULT_COMPOSITION_ELEMENT_WEIGHT,
                name: compositionElementName,
            },
        });
    }

    function updatePolicyComposition({
        compositionElementId,
        compositionElementData,
    }: IUpdatePolicyCompositionProps) {
        setCompositionFormFieldValue({
            fieldName: compositionFormField.fieldName,
            value: {
                ...policyComposition,
                [compositionElementId]: compositionElementData,
            },
        });
    }

    function doCompositionElementsAddUpTo100Percent() {
        return getCompositionElementsSum() === 1;
    }

    function getFormattedCompositionElementsSum() {
        return formatPercentage(
            getCompositionElementsSum() * 100,
            { nrOfDecimals: 2 },
        );
    }

    function getCompositionElementsSum() {
        return roundFloat4(compositionListItems.reduce(
            (sum, [, { weight }]) => {
                // eslint-disable-next-line no-param-reassign
                sum += weight || 0;
                return sum;
            },
            0,
        ));
    }
}

function renderCompositionListItem({
    policyAlgorithmType,
    compositionElementId,
    compositionElementData,
    updatePolicyComposition,
    removePolicyCompositionElement,
    isReadOnly,
}: IUpdatePolicyCompositionProps & {
    isReadOnly: boolean;
    policyAlgorithmType: PolicyAlgorithmType;
    updatePolicyComposition: (props: IUpdatePolicyCompositionProps) => void;
    removePolicyCompositionElement: (compositionElementId: TApiEntityId) => void;
}) {
    const { name, weight } = compositionElementData;

    return (
        <div className="compositionListItem" key={compositionElementId}>
            <div className="itemDetails">
                <EntityFiche
                    name={name}
                    id={compositionElementId}
                    variant={isPolicyOfTypeModelPortfolio(policyAlgorithmType)
                        ? 'instrument'
                        : 'regular'}
                />
                {!isReadOnly && (
                    <IconButton
                        id={`${compositionElementId}-remove`}
                        icon={<RemoveIcon />}
                        isCircle
                        variant="bare"
                        size="XS"
                        onClick={() => removePolicyCompositionElement(compositionElementId)}
                    />
                )}
            </div>
            <div className="elementInput">
                <InputWrapper
                    minHeight={20}
                    disabled={isReadOnly}
                    hasError={!isCompositionElementWeightPositiveInteger()}
                >
                    <InputNumberField
                        value={weight}
                        type="percentage"
                        shouldAllowNumbersAbove1={false}
                        shouldAllowNumbersBelow0={false}
                        nrOfDecimals={2}
                        disabled={isReadOnly}
                        onChange={({ value }) => updatePolicyComposition({
                            compositionElementId,
                            compositionElementData: {
                                name,
                                weight: value,
                            },
                        })}
                        error={!isCompositionElementWeightPositiveInteger() && {
                            msg: `${LABEL_PREFIX}.element_error`,
                        }}
                    />
                </InputWrapper>
            </div>
        </div>
    );

    function isCompositionElementWeightPositiveInteger() {
        return isSet(weight) && weight !== 0;
    }
}
