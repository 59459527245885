import React, { useState } from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import {
    IPortfolioAddEntityData,
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { isRoboPortfolioManagerType } from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import { TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import { TUser } from '@console/core-api/models/userMgmt/user.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { TI18nLabelOrString } from 'models/general.models';
import { SIZES } from 'config/styling/sizes';
import { NO_DATA_CHARACTER } from 'config/styling/typography';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import {
    portfolioDetailsEntity,
    triggerCreatePortfolio,
    shouldShowDepositCashModalAfterPortfolioCreation,
    redirectToPortfolioDetailAfterPortfolioCreation,
} from 'state/entities/portfolioMgmt/portfolioDetails';
import { triggerDepositPortfolioCash } from 'state/entities/portfolioMgmt/portfolioCash';
import { getDefaultPortfolioTypeConfig, getDefaultTenantCurrency } from 'state/appConfig/selectors';
import { canUserAssignPortfolioToOtherOwner } from 'state/auth/apiEntityAuthorization.selectors';
import { getAuthenticatedUser } from 'state/auth/selectors';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import AddPage from 'views/common/add/AddPage';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import {
    portfolioAddSchema,
    portfolioOwnerSchema,
    portfolioPolicySchema,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioDetailsSchema';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ExtendedSelectOneViaModal from 'views/common/inputs/extended/ExtendedSelectOneViaModal';
import { IRenderSelectedItemProps } from 'views/common/inputs/base/SelectOneViaModal';
import ContentTitle from 'views/common/layout/ContentTitle';
import {
    IPortfolioAddFormValues,
    TPortfolioOwnerFormValues,
    TPortfolioPolicyFormValues,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/types';
import {
    PortfolioExternalIdFormContent,
    PortfolioNameFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioDetailsFormContents';
import {
    PortfolioManagerTypeFormContent,
    PortfolioMoneyTypeFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioTypeFormContents';
import {
    PortfolioPolicyFormContent,
    PortfolioGoalFormContent,
    PortfolioHorizonFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioRoboRelatedFormContents';
import {
    PortfolioOwnerFormContent,
    PortfolioStartAmountFormContent,
} from 'views/portfolioMgmt/Portfolios/PortfolioDetail/portfolioFormContents/portfolioVariaFormContents';
import ConfirmationModal from 'views/common/layout/ConfirmationModal';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';

const LABEL_PREFIX = 'portfolio_mgmt.portfolios';
const LABEL_PREFIX_DETAIL = `${LABEL_PREFIX}.detail`;
const LABEL_PREFIX_ADD = `${LABEL_PREFIX}.add`;
const LABEL_PREFIX_CASH_MODAL = `${LABEL_PREFIX}.cash_deposit`;

interface IDepositCashModalState {
    portfolioId: TApiEntityId;
    startAmount: number;
    currency: string;
}

export default function PortfolioAdd() {
    const [depositCashModalConfig, setDepositCashModalConfig] = useState<IDepositCashModalState>(null);
    const canAssignToOtherOwner = canUserAssignPortfolioToOtherOwner();

    const isDepositCashModalOpen = depositCashModalConfig !== null;

    const initialValues: IPortfolioAddFormValues = {
        name: '',
        externalId: null,
        managerType: getDefaultPortfolioTypeConfig(),
        moneyType: PortfolioMoneyType.PAPER_MONEY,
        policy: null,
        goalId: null,
        horizonId: null,
        owner: canAssignToOtherOwner ? null : getAuthenticatedUserAsOwner(),
        startAmount: null,
    };

    return (
        <AddPage
            titleLabel="portfolio_mgmt.portfolios.add.title"
            entity={{
                notifications: [StateChangeNotification.PORTFOLIO_DETAILS_DATA],
                asyncEntitySelector: portfolioDetailsEntity.select,
            }}
            cancel={{
                onCancel: redirectToList,
            }}
        >
            <ExtendedInputForm<IPortfolioAddFormValues>
                name="portfolio-add-form"
                labelPrefix={LABEL_PREFIX_DETAIL}
                initialValues={initialValues}
                renderFormFields={renderFormFields}
                schema={portfolioAddSchema}
                maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
                reset={{}}
                submit={{
                    onSubmit: onSubmitAdd,
                    actionLabel: 'common.action.add',
                }}
            />

            <ConfirmationModal
                id="deposit-cash-for-virtual-portfolio-modal"
                open={isDepositCashModalOpen}
                onClose={closeDepositCashModal}
                onConfirm={confirmCashDeposit}
                titleLabel={`${LABEL_PREFIX_CASH_MODAL}.title`}
                messageLabel={getCashDepositModalInfoLabel()}
                confirmLabel={`${LABEL_PREFIX_CASH_MODAL}.actions.yes`}
                cancelLabel={`${LABEL_PREFIX_CASH_MODAL}.actions.no`}
            />
        </AddPage>
    );

    function getAuthenticatedUserAsOwner(): TUser {
        const authenticatedUser = getAuthenticatedUser();

        if (!authenticatedUser) {
            return null;
        }

        return {
            id: authenticatedUser.ulid,
            first_name: authenticatedUser.firstName,
            last_name: authenticatedUser.lastName,
        } as TUser;
    }

    function renderFormFields(formContext: IExtendedInputFormContext<IPortfolioAddFormValues>) {
        const { fields } = formContext;

        const currentOwner: TPortfolioOwnerFormValues = fields.owner.value
            ? { owner: fields.owner.value }
            : null;
        const policy = fields.policy.value as TPolicy;
        const currentPolicy: TPortfolioPolicyFormValues = fields.policy.value
            ? {
                policy,
                managerType: fields.managerType.value as PortfolioManagerType,
            }
            : null;

        return (
            <>
                <PortfolioNameFormContent {...formContext} />
                <PortfolioExternalIdFormContent {...formContext} />

                <ExtendedSelectOneViaModal<TPortfolioOwnerFormValues>
                    readOnly={!canAssignToOtherOwner}
                    formField={fields.owner}
                    label="fields.owner.label"
                    tooltip="fields.owner.tip"
                    currentSelectedItem={currentOwner}
                    renderSelectedItem={renderSelectedPortfolioOwner}
                    shouldPrefixAddModalTitle={false}
                    remove={{
                        shouldAllow: false,
                    }}
                    edit={{
                        modalTitle: `${LABEL_PREFIX_DETAIL}.fields.owner.select`,
                        modalMaxWidth: 'md',
                        label: 'common.action.choose',
                        labelPrefix: LABEL_PREFIX_ADD,
                        initialValues: { owner: fields.owner.value },
                        schema: portfolioOwnerSchema,
                        renderFormFields: renderSelectOwnerFormContent,
                        submit: {
                            onSubmit: onSelectPortfolioOwner,
                        },
                    }}
                />

                <InputGroup>
                    <PortfolioManagerTypeFormContent {...formContext} />
                    <PortfolioMoneyTypeFormContent {...formContext} />
                </InputGroup>

                {isRoboPortfolioManagerType(fields.managerType.value as PortfolioManagerType) && (
                    <>
                        <ContentTitle
                            label={`${LABEL_PREFIX_ADD}.sub_title.robo_specific`}
                            variant="section"
                        />

                        <PortfolioGoalFormContent {...formContext} />
                        <PortfolioHorizonFormContent {...formContext} />

                        <ExtendedSelectOneViaModal<TPortfolioPolicyFormValues>
                            formField={fields.policy}
                            label="fields.policy_id.label"
                            tooltip="fields.policy_id.tip"
                            currentSelectedItem={currentPolicy}
                            renderSelectedItem={renderSelectedPortfolioPolicy}
                            shouldPrefixAddModalTitle={false}
                            remove={{
                                shouldAllow: false,
                            }}
                            edit={{
                                modalTitle: `${LABEL_PREFIX_DETAIL}.fields.policy_id.select`,
                                modalMaxWidth: 'md',
                                labelPrefix: LABEL_PREFIX_ADD,
                                label: 'common.action.choose',
                                initialValues: {
                                    policy: fields.policy.value as TPolicy,
                                    managerType: fields.managerType.value,
                                },
                                schema: portfolioPolicySchema,
                                renderFormFields: renderSelectPolicyFormContent,
                                submit: {
                                    onSubmit: onSelectPortfolioPolicy,
                                },
                            }}
                        />
                    </>
                )}

                {(fields.moneyType.value === PortfolioMoneyType.PAPER_MONEY) && (
                    <>
                        <ContentTitle
                            label={`${LABEL_PREFIX_ADD}.sub_title.paper_money_specific`}
                            variant="section"
                        />

                        <PortfolioStartAmountFormContent {...formContext} />
                    </>
                )}
            </>
        );

        function onSelectPortfolioOwner({ values }: IOnSubmitProps<TPortfolioOwnerFormValues>) {
            formContext.setFieldValue({
                fieldName: fields.owner.fieldName,
                value: values.owner,
            });

            return Promise.resolve();
        }

        function renderSelectedPortfolioOwner({ item }: IRenderSelectedItemProps<TPortfolioOwnerFormValues>) {
            const owner = item.owner as TUser;

            if (!isSet(owner?.id)) {
                return (
                    <div>{NO_DATA_CHARACTER}</div>
                );
            }

            return (
                <div>{getUserFullName(owner)}</div>
            );
        }

        function renderSelectOwnerFormContent(
            selectOwnerFormContext: IExtendedInputFormContext<TPortfolioOwnerFormValues>,
        ) {
            return (
                <PortfolioOwnerFormContent
                    {...selectOwnerFormContext}
                />
            );
        }

        function onSelectPortfolioPolicy({ values: selectedPolicy }: IOnSubmitProps<TPortfolioPolicyFormValues>) {
            formContext.setFieldValue({
                fieldName: fields.policy.fieldName,
                value: selectedPolicy.policy,
            });

            return Promise.resolve();
        }

        function renderSelectedPortfolioPolicy({ item }: IRenderSelectedItemProps<TPortfolioPolicyFormValues>) {
            const selectedPolicy = item.policy as TPolicy;

            if (!isSet(selectedPolicy?.id)) {
                return (
                    <div>{NO_DATA_CHARACTER}</div>
                );
            }

            return (
                <div>{selectedPolicy.name}</div>
            );
        }

        function renderSelectPolicyFormContent(
            selectPolicyFormContext: IExtendedInputFormContext<TPortfolioPolicyFormValues>,
        ) {
            return (
                <PortfolioPolicyFormContent
                    {...selectPolicyFormContext}
                />
            );
        }
    }

    function onSubmitAdd({ values }: IOnSubmitProps<IPortfolioAddFormValues>) {
        /**
         * In case of PAPER_MONEY, the holdings should NOT contain the startAmount.
         * Otherwise, when the cash endpoint is called afterwards with the same startAmount, the holdings
         * would contain the double amount of cash.
         * See [RI-4353]
         */
        const portfolioHoldings = {};

        const isRoboManaged = isRoboPortfolioManagerType(values.managerType);

        return triggerCreatePortfolio(
            enrichApiEntityDataForCreate<IPortfolioAddEntityData>({
                base_currency: determinePortfolioCurrency(),
                config: {
                    manager: values.managerType,
                    manager_settings: {
                        goal_id: isRoboManaged ? values.goalId : null,
                        horizon_id: isRoboManaged ? values.horizonId : null,
                        policy_id: isRoboManaged ? values.policy?.id : null,
                        start_amount: (values.moneyType === PortfolioMoneyType.PAPER_MONEY)
                            ? values.startAmount
                            : null,
                        tags: isRoboManaged
                            ? values.policy.tags || []
                            : [],
                    },
                    manager_version: 1,
                },
                external_id: values.externalId,
                money_type: values.moneyType,
                name: values.name,
                owned_by_user_id: values.owner?.id,
                portfolio: portfolioHoldings,
            }, {
                userIdNeedingAccess: values.owner?.id,
            }),
        ).then((triggerResult) => {
            if (triggerResult.wasTriggered
                && shouldShowDepositCashModalAfterPortfolioCreation(triggerResult.asyncResult)) {
                openDepositCashModal({
                    portfolioId: triggerResult.asyncResult.id,
                    startAmount: triggerResult.asyncResult.config.manager_settings.start_amount,
                    currency: triggerResult.asyncResult.base_currency,
                });
            }
        });

        function determinePortfolioCurrency(): SupportedCurrency {
            if (isRoboPortfolioManagerType(values.managerType)) {
                return values.policy.config.algorithm_settings.execution_settings.base_currency;
            }

            return getDefaultTenantCurrency();
        }
    }

    function getCashDepositModalInfoLabel(): TI18nLabelOrString {
        if (!isDepositCashModalOpen) {
            return null;
        }

        return {
            msg: `${LABEL_PREFIX_ADD}.virtual_cash_deposit_info`,
            placeholders: {
                startAmount: formatAmount(depositCashModalConfig.startAmount, {
                    currency: depositCashModalConfig.currency,
                }),
            },
            raw: true,
        };
    }

    function openDepositCashModal(config: IDepositCashModalState) {
        setDepositCashModalConfig(config);
    }

    function closeDepositCashModal() {
        const { portfolioId } = depositCashModalConfig;

        setDepositCashModalConfig(null);

        redirectToPortfolioDetailAfterPortfolioCreation({ portfolioId });
    }

    function confirmCashDeposit() {
        const { portfolioId, startAmount } = depositCashModalConfig;

        triggerDepositPortfolioCash({
            portfolioId,
            amount: startAmount,
        }).then(closeDepositCashModal);
    }

    function redirectToList() {
        redirectTo({
            routeKey: ROUTE_KEY.R_PORTFOLIOS_LIST,
        });
    }
}
