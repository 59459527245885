import { OPERATION_PERMISSION_KEY } from '@console/core-api/config/operationPermissionKeys';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { PUBLIC_INSTRUMENT_GROUP_TAB_KEY } from 'config/tabs.config';
import { IExecuteOnRoute, IRoute } from 'models/route.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISinglePublicInstrumentApiInput } from '@console/core-api/models/thematicSearch/publicInstrument.models';
import {
    ISinglePublicInstrumentGroupApiInput,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import {
    getPublicInstrumentTitle,
    triggerFetchPublicInstrumentDetails,
} from 'state/entities/thematicSearch/publicInstrumentDetails';
import {
    getPublicInstrumentGroupTitle,
    triggerFetchPublicInstrumentGroupDetails,
} from 'state/entities/thematicSearch/publicInstrumentGroupDetails';
import { triggerFetchPublicInstrumentGroups } from 'state/entities/thematicSearch/publicInstrumentGroups';
import {
    triggerFetchPublicInstrumentGroupInstruments,
    triggerFetchPublicInstruments,
} from 'state/entities/thematicSearch/publicInstruments';
import { getCurrentRouteParam } from 'state/ui/selectors';
import getApiEntityVersionsChildRoute from 'views/common/genericApiEntity/versions/ApiEntityVersions';
import { ROUTE_KEY } from 'views/routeKeys';
import { PublicInstrumentGroupsList } from './PublicInstrumentGroups/PublicInstrumentGroupsList';
import { PublicInstrumentGroupAdd } from './PublicInstrumentGroups/PublicInstrumentGroupAdd';
import { PublicInstrumentGroupDetail } from './PublicInstrumentGroups/PublicInstrumentGroupDetail';
import { PublicInstrumentsList } from './PublicInstruments/PublicInstrumentsList';
import { PublicInstrumentAdd } from './PublicInstruments/PublicInstrumentAdd';
import { PublicInstrumentDetail } from './PublicInstruments/PublicInstrumentDetail';

export const THEMATIC_SEARCH_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENTS_LIST,
    path: '/instruments',
    breadcrumb: {
        translationKey: 'thematic_search.public_instruments.breadcrumb',
    },
    exact: true,
    component: PublicInstrumentsList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.thematicSearch?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchPublicInstruments,
    }],
    childRoutes: [{
        routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_ADD,
        path: '/add',
        breadcrumb: {
            translationKey: 'thematic_search.public_instruments.add.breadcrumb',
        },
        exact: true,
        component: PublicInstrumentAdd,
        requiredPermissions: [OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_CREATE],
    }, {
        routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_DETAIL,
        path: '/:publicInstrumentId/:publicInstrumentTab',
        breadcrumb: {
            translationKey: 'thematic_search.public_instruments.detail.breadcrumb',
            translationKeyIfCurrentRoute: 'thematic_search.public_instruments.detail.breadcrumb_if_current',
            translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                const publicInstrumentName = getPublicInstrumentTitle();
                const tabKey = (pathParams && pathParams.publicInstrumentTab)
                    || getCurrentRouteParam(state, 'publicInstrumentTab');

                return {
                    publicInstrumentName: translatorIfNeeded(publicInstrumentName),
                    publicInstrumentTab:
                        translator(`thematic_search.public_instruments.detail.tabs.${tabKey}.breadcrumb_part`),
                };
            },
            extraNotifications: [StateChangeNotification.PUBLIC_INSTRUMENT_DETAILS_DATA],
        },
        component: PublicInstrumentDetail,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        executeOnRoute: [<IExecuteOnRoute<ISinglePublicInstrumentApiInput>>{
            execute: triggerFetchPublicInstrumentDetails,
            executeInputSelector: ({ routeLocation }) => ({
                publicInstrumentId: routeLocation.params.publicInstrumentId,
            }),
        }],
        childRoutes: [
            getApiEntityVersionsChildRoute({
                entityType: CoreApiEntityType.publicInstruments,
                entityIdRouteParamName: 'publicInstrumentId',
                routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_VERSIONS,
            }),
        ],
    }],
}, {
    routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUPS_LIST,
    path: '/instrument-groups',
    breadcrumb: {
        translationKey: 'thematic_search.public_instrument_groups.breadcrumb',
    },
    exact: true,
    component: PublicInstrumentGroupsList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.thematicSearch?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchPublicInstrumentGroups,
    }],
    childRoutes: [{
        routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_ADD,
        path: '/add',
        breadcrumb: {
            translationKey: 'thematic_search.public_instrument_groups.add.breadcrumb',
        },
        exact: true,
        component: PublicInstrumentGroupAdd,
        requiredPermissions: [OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_CREATE],
    }, {
        routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_DETAIL,
        path: '/:publicInstrumentGroupId/:publicInstrumentGroupTab',
        breadcrumb: {
            translationKey: 'thematic_search.public_instrument_groups.detail.breadcrumb',
            translationKeyIfCurrentRoute: 'thematic_search.public_instrument_groups.detail.breadcrumb_if_current',
            translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                const publicInstrumentGroupName = getPublicInstrumentGroupTitle();
                const tabKey = (pathParams && pathParams.publicInstrumentGroupTab)
                    || getCurrentRouteParam(state, 'publicInstrumentGroupTab');

                return {
                    publicInstrumentGroupName: translatorIfNeeded(publicInstrumentGroupName),
                    publicInstrumentGroupTab:
                        translator(`thematic_search.public_instrument_groups.detail.tabs.${tabKey}.breadcrumb_part`),
                };
            },
            extraNotifications: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUP_DETAILS_DATA],
        },
        component: PublicInstrumentGroupDetail,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        executeOnRoute: [<IExecuteOnRoute<ISinglePublicInstrumentGroupApiInput>>{
            execute: triggerFetchPublicInstrumentGroupDetails,
            executeInputSelector: ({ routeLocation }) => ({
                publicInstrumentGroupId: routeLocation.params.publicInstrumentGroupId,
            }),
        }, {
            execute: triggerFetchPublicInstrumentGroupInstruments,
            shouldExecute: ({ routeLocation }) =>
                routeLocation.params.publicInstrumentGroupTab === PUBLIC_INSTRUMENT_GROUP_TAB_KEY.INSTRUMENTS,
        }],
        childRoutes: [
            getApiEntityVersionsChildRoute({
                entityType: CoreApiEntityType.publicInstrumentGroups,
                entityIdRouteParamName: 'publicInstrumentGroupId',
                routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_VERSIONS,
            }),
        ],
    }],
}];
