import {
    AsyncOperation,
    TRefreshMode,
} from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { IFetchUserGroupsApiInput, TUserGroupsData } from '@console/core-api/models/userMgmt/userGroup.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { UiPageKey } from 'models/state/ui.models';
import { USERGROUP_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { getDeepestPathParamNameOfCurrentRoute, isRouteLocation } from 'state/ui/selectors';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { addListResponseToExistingEntityData } from 'utils/entities/entityListUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { getEntitiesManager } from '../entitiesManager';

export const userGroupsEntity = getEntitiesManager().registerEntity<TUserGroupsData>({
    asyncEntityKey: AsyncEntityKeys.userGroups,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.USERGROUPS_DATA],
    includeUpdaters: true,
});

export const triggerResetUserGroupsFetch = () => userGroupsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchUserGroups({
    forceRefresh = false,
    ...apiInput
}: IFetchUserGroupsApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchUserGroupsGeneric({
        apiInput,
        pageKey: UiPageKey.userGroupsList,
        refreshMode: ({ state }) => forceRefresh || isObjectWithProps(apiInput) || !isRouteLocation(state, {
            prevRouteKey: ROUTE_KEY.R_USER_GROUP_DETAIL,
        }) || isRouteLocation(state, {
            prevParams: {
                userGroupTab: USERGROUP_TAB_KEY.ACCESSIBLE_BY,
            },
        }),
    });
}

export function triggerFetchUserGroupsForAccessibleBy({
    forceRefresh = false,
    ...apiInput
}: IFetchUserGroupsApiInput & IForceStateRefreshFilter = {}) {
    return triggerFetchUserGroupsGeneric({
        apiInput,
        pageKey: UiPageKey.accessibleByUserGroupsList,
        refreshMode: ({ state }) => {
            if (forceRefresh) {
                return true;
            }

            const deepestTabParam = getDeepestPathParamNameOfCurrentRoute(state, 'tab');

            return !isRouteLocation(state, {
                paramsSameAsPrevRoute: [deepestTabParam],
            });
        },
    });
}

function triggerFetchUserGroupsGeneric({
    apiInput,
    pageKey,
    refreshMode,
}: {
    apiInput: IFetchUserGroupsApiInput;
    pageKey: UiPageKey;
    refreshMode: TRefreshMode<IState>;
}) {
    return userGroupsEntity.async.fetch<IFetchUserGroupsApiInput>({
        api: api.userGroups.fetchUserGroups,
        apiInputSelector: ({ state }) => enrichApiInputWithListPageVars<IFetchUserGroupsApiInput>({
            pageKey,
            state,
            apiInput,
        }),
        mapApiResponse: ({ response }) => addListResponseToExistingEntityData({
            response,
            existingData: userGroupsEntity.select().data,
        }),
        refreshMode,
        resetDataOnTriggerMode: 'never',
    });
}
