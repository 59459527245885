import React from 'react';
import addItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import removeItemByIndexAndGetResultingArray
    from '@snipsonian/core/cjs/array/manipulation/removeItemByIndexAndGetResultingArray';
import { IRiskProfileEntityData } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';
import { SIZES } from 'config/styling/sizes';
import { makeStyles } from 'views/styling';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputNumber from 'views/common/inputs/extended/ExtendedInputNumber';
import ContentTitle from 'views/common/layout/ContentTitle';
import InputGroup from 'views/common/inputs/base/InputGroup';
import ManageManyViaModal, {
    IOnRemoveSingleItemProps, IRenderSingleItemProps,
} from 'views/common/inputs/base/ManageManyViaModal';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import {
    getMultiTranslationsLabelInitialValue,
} from 'state/appConfig/selectors';
import ExtendedMultiTranslationsInput from 'views/common/inputs/extended/ExtendedMultiTranslationsInput';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { getSubmitActionLabelBasedOnMode } from 'utils/entities/entityDetailUtils';
import { getExpectedAssetClassSchema, getRiskProfileDetailsSchema } from './riskProfileDetailsSchema';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    extends Pick<IExtendedInputFormProps<IRiskProfileFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
    mode: TPropertiesFormDisplayMode;
}

export interface IRiskProfileAssetClassesFormValues extends IFormValues {
    expectedAssetClasses: IExpectedAssetClassInput[];
}

export interface IRiskProfileFormValues extends IRiskProfileAssetClassesFormValues {
    name: IApiMultiTranslationsLabel;
    description: IApiMultiTranslationsLabel;
    scoreRangeMin: number;
    scoreRangeMax: number;
    imageUrl: string;
    expectedRiskFraction: number;
    expectedReturnFraction: number;
    externalId: string;
}

export type TRiskProfileDetailsFormValues = Omit<IRiskProfileFormValues, 'expectedAssetClasses'>;

interface IExpectedAssetClassInput extends IFormValues {
    name: IApiMultiTranslationsLabel;
    allocation: IApiMultiTranslationsLabel;
}

const LABEL_PREFIX = 'portfolio_mgmt.risk_profiles.detail';

const useStyles = makeStyles((theme) => ({
    RiskProfileDetailsForm: {
        '& .expectedAssetClasses': {
            '& .__current-item .InputWrapper:nth-child(2)': {
                paddingLeft: theme.spacing(1),
            },
        },
    },
}));

export function RiskProfileDetailsForm({
    fields,
}: IExtendedInputFormContext<TRiskProfileDetailsFormValues>) {
    return (
        <>
            <ExtendedMultiTranslationsInput
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.label',
                }}
            />

            <ExtendedMultiTranslationsInput
                formField={fields.description}
                multilineRows
                wrapper={{
                    label: 'fields.description.label',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.externalId}
                wrapper={{
                    label: 'fields.external_id.label',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.imageUrl}
                wrapper={{
                    label: 'fields.image_url.label',
                }}
            />

            <InputGroup>
                <ExtendedInputNumber
                    formField={fields.scoreRangeMin}
                    wrapper={{
                        label: 'fields.score_range_min.label',
                    }}
                />

                <ExtendedInputNumber
                    formField={fields.scoreRangeMax}
                    wrapper={{
                        label: 'fields.score_range_max.label',
                    }}
                />
            </InputGroup>

            <InputGroup>
                <ExtendedInputNumber
                    formField={fields.expectedRiskFraction}
                    wrapper={{
                        label: 'fields.expected_risk_fraction.label',
                    }}
                    nrOfDecimals={2}
                />

                <ExtendedInputNumber
                    formField={fields.expectedReturnFraction}
                    wrapper={{
                        label: 'fields.expected_return_fraction.label',
                    }}
                    nrOfDecimals={2}
                />
            </InputGroup>
        </>
    );
}

export default function RiskProfilePropertiesForm({
    mode,
    submit,
    ...formProps
}: IPublicProps) {
    return (
        <ExtendedInputForm<IRiskProfileFormValues>
            name={ExtendedInputFormName.riskProfileProperties}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: getSubmitActionLabelBasedOnMode(mode),
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderFormFields}
            schema={getRiskProfileDetailsSchema()}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );

    function renderFormFields(context: IExtendedInputFormContext<IRiskProfileFormValues>) {
        return (
            <>
                <ContentTitle
                    label="portfolio_mgmt.risk_profiles.detail.sub_title.main"
                    variant="section"
                />

                {RiskProfileDetailsForm(context)}

                <ContentTitle
                    label="portfolio_mgmt.risk_profiles.detail.fields.expected_asset_classes.label"
                    variant="section"
                    infoTooltip={{
                        name: 'risk-profile-assets',
                        simpleContent: {
                            info: {
                                msg: 'portfolio_mgmt.risk_profiles.detail.expected_asset_classes.info_tooltip.content',
                                raw: true,
                            },
                        },
                        showUntilDismissed: true,
                    }}
                />

                {RiskProfileAssetClassesForm(context)}
            </>
        );
    }
}

export function RiskProfileAssetClassesForm({
    fields,
    setFieldValue,
    readOnlyForm,
}: IExtendedInputFormContext<IRiskProfileAssetClassesFormValues>) {
    const expectedAssetClassesArray = fields.expectedAssetClasses.value as IExpectedAssetClassInput[];
    const classes = useStyles();
    const initialAddExpectedAssetClass: IExpectedAssetClassInput = {
        name: getMultiTranslationsLabelInitialValue(),
        allocation: getMultiTranslationsLabelInitialValue(),
    };

    return (
        <div className={classes.RiskProfileDetailsForm}>
            <ManageManyViaModal<IExpectedAssetClassInput>
                name="expectedAssetClasses"
                className="expectedAssetClasses"
                readOnly={readOnlyForm}
                items={expectedAssetClassesArray}
                renderItem={renderExpectedAssetClassItem}
                remove={{
                    onRemoveItem: onRemoveExpectedAssetClassItem,
                    tooltipPlacement: 'bottom-end',
                }}
                error={fields.expectedAssetClasses.error}
                emphasizeError={fields.expectedAssetClasses.emphasizeError}
                add={{
                    modalTitle: 'portfolio_mgmt.risk_profiles.detail.expected_asset_classes.add_title',
                    modalMaxWidth: 'xs',
                    labelPrefix: LABEL_PREFIX,
                    initialValues: initialAddExpectedAssetClass,
                    schema: getExpectedAssetClassSchema(),
                    renderFormFields: renderAddExpectedAssetClassFormFields,
                    submit: {
                        onSubmit: onAddExpectedAssetClassItem,
                    },
                }}
            />
        </div>
    );

    function renderExpectedAssetClassItem({ index }: IRenderSingleItemProps<IExpectedAssetClassInput>) {
        const itemFormFieldName = `expectedAssetClasses[${index}]`;
        const allocationFormFieldName = `${itemFormFieldName}.allocation`;
        const nameFormFieldName = `${itemFormFieldName}.name`;

        return (
            <InputGroup>
                <ExtendedMultiTranslationsInput
                    formField={fields[nameFormFieldName]}
                    wrapper={{
                        label: 'fields.expected_asset_classes.name.label',
                    }}
                />

                <ExtendedMultiTranslationsInput
                    formField={fields[allocationFormFieldName]}
                    wrapper={{
                        label: 'fields.expected_asset_classes.allocation.label',
                    }}
                />
            </InputGroup>
        );
    }

    function onRemoveExpectedAssetClassItem({ index }: IOnRemoveSingleItemProps<IExpectedAssetClassInput>) {
        setFieldValue({
            fieldName: fields.expectedAssetClasses.fieldName,
            value: removeItemByIndexAndGetResultingArray(expectedAssetClassesArray, index),
            resetChildFields: true,
        });
    }

    function onAddExpectedAssetClassItem({ values: assetClassToAdd }: IOnSubmitProps<IExpectedAssetClassInput>) {
        setFieldValue({
            fieldName: fields.expectedAssetClasses.fieldName,
            value: addItemAndGetResultingArray(expectedAssetClassesArray, assetClassToAdd),
            resetChildFields: true,
        });

        return Promise.resolve();
    }

    function renderAddExpectedAssetClassFormFields({
        fields: addFields,
    }: IExtendedInputFormContext<IExpectedAssetClassInput>) {
        return (
            <>
                <ExtendedMultiTranslationsInput
                    formField={addFields.name}
                    wrapper={{
                        label: 'fields.expected_asset_classes.name.label',
                    }}
                />

                <ExtendedMultiTranslationsInput
                    formField={addFields.allocation}
                    wrapper={{
                        label: 'fields.expected_asset_classes.allocation.label',
                    }}
                />
            </>
        );
    }
}

export function mapRiskProfileFormValuesToApiEntity(
    formValues: IRiskProfileFormValues,
): IRiskProfileEntityData {
    return {
        name: formValues.name,
        description: formValues.description,
        score_range: [formValues.scoreRangeMin, formValues.scoreRangeMax],
        expected_asset_classes: formValues.expectedAssetClasses.map((expectedAssetClass) => ({
            name: expectedAssetClass.name,
            allocation: expectedAssetClass.allocation,
        })),
        expected_return_fraction: formValues.expectedReturnFraction,
        expected_risk_fraction: formValues.expectedRiskFraction,
        external_id: formValues.externalId,
        image_url: isSetString(formValues.imageUrl) ? formValues.imageUrl : null,
    };
}
