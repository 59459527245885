import React, { FormEventHandler } from 'react';

export interface IInputFormProps {
    name: string;
    className?: string;
    children: React.ReactNode;
    onSubmit: FormEventHandler;
    cancelEventOnSubmit?: boolean; // default true
    autoComplete?: string; // default "off"
    noValidate?: boolean; // default true
}

export default function InputForm({
    name,
    className,
    children,
    onSubmit,
    autoComplete = 'off',
    noValidate = true,
    cancelEventOnSubmit = true,
}: IInputFormProps) {
    return (
        <form
            id={name}
            name={name}
            className={className}
            onSubmit={onSubmitForm}
            autoComplete={autoComplete}
            noValidate={noValidate}
        >
            {children}
        </form>
    );

    function onSubmitForm(event: React.FormEvent) {
        if (cancelEventOnSubmit) {
            event.preventDefault();
            event.stopPropagation();
        }

        onSubmit(event);
    }
}
