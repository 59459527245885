import React from 'react';
import { IPortfolioPastPerformanceMarkedDate } from 'models/ui/portfolioPerformance.ui.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IChartDimensions } from 'models/chart.models';
import { CHART, STANDARD_CHART_DIMENTIONS } from 'config/styling/chart';
import { getPortfolioPerformancePageVars } from 'state/ui/uiPages.selectors';
import { formatDate } from '@console/common/utils/date/formatDate';
import { IEnhancedPortfolioPerformancePast } from '@console/bff/models/portfolios/enhancedPortfolioPerformance.models';
import { convertPercentageRelative1ToRelative100 } from 'utils/number/percentageUtils';
import GenericPerformanceGraph, { IGenericPerformanceGraphProps } from 'views/common/charts/GenericPerformanceGraph';
import { IMarkedDateData, IMarkedDateValueItem } from 'views/common/charts/MarkedDateBox';
import { getPortfolioPastPerformanceChartConfig } from './portfolioPerformanceGraphsConfig';

interface IPublicProps
    // eslint-disable-next-line max-len
    extends Pick<IGenericPerformanceGraphProps<IPortfolioPastPerformanceMarkedDate>, 'baseCurrency' | 'onChangedMarkedDate'> {
    pastPerformanceData: IEnhancedPortfolioPerformancePast;
}

export default function PortfolioPerformanceGraphPast({
    pastPerformanceData,
    baseCurrency,
    onChangedMarkedDate,
}: IPublicProps) {
    return (
        <GenericPerformanceGraph
            baseCurrency={baseCurrency}
            chartDimensions={STANDARD_CHART_DIMENTIONS}
            getChartConfig={(dimensions: IChartDimensions) => getPortfolioPastPerformanceChartConfig({
                chartDimensions: dimensions,
                pastPerformanceData,
            })}
            markedDateBoxNotifications={[StateChangeNotification.UI_PAGE_PORTFOLIO_PERFORMANCE_PAST_MARKED_DATE]}
            markedDateDataSelector={markedDateDataSelector}
            onChangedMarkedDate={onChangedMarkedDate}
        />
    );

    function markedDateDataSelector(state: IState): IMarkedDateData {
        const { markedDate } = getPortfolioPerformancePageVars(state).past;

        if (!markedDate) {
            return null;
        }

        const valueItems: IMarkedDateValueItem[] = [{
            label: 'portfolio_mgmt.portfolios.detail.performance.past.marked_date.value',
            labelColor: CHART.PAST.VALUE_COLOR,
            amount: markedDate.values.value,
        }, {
            label: 'portfolio_mgmt.portfolios.detail.performance.past.marked_date.return',
            labelColor: CHART.PAST.RETURN_COLOR,
            amount: markedDate.values.return,
            percentage: convertPercentageRelative1ToRelative100(markedDate.values.twr),
        }, {
            label: 'portfolio_mgmt.portfolios.detail.performance.past.marked_date.invested',
            labelColor: CHART.PAST.INVESTED_COLOR,
            amount: markedDate.values.invested,
        }];

        if (markedDate.values.value < markedDate.values.invested) {
            valueItems.reverse();
        }

        return {
            formattedDate: formatDate({ date: markedDate.date }),
            valueItems,
        };
    }
}
