import assert from '@snipsonian/core/cjs/assert';

/**
 * If itemNr
 * = 1 --> last item of input array is returned
 * = 2 --> 2nd last item ...
 */
export function getItemOfArrayFromEnd<Item = unknown>(arr: Item[], itemNr = 1): Item {
    assert(itemNr, () => itemNr >= 1, 'The itemNr should be 1 or higher');

    if (arr && arr.length >= Math.abs(itemNr)) {
        return arr[arr.length - Math.abs(itemNr)];
    }

    return null;
}
