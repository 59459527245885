import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import d3 from 'utils/libs/d3';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import {
    IChartDimensions,
    IGenericPerformanceChartConfig,
    IPerformanceMarkedDateBase,
} from 'models/chart.models';
import { CHART } from 'config/styling/chart';
import {
    determineAxisTimeTicks,
    determineXTimeScale,
    determineAxisAmountTicks,
    determineYLinearScale,
} from './chartConfig.utils';

export function getGenericPerformanceChartConfig
<Date2valuesMap extends TObjectWithProps, MarkedDateValues extends IPerformanceMarkedDateBase>({
    chartDimensions,
    xDates,
    globalMinMaxAmount,
    date2valuesMap,
}: {
    chartDimensions: IChartDimensions;
    xDates: Date[];
    globalMinMaxAmount: [number, number];
    date2valuesMap: Date2valuesMap;
}): Omit<IGenericPerformanceChartConfig<MarkedDateValues>, 'lines' | 'area' | 'markerStartingPos'> {
    const {
        svgWidth, svgHeight,
        chartWidth, chartHeight,
    } = determineChartDimensions(chartDimensions);

    const xScale = determineXTimeScale({ xDates, chartWidth });
    const yScale = determineYLinearScale({ globalMinMaxAmount, chartHeight });

    /* bisect example: http://bl.ocks.org/mikehadlow/93b471e569e31af07cd3 */
    const bisectClosestDateIndex = d3.bisector<Date, Date>((d) => d).left;

    return {
        svgWidth,
        svgHeight,
        chartWidth,
        chartHeight,
        xScale,
        yScale,
        xAxis: determineAxisTimeTicks({
            xDates,
        }),
        yAxis: determineAxisAmountTicks({
            autoTickValues: xDates.length <= 4,
            minAmount: globalMinMaxAmount[1],
            maxAmount: globalMinMaxAmount[0],
            tickLinesLength: xScale(xScale.domain()[1]), // get the x position of the last x item
        }),
        getValuesForDate,
        getValuesForInitialMarkedDate,
    };

    function getValuesForDate(date: Date) {
        let dateString = formatDate({ date, format: DATE_FORMAT.BACK_END });

        if (!date2valuesMap[dateString]) {
            const closestDateIndex = bisectClosestDateIndex(xDates, date);
            dateString = formatDate({ date: xDates[closestDateIndex], format: DATE_FORMAT.BACK_END });
        }

        return date2valuesMap[dateString];
    }

    function getValuesForInitialMarkedDate() {
        return getValuesForDate(getLastItemOfArray(xDates));
    }
}

function determineChartDimensions(chartDimensions: IChartDimensions) {
    const { maxHeight, maxWidth } = chartDimensions;

    return {
        svgWidth: maxWidth,
        svgHeight: maxHeight,
        chartWidth: maxWidth - CHART.MARGIN_LEFT - CHART.MARGIN_RIGHT,
        chartHeight: maxHeight - CHART.MARGIN_TOP - CHART.MARGIN_BOTTOM,
    };
}
