import isSet from '@snipsonian/core/cjs/is/isSet';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { TEnhancedPortfolio } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import { IBaseApiEntity } from '@console/core-api/models/api.models';
import { OPERATION_PERMISSION_KEY } from '@console/core-api/config/operationPermissionKeys';
import { IOperationPermissions } from '@console/core-api/utils/entities/userGroups/extractUserOperationPermissions';
import { IState } from 'models/state.models';
import { TUserGroup } from '@console/core-api/models/userMgmt/userGroup.models';
import { TUser } from '@console/core-api/models/userMgmt/user.models';
import { TRiskProfile } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { TGoal } from '@console/core-api/models/portfolioMgmt/goal.models';
import { THorizon } from '@console/core-api/models/portfolioMgmt/horizon.models';
import { TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import { TPortfolioGroup } from '@console/core-api/models/portfolioMgmt/portfolioGroups.models';
import { TPortfolioReport } from '@console/core-api/models/portfolioMgmt/portfolioReport.models';
import { TPublicInstrument } from '@console/core-api/models/thematicSearch/publicInstrument.models';
import { TPublicInstrumentGroup } from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import {
    doUserPermissionsCoverRequiredPermissions,
} from '@console/core-api/utils/entities/userGroups/doUserPermissionsCoverRequiredPermissions';
import { getStore } from 'state';
import { getAuthenticatedUser } from 'state/auth/selectors';

export function canUserModifyGoal(goal: TGoal, state?: IState) {
    return canUserModifyApiEntity(goal, OPERATION_PERMISSION_KEY.GOALS_MODIFY, state);
}

export function canUserModifyHorizon(horizon: THorizon, state?: IState) {
    return canUserModifyApiEntity(horizon, OPERATION_PERMISSION_KEY.HORIZONS_MODIFY, state);
}

export function canUserModifyRiskProfile(riskProfile: TRiskProfile, state?: IState) {
    return canUserModifyApiEntity(riskProfile, OPERATION_PERMISSION_KEY.RISKPROFILES_MODIFY, state);
}

export function canUserModifyPortfolio(portfolio: TEnhancedPortfolio, state?: IState) {
    return canUserModifyApiEntity(portfolio, OPERATION_PERMISSION_KEY.PORTFOLIOS_MODIFY, state);
}

export function canUserModifyPortfolioGroup(portfolioGroup: TPortfolioGroup, state?: IState) {
    return canUserModifyApiEntity(portfolioGroup, OPERATION_PERMISSION_KEY.PORTFOLIO_GROUPS_MODIFY, state);
}

export function canUserCreatePortfolioReport(state: IState = getStore().getState()) {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_CREATE],
    });
}

export function canUserModifyPortfolioReport(portfolioReport: TPortfolioReport, state?: IState) {
    return canUserModifyApiEntity(portfolioReport, OPERATION_PERMISSION_KEY.PORTFOLIOREPORT_MODIFY, state);
}

export function canUserCreateBasePolicy({
    userPermissions,
    state,
}: {
    userPermissions?: Partial<IOperationPermissions>;
    state?: IState;
} = {}) {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: userPermissions
            || getAuthenticatedUser(state || getStore().getState()).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.BASE_POLICIES_CREATE],
    });
}

export function canUserModifyBasePolicy(policy: TPolicy, state?: IState) {
    return canUserModifyApiEntity(policy, OPERATION_PERMISSION_KEY.BASE_POLICIES_MODIFY, state);
}

export function canUserModifyPolicy(policy: TPolicy | TEnhancedPolicy, state?: IState) {
    return canUserModifyApiEntity(policy, OPERATION_PERMISSION_KEY.POLICIES_MODIFY, state);
}

export function canUserModifyUser(user: TUser, state?: IState) {
    return canUserModifyApiEntity(user, OPERATION_PERMISSION_KEY.USERS_MODIFY, state);
}

export function canUserModifyUserGroup(userGroup: TUserGroup, state?: IState) {
    return canUserModifyApiEntity(userGroup, OPERATION_PERMISSION_KEY.USERGROUPS_MODIFY, state);
}

/**
 * Users should not be able to modify their own feature flags.
 * So you need the USERS_MODIFY permission to be able to do it, even if you are in the 'modifiable_by'.
 * p.s. When creating a user, the USERS_CREATE permission also allows setting the feature flags of that user.
 */
export function canUserModifyUserFeatureFlags(state: IState = getStore().getState()) {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.USERS_MODIFY],
    });
}

export function canUserModifyStoryManagerScenarios(state: IState = getStore().getState()) {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.STORYMANAGER_SCENARIO_MODIFY],
    });
}

export function canUserModifyStoryManagerTextOutputs(state: IState = getStore().getState()) {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.STORYMANAGER_TEXTOUTPUT_MODIFY],
    });
}

export function canUserPublishStoryManager(state: IState = getStore().getState()) {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.STORYMANAGER_PUBLISH],
    });
}

export function canUserModifyPublicInstrument(instrument: TPublicInstrument, state?: IState) {
    return canUserModifyApiEntity(instrument, OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTS_MODIFY, state);
}

export function canUserModifyPublicInstrumentGroup(instrumentGroup: TPublicInstrumentGroup, state?: IState) {
    return canUserModifyApiEntity(instrumentGroup, OPERATION_PERMISSION_KEY.PUBLIC_INSTRUMENTGROUPS_MODIFY, state);
}

export function canUserModifyApiEntity(
    apiEntity: IBaseApiEntity,
    permissionKeyToModifyAll: OPERATION_PERMISSION_KEY,
    state: IState = getStore().getState(),
): boolean {
    if (!apiEntity || apiEntity.deleted) {
        return false;
    }

    const user = getAuthenticatedUser(state);

    if (!user || !isSet(user.permissions)) {
        return false;
    }

    /* check user has the required global-modify-permission */
    if (doUserPermissionsCoverRequiredPermissions({
        userPermissions: user.permissions,
        requiredPermissions: [permissionKeyToModifyAll],
    })) {
        return true;
    }

    if (!isArrayWithValues(apiEntity.modifiable_by)) {
        return false;
    }

    /* check user is in the modifiable_by array */
    if (apiEntity.modifiable_by.includes(user.ulid)) {
        return true;
    }

    /* check one-of-the-groups-the-user-belongs-to is in the modifiable_by array */
    return apiEntity.modifiable_by.some((modById) => user.groups && user.groups[modById]);
}

/**
 * A user can only change the readable_by & modifiable_by fields if:
 * - the user can modify the entity (either via the "modify-all-permission" or either via being in the modifiable_by)
 * - AND the user has the ACL_MODIFY permission
 */
export function canUserModifyAccessibleByOfApiEntity(
    apiEntity: IBaseApiEntity,
    permissionKeyToModifyAll: OPERATION_PERMISSION_KEY,
    state: IState = getStore().getState(),
): boolean {
    return canUserModifyApiEntity(apiEntity, permissionKeyToModifyAll, state)
        && doUserPermissionsCoverRequiredPermissions({
            userPermissions: getAuthenticatedUser(state).permissions,
            requiredPermissions: [OPERATION_PERMISSION_KEY.ACL_MODIFY],
        });
}

export function canUserAssignPortfolioToOtherOwner(state: IState = getStore().getState()): boolean {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.PORTFOLIOS_MODIFY_OWNER],
    });
}

export function canUserUnarchivePortfolios(state: IState = getStore().getState()): boolean {
    return doUserPermissionsCoverRequiredPermissions({
        userPermissions: getAuthenticatedUser(state).permissions,
        requiredPermissions: [OPERATION_PERMISSION_KEY.PORTFOLIOS_MODIFY],
    });
}
