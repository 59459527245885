import React from 'react';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import Tooltip, { ITooltipProps } from 'views/common/widget/Tooltip';

interface IPublicProps {
    value: string;
    numberOfCharacters?: number; // how many characters that will be shown at the begin and at the end of the value
    /**
     * For configuring the tooltip:
     * - placement -> default 'top-end'
     * - enableClickListener -> default true
     */
    tooltip?: Pick<ITooltipProps, 'placement' | 'enableClickListener'>;
}

export default function CondensedValue({
    value,
    numberOfCharacters = 5,
    tooltip,
}: IPublicProps) {
    if (!isSetString(value)) {
        return null;
    }

    if (value.length > numberOfCharacters * 2) {
        const regex = new RegExp(`(.{${numberOfCharacters}})(.{1,})(.{${numberOfCharacters}})+`);

        const tooltipProps: Partial<ITooltipProps> = {
            placement: 'top-end',
            enableClickListener: true,
            ...tooltip,
        };

        return (
            <Tooltip
                {...tooltipProps}
                label={{
                    text: value,
                    shouldTranslate: false,
                }}
            >
                {value.replace(regex, '$1...$3')}
            </Tooltip>
        );
    }
    return (
        <>
            {value}
        </>
    );
}
