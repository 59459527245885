import * as boxMixins from './box';
import * as flexMixins from './flex';
import * as sizeMixins from './size';
import * as spacingMixins from './spacing';
import * as typoMixins from './typo';

export const mixins = {
    ...boxMixins,
    ...flexMixins,
    ...sizeMixins,
    ...spacingMixins,
    ...typoMixins,
};
