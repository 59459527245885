import * as apiEntityVersions from './genericApiEntity/apiEntityVersions.api';
import * as goals from './portfolioMgmt/goals.api';
import * as horizons from './portfolioMgmt/horizons.api';
import * as instrumentPerformancePast from './portfolioMgmt/instrumentPerformancePast.api';
import * as instruments from './portfolioMgmt/instruments.api';
import * as instrumentsCache from './portfolioMgmt/instrumentsCache';
import * as optimizations from './portfolioMgmt/optimizations.api';
import * as policies from './portfolioMgmt/policies.api';
import * as policySimulation from './portfolioMgmt/policySimulation.api';
import * as portfolioAllocation from './portfolioMgmt/portfolioAllocation.api';
import * as portfolioCash from './portfolioMgmt/portfolioCash.api';
import * as portfolioGroups from './portfolioMgmt/portfolioGroups.api';
import * as portfolioOptimization from './portfolioMgmt/portfolioOptimization.api';
import * as portfolioReports from './portfolioMgmt/portfolioReports.api';
import * as portfolios from './portfolioMgmt/portfolios.api';
import * as portfolioTransactions from './portfolioMgmt/portfolioTransactions.api';
import * as riskProfiles from './portfolioMgmt/riskProfiles.api';
import * as publicInstruments from './thematicSearch/publicInstruments.api';
import * as publicInstrumentGroups from './thematicSearch/publicInstrumentGroups.api';
import * as userGroups from './userMgmt/userGroups.api';
import * as users from './userMgmt/users.api';

export const coreApiClient = {
    apiEntityVersions,
    goals,
    horizons,
    instrumentPerformancePast,
    instruments,
    instrumentsCache,
    optimizations,
    policies,
    policySimulation,
    portfolioAllocation,
    portfolioCash,
    portfolioGroups,
    portfolioOptimization,
    portfolioReports,
    portfolios,
    portfolioTransactions,
    riskProfiles,
    publicInstruments,
    publicInstrumentGroups,
    userGroups,
    users,
};
