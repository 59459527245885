import React from 'react';
import { useParams } from 'react-router-dom';
import { IBaseApiEntity } from '@console/core-api/models/api.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { DEFAULT_POLICY_TAB_KEY, POLICY_TAB_KEY } from 'config/tabs.config';
import {
    getPolicyTitle,
    getPolicyAlgorithmTagProps,
    policyDetailsEntity,
    triggerDeletePolicy,
} from 'state/entities/portfolioMgmt/policyDetails';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import Tag from 'views/common/widget/Tag';
import OverridenPolicySettingsHeader from './OverridenPolicySettingsHeader';
import PolicyAccessibleBy from './PolicyAccessibleBy';
import PolicyConfiguration from './PolicyConfiguration/PolicyConfiguration';
import PolicyParentChain from './PolicyParentChain';

const POLICY_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getPolicyTitle,
    endIconSelector: () => (
        <Tag {...getPolicyAlgorithmTagProps()} />
    ),
    notifications: [StateChangeNotification.POLICY_DETAILS_DATA],
};

const POLICY_TABS: ITabItem[] = [{
    key: POLICY_TAB_KEY.CONFIGURATION,
    label: 'portfolio_mgmt.policies.detail.tabs.configuration.title',
    component: PolicyConfiguration,
    wrapWithContentBox: false,
}, {
    key: POLICY_TAB_KEY.ACCESSIBLE_BY,
    label: 'portfolio_mgmt.policies.detail.tabs.accessible_by.title',
    component: PolicyAccessibleBy,
}];

export default function PolicyDetail() {
    const {
        policyTab = DEFAULT_POLICY_TAB_KEY,
    } = useParams<IPossiblePathParams>();

    return (
        <>
            <OverridenPolicySettingsHeader />

            <DetailPage
                title={POLICY_TITLE_CONFIG}
                tabsWithEntityWrapper={{
                    items: POLICY_TABS,
                    activeTabKey: policyTab,
                    onTabChange: tabChanged,
                    entity: {
                        notifications: [StateChangeNotification.POLICY_DETAILS_DATA],
                        asyncEntitySelector: policyDetailsEntity.select,
                        loaderPosition: 'fixed',
                    },
                }}
                footer={{
                    apiEntityVersion: {
                        apiEntitySelector: () => policyDetailsEntity.select().data,
                    },
                }}
                actions={getPolicyDetailActions}
                entityId={{
                    idSelector: ({ urlParams }) => urlParams.policyId as string,
                }}
                extraInfo={{
                    render: renderPolicyChain,
                }}
            />
        </>
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                policyTab: tabKey,
            },
        });
    }

    function getPolicyDetailActions(apiEntity: IBaseApiEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: CoreApiEntityType.policies,
            deleteTrigger: triggerDeletePolicy,
        })];
    }

    function renderPolicyChain() {
        return (
            <PolicyParentChain />
        );
    }
}
