/* eslint-disable no-template-curly-in-string */
import { Operation } from 'fast-json-patch';
import isArray from '@snipsonian/core/cjs/is/isArray';
import isString from '@snipsonian/core/cjs/is/isString';
import {
    SchemaErrorType, Schema,
    string, object, array, mixed,
    validateAgainstSchema,
    getDynamicObjectSchemaThatAppliesSameSchemaForEachField,
} from '@console/common/utils/schema';

/** "/outputKeys/:outputKeyId" */
const REGEX_JSON_PATCH_OUTPUT_KEY_PATH = new RegExp(/^\/outputKeys\/\w*$/);
/** "/outputKeys/:outputKeyId/*" */
const REGEX_JSON_PATCH_OUTPUT_KEY_CHILD_PATH = new RegExp(/^\/outputKeys\/\w*\/[\w|/]*$/);
/** /outputKeys/:outputKeyId/scenarios/:scenarioIndex/example */
const REGEX_JSON_PATCH_SCENARIO_EXAMPLE_PATH = new RegExp(/^\/outputKeys\/\w*\/scenarios\/\d*\/example$/);
/** /outputKeys/:outputKeyId/scenarios/:scenarioIndex/output/variations */
const REGEX_JSON_PATCH_SCENARIO_OUTPUT_VARIATIONS_PATH
    = new RegExp(/^\/outputKeys\/\w*\/scenarios\/\d*\/output\/variations$/);

const LIST_OF_ALLOWED_STATUSSES = ['TO_DO', 'IN_PROGRESS', 'IN_REVIEW', 'DONE'];

export const databaseIdSchema = string();
export const versionIdSchema = string();

export const storyManagerTranslationSchema = string({
    checkUnallowedHtml: {
        allowedTags: [
            'strong', /* we don't allow "b" as "strong" should be used instead */
            'big', 'small',
            'sub', 'sup',
            'em', /* emphasis */
            'ul', 'ol', 'li',
            'span', /* possibly used to force word-breaks */
            'br',
            'a', /* for a link */
        ],
        allowedAttributes: [
            'href', /* for a link */
            'rel', /* for a link - specifies the relationship between the current document and the linked document */
            'target', /* for a link */
        ],
    },
});

export const configSchema = object({
    outputKeys: getOutputKeysSchema(),
    order: array().of(string()).required(),
});

export const configJsonPatchSchema = array().of(
    object({
        path: string().required(),
        op: string().oneOf(['replace', 'add', 'remove']).required(),
        value: mixed(),
    })
        .test(
            SchemaErrorType.StoryManagerPatch,
            '${path} "remove" op is not allowed on this path',
            (value) => {
                if (value.op === 'remove') {
                    return REGEX_JSON_PATCH_OUTPUT_KEY_PATH.test(value.path);
                }
                return true;
            },
        )
        .test(
            SchemaErrorType.StoryManagerPatch,
            '${path} "add" op is not allowed on this path',
            (value) => {
                if (value.op === 'add') {
                    return REGEX_JSON_PATCH_OUTPUT_KEY_PATH.test(value.path);
                }
                return true;
            },
        )
        .test(
            SchemaErrorType.StoryManagerPatch,
            '${path} invalid output key value for "add" op',
            (value) => {
                if (value.op === 'add') {
                    return validateAgainstSchema({
                        input: value.value,
                        schema: getOutputKeySchema(),
                    }).isValid;
                }
                return true;
            },
        )
        .test(
            SchemaErrorType.StoryManagerPatch,
            '${path} invalid order value for "replace" op on "order" key',
            (value) => {
                if (value.op === 'replace' && value.path === '/order') {
                    return isArray(value.value);
                }
                return true;
            },
        )
        .test(
            SchemaErrorType.StoryManagerPatch,
            '${path} invalid path for "replace" op',
            (value) => {
                if (value.op === 'replace') {
                    return value.path === '/order' || REGEX_JSON_PATCH_OUTPUT_KEY_CHILD_PATH.test(value.path);
                }
                return true;
            },
        )
        .test(
            SchemaErrorType.StoryManagerPatch,
            '${path} invalid html content for "replace" op',
            (value) => {
                if (value.op === 'replace') {
                    if (REGEX_JSON_PATCH_SCENARIO_OUTPUT_VARIATIONS_PATH.test(value.path)) {
                        return validateAgainstSchema({
                            input: value.value,
                            schema: getVariationsSchema(),
                        }).isValid;
                    }

                    if (REGEX_JSON_PATCH_SCENARIO_EXAMPLE_PATH.test(value.path)) {
                        return validateAgainstSchema({
                            input: value.value,
                            schema: storyManagerTranslationSchema,
                        }).isValid;
                    }

                    if (isString(value.value)) {
                        /* no html content allowed at all */
                        return validateAgainstSchema({
                            input: value.value,
                            schema: string(),
                        }).isValid;
                    }
                }
                return true;
            },
        ) as unknown as Schema<Operation>,
).required();

function getOutputKeysSchema() {
    return getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
        isRequired: true,
        schemaToApply: getOutputKeySchema(),
    });
}

function getOutputKeySchema() {
    return object({
        name: string().required(),
        description: string().required(),
        scenarios: getScenariosSchema(),
    }).required();
}

function getScenariosSchema() {
    return getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
        isRequired: true,
        schemaToApply: object({
            status: string().oneOf(LIST_OF_ALLOWED_STATUSSES).required(),
            description: string().required(),
            example: storyManagerTranslationSchema.required(),
            context: string().required(),
            output: object({
                statuses: getStatusesSchema(),
                variations: getVariationsSchema(),
            }).required(),
            conditions: getConditionsSchema(),
        }).required(),
    });
}

function getStatusesSchema() {
    return getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
        isRequired: true,
        schemaToApply: string().oneOf(LIST_OF_ALLOWED_STATUSSES).required(),
    });
}

export function getVariationsSchema() {
    /* dynamic object variations where the index is the key */
    return getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
        isRequired: true,
        /* dynamic object of translation strings where the locale is the key */
        schemaToApply: getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
            isRequired: true,
            schemaToApply: storyManagerTranslationSchema,
        }),
    });
}

function getConditionsSchema() {
    return getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
        isRequired: true,
        schemaToApply: getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
            isRequired: true,
            schemaToApply: object({
                left: string().required(),
                operator: string().required(),
                right: string().required(),
            }).required(),
        }),
    });
}
