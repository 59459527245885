import { APP_COLORS } from 'config/styling/colors';
import React from 'react';
import ContentBox from 'views/common/layout/ContentBox';
import { makeStyles, mixins } from 'views/styling';
import PolicySettings from './PolicySettings';
import PolicySimulation from './PolicySimulation';

const useStyles = makeStyles((theme) => ({
    PolicyConfiguration: {
        ...mixins.flexRow(),
        ...mixins.widthMax(),

        '& .PolicySettingsSection': {
            ...mixins.borderRight({ width: 1, style: 'solid', color: APP_COLORS.GREY['100'] }),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(0),
            paddingBottom: theme.spacing(4),
            width: '35%',
            minWidth: 400,
        },
        '& .PolicySimulationSection': {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            paddingBottom: theme.spacing(4),
            width: '100%',
        },
        '& .UniverseSectionSpacing': {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        '& .UniverseChangeSpacing': {
            ...mixins.flexRowCenterRight(),
        },
        '& .__universeFieldError': {
            margin: theme.spacing(1, 0),
            color: APP_COLORS.FEEDBACK.ERROR,
        },
        '& .ChangeUniverseActions': {
            ...mixins.flexRowCenterRight(),
        },
        '& .titleSpacing': {
            paddingTop: theme.spacing(2),
        },
    },
}));

export default function PolicyConfiguration() {
    const classes = useStyles();

    return (
        <ContentBox noVertialPadding noWrapperPadding>
            <div className={classes.PolicyConfiguration}>
                <div className="PolicySettingsSection">
                    <PolicySettings />
                </div>
                <div className="PolicySimulationSection">
                    <PolicySimulation />
                </div>
            </div>
        </ContentBox>
    );
}
