import { II18nLabel } from 'models/general.models';
import React, { useState } from 'react';
import { makeStyles, mixins } from 'views/styling';
import isSet from '@snipsonian/core/cjs/is/isSet';
import IconButtonEdit from '../buttons/IconButtonEdit';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues,
    IOnSubmitProps,
} from '../inputs/extended/ExtendedInputForm';
import ContentTitle from '../layout/ContentTitle';
import { IInfoIconTooltipProps } from '../widget/InfoIconTooltip.models';
import CollapsableSection from '../layout/CollapsableSection';

const INITIAL_OPEN_SECTION_INDEX = 0;

export interface IOnChangeIsEditActiveProps {
    isEditActive: boolean;
}

interface IDetailFieldsListWithSectionsProps<Values extends IFormValues>
    // eslint-disable-next-line max-len
    extends Pick<IExtendedInputFormProps<Values>, 'schema' | 'submit' | 'initialValues' | 'keepTheManagedFormStateInSync'> {
    formName: string;
    title: II18nLabel;
    sections: IDetailSection<Values>[];
    readOnly?: boolean; // default false
    fieldsLabelPrefix?: string; // default empty
    onChangeIsEditActive?: (props: IOnChangeIsEditActiveProps) => void;
    showSectionBottomBorderWhenOpen?: boolean; /** default true */
}

export interface IDetailSection<Values extends IFormValues> {
    title: II18nLabel;
    tooltip?: IInfoIconTooltipProps; // default null
    renderFields: (renderFormFieldsProps: IExtendedInputFormContext<Values>) => React.ReactNode;
    // when the result is true next to the section name there will be an error indicator displayed
    areThereErrorsWithinSection?: (context: IExtendedInputFormContext<Values>) => boolean;
}

const useStyles = makeStyles((theme) => ({
    DetailFieldsListWithSections: {
        '& .Header': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            paddingBottom: theme.spacing(2),
        },
        '& .IconEdit': {
            paddingTop: theme.spacing(2),
        },
    },
}));

export default function DetailFieldsListWithSections<Values extends IFormValues>({
    formName,
    title,
    schema,
    readOnly = false,
    submit,
    initialValues,
    sections,
    fieldsLabelPrefix = '',
    keepTheManagedFormStateInSync,
    onChangeIsEditActive,
    showSectionBottomBorderWhenOpen = true,
}: IDetailFieldsListWithSectionsProps<Values>) {
    const classes = useStyles();
    const initialOpenSectionsState = sections.map((section, index) => INITIAL_OPEN_SECTION_INDEX === index);
    const [isEditActive, setIsEditActive] = useState<boolean>(false);
    const [openSectionsState, setOpenSectionsState] = useState<boolean[]>(initialOpenSectionsState);

    return (
        <div className={classes.DetailFieldsListWithSections}>
            <ExtendedInputForm<Values>
                name={formName}
                initialValues={initialValues}
                schema={schema}
                submit={isEditActive && {
                    ...submit,
                    onSubmit: submitForm,
                }}
                reset={{}}
                labelPrefix={fieldsLabelPrefix}
                readOnly={readOnly || !isEditActive}
                renderFormFields={renderList}
                placeFormActionsInFixedFooter
                keepTheManagedFormStateInSync={keepTheManagedFormStateInSync}
            />
        </div>
    );

    function submitForm(onSubmitProps: IOnSubmitProps<Values>) {
        return submit.onSubmit(onSubmitProps).then(() => changeIsEditActive());
    }

    function renderList(context: IExtendedInputFormContext<Values>) {
        return (
            <>
                <div className="Header">
                    <ContentTitle
                        label={title}
                        variant="section"
                    />

                    <div className="IconEdit">
                        <IconButtonEdit
                            id="edit-policy-settings-button"
                            isCancel={isEditActive}
                            disabled={readOnly || (context.isDiff && isEditActive)}
                            onClick={changeIsEditActive}
                            tooltip={readOnly && {
                                msg: 'common.action.edit_disabled',
                            }}
                        />
                    </div>
                </div>
                {sections.map((section, sectionIndex) => {
                    const isSectionOpen = openSectionsState[sectionIndex];
                    const doesSectionHaveError = section.areThereErrorsWithinSection
                        && section.areThereErrorsWithinSection(context);
                    return (
                        <CollapsableSection
                            id={sectionIndex}
                            key={`policy_settings_section_${section.title.msg}`}
                            onChange={() => changeSectionState(sectionIndex)}
                            title={section.title}
                            infoTooltip={section.tooltip}
                            hasError={doesSectionHaveError}
                            isOpen={isSectionOpen}
                            showBottomBorderWhenOpen={showSectionBottomBorderWhenOpen}
                        >
                            {section.renderFields(context)}
                        </CollapsableSection>
                    );
                })}
            </>
        );

        function changeSectionState(sectionIndex: number) {
            setOpenSectionsState(openSectionsState.map(
                (isSectionOpen, index) => (sectionIndex === index ? !isSectionOpen : isSectionOpen),
            ));
        }
    }

    function changeIsEditActive() {
        if (isEditActive) {
            setIsEditActive(false);
        } else {
            setIsEditActive(true);
        }

        if (isSet(onChangeIsEditActive)) {
            // We are providing the opposite value here, because it hasn't been updated yet
            onChangeIsEditActive({ isEditActive: !isEditActive });
        }
    }
}
