import React, { ErrorInfo, ReactNode } from 'react';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { reportError } from '@typsy/sentry-react/dist/reporting/reportErrorReact';
import { getBaseSiteUrl } from 'utils/env/url';
import ErrorWidget from './ErrorWidget';

interface IPublicProps {
    showReloadAction?: boolean; // default false
    children: ReactNode;
}

interface IComponentState {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<IPublicProps, IComponentState> {
    static getDerivedStateFromError(/* error: Error */) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
        };
    }

    constructor(props: IPublicProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        reportError(error, { extra: errorInfo as unknown as TAnyObject });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.showReloadAction) {
                return (
                    <ErrorWidget
                        action={{
                            label: 'app_shell.error.widget.action_reload',
                            onExecute: () => {
                                window.location.href = getBaseSiteUrl();
                            },
                        }}
                    />
                );
            }

            return (
                <ErrorWidget />
            );
        }

        return this.props.children;
    }
}
