import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TLabel } from 'models/general.models';
import { isI18nLabel, isUntranslatableLabelTypeGuard } from 'utils/i18n/i18nUtils';
import { ExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import InputWrapper, { IInputWrapperProps } from 'views/common/inputs/base/InputWrapper';

export interface IExtendedInputWrapperProps extends IInputWrapperProps {
    shouldPrefixLabel?: boolean; // default true
    shouldPrefixTooltip?: boolean; // default true
}

export default function ExtendedInputWrapper({
    label,
    shouldPrefixLabel = true,
    tooltip,
    shouldPrefixTooltip = true,
    children,
    ...otherProps
}: IExtendedInputWrapperProps) {
    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix }) => {
                const adjustedLabel = prependLabelWithPrefix({
                    label,
                    labelPrefix,
                    shouldPrefixLabel,
                });
                const adjustedTooltip = prependLabelWithPrefix({
                    label: tooltip,
                    labelPrefix,
                    shouldPrefixLabel: shouldPrefixTooltip,
                });

                return (
                    <InputWrapper
                        label={adjustedLabel}
                        tooltip={adjustedTooltip}
                        {...otherProps}
                    >
                        {children}
                    </InputWrapper>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );
}

export function prependLabelWithPrefix({
    label,
    labelPrefix,
    shouldPrefixLabel,
}: {
    label: TLabel;
    labelPrefix: string;
    shouldPrefixLabel: boolean;
}): TLabel {
    if (!isSet(label)) {
        return undefined;
    }

    if (!shouldPrefixLabel || !labelPrefix) {
        return label;
    }

    if (isUntranslatableLabelTypeGuard(label)) {
        return label;
    }

    if (isI18nLabel(label)) {
        return {
            ...label,
            msg: `${labelPrefix}.${label.msg}`,
        };
    }

    return `${labelPrefix}.${label}`;
}
