import {
    IApiEntityListResponseWithPageNr,
    IApiMultiTranslationsLabel,
    IBaseFetchEntityListApiInput,
    TApiEntityId,
    TApiEntity,
    TApiEntityCreate,
    TApiEntityPatch,
} from '../api.models';

export interface IGoalEntityData {
    name: IApiMultiTranslationsLabel;
    description: IApiMultiTranslationsLabel;
    min_horizon_years: number;
    type: GoalType;
    image_url?: string;
    external_id?: string;
}

export enum GoalType {
    FINANCIAL_SECURITY = 'FINANCIAL_SECURITY',
    NON_SPECIFIC = 'NON_SPECIFIC',
    OLD_AGE = 'OLD_AGE',
    OTHER = 'OTHER',
    SPECIFIC = 'SPECIFIC',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFetchGoalsApiInput extends IBaseFetchEntityListApiInput {}

export interface ISingleGoalApiInput {
    goalId: TApiEntityId;
}

export type TGoalsData = IApiEntityListResponseWithPageNr<IGoalEntityData>;
export type TGoal = TApiEntity<IGoalEntityData>;
export type TTGoalPatch = TApiEntityPatch<IGoalEntityData>;
export type TTGoalCreate = TApiEntityCreate<IGoalEntityData>;
