/**
 * As there is a separate console-web deployed per tenant, and we know which tenant based on the
 * "instance" var (E.g. "cbd.ae") set in public.html, we could show a customised login-screen
 * per "instance".
 * However, currently we show the same name and logo for each standalone-deployed console-web application.
 */
export interface IInstanceConfig {
    name: string;
    logoUrl: string;
}

export const INSTANCE_CONFIG = determineInstanceConfig();

function determineInstanceConfig(): IInstanceConfig {
    /* multi-tenant */
    return {
        name: 'InvestSuite',
        logoUrl: '/InvestSuite_Icon.svg',
    };
}
