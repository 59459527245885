import { object, string, array, email, phoneNumber } from '@console/common/utils/schema';
import { UserFeatureFlag } from '@console/core-api/models/userMgmt/user.models';

/**
 * For performance reasons, currently only full email addresses are allowed to search by
 */
export const simpleUserFilterSchema = email();

export const advancedUserFiltersSchema = object({
    email: email(),
    externalId: string(),
    lastName: string(),
    phoneNumber: phoneNumber(),
    virtualIban: string(),
    brokerageUserId: string(),
    featureFlags: array().of(
        string().required().oneOf(Object.values(UserFeatureFlag)),
    ),
});
