import React from 'react';
import { makeStyles, mixins } from 'views/styling';
import ErrorBoundary from 'views/common/error/ErrorBoundary';
import { getUtilityChildStyles } from 'views/assets/cssInJs/utilityClasses';
import AppHeader from './AppHeader';
import AppBody from './AppBody';
import AppMenu from './AppMenu';
import AppModals from './AppModals';
import RouteListener from '../routing/RouteListener';
import FlashMessages from './FlashMessages';

const useStyles = makeStyles((/* theme */) => ({
    row: {
        ...mixins.flexRow(),
        minHeight: '100%',
    },
    /* this acts as the "UtilityClassParent" */
    AppTemplate: {
        ...mixins.widthHeightMax(),
        ...getUtilityChildStyles(),
    },
}));

export default function AppTemplate() {
    const classes = useStyles();

    return (
        <div className={classes.AppTemplate}>
            <ErrorBoundary showReloadAction>
                <RouteListener />
                <FlashMessages />
                <AppModals />
                <AppHeader />
                <div className={classes.row}>
                    <AppMenu />
                    <AppBody />
                </div>
            </ErrorBoundary>
        </div>
    );
}
