import produce from 'immer';
import { api } from 'api';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { IBaseSingleEntityApiInput } from '@console/core-api/models/api.models';
import {
    ISinglePublicInstrumentApiInput,
    TPublicInstrument,
    TTPublicInstrumentPatch,
    TTPublicInstrumentCreate,
} from '@console/core-api/models/thematicSearch/publicInstrument.models';
import { DEFAULT_PUBLIC_INSTRUMENT_TAB_KEY } from 'config/tabs.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import {
    flashErrorApiEntityCreate, flashErrorApiEntityPatch,
    flashSuccessApiEntityCreate, flashSuccessApiEntityPatch,
} from '../entitiesFlashDispatcher';
import { getEntitiesManager } from '../entitiesManager';
import { triggerResetPublicInstrumentsFetch } from './publicInstruments';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';

export const publicInstrumentDetailsEntity = getEntitiesManager().registerEntity<TPublicInstrument>({
    asyncEntityKey: AsyncEntityKeys.publicInstrumentDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.PUBLIC_INSTRUMENT_DETAILS_DATA],
    includeUpdaters: true,
});

export const getPublicInstrumentTitle: TTitleLabelSelector = () => {
    const publicInstrumentDetails = publicInstrumentDetailsEntity.select();

    if (hasFetchSucceeded(publicInstrumentDetails)) {
        return {
            text: publicInstrumentDetails.data.name || publicInstrumentDetails.data.external_id,
            shouldTranslate: false,
        };
    }

    return 'thematic_search.public_instruments.detail.title';
};

export function triggerFetchPublicInstrumentDetails(apiInput: ISinglePublicInstrumentApiInput) {
    if (!validateEntityIdBeforeFetch({
        entityId: apiInput.publicInstrumentId,
        entityType: CoreApiEntityType.publicInstruments,
    })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TPublicInstrument, ISinglePublicInstrumentApiInput>({
        detailsEntity: publicInstrumentDetailsEntity,
        entityType: CoreApiEntityType.publicInstruments,
        api: api.publicInstruments.fetchPublicInstrumentDetails,
        apiInputSelector: () => apiInput,
        refreshMode: () => publicInstrumentDetailsEntity.select().data.id !== apiInput.publicInstrumentId,
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchPublicInstrumentDetails(
    instrumentUpdater: (currentInstrument: TTPublicInstrumentPatch) => void,
) {
    return publicInstrumentDetailsEntity.async.update<TTPublicInstrumentPatch, TPublicInstrument>({
        api: api.publicInstruments.patchPublicInstrument,
        apiInputSelector: () => ({
            /* We always pass the external_id (potentially overruled by the instrumentUpdater)
               as it is mandatory when patching */
            external_id: publicInstrumentDetailsEntity.select().data.external_id,
            ...getOnlyChangedProperties(
                publicInstrumentDetailsEntity.select().data,
                produce(publicInstrumentDetailsEntity.select().data, instrumentUpdater),
            ),
            id: publicInstrumentDetailsEntity.select().data.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPublicInstrumentsFetch();
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
    });
}

export function triggerCreatePublicInstrument(publicInstrument: TTPublicInstrumentCreate) {
    return publicInstrumentDetailsEntity.async.create<TTPublicInstrumentCreate, TPublicInstrument>({
        api: api.publicInstruments.createPublicInstrument,
        apiInputSelector: () => publicInstrument,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPublicInstrumentsFetch();
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_DETAIL,
                params: {
                    publicInstrumentId: apiResult.id,
                    publicInstrumentTab: DEFAULT_PUBLIC_INSTRUMENT_TAB_KEY,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export function triggerDeletePublicInstrument(identifier?: IBaseSingleEntityApiInput) {
    return apiEntityAsyncDeleteAction<TPublicInstrument>({
        detailsEntity: publicInstrumentDetailsEntity,
        api: api.publicInstruments.deletePublicInstrument,
        apiInputSelector: () => identifier || {
            id: publicInstrumentDetailsEntity.select().data.id,
        },
        onPreSuccess: () => {
            triggerResetPublicInstrumentsFetch();
            redirectTo({ routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENTS_LIST });
        },
    });
}
