import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

/**
 * Converts "CamelCase" strings to "camel_case".
 */
export function camelCaseToSnakeCase(camelCaseInput: string): string {
    return camelCaseInput[0].toLowerCase()
        + camelCaseInput.slice(1, camelCaseInput.length)
            .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

/**
 * Converts "A full sentence" strings to "AFullSentence".
 */
export function sentenceToPascalCase(sentence: string): string {
    return upperFirst(camelCase(sentence));
}

/**
 * Converts "PascalCase" strings to "Pascal case".
 */
export function pascalCaseToSentence(pascalCase: string): string {
    return pascalCase.replace(
        /^[a-z]|[A-Z]/g,
        (v, i) => (i === 0 ? v.toUpperCase() : ` ${v.toLowerCase()}`),
    );
}
