import React from 'react';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';
import {
    portfolioGroupDetailsEntity,
    triggerPatchPortfolioGroupDetails,
} from 'state/entities/portfolioMgmt/portfolioGroupDetails';

export default function PortfolioGroupAccessibleBy() {
    const portfolioGroupData = portfolioGroupDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={portfolioGroupData}
            nameForDisplay={portfolioGroupData.name}
            asyncEntityPatchTrigger={(values) => triggerPatchPortfolioGroupDetails((currentPortfolioGroup) => {
                /* eslint-disable no-param-reassign */
                currentPortfolioGroup.readable_by = values.readable_by;
                currentPortfolioGroup.modifiable_by = values.modifiable_by;
                /* eslint-enable no-param-reassign */
            })}
        />
    );
}
