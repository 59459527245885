import React from 'react';
import GenericReportsList from 'views/apps/StoryTeller/GenericReportsList';

export default function ReportsList() {
    return (
        <GenericReportsList
            box={{
                title: 'apps.story_teller.reports.list.title',
            }}
        />
    );
}
