import {
    IApiEntityListResponse,
    IApiEntityListResponseWithPageNr,
    IBaseFetchEntityListApiInput,
} from '../../models/api.models';
import { FIRST_PAGE_NR, LIST_RESPONSE_NO_COUNT, MAX_PAGE_ITEM_LIMIT } from '../../config/coreApi.config';

export async function fetchAllPagesOfApiEntity<ApiInput, ApiEntityData>({
    apiInput,
    api,
    offset,
    limit = MAX_PAGE_ITEM_LIMIT,
}: {
    apiInput: ApiInput;
    api: (apiInput: ApiInput) => Promise<IApiEntityListResponse<ApiEntityData>>;
} & Omit<IBaseFetchEntityListApiInput, 'pageNr'>): Promise<IApiEntityListResponseWithPageNr<ApiEntityData>> {
    const apiEntityListResponse = await api({
        ...apiInput,
        offset,
        limit,
    });

    if (apiEntityListResponse.offset === null) {
        return {
            ...apiEntityListResponse,
            pageNr: FIRST_PAGE_NR,
        };
    }

    const nextPageResult = await fetchAllPagesOfApiEntity({
        apiInput,
        api,
        offset: apiEntityListResponse.offset,
        limit,
    });

    return {
        next: null,
        offset: null,
        results: apiEntityListResponse.results
            .concat(nextPageResult.results),
        pageNr: FIRST_PAGE_NR,
        count: LIST_RESPONSE_NO_COUNT,
    };
}
