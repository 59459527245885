import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { getCurrentDate } from '@snipsonian/core/cjs/date/currentDate';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { presentFileForDownload } from 'utils/file/presentFileForDownload';
import { getPropPathsOfObjectList, IPropertyPath } from 'utils/object/getPropPathsOfObjectList';
import { mapObjectToFlatStringArray } from 'utils/object/mapObjectToFlatStringArray';

/**
 * BOM = Byte Order Mark
 * Needed for proper encoding of special characters like Ä and ö.
 * See https://donatstudios.com/CSV-An-Encoding-Nightmare
 *
 * p.s. Because these bytes have to be at the beginning of the csv string,
 * it prevents including the - excel specific - trick ("sep=;"\n) at the beginning of the file to specify
 * which separator/delimiter excel should use to open the file.
 * As a result, users will have to 'File > Open' the csv files so that they can specify the delimiter (or
 * they should configure ; as the default delimiter/separator)
 */
const UTF8_BOM = '\ufeff';

const CSV_ROW_SEPARATOR = '\r\n';
const ROW_SEPARATOR_WITHIN_FIELD = '\n';
const CSV_MIME_TYPE = 'text/csv;charset=utf-8';
const CSV_FILE_EXTENSION = 'csv';
/* values should not start with one of = + @ - because that could indicate a formula */
const CSV_INJECTION_PROTECTION_REGEX = /^[=+@-]{1}.*$/;
const FIELD_CONTAINING_ROW_SEPARATOR_REGEX = new RegExp(CSV_ROW_SEPARATOR, 'g');

interface IExportListAsCsvProps {
    baseFilename: string;
    list: TAnyObject[];
    colSeparator?: string; // default ;
    /* if 'includeSeparatorHintForExcel' is true, the BOM (see above) will not be included
       causing special chars not to be encoded properly */
    includeSeparatorHintForExcel?: boolean; // default false
}

export function exportListAsCsv({
    baseFilename,
    ...otherInput
}: IExportListAsCsvProps) {
    const content = formatListAsCsvContentString(otherInput);

    presentFileForDownload({
        data: toCsvBlob(content),
        filename: getCsvFilename(baseFilename),
    });
}

export function formatListAsCsvContentString({
    list,
    colSeparator = ';',
    includeSeparatorHintForExcel = false,
}: Omit<IExportListAsCsvProps, 'baseFilename'>): string {
    const propPaths = getPropPathsOfObjectList({ list });

    const columnHeaders = propPaths.map((propPath) => propPath.path);

    const rows: string[][] = [columnHeaders]
        .concat(convertListItemsToCsvRows({
            list,
            expectedPropPaths: propPaths,
            colSeparator,
        }));

    return convertRowDataToCsvContentString({
        rows,
        colSeparator,
        includeSeparatorHintForExcel,
    });
}

function toCsvBlob(content: string) {
    return new Blob([content], { type: CSV_MIME_TYPE });
}

function getCsvFilename(baseFilename: string) {
    return `${baseFilename}_${getCurrentTimestampFormatted()}.${CSV_FILE_EXTENSION}`;
}

function getCurrentTimestampFormatted() {
    return formatDate({
        date: getCurrentDate(),
        format: DATE_FORMAT.TIMESTAMP,
    });
}

function convertListItemsToCsvRows({
    list,
    expectedPropPaths,
    colSeparator,
}: {
    list: TAnyObject[];
    expectedPropPaths: IPropertyPath[];
    colSeparator: string;
}): string[][] {
    const WHEN_TO_SURROUND_FIELD_WITH_DOUBLE_QUOTES_REGEX =
        new RegExp(`"|${colSeparator}|${ROW_SEPARATOR_WITHIN_FIELD}`); // = /"|;|\n/

    return list.map((listItem) => mapObjectToFlatStringArray({
        obj: listItem,
        expectedPropPaths,
        valueTransformer: transformFieldValueIfNecessary,
    }));

    function transformFieldValueIfNecessary(fieldValue: string): string {
        return [fieldValue]
            .map(replaceNewLines)
            .map(replaceDoubleQuotes)
            .map(surroundWithDoubleQuotesIfNecessary)
            .pop();
    }

    function replaceNewLines(fieldValue: string) {
        return fieldValue.replace(FIELD_CONTAINING_ROW_SEPARATOR_REGEX, ROW_SEPARATOR_WITHIN_FIELD);
    }

    function replaceDoubleQuotes(fieldValue: string) {
        /* replace with two double quotes (this is for escaping double quotes according to csv specs) */
        return fieldValue.replace(/"/g, '""');
    }

    function surroundWithDoubleQuotesIfNecessary(fieldValue: string) {
        if (fieldValue.search(WHEN_TO_SURROUND_FIELD_WITH_DOUBLE_QUOTES_REGEX) > -1) {
            return `"${fieldValue}"`;
        }

        return fieldValue;
    }
}

function convertRowDataToCsvContentString({
    rows,
    colSeparator,
    includeSeparatorHintForExcel,
}: {
    rows: string[][];
    colSeparator: string;
    includeSeparatorHintForExcel: boolean;
}): string {
    const prefix = includeSeparatorHintForExcel
        ? getSeparatorHintForExcel(colSeparator)
        : UTF8_BOM;

    return prefix
        // eslint-disable-next-line arrow-body-style
        + rows.map((row) => {
            return row.map((value) => {
                /* Values starting with =+@\- could potentially be harmful, that's why we escape these values with ' */
                if (value.match(CSV_INJECTION_PROTECTION_REGEX)) {
                    return `'${value}`;
                }

                return value;
            }).join(colSeparator);
        }).join(CSV_ROW_SEPARATOR);
}

function getSeparatorHintForExcel(colSeparator: string) {
    return `"sep=${colSeparator}"\n`;
}
