import { SchemaErrorType, stringLike, string, array } from '@console/common/utils/schema';
import {
    CORE_API_ENTITY_TYPE_TO_ID_PREFIX,
    CORE_API_ENTITY_TYPE_TO_ID_REGEX,
    CoreApiEntityType,
} from '../../config/coreEntities.config';

export const entityId = (entityType: CoreApiEntityType) =>
    stringLike()
        .format(CORE_API_ENTITY_TYPE_TO_ID_REGEX[entityType], `${CORE_API_ENTITY_TYPE_TO_ID_PREFIX[entityType]}...`);

export const entityIdArray = ({
    entityType,
    entityName,
}: {
    entityType: CoreApiEntityType;
    entityName: string;
}) => {
    const entityIdRegex = CORE_API_ENTITY_TYPE_TO_ID_REGEX[entityType];

    return array()
        .of(string())
        .test({
            name: SchemaErrorType.ArrayOfEntityIds,
            test: (values) => {
                if (values) {
                    return values.every((value) => value.search(entityIdRegex) > -1);
                }
                return true;
            },
            params: {
                entityType: entityName,
            },
            // eslint-disable-next-line no-template-curly-in-string,prefer-template
            message: '${path} should contain a list of ' + entityName + ' identifiers.',
        });
};
