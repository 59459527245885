import * as XLSX from 'xlsx';
import isString from '@snipsonian/core/cjs/is/isString';

export type TExcelData = TExcelRow[]; // an array (rows) of arrays (columns)
type TExcelRow = unknown[]; // value per column
type TColumnHeaderNames = string[];

export interface IExtractColumnDataOutOfExcelFileResponse {
    isFileMissing?: boolean;
    isFileWrongFormat?: boolean;
    isColumnDataMissing?: boolean;
    columnData?: TExcelData;
}

export interface IExtractColumnDataOutOfExcelFileProps extends IReadWorksheetOfExcelFileProps {
    desiredColumnHeaderNames: TColumnHeaderNames;
    expectedFileFormat: string;
}

export interface IReadWorksheetOfExcelFileProps {
    file: File;
    options?: {
        sheetNumber?: number; // the worksheet to read - default 0 (= first worksheet)
    };
}

export async function extractColumnDataOutOfExcelFile({
    file,
    desiredColumnHeaderNames,
    options,
    expectedFileFormat,
}: IExtractColumnDataOutOfExcelFileProps): Promise<IExtractColumnDataOutOfExcelFileResponse> {
    if (!file) {
        return {
            isFileMissing: true,
        };
    }

    if (file.type !== expectedFileFormat) {
        return {
            isFileWrongFormat: true,
        };
    }

    const data = await readWorksheetOfExcelFile({
        file,
        options,
    });

    const extractedColumnData = extractColumnDataOutOfExcelData({
        data,
        desiredColumnHeaderNames,
    });

    if (!extractedColumnData) {
        return {
            isColumnDataMissing: true,
        };
    }

    return {
        columnData: extractedColumnData,
    };
}

export function readWorksheetOfExcelFile({
    file,
    options = {},
}: IReadWorksheetOfExcelFileProps): Promise<TExcelData> {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();

            const {
                sheetNumber = 0,
            } = options;

            reader.onload = (e) => {
                /* Parse data from file */
                const bstr = e.target.result;
                const workbook = XLSX.read(bstr, { type: 'binary' });

                /* Get worksheet */
                const worksheetName = workbook.SheetNames[sheetNumber];
                const worksheet = workbook.Sheets[worksheetName];

                /* Convert array of arrays */
                /**
                 * See https://github.com/SheetJS/sheetjs#utility-functions
                 *
                 * If header is specified, the first row is considered a data row.
                 * When 'header' is 1, the default is to generate blank rows.
                 * The flag 'blankrows' must be set to false to skip blank rows.
                 *
                 * header 1 >> Generate an array of arrays
                 */
                const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });

                resolve(data as TExcelData);
            };

            reader.readAsBinaryString(file);
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * This function will extract the desired column data.
 * - it will search for a row containing the desired column names
 * - and will then return the data of those columns
 *
 * @param data An array (rows) of arrays (columns)
 * @param desiredColumnHeaderNames String array containing the header column names which you are interested in.
 * @return Returns an array (rows) of arrays (columns) where the columns will be in the same order
 * as the specified desired column names.
 */
export function extractColumnDataOutOfExcelData({
    data,
    desiredColumnHeaderNames,
}: {
    data: TExcelData;
    desiredColumnHeaderNames: TColumnHeaderNames;
}): TExcelData {
    const headerRowMatchInfo = findHeaderRowContainingColumnNames({ data, desiredColumnHeaderNames });

    if (!headerRowMatchInfo) {
        return null;
    }

    return data.reduce(
        (accumulator: TExcelData, row, rowIndex) => {
            if (rowIndex > headerRowMatchInfo.rowIndex) {
                const desiredCells: TExcelRow = headerRowMatchInfo.columnIndexes
                    .map((columnIndex: number) => row[columnIndex]);

                accumulator.push(desiredCells);
            }

            return accumulator;
        },
        [],
    );
}

interface IHeaderRowInfo {
    rowIndex: number;
    columnIndexes: number[];
}

function findHeaderRowContainingColumnNames({
    data,
    desiredColumnHeaderNames,
}: {
    data: TExcelData;
    desiredColumnHeaderNames: TColumnHeaderNames;
}): IHeaderRowInfo {
    const matchInfo: IHeaderRowInfo = {
        rowIndex: 0,
        columnIndexes: [],
    };

    let row: TExcelRow;
    let cell: unknown;
    let headerName: string;

    for (matchInfo.rowIndex = 0; matchInfo.rowIndex < data.length; matchInfo.rowIndex++) {
        row = data[matchInfo.rowIndex];
        matchInfo.columnIndexes = [];

        for (let headerIndex = 0; headerIndex < desiredColumnHeaderNames.length; headerIndex++) {
            headerName = desiredColumnHeaderNames[headerIndex];

            if (headerIndex > 0 && matchInfo.columnIndexes.length === 0) {
                // no match for the first header, so no point in continuing to match the remaining headers to this row
                break;
            }

            for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                cell = row[columnIndex];

                if (isString(cell) && cell.toUpperCase() === headerName.toUpperCase()) {
                    matchInfo.columnIndexes.push(columnIndex);
                    break;
                }
            }
        }

        if (matchInfo.columnIndexes.length === desiredColumnHeaderNames.length) {
            return matchInfo;
        }
    }

    return null;
}
