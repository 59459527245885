import { hexToRgba } from 'utils/styling/colorUtils';

export * from 'utils/styling/colorUtils';

export const OPACITY = {
    VERY_LOW: 0.80,
    LOW: 0.64,
    MEDIUM: 0.48,
    HIGH: 0.32,
    VERY_HIGH: 0.16,
    EXTREME: 0.08,
};

export const APP_COLORS = {
    SYSTEM: {
        BLACK: '#050B21',
        WHITE: '#FFFFFF',
        BACKGROUND: '#F0F4F7',
        ICON: '#183755',
    },
    NAV: {
        DARKEST: '#050B21',
        DARKER: '#24293E',
        DARK: '#444B66',
        LIGHT: '#E6EBFD',
    },
    PRIMARY: {
        100: '#E5EBFF',
        200: '#93A9E8',
        300: '#7792E2',
        400: '#5D7DDC',
        500: '#466AD7',
        600: '#2F57D2',
        700: '#294EBE',
        800: '#2546AB',
        900: '#213F9A',
    },
    SECONDARY: {
        100: '#DCDBFF',
        200: '#B2B0FF',
        300: '#8C89FF',
        400: '#6A65FF',
        500: '#4B45FF',
        600: '#2C25FF',
        700: '#1008FF',
        800: '#0800ED',
        900: '#0700D5',
    },
    TERTIARY: {
        100: '#2C369D',
        200: '#28318F',
        300: '#242D82',
        400: '#212976',
        500: '#1E256B',
        600: '#1B2160',
        700: '#181E56',
        800: '#161B4D',
        900: '#141845',
    },
    TEXT: {
        100: '#D1D7DD',
        200: '#A3AFBB',
        300: '#8B9BAA',
        400: '#748799',
        500: '#183755',
        600: '#5D7388',
        700: '#465F77',
        800: '#2F4B66',
        900: '#10263C',
    },
    GREY: {
        100: '#DFE5EA',
        200: '#CAD4DD',
        300: '#A3AFBB',
        400: '#8092A3',
        500: '#748799',
        600: '#5D7388',
        700: '#465F77',
        800: '#2F4B66',
        900: '#183755',
    },
    FEEDBACK: {
        INFO: '#466AD7',
        WARNING: '#FF8F40',
        ERROR: '#F03A4E',
        SUCCESS: '#23AF64',
    },
};

interface IButtonColorFlavor {
    TEXT: string;
    TEXT_HOVER: string;
    TEXT_FOCUS: string;
    TEXT_DISABLED: string;
    BACKGROUND: string;
    BACKGROUND_HOVER: string;
    BACKGROUND_FOCUS: string;
    BACKGROUND_DISABLED: string;
    BORDER_FOCUS: string;
    BARE_HOVER: string;
}

export const BUTTON_COLORS: { [flavor: string]: IButtonColorFlavor } = {
    PRIMARY: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
        TEXT_HOVER: APP_COLORS.SYSTEM.WHITE,
        TEXT_FOCUS: APP_COLORS.SYSTEM.WHITE,
        TEXT_DISABLED: hexToRgba(APP_COLORS.SYSTEM.WHITE, 0.7),
        BACKGROUND: APP_COLORS.PRIMARY['500'],
        BACKGROUND_HOVER: APP_COLORS.PRIMARY['500'],
        BACKGROUND_FOCUS: APP_COLORS.PRIMARY['500'],
        BACKGROUND_DISABLED: APP_COLORS.GREY['200'],
        BORDER_FOCUS: hexToRgba(APP_COLORS.SYSTEM.BLACK, 1),
        BARE_HOVER: APP_COLORS.SYSTEM.BLACK,
    },
    SECONDARY: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
        TEXT_HOVER: APP_COLORS.SYSTEM.WHITE,
        TEXT_FOCUS: APP_COLORS.SYSTEM.WHITE,
        TEXT_DISABLED: hexToRgba(APP_COLORS.SYSTEM.WHITE, 0.7),
        BACKGROUND: hexToRgba(APP_COLORS.TEXT['500'], 0.56),
        BACKGROUND_HOVER: hexToRgba(APP_COLORS.TEXT['500'], 0.8),
        BACKGROUND_FOCUS: hexToRgba(APP_COLORS.TEXT['500'], 0.8),
        BACKGROUND_DISABLED: APP_COLORS.GREY['200'],
        BORDER_FOCUS: APP_COLORS.NAV.DARKEST,
        BARE_HOVER: hexToRgba(APP_COLORS.TEXT['500'], 0.8),
    },
    GREY: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
        TEXT_HOVER: APP_COLORS.SYSTEM.WHITE,
        TEXT_FOCUS: APP_COLORS.SYSTEM.WHITE,
        TEXT_DISABLED: hexToRgba(APP_COLORS.SYSTEM.WHITE, 0.7),
        BACKGROUND: APP_COLORS.GREY['200'],
        BACKGROUND_HOVER: APP_COLORS.GREY['200'],
        BACKGROUND_FOCUS: APP_COLORS.GREY['200'],
        // BACKGROUND_DISABLED: hexToRgba(APP_COLORS.GREY['200'], 0.33),
        // using the same hexToRgba value for both border as background gave other colors in chrome
        // TODO so best don't use them for BACKGROUND_DISABLED
        BACKGROUND_DISABLED: APP_COLORS.GREY['100'],
        BORDER_FOCUS: APP_COLORS.GREY['800'],
        BARE_HOVER: APP_COLORS.PRIMARY['500'],
    },
    GREY_INVERTED: {
        TEXT: APP_COLORS.GREY['400'],
        TEXT_HOVER: APP_COLORS.GREY['400'],
        TEXT_FOCUS: APP_COLORS.GREY['400'],
        TEXT_DISABLED: hexToRgba(APP_COLORS.GREY['400'], 0.33),
        BACKGROUND: APP_COLORS.GREY['100'],
        BACKGROUND_HOVER: APP_COLORS.GREY['100'],
        BACKGROUND_FOCUS: APP_COLORS.GREY['100'],
        BACKGROUND_DISABLED: hexToRgba(APP_COLORS.GREY['100'], 0.33),
        BORDER_FOCUS: APP_COLORS.GREY['400'],
        BARE_HOVER: APP_COLORS.PRIMARY['500'], // TODO ??
    },
    TEXT: {
        TEXT: APP_COLORS.SYSTEM.WHITE, // TODO
        TEXT_HOVER: APP_COLORS.SYSTEM.WHITE, // TODO
        TEXT_FOCUS: APP_COLORS.SYSTEM.WHITE, // TODO
        TEXT_DISABLED: hexToRgba(APP_COLORS.SYSTEM.WHITE, 0.7), // TODO
        BACKGROUND: APP_COLORS.TEXT['500'],
        BACKGROUND_HOVER: APP_COLORS.TEXT['500'],
        BACKGROUND_FOCUS: APP_COLORS.TEXT['500'],
        BACKGROUND_DISABLED: APP_COLORS.GREY['100'],
        BORDER_FOCUS: APP_COLORS.GREY['800'],
        BARE_HOVER: APP_COLORS.PRIMARY['500'],
    },
    WHITE: {
        TEXT: APP_COLORS.PRIMARY['500'], // TODO
        TEXT_HOVER: APP_COLORS.PRIMARY['500'], // TODO
        TEXT_FOCUS: APP_COLORS.PRIMARY['500'], // TODO
        TEXT_DISABLED: hexToRgba(APP_COLORS.PRIMARY['500'], 0.7), // TODO
        BACKGROUND: APP_COLORS.SYSTEM.WHITE,
        BACKGROUND_HOVER: APP_COLORS.SYSTEM.WHITE,
        BACKGROUND_FOCUS: APP_COLORS.SYSTEM.WHITE,
        BACKGROUND_DISABLED: APP_COLORS.GREY['100'],
        BORDER_FOCUS: APP_COLORS.GREY['800'],
        BARE_HOVER: APP_COLORS.PRIMARY['200'],
    },
};

export const HEADER_COLORS = {
    TEXT: '#3A3F44', // TODO which is the correct color?
    BACKGROUND: APP_COLORS.SYSTEM.BACKGROUND,
    USER: {
        BACKGROUND: APP_COLORS.SYSTEM.BLACK,
    },
};

export const MENU_COLORS = {
    ACTIVE_ITEM: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
        BACKGROUND: APP_COLORS.PRIMARY['500'],
    },
    SELECTED_ITEM: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
    },
    TOP: {
        TEXT: hexToRgba(APP_COLORS.SYSTEM.WHITE, OPACITY.LOW),
        TEXT_HOVER: APP_COLORS.NAV.LIGHT,
        BACKGROUND: APP_COLORS.NAV.DARKEST,
    },
    CONTEXT: {
        TEXT: hexToRgba(APP_COLORS.SYSTEM.WHITE, 0.6),
        TEXT_HOVER: APP_COLORS.SYSTEM.WHITE,
        BACKGROUND: APP_COLORS.NAV.DARKER,
        BACKGROUND_HOVER: APP_COLORS.NAV.DARK,
    },
    TITLE: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
    },
    FOOTER: {
        TEXT: APP_COLORS.SYSTEM.WHITE,
    },
    ICON: {
        BASIC: {
            A: '#FFFFFF',
            B: '#A8B8EC',
            C: '#C0CEFA',
            D: '#A8B8EC',
            E: '#7F97E4',
        },
        HOVER: {
            A: '#FDD8D3',
            B: '#FCAAA9',
            C: '#F77C87',
            D: '#F05B76',
            E: '#E7285D',
        },
    },
};
