import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { getSelectedStoryManagerDatabaseDetail } from '../entities/storyTeller/storyManagerDatabaseDetail';

export function redirectToStoryManagerFindAndReplaceAndResetView() {
    const databaseId = getSelectedStoryManagerDatabaseDetail().id;
    redirectTo({
        routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_FIND_AND_REPLACE,
        params: {
            databaseId,
            outputKeyId: 0,
            scenarioIndex: 0,
        },
    });
}
