import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS } from 'config/styling/colors';
import { BOX_SHADOW } from 'config/styling/elevation';
import { formatPercentage } from 'utils/number/percentageUtils';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import { CHART } from 'config/styling/chart';

interface IPublicProps {
    baseCurrency?: string;
    dataSelector: TMarkedDateDataSelector;
}

export type TMarkedDateDataSelector = (state: IState) => IMarkedDateData;

export interface IMarkedDateData {
    formattedDate: string;
    valueItems: IMarkedDateValueItem[];
}

export interface IMarkedDateValueItem extends IMarkedDateValueItemStyleProps {
    label: string;
    amount: number;
    percentage?: number;
}

interface IMarkedDateValueItemStyleProps {
    labelColor: string;
}

const useStyles = makeStyles((theme) => ({
    MarkedDateBox: {
        ...mixins.flexColTopLeft(),
        ...mixins.typo({ size: 14 }),
        position: 'absolute',
        top: -115,
        right: CHART.MARGIN_RIGHT,
        padding: theme.spacing(1),
        borderRadius: 4,
        backgroundColor: APP_COLORS.SYSTEM.WHITE,
        boxShadow: BOX_SHADOW.CARD,

        '& .__date': {
            ...mixins.typoBold({ size: 10 }),
            padding: theme.spacing(0, 1, 1, 1),
        },
    },
    MarkedDateBoxItem: {
        borderBottom: 0,

        '& td': {
            padding: theme.spacing(1, 1, 0, 1),
        },

        '& .__label': {
            ...mixins.typoBold(),
            color: ({ labelColor }: IMarkedDateValueItemStyleProps) => labelColor,
        },

        '& .__amount': {
            textAlign: 'right',
        },
    },
}));

function MarkedDateBox({
    baseCurrency,
    dataSelector,
    state,
}: IPublicProps & IObserveProps) {
    const classes = useStyles({ labelColor: null });
    const data = dataSelector(state);

    if (!data) {
        return null;
    }

    return (
        <div className={classes.MarkedDateBox}>
            <div className="__date">
                {data.formattedDate}
            </div>
            <table>
                <tbody>
                    {data.valueItems.map((valueItem, index) => {
                        const key = `MarkedDateBoxItem-${index}`;

                        return (
                            <MarkedDateBoxItem
                                key={key}
                                baseCurrency={baseCurrency}
                                {...valueItem}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

function MarkedDateBoxItem({
    label,
    labelColor,
    amount,
    percentage,
    baseCurrency,
}: IMarkedDateValueItem & { baseCurrency?: string }) {
    const classes = useStyles({ labelColor });

    return (
        <tr className={classes.MarkedDateBoxItem}>
            <td className="__label">
                <Translate msg={label} />
            </td>
            <td className="__amount">
                {formatAmount(amount, { currency: baseCurrency || null })}
            </td>
            {isSet(percentage) && (
                <td className="__percentage">
                    {formatPercentage(percentage)}
                </td>
            )}
        </tr>
    );
}

export function initMarkedDateBox({
    notifications,
}: { notifications: StateChangeNotification[] }) {
    return observe<IPublicProps>(
        notifications,
        MarkedDateBox,
    );
}
