import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';

export interface IChartLegendProps {
    className?: string;
    variant?: TChartLegendVariant;
    items: IChartLegendItem[];
}

export type TChartLegendVariant = 'horizontal' | 'vertical';

export interface IChartLegendItem extends IChartLegendItemStyleProps {
    label: TLabel;
    highlightedText?: TLabel;
}

interface IChartLegendItemStyleProps {
    color: string;
}

const useStyles = makeStyles((theme) => ({
    ChartLegend: {
        margin: theme.spacing(1),

        '&.--horizontal': {
            ...mixins.flexRow({ alignMain: 'center', alignCross: 'center', wrap: 'wrap' }),
        },

        '&.--vertical': {
            ...mixins.flexColCenterLeft(),
            ...mixins.widthMax(),

            '& .__chartLegendItem': {
                display: 'grid',
                gridTemplateColumns: '20px 50px 1fr',
                gridTemplateRows: 'auto',
            },

            '& .__highlightedText': {
                justifySelf: 'end',
            },
        },
    },
    ChartLegendItem: {
        ...mixins.flexRow({ alignMain: 'center', alignCross: 'center', wrap: 'nowrap' }),
        padding: theme.spacing(0.5, 2),
        fontSize: 12,

        '& .__indicator': {
            marginRight: theme.spacing(0.5),
            width: theme.spacing(1),
            height: theme.spacing(1),

            backgroundColor: ({ color }: IChartLegendItemStyleProps) => color,
            borderRadius: '50%',
        },

        '& .__highlightedText': {
            marginRight: theme.spacing(1),
            fontWeight: 700,
        },
    },
}));

export function ChartLegend({
    className,
    variant = 'horizontal',
    items,
}: IChartLegendProps) {
    const classes = useStyles({ color: null });

    return (
        <div
            className={clsx(
                classes.ChartLegend,
                '__chartLegend',
                `--${variant}`,
                className,
            )}
        >
            {items.map((item, index) => {
                const key = `chart-legend-item_${index}`;

                return (
                    <ChartLegendItem
                        key={key}
                        {...item}
                    />
                );
            })}
        </div>
    );

    function ChartLegendItem({
        color,
        label,
        highlightedText,
    }: IChartLegendItem) {
        const itemClasses = useStyles({ color });

        return (
            <div
                className={clsx(
                    itemClasses.ChartLegendItem,
                    '__chartLegendItem',
                )}
            >
                <div className="__indicator" />

                {highlightedText && (
                    <div className="__highlightedText">
                        <Text label={highlightedText} />
                    </div>
                )}

                <Text label={label} />
            </div>
        );
    }
}
