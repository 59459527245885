import React, { ReactNode } from 'react';
import initActionableReactObservableState
    from '@snipsonian/react-observable-state/cjs/init/initActionableReactObservableState';
import { IActionableStoreForComp } from '@snipsonian/react-observable-state/cjs/init/types';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';

type TObserve = <PublicProps = TAnyObject>(
    notifications: StateChangeNotification[],
    WrappedComponent: React.ElementType,
) => React.ComponentType<PublicProps>;

const {
    ObservableStateProvider,
    observe: observeOrig,
    observeXL: observeXLOrig,
} = initActionableReactObservableState<IState, StateChangeNotification>();

export const StoreProvider = ObservableStateProvider;

export const observe = observeOrig as TObserve;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IObserveProps extends IActionableStoreForComp<IState, StateChangeNotification> {}

export const observeXL = observeXLOrig;

export interface IPublicPropsWithChildren {
    children: ReactNode;
}
