import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TI18nLabelOrString } from 'models/general.models';
import DetailFieldsList, {
    IDetailFieldsListProps,
    IOnEditFieldProps,
} from 'views/common/detail/DetailFieldsList';
import Modal, { TModalMaxWidth } from 'views/common/layout/Modal';
import ExtendedInputForm, {
    IExtendedInputFormProps,
    IFormValues, IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import useIsMounted from 'utils/react/hooks/useIsMounted';

export type { IDetailField } from 'views/common/detail/DetailFieldsList';

const GLOBAL_FIELD_KEY = 'GLOBAL_FIELD_KEY';

interface IDetailFieldsListWithEditModalProps
// eslint-disable-next-line @typescript-eslint/ban-types
<FieldKey extends string = string, EditGlobalFormValues extends IFormValues = {}>
    extends Omit<IDetailFieldsListProps<FieldKey>, 'onEditField' | 'onEditGlobal'> {
    id: string;
    editGlobalForm?: IEditForm<EditGlobalFormValues>;
    editFieldForms?: IEditForms;
}

export interface IEditForms {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [fieldKey: string]: IEditForm<any>;
}

export interface IEditForm<FormValues extends IFormValues>
    extends Omit<IExtendedInputFormProps<FormValues>, 'children' | 'name' | 'cancel'> {
    modalMaxWidth?: TModalMaxWidth;
    modalTitle?: TI18nLabelOrString;
}

export default function DetailFieldsListWithEditModal
// eslint-disable-next-line @typescript-eslint/ban-types
<FieldKey extends string = string, EditGlobalFormValues extends IFormValues = {}>({
    id,
    editGlobalForm,
    editFieldForms,
    ...detailFieldsListProps
}: IDetailFieldsListWithEditModalProps<FieldKey, EditGlobalFormValues>) {
    const [fieldKeyBeingEdited, setFieldKeyBeingEdited] = React.useState<string>(null);
    const isMounted = useIsMounted();

    const activeEditFieldForm = isSet(fieldKeyBeingEdited) && fieldKeyBeingEdited !== GLOBAL_FIELD_KEY
        ? editFieldForms[fieldKeyBeingEdited]
        : null;

    const modalMaxWidth = activeEditFieldForm
        ? activeEditFieldForm.modalMaxWidth
        : editGlobalForm
            ? editGlobalForm.modalMaxWidth
            : undefined;

    const modalTitle = (activeEditFieldForm && activeEditFieldForm.modalTitle)
        ? activeEditFieldForm.modalTitle
        : (editGlobalForm && editGlobalForm.modalTitle)
            ? editGlobalForm.modalTitle
            : '';

    // eslint-disable-next-line @typescript-eslint/ban-types
    const commonFormFields: Pick<IExtendedInputFormProps<{}>, 'cancel'> = {
        cancel: {
            onCancel: closeEditModal,
        },
    };

    return (
        <div id={id}>
            <DetailFieldsList
                {...detailFieldsListProps}
                onEditField={openEditModalForSelectedField}
                onEditGlobal={isSet(editGlobalForm) ? openEditGlobalModal : undefined}
            />

            <Modal
                id={`${id}_modal`}
                title={modalTitle}
                open={isSet(fieldKeyBeingEdited)}
                onClose={closeEditModal}
                maxWidth={modalMaxWidth}
            >
                {activeEditFieldForm && (
                    <ExtendedInputForm
                        name={`${id}_editForm_${fieldKeyBeingEdited}`}
                        {...commonFormFields}
                        {...activeEditFieldForm}
                        submit={{
                            onSubmit: submitEditModalAndCloseOnSuccess,
                        }}
                    />
                )}

                {!activeEditFieldForm && editGlobalForm && (
                    <ExtendedInputForm<EditGlobalFormValues>
                        name={`${id}_editForm_global`}
                        {...commonFormFields}
                        {...editGlobalForm}
                        submit={{
                            onSubmit: submitEditModalAndCloseOnSuccess,
                        }}
                    />
                )}
            </Modal>
        </div>
    );

    function openEditModalForSelectedField({ fieldKey }: IOnEditFieldProps<FieldKey>) {
        setFieldKeyBeingEdited(fieldKey);
    }

    function openEditGlobalModal() {
        setFieldKeyBeingEdited(GLOBAL_FIELD_KEY);
    }

    function closeEditModal() {
        if (isMounted.current) {
            setFieldKeyBeingEdited(null);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function submitEditModalAndCloseOnSuccess(onSubmitProps: IOnSubmitProps<any>) {
        const onSubmitAction = activeEditFieldForm
            ? activeEditFieldForm.submit.onSubmit
            : editGlobalForm.submit.onSubmit;

        return onSubmitAction(onSubmitProps)
            .then(closeEditModal);
    }
}
