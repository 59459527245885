import {
    IFetchInstrumentPerformancePastApiInput,
    IInstrumentPerformancePast,
    ITimeseriesPastData,
} from '../../models/portfolioMgmt/instrumentPerformancePast.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { getInstrumentsFromCache } from './instrumentsCache';
import { post } from '../coreApiRequestWrapper';

export async function fetchInstrumentPerformancePast({
    isin,
    currency,
    universe_type,
    startDate,
    sampleFrequency,
    instrumentAmount,
}: IFetchInstrumentPerformancePastApiInput) {
    const mapOfIsin = [isin];

    const instrumentInfo = await getInstrumentsFromCache({
        instrumentIds: mapOfIsin,
        currency,
        universe_type,
    });

    return post<IInstrumentPerformancePast, ITimeseriesPastData>({
        url: CoreApiPath.TIMESERIES_PAST,
        body: {
            method: 'BUY_AND_HOLD',
            method_settings: {
                base_currency: currency,
                start_date: startDate,
                sample_frequency: sampleFrequency,
            },
            portfolio: {
                [isin]: instrumentAmount,
            },
        },
        mapResponse: ({ data }) => ({
            isin,
            instrumentInfo,
            ...data,
        }),
    });
}
