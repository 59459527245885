import React from 'react';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { makeStyles } from 'views/styling';
import { ILineChartOptions } from 'models/lineChart.models';
import d3 from 'utils/libs/d3';
import {
    determineAxisAmountTicks,
    determineAxisTimeTicks,
    determineXTimeScale,
    determineYLinearScale,
} from 'utils/chart/chartConfig.utils';
import { determineLineChartSizeWithoutAxis } from 'utils/chart/lineChartUtils';
import { CHART_STYLING, COLOR_OPACTITY_WHEN_UN_SELECTED } from 'config/styling/chart';
import { APP_COLORS } from 'config/styling/colors';
import { ILineChartAreasData, ILineChartData, ILineDataItem } from './types';
import Text from '../widget/Text';
import YAxis from './YAxis';
import XAxis from './XAxis';
import { YAxisLabel } from './YAxisLabel';
import PathLine from './PathLine';
import AreaBetweenTwoLines from './AreaBetweenTwoLines';

export interface IGenericLineChartProps {
    id: string;
    options: ILineChartOptions;
    data: ILineChartData<Date, number>[];
    selectedAreaColor?: string;
    onSelectArea?: (color: string | null) => void; /* will be null when an area gets deselected */
    selectedDate?: Date;
    onSelectDate?: (date: Date | null) => void; /* will be null when a date gets deselected */
}

const DEFAULT_LINE_COLOR = APP_COLORS.SYSTEM.WHITE;
const DEFAULT_AREA_COLOR = APP_COLORS.GREY[300];

const useStyles = makeStyles(() => ({
    GenericDonutChart: {
        width: '100%',
        position: 'relative',
    },
}));

export default function GenericLineChart({
    id,
    options,
    data: rawData,
    selectedAreaColor,
    onSelectArea,
    // selectedDate, // TODO
    // onSelectDate,
}: IGenericLineChartProps) {
    const classes = useStyles();
    const data = options.shouldStackLines ? getStackedData() : rawData;
    const {
        chartWidth,
        chartHeight,
    } = determineLineChartSizeWithoutAxis(options);

    const yValues = getAllYValues();
    const xValues = getAllXValues();
    const minMaxXValues = d3.extent(xValues);
    let minMaxYValues = d3.extent(yValues);

    const xAxisTicks = determineAxisTimeTicks({
        xDates: xValues,
    });
    const yAxisTicks = determineAxisAmountTicks({
        minAmount: minMaxYValues[0],
        maxAmount: minMaxYValues[1],
        tickLinesLength: chartWidth,
        autoTickValues: false,
        alwaysIncludeZeroTick: true,
        widerTickValues: true,
    });

    if (yAxisTicks.tickValues) {
        minMaxYValues = d3.extent(yAxisTicks.tickValues);
    }

    const yScale = determineYLinearScale({
        globalMinMaxAmount: minMaxYValues,
        chartHeight,
    }).nice();
    const xScale = determineXTimeScale({
        xDates: xValues,
        chartWidth,
    });

    return (
        <div className={classes.GenericDonutChart}>
            <svg
                id={id}
                style={{
                    maxWidth: options.dimensions.maxWidth,
                    maxHeight: options.dimensions.maxHeight,
                }}
                viewBox={`0 0 ${options.dimensions.maxWidth} ${options.dimensions.maxHeight}`}
                preserveAspectRatio="xMidYMid meet"
            >
                {options.axis.y.text && (
                    <g transform={`translate(0, ${options.axis.y.marginTop})`}>
                        <YAxisLabel
                            label={options.axis.y.text.label}
                            chartHeight={chartHeight}
                            paddingLeft={options.axis.y.text.paddingLeft}
                        />
                    </g>
                )}
                <g transform={`translate(${options.axis.y.width}, ${options.axis.y.marginTop})`}>
                    <YAxis
                        yScale={yScale}
                        {...yAxisTicks}
                        showTickLines
                        tickLineColor={CHART_STYLING.colors.neutral['400']}
                        tickLineStrokeDasharray="0.25 6"
                        tickLineStrokeLinecap="round"
                        textColor={CHART_STYLING.colors.neutral['900']}
                        textSize={10}
                        specialTickLines={[{
                            yValue: 0,
                            strokeColor: CHART_STYLING.colors.neutral['300'],
                            strokeLinecap: 'round',
                        }]}
                    />

                    <g transform={`translate(0, ${chartHeight})`}>
                        <XAxis
                            xScale={xScale}
                            textColor={CHART_STYLING.colors.neutral['900']}
                            textSize={10}
                            tickFormatter={xAxisTicks.tickFormatter}
                            tickValues={minMaxXValues}
                        />
                    </g>

                    {options.shouldIncludeAreas && getAreasData().map(({ dataPoints, areaColor, label, key }) => {
                        const lastAreaDataPoint = getLastItemOfArray(dataPoints);
                        const opacity = determineOpacityBasedOnSelection(areaColor);

                        return (
                            <g key={`line_chart_area_${key}`}>
                                <AreaBetweenTwoLines
                                    xScale={xScale}
                                    yScale={yScale}
                                    areaData={dataPoints}
                                    fillColor={areaColor}
                                    opacity={opacity}
                                    onAreaClicked={() => onClickArea(areaColor)}
                                />
                                {(lastAreaDataPoint.y0 !== lastAreaDataPoint.y1) && (
                                    <text
                                        transform={`translate(${options.labels.paddingLeft}, 0)`}
                                        x={xScale(lastAreaDataPoint.x)}
                                        y={yScale((lastAreaDataPoint.y0 + lastAreaDataPoint.y1) / 2)}
                                        dominantBaseline="middle"
                                        fontWeight="700"
                                        fontSize="12px"
                                        fill={areaColor}
                                        opacity={opacity}
                                    >
                                        <Text label={label} />
                                    </text>
                                )}
                            </g>
                        );
                    })}

                    {data.map(({ dataPoints, lineColor, key }) => (
                        <PathLine
                            key={`line_chart_line_${key}`}
                            xScale={xScale}
                            yScale={yScale}
                            lineData={dataPoints}
                            strokeColor={lineColor || DEFAULT_LINE_COLOR}
                        />
                    ))}
                </g>
            </svg>
        </div>
    );

    function getStackedData(): ILineChartData<Date, number>[] {
        let prevStackedLineDataPoints: ILineDataItem<Date, number>[] = [];

        return rawData.map((lineData, lineIndex) => {
            const currentLineStackedDataPoints = lineData.dataPoints.map((lineDataPoint, dataPointIndex) => ({
                ...lineDataPoint,
                y: lineDataPoint.y + (lineIndex !== 0 ? prevStackedLineDataPoints[dataPointIndex].y : 0),
            }));

            prevStackedLineDataPoints = currentLineStackedDataPoints;

            return {
                ...lineData,
                dataPoints: currentLineStackedDataPoints,
            };
        });
    }

    function getAllYValues() {
        return data.reduce(
            (allYValues, { dataPoints }) => allYValues.concat(dataPoints.map(({ y }) => y)),
            [],
        );
    }

    function getAllXValues() {
        return data[0].dataPoints.map(({ x }) => x);
    }

    function getAreasData(): ILineChartAreasData<Date, number>[] {
        return data.map(({ areaColor, ...otherData }, lineIndex) => ({
            ...otherData,
            areaColor: areaColor || DEFAULT_AREA_COLOR,
            dataPoints: data[lineIndex].dataPoints.map((lineDataPoint, dataPointIndex) => ({
                x: lineDataPoint.x,
                y0: lineIndex !== 0 ? data[lineIndex - 1].dataPoints[dataPointIndex].y : 0,
                y1: lineDataPoint.y,
            })),
        }));
    }

    function determineOpacityBasedOnSelection(areaColor: string): number {
        if (!selectedAreaColor) {
            /* no area selected */
            return null;
        }

        if (selectedAreaColor === areaColor) {
            /* this area selected --> leave color untouched */
            return null;
        }

        /* other area selected --> blur this one */
        return COLOR_OPACTITY_WHEN_UN_SELECTED;
    }

    function onClickArea(newAreaColor: string) {
        if (selectedAreaColor === newAreaColor) {
            /* re-click on already selected area --> de-select */
            onSelectArea(null);
        } else {
            onSelectArea(newAreaColor);
        }
    }
}
