import React from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';
import { INavigateToRoute } from 'models/routing.models';
import { TI18nLabelOrString } from 'models/general.models';
import { Z_INDEX } from 'config/styling/elevation';
import ConfirmationModal from 'views/common/layout/ConfirmationModal';
import { redirectTo } from 'views/routes';
import getRouteMatchByPath from 'utils/routing/getRouteMatchByPath';

/**
 * To conditionally ask confirmation if the user indeeds wants to navigate to the new location.
 *
 * Inspiration from:
 * - https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 * - https://medium.com/@nikoskleidis/customised-confirm-navigation-prompt-with-react-router-v6-d04c8756ba52
 * (the 2nd link also has an example how to do it in the next version of react-router - currently in beta)
 */

interface IPublicProps {
    enabled: boolean; // this guard is only enabled when this field is true
    message: TI18nLabelOrString;
    shouldAllowRoute?: (nextRoute: INavigateToRoute) => boolean;
}

export default function RouteLeavingGuard({
    enabled,
    message,
    shouldAllowRoute,
}: IPublicProps) {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<boolean>(false);
    const [hasUserConfirmedNavigation, setHasUserConfirmedNavigation] = React.useState<boolean>(false);
    const [nextRoute, setNextRoute] = React.useState<INavigateToRoute>(null);

    React.useEffect(
        () => {
            if (hasUserConfirmedNavigation && nextRoute) {
                redirectTo(nextRoute);
            }
        },
        [hasUserConfirmedNavigation, nextRoute],
    );

    return (
        <>
            <Prompt
                when={enabled}
                message={onRouteChange}
            />

            <ConfirmationModal
                id="route-leaving-guard-modal"
                messageLabel={message}
                confirmLabel="common.confirmation.leave_route_even_when_form_changes.yes"
                cancelLabel="common.confirmation.leave_route_even_when_form_changes.no"
                open={isConfirmationModalOpen}
                onClose={closeConfirmationModal}
                onConfirm={confirmNavigation}
                zIndex={Z_INDEX.ROUTE_LEAVE_PROMPT}
            />
        </>
    );

    /**
     * Will be called with the next location and action the user is attempting to navigate to.
     * Return a string to show a prompt to the user or true to allow the transition.
     */
    function onRouteChange(nextLocation: Location): boolean {
        if (hasUserConfirmedNavigation) {
            return true;
        }

        const { route, match } = getRouteMatchByPath(nextLocation.pathname);
        const matchingRoute: INavigateToRoute = {
            routeKey: route.routeKey,
            params: match.params,
        };

        if (shouldAllowRoute && shouldAllowRoute(matchingRoute)) {
            return true;
        }

        setIsConfirmationModalOpen(true);
        setNextRoute(matchingRoute);

        return false;
    }

    function closeConfirmationModal() {
        setIsConfirmationModalOpen(false);
    }

    function confirmNavigation() {
        setIsConfirmationModalOpen(false);
        setHasUserConfirmedNavigation(true);
    }
}
