import { IStateStorageConfig } from '@snipsonian/observable-state/cjs/store/stateStorage';
import { IState } from 'models/state.models';
import { IAuthState } from 'models/state/auth.models';
import { II18nState } from 'models/state/i18n.models';
import { IListPageVars, IUiState } from 'models/state/ui.models';
import { IEntitiesState } from 'models/state/entities.models';
import { IAppConfigState } from 'models/state/appConfig.models';
import { BASE_BROWSER_STORAGE_KEY } from 'config/browserStorage.config';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import 'state/entities/allEntities';

let initialState: IState;
let stateStorageConfig: IStateStorageConfig<IState>;

export function getInitialState(): IState {
    if (!initialState) {
        initialState = {
            auth: {
                authenticatedUser: null,
                loginRedirectUrl: null,
                flowStatus: null,
            },
            appConfig: {
                tenant: null,
            },
            i18n: {
                locale: null,
                areTranslationsRefreshed: false,
                showTranslationKeys: false,
            },
            ui: {
                currentRoute: {
                    location: null,
                    prevLocation: null,
                    appShellConfig: null,
                },
                appMenu: {
                    selectedItemId: null,
                    isSelecting: false,
                    prefersCollapsed: false,
                },
                flashMessages: [],
                appModals: {
                    createPortfolioReport: {
                        open: false,
                        extraData: null,
                    },
                    entityStillUsed: {
                        open: false,
                        extraData: null,
                    },
                },
                tooltips: {
                    closed: {},
                    notAgain: {},
                },
                pages: {},
            },
            entities: getEntitiesManager().getEntitiesInitialState() as unknown as IEntitiesState,
        };
    }

    return initialState;
}

export function getStateStorageConfig(): IStateStorageConfig<IState> {
    if (!stateStorageConfig) {
        const browserStorageKey = BASE_BROWSER_STORAGE_KEY;

        stateStorageConfig = {
            local: {
                browserStorageKey,
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                getStatePartToSave: (state) => ({
                    auth: {
                        authenticatedUser: state.auth.authenticatedUser,
                        flowStatus: state.auth.flowStatus,
                    } as IAuthState,
                    i18n: {
                        locale: state.i18n.locale,
                    } as II18nState,
                    ui: {
                        appMenu: state.ui.appMenu,
                        tooltips: {
                            /**
                             * The 'notAgain' tooltips are stored in local storage so that they are remembered.
                             * But the 'closed' tooltips are not stored, so they will again be shown after
                             * e.g. page refresh.
                             */
                            notAgain: state.ui.tooltips.notAgain,
                        },
                    } as IUiState,
                }),
            },
            session: {
                browserStorageKey,
                getStatePartToSave: (state) => ({
                    auth: {
                        loginRedirectUrl: state.auth.loginRedirectUrl,
                    } as IAuthState,
                    appConfig: {
                        tenant: state.appConfig.tenant,
                    } as IAppConfigState,
                    ui: {
                        currentRoute: {
                            /* prevLocation in session state so that it is still there on page refresh
                               but not remembered across tabs */
                            prevLocation: state.ui.currentRoute.prevLocation,
                        },
                        /* pages in session state so that not remembered long time & not carried over to another tab */
                        pages: state.ui.pages,
                    } as IUiState,
                }),
            },
        };
    }

    return stateStorageConfig;
}

export function removeStoredStatePartsNotToBeKeptOnInit(draftState: IState) {
    if (draftState?.ui?.pages) {
        Object.values(draftState.ui.pages).forEach((uiPage) => {
            /**
             * We don't want to keep the active sortColumn because:
             * - otherwise a user can't go back to the default sorting by the back-end (based on entity id's)
             * - the default sorting is more performant
             */
            if ((uiPage as IListPageVars<unknown>).sorting) {
                (uiPage as IListPageVars<unknown>).sorting = null;
            }
        });
    }
}
