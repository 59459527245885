import { getCurrentYear } from '@snipsonian/core/cjs/date/currentDate';
import {
    nowAsDayJs, parseInputDate, TDateToFormat, TDateManipulateUnitType, toEndOfDay,
} from './dateUtils';

export function getEndOfTodayAsDate(): Date {
    return toEndOfDay(nowAsDayJs()).toDate();
}

export interface ISpecificDateInput {
    year?: number;
    month: number;
    day: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    millis?: number;
}

export function getSpecificDate({
    year = getCurrentYear(),
    month,
    day,
    hours = 0,
    minutes = 0,
    seconds = 0,
    millis = 0,
}: ISpecificDateInput) {
    return new Date(year, month - 1, day, hours, minutes, seconds, millis);
}

export function yearOffset({ date, yearsToAdd }: { date?: TDateToFormat; yearsToAdd: number }) {
    return offsetFromDate({
        date,
        offsetToAdd: yearsToAdd,
        offsetUnitType: 'year',
    });
}

export function quarterOffset({ date, quartersToAdd }: { date?: TDateToFormat; quartersToAdd: number }) {
    return offsetFromDate({
        date,
        offsetToAdd: quartersToAdd,
        offsetUnitType: 'quarter', /* requires QuarterOfYear plugin */
    });
}

export function monthOffset({ date, monthsToAdd }: { date?: TDateToFormat; monthsToAdd: number }) {
    return offsetFromDate({
        date,
        offsetToAdd: monthsToAdd,
        offsetUnitType: 'month',
    });
}

export function weekOffset({ date, weeksToAdd }: { date?: TDateToFormat; weeksToAdd: number }) {
    return offsetFromDate({
        date,
        offsetToAdd: weeksToAdd,
        offsetUnitType: 'week',
    });
}

export function dayOffset({ date, daysToAdd }: { date?: TDateToFormat; daysToAdd: number }) {
    return offsetFromDate({
        date,
        offsetToAdd: daysToAdd,
        offsetUnitType: 'day',
    });
}

function offsetFromDate({
    date = nowAsDayJs(),
    offsetToAdd,
    offsetUnitType,
}: {
    date?: TDateToFormat;
    offsetToAdd: number;
    offsetUnitType: TDateManipulateUnitType | 'quarter';
}) {
    return parseInputDate(date)
        .add(offsetToAdd, offsetUnitType as TDateManipulateUnitType);
}
