import React from 'react';
import clsx from 'clsx';
import InputNumberField, {
    IInputNumberFieldProps,
    IOnChangeNumberInputProps,
} from 'views/common/inputs/base/InputNumberField';
import { makeStyles, mixins } from 'views/styling';
import ExtendedInputWrapper, { prependLabelWithPrefix } from 'views/common/inputs/extended/ExtendedInputWrapper';
import { IExtendedInputBaseProps } from 'views/common/inputs/extended/ExtendedInputText';
import { ExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';

interface IExtendedInputNumberProps
    // eslint-disable-next-line max-len
    extends IExtendedInputBaseProps, Omit<IInputNumberFieldProps, 'id' | 'name' | 'value' | 'onChange' | 'error' | 'emphasizeError'> {
    shouldPrefixPlaceholder?: boolean; // default true
    shouldPrefixNoDataLabel?: boolean; // default true
}

const useStyles = makeStyles((theme) => ({
    ReadOnlyExtendedInputNumber: {
        ...mixins.flexColCenterLeft(),
        paddingBottom: theme.spacing(1),
    },
}));

export default function ExtendedInputNumber({
    readOnly = false,
    formField,
    wrapper,
    placeholder,
    shouldPrefixPlaceholder = true,
    shouldPrefixNoDataLabel = true,
    noDataLabel,
    ...inputFieldProps
}: IExtendedInputNumberProps) {
    const classes = useStyles();

    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, readOnlyForm, setFieldValue }) => {
                const adjustedPlaceholder = prependLabelWithPrefix({
                    label: placeholder,
                    labelPrefix,
                    shouldPrefixLabel: shouldPrefixPlaceholder,
                });
                const adjustedNoDataLabel = noDataLabel && prependLabelWithPrefix({
                    label: noDataLabel,
                    labelPrefix,
                    shouldPrefixLabel: shouldPrefixNoDataLabel,
                });

                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChange({ value }: IOnChangeNumberInputProps) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                return (
                    <ExtendedInputWrapper
                        disabled={inputFieldProps.disabled}
                        readOnly={isFieldReadOnly}
                        hasError={!!formField.error && formField.emphasizeError}
                        {...wrapper}
                        className={clsx(wrapper.className, isFieldReadOnly && classes.ReadOnlyExtendedInputNumber)}
                    >
                        <InputNumberField
                            id={formField.fieldId}
                            name={formField.fieldName}
                            value={formField.value as number}
                            disabled={isFieldReadOnly}
                            onChange={onChange}
                            error={formField.error}
                            emphasizeError={formField.emphasizeError}
                            placeholder={adjustedPlaceholder}
                            noDataLabel={adjustedNoDataLabel}
                            crudStylingType={formField.isDiff ? 'edited' : null}
                            {...inputFieldProps}
                        />
                    </ExtendedInputWrapper>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
