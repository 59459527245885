import React from 'react';
import { horizonDetailsEntity, triggerPatchHorizonDetails } from 'state/entities/portfolioMgmt/horizonDetails';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function HorizonAccessibleBy() {
    const horizonDetailsData = horizonDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={horizonDetailsData}
            nameForDisplay={getDefaultTranslationFromApiLabel(horizonDetailsData.name)}
            asyncEntityPatchTrigger={triggerPatchHorizonDetails}
        />
    );
}
