import { IDonutChartOptions } from 'models/donutChart.models';

export function determineDonutChartCenter({
    pie,
}: IDonutChartOptions) {
    return {
        centerX: pie.centerX || pie.outerRadius,
        centerY: pie.centerY || pie.outerRadius,
    };
}
