import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { observe } from 'views/observe';

interface INotifiableProps {
    className?: string;
    renderContent: () => React.ReactNode;
}

export interface INotifiableConfig extends INotifiableProps {
    notifications: StateChangeNotification[];
}

function Notifiable({
    className,
    renderContent,
}: INotifiableProps) {
    return (
        <div className={className}>
            {renderContent()}
        </div>
    );
}

export function initNotifiable({
    notifications,
}: { notifications: StateChangeNotification[] }) {
    return observe<INotifiableProps>(
        notifications,
        Notifiable,
    );
}
