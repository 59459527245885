import { PolicyAlgorithmType } from '../../models/policies/enhancedPolicyDetails.models';

export function isPolicyOfTypeModelPortfolio(algorithmType: PolicyAlgorithmType): boolean {
    return algorithmType === PolicyAlgorithmType.MODEL_PORTFOLIO;
}

export function isPolicyOfTypeMetaPortfolio(algorithmType: PolicyAlgorithmType): boolean {
    return algorithmType === PolicyAlgorithmType.META_PORTFOLIO;
}

export function isPolicyOfTypeRiskBased(algorithmType: PolicyAlgorithmType): boolean {
    return algorithmType === PolicyAlgorithmType.RISK_BASED;
}

export function isPolicyOfCompositionBasedType(algorithmType: PolicyAlgorithmType): boolean {
    return isPolicyOfTypeMetaPortfolio(algorithmType) || isPolicyOfTypeModelPortfolio(algorithmType);
}
