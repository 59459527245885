import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { THEME } from 'config/styling/theme';
import { IPublicPropsWithChildren } from 'views/observe';

export default function AppThemeProvider({ children }: IPublicPropsWithChildren) {
    return (
        <ThemeProvider theme={THEME}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}
