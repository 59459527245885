import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { string } from '@typsy/schema-validation/dist/baseSchemas/stringSchema';
import { SchemaErrorType } from '../types';

interface IPhoneFormat {
    country: string;
    validation: RegExp;
}

const PHONE_REGEXES: IPhoneFormat[] = [
    {
        country: '+971',
        validation: /^5[0-9]{8}$/,
    },
    {
        country: '+44',
        validation: /^7[0-9]{9}$/,
    },
    {
        country: '+1',
        validation: /^[0-9]{10}$/,
    },
    {
        country: '+32',
        validation: /^4[0-9]{8}$/,
    },
    {
        country: '+40',
        validation: /^7[0-9]{8}$/,
    },
    {
        country: '+972',
        validation: /^5[0-9]{8}$/,
    },
    /* Default non-specific format for numbers from countries that are not defined */
    {
        country: '',
        validation: /^\+[0-9]{7,15}$/,
    },
];

const GENERAL_PHONE_REGEX = /^\+[0-9]{3,}$/;

export const phoneNumber = () =>
    string({ checkCsvInjectionRisk: false }) // no cvs-injection-risk check because phone has to start with a +
        .nullable()
        .test(
            SchemaErrorType.CountryPhoneFormat,
            // eslint-disable-next-line no-template-curly-in-string
            '${path} is not a valid phone number',
            (value) => {
                if (!isSetString(value)) {
                    return true;
                }

                const detectedPhoneFormat: IPhoneFormat =
                    PHONE_REGEXES.find((phoneFormat) => value.includes(phoneFormat.country));

                return value.replace(detectedPhoneFormat.country, '').search(detectedPhoneFormat.validation) > -1;
            },
        )
        .format(GENERAL_PHONE_REGEX, '', SchemaErrorType.PhoneFormat);
