import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { IBaseFetchEntityListApiInput } from '@console/core-api/models/api.models';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { TPoliciesData } from '@console/core-api/models/portfolioMgmt/policy.models';
import { TI18nLabelOrString } from 'models/general.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS } from 'config/styling/colors';
import { api } from 'api';
import { getTenantSettings } from 'state/appConfig/selectors';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import ContentBox from 'views/common/layout/ContentBox';
import { makeStyles, mixins } from 'views/styling';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import ContentTitle from 'views/common/layout/ContentTitle';
import IconButton from 'views/common/buttons/IconButton';
import { ArrowRightIcon } from 'views/common/icons';
import UserBackground from 'views/assets/img/backgrounds/user_background.png';
import UserGroupBackground from 'views/assets/img/backgrounds/user_group_background.png';
import PolicyBackground from 'views/assets/img/backgrounds/policy_background.png';
import PortfolioBackground from 'views/assets/img/backgrounds/portfolio_background.png';
import { observe } from 'views/observe';

const COUNT_PLACEHOLDER_TEXT = '...';
const DASHBOARD_ENTITY_COUNT_FILTER: IBaseFetchEntityListApiInput = {
    limit: 1,
    count: true,
};

const useStyles = makeStyles((theme) => ({
    DashboardRow: {
        ...mixins.flexRow({ alignMain: 'center', wrap: 'wrap' }),
    },
    DashboardEntityBox: {
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'multiply',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundSize: '250px, 100px',
        backgroundColor: '#A8B8EC',

        '&.__users': {
            backgroundImage: `url(${UserBackground})`,
        },
        '&.__userGroups': {
            backgroundImage: `url(${UserGroupBackground})`,
        },
        '&.__policies': {
            backgroundImage: `url(${PolicyBackground})`,
            backgroundSize: '500px',
            backgroundPositionX: 'right',
        },
        '&.__portfolios': {
            backgroundImage: `url(${PortfolioBackground})`,
        },

        ...mixins.widthHeightPixels({ w: 560, h: 200 }),
        padding: theme.spacing(3, 0, 0, 4),
        borderRadius: 5,
        margin: theme.spacing(1),

        '& .__entityName': {
            ...mixins.typo({ size: 17 }),
            paddingBottom: theme.spacing(3),
        },
        '& .__entityCount': {
            ...mixins.typoBold({ size: 24, color: APP_COLORS.SYSTEM.WHITE }),
            paddingBottom: theme.spacing(4),
        },
        '& .__entityListLink': {
            ...mixins.flexRowCenterRight(),
            paddingRight: theme.spacing(4),
        },

        '&:hover': {
            ...mixins.widthHeightPixels({ w: 564, h: 204 }),
            margin: theme.spacing(0.7),
            backgroundColor: '#7792E2',
            cursor: 'pointer',

            '& .__entityName': {
                ...mixins.typo({ size: 17, color: APP_COLORS.SYSTEM.WHITE }),
            },
        },
    },
}));

export default observe(
    [StateChangeNotification.APP_CONFIG_TENANT],
    Dashboard,
);

function Dashboard() {
    const classes = useStyles();
    const tenantSettings = getTenantSettings();

    const arePoliciesEnabled = tenantSettings?.policies?.isEnabled;

    const [asyncUsers] = useAsyncFetchOnMount({
        fetcher: () => api.users.fetchUsers(DASHBOARD_ENTITY_COUNT_FILTER),
    });
    const [asyncUserGroups] = useAsyncFetchOnMount({
        fetcher: () => api.userGroups.fetchUserGroups(DASHBOARD_ENTITY_COUNT_FILTER),
    });
    const [asyncPolicies] = useAsyncFetchOnMount<TPoliciesData>({
        fetcher: () => {
            if (arePoliciesEnabled) {
                return api.policies.fetchPolicies(DASHBOARD_ENTITY_COUNT_FILTER);
            }

            return Promise.resolve(null);
        },
    });
    const [asyncPortfolios] = useAsyncFetchOnMount({
        fetcher: () => api.portfolios.fetchPortfolios(DASHBOARD_ENTITY_COUNT_FILTER),
    });

    return (
        <ContentBox titleLabel="main.dashboard.title" noBackground>
            <div className={classes.DashboardRow}>
                {renderDashboardEntityBox({
                    className: '__users',
                    routeKey: ROUTE_KEY.R_USERS_LIST,
                    title: 'user_mgmt.users.title',
                    count: asyncUsers?.data?.count,
                })}
                {renderDashboardEntityBox({
                    className: '__userGroups',
                    routeKey: ROUTE_KEY.R_USER_GROUPS_LIST,
                    title: 'user_mgmt.user_groups.title',
                    count: asyncUserGroups?.data?.count,
                })}
            </div>
            <div className={classes.DashboardRow}>
                {arePoliciesEnabled && renderDashboardEntityBox({
                    className: '__policies',
                    routeKey: ROUTE_KEY.R_POLICIES_LIST,
                    title: 'portfolio_mgmt.policies.title',
                    count: asyncPolicies?.data?.count,
                })}
                {renderDashboardEntityBox({
                    className: '__portfolios',
                    routeKey: ROUTE_KEY.R_PORTFOLIOS_LIST,
                    title: 'portfolio_mgmt.portfolios.title',
                    count: asyncPortfolios?.data?.count,
                })}
            </div>
        </ContentBox>
    );

    function renderDashboardEntityBox({
        className,
        routeKey,
        title,
        count,
    }: {
        count: number;
        title: TI18nLabelOrString;
        className: string;
        routeKey: ROUTE_KEY;
    }) {
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
                className={clsx(classes.DashboardEntityBox, className)}
                onClick={redirectToList}
            >
                <ContentTitle
                    label={title}
                    className="__entityName"
                />
                {renderEntityCount(count)}

                <div className="__entityListLink">
                    <IconButton
                        id={`${routeKey.toLowerCase()}_button`}
                        icon={<ArrowRightIcon />}
                        isCircle
                        size="XS"
                        svgSize={24}
                        color="white"
                        onClick={redirectToList}
                    />
                </div>
            </div>
        );

        function redirectToList() {
            redirectTo({ routeKey });
        }
    }

    function renderEntityCount(count: number) {
        return (
            <div className="__entityCount">
                {isSet(count) ? formatFloat(count, { nrOfDecimals: 0 }) : COUNT_PLACEHOLDER_TEXT}
            </div>
        );
    }
}
