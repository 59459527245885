import React, { useRef, useEffect } from 'react';
import d3, { AxisDomain, CurveFactory } from 'utils/libs/d3';
import { IAreaDataItem, TXScale, TYScale } from './types';

interface IPublicProps<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number> {
    xScale: TXScale<XDomain>;
    yScale: TYScale<YDomain>;
    areaData: IAreaDataItem<XDomain, YDomain>[];
    fillColor: string;
    opacity?: number;
    curveFactory?: CurveFactory; // default curveLinear, see https://github.com/d3/d3-shape/blob/master/README.md#curves
    onAreaClicked?: () => void;
}

export default function AreaBetweenTwoLines<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number>({
    xScale,
    yScale,
    areaData,
    fillColor,
    opacity = 1,
    curveFactory,
    onAreaClicked,
}: IPublicProps<XDomain, YDomain>) {
    const ref = useRef();

    useEffect(
        () => {
            const area = d3.area<IAreaDataItem<XDomain, YDomain>>()
                .x((d) => xScale(d.x))
                .y0((d) => yScale(d.y0))
                .y1((d) => yScale(d.y1));

            if (curveFactory) {
                area.curve(curveFactory);
            }

            d3.select<SVGPathElement, unknown>(ref.current)
                .datum(areaData)
                .attr('fill', fillColor)
                .attr('stroke', 'none')
                .attr('d', area);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [xScale, yScale, areaData],
    );

    /* The style "pointerEvents: 'none'" is needed so that this path - which sets on top of other svg
       elements - does not block the capture of the mouse movement */
    return (
        <path
            ref={ref}
            style={{
                cursor: onAreaClicked ? 'pointer' : 'default',
                opacity,
            }}
            onMouseDown={onClickArea}
        />
    );

    function onClickArea() {
        if (onAreaClicked) {
            onAreaClicked();
        }
    }
}
