import { IChartDimensions } from 'models/chart.models';
import { APP_COLORS } from './colors';

export const MIN_Y_AXIS_AMOUNT_RANGE = 100;

export const PERFORMANCE_GRAPH_ELEMENT_ID = 'generic_performance_graph';

export const STANDARD_CHART_DIMENTIONS: IChartDimensions = {
    maxWidth: 1400,
    maxHeight: 500,
};

export const MODAL_CHART_DIMENTIONS: IChartDimensions = {
    maxWidth: 1200,
    maxHeight: 400,
};

export const CHART = {
    MARGIN_TOP: 24,
    MARGIN_BOTTOM: 40, // ~ height of the x axis
    MARGIN_LEFT: 60, // ~ width of the y axis
    MARGIN_RIGHT: 30,
    PAST: {
        VALUE_COLOR: '#4B45FF',
        INVESTED_COLOR: '#1E256B',
        RETURN_COLOR: '#A8B8EC',
        AREA_COLOR: '#EEF1FB',
    },
    FUTURE: {
        OPTIMISTIC_COLOR: APP_COLORS.SECONDARY['500'],
        REALISTIC_COLOR: APP_COLORS.PRIMARY['500'],
        PESSIMISTIC_COLOR: APP_COLORS.TERTIARY['500'],
        AREA_COLOR: '#EEF1FB',
    },
    Y_AXIS: {
        TICK_LINE_COLOR: '#A3AFBB',
    },
    MARKER: {
        LINE_COLOR: '#CAD4DD',
    },
    BENCHMARK_COLOR: '#22A8CF',
};

export const CHART_STYLING = {
    colors: {
        neutral: {
            50: '#FFFFFF',
            300: '#A3AFBB',
            400: '#8092A3',
            700: '#1F4061',
            900: '#183755',
        },
        typography: {
            900: '#183755',
        },
        dataVisualisation: {
            multiple: {
                1: '#23356B',
            },
        },
    },
};

export const COLOR_OPACTITY_WHEN_UN_SELECTED = 0.2;
