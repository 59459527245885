export function toIdSelector(elementId: string) {
    return `#${elementId}`;
}

/**
 * For some reason in the playwright e2e tests, clicking on a button with a selector containing dashes ('-')
 * did not work until I replcase those dashes with underscores ('_').
 */
export function cleanupToUseAsElementId(input: string) {
    return input
        .replaceAll('.', '_')
        .replaceAll('-', '_');
}

export function getElementDimensionsById(elementId: string): DOMRect {
    return document.getElementById(elementId)?.getBoundingClientRect() || {} as DOMRect;
}
