import { IActionItem } from 'views/common/buttons/ActionButtons';

export function conditionallyDisableStoryManagerActions({
    canUserModify,
    actions,
}: {
    canUserModify: boolean;
    actions: IActionItem[];
}): IActionItem[] {
    if (canUserModify) {
        return actions;
    }

    const disableAction = {
        disabled: true,
        tooltip: 'common.action.insufficient_permissions',
    };

    return actions.map((action) => ({
        ...disableAction,
        ...action,
    }));
}

/** Specifically for an icon button (that does not have a label) to add something */
export function conditionallyDisableStoryManagerAddAction({
    canUserModify,
    action,
}: {
    canUserModify: boolean;
    action: IActionItem;
}): IActionItem {
    if (canUserModify) {
        return action;
    }

    return {
        ...action,
        disabled: true,
        label: 'common.action.add_not_allowed', // overrule of the provided label
    };
}
