import {
    PortfolioTransactionMovementStatus,
    PortfolioTransactionMovementType,
} from '@console/core-api/models/portfolioMgmt/portfolioTransaction.models';
import { TTagVariant } from 'views/common/widget/Tag';

const PF_DATA_LABEL_PREFIX = 'portfolio_mgmt.portfolios.data';

export function getMovementTypeLabel(movementType: PortfolioTransactionMovementType): string {
    return `${PF_DATA_LABEL_PREFIX}.movement_type.${movementType.toLowerCase()}`;
}

export function getMovementStatusLabel(movementStatus: PortfolioTransactionMovementStatus): string {
    return `${PF_DATA_LABEL_PREFIX}.movement_status.${movementStatus.toLowerCase()}`;
}

export function getTagVariantForMovementStatus(movementStatus: PortfolioTransactionMovementStatus): TTagVariant {
    switch (movementStatus) {
        case PortfolioTransactionMovementStatus.SETTLED:
            return 'regular';
        case PortfolioTransactionMovementStatus.EXECUTED:
            return 'info';
        case PortfolioTransactionMovementStatus.NOT_EXECUTED:
        case PortfolioTransactionMovementStatus.EXPIRED:
            return 'warning';
        case PortfolioTransactionMovementStatus.CANCELLED:
            return 'error';
        default:
            return 'success';
    }
}
