import isSet from '@snipsonian/core/cjs/is/isSet';
import isNull from '@snipsonian/core/cjs/is/isNull';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import hasArrayAtLeastSameValues from '@snipsonian/core/cjs/array/verification/hasArrayAtLeastSameValues';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { TKeyMap } from '@console/common/utils/object/objectUtils';
import { array, object, SchemaErrorType, string, ObjectSchema } from '@console/common/utils/schema';
import {
    CombineTransactionCostMode,
} from '@console/core-api/models/portfolioMgmt/policy.models';
import { PolicyAlgorithmType } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import {
    isPolicyOfTypeMetaPortfolio,
    isPolicyOfTypeModelPortfolio,
    isPolicyOfTypeRiskBased,
} from '@console/bff/utils/policies/policyTypeUtils';
import { policyManagerTagsSchema } from '@console/bff/server/utils/schemas/policy.schemas';
import { IPolicySettingsRules, PolicySettingNumberType } from 'models/ui/policySettings.ui.models';
import { getPoliciesAlgorithmsConfig } from 'state/appConfig/selectors';
import {
    booleanComparedAgainstValueSchema,
    getPolicyCompositionSchema,
    maxNumberComparedAgainstRangeSchema,
    minNumberComparedAgainstRangeSchema,
    metaPortfolioCompositionElementIdsTest,
    modelPortfolioCompositionElementIdsTest,
    numberComparedAgainstRangeSchema,
    numberRangeComparedAgainstRangeSchema,
    stringComparedAgainstValueSchema,
} from 'utils/entities/portfolioMgmt/policyDetailsSchemaUtils';
import {
    getInfeasiblePolicyAllocationCategoriesIfAny,
    isLessItemsAllowedRule,
    isMoreItemsAllowedRule,
} from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { mapPolicyTagsForDisplay } from 'utils/entities/portfolioMgmt/policyUtils';
import { determineBuyUniverse } from 'utils/entities/portfolioMgmt/policyUniverseUtils';
import {
    IPolicyAllocationBoundariesFormValues,
    TPolicyDetailFormValues,
} from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';

export function getPolicyDetailsDynamicSchema({
    policySettingsRules,
}: {
    policySettingsRules: IPolicySettingsRules;
}) {
    return object({
        ...policyDetails(policySettingsRules),
        ...policyIvarUniverse(policySettingsRules),
        ...policyModelPortfolioSettings(),
        ...policyMetaPortfolioSettings(),
        ...policyExecutionConstraints(policySettingsRules),
        ...policyPortfolioConstraints(policySettingsRules),
        ...policyPortfolioUpdateConstraints(policySettingsRules),
        ...policyBenchmark(policySettingsRules),
        ...policyAllocationBoundaries(policySettingsRules),
    })
        .test({
            name: SchemaErrorType.PolicyAllocationInfeasibleLowerBoundaries,
            test: (allFields) => {
                const {
                    areThereInfeasibleBoundaries,
                    categoriesWhereLowerBoundariesAddUpToMoreThan100Percent,
                } = getInfeasiblePolicyAllocationCategoriesIfAny(
                    allFields as unknown as IPolicyAllocationBoundariesFormValues,
                );

                return !areThereInfeasibleBoundaries
                    || !isArrayWithValues(categoriesWhereLowerBoundariesAddUpToMoreThan100Percent);
            },
            // eslint-disable-next-line no-template-curly-in-string
            message: '${path} contains lower boundaries that add up to more than 100%',
        })
        .test({
            name: SchemaErrorType.PolicyAllocationInfeasibleUpperBoundaries,
            test: (allFields) => {
                const {
                    areThereInfeasibleBoundaries,
                    categoriesWhereUpperBoundariesAddUpToLessThan100Percent,
                } = getInfeasiblePolicyAllocationCategoriesIfAny(
                    allFields as unknown as IPolicyAllocationBoundariesFormValues,
                );

                return !areThereInfeasibleBoundaries
                    || !isArrayWithValues(categoriesWhereUpperBoundariesAddUpToLessThan100Percent);
            },
            // eslint-disable-next-line no-template-curly-in-string
            message: '${path} contains upper boundaries that add up to less than 100%',
        }) as unknown as ObjectSchema<TPolicyDetailFormValues>;
}

export function getPolicyAddStepOneSchema({
    isParentPolicyRequired,
}: {
    isParentPolicyRequired: boolean;
}) {
    return object({
        algorithm: string()
            .required()
            .oneOf(getPoliciesAlgorithmsConfig()),
        parentPolicyId: string()
            .when('algorithm', {
                is: (algorithm: PolicyAlgorithmType) => isSet(algorithm) && isParentPolicyRequired,
                then: (schema) => schema.required(),
            }),
    });
}

export function getParentPolicySchema({
    isParentPolicyRequired,
}: {
    isParentPolicyRequired: boolean;
}) {
    return object({
        parentPolicyId: isParentPolicyRequired
            ? string().required()
            : string(),
    });
}

function policyDetails(policySettingsRules: IPolicySettingsRules) {
    return {
        name: string().required(),
        externalId: string(),
        riskProfileId: string(),
        tags: policyManagerTags(policySettingsRules),
    };
}

function policyManagerTags(policySettingsRules: IPolicySettingsRules) {
    const {
        rule,
        boundaryValues: parentTagsIfAny,
    } = policySettingsRules.details.tags;

    return policyManagerTagsSchema
        .test({
            name: SchemaErrorType.PolicyTagsAtLeastOfParent,
            test: (value) => !isMoreItemsAllowedRule(rule)
                || hasArrayAtLeastSameValues(value, parentTagsIfAny),
            // eslint-disable-next-line no-template-curly-in-string
            message: '${path} should contain at least the tags of the parent policy',
            params: {
                parentTags: mapPolicyTagsForDisplay(parentTagsIfAny),
            },
        });
}

function policyIvarUniverse(policySettingsRules: IPolicySettingsRules) {
    const {
        rule: holdInstrumentsRule,
        boundaryValues: parentHoldInstruments,
    } = policySettingsRules.universe.instruments;
    const {
        rule: buyInstrumentsRule,
        boundaryValues: parentBuyInstruments,
    } = policySettingsRules.universe.buyInstruments;

    return {
        investmentUniverse: array()
            .of(string())
            .when('algorithm', ([algorithm], schema) => schema
                .test({
                    name: SchemaErrorType.PolicyUniverseSubsetOfParent,
                    test: (value: string[]) => !isPolicyOfTypeRiskBased(algorithm)
                        || !isLessItemsAllowedRule(holdInstrumentsRule)
                        || hasArrayAtLeastSameValues(parentHoldInstruments, value),
                    // eslint-disable-next-line no-template-curly-in-string
                    message: '${path} shouldn\'t have instruments that are not part of the parent policy',
                })),
        excludedBuyInstrumentsMap: object()
            .when(['algorithm', 'investmentUniverse'], ([algorithm, investmentUniverse]) => object()
                .test({
                    name: SchemaErrorType.PolicyBuyUniverseSubsetOfParent,
                    test: (value: TKeyMap) => {
                        if (!isPolicyOfTypeRiskBased(algorithm)) {
                            return true;
                        }

                        if (!isLessItemsAllowedRule(buyInstrumentsRule)) {
                            return true;
                        }

                        const buyUniverse = determineBuyUniverse({
                            investmentUniverse,
                            excludedBuyInstrumentsMap: value,
                        });

                        if (isNull(buyUniverse) || hasArrayAtLeastSameValues(parentBuyInstruments, buyUniverse)) {
                            return true;
                        }

                        return false;
                    },
                    // eslint-disable-next-line no-template-curly-in-string,max-len
                    message: '${path} shouldn\'t have instruments that are not part of the buy universe of the parent policy',
                })),
    };
}

function policyModelPortfolioSettings() {
    return {
        modelPortfolioComposition: object()
            .when('algorithm', {
                is: (algorithm: PolicyAlgorithmType) => isPolicyOfTypeModelPortfolio(algorithm),
                then: () => getPolicyCompositionSchema(modelPortfolioCompositionElementIdsTest),
            }),
    };
}

function policyMetaPortfolioSettings() {
    return {
        metaPortfolioComposition: object()
            .when('algorithm', {
                is: (algorithm: PolicyAlgorithmType) => isPolicyOfTypeMetaPortfolio(algorithm),
                then: () => getPolicyCompositionSchema(metaPortfolioCompositionElementIdsTest),
            }),
    };
}

function policyExecutionConstraints(policySettingsRules: IPolicySettingsRules) {
    const { hasParentPolicy } = policySettingsRules;

    return {
        baseCurrency: stringComparedAgainstValueSchema<SupportedCurrency>(
            policySettingsRules.executionConstraints.currency,
        ).required(),
        minimumTransactionAmount: minNumberComparedAgainstRangeSchema({
            ...policySettingsRules.executionConstraints.transactionAmountRange,
            hasParentPolicy,
        }),
        maximumTransactionAmount: maxNumberComparedAgainstRangeSchema({
            ...policySettingsRules.executionConstraints.transactionAmountRange,
            hasParentPolicy,
            nameOfMinField: 'minimumTransactionAmount',
        }),
        transactionCostAmount: numberComparedAgainstRangeSchema({
            ...policySettingsRules.executionConstraints.costPerTransactionAmount,
            hasParentPolicy,
        }),
        transactionCostFraction: numberComparedAgainstRangeSchema({
            ...policySettingsRules.executionConstraints.costPerTransactionFraction,
            hasParentPolicy,
        }),
        combineTransactionCostMode: stringComparedAgainstValueSchema<CombineTransactionCostMode>(
            policySettingsRules.executionConstraints.combineTransactionCostMode,
        )
            .oneOf(Object.values(CombineTransactionCostMode)),
        fractionalShares: booleanComparedAgainstValueSchema(
            policySettingsRules.executionConstraints.includeFractionalShares,
        ),
    };
}

function policyPortfolioConstraints(policySettingsRules: IPolicySettingsRules) {
    const { hasParentPolicy } = policySettingsRules;

    return {
        minMomentum: minNumberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.momentum,
            numberType: PolicySettingNumberType.PERCENTAGE_TO_1,
            hasParentPolicy,
        }),
        maxMomentum: maxNumberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.momentum,
            numberType: PolicySettingNumberType.PERCENTAGE_TO_1,
            nameOfMinField: 'minMomentum',
            hasParentPolicy,
        }),
        minPositionAmount: minNumberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.positionAmountRange,
            hasParentPolicy,
        }),
        maxPositionAmount: maxNumberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.positionAmountRange,
            hasParentPolicy,
            nameOfMinField: 'minPositionAmount',
        }),
        positionFraction: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.positionFractionRange,
            hasParentPolicy,
        }),
        minCashAmount: minNumberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.cashAmountRange,
            hasParentPolicy,
        }),
        maxCashAmount: maxNumberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.cashAmountRange,
            hasParentPolicy,
            nameOfMinField: 'minCashAmount',
        }),
        cashFraction: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioConstraints.cashFractionRange,
            hasParentPolicy,
        }),
    };
}

function policyPortfolioUpdateConstraints(policySettingsRules: IPolicySettingsRules) {
    const { hasParentPolicy } = policySettingsRules;

    return {
        minPortfolioOptimalityImprovement: numberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioUpdateConstraints.minPortfolioOptimalityImprovementFraction,
            hasParentPolicy,
        })
            .required(),
        maxPortfolioConstraintsViolation: numberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioUpdateConstraints.maxPortfolioConstraintsViolationFraction,
            hasParentPolicy,
        })
            .required(),
        minMaxCashPercentage: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioUpdateConstraints.cashFractionRange,
            hasParentPolicy,
        }),
        minPortfolioValue: numberComparedAgainstRangeSchema({
            ...policySettingsRules.portfolioUpdateConstraints.minPortfolioValue,
            hasParentPolicy,
        }),
    };
}

function policyBenchmark(policySettingsRules: IPolicySettingsRules) {
    const { hasParentPolicy } = policySettingsRules;

    return {
        benchmarkId: stringComparedAgainstValueSchema<string>(policySettingsRules.benchmark.id),
        benchmarkPositionFraction: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.positionFractionRange,
            hasParentPolicy,
        }),

        benchmarkAssetClassAlternatives: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassAlternativesRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassBonds: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassBondsRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassCash: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassCashRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassCommodities: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassCommoditiesRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassStocks: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassStocksRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassCorporate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassCorporateRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassEurobond: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassEurobondRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassEquity: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassEquityRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassGovernment: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassGovernmentRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassMoneyMarket: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassMoneyMarketRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassSukuk: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassSukukRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassFixedIncome: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassFixedIncomeRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassOther: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassOtherRange,
            hasParentPolicy,
        }),
        benchmarkAssetClassRealEstate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.assetClassRealEstateRange,
            hasParentPolicy,
        }),

        benchmarkRegionBondsAsia: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionBondsAsiaRange,
            hasParentPolicy,
        }),
        benchmarkRegionBondsEmerging: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionBondsEmergingRange,
            hasParentPolicy,
        }),
        benchmarkRegionBondsEurope: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionBondsEuropeRange,
            hasParentPolicy,
        }),
        benchmarkRegionBondsAmerica: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionBondsAmericaRange,
            hasParentPolicy,
        }),

        benchmarkRegionStocksAsia: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionStocksAsiaRange,
            hasParentPolicy,
        }),
        benchmarkRegionStocksEmerging: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionStocksEmergingRange,
            hasParentPolicy,
        }),
        benchmarkRegionStocksEurope: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionStocksEuropeRange,
            hasParentPolicy,
        }),
        benchmarkRegionStocksAmerica: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.regionStocksAmericaRange,
            hasParentPolicy,
        }),

        benchmarkBondTypeConvertible: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.bondTypeConvertibleRange,
            hasParentPolicy,
        }),
        benchmarkBondTypeCorporate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.bondTypeCorporateRange,
            hasParentPolicy,
        }),
        benchmarkBondTypeGovernment: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.bondTypeGovernmentRange,
            hasParentPolicy,
        }),

        benchmarkSectorBasicMaterials: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorBasicMaterialsRange,
            hasParentPolicy,
        }),
        benchmarkSectorCommunicationServices: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorCommunicationServicesRange,
            hasParentPolicy,
        }),
        benchmarkSectorConsumerCyclical: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorConsumerCyclicalRange,
            hasParentPolicy,
        }),
        benchmarkSectorConsumerDefensive: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorConsumerDefensiveRange,
            hasParentPolicy,
        }),
        benchmarkSectorEnergy: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorEnergyRange,
            hasParentPolicy,
        }),
        benchmarkSectorFinancialServices: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorFinancialServicesRange,
            hasParentPolicy,
        }),
        benchmarkSectorHealthcare: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorHealthcareRange,
            hasParentPolicy,
        }),
        benchmarkSectorIndustrials: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorIndustrialsRange,
            hasParentPolicy,
        }),
        benchmarkSectorRealEstate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorRealEstateRange,
            hasParentPolicy,
        }),
        benchmarkSectorTechnology: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorTechnologyRange,
            hasParentPolicy,
        }),
        benchmarkSectorUtilities: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.sectorUtilitiesRange,
            hasParentPolicy,
        }),
        relativeIvarWeight: numberComparedAgainstRangeSchema({
            ...policySettingsRules.benchmark.relativeIvarWeight,
            hasParentPolicy,
        }),
    };
}

function policyAllocationBoundaries(policySettingsRules: IPolicySettingsRules) {
    const { hasParentPolicy } = policySettingsRules;

    return {
        assetClassAlternatives: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassAlternativesRange,
            hasParentPolicy,
        }),
        assetClassBonds: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassBondsRange,
            hasParentPolicy,
        }),
        assetClassCash: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassCashRange,
            hasParentPolicy,
        }),
        assetClassCommodities: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassCommoditiesRange,
            hasParentPolicy,
        }),
        assetClassStocks: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassStocksRange,
            hasParentPolicy,
        }),
        assetClassCorporate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassCorporateRange,
            hasParentPolicy,
        }),
        assetClassEurobond: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassEurobondRange,
            hasParentPolicy,
        }),
        assetClassEquity: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassEquityRange,
            hasParentPolicy,
        }),
        assetClassGovernment: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassGovernmentRange,
            hasParentPolicy,
        }),
        assetClassMoneyMarket: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassMoneyMarketRange,
            hasParentPolicy,
        }),
        assetClassSukuk: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassSukukRange,
            hasParentPolicy,
        }),
        assetClassFixedIncome: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassFixedIncomeRange,
            hasParentPolicy,
        }),
        assetClassOther: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassOtherRange,
            hasParentPolicy,
        }),
        assetClassRealEstate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.assetClassRealEstateRange,
            hasParentPolicy,
        }),

        regionBondsAsia: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionBondsAsiaRange,
            hasParentPolicy,
        }),
        regionBondsEmerging: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionBondsEmergingRange,
            hasParentPolicy,
        }),
        regionBondsEurope: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionBondsEuropeRange,
            hasParentPolicy,
        }),
        regionBondsAmerica: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionBondsAmericaRange,
            hasParentPolicy,
        }),

        regionStocksAsia: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionStocksAsiaRange,
            hasParentPolicy,
        }),
        regionStocksEmerging: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionStocksEmergingRange,
            hasParentPolicy,
        }),
        regionStocksEurope: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionStocksEuropeRange,
            hasParentPolicy,
        }),
        regionStocksAmerica: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.regionStocksAmericaRange,
            hasParentPolicy,
        }),

        bondTypeConvertible: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.bondTypeConvertibleRange,
            hasParentPolicy,
        }),
        bondTypeCorporate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.bondTypeCorporateRange,
            hasParentPolicy,
        }),
        bondTypeGovernment: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.bondTypeGovernmentRange,
            hasParentPolicy,
        }),

        sectorBasicMaterials: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorBasicMaterialsRange,
            hasParentPolicy,
        }),
        sectorCommunicationServices: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorCommunicationServicesRange,
            hasParentPolicy,
        }),
        sectorConsumerCyclical: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorConsumerCyclicalRange,
            hasParentPolicy,
        }),
        sectorConsumerDefensive: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorConsumerDefensiveRange,
            hasParentPolicy,
        }),
        sectorEnergy: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorEnergyRange,
            hasParentPolicy,
        }),
        sectorFinancialServices: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorFinancialServicesRange,
            hasParentPolicy,
        }),
        sectorHealthcare: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorHealthcareRange,
            hasParentPolicy,
        }),
        sectorIndustrials: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorIndustrialsRange,
            hasParentPolicy,
        }),
        sectorRealEstate: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorRealEstateRange,
            hasParentPolicy,
        }),
        sectorTechnology: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorTechnologyRange,
            hasParentPolicy,
        }),
        sectorUtilities: numberRangeComparedAgainstRangeSchema({
            ...policySettingsRules.allocationBoundaries.sectorUtilitiesRange,
            hasParentPolicy,
        }),
    };
}
