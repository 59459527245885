import React from 'react';
import clsx from 'clsx';
import isString from '@snipsonian/core/cjs/is/isString';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TI18nLabelOrString } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';

interface IPublicProps {
    label: TI18nLabelOrString;
    value: string | React.ReactNode;
    variant?: 'col' | 'row'; // default 'col'
    className?: string;
    labelInBold?: boolean | string; // default false
    valueInBold?: boolean | string; // default true
    emphasizeValue?: boolean | string; // default false
}

interface IStyleProps {
    emphasizeValueColor: string;
}

const useStyles = makeStyles((theme) => ({
    LabelWithValue: {
        '&.__variant-col': {
            ...mixins.flexColTopLeft(),
        },
        '&.__variant-row': {
            ...mixins.flexRowCenterLeft(),
        },

        '&.__variant-row .__label': {
            paddingRight: theme.spacing(1),
        },

        '& .__label': {},
        '& .__value': {},

        '& .__inBold': {
            ...mixins.typoBold(),
        },
        '& .__valueEmphasized': {
            color: ({ emphasizeValueColor }: IStyleProps) => emphasizeValueColor,
        },
    },
}));

export default function LabelWithValue({
    label,
    value,
    variant = 'col',
    className,
    labelInBold = false,
    valueInBold = true,
    emphasizeValue = false,
}: IPublicProps) {
    const emphasizeValueColor = isString(emphasizeValue)
        ? emphasizeValue
        : APP_COLORS.PRIMARY['500'];
    const classes = useStyles({ emphasizeValueColor });

    return (
        <div
            className={clsx(
                classes.LabelWithValue,
                className,
                `__variant-${variant}`,
            )}
        >
            <div
                className={clsx(
                    '__label',
                    labelInBold && '__inBold',
                )}
            >
                <Translate {...toI18nLabel(label)} />
                {(variant === 'row') && <span>:</span>}
            </div>
            <div
                className={clsx(
                    '__value',
                    valueInBold && '__inBold',
                    emphasizeValue && '__valueEmphasized',
                )}
            >
                {value}
            </div>
        </div>
    );
}
