import { TApiEntity } from '@console/core-api/models/api.models';
import { IUserEntityData, UserFeatureFlag } from '@console/core-api/models/userMgmt/user.models';
import { joinParts } from '@console/common/utils/string/joinParts';

export function getUserFullName(user: IUserEntityData) {
    if (!user) {
        return '';
    }

    return joinParts([
        user.first_name,
        user.last_name,
    ], { joinSeparator: ' ' });
}

export function isUserFeatureFlagPresent(
    user: TApiEntity<IUserEntityData>,
    featureFlag: UserFeatureFlag,
): boolean {
    return user.feature_flags?.includes(featureFlag);
}

export function getUserFeatureFlagLabel(featureFlag: UserFeatureFlag): string {
    return `user_mgmt.users.detail.fields.feature_flags.${featureFlag.toLowerCase()}`;
}
