import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetObject from '@snipsonian/core/cjs/object/verification/isSetObject';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { IRiskProfileExpectedAssetClass } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { IUserCounterAccount } from '@console/core-api/models/userMgmt/user.models';
import { getStore } from 'state';
import { getDefaultTranslationFromApiLabel, getTranslator } from 'state/i18n/selectors';

const TRANSLATION_PREFIX = {
    riskProfileFields: 'portfolio_mgmt.risk_profiles.detail.fields',
    counterAccountFields: 'user_mgmt.users.detail.fields',
};

let stateTranslator: TTranslator;

function getStateTranslator() {
    if (!stateTranslator) {
        stateTranslator = getTranslator(getStore().getState());
    }

    return stateTranslator;
}

export function formatExpectedAssetClasses(expectedAssetClass: IRiskProfileExpectedAssetClass): string {
    if (!isSetObject(expectedAssetClass)) {
        return '';
    }

    return formatKeyVals({
        translationKey: `${TRANSLATION_PREFIX.riskProfileFields}.expected_asset_classes.name.label`,
        value: getDefaultTranslationFromApiLabel(expectedAssetClass.name),
    }, {
        translationKey: `${TRANSLATION_PREFIX.riskProfileFields}.expected_asset_classes.allocation.label`,
        value: getDefaultTranslationFromApiLabel(expectedAssetClass.allocation),
    });
}

export function formatCounterAccount(counterAccount: IUserCounterAccount): string {
    if (!isSetObject(counterAccount)) {
        return '';
    }

    return formatKeyVals({
        translationKey: `${TRANSLATION_PREFIX.counterAccountFields}.bank_account_number_type.label`,
        value: counterAccount.bank_account_number_type,
    }, {
        translationKey: `${TRANSLATION_PREFIX.counterAccountFields}.bank_account_number.label`,
        value: counterAccount.bank_account_number,
    }, {
        translationKey: `${TRANSLATION_PREFIX.counterAccountFields}.bank_id_type.label`,
        value: counterAccount.bank_id_type,
    }, {
        translationKey: `${TRANSLATION_PREFIX.counterAccountFields}.bank_id.label`,
        value: counterAccount.bank_id,
    });
}

function formatKeyVals(...keyVals: { translationKey: string; value: string }[]): string {
    const formattedKeyVals = keyVals
        .map((keyVal) => formatKeyVal(keyVal))
        .join(' ; ');
    return `[${formattedKeyVals}]`;
}

function formatKeyVal({ translationKey, value }: { translationKey: string; value: string }) {
    return `${getStateTranslator()(translationKey)}: ${formatValue(value)}`;
}

function formatValue(value: string) {
    return isSet(value)
        ? `'${value}'`
        : '-';
}
