import { ONE_SECOND_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';

export const DEFAULT_TIMEOUT_IN_MILLIS = 20 * ONE_SECOND_IN_MILLIS;

export const HEADER_NAME = {
    AUTHORIZATION: 'Authorization',
};

export const DEFAULT_PAGE_ITEM_LIMIT = 10;
export const MAX_PAGE_ITEM_LIMIT = 100;
export const ALLOWED_PAGE_ITEM_LIMITS = [5, 10, 20, 50, 100];

export const FIRST_PAGE_NR = 1;

export const LIST_RESPONSE_NO_COUNT = -1;

export const DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS = false;
export const ENABLE_USE_EXPECTED_RETURNS_OPTION = false;
