import React from 'react';
import { IExtendedInputBaseProps } from 'views/common/inputs/extended/ExtendedInputText';
import InputDateField, { IInputDateFieldProps, IOnChangeDateInputProps } from 'views/common/inputs/base/InputDateField';
import { ExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputWrapper from 'views/common/inputs/extended/ExtendedInputWrapper';

interface IExtendedInputNumberProps
    // eslint-disable-next-line max-len
    extends IExtendedInputBaseProps, Omit<IInputDateFieldProps, 'id' | 'value' | 'onChange' | 'error'> {}

export default function ExtendedInputDate({
    readOnly = false,
    formField,
    wrapper,
    ...inputFieldProps
}: IExtendedInputNumberProps) {
    return (
        <ExtendedInputFormContext.Consumer>
            {({ readOnlyForm, setFieldValue }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChange({ value }: IOnChangeDateInputProps) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                return (
                    <ExtendedInputWrapper
                        disabled={inputFieldProps.disabled}
                        readOnly={isFieldReadOnly}
                        hasError={!!formField.error && formField.emphasizeError}
                        {...wrapper}
                    >
                        <InputDateField
                            id={formField.fieldId}
                            value={formField.value as Date}
                            onChange={onChange}
                            error={formField.error}
                            emphasizeError={formField.emphasizeError}
                            {...inputFieldProps}
                        />
                    </ExtendedInputWrapper>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
