export enum SampleFrequencyPerformancePast {
    B = 'B',
    D = 'D',
    W = 'W',
    M = 'M',
    MS = 'MS',
    SM = 'SM',
    SMS = 'SMS',
    BM = 'BM',
    BMS = 'BMS',
    Q = 'Q',
    BQ = 'BQ',
    BQS = 'BQS',
    Y = 'Y',
    BY = 'BY',
}

export enum SampleFrequencyPerformanceFuture {
    B = 'B',
    D = 'D',
    W = 'W',
    M = 'M',
    Y = 'Y',
}

/**
 * This can have the following values: 'B' | 'D' | 'W' | 'M' | 'Y'
 * but we only offer the user a subset of those.
 */
export enum RecurringDepositFrequency {
    MONTHLY = 'M',
    YEARLY = 'Y',
}
