import React, { Fragment } from 'react';
import { mixins } from 'views/styling';
import ContentTitle from 'views/common/layout/ContentTitle';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { makeStyles, Typography } from '@material-ui/core';
import { APP_COLORS } from 'config/styling/colors';
import { KeyIcon, FindAndReplaceIcon } from 'views/common/icons';
import { getStore } from 'state';
import { triggerFlashSuccess } from 'state/ui/flashMessages.actions';
import Tag from 'views/common/widget/Tag';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import IconButton from 'views/common/buttons/IconButton';
import { triggerPatchStoryManagerConfig } from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { JSONPatchModels } from '@console/bff/models/storyteller/storymanager.models';
import VirtualList from 'react-tiny-virtual-list';
import Tooltip from 'views/common/widget/Tooltip';
import { IOutputKeyResults, IResult } from './resultCalculations';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.find_and_replace.side_content';

const OUTPUT_KEY_HEIGHT = 48;
const RESULT_HEIGHT = 64;
const MAX_RESULT_LENGTH = 60;

const useStyles = makeStyles((theme) => ({
    ResultsWrapper: {
        paddingBottom: theme.spacing(3),
        '& > .title': {
            paddingBottom: theme.spacing(1),
        },
        '& .noResults': {
            minHeight: 100,
            ...mixins.flexColCenterCenter(),
            ...mixins.typo({ color: '#A8B1BA', size: 14 }),
        },
    },
    OutputKeyResults: {
        '& .outputKey': {
            height: OUTPUT_KEY_HEIGHT,
            padding: theme.spacing(0.5),
            marginRight: theme.spacing(1),
            overflow: 'hidden',

            ...mixins.flexRowCenterLeft(),
            ...mixins.typoBold({ color: APP_COLORS.TEXT[500], size: 15 }),

            '&:hover': {
                background: theme.palette.grey[200],
                borderRadius: '4px',
            },

            '& > svg': {
                marginRight: theme.spacing(1),
            },

            '& .actions': {
                flex: 1,
                ...mixins.flexRowCenterRight(),
            },
        },
        '& .list': {
            paddingLeft: theme.spacing(3),
        },
    },
    ResultLine: {
        height: RESULT_HEIGHT,

        '&:first-child': {
            marginTop: theme.spacing(1),
        },
        '& .context': {
            paddingLeft: theme.spacing(0.5),
            ...mixins.typoBold({ color: APP_COLORS.GREY[400], size: 10 }),
        },
    },
    ResultValue: {
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),
        gap: theme.spacing(1),
        ...mixins.flexRowCenterLeft(),
        ...mixins.typo({ color: APP_COLORS.TEXT[500], size: 15 }),

        '&:hover': {
            background: theme.palette.grey[200],
            borderRadius: '4px',
        },

        '& .tooltip': {
            flex: 1,
            overflow: 'hidden',

            '& .labelButton': {
                padding: 0,

                overflow: 'hidden',
                width: '100%',
                flex: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',

                background: 'none',
                border: 'none',

                cursor: 'pointer',

                '& > .label': {
                    flex: '1',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',

                    '& .highlight': {
                        ...mixins.typoBold({ color: APP_COLORS.TEXT[500] }),
                    },
                },
            },
        },
    },
}));

interface IPublicProps {
    replaceValue: string;
    results: IOutputKeyResults;
    readonly: boolean;
}

export default function Results({ results, replaceValue, readonly }: IPublicProps) {
    const { databaseId } = useRouteParams();
    const classes = useStyles();

    return (
        <div className={classes.ResultsWrapper}>
            <ContentTitle
                variant="section-small"
                className="title"
                label={{
                    msg: Object.keys(results).length === 0 ?
                        `${TRANSLATION_PREFIX}.results.title_no_results`
                        : `${TRANSLATION_PREFIX}.results.title`,
                    placeholders: {
                        nResults: getResultsTotal(),
                        nOutputKeys: Object.keys(results).length,
                    },
                }}
            />
            {Object.keys(results).length === 0 && (
                <div className="noResults">
                    <Translate msg={`${TRANSLATION_PREFIX}.results.no_results`} />
                </div>
            )}
            <VirtualList
                height={500}
                itemCount={Object.keys(results).length}
                itemSize={(index) => {
                    const outputKey = Object.values(results)[index];
                    return OUTPUT_KEY_HEIGHT + outputKey.results.length * RESULT_HEIGHT;
                }}
                renderItem={({ index, style }) => {
                    const outputKey = Object.values(results)[index];
                    return (
                        <div key={index} className={classes.OutputKeyResults} style={style}>
                            <div className="outputKey">
                                <KeyIcon />
                                <span>{outputKey.name}</span>
                                <Tag
                                    variant="grey"
                                    label={{ text: outputKey.results.length.toString(), shouldTranslate: false }}
                                />
                                <div className="actions">
                                    <IconButton
                                        id={`outputkey-replace-all-${index}`}
                                        icon={<FindAndReplaceIcon />}
                                        size="XS"
                                        svgSize={24}
                                        variant="bare"
                                        tooltip={readonly ? 'common.action.insufficient_permissions' : {
                                            msg: `${TRANSLATION_PREFIX}.results.actions.replace_all_in_output_key`,
                                            placeholders: {
                                                outputKeyName: outputKey.name,
                                                amount: outputKey.results.length,
                                            },
                                        }}
                                        onClick={() => replaceAllResultsForOutputKey(outputKey.results)}
                                        disabled={!replaceValue || readonly}
                                    />
                                </div>
                            </div>
                            <div className="list">
                                {outputKey.results.map((result, resultIndex) => (
                                    <ResultLine
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={resultIndex}
                                        {...result}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );

    function getResultsTotal() {
        return Object.keys(results).reduce(
            (total, outputKeyId) => {
                const newTotal = total + results[outputKeyId].results.length;
                return newTotal;
            },
            0,
        );
    }

    function replaceAllResultsForOutputKey(outputKeyResults: IResult[]) {
        const jsonPatch = outputKeyResults.reduce(
            (acc, result) => {
                result.jsonPatchOperationsGetter(replaceValue).forEach((op) => acc.push(op));
                return acc;
            },
            [] as JSONPatchModels.Operation[],
        );
        triggerPatchStoryManagerConfig({
            jsonPatch,
            onSuccess: () => {
                getStore().dispatch( // eslint-disable-next-line max-len
                    triggerFlashSuccess({ translationKey: 'apps.story_teller.output_keys.find_and_replace.flash_messages.replace_success' }),
                );
            },
        });
    }

    function ResultLine({ value, context, highlight, outputKeyId, scenarioIndex, jsonPatchOperationsGetter }: IResult) {
        return (
            <div className={classes.ResultLine}>
                <div className="context">
                    <Translate {...context} />
                </div>
                <HighlightedValue />
            </div>
        );

        function HighlightedValue() {
            const valueToShow = getValueToShow();
            const parts = valueToShow.split(highlight);
            return (
                <div className={classes.ResultValue}>
                    <Tooltip
                        label={{
                            text: value,
                            shouldTranslate: false,
                        }}
                        enterDelay={350}
                        className="tooltip"
                    >
                        <button className="labelButton" onClick={selectScenario} type="button">
                            <Typography className="label">
                                {parts.map((part, index) => (index === parts.length - 1 ? (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <span key={part + index}>{part}</span>
                                ) : (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Fragment key={part + index}>
                                        <span>{part}</span>
                                        <span className="highlight">{highlight}</span>
                                    </Fragment>
                                )))}
                            </Typography>
                        </button>
                    </Tooltip>
                    <IconButton
                        id="replace-result"
                        icon={<FindAndReplaceIcon />}
                        size="XS"
                        svgSize={24}
                        variant="bare"
                        tooltip={readonly ? 'common.action.insufficient_permissions' :
                            `${TRANSLATION_PREFIX}.results.actions.replace`}
                        onClick={() => triggerPatchStoryManagerConfig({
                            jsonPatch: jsonPatchOperationsGetter(replaceValue),
                            onSuccess: () => {
                                getStore().dispatch( // eslint-disable-next-line max-len
                                    triggerFlashSuccess({ translationKey: 'apps.story_teller.output_keys.find_and_replace.flash_messages.replace_success' }),
                                );
                            },
                        })}
                        disabled={!replaceValue || readonly}
                    />
                </div>
            );

            function getValueToShow() {
                if (highlight.length >= MAX_RESULT_LENGTH) {
                    return value;
                }

                const startIndexOfHighlight = value.indexOf(highlight);
                const remainingCharactersBeforeAndAfterHighlight = MAX_RESULT_LENGTH - highlight.length / 2;

                const startIndex = startIndexOfHighlight - Math.floor(remainingCharactersBeforeAndAfterHighlight / 2);
                const endIndex = startIndexOfHighlight
                    + highlight.length
                    + Math.floor(remainingCharactersBeforeAndAfterHighlight / 2);

                const wasStartCutOff = startIndex > 0;
                const wasEndCutOff = endIndex < value.length - 1;

                // eslint-disable-next-line max-len
                return `${wasStartCutOff ? '...' : ''}${value.substring(startIndex, endIndex)}${wasEndCutOff ? '...' : ''}`;
            }
        }

        function selectScenario() {
            redirectTo({
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_FIND_AND_REPLACE,
                params: {
                    databaseId,
                    outputKeyId,
                    scenarioIndex,
                },
            });
        }
    }
}
