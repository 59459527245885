import React from 'react';
import { useParams } from 'react-router-dom';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { DEFAULT_HORIZON_TAB_KEY, HORIZON_TAB_KEY } from 'config/tabs.config';
import {
    getHorizonTitle,
    horizonDetailsEntity,
    triggerDeleteHorizon,
} from 'state/entities/portfolioMgmt/horizonDetails';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { IBaseApiEntity } from '@console/core-api/models/api.models';
import HorizonPropertiesTab from './HorizonPropertiesTab';
import HorizonAccessibleBy from './HorizonAccessibleBy';

const HORIZON_TABS: ITabItem[] = [{
    key: HORIZON_TAB_KEY.PROPERTIES,
    label: 'portfolio_mgmt.horizons.detail.tabs.properties.title',
    component: HorizonPropertiesTab,
    wrapWithContentBox: false,
}, {
    key: HORIZON_TAB_KEY.ACCESSIBLE_BY,
    label: 'portfolio_mgmt.horizons.detail.tabs.accessible_by.title',
    component: HorizonAccessibleBy,
}];

const HORIZON_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getHorizonTitle,
    notifications: [StateChangeNotification.HORIZON_DETAILS_DATA],
};

export default function HorizonDetail() {
    const { horizonTab = DEFAULT_HORIZON_TAB_KEY } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={HORIZON_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: HORIZON_TABS,
                activeTabKey: horizonTab,
                onTabChange: tabChanged,
                entity: {
                    notifications: [StateChangeNotification.HORIZON_DETAILS_DATA],
                    asyncEntitySelector: horizonDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getHorizonDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => horizonDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.horizonId as string,
            }}
        />
    );

    function tabChanged({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                horizonTab: tabKey,
            },
        });
    }

    function getHorizonDetailActions(apiEntity: IBaseApiEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: CoreApiEntityType.horizons,
            deleteTrigger: triggerDeleteHorizon,
        })];
    }
}
