import React from 'react';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import Alert from 'views/common/widget/Alert';
import { TPortfolioRiskProfileFormValues } from './types';

export function PortfolioRiskProfileFormContent({
    fields,
    labelPrefix,
}: IExtendedInputFormContext<TPortfolioRiskProfileFormValues>) {
    const [asyncAllRiskProfiles] = useAsyncFetchOnMount({
        fetcher: apiCacheManager.fetchAllRiskProfiles,
    });

    const hasRiskProfileChanged = fields.riskProfileId.isDiff;

    return (
        <>
            <ExtendedInputText<string>
                readOnly
                formField={fields.riskProfileScore}
                wrapper={{
                    label: 'fields.risk_profile_id.score',
                }}
            />

            <ExtendedInputSelect
                formField={fields.riskProfileId}
                wrapper={{
                    label: 'fields.risk_profile_id.selected',
                }}
                items={[]}
                async={{
                    itemsData: asyncAllRiskProfiles,
                    dataToSelectItemsMapper: mapRiskProfilesToSelectItems,
                }}
            />

            {hasRiskProfileChanged && (
                <Alert
                    severity="warning"
                    message={`${labelPrefix}.fields.risk_profile_id.change_warning`}
                />
            )}

            {isSetString(fields.proposedRiskProfileId.value) && (
                <ExtendedInputSelect<string>
                    readOnly
                    formField={fields.proposedRiskProfileId}
                    wrapper={{
                        label: 'fields.risk_profile_id.proposed',
                    }}
                    items={[]}
                    async={{
                        itemsData: asyncAllRiskProfiles,
                        dataToSelectItemsMapper: getProposedRiskProfile,
                    }}
                />
            )}
        </>
    );

    function mapRiskProfilesToSelectItems(): IInputSelectItem<string>[] {
        return asyncAllRiskProfiles.data.results
            .sort((riskProfileA, riskProfileB) =>
                anyComparerAscending(riskProfileA.score_range[0], riskProfileB.score_range[0]))
            .map((riskProfile) => ({
                value: riskProfile.id,
                label: getDefaultTranslationFromApiLabel(riskProfile.name),
            }));
    }

    function getProposedRiskProfile(): IInputSelectItem<string>[] {
        return [asyncAllRiskProfiles.data.results
            .find((riskProfile) =>
                riskProfile.id === fields.proposedRiskProfileId.value as string)]
            .map((riskProfile) => ({
                value: riskProfile.id,
                label: getDefaultTranslationFromApiLabel(riskProfile.name),
            }));
    }
}
