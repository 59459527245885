import React from 'react';
import {
    DEFAULT_PUBLIC_INSTRUMENT_GROUP_TAB_KEY,
    PUBLIC_INSTRUMENT_GROUP_TAB_KEY,
} from 'config/tabs.config';
import { IBaseApiEntity } from '@console/core-api/models/api.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { useParams } from 'react-router-dom';
import {
    getPublicInstrumentGroupTitle,
    publicInstrumentGroupDetailsEntity,
    triggerDeletePublicInstrumentGroup,
} from 'state/entities/thematicSearch/publicInstrumentGroupDetails';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { getApiEntityDeleteAction } from 'views/common/genericApiEntity/apiEntityHelper';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import { PublicInstrumentGroupAccessibleByTab } from './PublicInstrumentGroupAccessibleByTab';
import { PublicInstrumentGroupPropertiesTab } from './PublicInstrumentGroupPropertiesTab';
import { PublicInstrumentGroupInstrumentsTab } from './PublicInstrumentGroupInstrumentsTab';

const PUBLIC_INSTRUMENT_GROUP_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: getPublicInstrumentGroupTitle,
    notifications: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUP_DETAILS_DATA],
};

const PUBLIC_INSTRUMENT_GROUP_TABS: ITabItem[] = [{
    key: PUBLIC_INSTRUMENT_GROUP_TAB_KEY.PROPERTIES,
    label: 'thematic_search.public_instrument_groups.detail.tabs.properties.title',
    component: PublicInstrumentGroupPropertiesTab,
    wrapWithContentBox: false,
}, {
    key: PUBLIC_INSTRUMENT_GROUP_TAB_KEY.INSTRUMENTS,
    label: 'thematic_search.public_instrument_groups.detail.tabs.instruments.title',
    component: PublicInstrumentGroupInstrumentsTab,
}, {
    key: PUBLIC_INSTRUMENT_GROUP_TAB_KEY.ACCESSIBLE_BY,
    label: 'thematic_search.public_instrument_groups.detail.tabs.accessible_by.title',
    component: PublicInstrumentGroupAccessibleByTab,
}];

export function PublicInstrumentGroupDetail() {
    const { publicInstrumentGroupTab = DEFAULT_PUBLIC_INSTRUMENT_GROUP_TAB_KEY } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={PUBLIC_INSTRUMENT_GROUP_TITLE_CONFIG}
            tabsWithEntityWrapper={{
                items: PUBLIC_INSTRUMENT_GROUP_TABS,
                activeTabKey: publicInstrumentGroupTab,
                onTabChange: tabChange,
                entity: {
                    notifications: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUP_DETAILS_DATA],
                    asyncEntitySelector: publicInstrumentGroupDetailsEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
            actions={getPublicInstrumentGroupDetailActions}
            footer={{
                apiEntityVersion: {
                    apiEntitySelector: () => publicInstrumentGroupDetailsEntity.select().data,
                },
            }}
            entityId={{
                idSelector: ({ urlParams }) => urlParams.publicInstrumentGroupId as string,
            }}
        />
    );

    function getPublicInstrumentGroupDetailActions(apiEntity: IBaseApiEntity): IActionItem[] {
        return [getApiEntityDeleteAction({
            apiEntity,
            apiEntityType: CoreApiEntityType.publicInstrumentGroups,
            deleteTrigger: triggerDeletePublicInstrumentGroup,
        })];
    }

    function tabChange({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                publicInstrumentGroupTab: tabKey,
            },
        });
    }
}
