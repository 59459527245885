import React from 'react';
import Modal from 'views/common/layout/Modal';
import { getCurrentRouteParams } from 'state/ui/selectors';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { makeStyles, mixins } from 'views/styling';
import TextButton from 'views/common/buttons/TextButton';
import Text from 'views/common/widget/Text';
import Spinner from 'views/common/loading/Spinner';
import {
    getSelectedStoryManagerDatabaseDetail,
    triggerResetStoryManagerConfig,
    isBusyUpdatingStoryManagerDatabase,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.template.footer.reset_dialog';

interface IPublicProps {
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    ResetModal: {
        ...mixins.flexColTopLeft(),
        ...mixins.widthMax(),

        '& .__message': {
            paddingBottom: theme.spacing(3),
        },

        '& .__spinner': {
            width: '100%',
            marginBottom: theme.spacing(2),
            ...mixins.flexColCenterCenter(),
        },

        '& .__actions': {
            ...mixins.flexRowCenterRight(),
            ...mixins.widthMax(),
        },
    },
}));

export default function ResetModal({
    open,
    onClose,
}: IPublicProps) {
    const classes = useStyles();
    const storyManagerDatabase = getSelectedStoryManagerDatabaseDetail();

    const isLoading = isBusyUpdatingStoryManagerDatabase();

    return (
        <Modal
            id="storymanager-reset-config-confirmation"
            open={open}
            onClose={closeModal}
            title={`${TRANSLATION_PREFIX}.title`}
            maxWidth="sm"
            disableClose={isLoading}
        >
            <div className={classes.ResetModal}>
                <div className="__message">
                    <Text label={`${TRANSLATION_PREFIX}.message`} />
                </div>
                {isLoading && <Spinner className="__spinner" />}
                <div className="__actions">
                    <TextButton
                        id="storymanager-reset-config-confirmation-no"
                        label="common.confirmation.no"
                        variant="bare"
                        noMargin={false}
                        color="secondary"
                        onClick={closeModal}
                        disabled={isLoading}
                    />

                    <TextButton
                        id="storymanager-reset-config-confirmation-yes"
                        label={`${TRANSLATION_PREFIX}.confirm`}
                        variant="filled"
                        color="primary"
                        onClick={resetChanges}
                        disabled={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );

    function closeModal() {
        if (!isLoading) {
            onClose();
        }
    }

    function resetChanges() {
        triggerResetStoryManagerConfig({
            databaseId: storyManagerDatabase.id.toString(),
            onSuccess: ({ state }) => {
                const { databaseId } = getCurrentRouteParams(state);
                onClose();
                redirectTo({
                    routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
                    params: { databaseId },
                });
            },
        });
    }
}
