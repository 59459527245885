import React from 'react';
import Link from '@material-ui/core/Link';

interface IPublicProps {
    href: string;
    children: React.ReactNode;
    className?: string;
}

export default function OutgoingLink({
    href,
    children,
    className,
}: IPublicProps) {
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <Link href={href} target="_blank" onClick={onClickLink} className={className}>
            {children}
        </Link>
    );

    function onClickLink() {
        // TODO log outgoing link (analytics)
    }
}
