import React, { useState } from 'react';
import clsx from 'clsx';
import assert from '@snipsonian/core/cjs/assert';
import isSet from '@snipsonian/core/cjs/is/isSet';
import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { TI18nLabelOrString, TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import ContentTitle from 'views/common/layout/ContentTitle';
import Text from 'views/common/widget/Text';
import ExtendedInputForm, {
    IExtendedInputFormProps,
    IFormValues,
    IOnSubmitProps,
    IOnBackProps,
    IExtendedInputFormContext,
} from '../inputs/extended/ExtendedInputForm';
import Stepper, { IStepperProps } from './Stepper';

const INITIAL_WIZARD_STEP = 1;

export interface IWizardStep<Values extends IFormValues>
    extends Pick<IExtendedInputFormProps<Values>, 'renderFormFields' | 'schema'> {
    id: string;
    title?: {
        label: TLabel;
    };
    hint?: TLabel;
    className?: string;
    onToNextStep?: (props: {
        values: Values;
        initialValues: Values;
    }) => Values;
    /**
     * Steps can be disabled conditionally (based on e.g. the chosen values within previous steps).
     * It's NOT supported to disable the first or last step.
     */
    disableStep?: (props: {
        values: Values;
    }) => boolean;
}

interface IWizardState<Values extends IFormValues> {
    activeStep: number;
    values: Values;
}

interface IWizardProps<Values extends IFormValues>
    extends Pick<IStepperProps, 'backgroundImagePath' | 'backgroundImageSize'>,
    Pick<IExtendedInputFormProps<Values>, 'initialValues' | 'labelPrefix' | 'cancel'> {
    name: string;
    steps: IWizardStep<Values>[];
    completeWizardActionLabel: TI18nLabelOrString;
    onWizardComplete: (onSubmitProps: IOnSubmitProps<Values>) => Promise<unknown>;
}

const useStyles = makeStyles((theme) => ({
    Wizard: {
        ...mixins.widthMax(),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        ...mixins.flexColTopCenter(),
        ...mixins.widthHeightPercentage({ w: 35 }),
        minWidth: 400,

        '&.noDisplay': {
            display: 'none',
        },

        '& .__stepTitle': {
            ...mixins.widthMax(),
            paddingBottom: theme.spacing(1),
        },

        '& .__stepHint': {
            paddingTop: theme.spacing(1),
            ...mixins.typo({ color: APP_COLORS.GREY['400'] }),
        },
    },
}));

export default function Wizard<Values extends IFormValues>({
    steps,
    backgroundImagePath,
    backgroundImageSize,
    name,
    labelPrefix,
    initialValues,
    cancel,
    onWizardComplete,
    completeWizardActionLabel,
}: IWizardProps<Values>) {
    const [wizardState, setWizardState] = useState<IWizardState<Values>>(getInitialWizardState);
    const isCurrentStepFirst = wizardState.activeStep === INITIAL_WIZARD_STEP;
    const isCurrentStepLast = !(wizardState.activeStep < steps.length);
    const activeStepIndex = wizardState.activeStep - 1;
    const classes = useStyles();

    assert(
        null,
        () => {
            if (isSet(getFirstItemOfArray(steps).disableStep)
                || isSet(getLastItemOfArray(steps).disableStep)) {
                return false;
            }

            return true;
        },
        "It's not supported to conditionally disable the first or last step of a wizard.",
    );

    return (
        <>
            <Stepper
                backgroundImagePath={backgroundImagePath}
                backgroundImageSize={backgroundImageSize}
                nrOfSteps={steps.length}
                activeStep={wizardState.activeStep}
            />
            {steps.map((step, index) => {
                const isActiveStep = activeStepIndex === index;

                return (
                    <div
                        className={clsx(
                            classes.Wizard,
                            step.className,
                            !isActiveStep && 'noDisplay',
                        )}
                        key={step.id}
                    >
                        {isActiveStep && (
                            <ExtendedInputForm<Values>
                                name={`${name}_step_${step.id}`}
                                schema={step.schema}
                                initialValues={initialValues}
                                currentValues={wizardState.values}
                                submit={{
                                    shouldBeAllowedWithNoChanges: true,
                                    actionLabel: getSubmitActionLabel(),
                                    onSubmit: goToNextStep,
                                }}
                                labelPrefix={labelPrefix}
                                back={!isCurrentStepFirst
                                    ? {
                                        onBack: goToPreviousStep,
                                    }
                                    : null
                                }
                                {...cancel}
                                renderFormFields={(context) => renderStepContent(context, index)}
                            />
                        )}
                    </div>
                );
            })}
        </>
    );

    function getSubmitActionLabel() {
        return isCurrentStepLast
            ? isSet(completeWizardActionLabel)
                ? completeWizardActionLabel
                : 'common.action.save'
            : 'common.action.next';
    }

    function renderStepContent(context: IExtendedInputFormContext<Values>, index: number) {
        return (
            <>
                {steps[index].title && (
                    <ContentTitle
                        label={steps[index].title.label}
                        variant="list-section-open"
                        className="__stepTitle"
                    />
                )}
                {steps[index].renderFormFields(context)}
                {steps[index]?.hint && (
                    <WizardStepHint label={steps[index].hint} />
                )}
            </>
        );
    }

    function goToNextStep({ values, getOnlyChangedValues }: IOnSubmitProps<Values>) {
        const optionalOnToNextStep = steps[activeStepIndex].onToNextStep;
        const potentiallyAdjustedValues = optionalOnToNextStep
            ? optionalOnToNextStep({
                values,
                initialValues,
            })
            : values;

        if (isCurrentStepLast) {
            return onWizardComplete({ values: potentiallyAdjustedValues, getOnlyChangedValues });
        }

        const indexOfNextEnabledStep = getSequentStepIndex({
            ascending: true,
        });

        setWizardState({
            activeStep: indexOfNextEnabledStep + 1,
            values: potentiallyAdjustedValues,
        });
        return Promise.resolve(true);
    }

    function goToPreviousStep({ values }: IOnBackProps<Values>) {
        const indexOfPrevEnabledStep = getSequentStepIndex({
            ascending: false,
        });

        setWizardState({
            activeStep: indexOfPrevEnabledStep + 1,
            values,
        });
    }

    function getInitialWizardState(): IWizardState<Values> {
        return {
            activeStep: INITIAL_WIZARD_STEP,
            values: initialValues,
        };
    }

    function getSequentStepIndex({
        inputStepIndex = activeStepIndex,
        ascending,
    }: {
        inputStepIndex?: number;
        ascending: boolean;
    }): number {
        const sequentStepIndex = ascending
            ? inputStepIndex + 1
            : inputStepIndex - 1;

        if (isSet(steps[sequentStepIndex].disableStep)) {
            if (steps[sequentStepIndex].disableStep({ values: wizardState.values })) {
                return getSequentStepIndex({
                    inputStepIndex: sequentStepIndex,
                    ascending,
                });
            }
        }

        return sequentStepIndex;
    }
}

export function WizardStepHint({
    label,
}: {
    label: TLabel;
}) {
    return (
        <div className="__stepHint">
            <Text label={label} />
        </div>
    );
}
