import {
    IPublicInstrumentEntityData, PublicInstrumentType,
} from '@console/core-api/models/thematicSearch/publicInstrument.models';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtendedInputFormProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import React from 'react';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { SIZES } from 'config/styling/sizes';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { IFormValues } from 'views/common/inputs/extended/types';
import InputGroup from 'views/common/inputs/base/InputGroup';
import { getSubmitActionLabelBasedOnMode } from 'utils/entities/entityDetailUtils';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { publicInstrumentDetailsSchema } from './publicInstrumentDetailsSchema';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    extends Pick<IExtendedInputFormProps<IPublicInstrumentFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
    mode: TPropertiesFormDisplayMode;
}

export interface IPublicInstrumentFormValues extends IFormValues {
    externalId: string;
    name: string;
    displayName: string;
    type: PublicInstrumentType;
    imgUri: string;
    tags: string[];
}

const LABEL_PREFIX = 'thematic_search.public_instruments.detail';

const PUBLIC_INSTRUMENT_TYPES: IInputSelectItem<string>[] = Object.values(PublicInstrumentType).map((value) => ({
    value,
    label: `thematic_search.public_instruments.types.${value.toLowerCase()}`,
}));

export default function PublicInstrumentDetailsForm({
    mode,
    submit,
    ...formProps
}: IPublicProps) {
    return (
        <ExtendedInputForm
            name={ExtendedInputFormName.publicInstrumentProperties}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: getSubmitActionLabelBasedOnMode(mode),
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderPublicInstrumentPropertiesFormFields}
            schema={publicInstrumentDetailsSchema}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );
}

export function renderPublicInstrumentPropertiesFormFields({
    fields,
}: IExtendedInputFormContext<IPublicInstrumentFormValues>) {
    return (
        <>
            <ExtendedInputText<string>
                formField={fields.imgUri}
                wrapper={{
                    label: 'fields.image_uri.label',
                }}
            />

            <InputGroup>
                <ExtendedInputText<string>
                    formField={fields.name}
                    wrapper={{
                        label: 'fields.name.label',
                    }}
                />

                <ExtendedInputText<string>
                    formField={fields.displayName}
                    wrapper={{
                        label: 'fields.display_name.label',
                    }}
                />
            </InputGroup>

            <InputGroup>
                <ExtendedInputText<string>
                    formField={fields.externalId}
                    wrapper={{
                        label: 'fields.external_id.label',
                    }}
                />

                <ExtendedInputSelect
                    formField={fields.type}
                    wrapper={{
                        label: 'fields.type.label',
                    }}
                    itemLabelsAreTranslationKeys
                    shouldPrefixItemLabels={false}
                    items={PUBLIC_INSTRUMENT_TYPES}
                    addNoDataSelectItem
                />
            </InputGroup>

            <ExtendedInputSearchableSelect
                formField={fields.tags}
                wrapper={{
                    label: 'fields.tags.label',
                }}
                shouldAllowCustomInput
            />
        </>
    );
}

export function mapPublicInstrumentValuesToApiEntity(
    formValues: IPublicInstrumentFormValues,
): IPublicInstrumentEntityData {
    return {
        name: formValues.name,
        type: formValues.type,
        display_name: formValues.displayName,
        image_uri: formValues.imgUri,
        external_id: formValues.externalId,
        tags: formValues.tags,
    };
}
