import React from 'react';
import { useParams } from 'react-router-dom';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { IPeriodFilter } from '@console/common/models/period.models';
import { toEndOfDay, toStartOfDay } from '@console/common/utils/date/dateUtils';
import { getEndOfTodayAsDate } from '@console/common/utils/date/getSpecificDate';
import {
    IAdvancedPortfolioOptimizationsFilters,
    OptimizationOwnerChoice, OptimizationOwnerChoiceFilter, OptimizationTypeFilter,
} from '@console/core-api/models/portfolioMgmt/portfolioOptimization.models';
import {
    IEnhancedPortfolioOptimizationForList,
} from '@console/bff/models/portfolios/enhancedPortfolioOptimizations.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import {
    portfolioOptimizationsEntity,
    triggerFetchPortfolioOptimizations,
} from 'state/entities/portfolioMgmt/portfolioOptimizations';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { isPortfolioOptimizationOwnerChoiceApplicable } from 'utils/entities/portfolioMgmt/portfolioOptimizationUtils';
import { makeStyles, mixins, UtilityClass } from 'views/styling';
import ListPageForApiEntity, { TSetStateOnPageNrChange } from 'views/common/list/ListPageForApiEntity';
import DataTable from 'views/common/list/DataTable';
import Text from 'views/common/widget/Text';
import OptimizationNrOfOrders
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioOptimizations/OptimizationNrOfOrders';
import OptimizationOwnerChoiceTag
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioOptimizations/OptimizationOwnerChoiceTag';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { getInitialPeriodData } from 'utils/date/periodUtils';
import { IRenderAdvancedFiltersProps } from 'views/common/list/DataSearch';
import InputGroup from 'views/common/inputs/base/InputGroup';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputPeriodField from 'views/common/inputs/base/InputPeriodField';
import InputSelectField, { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';

const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.optimizations';
const COL_LABEL_PREFIX = `${LABEL_PREFIX}.columns`;
const ADVANCED_FILTER_LABEL_PREFIX = `${LABEL_PREFIX}.filter.advanced`;

const OWNER_CHOICE_SELECT_ITEMS: IInputSelectItem<OptimizationOwnerChoiceFilter>[] =
    Object.values(OptimizationOwnerChoiceFilter)
        .map((ownerChoice) => ({
            value: ownerChoice,
            label: `${LABEL_PREFIX}.data.owner_choice.${ownerChoice.toLowerCase()}`,
        }));

const OPTIMIZATION_TYPE_SELECT_ITEMS: IInputSelectItem<OptimizationTypeFilter>[] =
    Object.values(OptimizationTypeFilter).map((optimizationType) => ({
        value: optimizationType,
        label: `${LABEL_PREFIX}.data.optimization_type.${optimizationType.toLowerCase()}`,
    }));

interface IOptimizationCols extends IColValues {
    dateCreated: string;
    orders: {
        nrOfBuys: number;
        nrOfSells: number;
    };
    ownerChoice?: {
        value: OptimizationOwnerChoice,
        when: string;
    };
    isRecommended: boolean;
}

const useStyles = makeStyles((theme) => ({
    PortfolioOptimizationsList: {
        '& .__ownerChoiceColValue': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center', wrap: 'nowrap' }),

            '& .Tag': {
                marginRight: theme.spacing(0.5),
            },
        },
    },
}));

export default function PortfolioOptimizationsList() {
    const classes = useStyles();
    const { portfolioId } = useParams<IPossiblePathParams>();
    const customApiInput = {
        portfolioId,
    };
    const portfolioDetailsData = portfolioDetailsEntity.select().data;

    if (!portfolioDetailsData) {
        return null;
    }

    const { minDate, maxDate } = getMinMaxDateRange();
    const initialAdvancedMovementsFilters: IAdvancedPortfolioOptimizationsFilters = {
        period: getInitialPeriodData({ minDate, maxDate }),
        ownerChoice: null,
        type: null,
    };

    const showOwnerChoice = isPortfolioOptimizationOwnerChoiceApplicable({
        portfolioManagerType: portfolioDetailsData.config.manager,
    });

    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.portfolioOptimizations.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.PORTFOLIO_OPTIMIZATIONS_DATA],
    });

    return (
        <ListPageForApiEntity
            className={classes.PortfolioOptimizationsList}
            notifications={[
                StateChangeNotification.PORTFOLIO_OPTIMIZATIONS_DATA,
                StateChangeNotification.PORTFOLIO_OPTIMIZATIONS_UI_VARS,
            ]}
            asyncListEntity={portfolioOptimizationsEntity}
            asyncListEntityFetchTrigger={triggerFetchPortfolioOptimizations}
            customApiInput={customApiInput}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.portfolioOptimizationsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.PORTFOLIO_OPTIMIZATIONS_UI_VARS}
            list={{
                renderData: renderPortfolioOptimizations,
            }}
            search={{
                advanced: {
                    initialValues: initialAdvancedMovementsFilters,
                    renderFilters: renderAdvancedPortfolioMovementsFilters,
                },
            }}
        />
    );

    function getMinMaxDateRange(): { minDate: Date, maxDate: Date } {
        return {
            minDate: new Date(portfolioDetailsData.creation_datetime),
            maxDate: getEndOfTodayAsDate(),
        };
    }

    function renderPortfolioOptimizations({
        data: enhancedOptimizations,
    }: {
        data: IEnhancedPortfolioOptimizationForList[],
    }) {
        const optimizationItems: IDataItem<IOptimizationCols>[] = enhancedOptimizations.map((enhancedOptimization) => ({
            id: enhancedOptimization.id,
            colValues: {
                dateCreated: enhancedOptimization.creation_datetime,
                orders: {
                    nrOfBuys: enhancedOptimization.nrOfBuys,
                    nrOfSells: enhancedOptimization.nrOfSells,
                },
                ownerChoice: showOwnerChoice
                    ? {
                        value: enhancedOptimization.choice?.value,
                        when: enhancedOptimization.choice?.when,
                    }
                    : null,
                isRecommended: enhancedOptimization.isRecommended,
            },
        }));

        return (
            <DataTable
                cols={getCols({ showOwnerChoice })}
                items={optimizationItems}
                onItemRowClicked={(optimization) => goToOptimizationComparison(optimization.id)}
            />
        );

        function goToOptimizationComparison(optimizationId: string) {
            redirectTo({
                routeKey: ROUTE_KEY.R_PORTFOLIO_OPTIMIZATION_DETAIL,
                params: {
                    portfolioId,
                    optimizationId,
                },
            });
        }
    }

    function renderAdvancedPortfolioMovementsFilters({
        filterValues,
        onChangeFilterValue,
    }: IRenderAdvancedFiltersProps<IAdvancedPortfolioOptimizationsFilters>) {
        return (
            <InputGroup>
                <InputWrapper label={`${ADVANCED_FILTER_LABEL_PREFIX}.period.label`}>
                    <InputPeriodField<Date>
                        onChange={onChangePeriodFilter}
                        currentPeriod={{
                            type: filterValues.period.type,
                            startDate: filterValues.period.startDate,
                            endDate: filterValues.period.endDate,
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </InputWrapper>
                {showOwnerChoice ? (
                    <InputWrapper label={`${ADVANCED_FILTER_LABEL_PREFIX}.owner_choice.label`}>
                        <InputSelectField
                            value={filterValues.ownerChoice}
                            items={OWNER_CHOICE_SELECT_ITEMS}
                            itemLabelsAreTranslationKeys
                            addNoDataSelectItem={`${ADVANCED_FILTER_LABEL_PREFIX}.owner_choice.no_selection`}
                            onChange={({ value }) => onChangeFilterValue({ ownerChoice: value })}
                        />
                    </InputWrapper>
                ) : null}
                <InputWrapper label={`${ADVANCED_FILTER_LABEL_PREFIX}.type.label`}>
                    <InputSelectField
                        value={filterValues.type}
                        items={OPTIMIZATION_TYPE_SELECT_ITEMS}
                        itemLabelsAreTranslationKeys
                        addNoDataSelectItem={`${ADVANCED_FILTER_LABEL_PREFIX}.type.no_selection`}
                        onChange={({ value }) => onChangeFilterValue({ type: value })}
                    />
                </InputWrapper>
            </InputGroup>
        );

        function onChangePeriodFilter({ type, startDate, endDate }: IPeriodFilter) {
            onChangeFilterValue({
                period: {
                    type,
                    startDate: toStartOfDay(startDate).toDate(),
                    endDate: toEndOfDay(endDate).toDate(),
                },
            });
        }
    }
}

function getCols({
    showOwnerChoice,
}: {
    showOwnerChoice: boolean;
}): TDataColumns<IOptimizationCols> {
    const optionalOwnerChoiceCol = showOwnerChoice
        ? {
            ownerChoice: {
                label: {
                    msg: `${COL_LABEL_PREFIX}.owner_choice`,
                },
                data: {
                    render: ({ item }: { item: IDataItem<IOptimizationCols> }) => (
                        <div className="__ownerChoiceColValue">
                            <OptimizationOwnerChoiceTag ownerChoice={item.colValues.ownerChoice.value} />
                            {item.colValues.ownerChoice.when
                                ? (
                                    <Text
                                        label={{
                                            text: formatOptimizationDate(item.colValues.ownerChoice.when),
                                            shouldTranslate: false,
                                        }}
                                    />
                                )
                                : <div>&nbsp;</div>
                            }
                        </div>
                    ),
                },
                percentWidth: 25,
            },
        }
        : {};

    return {
        dateCreated: {
            label: {
                msg: `${COL_LABEL_PREFIX}.date_created`,
            },
            data: {
                className: UtilityClass.table.cellNoTextWrap,
                render: ({ cellValue }) => formatOptimizationDate(cellValue as string),
            },
            percentWidth: showOwnerChoice ? 25 : 30,
        },
        orders: {
            label: {
                msg: `${COL_LABEL_PREFIX}.orders`,
            },
            data: {
                render: ({ item }) => {
                    if (!isSet(item.colValues.isRecommended)) {
                        return ('-');
                    }

                    return (
                        <OptimizationNrOfOrders
                            status={item.colValues.isRecommended
                                ? EnhancedOptimizationStatus.RECOMMENDED
                                : EnhancedOptimizationStatus.NOT_RECOMMENDED}
                            nrOfBuys={item.colValues.orders.nrOfBuys}
                            nrOfSells={item.colValues.orders.nrOfSells}
                            skipStatusPrefix
                        />
                    );
                },
            },
            percentWidth: showOwnerChoice ? 25 : 35,
        },
        ...optionalOwnerChoiceCol,
        isRecommended: {
            label: {
                msg: `${COL_LABEL_PREFIX}.is_recommended`,
            },
            data: {
                render: ({ cellValue }) => {
                    if (!isSet(cellValue)) {
                        return ('-');
                    }

                    const optimizationType = (cellValue as boolean) === true
                        ? 'recommended'
                        : 'not_recommended';

                    return (
                        <Text label={`${LABEL_PREFIX}.data.optimization_type.${optimizationType}`} />
                    );
                },
            },
            percentWidth: showOwnerChoice ? 25 : 35,
        },
    };
}

function formatOptimizationDate(date: string) {
    return formatDateRelativeToNow({
        date,
        options: {
            alwaysIncludeTime: true,
        },
    });
}
