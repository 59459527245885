import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ISinglePortfolioApiInput } from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import {
    IEnhancedPortfolioAllocation, TFetchEnhancedPortfolioAllocationApiInput,
} from '@console/bff/models/portfolios/portfolioAllocation.models';
import { isUserManagedPortfolio } from 'utils/entities/portfolioMgmt/portfolioUtils';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';

export const portfolioAllocationEntity = getEntitiesManager().registerEntity<IEnhancedPortfolioAllocation>({
    asyncEntityKey: AsyncEntityKeys.portfolioAllocation,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_ALLOCATION],
});

export function triggerFetchPortfolioAllocation(apiInput: ISinglePortfolioApiInput) {
    if (!validateEntityIdBeforeFetch({ entityId: apiInput.portfolioId, entityType: CoreApiEntityType.portfolios })) {
        return null;
    }

    const portfolioDetail = portfolioDetailsEntity.select().data;
    if (!portfolioDetail || isUserManagedPortfolio(portfolioDetail)) {
        return null;
    }

    return portfolioAllocationEntity.async.fetch<TFetchEnhancedPortfolioAllocationApiInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolioAllocation,
        apiInputSelector: () => ({
            ...apiInput,
            currency: portfolioDetail.base_currency,
            managerType: portfolioDetail.config.manager,
        }),
        refreshMode: () => portfolioAllocationEntity.select().data.portfolioId !== apiInput.portfolioId,
        resetDataOnTriggerMode: 'always',
    });
}
