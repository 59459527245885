import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import InputRadioField, {
    IInputRadioFieldProps,
    IOnSelectedRadioProps,
} from 'views/common/inputs/base/InputRadioField';
import { IExtendedInputBaseProps } from './ExtendedInputText';
import { ExtendedInputFormContext } from './ExtendedInputForm';
import ExtendedInputWrapper, { prependLabelWithPrefix } from './ExtendedInputWrapper';

interface IExtendedInputRadioProps<Name extends string = string>
    extends IExtendedInputBaseProps,
    Pick<IInputRadioFieldProps<Name>, 'disabled' | 'name' | 'showValueIndicatorsIfDisabled'> {
    baseId: string;
    radioOptions: IRadioOption[];
    shouldPrefixOptionLabels?: boolean; // default true
}

export interface IRadioOption
    extends Pick<IInputRadioFieldProps, 'value'> {
    label: TLabel;
    extraDescription?: TLabel;
}

const useStyles = makeStyles((/* theme */) => ({
    ExtendedInputRadio: {
        ...mixins.flexColTopLeft(),
    },
}));

export default function ExtendedInputRadio<Name extends string = string>({
    readOnly = false,
    formField,
    wrapper,
    disabled = false,
    name,
    baseId,
    radioOptions,
    shouldPrefixOptionLabels = true,
    ...otherRadioProps
}: IExtendedInputRadioProps<Name>) {
    const classes = useStyles();

    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, readOnlyForm, setFieldValue }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChange({ value }: IOnSelectedRadioProps<Name>) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                return (
                    <ExtendedInputWrapper
                        disabled={disabled}
                        readOnly={isFieldReadOnly}
                        hasError={!!formField.error && formField.emphasizeError}
                        shouldStyleInputWrapped={false}
                        {...wrapper}
                        className={clsx(classes.ExtendedInputRadio, wrapper.className)}
                    >
                        {radioOptions.map((radioOption, index) => {
                            const id = `${baseId}_${radioOption.value}`;

                            const adjustedLabel = prependLabelWithPrefix({
                                label: radioOption.label,
                                labelPrefix,
                                shouldPrefixLabel: shouldPrefixOptionLabels,
                            });
                            const adjustedExtraDescription = radioOption.extraDescription
                                ? prependLabelWithPrefix({
                                    label: radioOption.extraDescription,
                                    labelPrefix,
                                    shouldPrefixLabel: shouldPrefixOptionLabels,
                                })
                                : null;

                            /* we only show the error - if any - below the last radio option */
                            const formFieldErrorIfLastOption = (index === radioOptions.length - 1)
                                ? formField.error
                                : null;

                            return (
                                <InputRadioField
                                    key={id}
                                    id={id}
                                    name={name}
                                    value={radioOption.value}
                                    selected={radioOption.value === formField.value}
                                    onSelected={onChange}
                                    disabled={disabled || isFieldReadOnly}
                                    description={adjustedLabel}
                                    extraDescription={adjustedExtraDescription}
                                    error={formFieldErrorIfLastOption}
                                    {...otherRadioProps}
                                />
                            );
                        })}
                    </ExtendedInputWrapper>
                );
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
