import React from 'react';
import { PortfolioMoneyType } from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputRadio, { IRadioOption } from 'views/common/inputs/extended/ExtendedInputRadio';
import {
    getPortfolioManagerTypeLabel,
    getPortfolioMoneyTypeLabel,
} from 'utils/entities/portfolioMgmt/portfolioUtils';
import { getPortfolioTypesConfig } from 'state/appConfig/selectors';
import { IPortfolioFormValues } from './types';

export function PortfolioManagerTypeFormContent({
    fields,
    readOnlyForm = false,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'managerType'>>) {
    const radioOptions: IRadioOption[] = getPortfolioTypesConfig()
        .map((managerType) => ({
            value: managerType,
            label: getPortfolioManagerTypeLabel(managerType),
        }));

    return (
        <ExtendedInputRadio
            baseId="portfolio-manager-type-edit"
            name="portfolioManagerType"
            formField={fields.managerType}
            readOnly={readOnlyForm}
            wrapper={{
                label: 'fields.manager_type.label',
            }}
            shouldPrefixOptionLabels={false}
            radioOptions={radioOptions}
        />
    );
}

export function PortfolioMoneyTypeFormContent({
    fields,
    readOnlyForm = false,
}: IExtendedInputFormContext<Pick<IPortfolioFormValues, 'moneyType'>>) {
    const radioOptions: IRadioOption[] = Object.values(PortfolioMoneyType)
        .map((moneyType) => ({
            value: moneyType,
            label: getPortfolioMoneyTypeLabel(moneyType),
        }));

    return (
        <ExtendedInputRadio
            baseId="portfolio-money-type-edit"
            name="portfolioMoneyType"
            formField={fields.moneyType}
            readOnly={readOnlyForm}
            wrapper={{
                label: 'fields.money_type.label',
                tooltip: 'fields.money_type.tip',
            }}
            shouldPrefixOptionLabels={false}
            radioOptions={radioOptions}
        />
    );
}
