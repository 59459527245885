import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { APP_COLORS } from 'config/styling/colors';
import { formatInstrumentOrListingIdForDisplay } from 'utils/entities/portfolioMgmt/instrumentUtils';
import { makeStyles, mixins } from 'views/styling';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import LabelWithValue from 'views/common/layout/LabelWithValue';

const INSTRUMENTS_LABEL_PREFIX = 'portfolio_mgmt.instruments.detail.fields';
const LABEL_PREFIX = 'common.generic_api_entity.entity_fiche';

interface IPublicProps {
    id?: string;
    name: string;
    variant?: 'regular' | 'instrument';
}

const useStyles = makeStyles((theme) => ({
    EntityFiche: {
        '& .__entityName': {
            paddingRight: theme.spacing(1),
            ...mixins.typo({ size: 15, weight: 'bold' }),
        },
        '& .__entityId': {
            ...mixins.typo({ size: 12, color: APP_COLORS.TEXT['400'], weight: 'normal' }),
        },
    },
}));

export default function EntityFiche({
    id,
    name,
    variant = 'regular',
}: IPublicProps) {
    const classes = useStyles();

    return (
        <div className={clsx(UtilityClass.layout.colTopLeft, classes.EntityFiche)}>
            <div className={UtilityClass.layout.rowCenterLeft}>
                <div className="__entityName">
                    {name}
                </div>
            </div>

            {isSet(id) && (
                <LabelWithValue
                    variant="row"
                    className="__entityId"
                    label={variant === 'instrument' ? `${INSTRUMENTS_LABEL_PREFIX}.isin` : `${LABEL_PREFIX}.id`}
                    value={variant === 'instrument' ? formatInstrumentOrListingIdForDisplay(id) : id}
                    labelInBold={false}
                    valueInBold={false}
                />
            )}
        </div>
    );
}
