import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getBodyShiftWidthByAppMenuStatus } from 'config/menu.config';
import { APP_COLORS } from 'config/styling/colors';
import { BOX_SHADOW, Z_INDEX } from 'config/styling/elevation';
import { getAppMenuStatus } from 'state/ui/selectors';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, mixins } from 'views/styling';
import {
    IActiveApiEntityVersionProps, initActiveApiEntityVersion,
} from 'views/common/genericApiEntity/ActiveApiEntityVersion';

export interface IDetailPageFooterProps {
    className?: string;
    apiEntityVersion?: IActiveApiEntityVersionProps & { extraNotifications?: StateChangeNotification[] };
    children?: ReactNode;
}

interface IStyleProps {
    shiftWidth: number;
}

const useStyles = makeStyles((theme) => ({
    DetailPageFooter: {
        ...mixins.widthMax(),
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 1,

        width: ({ shiftWidth }: IStyleProps) => `calc(100% - ${shiftWidth}px)`,
        marginLeft: ({ shiftWidth }: IStyleProps) => shiftWidth,

        '& .__box': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            height: theme.spacing(6), // 48
            padding: theme.spacing(1, 2),
            backgroundColor: APP_COLORS.SYSTEM.WHITE,
            boxShadow: BOX_SHADOW.DEFAULT,
            zIndex: Z_INDEX.DETAIL_PAGE_FOOTER,
        },

        /**
         * We reserve spaces for when e.g. the form action buttons are placed (with fixed positioning) on top
         * of the detail page footer.
         * By reserving space, we trick that those actions don't overlap
         * with the versions info in case of small screens.
         */
        '& .__reserveSpaceForActions': {
            ...mixins.widthHeightPixels({ w: 160 }),
        },
    },
}));

function DetailPageFooter({
    className,
    apiEntityVersion: apiEntityVersionConfig,
    state,
    children,
}: IDetailPageFooterProps & IObserveProps) {
    const appMenuStatus = getAppMenuStatus(state);
    const classes = useStyles({
        shiftWidth: getBodyShiftWidthByAppMenuStatus(appMenuStatus),
    });

    const ApiEntityVersion = apiEntityVersionConfig
        ? initActiveApiEntityVersion({ notifications: apiEntityVersionConfig.extraNotifications || [] })
        : null;

    return (
        <div className={clsx(classes.DetailPageFooter, className)}>
            <div className="__box">
                {ApiEntityVersion && (
                    <ApiEntityVersion {...apiEntityVersionConfig} />
                )}
                {children}
                <div className="__reserveSpaceForActions">&nbsp;</div>
            </div>
        </div>
    );
}

export default observe<IDetailPageFooterProps>(
    [StateChangeNotification.UI_APP_MENU],
    DetailPageFooter,
);
