import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { IPeriodFilter, PeriodType } from '@console/common/models/period.models';
import { DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS } from '@console/core-api/config/coreApi.config';
import { IState } from 'models/state.models';
import {
    AccessibleByEntityType,
    IAccessibleByPageVars,
} from 'models/ui/apiEntityAccessibleBy.ui.models';
import {
    IListPageVars,
    IListPagination,
    IListSimpleSearch,
    ISimpleFilterToggles,
    UiPageKey,
} from 'models/state/ui.models';
import {
    IPolicySimulationPageVars,
    IPolicySimulationPerformancePageVars,
} from 'models/ui/policySimulation.ui.models';
import { IInstrumentPerformancePastPageVars } from 'models/ui/instrument.ui.models';
import { IUniverseInstrumentsPageVars } from 'models/ui/instruments.ui.models';
import { IUserGroupMembersPageVars } from 'models/ui/userGroup.ui.models';
import { IPortfolioPerformancePageVars } from 'models/ui/portfolioPerformance.ui.models';
import { IStoryManagerPageVars } from 'models/ui/storymanager.ui.models';
import {
    IMgmtReportingUiVars,
    IRoboMgmtReportingUiVars,
} from 'models/ui/mgmtReporting.ui.models';
import {
    DEFAULT_POLICY_HORIZON_YEARS,
    DEFAULT_POLICY_INVESTMENT_AMOUNT,
} from 'config/portfolioMgmt/policySimulation.config';
import { INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS } from 'config/portfolioMgmt/portfolioPerformance.config';
import {
    DEFAULT_AGGREGATE_ROBO_AUM_BY,
    DEFAULT_MGMT_REPORTING_PERIOD_TYPE,
} from 'config/mgmtReporting/mgmtReporting.config';
import { getStore } from 'state';
import { selectMatchingReportPeriod } from 'state/entities/mgmtReporting/reportInfo';

const getUiPages = (state: IState) => state.ui.pages;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getListPageVars = <AdvancedFilters = any, ExtraSimpleFilterToggles extends ISimpleFilterToggles = any>(
    state: IState,
    pageKey: UiPageKey,
) => getUiPages(state)[pageKey] || ({} as IListPageVars<AdvancedFilters, ExtraSimpleFilterToggles>);

const isSimpleFilterSet = (simpleSearch: IListSimpleSearch) => isSetString(simpleSearch?.filterValue);
const isAnyAdvancedFilterSet = (filterValues: TObjectWithProps) =>
    (filterValues ? Object.values(filterValues).find((filter) => isSetString(filter)) : false);

export const areListPageFiltersSet = (state: IState, pageKey: UiPageKey) => {
    const search = getListPageVars(state, pageKey)?.search;
    return isSimpleFilterSet(search?.simple) || isAnyAdvancedFilterSet(search?.advanced?.filterValues);
};

export const getListPagePaginationVars = (state: IState, pageKey: UiPageKey) =>
    getListPageVars(state, pageKey).pagination || ({} as IListPagination);

export const getPortfolioPerformancePageVars = (state: IState): IPortfolioPerformancePageVars => {
    const pageVars = getUiPages(state).portfolioPerformance;

    return {
        mode: pageVars?.mode || INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS.mode,
        future: {
            ...INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS.future,
            ...pageVars?.future,
        },
        past: {
            ...INITIAL_PORTFOLIO_PERFORMANCE_PAGE_VARS.past,
            ...pageVars?.past,
        },
    };
};

export const getAccessibleByPageVars = (state: IState) =>
    getUiPages(state).accessibleBy ||
    ({ entityType: AccessibleByEntityType.Users, showOnlyEnabledPermissions: false } as IAccessibleByPageVars);

export const getPolicySimulationPerformancePageVars = (state: IState) =>
    getUiPages(state).policySimulationPerformance || ({} as IPolicySimulationPerformancePageVars);

export const getPolicySimulationPageVars = (state: IState): IPolicySimulationPageVars => {
    const pageVars = getUiPages(state).policySimulation || ({} as IPolicySimulationPageVars);

    return {
        investmentAmount: pageVars.investmentAmount || DEFAULT_POLICY_INVESTMENT_AMOUNT,
        horizonYears: pageVars.horizonYears || DEFAULT_POLICY_HORIZON_YEARS,
        useExpectedReturns: pageVars.useExpectedReturns || DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
    };
};

export const getUniverseInstrumentsPageVars = (state: IState): IUniverseInstrumentsPageVars =>
    getUiPages(state).universeInstrumentsList ||
    ({ showOnlySelectedInstruments: false } as IUniverseInstrumentsPageVars);

export const getUserGroupMembersPageVars = (state: IState): IUserGroupMembersPageVars =>
    (getUiPages(state).userGroupMembersList || ({} as IUserGroupMembersPageVars));

export const getInstrumentPerformancePastPageVars = (state: IState) =>
    getUiPages(state).instrumentPerformancePast || ({} as IInstrumentPerformancePastPageVars);

export const getStoryManagerPageVars = (state: IState) =>
    getUiPages(state).storyManager || ({} as IStoryManagerPageVars);

export const getMgmtReportingPageVars = (state: IState = getStore().getState()) =>
    getUiPages(state).mgmtReporting || ({} as IMgmtReportingUiVars);

export interface IEnrichedRoboMgmtReportingUiVars extends Pick<IRoboMgmtReportingUiVars, 'aumAggregateBy'> {
    period: IPeriodFilter<string>;
}

export const getRoboMgmtReportingPageVars = (state?: IState): IEnrichedRoboMgmtReportingUiVars => {
    const pageVars = getMgmtReportingPageVars(state);

    return {
        period: enrichMgmtReportingPeriodWithDates({
            periodType: pageVars.robo?.periodType,
        }),
        aumAggregateBy: pageVars.robo?.aumAggregateBy || DEFAULT_AGGREGATE_ROBO_AUM_BY,
    };
};

export interface IEnrichedSelfMgmtReportingUiVars {
    period: IPeriodFilter<string>;
}

export const getSelfMgmtReportingPageVars = (state?: IState): IEnrichedSelfMgmtReportingUiVars => {
    const pageVars = getMgmtReportingPageVars(state);

    return {
        period: enrichMgmtReportingPeriodWithDates({
            periodType: pageVars.self?.periodType,
        }),
    };
};

function enrichMgmtReportingPeriodWithDates({
    periodType = DEFAULT_MGMT_REPORTING_PERIOD_TYPE,
}: {
    periodType?: PeriodType;
}): IPeriodFilter<string> {
    return selectMatchingReportPeriod(periodType);
}
