import React from 'react';
import { TEnhancedPortfolio } from '@console/bff/models/portfolios/enhancedPortfolioDetails.models';
import Text from 'views/common/widget/Text';
import { makeStyles, mixins } from 'views/styling';
import Tag from 'views/common/widget/Tag';
import EnhancedPortfolioStatus from '../EnhancedPortfolioStatus';

const TRANSLATION_PREFIX = 'portfolio_mgmt.portfolios.status';

interface IPortfolioStatusOverviewProps {
    portfolio: TEnhancedPortfolio;
}

const useStyles = makeStyles((theme) => ({
    PortfolioStatusOverview: {
        ...mixins.flexRowCenterLeft(),

        '& .__prefix': {
            ...mixins.typo({ size: 12, weight: 700, color: '#A8B1BA' }),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
    },
}));

export default function PortfolioStatusOverview({
    portfolio,
}: IPortfolioStatusOverviewProps) {
    const classes = useStyles();

    if (!portfolio) {
        return null;
    }

    return (
        <div className={classes.PortfolioStatusOverview}>
            <div className="__prefix">
                <Text label={`${TRANSLATION_PREFIX}.prefix`} />:
            </div>

            <EnhancedPortfolioStatus
                enhancedStatus={portfolio.enhancedStatus}
            />

            {portfolio.archived && (
                <Tag
                    variant="red"
                    label={`${TRANSLATION_PREFIX}.archived.tag`}
                    tooltip={{
                        label: `${TRANSLATION_PREFIX}.archived.tooltip`,
                    }}
                />
            )}
        </div>
    );
}
