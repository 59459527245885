import isSetString from '@snipsonian/core/cjs/string/isSetString';
import convertArrayToListString from '@snipsonian/core/cjs/array/listString/convertArrayToListString';
import waitSeconds from '@snipsonian/core/cjs/promise/waitSeconds';
import { convertOrderByObjectToString } from '@console/core-api/utils/fetch/entityFetchUtils';
import {
    TFetchPolicyOptimizationApiInput,
} from '@console/core-api/models/portfolioMgmt/policySimulation.models';
import { TFetchPolicyChainApiInput, IPolicyChain } from '../../models/policies/policyChain.models';
import {
    TFetchPolicyOptimizationApiInputBff,
    TPolicyOptimizationApiResponse,
} from '../../models/policies/policyOptimization.models';
import { get, patch, post } from '../consoleBffRequestWrapper';
import { ConsoleBffPath } from '../../server/consoleBffUrls';
import {
    DEFAULT_INTERVAL_IN_SECONDS_FOR_AD_HOC_OPTIMIZATION_POLLING,
    DEFAULT_MAX_RETRY_NR_FOR_AD_HOC_OPTIMIZATION_POLLING,
} from '../../config/consoleBff.config';
import {
    TCreatePolicyApiInput,
    TEnhancedPolicy,
    TFetchEnhancedPolicyDetailsApiInput,
    TPatchPolicyApiInput,
} from '../../models/policies/enhancedPolicyDetails.models';
import {
    TEnhancedPoliciesData,
    TFetchEnhancedPoliciesClientApiInput,
} from '../../models/policies/enhancedPolicies.models';
import { EnhancedOptimizationStatus } from '../../models/enhancedOptimization.models';

export function fetchEnhancedPolicies({
    ids,
    currencies,
    tags,
    orderBy,
    ...queryParams
}: TFetchEnhancedPoliciesClientApiInput) {
    return get<TEnhancedPoliciesData>({
        url: ConsoleBffPath.POLICIES,
        queryParams: {
            ...queryParams,
            ids: convertArrayToListString(ids),
            currencies: convertArrayToListString(currencies),
            tags: convertArrayToListString(tags),
            orderBy: convertOrderByObjectToString(orderBy),
        },
    });
}

export function fetchEnhancedPolicyDetails({
    policyId,
}: TFetchEnhancedPolicyDetailsApiInput) {
    return get<TEnhancedPolicy>({
        url: ConsoleBffPath.POLICY_DETAILS,
        pathParams: {
            policyId,
        },
    });
}

export function createPolicy(policyToCreate: TCreatePolicyApiInput) {
    return post<TEnhancedPolicy>({
        url: ConsoleBffPath.POLICIES,
        body: policyToCreate,
    });
}

export function patchPolicy({
    policyId,
    ...fieldsToPatch
}: TPatchPolicyApiInput) {
    return patch<TEnhancedPolicy>({
        url: ConsoleBffPath.POLICY_DETAILS,
        pathParams: {
            policyId,
        },
        body: fieldsToPatch,
    });
}

export function fetchPolicyChain({
    policyId,
}: TFetchPolicyChainApiInput) {
    return get<IPolicyChain>({
        url: ConsoleBffPath.POLICY_CHAIN,
        pathParams: {
            policyId,
        },
    });
}

/**
 * The client does a retry - in case the optimisation returns a "pending" state - instead of
 * in the BFF itself because there is a serverside-global-timeout that would cause an error
 * when retrying a couple of times within a single call to the BFF.
 */
export function fetchPolicyOptimization(input: TFetchPolicyOptimizationApiInput) {
    return fetchPolicyOptimizationRecursive({
        retryNumber: 0,
        ...input,
    });
}

async function fetchPolicyOptimizationRecursive({
    policyId,
    retryNumber,
    ...body
}: TFetchPolicyOptimizationApiInputBff & {
    retryNumber: number;
}): Promise<Omit<TPolicyOptimizationApiResponse, 'pendingRetryLocation'>> {
    const {
        pendingRetryLocation,
        ...otherResponse
    } = await post<TPolicyOptimizationApiResponse>({
        url: ConsoleBffPath.POLICY_OPTIMIZATION,
        pathParams: {
            policyId,
        },
        body,
    });

    if (otherResponse.status === EnhancedOptimizationStatus.PENDING) {
        if (isSetString(pendingRetryLocation)
            && retryNumber < DEFAULT_MAX_RETRY_NR_FOR_AD_HOC_OPTIMIZATION_POLLING) {
            await waitSeconds(DEFAULT_INTERVAL_IN_SECONDS_FOR_AD_HOC_OPTIMIZATION_POLLING);

            return fetchPolicyOptimizationRecursive({
                policyId,
                retryNumber: retryNumber + 1,
                pendingRetryLocation,
                ...body,
            });
        }
        /**
         * else (= in the case that the max number of retries has been reached)
         * - we don't do anything special and just return the PENDING result
         * - in policySimulation.ts this will result in a specific flash message
         */
    }

    return otherResponse;
}
