import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { diffObjects } from '@console/common/utils/object/diffObjects';
import { IOutputKey, JSONPatchModels } from '@console/bff/models/storyteller/storymanager.models';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import {
    getStoryManagerOutputKeyNamesList,
    getStoryManagerOutputKeyStatus,
} from 'state/ui/storyManager.selector';
import {
    triggerDeleteOutputKeys,
    triggerDuplicateOutputKey,
    triggerPatchStoryManagerConfig,
    isBusyUpdatingStoryManagerDatabase,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { getStore } from 'state';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { IFormValues } from 'views/common/inputs/extended/types';
import ContentTitle from 'views/common/layout/ContentTitle';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import { KeyIcon, EditIcon, DuplicateIcon, TrashIcon } from 'views/common/icons';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import ActionButtons from 'views/common/buttons/ActionButtons';
import { getOutputKeyDetailsSchema } from './outputKeyDetailsSchema';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.detail';

interface IPublicProps {
    outputKey: IOutputKey;
    isScenarioEditorMode: boolean;
    canUserModifyScenarios: boolean;
}

const useStyles = makeStyles((theme) => ({
    OutputKeyDetailsForm: {
        '& .header': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',

            padding: theme.spacing(2, 0, 3),

            borderBottom: `1px solid ${theme.palette.grey[300]}`,

            '& > svg': {
                marginTop: 4,
                marginRight: theme.spacing(1),
            },

            '& > .title': {
                margin: 0,
            },

            '& > .outputkey-status': {
                marginTop: 4,
                flexShrink: 0,
            },

            '& .header-action-buttons': {
                flex: 1,
                '&.disable-grow': {
                    flex: 0,
                },
            },

            '& .input-name-field': {
                width: 'auto',
                flex: 1,

                marginRight: theme.spacing(1),
                marginTop: -4,
                padding: 0,

                '& input': {
                    ...theme.typography.h5,
                    color: APP_COLORS.PRIMARY['500'],
                },

                '& .input-label': {
                    display: 'none',
                },

                '& .helperOrErrorText': {
                    // display: 'none',
                },
            },
        },
    },
    OutputKeyTitle: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

export interface IOutputKeyDetailsFormValues extends IFormValues {
    description: string;
    name: string;
}

export default function OutputKeyDetailsForm({
    outputKey,
    isScenarioEditorMode,
    canUserModifyScenarios,
}: IPublicProps) {
    const { outputKeyId, databaseId } = useRouteParams();
    const [editNameEnabled, setEditNameEnabled] = useState(false);
    const classes = useStyles();
    const state = getStore().getState();

    const initialValues = {
        name: outputKey.name,
        description: outputKey.description,
    };

    return (
        <ExtendedInputForm<IOutputKeyDetailsFormValues>
            className={classes.OutputKeyDetailsForm}
            name={ExtendedInputFormName.storyManagerOutputKeyDetails}
            labelPrefix={TRANSLATION_PREFIX}
            submit={{
                onSubmit,
                additionalConditionToDisable: () => isBusyUpdatingStoryManagerDatabase(),
            }}
            initialValues={initialValues}
            renderFormFields={renderFormFields}
            schema={getOutputKeyDetailsSchema({
                allOtherOutputKeyNames: getStoryManagerOutputKeyNamesList({
                    exclude: [initialValues.name],
                }),
            })}
            placeFormActionsInFixedFooter
            readOnly={!isScenarioEditorMode || !canUserModifyScenarios}
            reset={{
                triggerOnChangeDependencies: [outputKeyId],
            }}
        />
    );

    async function onSubmit(submitProps: IOnSubmitProps<IOutputKeyDetailsFormValues>) {
        const diffResult = diffObjects(submitProps.values, initialValues);

        if (!diffResult.areDiffsDetected) {
            return;
        }

        const patch = diffResult.diffs.map((item): JSONPatchModels.Operation => ({
            op: 'replace',
            path: `/outputKeys/${outputKeyId}/${item.propChain[0]}`,
            value: item.master,
        }));

        triggerPatchStoryManagerConfig({
            jsonPatch: patch,
        });
    }

    function renderFormFields(context: IExtendedInputFormContext<IOutputKeyDetailsFormValues>) {
        return (
            <>
                <div className="header">
                    <KeyIcon />
                    {(editNameEnabled && canUserModifyScenarios)
                        ? (
                            <ExtendedInputText
                                formField={context.fields.name}
                                wrapper={{
                                    className: 'input-name-field',
                                }}
                            />
                        )
                        : (
                            <ContentTitle
                                className="title"
                                variant="section"
                                label={context.fields.name.value as string}
                                customRenderer={renderTitle}
                            />
                        )}
                    <StoryManagerStatus
                        className="outputkey-status"
                        status={getStoryManagerOutputKeyStatus(state, outputKey)}
                        infoIconTooltip={{
                            name: 'storymanager-status-outputkey',
                            label: 'apps.story_teller.output_keys.status.tooltip.output_key',
                        }}
                    />
                    {!context.readOnlyForm && (
                        <ActionButtons
                            className={clsx('header-action-buttons', { 'disable-grow': !!editNameEnabled })}
                            actions={[{
                                id: 'output-key-form-edit-name',
                                label: `${TRANSLATION_PREFIX}.actions.change_name`,
                                onExecute: enableEditName,
                                variant: 'extra',
                                icon: <EditIcon />,
                            }, {
                                id: 'output-key-form-duplicate',
                                label: `${TRANSLATION_PREFIX}.actions.duplicate`,
                                onExecute: async () => triggerDuplicateOutputKey(outputKeyId, outputKey),
                                variant: 'extra',
                                icon: <DuplicateIcon />,
                            }, {
                                id: 'output-key-form-delete',
                                label: `${TRANSLATION_PREFIX}.actions.delete`,
                                onExecute: async () => {
                                    triggerDeleteOutputKeys([outputKeyId]);
                                    redirectTo({
                                        routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
                                        params: {
                                            databaseId,
                                        },
                                    });
                                },
                                variant: 'extra',
                                icon: <TrashIcon />,
                            }]}
                        />
                    )}
                </div>
                <ExtendedInputText
                    formField={context.fields.description}
                    wrapper={{
                        label: 'fields.description.label',
                    }}
                    multilineRows
                    fullWidth
                />
            </>
        );
    }

    function enableEditName() {
        if (!editNameEnabled) {
            setEditNameEnabled(true);
        }
    }

    function renderTitle(label: TLabel) {
        const outputKeyName = label as string;

        const parts = outputKeyName.split('.');

        return (
            <div className={classes.OutputKeyTitle}>
                {parts.map((part, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={i}>{`${i > 0 ? '.' : ''}${part}`}</span>
                ))}
            </div>
        );
    }
}
