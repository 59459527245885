import { RecurringDepositFrequency } from '@console/core-api/models/performance.models';
import {
    IPortfolioPerformancePageVars,
    PortfolioPerformancePastPeriod,
    PortfolioPerformanceFuturePeriod,
} from 'models/ui/portfolioPerformance.ui.models';
import { TI18nLabelOrString } from 'models/general.models';
import { IInputSelectItem } from 'views/common/inputs/base/InputSelectField';
import { parseInputDate, TDateEnhanced, TDateToFormat } from '@console/common/utils/date/dateUtils';
import { yearOffset, monthOffset, weekOffset, getSpecificDate } from '@console/common/utils/date/getSpecificDate';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import {
    IFetchPortfolioPerformanceFutureApiBody,
    IFetchPortfolioPerformancePastApiBody,
} from '@console/core-api/models/portfolioMgmt/portfolioPerformance.models';

export const MIN_SIMULATION_RECURRING_DEPOSIT_AMOUNT = 500;

const PAST_FILTER_PERIODS: IInputSelectItem<PortfolioPerformancePastPeriod>[] =
    Object.values(PortfolioPerformancePastPeriod)
        .map((pastPeriod) => ({
            label: getPastPeriodLabel(pastPeriod),
            value: pastPeriod,
        }));

export const FUTURE_FILTER_PERIODS: IInputSelectItem<PortfolioPerformanceFuturePeriod>[] =
    Object.values(PortfolioPerformanceFuturePeriod)
        .map((futurePeriod) => ({
            label: getFuturePeriodLabel(futurePeriod),
            value: futurePeriod,
        }));

export const RECURRING_DEPOSIT_FREQUENCIES: IInputSelectItem<RecurringDepositFrequency>[] =
    Object.values(RecurringDepositFrequency)
        .map((frequency) => ({
            // eslint-disable-next-line max-len
            label: `portfolio_mgmt.portfolios.detail.performance.future.recurring_deposit.frequency.${frequency.toLowerCase()}`,
            value: frequency,
        }));

interface IPastPerformanceFilterPeriodsConfig {
    [period: string]: { getStartDate?: () => TDateEnhanced };
}

interface IFuturePerformanceFilterPeriodsConfig {
    [period: string]: { getEndDate?: () => TDateEnhanced };
}

const pastFilterPeriod2Config: IPastPerformanceFilterPeriodsConfig = {
    [PortfolioPerformancePastPeriod.SINCE_START]: {},
    [PortfolioPerformancePastPeriod.YEARS_3]: { getStartDate: () => yearOffset({ yearsToAdd: -3 }) },
    [PortfolioPerformancePastPeriod.YEAR]: { getStartDate: () => yearOffset({ yearsToAdd: -1 }) },
    // eslint-disable-next-line max-len
    [PortfolioPerformancePastPeriod.YEAR_TO_DATE]: { getStartDate: () => parseInputDate(getSpecificDate({ month: 1, day: 1 })) },
    [PortfolioPerformancePastPeriod.MONTHS_3]: { getStartDate: () => monthOffset({ monthsToAdd: -3 }) },
    [PortfolioPerformancePastPeriod.MONTH]: { getStartDate: () => monthOffset({ monthsToAdd: -1 }) },
    [PortfolioPerformancePastPeriod.WEEK]: { getStartDate: () => weekOffset({ weeksToAdd: -1 }) },
};

const futureFilterPeriod2Config: IFuturePerformanceFilterPeriodsConfig = {
    [PortfolioPerformanceFuturePeriod.YEARS_3]: { getEndDate: () => yearOffset({ yearsToAdd: 3 }) },
    [PortfolioPerformanceFuturePeriod.YEARS_5]: { getEndDate: () => yearOffset({ yearsToAdd: 5 }) },
    [PortfolioPerformanceFuturePeriod.YEARS_10]: {}, // default
    [PortfolioPerformanceFuturePeriod.YEARS_20]: { getEndDate: () => yearOffset({ yearsToAdd: 20 }) },
};

export function getPastFilterPeriodsByPortfolioStartDate(
    portfolioStartDate: TDateToFormat,
): IInputSelectItem<PortfolioPerformancePastPeriod>[] {
    const parsedStartDate = parseInputDate(portfolioStartDate);

    return PAST_FILTER_PERIODS
        .filter((pastPeriod) => {
            const periodConfig = pastFilterPeriod2Config[pastPeriod.value];

            if (!periodConfig.getStartDate) {
                return true;
            }

            return portfolioStartDate && parsedStartDate.isBefore(periodConfig.getStartDate());
        });
}

export function getPastPeriodLabel(pastPeriod: PortfolioPerformancePastPeriod) {
    return `portfolio_mgmt.portfolios.detail.performance.past.period.${pastPeriod.toLowerCase()}`;
}

export function getFuturePeriodLabel(futurePeriod: PortfolioPerformanceFuturePeriod) {
    return `portfolio_mgmt.portfolios.detail.performance.future.period.${futurePeriod.toLowerCase()}`;
}

export function getPastPerformanceFilterBasedOnPageVars(
    pageVars: IPortfolioPerformancePageVars,
): Partial<IFetchPortfolioPerformancePastApiBody> {
    const filter: Partial<IFetchPortfolioPerformancePastApiBody> = {};

    const { period } = pageVars.past;

    if (period) {
        const periodConfig = pastFilterPeriod2Config[period];

        if (periodConfig.getStartDate) {
            filter.startDate = formatDate({ date: periodConfig.getStartDate(), format: DATE_FORMAT.BACK_END });
        }
    }

    return filter;
}

export function getFuturePerformanceFilterBasedOnPageVars(
    pageVars: IPortfolioPerformancePageVars,
): Partial<IFetchPortfolioPerformanceFutureApiBody> {
    const filter: Partial<IFetchPortfolioPerformanceFutureApiBody> = {};

    const { period, recurringDepositAmount, recurringDepositFrequency, useExpectedReturns } = pageVars.future;

    if (period) {
        const periodConfig = futureFilterPeriod2Config[period];

        if (periodConfig.getEndDate) {
            filter.endDate = formatDate({ date: periodConfig.getEndDate(), format: DATE_FORMAT.BACK_END });
        }
    }

    if (validateRecurringDepositFilterInput({ recurringDepositAmount, recurringDepositFrequency }).isValid) {
        filter.recurringDepositAmount = recurringDepositAmount;
        filter.recurringDepositFrequency = recurringDepositFrequency;
    }

    filter.useExpectedReturns = useExpectedReturns;

    return filter;
}

interface IFormValidationResult {
    isValid: boolean;
    errors: {
        [fieldName: string]: TI18nLabelOrString;
    };
}

const FORM_IS_VALID: IFormValidationResult = {
    isValid: true,
    errors: {},
};

export function validateRecurringDepositFilterInput({
    recurringDepositAmount,
    recurringDepositFrequency,
    allowEmptyAmount = false,
}: {
    recurringDepositAmount?: number;
    recurringDepositFrequency: RecurringDepositFrequency;
    allowEmptyAmount?: boolean;
}): IFormValidationResult {
    if (!recurringDepositFrequency) {
        return {
            isValid: false,
            errors: {
                recurringDepositFrequency: 'error.validation.required',
            },
        };
    }

    if (allowEmptyAmount && !recurringDepositAmount) {
        /* to be able to clear the input */
        return FORM_IS_VALID;
    }

    if (recurringDepositAmount < MIN_SIMULATION_RECURRING_DEPOSIT_AMOUNT) {
        return {
            isValid: false,
            errors: {
                recurringDepositAmount: {
                    msg: 'error.validation.min_val',
                    placeholders: {
                        minValue: MIN_SIMULATION_RECURRING_DEPOSIT_AMOUNT,
                    },
                },
            },
        };
    }

    return FORM_IS_VALID;
}
