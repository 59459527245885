import React from 'react';
import {
    riskProfileDetailsEntity,
    triggerPatchRiskProfileDetails,
} from 'state/entities/portfolioMgmt/riskProfileDetails';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function RiskProfileAccessibleByTab() {
    const riskProfileDetailsData = riskProfileDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={riskProfileDetailsData}
            nameForDisplay={getDefaultTranslationFromApiLabel(riskProfileDetailsData.name)}
            asyncEntityPatchTrigger={(values) => triggerPatchRiskProfileDetails((currentRiskProfile) => {
                /* eslint-disable no-param-reassign */
                currentRiskProfile.readable_by = values.readable_by;
                currentRiskProfile.modifiable_by = values.modifiable_by;
                /* eslint-enable no-param-reassign */
            })}
        />
    );
}
