import { IBaseFetchEntityListApiInput, TApiEntityCreate, TApiEntityId } from '@console/core-api/models/api.models';
import { IState } from 'models/state.models';
import { UiPageKey } from 'models/state/ui.models';
import { getStore } from 'state';
import { getUserId } from 'state/auth/selectors';
import { getListPageVars } from 'state/ui/uiPages.selectors';
import { enrichApiEntityToEnsureUserAccess } from 'utils/entities/entityEnrichUtils';
import { mapActiveSortColumnToApiOrderBy } from 'utils/entities/entityListUtils';

export function enrichApiEntityDataForCreate<ApiEntityData>(
    entityData: ApiEntityData,
    {
        state = getStore().getState(),
        userIdNeedingAccess,
    }: {
        state?: IState;
        /* Optional userId to make sure that this user has both read- and modify-access
         * next to the authenticated user doing the create.
         * If those are the same user id's, this function is smart enough to only add the userId once.
         */
        userIdNeedingAccess?: TApiEntityId;
    } = {},
): TApiEntityCreate<ApiEntityData> {
    const authenticatedUserId = getUserId(state);

    return enrichApiEntityToEnsureUserAccess({
        entity: {
            ...entityData,
            readable_by: [authenticatedUserId],
            modifiable_by: [authenticatedUserId],
        },
        userId: userIdNeedingAccess,
    });
}

export function enrichApiInputWithListPageVars
<ApiInput extends IBaseFetchEntityListApiInput, AdvancedPageFilter = unknown>({
    pageKey,
    state,
    apiInput,
    mapSimpleFilterValue,
    mapAdvancedFilterValues,
}: {
    pageKey: UiPageKey;
    state: IState;
    apiInput: ApiInput;
    mapSimpleFilterValue?: (simpleFilterValue: string) => Partial<ApiInput>;
    mapAdvancedFilterValues?: (advancedFilterValues: AdvancedPageFilter) => Partial<ApiInput>;
}): ApiInput {
    const listPageVars = getListPageVars<AdvancedPageFilter>(state, pageKey);

    if (listPageVars && (listPageVars.search || listPageVars.pagination || listPageVars.sorting)) {
        let filterFromState: IBaseFetchEntityListApiInput = {
            limit: (listPageVars.pagination && listPageVars.pagination.itemsPerPage) || undefined,
        };

        if (listPageVars.search) {
            if (listPageVars.search.mode === 'simple' && mapSimpleFilterValue) {
                const simpleFilterValue = (listPageVars.search.simple || {}).filterValue;

                filterFromState = {
                    ...filterFromState,
                    ...mapSimpleFilterValue(simpleFilterValue),
                };
            } else if (mapAdvancedFilterValues) {
                const advancedFilterValues = (listPageVars.search.advanced || {}).filterValues
                    || ({} as AdvancedPageFilter);

                filterFromState = {
                    ...filterFromState,
                    ...mapAdvancedFilterValues(advancedFilterValues),
                };
            }
        }

        if (listPageVars.sorting) {
            if (listPageVars.sorting.column?.serverSide) {
                filterFromState = {
                    ...filterFromState,
                    orderBy: mapActiveSortColumnToApiOrderBy(listPageVars.sorting.column),
                };
            }
        }

        return {
            ...filterFromState,
            ...apiInput, // overrule if passed as input
        };
    }

    return apiInput;
}
