import React, { useEffect, useRef } from 'react';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { IBarSeparatorLine } from 'models/barChart.models';
import { CHART_STYLING } from 'config/styling/chart';
import d3 from 'utils/libs/d3';
import { TYScale } from 'views/common/charts/types';

interface IVerticalBarProps {
    yScale: TYScale<number>;
    /** the x-coordinate of both the top-left as the bottom-left corner */
    xCoordinateLeft: number;
    /** the width in pixels */
    width: number;
    /**
     * 'yStartingValue and 'yEndingValue' are the values which will be converted to coordinates.
     * - when yEndingValue > yStartingValue, it will be considered a 'positive' bar
     * - when yEndingValue < yStartingValue, it will be considered a 'negative' bar
     */
    yStartingValue: number;
    yEndingValue: number;
    displayEndingValue?: boolean; /* default false */
    yStartingValueLine?: IBarSeparatorLine;
    /**
     * To optionally make the 'end corners' (= 'top' corners for a positive bar OR 'botton corners for a negative bar')
     * rounded.
     */
    endCornerRadius?: number; /* default 0 = no corner */
    color: string;
    /** the direction of the transition effect will depend on this being a 'positive' or 'negative' bar */
    transitionDurationInMillis?: number;
}

export function VerticalBar({
    yScale,
    xCoordinateLeft,
    width,
    yStartingValue,
    yEndingValue,
    displayEndingValue = false,
    yStartingValueLine,
    endCornerRadius: endCornerRadiusMax = 0,
    color,
    transitionDurationInMillis = 0,
}: IVerticalBarProps) {
    const pathRef = useRef();

    const isPositiveBar = yEndingValue >= yStartingValue;
    const yCoordinateTop = yScale(
        isPositiveBar ? yEndingValue : yStartingValue,
    );
    const yCoordinateBottom = yScale(
        isPositiveBar ? yStartingValue : yEndingValue,
    );

    const fullHeight = yCoordinateBottom - yCoordinateTop;
    const doRoundedCorners = endCornerRadiusMax > 0;
    const endCornerRadius = doRoundedCorners
        ? Math.min(fullHeight, endCornerRadiusMax)
        : 0;

    const fullHeightWithoutRoundedCorners = fullHeight - endCornerRadius;
    const widthWithoutRoundedCorners = width - (2 * endCornerRadius);

    useEffect(
        () => {
            if (transitionDurationInMillis > 0) {
                const path = d3.select<SVGPathElement, unknown>(pathRef.current);

                path.transition()
                    .duration(transitionDurationInMillis)
                    .attrTween('d', () => {
                        const i = d3.interpolate(1 ** -6, fullHeightWithoutRoundedCorners);

                        return (t: number) => {
                            const transitionHeight = i(t);

                            return determinePath(transitionHeight);
                        };
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [transitionDurationInMillis, fullHeightWithoutRoundedCorners],
    );

    /**
     * We draw the bar using a <path /> (instead of a <rect />) to be able to have rounded corners
     * on only one side of the bar.
     */
    return (
        <g>
            <path
                ref={pathRef}
                fill={color}
                d={determinePath()}
            />

            {displayEndingValue && renderBarValue()}

            {yStartingValueLine && renderStartingValueLine()}
        </g>
    );

    /**
     * Also see https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
     *
     * Don;t forget: svg's are drawn from the top-left
     * - so e.g. a positive x value --> towards the right
     * - so e.g. a negative y value --> towards the top
     *
     * For the 'end' rounded corners:
     *   - syntax: "q<coordinates of control point> <coordinates of end point>"
     *     where q = A quadratic bezier curve
     */
    function determinePath(customHeight: number = fullHeightWithoutRoundedCorners) {
        const pathParts: string[] = [];

        if (isPositiveBar) {
            /* we draw from the bottom upwards + rounded corners (if any) are on top */

            pathParts.push(`M${xCoordinateLeft},${yCoordinateBottom}`); /* Absolute coordinate of bottom-left */
            pathParts.push(`v-${customHeight}`); /* vertical line to top */
            if (doRoundedCorners) {
                pathParts.push(`q0,-${endCornerRadius} ${endCornerRadius},-${endCornerRadius}`);
            }
            pathParts.push(`h${widthWithoutRoundedCorners}`); /* horizontal line to the right */
            if (doRoundedCorners) {
                pathParts.push(`q${endCornerRadius},0 ${endCornerRadius},${endCornerRadius}`);
            }
            pathParts.push(`v${customHeight}`); /* vertical line to the bottom */
            pathParts.push('z'); /* close the curve */
        } else {
            /* we draw from the top downwards + rounded corners (if any) are on the bottom */

            pathParts.push(`M${xCoordinateLeft},${yCoordinateTop}`); /* Absolute coordinate of top-left */
            pathParts.push(`v${customHeight}`); /* vertical line to bottom */
            if (doRoundedCorners) {
                pathParts.push(`q0,${endCornerRadius} ${endCornerRadius},${endCornerRadius}`);
            }
            pathParts.push(`h${widthWithoutRoundedCorners}`); /* horizontal line to the right */
            if (doRoundedCorners) {
                pathParts.push(`q${endCornerRadius},0 ${endCornerRadius},-${endCornerRadius}`);
            }
            pathParts.push(`v-${customHeight}`); /* vertical line to the top */
            pathParts.push('z'); /* close the curve */
        }

        return pathParts.join(' ');
    }

    function renderBarValue() {
        const xCoordinateMiddleOfBar = xCoordinateLeft + (width / 2);
        /* for a positive bar, the endingValue is lower */
        const spacingBetweenBarAndValue = isPositiveBar ? -10 : 18;
        const yCoordinate = yScale(yEndingValue) + spacingBetweenBarAndValue;

        return (
            <g transform={`translate(${xCoordinateMiddleOfBar}, ${yCoordinate})`}>
                <text
                    textAnchor="middle"
                    fontSize="14px"
                    fontWeight="700"
                    fill={CHART_STYLING.colors.typography['900']}
                >
                    {formatFloat(yEndingValue, {
                        nrOfDecimals: 2,
                        stripTrailingDecimalZeros: true,
                    })}
                </text>
            </g>
        );
    }

    function renderStartingValueLine() {
        const yCoordinate = yScale(yStartingValue);

        return (
            <line
                x1={xCoordinateLeft}
                y1={yCoordinate}
                x2={xCoordinateLeft + width}
                y2={yCoordinate}
                stroke={yStartingValueLine.color}
                strokeWidth={1}
            />
        );
    }
}
