import React from 'react';
import { SIZES } from 'config/styling/sizes';
import { IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';
import {
    IPublicInstrumentGroupDetailsEntityData,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtendedInputFormProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import { IFormValues } from 'views/common/inputs/extended/types';
import ContentTitle from 'views/common/layout/ContentTitle';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedMultiTranslationsInput from 'views/common/inputs/extended/ExtendedMultiTranslationsInput';
import { TPropertiesFormDisplayMode } from 'models/state/ui.models';
import { getSubmitActionLabelBasedOnMode } from 'utils/entities/entityDetailUtils';
import { getPublicInstrumentGroupDetailsSchema } from './publicInstrumentGroupDetailsSchema';

export interface IPublicInstrumentGroupFormValues extends IFormValues {
    externalId: string;
    name: IApiMultiTranslationsLabel;
    category: IApiMultiTranslationsLabel;
    imgUri: string;
    description: IApiMultiTranslationsLabel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicProps
    extends Pick<IExtendedInputFormProps<IPublicInstrumentGroupFormValues>, 'initialValues' | 'submit' | 'readOnly'> {
    mode: TPropertiesFormDisplayMode;
}

const LABEL_PREFIX = 'thematic_search.public_instrument_groups.detail';

export default function PublicInstrumentGroupDetailsForm({
    submit,
    mode,
    ...formProps
}: IPublicProps) {
    return (
        <ExtendedInputForm
            name={ExtendedInputFormName.publicInstrumentGroupProperties}
            labelPrefix={LABEL_PREFIX}
            submit={{
                actionLabel: getSubmitActionLabelBasedOnMode(mode),
                ...submit,
            }}
            {...formProps}
            renderFormFields={renderPublicInstrumentGroupPropertiesForm}
            schema={getPublicInstrumentGroupDetailsSchema()}
            maxWidthPixels={SIZES.DETAIL_FORM.MAX_WIDTH}
            reset={{}}
        />
    );

    function renderPublicInstrumentGroupPropertiesForm(
        context: IExtendedInputFormContext<IPublicInstrumentGroupFormValues>,
    ) {
        return (
            <>
                <ContentTitle
                    label={`${LABEL_PREFIX}.sub_title.main`}
                    variant="section"
                />

                {renderPublicInstrumentGroupPropertiesFormFields(context)}
            </>
        );
    }
}

export function renderPublicInstrumentGroupPropertiesFormFields({
    fields,
}: IExtendedInputFormContext<IPublicInstrumentGroupFormValues>) {
    return (
        <>
            <ExtendedMultiTranslationsInput
                formField={fields.name}
                wrapper={{
                    label: 'fields.name.label',
                }}
            />

            <ExtendedMultiTranslationsInput
                formField={fields.category}
                wrapper={{
                    label: 'fields.category.label',
                }}
            />

            <ExtendedMultiTranslationsInput
                formField={fields.description}
                multilineRows
                wrapper={{
                    label: 'fields.description.label',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.externalId}
                wrapper={{
                    label: 'fields.external_id.label',
                }}
            />

            <ExtendedInputText<string>
                formField={fields.imgUri}
                wrapper={{
                    label: 'fields.image_uri.label',
                }}
            />
        </>
    );
}

export function mapPublicInstrumentGroupValuesToApiEntity(
    formValues: IPublicInstrumentGroupFormValues,
): IPublicInstrumentGroupDetailsEntityData {
    return {
        name: formValues.name,
        image_uri: formValues.imgUri,
        external_id: formValues.externalId,
        category: formValues.category,
        description: formValues.description,
    };
}
