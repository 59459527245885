import { useLocation } from 'react-router-dom';

export default function useQuery(...paramNames: string[]): string[] {
    const urlSearchParams = new URLSearchParams(useLocation().search);

    return paramNames.reduce(
        (accumulator, paramName) => {
            accumulator.push(urlSearchParams.get(paramName));
            return accumulator;
        },
        [],
    );
}
