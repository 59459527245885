import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import { IRegisteredEntity } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { BaseApiErrorCode, IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { ISetStateImmutableProps, IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { ICustomAsyncEntity, IForceStateRefreshFilter } from 'models/state/entities.models';
import {
    IApiBaseListResponse,
    IApiEntityListResponseWithPageNr,
    IBaseFetchEntityListApiInput,
} from '@console/core-api/models/api.models';
import { TI18nLabelOrString } from 'models/general.models';
import { FIRST_PAGE_NR, LIST_RESPONSE_NO_COUNT } from '@console/core-api/config/coreApi.config';
import { createAction, getStore } from 'state';
import { mapActiveSortColumnToApiOrderBy } from 'utils/entities/entityListUtils';
import {
    IListConfig,
    IListPageProps,
    initListPage,
    ISearchConfig, TListPageSimpleFilterProps,
    TListPageAdvancedFilterProps, TListPageAdvancedWithSchemaFilterProps,
    IPaginationInfo, IOnChangePageNrWithOffsetProps,
    ISortConfig, IOnChangeSortColumnProps,
    IToListItemsProps,
} from 'views/common/list/ListPage';
import { IOnChangeItemsPerPageProps } from 'views/common/list/DataPagination';
import { IDynamicTitleLabelConfig, isDynamicTitleLabelConfig } from 'views/common/layout/PageTitleBasedOnState';
import {
    ITCustomizeEntityOperationErrorInput,
    TCustomizeEntityOperationErrorOutcome,
} from 'views/common/widget/EntityWrapper';
import { RenderIcon } from '../icons';
import { IListAction } from './ListActionsHeader';

export type { ISortConfigFunctions } from 'views/common/list/ListPage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
export interface IListPageForApiEntityProps<ApiInput extends IBaseFetchEntityListApiInput, AdvancedFilters = any, EntityData = any, ListItem = any>
    extends Omit<IListPageProps<AdvancedFilters, EntityData, ListItem>, 'list' | 'pagination' | 'search' | 'sort'> {
    notifications: StateChangeNotification[];
    list: TListConfigOverruledForApiEntity<EntityData, ListItem>;
    asyncListEntity: IRegisteredEntity<IState, EntityData, StateChangeNotification, IBaseApiErrorClientSide>;
    asyncListEntityFetchTrigger: (apiInput?: ApiInput) => unknown;
    customApiInput?: Partial<ApiInput>;
    setStateOnPageNrChange: TSetStateOnPageNrChange;
    search?: {
        simple?: Omit<TListPageSimpleFilterProps, 'onSearch'> & {
            mapSimpleSearchInputToFetchFilter: (filterValue: string, extraFilterToggles?: TAnyObject) => ApiInput;
        };
        advanced?: Omit<TListPageAdvancedFilterProps<AdvancedFilters>, 'onSearch'>;
        advancedWithSchema?: Omit<TListPageAdvancedWithSchemaFilterProps<AdvancedFilters>, 'onSearch'>;
    };
    sort?: Omit<ISortConfig, 'onChangeSortColumn'>;
    includeRefreshButton?: boolean; // default true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TListConfigOverruledForApiEntity<EntityData = any, ListItem = any> =
    Omit<IListConfig<EntityData, ListItem>, 'asyncEntitySelector' | 'toListItems'>;

export type TSetStateOnPageNrChange = (pageNr: number) => ISetStateImmutableProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
export default function ListPageForApiEntity<ApiInput extends IBaseFetchEntityListApiInput = IBaseFetchEntityListApiInput, AdvancedFilters extends TAnyObject = any>({
    notifications,
    list,
    asyncListEntity,
    asyncListEntityFetchTrigger,
    customApiInput = {},
    setStateOnPageNrChange,
    uiPageKey,
    search,
    sort,
    listActions,
    includeRefreshButton = true,
    box,
    ...other
}: IListPageForApiEntityProps<ApiInput>) {
    const ListPage = initListPage({ notifications });

    const adjustedList: TListConfigOverruledForApiEntity = {
        ...list,
        fetch: {
            customizeError: optionallyCustomizeListFetchError,
            ...list.fetch,
        },
    };
    const adjustedSort: ISortConfig = sort
        ? {
            ...sort,
            onChangeSortColumn,
        }
        : null;
    const adjustedSearch: ISearchConfig<AdvancedFilters> = search
        ? {
            simple: search.simple
                ? {
                    ...search.simple,
                    onSearch: doSimpleSearch,
                }
                : null,
            advanced: search.advanced
                ? {
                    ...search.advanced,
                    onSearch: doAdvancedSearch,
                }
                : null,
            advancedWithSchema: search.advancedWithSchema
                ? {
                    ...search.advancedWithSchema,
                    onSearch: doAdvancedSearch,
                }
                : null,
        } as unknown as ISearchConfig<AdvancedFilters>
        : null;

    return (
        <ListPage
            uiPageKey={uiPageKey}
            box={getBoxConfigIncludingCountTag()}
            list={{
                ...adjustedList,
                asyncEntitySelector: asyncListEntity.select,
                toListItems,
            }}
            pagination={{
                onChangeItemsPerPage,
                onChangePageNr,
                getPaginationInfoSelector,
            }}
            search={adjustedSearch}
            sort={adjustedSort}
            listActions={getAdjustedListActions()}
            {...other}
        />
    );

    function getAdjustedListActions(): IListAction[] {
        if (!includeRefreshButton) {
            return listActions;
        }
        const refreshListAction: IListAction = {
            id: 'refresh_list_button',
            icon: <RenderIcon />,
            label: 'common.action.refresh',
            onClick: () => asyncListEntityFetchTrigger({
                ...customApiInput,
                forceRefresh: true,
            } as ApiInput & IForceStateRefreshFilter),
            showLoaderWhenAsync: false,
        };
        return listActions
            ? listActions.concat([refreshListAction])
            : [refreshListAction];
    }

    function getBoxConfigIncludingCountTag(): { title: IDynamicTitleLabelConfig } {
        if (!box) {
            return null;
        }

        if (isDynamicTitleLabelConfig(box.title)) {
            return {
                title: box.title,
            };
        }

        return {
            title: {
                notifications: [],
                selector: () => (box.title as TI18nLabelOrString),
                tagSelector: () => {
                    const entity = asyncListEntity.select();

                    if (entity) {
                        const entityListData = (entity.data as IApiBaseListResponse<TAnyObject>);

                        if (entityListData
                            && isNumber(entityListData.count)
                            && entityListData.count !== LIST_RESPONSE_NO_COUNT) {
                            return {
                                label: {
                                    text: `${entityListData.count}`,
                                    shouldTranslate: false,
                                },
                            };
                        }
                    }

                    return null;
                },
            },
        };
    }

    function toListItems({ entityData }: IToListItemsProps<IApiEntityListResponseWithPageNr<unknown>, unknown>) {
        return entityData ? entityData.results : null;
    }

    function onChangeItemsPerPage({ itemsPerPage }: IOnChangeItemsPerPageProps) {
        asyncListEntityFetchTrigger({
            ...customApiInput,
            limit: itemsPerPage,
            pageNr: FIRST_PAGE_NR, // automatically go back to the first page
        } as ApiInput);
    }

    function onChangePageNr({ pageNr, offset, itemsPerPage }: IOnChangePageNrWithOffsetProps) {
        if (offset) {
            asyncListEntityFetchTrigger({
                ...customApiInput,
                limit: itemsPerPage,
                pageNr,
                offset,
            } as ApiInput);
        } else {
            getStore().dispatch(createChangePageNrAction(pageNr));
        }
    }

    function onChangeSortColumn({ sortColumn, itemsPerPage }: IOnChangeSortColumnProps) {
        asyncListEntityFetchTrigger({
            ...customApiInput,
            limit: itemsPerPage,
            pageNr: FIRST_PAGE_NR, // automatically go back to the first page
            orderBy: sortColumn ? mapActiveSortColumnToApiOrderBy(sortColumn) : null,
        } as unknown as ApiInput);
    }

    function createChangePageNrAction(pageNr: number) {
        return createAction({
            type: `CHANGE_PAGE_NR-${uiPageKey}`,
            payload: {
                pageNr,
            },
            process({ setStateImmutable }) {
                setStateImmutable(setStateOnPageNrChange(pageNr));
            },
        });
    }

    function getPaginationInfoSelector(state: IState): IPaginationInfo {
        return getPaginationInfoForDefaultResultsList({
            state,
            listAsyncEntitySelector: asyncListEntity.select,
        });
    }

    function doSimpleSearch(filterValue: string, extraFilterToggles?: TAnyObject) {
        asyncListEntityFetchTrigger({
            ...customApiInput,
            ...search.simple.mapSimpleSearchInputToFetchFilter(filterValue, extraFilterToggles),
        });
    }

    function doAdvancedSearch(advancedFilters: AdvancedFilters) {
        asyncListEntityFetchTrigger({
            ...customApiInput,
            ...(advancedFilters as unknown as ApiInput),
        });
    }
}

export function getPaginationInfoForDefaultResultsList({
    state,
    listAsyncEntitySelector,
}: {
    state: IState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listAsyncEntitySelector: (state: IState) => ICustomAsyncEntity<IApiEntityListResponseWithPageNr<any>>;
}): IPaginationInfo {
    const listResponse = listAsyncEntitySelector(state).data;

    if (!listResponse) {
        return {
            nrOfItems: 0,
            totalNrOfItems: undefined,
            offset: null,
        };
    }

    const nrOfItems = listResponse.results ? listResponse.results.length : 0;
    const totalNrOfItems = isSet(listResponse.count) && listResponse.count !== LIST_RESPONSE_NO_COUNT
        ? listResponse.count
        : listResponse.offset === null
            ? nrOfItems
            : undefined;

    const paginationInfo: IPaginationInfo = {
        pageNr: listResponse.pageNr,
        nrOfItems,
        totalNrOfItems,
        offset: listResponse.offset,
    };

    return paginationInfo;
}

function optionallyCustomizeListFetchError({
    error,
}: ITCustomizeEntityOperationErrorInput): TCustomizeEntityOperationErrorOutcome {
    if (error?.response?.code === BaseApiErrorCode.VALIDATION_ERROR) {
        return {
            label: 'error.operation_failed.fetch_validation_error',
            severity: 'warning',
        };
    }

    return false;
}
