import { ALL_CURRENCIES } from '@console/common/config/currencies.config';
import { string, object, array } from '@console/common/utils/schema';

export const portfolioGroupDetailsSchema = object({
    name: string().required().trim(),
    portfolioIds: array().of(string()).minItems(2),
    currency: string().oneOf(ALL_CURRENCIES),
}).required();

export const addPortfolioGroupStepOneSchema = portfolioGroupDetailsSchema.pick(['name', 'currency']);
export const addPortfolioGroupStepTwoSchema = portfolioGroupDetailsSchema.pick(['portfolioIds']);

export const addPortfolioToPortfolioGroupSchema = object({
    portfolioIds: array().of(string()).minItems(1),
}).required();
