import React from 'react';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { Z_INDEX } from 'config/styling/elevation';
import { makeStyles, mixins } from 'views/styling';
import { getIconButton } from 'views/common/buttons/buttonsNoCircularDependencies';
import { GENERIC_CLASS } from 'views/assets/cssInJs/genericClasses';
import { getUtilityChildStyles } from 'views/assets/cssInJs/utilityClasses';
import Text from 'views/common/widget/Text';

export interface IModalProps extends IStyleProps {
    id: string;
    title: TLabel;
    emphasizeTitle?: boolean; // default false - if true, the title will be in the primary color
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    maxWidth?: TModalMaxWidth; // default 'sm'
    className?: string;
    disableClose?: boolean;
}

interface IStyleProps {
    zIndex?: number; // only to be specified if the default of 1300 is not ok
}

export type TModalMaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

const useStyles = makeStyles((theme) => ({
    Modal: {
        ...getUtilityChildStyles(),

        '& .MuiDialog-paper': {
            borderRadius: 8,
            padding: theme.spacing(2, 3),
        },

        '& .__title': {
            ...mixins.typoBold({ size: 18 }),
            marginRight: theme.spacing(3),
            padding: theme.spacing(1, 0),
        },
        '& .__title .__label-emphasized': {
            color: APP_COLORS.PRIMARY['500'],
        },

        '& .closeModalIcon': {
            position: 'absolute',
            top: 0,
            right: 0,
        },

        '& .__content': {
            padding: theme.spacing(1, 0),
        },
    },
}));

export default function Modal({
    id,
    title,
    emphasizeTitle = false,
    open,
    onClose,
    children,
    maxWidth = 'sm',
    className,
    zIndex = Z_INDEX.MODAL,
    disableClose,
}: IModalProps) {
    const classes = useStyles();

    const IconButton = getIconButton();

    return (
        <Dialog
            id={id}
            className={clsx(classes.Modal, GENERIC_CLASS.APP_ROUTE_WRAPPER, className)}
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={maxWidth}
            style={{
                zIndex,
            }}
        >
            <DialogTitle className="__title">
                <div
                    className={clsx(
                        '__label',
                        emphasizeTitle && '__label-emphasized',
                    )}
                >
                    <Text label={title} />
                </div>

                <IconButton
                    id={`${id}_close`}
                    className="closeModalIcon"
                    variant="bare"
                    color="grey"
                    size="M"
                    isCircle={false}
                    onClick={onCloseIcon}
                    icon={<CloseOutlinedIcon />}
                    disabled={disableClose}
                />
            </DialogTitle>
            <div className="__content">
                {children}
            </div>
        </Dialog>
    );

    function onCloseIcon() {
        onClose();
    }
}
