import {
    IApiEntityListResponse, IBaseSingleEntityApiInput,
} from '../../models/api.models';
import {
    IFetchPortfolioGroupsApiInput,
    IPortfolioGroupEntityData,
    ISinglePortfolioGroupApiInput,
    TPortfolioGroup,
    TPortfolioGroupCreate,
    TPortfolioGroupPatch,
    TPortfolioGroupsData,
} from '../../models/portfolioMgmt/portfolioGroups.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchPortfolioGroups({
    orderBy,
    portfolioGroupName,
    externalId,
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    count,
}: IFetchPortfolioGroupsApiInput = {}) {
    return get<TPortfolioGroupsData, IApiEntityListResponse<IPortfolioGroupEntityData>>({
        url: CoreApiPath.PORTFOLIO_GROUPS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .contains({ field: 'name', value: portfolioGroupName })
                .exactMatch({ field: 'external_id', value: externalId })
                .orderBy(orderBy)
                .build(),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchPortfolioGroupDetails({
    portfolioGroupId,
}: ISinglePortfolioGroupApiInput) {
    return get<TPortfolioGroup>({
        url: CoreApiPath.PORTFOLIO_GROUP_DETAILS,
        pathParams: {
            portfolioGroupId,
        },
    });
}

export function createPortfolioGroup(portfolioGroupToCreate: TPortfolioGroupCreate) {
    return post<TPortfolioGroup>({
        url: CoreApiPath.PORTFOLIO_GROUPS,
        body: portfolioGroupToCreate,
    });
}

export function deletePortfolioGroup({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.PORTFOLIO_GROUP_DETAILS,
        pathParams: {
            portfolioGroupId: id,
        },
    });
}

export function patchPortfolioGroup({
    id,
    ...patchFields
}: TPortfolioGroupPatch) {
    return patch<TPortfolioGroup>({
        url: CoreApiPath.PORTFOLIO_GROUP_DETAILS,
        pathParams: {
            portfolioGroupId: id,
        },
        body: patchFields,
    });
}
