import * as React from 'react';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { TTranslatorIfNeeded } from 'models/general.models';

const CustomI18nContext = React.createContext({
    translator: {} as TTranslator,
    translatorIfNeeded: {} as TTranslatorIfNeeded,
    locale: '',
});

export default CustomI18nContext;
