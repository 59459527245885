import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TApiEntity } from '@console/core-api/models/api.models';
import { IPublicInstrumentEntityData } from '@console/core-api/models/thematicSearch/publicInstrument.models';
import { canUserModifyPublicInstrument } from 'state/auth/apiEntityAuthorization.selectors';
import {
    publicInstrumentDetailsEntity,
    triggerPatchPublicInstrumentDetails,
} from 'state/entities/thematicSearch/publicInstrumentDetails';
import { readOnlyEntityClass } from 'views/assets/cssInJs/genericClasses';
import { IDetailField } from 'views/common/detail/DetailFieldsList';
import { IEditForm } from 'views/common/detail/DetailFieldsListWithEditModal';
import PropertiesCategoryBox from 'views/common/detail/PropertiesCategoryBox';
import { publicInstrumentDetailsSchema } from './publicInstrumentDetailsSchema';
import {
    IPublicInstrumentFormValues,
    renderPublicInstrumentPropertiesFormFields,
} from './PublicInstrumentPropertiesForm';

const LABEL_PREFIX = 'thematic_search.public_instruments.detail';

export function PublicInstrumentPropertiesTab() {
    const publicInstrumentData = publicInstrumentDetailsEntity.select().data;
    const isReadOnly = !canUserModifyPublicInstrument(publicInstrumentData);

    return (
        <div className={clsx(readOnlyEntityClass(isReadOnly))}>
            <PropertiesCategoryBox<IPublicInstrumentFormValues>
                id="public_instrument_details_fields"
                category="details"
                labelPrefix={LABEL_PREFIX}
                isReadOnly={isReadOnly}
                fields={getDetailsFields(publicInstrumentData)}
                editGlobalForm={getEditDetailsForm(publicInstrumentData)}
                maxWidth={800}
                centralizeContentBox
            />
        </div>
    );

    function getDetailsFields(instrument: TApiEntity<IPublicInstrumentEntityData>): IDetailField[] {
        return [{
            label: 'name.label',
            value: instrument.name,
        }, {
            label: 'display_name.label',
            value: instrument.display_name,
        }, {
            label: 'external_id.label',
            value: instrument.external_id,
        }, {
            label: 'type.label',
            value: isSet(instrument.type)
                ? `thematic_search.public_instruments.types.${instrument.type.toLowerCase()}`
                : '',
            shouldTranslateValue: true,
        }, {
            label: 'tags.label',
            value: instrument.tags.join(', '),
        }, {
            label: 'image_uri.label',
            value: instrument.image_uri,
        }];
    }

    function getEditDetailsForm(
        instrument: TApiEntity<IPublicInstrumentEntityData>,
    ): IEditForm<IPublicInstrumentFormValues> {
        return {
            initialValues: {
                name: instrument.name,
                displayName: instrument.display_name,
                externalId: instrument.external_id,
                type: instrument.type,
                imgUri: instrument.image_uri,
                tags: instrument.tags,
            },
            schema: publicInstrumentDetailsSchema,
            renderFormFields: renderPublicInstrumentPropertiesFormFields,
            submit: {
                onSubmit: ({ values }) => triggerPatchPublicInstrumentDetails((currentInstrument) => {
                    /* eslint-disable no-param-reassign */
                    currentInstrument.name = values.name;
                    currentInstrument.display_name = values.displayName;
                    currentInstrument.external_id = values.externalId;
                    currentInstrument.type = values.type;
                    currentInstrument.image_uri = values.imgUri;
                    currentInstrument.tags = values.tags;
                    /* eslint-enable no-param-reassign */
                }),
            },
            modalMaxWidth: 'sm',
            modalTitle: `${LABEL_PREFIX}.properties.modal_titles.details`,
            labelPrefix: LABEL_PREFIX,
        };
    }
}
