import { nanoid, customAlphabet, urlAlphabet } from 'nanoid';

export function generateId(len?: number) {
    return nanoid(len);
}

export function generateAlphaNumericId(len?: number) {
    const alphabet = urlAlphabet
        .replace('_', '')
        .replace('-', '');
    return customAlphabet(alphabet)(len);
}
