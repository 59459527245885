import { object } from '@console/common/utils/schema';
import { CORE_API_ENTITY_TYPE_TO_ID_REGEX, CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { entityIdArray } from '@console/core-api/utils/schema/entityIdSchema';
import { advancedPortfolioFiltersServerSideSchema } from '@console/bff/server/utils/schemas/portfolio.schemas';

export const PORTFOLIO_ID_REGEX = CORE_API_ENTITY_TYPE_TO_ID_REGEX[CoreApiEntityType.portfolios];

export const advancedPortfolioFiltersClientSideSchema = advancedPortfolioFiltersServerSideSchema
    .omit(['ids'])
    .concat(object({
        ids: entityIdArray({
            entityType: CoreApiEntityType.portfolios,
            entityName: 'portfolio',
        }),
    }));
