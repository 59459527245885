import React from 'react';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
import { TLabel } from 'models/general.models';
import { makeStyles } from 'views/styling';
import Text from 'views/common/widget/Text';

export type TAlertSeverity = Color;

interface IPublicProps {
    severity?: TAlertSeverity; // default 'info'
    title?: TLabel;
    message: TLabel;
}

const useStyles = makeStyles((theme) => ({
    Alert: {
        margin: theme.spacing(1, 2),

        '& .MuiAlert-root': {
            borderRadius: 12,
        },
    },
}));

export default function Alert({
    severity = 'info',
    title,
    message,
}: IPublicProps) {
    const classes = useStyles();

    return (
        <div className={classes.Alert}>
            <MuiAlert variant="filled" severity={severity}>
                {title && (
                    <MuiAlertTitle>
                        <Text label={title} />
                    </MuiAlertTitle>
                )}

                <Text label={message} />
            </MuiAlert>
        </div>
    );
}
