import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IPublicInstrumentGroupDetailsEntityData,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import React from 'react';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import { publicInstrumentDetailsEntity } from 'state/entities/thematicSearch/publicInstrumentDetails';
import { triggerCreatePublicInstrumentGroup } from 'state/entities/thematicSearch/publicInstrumentGroupDetails';
import AddPage from 'views/common/add/AddPage';
import { getMultiTranslationsLabelInitialValue } from 'state/appConfig/selectors';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import PublicInstrumentGroupDetailsForm, {
    IPublicInstrumentGroupFormValues,
    mapPublicInstrumentGroupValuesToApiEntity,
} from '../PublicInstrumentGroupDetail/PublicInstrumentGroupPropertiesForm';

export function PublicInstrumentGroupAdd() {
    const initialValues: IPublicInstrumentGroupFormValues = {
        name: getMultiTranslationsLabelInitialValue(),
        externalId: '',
        category: getMultiTranslationsLabelInitialValue(),
        imgUri: '',
        description: getMultiTranslationsLabelInitialValue(),
    };

    return (
        <AddPage
            titleLabel="thematic_search.public_instrument_groups.add.title"
            entity={{
                notifications: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUPS_DATA],
                asyncEntitySelector: publicInstrumentDetailsEntity.select,
            }}
        >
            <PublicInstrumentGroupDetailsForm
                initialValues={initialValues}
                mode="add"
                submit={{
                    onSubmit: onSubmitAdd,
                }}
            />
        </AddPage>
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IPublicInstrumentGroupFormValues>) {
        return triggerCreatePublicInstrumentGroup(
            enrichApiEntityDataForCreate<IPublicInstrumentGroupDetailsEntityData>(
                mapPublicInstrumentGroupValuesToApiEntity(values),
            ),
        );
    }
}
