import {
    IApiEntityListResponseWithPageNr,
    IBaseFetchEntityListApiInput,
    TApiEntity,
    TApiEntityCreate,
    TApiEntityId,
    TApiEntityPatch,
} from '../api.models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFetchPublicInstrumentsApiInput
    extends IBaseFetchEntityListApiInput, Partial<IAdvancedPublicInstrumentsFilters> {}

export interface IAdvancedPublicInstrumentsFilters {
    externalId: string;
    name: string;
}

export interface ISinglePublicInstrumentApiInput {
    publicInstrumentId: TApiEntityId;
}

export enum PublicInstrumentType {
    Stock = 'STOCK',
    Bond = 'BOND',
    Etf = 'ETF',
    MutualFund = 'MUTUAL_FUND',
    Token = 'TOKEN',
    Index = 'INDEX',
    Currency = 'CURRENCY',
    InterestRate = 'INTEREST_RATE',
}

export interface IPublicInstrumentEntityData {
    external_id: string;
    name?: string;
    display_name?: string;
    tags?: string[];
    type?: PublicInstrumentType;
    image_uri?: string;
}

export type TPublicInstrument = TApiEntity<IPublicInstrumentEntityData>;
export type TPublicInstrumentsData = IApiEntityListResponseWithPageNr<IPublicInstrumentEntityData>;
export type TTPublicInstrumentPatch = TApiEntityPatch<IPublicInstrumentEntityData>;
export type TTPublicInstrumentCreate = TApiEntityCreate<IPublicInstrumentEntityData>;
