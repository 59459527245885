import React from 'react';
import { triggerCreateUser } from 'state/entities/userMgmt/userDetails';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import { enrichApiEntityDataForCreate } from 'state/entities/apiEntityEnricher';
import GenericUserAdd from 'views/userMgmt/shared/GenericUserAdd';
import {
    IUserAddFormValues,
    mapUserAddFormValuesToApiEntity,
} from 'views/userMgmt/shared/GenericUserAddPropertiesForm';
import { triggerResetUsersFetch } from 'state/entities/userMgmt/users';
import { ROUTE_KEY } from 'views/routeKeys';
import { DEFAULT_USER_TAB_KEY } from 'config/tabs.config';
import { redirectTo } from 'views/routes';

export default function UserAdd() {
    return (
        <GenericUserAdd
            onSubmit={onSubmitAdd}
        />
    );

    function onSubmitAdd({ values }: IOnSubmitProps<IUserAddFormValues>) {
        return triggerCreateUser({
            user: enrichApiEntityDataForCreate<IUserEntityData>(mapUserAddFormValuesToApiEntity(values)),
            onPreSuccess: triggerResetUsersFetch,
            onSuccess: ({ apiResult }) => redirectTo({
                routeKey: ROUTE_KEY.R_USER_DETAIL,
                params: {
                    userId: apiResult.id,
                    userTab: DEFAULT_USER_TAB_KEY,
                },
            }),
        });
    }
}
