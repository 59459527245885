import React from 'react';
import { IIconButtonProps } from './types';
import IconButton from './IconButton';
import { EditFullIcon } from '../icons';

// eslint-disable-next-line max-len, @typescript-eslint/no-empty-interface
interface IPublicProps
    extends Pick<IIconButtonProps, 'id' | 'className' | 'onClick' | 'disabled' | 'tooltip' | 'tooltipPlacement'> {
    isCancel?: boolean; // default false
}

export default function IconButtonEdit({
    id,
    className,
    onClick,
    disabled,
    tooltip,
    isCancel = false,
    tooltipPlacement = 'top',
}: IPublicProps) {
    return (
        <IconButton
            id={id}
            className={className}
            variant="filled"
            isCircle
            color={(disabled) ? 'grey' : (isCancel) ? 'grey_inverted' : 'primary'}
            onClick={onClick}
            disabled={disabled}
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            icon={<EditFullIcon />}
            size="S"
            svgSize={16}
        />
    );
}
