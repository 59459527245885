import { setNumbersDisplayCustomizationConfig } from '@console/common/utils/float/floatUtils';
import { setCurrencyDisplayCustomizationConfig } from '@console/common/utils/number/amountUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { api } from 'api';
import { createAction } from 'state';
import { redirectToLogin } from 'views/routes';

export async function fetchAndProcessTenantSettings() {
    const tenantSettings = await api.bff.tenantSettings.fetchTenantSettings();

    setNumbersDisplayCustomizationConfig(tenantSettings.displayCustomization.numbers);
    setCurrencyDisplayCustomizationConfig(tenantSettings.displayCustomization.currency);

    return tenantSettings;
}

export const refreshTenantSettings = () => createAction({
    type: 'REFRESH_TENANT_SETTINGS',
    payload: {},
    async process({ setStateImmutable }) {
        try {
            const tenantSettings = await fetchAndProcessTenantSettings();

            setStateImmutable({
                toState: (draftState) => {
                    draftState.appConfig.tenant = tenantSettings;
                },
                notificationsToTrigger: [StateChangeNotification.APP_CONFIG_TENANT],
            });
        } catch (error) {
            redirectToLogin();
        }
    },
});
