import { IUnderlyingRequestApiInput } from '@console/api-base/server/request/getUnderlyingApiRequestConfig';
import { enhanceApiOptimizationMissingError } from '@console/api-base/client/error/clientSideApiErrorUtils';
import { ISinglePolicyApiInput } from '../../models/portfolioMgmt/policy.models';
import {
    IPolicyOptimizationEntityData,
    IPolicyOptimization,
    ITriggerAdHocOptimizationResponse,
    TFetchPolicyOptimizationApiInput,
} from '../../models/portfolioMgmt/policySimulation.models';
import { TApiEntity } from '../../models/api.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { get, post } from '../coreApiRequestWrapper';

/**
 * To fetch the "cached" policy optimization.
 */
export function fetchCachedPolicyOptimization({
    policyId,
    underlyingApiRequestConfig,
}: ISinglePolicyApiInput & IUnderlyingRequestApiInput) {
    return get<IPolicyOptimization, TApiEntity<IPolicyOptimizationEntityData>>({
        url: CoreApiPath.POLICY_OPTIMIZATION,
        pathParams: {
            policyId,
        },
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            result: data,
        }),
        enhanceError: enhanceApiOptimizationMissingError,
        ...underlyingApiRequestConfig,
    });
}

/**
 * First step to fetch the "ad hoc" policy optimization (for when user wants to simulate with unsaved changes).
 * >> create uniq id to optimize
 */
export function triggerAdHocOptimization({
    policyId,
    shouldUseRelaxedProcessing = true,
    underlyingApiRequestConfig,
    ...other
}: TFetchPolicyOptimizationApiInput & IUnderlyingRequestApiInput) {
    const baseCurrencyKey = `$${other?.algorithmSettings?.execution_settings?.base_currency}`;
    return post<ITriggerAdHocOptimizationResponse>({
        url: CoreApiPath.ALGORITHMS_IVAR_OPTIMIZE,
        pathParams: {
            policyId,
        },
        body: {
            algorithm_settings: other.algorithmSettings,
            portfolio: {
                [baseCurrencyKey]: other.investmentAmount,
            },
            relaxed: shouldUseRelaxedProcessing,
        },
        ...underlyingApiRequestConfig,
    });
}

/**
 * Second step to fetch the "ad hoc" policy optimization.
 * >> returns result of optimization when ready (status 200), otherwise status 202
 */
export function fetchAdHocOptimization({
    location,
    underlyingApiRequestConfig,
}: ITriggerAdHocOptimizationResponse & IUnderlyingRequestApiInput) {
    return get<IPolicyOptimization, TApiEntity<IPolicyOptimizationEntityData>>({
        url: location,
        mapResponse: ({ data, status }) => ({
            apiStatus: status,
            result: data,
        }),
        ...underlyingApiRequestConfig,
    });
}
