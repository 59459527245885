import React from 'react';
import { makeStyles } from 'views/styling';
import InputCheckboxField, {
    IInputCheckboxFieldProps, IOnChangeCheckboxProps,
} from 'views/common/inputs/base/InputCheckboxField';
import { ExtendedInputFormContext } from './ExtendedInputForm';
import { IExtendedInputBaseProps } from './ExtendedInputText';
import ExtendedInputWrapper, { IExtendedInputWrapperProps } from './ExtendedInputWrapper';

interface IExtendedInputCheckboxProps<Name extends string = string>
    extends Omit<IExtendedInputBaseProps, 'wrapper'>,
    Omit<IInputCheckboxFieldProps<Name>, 'id' | 'name' | 'checked' | 'onChange'> {
    wrapper?: Omit<IExtendedInputWrapperProps, 'disabled' | 'children' | 'readOnly' | 'hasError'>;
    inForm?: boolean; // default false
}

const useStyles = makeStyles((theme) => ({
    InFormCheckboxWrapper: {
        '& .inFormToggle': {
            marginTop: theme.spacing(4),
        },
    },
}));

export default function ExtendedInputCheckbox<Name extends string = string>({
    readOnly = false,
    inForm = false,
    formField,
    wrapper,
    ...checkboxFieldProps
}: IExtendedInputCheckboxProps<Name>) {
    const classes = useStyles();
    return (
        <ExtendedInputFormContext.Consumer>
            {({ readOnlyForm, setFieldValue }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChange({ checked }: IOnChangeCheckboxProps) {
                    setFieldValue({ fieldName: formField.fieldName, value: checked });
                }

                const checkboxField = (
                    <InputCheckboxField
                        id={formField.fieldId}
                        name={formField.fieldName}
                        checked={formField.value as boolean}
                        onChange={onChange}
                        {...checkboxFieldProps}
                        disabled={checkboxFieldProps.disabled || isFieldReadOnly}
                        error={formField.error}
                        emphasizeError={formField.emphasizeError}
                    />
                );

                if (inForm) {
                    return (
                        <div className={classes.InFormCheckboxWrapper}>
                            <ExtendedInputWrapper
                                disabled={checkboxFieldProps.disabled}
                                hasError={!!formField.error && formField.emphasizeError}
                                readOnly
                                shouldPrefixLabel={false}
                                {...wrapper}
                            >
                                <div className="inFormToggle">
                                    {checkboxField}
                                </div>
                            </ExtendedInputWrapper>
                        </div>
                    );
                }

                if (wrapper) {
                    return (
                        <ExtendedInputWrapper
                            disabled={checkboxFieldProps.disabled}
                            readOnly={isFieldReadOnly}
                            hasError={!!formField.error && formField.emphasizeError}
                            {...wrapper}
                        >
                            {checkboxField}
                        </ExtendedInputWrapper>
                    );
                }

                return checkboxField;
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
