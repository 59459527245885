import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { updateSelfMgmtReportingPageVars } from 'state/ui/uiPages.actions';
import { getSelfMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { triggerFetchMgmtReportsSelf } from 'state/entities/mgmtReporting/triggerFetchMgmtReportsGeneric';
import { IObserveProps, observe } from 'views/observe';
import MgmtReportingFilterBlock from '../blocks/MgmtReportingFilterBlock';

function SelfMgmtReportingFilterBlock({ dispatch, state }: IObserveProps) {
    const { period } = getSelfMgmtReportingPageVars(state);

    return (
        <MgmtReportingFilterBlock
            currentPeriod={period}
            updatePeriodData={(currentPeriod) => {
                dispatch(
                    updateSelfMgmtReportingPageVars({
                        periodType: currentPeriod.type,
                    }),
                );

                triggerFetchMgmtReportsSelf({ forceRefresh: true });
            }}
        />
    );
}

export default observe(
    [StateChangeNotification.MGMT_REPORTING_SELF_UI_VARS],
    SelfMgmtReportingFilterBlock,
);
