import { TI18nLabelOrString } from 'models/general.models';
import React from 'react';
import { makeStyles, mixins } from 'views/styling';
import { SIZES } from 'config/styling/sizes';
import { IFormValues } from '../inputs/extended/ExtendedInputForm';
import CenteredDetail from '../layout/CenteredDetail';
import ContentBox from '../layout/ContentBox';
import { IDetailField, IDetailFieldsListProps } from './DetailFieldsList';
import DetailFieldsListWithEditModal, { IEditForm, IEditForms } from './DetailFieldsListWithEditModal';

interface IPublicProps<FormValues extends IFormValues> extends Pick<IDetailFieldsListProps, 'clearAll' > {
    id: string;
    category: string;
    fields: IDetailField[];
    isReadOnly: boolean;
    titleTooltip?: TI18nLabelOrString;
    shouldPrefixLabels?: boolean; // default true
    labelPrefix: string;
    editFieldForms?: IEditForms;
    editGlobalForm?: IEditForm<FormValues>;
    maxWidth?: number;
    centralizeContentBox?: boolean;
}

interface IStyleProps {
    centralize?: boolean;
    maxWidth?: number;
}

const useStyles = makeStyles((theme) => ({
    PropertiesCategoryBox: {
        ...mixins.widthMax(),
        marginBottom: theme.spacing(2),

        maxWidth: ({ maxWidth, centralize }: IStyleProps) => centralize && maxWidth,
        marginLeft: ({ centralize }: IStyleProps) => centralize && 'auto',
        marginRight: ({ centralize }: IStyleProps) => centralize && 'auto',
    },
}));

export default function PropertiesCategoryBox<FormValues extends IFormValues>({
    id,
    category,
    fields,
    labelPrefix,
    isReadOnly,
    editFieldForms,
    editGlobalForm,
    maxWidth,
    titleTooltip,
    shouldPrefixLabels = true,
    centralizeContentBox,
    ...otherProps
}: IPublicProps<FormValues>) {
    const classes = useStyles({ centralize: centralizeContentBox, maxWidth: maxWidth || SIZES.DETAIL_FORM.MAX_WIDTH });

    return (
        <ContentBox noWrapperPadding className={classes.PropertiesCategoryBox}>
            <CenteredDetail maxWidth={maxWidth}>
                <DetailFieldsListWithEditModal<string, FormValues>
                    id={id}
                    title={{
                        label: `${labelPrefix}.properties.box_titles.${category}`,
                        tooltip: titleTooltip,
                        showTooltipUntilDismissed: true,
                        shouldPrefixLabel: false,
                    }}
                    fields={fields}
                    labelPrefix={shouldPrefixLabels ? `${labelPrefix}.fields` : ''}
                    isReadOnly={isReadOnly}
                    editFieldForms={editFieldForms}
                    editGlobalForm={editGlobalForm}
                    {...otherProps}
                />
            </CenteredDetail>
        </ContentBox>
    );
}
