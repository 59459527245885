import isSet from '@snipsonian/core/cjs/is/isSet';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { TNumberRange } from '@console/core-api/models/api.models';
import { IPolicyBenchmarkConstraints, TPolicy } from '@console/core-api/models/portfolioMgmt/policy.models';
import { IOperationPermissions } from '@console/core-api/utils/entities/userGroups/extractUserOperationPermissions';
import { ITenantPolicySettingsBoundaries } from '@console/bff/models/settings/tenantSettings.models';
import { TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import {
    RANGE_FROM_0_TO_1,
    RANGE_FROM_MIN1_TO_1,
    RANGE_FROM_0_TO_INFINITY,
} from '@console/core-api/config/range.config';
import {
    IPolicyAllocationBoundariesSettingsRules,
    IPolicySettingRuleBoundedNumberRangeField,
    IPolicySettingsRules,
    PolicySettingRuleType,
    PolicySettingNumberType,
    PolicySettingFieldType,
    IPolicySettingRuleMultiValueField,
    IPolicySettingRuleSingleValueField, IPolicySettingRuleBoundedSingleNumberField,
} from 'models/ui/policySettings.ui.models';
import { canUserCreateBasePolicy } from 'state/auth/apiEntityAuthorization.selectors';

/**
 * See https://investsuite.atlassian.net/wiki/spaces/CA/pages/2494660621/Policies+inheritance
 * for some more info.
 */

export function determinePolicySettingsRules({
    userPermissions,
    tenantPolicySettingsBoundaries,
    parentPolicy,
}: {
    userPermissions: Partial<IOperationPermissions>;
    tenantPolicySettingsBoundaries: ITenantPolicySettingsBoundaries;
    parentPolicy: TEnhancedPolicy | TPolicy;
}): IPolicySettingsRules {
    const hasParentPolicy = !!parentPolicy;
    const baseRuleForExecutionConstraints = hasParentPolicy
        ? PolicySettingRuleType.CANNOT_DIFFER
        : null;
    const baseRuleForPortfolioUpdateConstraints = baseRuleForExecutionConstraints;

    const parentExecutionSettings = parentPolicy?.coerced_config.algorithm_settings.execution_settings;
    const parentPortfolioConstraints = parentPolicy?.coerced_config.algorithm_settings.portfolio_constraints;
    const parentPortfolioUpdateConstraints =
        parentPolicy?.coerced_config.algorithm_settings.portfolio_update_constraints;
    const parentBenchmarkConstraints = parentPolicy?.coerced_config.algorithm_settings.benchmark_constraints;

    return {
        hasParentPolicy,
        parent: {
            isRequired: !canUserCreateBasePolicy({ userPermissions }),
        },
        details: {
            tags: createRuleForMultiValueField({
                rule: hasParentPolicy
                    ? PolicySettingRuleType.MORE_ITEMS_ALLOWED
                    : PolicySettingRuleType.NONE,
                boundaryValues: parentPolicy?.tags,
                parentValue: parentPolicy?.tags,
            }),
        },
        universe: {
            instruments: createRuleForMultiValueField({
                rule: (hasParentPolicy && isArrayWithValues(parentPortfolioConstraints?.investment_universe))
                    ? PolicySettingRuleType.LESS_ITEMS_ALLOWED
                    : PolicySettingRuleType.NONE,
                boundaryValues: parentPortfolioConstraints?.investment_universe,
                parentValue: parentPortfolioConstraints?.investment_universe,
            }),
            buyInstruments: createRuleForMultiValueField({
                rule: (hasParentPolicy && isArrayWithValues(parentPortfolioConstraints?.buy_universe))
                    ? PolicySettingRuleType.LESS_ITEMS_ALLOWED
                    : PolicySettingRuleType.NONE,
                boundaryValues: parentPortfolioConstraints?.buy_universe,
                parentValue: parentPortfolioConstraints?.buy_universe,
            }),
        },
        executionConstraints: {
            currency: createRuleForSingleValueField({
                rule: baseRuleForExecutionConstraints,
                boundaryValue: parentExecutionSettings?.base_currency,
                parentValue: parentExecutionSettings?.base_currency,
            }),
            transactionAmountRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToAmountBoundaries(parentExecutionSettings
                    ? parentExecutionSettings.transaction_amount
                    : tenantPolicySettingsBoundaries.transactionAmount.range),
                parentValue: parentExecutionSettings?.transaction_amount,
            }),
            costPerTransactionAmount: createRuleForBoundedSingleNumberField({
                rule: baseRuleForExecutionConstraints,
                ...mapNumberRangeToAmountBoundaries(RANGE_FROM_0_TO_INFINITY),
                boundaryValue: parentExecutionSettings?.cost_per_transaction_amount,
                parentValue: parentExecutionSettings?.cost_per_transaction_amount,
            }),
            costPerTransactionFraction: createRuleForBoundedSingleNumberField({
                rule: baseRuleForExecutionConstraints,
                ...mapNumberRangeToPercentageBoundaries(RANGE_FROM_0_TO_1),
                boundaryValue: parentExecutionSettings?.cost_per_transaction_fraction,
                parentValue: parentExecutionSettings?.cost_per_transaction_fraction,
            }),
            combineTransactionCostMode: createRuleForSingleValueField({
                rule: baseRuleForExecutionConstraints,
                boundaryValue: parentExecutionSettings?.combine_transaction_cost_components,
                parentValue: parentExecutionSettings?.combine_transaction_cost_components,
            }),
            includeFractionalShares: createRuleForSingleValueField({
                rule: baseRuleForExecutionConstraints,
                boundaryValue: parentExecutionSettings?.fractional_shares,
                parentValue: parentExecutionSettings?.fractional_shares,
            }),
        },
        portfolioConstraints: {
            momentum: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToPercentageBoundaries(parentPortfolioConstraints
                    ? parentPortfolioConstraints.momentum
                    : tenantPolicySettingsBoundaries.momentum.range),
                parentValue: parentPortfolioConstraints?.momentum,
            }),
            positionAmountRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToAmountBoundaries(parentPortfolioConstraints
                    ? parentPortfolioConstraints.holdings.position_amount
                    : tenantPolicySettingsBoundaries.positionAmount.range),
                parentValue: parentPortfolioConstraints?.holdings?.position_amount,
            }),
            positionFractionRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToPercentageBoundaries(parentPortfolioConstraints?.holdings?.position_fraction),
                parentValue: parentPortfolioConstraints?.holdings?.position_fraction,
            }),
            cashAmountRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToAmountBoundaries(parentPortfolioConstraints
                    ? parentPortfolioConstraints.holdings.cash_amount
                    : tenantPolicySettingsBoundaries.cashAmount.range),
                parentValue: parentPortfolioConstraints?.holdings?.cash_amount,
            }),
            cashFractionRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToPercentageBoundaries(parentPortfolioConstraints?.holdings.cash_fraction),
                parentValue: parentPortfolioConstraints?.holdings?.cash_fraction,
            }),
        },
        portfolioUpdateConstraints: {
            minPortfolioOptimalityImprovementFraction: createRuleForBoundedSingleNumberField({
                rule: baseRuleForPortfolioUpdateConstraints,
                ...mapNumberRangeToPercentageBoundaries(
                    tenantPolicySettingsBoundaries.minPortfolioOptimalityImprovementFraction.range,
                ),
                boundaryValue: parentPortfolioUpdateConstraints?.min_portfolio_update_optimality_improvement_fraction,
                parentValue: parentPortfolioUpdateConstraints?.min_portfolio_update_optimality_improvement_fraction,
            }),
            maxPortfolioConstraintsViolationFraction: createRuleForBoundedSingleNumberField({
                rule: baseRuleForPortfolioUpdateConstraints,
                ...mapNumberRangeToPercentageBoundaries(
                    tenantPolicySettingsBoundaries.maxPortfolioConstraintsViolationFraction.range,
                ),
                boundaryValue: parentPortfolioUpdateConstraints?.max_portfolio_constraint_violation_pctpoints,
                parentValue: parentPortfolioUpdateConstraints?.max_portfolio_constraint_violation_pctpoints,
            }),
            cashFractionRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToPercentageBoundaries(parentPortfolioUpdateConstraints?.holdings.cash_fraction),
                parentValue: parentPortfolioUpdateConstraints?.holdings?.cash_fraction,
            }),
            minPortfolioValue: createRuleForBoundedSingleNumberField({
                rule: baseRuleForPortfolioUpdateConstraints,
                ...mapNumberRangeToAmountBoundaries(
                    tenantPolicySettingsBoundaries.minPortfolioValue.range,
                ),
                boundaryValue: parentPortfolioUpdateConstraints?.min_portfolio_value,
                parentValue: parentPortfolioUpdateConstraints?.min_portfolio_value,
            }),
        },
        benchmark: {
            id: createRuleForSingleValueField({
                rule: (hasParentPolicy && isSet(parentExecutionSettings.benchmark_id))
                    ? PolicySettingRuleType.CANNOT_DIFFER
                    : PolicySettingRuleType.NONE,
                boundaryValue: parentExecutionSettings?.benchmark_id,
                parentValue: parentExecutionSettings?.benchmark_id,
            }),
            positionFractionRange: createRuleForBoundedNumberRangeField({
                ...mapNumberRangeToPercentageBoundaries(
                    parentBenchmarkConstraints?.holdings?.position_fraction,
                    { defaultPercentageRange: RANGE_FROM_MIN1_TO_1 },
                ),
                parentValue: parentBenchmarkConstraints?.holdings?.position_fraction,
            }),
            relativeIvarWeight: createRuleForBoundedSingleNumberField({
                rule: (hasParentPolicy && isSet(parentExecutionSettings.benchmark_id))
                    ? PolicySettingRuleType.CANNOT_DIFFER
                    : PolicySettingRuleType.WITHIN_BOUNDARIES,
                ...mapNumberRangeToPercentageBoundaries(RANGE_FROM_0_TO_1),
                boundaryValue: parentBenchmarkConstraints?.relative_ivar_weight,
                parentValue: parentBenchmarkConstraints?.relative_ivar_weight,
            }),
            ...determinePolicyAllocationBoundariesRules({
                parentPortfolioConstraints: parentBenchmarkConstraints,
                defaultPercentageRange: RANGE_FROM_MIN1_TO_1,
            }),
        },
        allocationBoundaries: determinePolicyAllocationBoundariesRules({
            parentPortfolioConstraints,
            defaultPercentageRange: RANGE_FROM_0_TO_1,
        }),
    };
}

function determinePolicyAllocationBoundariesRules({
    parentPortfolioConstraints,
    defaultPercentageRange,
}: {
    parentPortfolioConstraints: IPolicyBenchmarkConstraints;
    defaultPercentageRange: TNumberRange;
}): IPolicyAllocationBoundariesSettingsRules {
    return {
        assetClassAlternativesRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.alternatives,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.alternatives,
        }),
        assetClassBondsRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.bonds,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.bonds,
        }),
        assetClassCashRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.cash,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.cash,
        }),
        assetClassCommoditiesRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.commodities,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.commodities,
        }),
        assetClassStocksRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.stocks,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.stocks,
        }),
        assetClassCorporateRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.corporate,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.corporate,
        }),
        assetClassEurobondRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.eurobond,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.eurobond,
        }),
        assetClassEquityRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.equity,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.equity,
        }),
        assetClassGovernmentRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.government,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.government,
        }),
        assetClassMoneyMarketRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.money_market,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.money_market,
        }),
        assetClassSukukRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.sukuk,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.sukuk,
        }),
        assetClassFixedIncomeRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.fixed_income,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.fixed_income,
        }),
        assetClassOtherRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.other,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.other,
        }),
        assetClassRealEstateRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.asset_classes?.real_estate,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.asset_classes?.real_estate,
        }),

        regionBondsAsiaRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.bonds?.asia_pacific_developed,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.bonds?.asia_pacific_developed,
        }),
        regionBondsEmergingRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.bonds?.emerging,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.bonds?.emerging,
        }),
        regionBondsEuropeRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.bonds?.europe_developed,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.bonds?.europe_developed,
        }),
        regionBondsAmericaRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.bonds?.north_america,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.bonds?.north_america,
        }),

        regionStocksAsiaRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.stocks?.asia_pacific_developed,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.stocks?.asia_pacific_developed,
        }),
        regionStocksEmergingRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.stocks?.emerging,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.stocks?.emerging,
        }),
        regionStocksEuropeRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.stocks?.europe_developed,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.stocks?.europe_developed,
        }),
        regionStocksAmericaRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.regions?.stocks?.north_america,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.regions?.stocks?.north_america,
        }),

        bondTypeConvertibleRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.bond_types?.convertible,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.bond_types?.convertible,
        }),
        bondTypeCorporateRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.bond_types?.corporate,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.bond_types?.corporate,
        }),
        bondTypeGovernmentRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.bond_types?.government,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.bond_types?.government,
        }),

        sectorBasicMaterialsRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.basic_materials,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.basic_materials,
        }),
        sectorCommunicationServicesRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.communication_services,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.communication_services,
        }),
        sectorConsumerCyclicalRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.consumer_cyclical,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.consumer_cyclical,
        }),
        sectorConsumerDefensiveRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.consumer_defensive,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.consumer_cyclical,
        }),
        sectorEnergyRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.energy,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.energy,
        }),
        sectorFinancialServicesRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.financial_services,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.financial_services,
        }),
        sectorHealthcareRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.healthcare,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.healthcare,
        }),
        sectorIndustrialsRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.industrials,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.industrials,
        }),
        sectorRealEstateRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.real_estate,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.real_estate,
        }),
        sectorTechnologyRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.technology,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.technology,
        }),
        sectorUtilitiesRange: createRuleForBoundedNumberRangeField({
            ...mapNumberRangeToPercentageBoundaries(
                parentPortfolioConstraints?.sectors?.utilities,
                { defaultPercentageRange },
            ),
            parentValue: parentPortfolioConstraints?.sectors?.utilities,
        }),
    };
}

function mapNumberRangeToAmountBoundaries(
    numberRange: TNumberRange,
    {
        defaultAmountRange = RANGE_FROM_0_TO_INFINITY,
    }: {
        defaultAmountRange?: TNumberRange;
    } = {},
): Pick<IPolicySettingRuleBoundedNumberRangeField, 'boundaryMin' | 'boundaryMax' | 'range' | 'numberType'> {
    return {
        boundaryMin: (numberRange || defaultAmountRange)[0],
        boundaryMax: (numberRange || defaultAmountRange)[1],
        range: numberRange || defaultAmountRange,
        numberType: PolicySettingNumberType.AMOUNT,
    };
}

function mapNumberRangeToPercentageBoundaries(
    numberRange: TNumberRange,
    {
        defaultPercentageRange = RANGE_FROM_0_TO_1,
    }: {
        defaultPercentageRange?: TNumberRange;
    } = {},
): Pick<IPolicySettingRuleBoundedNumberRangeField, 'boundaryMin' | 'boundaryMax' | 'range' | 'numberType'> {
    return {
        boundaryMin: (numberRange || defaultPercentageRange)[0],
        boundaryMax: (numberRange || defaultPercentageRange)[1],
        range: numberRange || defaultPercentageRange,
        numberType: PolicySettingNumberType.PERCENTAGE_TO_1,
    };
}

function createRuleForMultiValueField<Value>({
    rule = PolicySettingRuleType.NONE,
    ...otherInput
}: Omit<IPolicySettingRuleMultiValueField<Value>, 'fieldType' | 'rule' | 'isParentValueSet'> & {
    rule?: PolicySettingRuleType;
}): IPolicySettingRuleMultiValueField<Value> {
    return {
        fieldType: PolicySettingFieldType.MULTI_VALUE,
        rule,
        isParentValueSet: () => isArrayWithValues(otherInput.parentValue),
        ...otherInput,
    };
}

function createRuleForSingleValueField<Value>({
    rule = PolicySettingRuleType.NONE,
    ...otherInput
}: Omit<IPolicySettingRuleSingleValueField<Value>, 'fieldType' | 'rule' | 'isParentValueSet'> & {
    rule?: PolicySettingRuleType;
}): IPolicySettingRuleSingleValueField<Value> {
    return {
        fieldType: PolicySettingFieldType.SINGLE_VALUE,
        rule,
        isParentValueSet: () => isSet(otherInput.parentValue),
        ...otherInput,
    };
}

function createRuleForBoundedNumberRangeField({
    rule = PolicySettingRuleType.WITHIN_BOUNDARIES,
    ...otherInput
}: Omit<IPolicySettingRuleBoundedNumberRangeField, 'fieldType' | 'rule' | 'isParentValueSet'> & {
    rule?: PolicySettingRuleType;
}): IPolicySettingRuleBoundedNumberRangeField {
    return {
        fieldType: PolicySettingFieldType.BOUNDED_NUMBER_RANGE,
        rule,
        isParentValueSet: () => isSet(otherInput.parentValue),
        ...otherInput,
    };
}

function createRuleForBoundedSingleNumberField({
    rule = PolicySettingRuleType.WITHIN_BOUNDARIES,
    ...otherInput
}: Omit<IPolicySettingRuleBoundedSingleNumberField, 'fieldType' | 'rule' | 'isParentValueSet'> & {
    rule?: PolicySettingRuleType;
}): IPolicySettingRuleBoundedSingleNumberField {
    return {
        fieldType: PolicySettingFieldType.BOUNDED_SINGLE_NUMBER,
        rule,
        isParentValueSet: () => isSet(otherInput.parentValue),
        ...otherInput,
    };
}
