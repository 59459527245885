import { createTheme, ThemeOptions } from '@material-ui/core';
import { SIZES } from 'config/styling/sizes';
import { APP_COLORS, HEADER_COLORS } from './colors';

const DEFAULT_SPACING = 8;
const TYPOGRAPHY = {
    FONT_SIZE: 14,
    HTML_FONT_SIZE: 16,
};

/**
 * For default values, see https://material-ui.com/customization/default-theme/
 *
 * For the typography, see the formula for the calculated font size
 * in https://material-ui.com/customization/typography/
 * The 14 <> 16 causes: 1rem = 16px (if user did not change the default font size)
 */
const DEFAULT_THEME = createTheme({
    typography: {
        fontSize: TYPOGRAPHY.FONT_SIZE,
        htmlFontSize: TYPOGRAPHY.HTML_FONT_SIZE,
    },
});

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: APP_COLORS.PRIMARY['500'],
        },
        secondary: {
            main: APP_COLORS.SECONDARY['500'],
        },
        success: {
            main: APP_COLORS.FEEDBACK.SUCCESS,
        },
        error: {
            main: APP_COLORS.FEEDBACK.ERROR,
        },
        warning: {
            main: APP_COLORS.FEEDBACK.WARNING,
        },
        info: {
            main: APP_COLORS.FEEDBACK.INFO,
        },
    },
    typography: {
        fontFamily: [
            '"Nunito Sans"',
            'Roboto',
            'sans-serif',
        ].join(','),
        fontSize: TYPOGRAPHY.FONT_SIZE,
        htmlFontSize: TYPOGRAPHY.HTML_FONT_SIZE,
        h1: {
            fontSize: DEFAULT_THEME.typography.pxToRem(36),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        h2: {
            fontSize: DEFAULT_THEME.typography.pxToRem(32),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        h3: {
            fontSize: DEFAULT_THEME.typography.pxToRem(30),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        h4: {
            fontSize: DEFAULT_THEME.typography.pxToRem(26),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        h5: {
            fontSize: DEFAULT_THEME.typography.pxToRem(22),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        h6: {
            fontSize: DEFAULT_THEME.typography.pxToRem(18),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        subtitle1: {
            fontSize: DEFAULT_THEME.typography.pxToRem(20),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        subtitle2: {
            fontSize: DEFAULT_THEME.typography.pxToRem(13),
            fontWeight: DEFAULT_THEME.typography.fontWeightBold as number,
        },
        body1: {
            fontSize: DEFAULT_THEME.typography.pxToRem(16),
        },
        body2: {
            fontSize: DEFAULT_THEME.typography.pxToRem(15),
        },
        button: {
            fontSize: DEFAULT_THEME.typography.pxToRem(16),
        },
        caption: {
            fontSize: DEFAULT_THEME.typography.pxToRem(12),
        },
    },
    spacing: DEFAULT_SPACING,
    mixins: {
        toolbar: {
            minHeight: SIZES.HEADER.HEIGHT,
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                color: HEADER_COLORS.TEXT,
                backgroundColor: HEADER_COLORS.BACKGROUND,
            },
        },
    },
};

export const THEME = createTheme(themeOptions);
