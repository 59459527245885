import React from 'react';
import { goalDetailsEntity, triggerPatchGoalDetails } from 'state/entities/portfolioMgmt/goalDetails';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import AccessibleBy from 'views/common/genericApiEntity/accessibleBy/AccessibleBy';

export default function GoalAccessibleBy() {
    const goalDetailsData = goalDetailsEntity.select().data;

    return (
        <AccessibleBy
            entityDetailsData={goalDetailsData}
            nameForDisplay={getDefaultTranslationFromApiLabel(goalDetailsData.name)}
            asyncEntityPatchTrigger={triggerPatchGoalDetails}
        />
    );
}
