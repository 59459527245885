import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import {
    IApiEntityListResponseWithPageNr,
    IBaseFetchEntityListApiInput,
    TApiEntityId,
    TApiEntity,
} from '../api.models';

export enum InstrumentType {
    etfs = 'etfs',
    stocks = 'stocks',
    tokens = 'tokens',
}

export const ALL_INSTRUMENT_TYPES: InstrumentType[] = Object.values(InstrumentType);

export interface IFetchSpecificInstrumentsApiInput extends IBaseFetchEntityListApiInput {
    instrumentIds?: TApiEntityId[];
    universe_type: UniverseType;
    currency: string;
}

export interface IFetchInstrumentsApiInput extends IBaseFetchEntityListApiInput {
    universe_type: UniverseType;
    currency: string;
    name?: string; // contains check
    instrumentIds?: string[]; // array contains check
    partialInstrumentId?: string; // contains match check
}

export enum UniverseType {
    Robo = 'ROBO',
    Self = 'SELF',
}

export interface IAdvancedInstrumentFilters {
    name: string;
    partialInstrumentId: string;
}

export type TInstrument = TApiEntity<IInstrumentEntityData>;
export type TInstrumentsData = IApiEntityListResponseWithPageNr<IInstrumentEntityData>;

export interface IInstrumentEntityData {
    id: TApiEntityId;
    class: string; // e.g. 'stocks'
    name: string; // e.g. 'iShares MSCI Qatar ETF'
    price: number; // e.g, 15.8637
    type: string; // e.g. 'ETF'
    look_through: TAnyObject; // TODO proper typing
}

export interface IInstrumentsMap {
    [instrumentId: string]: IInstrumentEntityData;
}

export interface IInstrumentIdToNameMap {
    [instrumentId: string]: string;
}

export interface IEnhancedPortfolioInstrument {
    name: string;
    pricePer: number;
    units: number; // how many of this instrument
    amount: number; // the total price of this instrument
    percentageWithinHoldings: number; // the percentage of the amount vs. the amount of the whole portfolio
    class: string; // e.g. 'stocks'
    type: string;
    deleted?: boolean; // Will be used only in the Holdings screen
    edited?: boolean;
    added?: boolean;
    missingData?: boolean;
}
