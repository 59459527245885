import createActionableObservableStateStore
    from '@snipsonian/observable-state/cjs/actionableStore/createActionableObservableStateStore';
import { IActionableObservableStateStore } from '@snipsonian/observable-state/cjs/actionableStore/types';
import produce from 'immer';
import { IExtraProcessInput, ISetStateImmutableProps, IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { isStateLoggingEnabled, isStateStorageEnabled } from 'config/develop.config';
import { api } from 'api';
import { getInitialState, getStateStorageConfig } from '../stateConfig';

let configuredStore: IActionableObservableStateStore<IState, StateChangeNotification> = null;

/**
 * Make sure to only call this function AFTER the "overrideAppConfig" (within init of app) has been called.
 */
export default function getConfiguredStore(): IActionableObservableStateStore<IState, StateChangeNotification> {
    if (!configuredStore) {
        configuredStore = createActionableObservableStateStore<IState, IExtraProcessInput, StateChangeNotification>({
            initialState: getInitialState(),
            storage: isStateStorageEnabled
                ? getStateStorageConfig()
                : null,
            middlewares: [],
            logStateUpdates: isStateLoggingEnabled,
            logNotifiedObserverNames: isStateLoggingEnabled,
            triggerParentNotifications: {
                nrOfLevels: 100,
                notificationDelimiter: '.',
            },
            observableStateActionExtraProcessInput: {
                api,
                produce,
                setStateImmutable: (props: ISetStateImmutableProps) => {
                    const { toState, ...otherProps } = props;

                    configuredStore.setState({
                        newState: produce(configuredStore.getState(), toState),
                        ...otherProps,
                    });
                },
            },
        });
    }

    return configuredStore;
}
