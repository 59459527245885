import React from 'react';
import clsx from 'clsx';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetObject from '@snipsonian/core/cjs/object/verification/isSetObject';
import { TLabel } from 'models/general.models';
import { INavigateToRoute } from 'models/routing.models';
import { APP_COLORS, OPACITY } from 'config/styling/colors';
import { hexToRgba } from 'utils/styling/colorUtils';
import { mixins, makeStyles } from 'views/styling';
import { getRoute } from 'views/routes';
import Text from 'views/common/widget/Text';
import RouteLink from '../routing/RouteLink';

interface IStyleProps {
    noPadding?: boolean;
}

const useStyles = makeStyles((theme) => ({
    RouteBreadcrumbs: {
        ...mixins.typo({ size: 10 }),

        '&:not(:first-child)': {
            paddingLeft: ({ noPadding }: IStyleProps) => (noPadding ? 0 : theme.spacing(3)),
        },

        '& .MuiBreadcrumbs-ol': {
            minHeight: 20,
        },

        '& .MuiBreadcrumbs-separator': {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },

        '& .breadcrumb,a:not(.MuiButton-root).active': {
            ...mixins.typoBold({ color: APP_COLORS.GREY['300'] }),
            textTransform: 'uppercase',
        },

        '& svg': {
            color: hexToRgba(APP_COLORS.TEXT['500'], OPACITY.HIGH),
        },

        '& .breadcrumb.highlightedBreadcrumb': {
            ...mixins.typo({ color: APP_COLORS.TEXT['500'] }),
        },
    },
}));

const BREADCRUMB_SEPARATOR = <NavigateNextIcon fontSize="small" />;

export interface IBreadcrumb {
    label: TLabel;
    // If the route is specified clicking on the breadcrumb will result in a redirect
    route?: INavigateToRoute;
    shouldHighlightBreadcrumb?: boolean;
}

interface IPublicProps {
    breadcrumbs: IBreadcrumb[];
    noPadding?: boolean; // default false
}

export default function Breadcrumbs({
    breadcrumbs,
    noPadding = false,
}: IPublicProps) {
    const classes = useStyles({ noPadding });

    return (
        <MuiBreadcrumbs
            className={classes.RouteBreadcrumbs}
            separator={BREADCRUMB_SEPARATOR}
            aria-label="breadcrumb"
        >
            {breadcrumbs.map(({ route, label, shouldHighlightBreadcrumb = false }) => {
                const breadcrumb = <Text label={label} />;

                if (!isSetObject(route) || !isSet(getRoute({ routeKey: route?.routeKey }).component)) {
                    return (
                        <span
                            key={`breadcrumb_${label}`}
                            className={clsx('breadcrumb', shouldHighlightBreadcrumb && 'highlightedBreadcrumb')}
                        >
                            {breadcrumb}
                        </span>
                    );
                }

                return (
                    <RouteLink
                        key={`breadcrumb_${route?.routeKey}`}
                        className="breadcrumb"
                        to={route?.routeKey}
                        params={route?.params}
                    >
                        {breadcrumb}
                    </RouteLink>
                );
            })}
        </MuiBreadcrumbs>
    );
}
