import { string, object } from '@console/common/utils/schema';

export const getOutputKeyDetailsSchema = ({
    allOtherOutputKeyNames,
}: {
    allOtherOutputKeyNames: string[];
}) => object({
    name: string()
        // .notOneOf(allOtherOutputKeyNames)
        .test({
            name: 'output_key_name',
            test: (value) => !!value.match(/^(\w|\.)*$/),
        })
        .test({
            name: 'output_key_name_unique',
            test: (value) => !allOtherOutputKeyNames.includes(value),
        })
        .required(),
    description: string().required(),
}).required();
