import { object, array, string } from '@console/common/utils/schema';

const userOrGroupId = string().required();

export const accessibleBySchema = object({
    readableBy: array().of(userOrGroupId)
        .unique({ uniqueLabelForDisplay: 'users or groups' }),
    modifiableBy: array().of(userOrGroupId)
        .unique({ uniqueLabelForDisplay: 'users or groups' }),
}).required();
