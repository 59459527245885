import produce from 'immer';
import { ALL_ASYNC_OPERATIONS } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { hasFetchSucceeded } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import { getOnlyChangedProperties } from '@console/common/utils/object/getOnlyChangedProperties';
import { ObjectSchema } from '@console/common/utils/schema';
import {
    TCreatePolicyApiBody,
    TCreatePolicyApiInput,
    TEnhancedPolicy,
    TPatchPolicyApiBody,
    TPatchPolicyApiInput,
} from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import {
    ISinglePolicyApiInput,
    TPolicy,
} from '@console/core-api/models/portfolioMgmt/policy.models';
import { IBaseSingleEntityApiInput, TApiEntityId } from '@console/core-api/models/api.models';
import { ITenantPolicySettingsBoundaries } from '@console/bff/models/settings/tenantSettings.models';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import { IState } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { AsyncEntityKeys } from 'models/state/entities.models';
import { POLICY_TAB_KEY } from 'config/tabs.config';
import { api } from 'api';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { getPoliciesSettingsBoundariesConfig } from 'state/appConfig/selectors';
import { getUserPermissions } from 'state/auth/selectors';
import { determinePolicySettingsRules } from 'utils/entities/portfolioMgmt/policySettingsRulesManager';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { getPolicyDetailsDynamicSchema } from 'views/portfolioMgmt/Policies/PolicyDetail/policyDetailsSchema';
import {
    TPolicyDetailFormValues,
} from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';
import { getPolicyAlgorithmLabel } from 'utils/entities/portfolioMgmt/policyUtils';
import { getStore } from 'state';
import { TTagSelector, TTitleLabelSelector } from 'views/common/layout/PageTitleBasedOnState';
import { getEntitiesManager } from '../entitiesManager';
import {
    flashErrorApiEntityPatch,
    flashSuccessApiEntityPatch,
    flashSuccessApiEntityCreate,
    flashErrorApiEntityCreate,
} from '../entitiesFlashDispatcher';
import { triggerResetPoliciesFetch } from './policies';
import {
    apiDetailsEntityAsyncFetchAction,
    apiEntityAsyncDeleteAction,
} from '../genericApiEntity/apiEntityAsyncActions';
import {
    triggerFetchParentPolicyChain,
    triggerResetParentPolicyChain,
} from './policyParentChain';

let policyDetailsMetaInfoCache: IPolicyDetailsMetaInfo = null;

export const policyDetailsEntity = getEntitiesManager().registerEntity<TEnhancedPolicy>({
    asyncEntityKey: AsyncEntityKeys.policyDetails,
    operations: ALL_ASYNC_OPERATIONS,
    notificationsToTrigger: [StateChangeNotification.POLICY_DETAILS_DATA],
    includeUpdaters: true,
});

export const getPolicyTitle: TTitleLabelSelector = () => {
    const policyDetails = policyDetailsEntity.select();

    if (hasFetchSucceeded(policyDetails)) {
        return {
            text: policyDetails.data.name,
            shouldTranslate: false,
        };
    }

    return 'portfolio_mgmt.policies.detail.title';
};

export const getPolicyAlgorithmTagProps: TTagSelector = () => {
    const policyDetails = policyDetailsEntity.select();

    if (hasFetchSucceeded(policyDetails)) {
        return {
            label: getPolicyAlgorithmLabel(policyDetails.data.config.algorithm),
            variant: 'grey',
        };
    }

    return null;
};

export function triggerFetchPolicyDetails() {
    if (!validateEntityIdBeforeFetch({
        entityId: getCurrentRouteParam(getStore().getState(), 'policyId'),
        entityType: CoreApiEntityType.policies,
    })) {
        return null;
    }

    return apiDetailsEntityAsyncFetchAction<TEnhancedPolicy, ISinglePolicyApiInput>({
        detailsEntity: policyDetailsEntity,
        entityType: CoreApiEntityType.policies,
        api: api.bff.policies.fetchEnhancedPolicyDetails,
        apiInputSelector: ({ state }) => ({
            policyId: getCurrentRouteParam(state, 'policyId'),
        }),
        onPreSuccess: ({ apiResult }) => {
            if (apiResult.parent_policy_id) {
                triggerFetchParentPolicyChain({
                    policyId: apiResult.parent_policy_id,
                });
            }
        },
        refreshMode: ({ state }) =>
            policyDetailsEntity.select().data.id !== getCurrentRouteParam(state, 'policyId'),
        resetDataOnTriggerMode: 'always',
    });
}

export function triggerPatchPolicyDetails(policyUpdater: (currentPolicy: TPatchPolicyApiBody) => void, {
    onPreSuccess,
    notificationsToTrigger,
    isChangingParentPolicy = false,
}: {
    onPreSuccess?: (props: { state: IState }) => void;
    notificationsToTrigger?: StateChangeNotification[];
    isChangingParentPolicy?: boolean;
} = {}) {
    return policyDetailsEntity.async.update<TPatchPolicyApiInput, TEnhancedPolicy>({
        api: api.bff.policies.patchPolicy,
        apiInputSelector: () => ({
            ...getOnlyChangedProperties(
                policyDetailsEntity.select().data,
                produce(policyDetailsEntity.select().data, policyUpdater),
            ),
            policyId: policyDetailsEntity.select().data.id,
        }),
        updateDataOnSuccess: true,
        onPreSuccess: ({ state, apiResult }) => {
            triggerResetPoliciesFetch();
            apiCacheManager.resetPolicy(apiResult);

            if (isChangingParentPolicy) {
                triggerResetParentPolicyChain();
            }

            if (policyDetailsMetaInfoCache
                && policyDetailsMetaInfoCache.parentPolicyId === policyDetailsEntity.select().data.id) {
                /* reset because the policy that was in that cache was just updated */
                policyDetailsMetaInfoCache = null;
            }

            if (onPreSuccess) {
                onPreSuccess({ state });
            }
        },
        onSuccess: flashSuccessApiEntityPatch,
        onError: flashErrorApiEntityPatch,
        notificationsToTrigger,
    });
}

export function triggerDeletePolicy(policyIndentifier?: IBaseSingleEntityApiInput) {
    const identifier = policyIndentifier || {
        id: policyDetailsEntity.select().data.id,
    };

    return apiEntityAsyncDeleteAction<TEnhancedPolicy>({
        detailsEntity: policyDetailsEntity,
        api: api.policies.deletePolicy,
        apiInputSelector: () => identifier,
        onPreSuccess: () => {
            triggerResetPoliciesFetch();
            apiCacheManager.clearPolicy({ policyId: identifier.id });
            redirectTo({
                routeKey: ROUTE_KEY.R_POLICIES_LIST,
            });
        },
    });
}

export function triggerCreatePolicy(policy: TCreatePolicyApiBody) {
    return policyDetailsEntity.async.create<TCreatePolicyApiInput, TEnhancedPolicy>({
        api: api.bff.policies.createPolicy,
        apiInputSelector: () => policy,
        updateDataOnSuccess: true,
        onPreSuccess: () => {
            triggerResetPoliciesFetch();

            if (policy.parent_policy_id) {
                triggerFetchParentPolicyChain({
                    policyId: policy.parent_policy_id,
                });
            }
        },
        onSuccess: ({ apiResult, dispatch }) => {
            flashSuccessApiEntityCreate({ dispatch });

            redirectTo({
                routeKey: ROUTE_KEY.R_POLICY_DETAIL,
                params: {
                    policyId: apiResult.id,
                    policyTab: POLICY_TAB_KEY.CONFIGURATION,
                },
            });
        },
        onError: flashErrorApiEntityCreate,
    });
}

export interface IPolicyDetailsMetaInfo {
    parentPolicyId: TApiEntityId;
    rules: IPolicySettingsRules;
    schema: ObjectSchema<TPolicyDetailFormValues>;
    tenantSettings: ITenantPolicySettingsBoundaries;
}

export function getPolicyDetailsMetaInfo({
    parentPolicy,
}: {
    parentPolicy: TEnhancedPolicy | TPolicy | null;
}) {
    const parentPolicyId = parentPolicy?.id;

    if (!policyDetailsMetaInfoCache || (policyDetailsMetaInfoCache.parentPolicyId !== parentPolicyId)) {
        const tenantPolicySettingsBoundaries = getPoliciesSettingsBoundariesConfig();

        const rules = determinePolicySettingsRules({
            userPermissions: getUserPermissions(),
            tenantPolicySettingsBoundaries,
            parentPolicy,
        });

        policyDetailsMetaInfoCache = {
            parentPolicyId,
            rules,
            // eslint-disable-next-line max-len
            schema: getPolicyDetailsDynamicSchema({ policySettingsRules: rules }),
            tenantSettings: tenantPolicySettingsBoundaries,
        };
    }

    return policyDetailsMetaInfoCache;
}

export function getPolicySettingsRules({
    parentPolicy,
}: {
    parentPolicy: TEnhancedPolicy | TPolicy | null;
}) {
    return getPolicyDetailsMetaInfo({ parentPolicy }).rules;
}

export function getPolicyDetailsSchema({
    parentPolicy,
}: {
    parentPolicy: TEnhancedPolicy | TPolicy | null;
}) {
    return getPolicyDetailsMetaInfo({ parentPolicy }).schema;
}
