import { TAsyncValueMapper } from '@console/core-api/models/api.models';
import { IPathParams, IQueryParams } from 'models/routing.models';
import {
    GOAL_TAB_KEY,
    HORIZON_TAB_KEY,
    POLICY_TAB_KEY,
    RISK_PROFILE_TAB_KEY,
    USER_TAB_KEY,
    USERGROUP_TAB_KEY,
} from 'config/tabs.config';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import { isUserEntityId } from 'utils/entities/entityTypeUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { ROUTE_KEY } from 'views/routeKeys';

export interface IApiEntityAsyncValueField {
    asyncValueMapper: TAsyncValueMapper;
    linkTo: (id: string) => {
        to: ROUTE_KEY;
        params?: IPathParams;
        query?: IQueryParams;
    };
}

export const userNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => apiCacheManager
        .fetchUser({ userId: id })
        .then((user) => getUserFullName(user)),
    linkTo: (id: string) => ({
        to: ROUTE_KEY.R_USER_DETAIL,
        params: {
            userId: id,
            userTab: USER_TAB_KEY.PROPERTIES,
        },
    }),
};

export const userGroupNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => apiCacheManager
        .fetchUserGroup({ userGroupId: id })
        .then((userGroup) => userGroup.name),
    linkTo: (id: string) => ({
        to: ROUTE_KEY.R_USER_GROUP_DETAIL,
        params: {
            groupId: id,
            userGroupTab: USERGROUP_TAB_KEY.PROPERTIES,
        },
    }),
};

export const userOrGroupNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => {
        if (isUserEntityId(id)) {
            return userNameAsyncValueField.asyncValueMapper(id);
        }

        return userGroupNameAsyncValueField.asyncValueMapper(id);
    },
    linkTo: (id: string) => {
        if (isUserEntityId(id)) {
            return userNameAsyncValueField.linkTo(id);
        }

        return userGroupNameAsyncValueField.linkTo(id);
    },
};

export const goalNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => apiCacheManager
        .fetchGoal({ goalId: id })
        .then((goal) => getDefaultTranslationFromApiLabel(goal.name)),
    linkTo: (id: string) => ({
        to: ROUTE_KEY.R_GOAL_DETAIL,
        params: {
            goalId: id,
            goalTab: GOAL_TAB_KEY.PROPERTIES,
        },
    }),
};

export const horizonNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => apiCacheManager
        .fetchHorizon({ horizonId: id })
        .then((horizon) => getDefaultTranslationFromApiLabel(horizon.name)),
    linkTo: (id: string) => ({
        to: ROUTE_KEY.R_HORIZON_DETAIL,
        params: {
            horizonId: id,
            horizonTab: HORIZON_TAB_KEY.PROPERTIES,
        },
    }),
};

export const riskProfileNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => apiCacheManager
        .fetchRiskProfile({ riskProfileId: id })
        .then((riskProfile) => getDefaultTranslationFromApiLabel(riskProfile.name)),
    linkTo: (id: string) => ({
        to: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
        params: {
            riskProfileId: id,
            riskProfileTab: RISK_PROFILE_TAB_KEY.PROPERTIES,
        },
    }),
};

export const policyNameAsyncValueField: IApiEntityAsyncValueField = {
    asyncValueMapper: (id: string) => apiCacheManager
        .fetchPolicy({ policyId: id })
        .then((policy) => policy.name),
    linkTo: (id: string) => ({
        to: ROUTE_KEY.R_POLICY_DETAIL,
        params: {
            policyId: id,
            policyTab: POLICY_TAB_KEY.CONFIGURATION,
        },
    }),
};
