import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    prefixWithDecimalSeparator,
    splitAtDecimalSeparator,
} from '@console/common/utils/float/floatUtils';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { makeStyles } from 'views/styling';

export type TFractionalVariant = 'mediumSuperscript' | 'regular';

interface IAmountDisplayProps {
    value: number;
    fractionalsVariant?: TFractionalVariant;
    nrOfDecimals?: number;
    disableValue?: boolean; /* default false */
    currency?: SupportedCurrency;
}

const useStyles = makeStyles((/* theme */) => ({
    AmountSuperscript: {
        '& sup': {
            paddingLeft: 2,
        },
    },
}));

export default function AmountDisplay({
    value,
    fractionalsVariant = 'mediumSuperscript',
    nrOfDecimals = 2,
    disableValue = false,
    currency,
}: IAmountDisplayProps) {
    const classes = useStyles();

    if (!isSet(value)) {
        return null;
    }

    if (disableValue) {
        return <>-</>;
    }

    const formattedValue = formatAmount(value, { nrOfDecimals, currency });

    if (fractionalsVariant === 'mediumSuperscript') {
        const valueParts = splitAtDecimalSeparator(formattedValue);
        const mainValueMediumSuperscript = valueParts && valueParts[0];
        let supValueMediumSuperscript = valueParts && valueParts[1];

        if (!supValueMediumSuperscript.search(/^0+$/)) {
            /* in case of 'mediumSuperscript', no decimals are shown when they would only contain zero's */
            supValueMediumSuperscript = null;
        }

        return (
            <div className={classes.AmountSuperscript}>
                {mainValueMediumSuperscript}
                {
                    supValueMediumSuperscript && <sup>{prefixWithDecimalSeparator(supValueMediumSuperscript)}</sup>
                }
            </div>
        );
    }

    return (
        <>
            {formattedValue}
        </>
    );
}
